import { dateConvertToCurrentTime } from "../../../utils/date/dateConvertToCurrentTime";
import { DateP, ImgContent, MsgTypeContainer } from "../../../utils/msgStyles";
import ImageLayout from "../../shared/ImageLayout";

const TypeImage = ({ msgObj, msg, originalPreview, isMine, createdAt }) => {
  return (
    <MsgTypeContainer isMine={isMine}>
      {isMine ? (
        <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
      ) : null}
      <ImgContent>
        <ImageLayout
          onClick={() => originalPreview(msgObj)}
          src={msg}
          variant="chat"
        />
      </ImgContent>
      {!isMine ? (
        <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
      ) : null}
    </MsgTypeContainer>
  );
};

export default TypeImage;
