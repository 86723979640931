//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

import { cpus } from "os";
import { useEffect } from "react";
import { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import colors from "../../utils/colors";
import { faceDetection, loader } from "../../utils/faceDetection";
import { FileLabel } from "./FileLabel";
import { GButton } from "./GButton";
import ImageLayout from "./ImageLayout";
import { SInput } from "./InputLayout";

const Container = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
  /* min-height: 60vh; */
`;
const Interact = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5px;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
`;

const CropCon = styled.div`
  display: flex;
  width: 65%;
  flex-direction: column;
  align-items: center;
`;

const PreViewCon = styled.div`
  display: flex;
  width: 100%;
  /* height: 100%; */
  flex-direction: column;
  justify-content: space-between;
`;
const CropImage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
`;
const Preview = styled.div`
  width: 100%;
  /* border: 1px solid rgb(230, 229, 239); */
`;

const ImgCropTitle = styled.h2`
  font-size: 18px;
  color: white;
  width: 100%;
  text-align: center;
  padding: 0.25rem 0.4rem;
  background-color: #ff6348;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const PreViewTitle = styled(ImgCropTitle)``;
const ImageCrop = ({
  file,
  setFile,
  aspect,
  getCropImage,
  noDetect,
  dropImage,
  getModal,
}) => {
  const onClick = () => {
    getModal(false);
  };
  useEffect(async () => {
    if (!!file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(file);
    }
  }, []);

  useEffect(async () => {
    if (!!dropImage && src == null) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        if (!noDetect) {
          const detectedFileBinary = await faceDetection(reader.result);
          setSrc(detectedFileBinary);
        } else {
          setSrc(reader.result);
        }
      });
      reader.readAsDataURL(dropImage[0]);
    }
  }, [dropImage]);

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    ...(aspect && { aspect }),
    unit: "px",
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  });

  const [imageRef, setImageRef] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState();
  const [once, setOnce] = useState(false);
  // console.log(src);

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        if (!noDetect) {
          const detectedFileBinary = await faceDetection(reader.result);
          setSrc(detectedFileBinary);
        } else {
          setSrc(reader.result);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };
  useEffect(() => {
    if (false) {
      // makeClientCrop({
      //   aspect: 1,
      //   width: 100,
      //   height: 100,
      //   unit: "px",
      //   x: 0,
      //   y: 0,
      // });
      imageRef.src = imageRef?.src;
    } else if (!!imageRef) {
      makeClientCrop({
        aspect: 1,
        width: 100,
        height: 100,
        unit: "px",
        x: 0,
        y: 0,
      });
      setOnce(true);
    }
  }, [imageRef?.src]);

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop) => {
    if (crop?.y < 0) {
      crop.y = 0;
      setCrop(crop);
    } else {
      setCrop(crop);
    }
    if (crop?.x < 0) {
      crop.x = 0;
      setCrop(crop);
    } else {
      setCrop(crop);
    }
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        file ? `${file.name}` : "crop.png"
      );
      await setCroppedImageUrl(croppedImageUrl);
      if (!!getCropImage) {
        await getCropImage(croppedImageUrl);
      }
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          const reader = new FileReader();
          reader.onload = (e) => {
            setFile(new File([blob], fileName));
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        },
        "image/jpeg",
        1
      );
    });
  };
  0;

  return dropImage ? (
    <Container>
      <CropCon>
        <ImgCropTitle>Image Crop</ImgCropTitle>
        <CropImage>
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
            />
          )}
        </CropImage>
      </CropCon>
      <div
        style={{
          display: "flex",
          width: "35%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <PreViewCon>
          <PreViewTitle>PreView</PreViewTitle>
          <Preview>
            {croppedImageUrl && (
              <img alt="Crop" src={croppedImageUrl} width="100%" />
            )}
          </Preview>
        </PreViewCon>
        {!!dropImage ? (
          <Interact>
            <FileLabel align="center" variant="update" context="파일 선택">
              <SInput
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                style={{ display: "none" }}
              />
            </FileLabel>
            <GButton
              variant="create"
              context={
                <i
                  style={{ fontSize: "24px" }}
                  className="ri-folder-upload-line"
                ></i>
              }
              padding="0.375rem 0.75rem"
              width="100%"
              fontSize="12px"
              onClick={onClick}
            />
          </Interact>
        ) : null}
      </div>
    </Container>
  ) : null;
};

export default ImageCrop;
