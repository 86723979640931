//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { faCrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { CardColumns, Container, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import ChatroomModule from "../component/chatroom/ChatroomModule";
import ConsultsNavigationLeft from "../component/shared/ConsultsNavigationLeft";
import { FatText } from "../component/shared/FatText";
import { GButton } from "../component/shared/GButton";
import MsgNavigationLeft from "../component/shared/MsgNavigationLeft";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import { SELECT_CONSULTS_QUERY } from "../queries/consults/selectConsults";
import { UPDATE_CONSULT_MUTATION } from "../queries/consults/updateConsults";
import { CHECK_MESSAGE_MUTATION } from "../queries/message/checkMessage";
import { SEE_ROOM_LIST } from "../queries/message/seeRoomList";
import CheckUserToken from "../utils/checkUserToken";
import colors from "../utils/colors";
import {
  consultsCloseClick,
  consultsSidebarClick,
  consultsSidebarToggleVar,
  isNewMessageVar,
  messageCloseClick,
  messageSidebarClick,
  messageSidebarToggleVar,
  myChatTabVar,
  sidebarCloseClick,
} from "../utils/reactiveVar";
import { routes } from "./routes";
import { UPDATE_ORDER_CHATROOM_MUTATION } from "../queries/user/updateOrderChatRoom";
import SelectOrderChatRoom from "../queries/user/selectOrderChatRoom";
import { UPDATE_ORDER_CONSULT_MUTATION } from "../queries/user/updateOrderConsult";
import ChList from "../component/chatroom/ChatRoomList";
import axios from "axios";

const MessageContainer = styled(Container)`
  max-width: 1320px;
  padding: 0;
  justify-content: center;
  /* border-radius: 10px; */
  border: 1px solid #ccc;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr 2.5fr 1fr;
  /* gap: 10px; */
  /* margin: 0 10px; */
  height: 90vh;
  @media (max-width: 1399px) {
    grid-template-columns: 1fr 3fr;
  }
  @media (max-width: 899px) {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: none;
    padding: 5px;
  }
`;
const ChatRoomContainerWrap = styled(CardColumns)`
  position: relative;
  /* border: 2px solid rgb(215, 215, 215); */
  /* background-color: inherit; */
  width: 100%;
  height: 100%;
  /* box-shadow: 0 0 15px -5px gray; */
  /* border-top-left-radius: 5px; */
  /* border-top-right-radius: 5px; */
  overflow: hidden;
  /* background-color: rgba(206, 17, 38, 0.05); */
`;

const ChatRoomContainer = styled(CardColumns)`
  position: relative;
  /* border: 2px solid rgb(215, 215, 215); */
  background-color: inherit;
  width: 100%;
  height: 100%;
  /* box-shadow: 0 0 15px -5px gray; */
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  overflow: hidden;
  border: 1px solid rgb(215, 215, 215);
  border-top: none;
  background-color: #e6e6e8;
`;

const UserList = styled(CardColumns)`
  background-color: ${colors.lightGray};
  /* box-shadow: 0 0 15px -5px gray; */
  /* border: 2px solid rgb(215, 215, 215); */
  /* border-radius: 5px; */

  display: flex;
  flex-direction: column;

  /* gap: 10px; */
  height: 100%;
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : ""};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ed5f00;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
  @media (max-width: 1399px) {
    display: none;
  }
`;
const UserOne = styled(CardColumns)`
  display: flex;
  /* width:100 */
  box-shadow: 0 5px 20px 0 rgba(82, 63, 105, 0.2);
  height: 60px;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  justify-content: flex-start;
  gap: 15px;
  cursor: pointer;
  padding: 0 10px;
  &:hover {
    background-color: rgb(250, 250, 252);
  }
`;
const ConsultOne = styled(CardColumns)`
  display: flex;
  /* width:100 */
  box-shadow: 0 5px 20px 0 rgba(82, 63, 105, 0.2);
  height: 60px;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  /* justify-content: space-between; */
  gap: 10px;
  cursor: pointer;
  padding: 0 10px;
  &:hover {
    background-color: rgb(250, 250, 252);
  }
  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;
const ConsultDate = styled(CardColumns)`
  align-self: flex-end;
  width: 100%;
  text-align: right;
  align-content: flex-end;
`;

const SelectChatType = styled(CardColumns)`
  width: 100%;
  background-color: white;
`;
const Tabs = styled(CardColumns)`
  display: flex;
  width: 100%;
  /* border-radius: 5px; */

  background-color: white;
  justify-content: space-between;
  text-align: center;
`;
const Tab = styled(CardColumns)`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  /* border: 2px solid rgb(215, 215, 215); */
  cursor: pointer;
  ${(props) =>
    props.checked
      ? `border: 2px solid #ed5f00;   color:#ed5f00; background-color: white;`
      : props.cnum === 1
      ? ` border-left:none;    `
      : ` border-right:none;   `};
`;
const ModalCon = styled(CardColumns)`
  width: 100%;
  margin: 0;
  padding: 0;
`;
const UserContainer = styled(CardColumns)`
  background-color: #f5f6fa;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;
const UserName = styled(CardColumns)`
  color: #222;
  padding: 5px 0 10px 0;
  font-size: 23px;
  font-weight: 700;
`;
const Contents = styled(CardColumns)`
  width: 100%;
  padding: 10px;
  background-color: white;
  min-height: 150px;
  border-top: 1px solid lightgray;
  margin-bottom: 10px;
`;
const TypeCon = styled(CardColumns)`
  @media (max-width: 899px) {
    display: none;
  }
`;
const ButtonCon = styled(CardColumns)`
  position: absolute;

  width: 100%;
  display: flex;
  align-content: flex-end;
  height: 50px;
  top: 0;
  left: 0;
  padding: 10px 0;
  div {
    display: flex;
    margin-left: auto;
    padding-right: 10px;
  }
`;

const ChatIcon = styled.i`
  display: none;
  border-radius: 50%;
  background-color: #e4e6eb;
  color: #050505;
  width: 40px;
  height: 40px;
  font-size: 18px;
  padding: 11px;
  margin-left: 10px;
  cursor: pointer;
  @media (max-width: 899px) {
    display: block;
  }
  &:hover {
    background-color: #e4e6eb;
    color: ${colors.bbeego};
  }
`;

export const NoInfo = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.bbeego};
  gap: 5px;
`;
const NoInfoIcon = styled.i``;
const NoInfoText = styled.div``;
const UserIcon = styled.i`
  display: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e4e6eb;
  color: #050505;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  font-size: 20px;
  padding: 10px;
  @media (max-width: 1399px) {
    display: flex;
  }
  &:hover {
    background-color: #e5e6ea;
    color: ${colors.bbeego};
  }
`;

function Message() {
  const location = useLocation();
  const history = useHistory();
  const tab_1 = useRef();
  const tab_2 = useRef();
  const loggedInUser = CheckUserToken();
  const msgSidebarToggle = useReactiveVar(messageSidebarToggleVar);
  const conSidebarToggle = useReactiveVar(consultsSidebarToggleVar);
  const crArray = SelectOrderChatRoom(loggedInUser?.me?.id);

  const consultsData = useQuery(SELECT_CONSULTS_QUERY, {
    fetchPolicy: "network-only",
  })?.data;

  const [cnsAccepted, setCNSAceepted] = useState();
  const [chatRoomSelected, setChatRoomSelected] = useState(false);
  const [modalData, setModalData] = useState();
  const myChatTab = useReactiveVar(myChatTabVar);
  const [cnsModal, setCNSModal] = useState(false);
  const [crList, setCRList] = useState([]);
  const [cnList, setCNList] = useState([]);
  const [tags, setTags] = useState([]);
  const [conTags, setConTags] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [conList, setConList] = useState([]);

  //mobile
  const [isMobile, setIsMobile] = useState(false);
  const reSizingHandler = () => {
    if (window.innerWidth < 899) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const [showChatRoom, setShowChatRoom] = useState(0); //chatroomId

  useEffect(() => {
    if (window.innerWidth <= 899) {
      setIsMobile(true);
    }

    window.addEventListener("resize", reSizingHandler);
    return () => {
      window.removeEventListener("resize", reSizingHandler);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      messageSidebarClick();
      consultsCloseClick();
      sidebarCloseClick();
    } else {
      messageCloseClick();
      consultsCloseClick();
      sidebarCloseClick();
    }
  }, [isMobile]);

  const getdata = (cid) => {
    setShowChatRoom(cid);
    setChatRoomSelected(true);
    checkMessageMutation({
      variables: {
        roomId: cid,
      },
    });
  };

  const getChdata = (cid) => {
    setShowChatRoom(cid);
    setChatRoomSelected(true);
    checkMessageMutation({
      variables: {
        roomId: cid,
      },
    });
  };

  const getCosultData = (v) => {
    setModalData(v);

    setCNSModal(true);
  };

  const { data: roomList } = useQuery(SEE_ROOM_LIST, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (crArray?.selectOrderChatRoom?.chatRoomOrder) {
      let array = crArray?.selectOrderChatRoom?.chatRoomOrder
        ?.split("|")
        ?.map(Number);
      setChatList(array);
    }
    if (crArray?.selectOrderChatRoom?.consultOrder) {
      let array = crArray?.selectOrderChatRoom?.consultOrder
        ?.split("|")
        ?.map(Number);

      setConList(array);
    }
  }, [crArray]);

  useEffect(() => {
    let array = [];
    let conArray = [];
    if (roomList?.seeRoomList?.length) {
      let data = JSON.parse(JSON.stringify(roomList.seeRoomList));
      let chatData = data?.filter((c) => {
        if (c.contract !== null || c.multiChallengeGroup !== null) return c;
      });
      let conData = data?.filter((c) => {
        if (c.consult !== null) return c;
      });

      if (chatList?.length) {
        chatList.map((v) => {
          chatData.filter((cd) => {
            if (cd?.id === v) {
              array.push(cd);
            }
          });
          setTags(array);
        });
      } else {
        setTags(chatData);
      }
      if (conList?.length) {
        conList.map((v) => {
          conData.filter((cd) => {
            if (cd?.id === v) {
              conArray.push(cd);
            }
          });
          setConTags(conArray);
        });
      } else {
        setConTags(conData);
      }
    }
  }, [roomList]);

  const onBarsClick = () => {
    messageSidebarClick();
    consultsCloseClick();
    sidebarCloseClick();
  };
  const onConsultsClick = () => {
    consultsSidebarClick();
    messageCloseClick();
    sidebarCloseClick();
  };

  const update = (cache, data) => {
    cache.modify({
      id: `ChatRoom:${showChatRoom}`,
      fields: {
        messages(prev, { fieldName, storeFieldName }) {},
      },
    });
  };

  const consultTrue = (tid) => {
    setCNSAceepted(true);
    updateConsultMutation({
      variables: {
        id: tid?.id,
        trainerAccepted: true,
      },
    });
  };

  const consultFalse = (fid) => {
    setCNSAceepted(false);
    updateConsultMutation({
      variables: {
        id: fid?.id,
        trainerAccepted: false,
      },
    });
  };

  const [checkMessageMutation] = useMutation(CHECK_MESSAGE_MUTATION, {
    update,
  });

  const [updateOrderChatRoomMutation] = useMutation(
    UPDATE_ORDER_CHATROOM_MUTATION
  );

  const [updateOrderConsultMutation] = useMutation(
    UPDATE_ORDER_CONSULT_MUTATION
  );

  const [updateConsultMutation] = useMutation(UPDATE_CONSULT_MUTATION, {
    onCompleted: (data) => {
      const {
        updateConsult: { ok },
      } = data;
      if (ok && cnsAccepted === true) {
        alert("1 대 1 채팅 요청을 수락했습니다.");
        crArray.refetch();
      } else if (ok && cnsAccepted === false) {
        alert("1 대 1 채팅 요청을 거절했습니다.");
      }
      setCNSModal(false);
    },

    refetchQueries: [
      { query: SEE_ROOM_LIST },
      { query: SELECT_CONSULTS_QUERY },
    ],
  });

  const onConsultClick = (v) => {
    setModalData(v);
    setCNSModal(true);
  };

  const onChatRoomClick = (chatRoomId) => {
    setChatRoomSelected(true);
    setShowChatRoom(chatRoomId);
    checkMessageMutation({
      variables: {
        roomId: chatRoomId,
      },
    });
    isNewMessageVar(false);
  };

  // useEffect(() => {
  //   if (location?.state) {
  //     const chatRoomId = location?.state?.id;
  //     if (!!chatRoomId) {
  //       setChatRoomSelected(true);
  //       setShowChatRoom(chatRoomId);
  //     }
  //   }
  // }, [location]);
  const onTabClick = (tabNo, { target }) => {
    myChatTabVar(tabNo);
  };

  useEffect(() => {
    if (location?.state?.ChatRoomId) {
      setChatRoomSelected(true);
      setShowChatRoom(location?.state?.ChatRoomId);
      checkMessageMutation({
        variables: {
          roomId: location?.state?.ChatRoomId,
        },
      });
      isNewMessageVar(false);
    }
  }, [location?.state?.ChatRoomId]);

  useEffect(() => {
    if (location?.state?.myChatTab) {
      myChatTabVar(location?.state?.myChatTab);
    }
  }, []);

  //dnd
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const currentTags = myChatTab === 1 ? [...tags] : [...conTags];
    const draggingItemIndex = result.source.index;
    const afterDragItemIndex = result.destination.index;
    const removeTag = currentTags.splice(draggingItemIndex, 1);
    currentTags.splice(afterDragItemIndex, 0, removeTag[0]);
    if (myChatTab === 1) {
      setTags(currentTags);
      let array = [];
      if (currentTags.length) {
        currentTags.map((v) => {
          array.push({ id: v.id });
        });
      }
      setCRList(array);
      if (crList?.length) {
        updateOrderChatRoomMutation({
          variables: {
            data: crList,
          },
        });
      }
    } else {
      setConTags(currentTags);
      let array = [];
      if (currentTags.length) {
        currentTags.map((v) => {
          array.push({ id: v.id });
        });
      }
      setCNList(array);
      if (cnList?.length) {
        updateOrderConsultMutation({
          variables: {
            data: cnList,
          },
        });
      }
    }
  };

  //csp 사이트로 이동후 SSO 로그인
  const moveToCsp = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/sendKeyCsp?email=${loggedInUser?.me?.email}`
      );
      const { ok, data } = res.data;
      if (!!ok) {
        const { email, key } = data;
        // const sso = await axios.get(
        //   `https://csp.waterai.co.kr/sso?username=${email}&key=${key}`
        // );
        const link = document.createElement("a");
        link.href = `https://csp.waterai.co.kr/sso?username=${email}&key=${key}`;
        link.target = "_blank";
        link.click();
      }
    } catch (e) {
      return;
    }
  };

  return (
    <MessageContainer id="main">
      <MsgNavigationLeft
        // ref={sidebar}
        roomList={roomList}
        iconShow={true}
        borderRadius="10px"
        width="400px"
        getdata={getdata}
        onDragEnd={onDragEnd}
        tags={tags}
        conTags={conTags}
        margin={msgSidebarToggle ? "" : "0 0 0 -400px"}
      />
      <ConsultsNavigationLeft
        // ref={sidebar}
        roomList={roomList}
        consultsData={consultsData}
        iconShow={true}
        showChatRoom={showChatRoom}
        borderRadius="10px"
        width="400px"
        getCosultData={getCosultData}
        // getdata={getdata}
        margin={conSidebarToggle ? "" : "0 0 0 -400px"}
      />
      <TypeCon>
        <SelectChatType>
          {" "}
          <Tabs>
            <Tab
              // style={{ borderTopLeftRadius: "5px" }}
              ref={tab_1}
              className="tab"
              checked={myChatTab === 1 ? true : false}
              cnum={myChatTab === 1 ? 1 : 2}
              onClick={(e) => {
                onTabClick(1, e);
              }}
            >
              채팅
            </Tab>
            <Tab
              // style={{ borderTopRightRadius: "5px" }}
              ref={tab_2}
              className="tab"
              checked={myChatTab === 2 ? true : false}
              cnum={myChatTab === 2 ? 2 : 1}
              onClick={(e) => {
                onTabClick(2, e);
              }}
            >
              1:1문의
            </Tab>
          </Tabs>
        </SelectChatType>
        <div>
          <ChList getChdata={getChdata} />
        </div>
      </TypeCon>
      <ChatRoomContainerWrap>
        {/* 작업장으로 이동 */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "5px",
          }}
        >
          <GButton
            variant="read"
            context="작업장으로 이동"
            zIndex="1000"
            onClick={moveToCsp}
          />
        </div> */}
        {/* <ChatRoomContainer style={{ height: "95%" }}> */}
        <ChatRoomContainer style={{ height: "100%" }}>
          <ButtonCon>
            <div>
              <ChatIcon
                onClick={onBarsClick}
                className="bi bi-chat-dots-fill"
              />
              <UserIcon
                onClick={onConsultsClick}
                className="bi bi-person-fill"
              />
            </div>
          </ButtonCon>

          {chatRoomSelected ? <ChatroomModule id={showChatRoom} /> : null}
        </ChatRoomContainer>
      </ChatRoomContainerWrap>
      <UserList>
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "7px",
          }}
        >
          {myChatTab === 1 ||
          (myChatTab === 2 && loggedInUser?.me?.type === "user")
            ? roomList?.seeRoomList //방장
                ?.filter((room) => room?.id === showChatRoom)[0]
                ?.users?.map((user) => {
                  if (
                    user?.id ===
                    roomList?.seeRoomList?.filter(
                      (room) => room?.id === showChatRoom
                    )[0].ownerId
                  ) {
                    return (
                      <UserOne
                        onClick={() =>
                          history.push(routes?.myGym + user?.id, {
                            state: user?.id,
                          })
                        }
                        key={user?.id}
                      >
                        <ProfilePhoto src={user?.profilePhoto} />
                        <div
                          style={{
                            fontSize: "19px",
                            display: "flex",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            alignItems: "flex-start",
                            justifyContent: "start",
                            padding: "2px",
                          }}
                        >
                          {" "}
                          <div>{user?.username}</div>
                        </div>
                        <FontAwesomeIcon icon={faCrown} color="gold" />
                      </UserOne>
                    );
                  }
                })
            : null}
          {myChatTab === 1 ||
          (myChatTab === 2 && loggedInUser?.me?.type === "user")
            ? roomList?.seeRoomList //방장외 : 위와 합치지 않은 이유는 채팅에 연결된 user 들이 id 순서대로 select 가 되어 방장이 첫번째가 아닌경우가 발생했다.
                ?.filter((room) => room?.id === showChatRoom)[0]
                ?.users?.map((user) => {
                  if (
                    user?.id ===
                    roomList?.seeRoomList?.filter(
                      (room) => room?.id === showChatRoom
                    )[0].ownerId
                  ) {
                  } else {
                    return (
                      <UserOne
                        onClick={() =>
                          history.push(routes?.myGym + user?.id, {
                            state: user?.id,
                          })
                        }
                        key={user?.id}
                      >
                        <ProfilePhoto src={user?.profilePhoto} />
                        <FatText
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            padding: "2px",
                          }}
                          fontSize="19px"
                        >
                          {" "}
                          {user?.username}
                        </FatText>
                      </UserOne>
                    );
                  }
                })
            : null}

          {myChatTab === 1 ||
          (myChatTab === 2 && loggedInUser?.me?.type === "user") ? (
            !roomList?.seeRoomList?.filter(
              (room) => room?.id === showChatRoom
            )[0]?.users?.length ? (
              <NoInfo>
                <NoInfoIcon className="bx bx-user-x" />
                <NoInfoText>유저가 존재하지 않습니다.</NoInfoText>
              </NoInfo>
            ) : null
          ) : null}

          {
            myChatTab === 2 && loggedInUser?.me?.type === "trainer"
              ? consultsData?.selectConsults?.map((v) => {
                  if (v?.trainerAccepted === null) {
                    return (
                      <ConsultOne onClick={() => onConsultClick(v)} key={v?.id}>
                        <ProfilePhoto
                          id={v?.trainee?.id}
                          src={v?.trainee?.profilePhoto}
                        />
                        <div className="column">
                          <FatText padding="2px 2px 2px 10px" fontSize="19px">
                            {v?.trainee?.username}
                          </FatText>
                          <ConsultDate>
                            {v.createdAt
                              ? new Date(Number(v.createdAt))
                                  .toJSON()
                                  .split("T")[0]
                              : v.createdAt}
                          </ConsultDate>
                        </div>
                      </ConsultOne>
                    );
                  }
                })
              : null
            // <NoConsult>
            //   <div>상담 요청 목록이 존재하지 않습니다.</div>
            // </NoConsult>
          }
          {myChatTab === 2 &&
          loggedInUser?.me?.type === "trainer" &&
          !consultsData?.selectConsults?.filter(
            (v) => v.trainerAccepted === null || false
          ).length ? (
            <NoInfo>
              <NoInfoIcon className="bi bi-telephone-x" />
              <NoInfoText>1 대 1 채팅요청이 존재하지 않습니다.</NoInfoText>
            </NoInfo>
          ) : null}
        </div>
      </UserList>
      <Modal
        show={cnsModal}
        onHide={() => setCNSModal(false)}
        centered="true"
        size="m"
        className="consultsmodal"
      >
        <ModalCon>
          <UserContainer>
            <ProfilePhoto
              id={modalData?.trainee?.id}
              src={modalData?.trainee?.profilePhoto}
              width="110px"
              height="110px"
            />
            <UserName>{modalData?.trainee?.username}</UserName>
            <Contents>{modalData?.contents}</Contents>
            {modalData?.trainer?.id === loggedInUser?.me?.id ? (
              <div>
                <GButton
                  onClick={() => consultTrue(modalData)}
                  width="100px"
                  padding="10px"
                  context="수락"
                  // margin="0 10px"
                  variant="create"
                />
                <GButton
                  onClick={() => consultFalse(modalData)}
                  width="100px"
                  padding="10px"
                  context="거절"
                  margin="0 10px"
                  variant="delete"
                />
              </div>
            ) : null}
          </UserContainer>
        </ModalCon>
      </Modal>
    </MessageContainer>
  );
}

export default Message;
