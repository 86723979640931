//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/routes";
import PostHeader from "../../Accounts/PostHeader";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../utils/reactiveVar";
import colors from "../../utils/colors";

const PTRoomContainer = styled.div`
  width: 225px;
  //border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0 5px 30px 0 rgb(82 63 105 / 20%);
  background-color: white;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25), 0 2px 3px rgba(0, 0, 0, 0.24);
  .ribbon {
    width: 142px;
    height: 142px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: -10px;
  }
  .ribbon__content {
    left: -25px;
    top: 30px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: #0dcaf0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.192);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    border: 2px dotted #fff;
    outline: 5px solid #0dcaf0;
  }
  .ribbon::before {
    top: 0;
    left: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #70a1ff;
    box-sizing: content-box;
  }
  .ribbon::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #70a1ff;
  }
  &:hover {
    transform: scale(1.08);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.45), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  .accordion {
    .show {
      width: 400px;
      min-height: 40px;
      overflow-y: auto;
      background-color: white;
      border: 0.5px solid lightgrey;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      margin-top: 10px;
    }
    .toggle {
      display: flex;
      border: none;
      background-color: white;
      align-items: center;
      gap: 10px;
    }
  }
`;

const ConHead = styled.div`
  padding: 5px;
  width: 100%;
  display: flex;
`;

const Title = styled.h2`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0 0 0 !important;
  padding: 5px;
  height: 35px;
  line-height: 1rem !important;
  font-size: 14px !important;
  text-align: left;
  /* margin-bottom: 7px; */
  align-content: center;
`;
const TitleCon = styled.div`
  padding: 5px;
  /* margin: 0 10px; */
`;
const CPrice = styled.div`
  font-size: 25px;
  font-weight: bold;
  text-align: left;
`;
const CMonth = styled.div`
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  color: ${colors.bbeego};
  //${colors.bbeego};
  //#9b0000;
  margin-bottom: 6px;
`;

export const Thumbnail = styled.div`
  z-index: 99;
  position: relative;
  height: 225px;
  width: 225px;
  background-image: url(${(props) => (props.bgImg ? props.bgImg : "")});
  background-size: contain;
  background-repeat: no-repeat;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  :before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: rgba(0, 0, 0, 0.3);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

const Seller = styled.div`
  display: grid;
  line-height: 1rem !important;
  /* grid-template-columns: 1fr 4fr; */
  width: 100%;
  padding: 5px;
  margin: 0 auto;
  text-align: center;
`;
const PTRoom = (props) => {
  const users = [];
  const history = useHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  props?.pTPrices.forEach((v) => {
    let userObj = {};
    const priceId = v?.id;
    userObj.id = priceId;
    let customers = [];
    v?.contracts?.forEach((v2) => {
      if (v2?.customer) {
        customers.push(v2?.customer);
      }
    });
    userObj.customers = customers;
    users.push(userObj);
    window.users = users;
  });

  const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));
  const copied = cloneObj(props);

  let priceResult = copied?.pTPrices?.sort(function (a, b) {
    return a?.price - b?.price;
  });

  return (
    <PTRoomContainer key={props?.id} id={props?.id}>
      <Thumbnail
        onClick={() =>
          isLoggedIn
            ? history.push(routes.sale, {
                ...JSON.parse(JSON.stringify(props)),
              })
            : history.push("/login")
        }
        bgImg={props?.thumbnail}
      >
        <div className="ribbon">
          <span className="ribbon__content">pt room</span>
        </div>
        <ConHead></ConHead>
      </Thumbnail>
      <Seller>
        <Title>{props?.title}</Title>
        <div style={{ display: "grid", gridTemplateColumns: "4fr 1fr" }}>
          <TitleCon>
            <CMonth>{priceResult[0]?.month}개월</CMonth>
            <CPrice>
              <strong style={{ fontweight: "1000" }}>
                {priceResult[0]?.price}
              </strong>
              <span style={{ fontSize: "12px" }}>만원</span>
            </CPrice>
          </TitleCon>
          <PostHeader
            font-size="20px"
            user={props?.trainer}
            isMine={props?.isMine}
          ></PostHeader>
        </div>
      </Seller>
    </PTRoomContainer>
  );
};
export default PTRoom;
