//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//멀티 챌린지 인증 사진 컴포넌트

import { useMutation } from "@apollo/client";
import { Card, CardColumns, Image, Modal } from "react-bootstrap";
import styled from "styled-components";
import { DELETE_MCGPROVEFILE_MUTATION } from "../../../queries/multiChallenge/deleteMCGProveFile";
import { GButton } from "../../shared/GButton";

const DelIcon = styled.i`
  font-size: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  background-color: white;
  cursor: pointer;
  &:hover {
    zoom: 1.1;
  }
`;

const MCGProveFile = ({
  MCGProveFile,
  setEditModal,
  setMCGProveFile,
  refetch,
  isFinished, //해당 챌린지가 종료되었는지 여부
  setSelProveFileEditable, //선택한 인증 사진이 수정가능한지
}) => {
  //인증 사진 삭제
  const onDeleteCompleted = (data) => {
    const {
      deleteMCGProveFile: { ok, error },
    } = data;
    if (ok) {
      refetch();
    } else {
      return alert("해당 사진은 수정/삭제 기한이 지나 삭제할 수 없습니다!");
    }
  };
  const [deleteMCGProveFileMutation] = useMutation(
    DELETE_MCGPROVEFILE_MUTATION,
    {
      onCompleted: onDeleteCompleted,
    }
  );
  const onMCGProveFileClick = () => {
    if (!!MCGProveFile?.editable && !isFinished) {
      setSelProveFileEditable(true);
      setEditModal(true);
      setMCGProveFile(MCGProveFile);
    } else {
      setSelProveFileEditable(false);
      setEditModal(true);
      setMCGProveFile(MCGProveFile);
    }
  };
  const onDelIconClick = (e) => {
    e.stopPropagation();
    const confirmBool = window.confirm(
      `${
        new Date(Number(MCGProveFile?.createdAt)).toJSON()?.split("T")?.[0]
      } 인증 사진을 삭제하시겠습니까?`
    );
    if (!!confirmBool) {
      deleteMCGProveFileMutation({
        variables: {
          id: Number(MCGProveFile?.id),
        },
      });
    }
  };
  return (
    <CardColumns style={{ cursor: "pointer" }} onClick={onMCGProveFileClick}>
      <Card>
        <Card.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <CardColumns>
            {!!MCGProveFile?.editable && !isFinished ? (
              <DelIcon
                className="bi bi-x-square-fill"
                onClick={onDelIconClick}
              ></DelIcon>
            ) : null}
            <Image src={MCGProveFile?.filepath} width="133.3px" />
          </CardColumns>
          <CardColumns>
            {
              new Date(Number(MCGProveFile?.createdAt))
                .toJSON()
                ?.split("T")?.[0]
            }
          </CardColumns>
        </Card.Body>
      </Card>
    </CardColumns>
  );
};

export default MCGProveFile;
