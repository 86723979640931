//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../routes/routes";
import colors from "../../utils/colors";
import { isLoggedInVar } from "../../utils/reactiveVar";

const FooterInteraction = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  return (
    <footer id="footer" style={{ marginTop: "100px" }}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>BBEEGO</h3>
              <p>
                주재넷(주)
                <br />
                서울특별시 송파구 법원로 90, 502호
                <br />
                (문정동, 파트너스 2) 대표 : 박종호
                <br />
                개인정보관리책임자 : 박종호
                <br />
                사업자 등록번호 : 220-88-10833
                <a
                  href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208810833"
                  style={{
                    border: "1px solid lightgray",
                    padding: "2px",
                    borderRadius: "3px",
                  }}
                  target="_blank"
                >
                  <span>사업자 확인</span>
                </a>
                <br />
                통신판매업 신고번호 : 2021-서울송파-4119
                <br />
                사무실전화 : 02-2043-9112
                <br />
                이메일주소 : contact@bbeego.com
                <br />
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>사용자링크</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to={routes.home}>Home</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to={{ pathname: routes.about }}>About us</Link>
                </li>
                {/* <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="#">다이어트</a>
                </li> */}
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link
                    to={{
                      pathname: routes.terms,
                      state: { defaultActiveKey: "term" },
                    }}
                  >
                    Terms of service
                  </Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link
                    to={{
                      pathname: routes.terms,
                      state: { defaultActiveKey: "privacyPolicy" },
                    }}
                  >
                    개인정보 보호정책
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>서비스</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <Link to={{ pathname: routes.multiChallengeList }}>
                    챌린지
                  </Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to={{ pathname: routes.pTRooms }}>
                    전문트레이너등록
                  </Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  {/* <a href="#chatroom">트레이너 채팅상담</a> */}
                  <Link to={routes.consulting}>트레이너 채팅상담</Link>
                </li>
                {/* <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">공지</a>
                </li> */}
                {/* <li>
                  <i className="bx bx-chevron-right"></i> <a href="#">이벤트</a>
                </li> */}
              </ul>
            </div>
            <div
              className="col-lg-12 col-md-6 footer-links"
              style={{
                textAlign: "center",
                lineHeight: "20px",
                color: "gray",
              }}
            >
              <p>
                주재넷(주)는 통신판매중개자이며, 통신판매의 당사자가 아닙니다.
                상품, 상품정보, 거래에 관한 의무와 책임은 판매회원에게 있습니다.
              </p>
              <p>
                주재넷(주) 사이트의 상품/판매회원/중개 서비스/거래 정보, 콘텐츠,
                UI 등에 대한 무단복제, 전송, 배포, 스크래핑 등의 행위는
                저작권법, 콘텐츠산업 진흥법 등 관련법령에 의하여 엄격히
                금지됩니다.
              </p>
            </div>

            {/* <h4>BBEEGO 뉴스레터신청</h4>
        <p>
          이메일을 등록하여 매월 포스트를 구독하여 주시면 새로운소식을
          보내드립니다.
        </p>
        <form action="" method="post">
          <input type="email" name="email" />
          <input type="submit" value="Subscribe" />
        </form> */}
          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <div style={{ margin: "0 auto" }} className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>JJNET Co.,Ltd.</span>
            </strong>{" "}
            All Rights Reserved
          </div>
        </div>
        {/* 추후 연결 */}
        {/* <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="#" className="twitter">
            <i className="bx bxl-twitter"></i>
          </a>
          <a href="#" className="facebook">
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="#" className="instagram">
            <i className="bx bxl-instagram"></i>
          </a>
          <a href="#" className="google-plus">
            <i className="bx bxl-skype"></i>
          </a>
          <a href="#" className="linkedin">
            <i className="bx bxl-linkedin"></i>
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default FooterInteraction;
