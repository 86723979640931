//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useCallback, useEffect, useRef, useState, lazy } from "react";
import { useForm } from "react-hook-form";

import {
  useMutation,
  useQuery,
  useSubscription,
  gql,
  useLazyQuery,
} from "@apollo/client";
import { Button as BButton, CardColumns, Modal } from "react-bootstrap";
import DOMPurify from "dompurify";
import { saveAs } from "file-saver";
import styled from "styled-components";
import colors from "../../utils/colors";
import ImageCrop from "../shared/ImageCrop";
import { GButton } from "../shared/GButton";
import IsDropZone from "../shared/isDropZone";
import { useHistory } from "react-router-dom";
import ProfilePhoto from "../shared/ProfilePhoto";
import CheckUserToken from "../../utils/checkUserToken";
import imageCompression from "browser-image-compression";
import { OPEN_ROOM } from "../../queries/message/openRoom";
import { SEND_IMAGE } from "../../queries/message/sendImage";
import { SEE_MESSAGES } from "../../queries/message/seeMessages";
import { SEND_MESSAGE } from "../../queries/message/sendMessage";
import { CHECK_MESSAGE_MUTATION } from "../../queries/message/checkMessage";
import { UPDATE_ISDOWNLOAD_MUTATION } from "../../queries/message/updateIsDownload";
import { dateConvertToCurrentTime } from "../../utils/date/dateConvertToCurrentTime";
import { ADD_DOWNLOAD_COUNT_MUTATION } from "../../queries/message/addDownloadCount";
import FileList from "./FileList";
import client from "../../client";
import DropDownModal from "../shared/DropDownModal";
import ReplyMessage from "./ReplyMessage";
import TypeUrl from "./messageMaker/TypeUrl";
import TypeMsg from "./messageMaker/TypeMsg";
import TypeImage from "./messageMaker/TypeImage";
import CheckExt from "../../utils/checkExt";
import TypeFile from "./messageMaker/TypeFile";
import MessageList from "./messageMaker/MessageList";
import { MsgContent } from "../../utils/msgStyles";
import { useInView } from "react-intersection-observer";
import { Cache } from "@apollo/client/cache";

const ModuleContainer = styled.div`
  width: inherit;
  height: 100%;
  display: grid;
  position: relative;

  grid-template-rows: ${(props) =>
    props.isReply ? "calc(100% - 180px) auto" : "calc(100% - 120px) auto"};
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  /* background-color: rgba(206, 17, 38, 0.05); 기존 */
  background-color: #eeeef0;
`;
const Section = styled.div`
  transform: rotate(0);
  width: 100%;
  height: 100%;
  border: 1px solid rgb(215, 215, 215);
  border-bottom: none;
`;
const TextField = styled.div`
  width: 100%;
  height: inherit;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #636e72;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* background-color: rgba(206, 17, 38, 0.05); */
  background-color: #e6e6e8;
`;
const TextInput = styled.textarea`
  width: 100%;
  height: 80px;
  line-height: 1.5;
  resize: none;
  outline: none;
  border: none;
`;
const SendCon = styled.div`
  display: flex;
  flex-direction: column;
  /* border-top: 2px solid rgb(230, 229, 239); */
`;

const SendButtons = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 5px;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;

  /* padding-top: 3px;
  padding-right: 5px; */
  /* padding: 5px; */
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
`;
const ButtonTag = styled.button`
  color: white;
  font-weight: bold;
  width: 60px;
  /* margin: 0 2px 0 2px; */
  font-size: 13px;
  /* height: 30px; */
  padding: 0.2rem 0.625rem;
  letter-spacing: 2px;
  background-color: ${colors.bbeego};
  &:hover {
    color: white;
  }
`;
const IconDiv = styled.div`
  i {
    font-size: 30px;
  }
  label {
    font-size: 30px;
  }
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

const DateCon = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const DateTitle = styled.div`
  width: 25%;
  margin: 60px 0 40px 0;
  padding: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  border-radius: 20px;
  color: ${colors.bbeego};
  /* background-color: #ccc;
  border-radius: 20px; */
`;

const DateP = styled.p`
  font-size: 10px;
  padding-bottom: 3px;
  white-space: nowrap;
`;

const ZipCon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MsgContainer = styled.div`
  width: 150%;
  ${(props) =>
    props?.isMine
      ? `
  display: flex;
  flex-direction:row;
  align-items: flex-end;
  justify-content: end;
  `
      : `display: flex;
  flex-direction:row;
  align-items: flex-end;
  justify-content: start;
  `};
  margin-top: 5px;
  gap: 5px;
`;
const DownloadCnt = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
`;

//original image preview

const ImageCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
`;

const ImageHeader = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  background-color: white;
`;

const ImageFooter = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  font-size: 20px;
  justify-content: flex-end;
  background-color: white;
`;

const ImageDownload = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #5f6368;
  cursor: pointer;
  height: 40px;
  margin-top: 4px;
  padding: 10px;
  width: 40px;
  text-align: center;
  border-radius: 50%;
  /* margin: auto; */
  &:hover {
    border-radius: 50%;
    background-color: rgb(250, 250, 252);
  }
  &:active {
    background-color: #c1c1c1;
  }
`;

const PreViewCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700px;
  min-width: 900px;
`;

const ImagePreView = styled.img`
  width: 60%;
  height: 60%;
  max-width: 1100px;
  max-height: 800px;
  object-fit: contain;
  margin: ${(props) => (props?.margin ? props?.margin : "80px 0")};
  transform: rotate(
    ${(props) => (props.rotate ? props.rotate + "deg" : "0deg")}
  );
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const MsgBlur = styled.div`
  position: absolute;
  z-index: 104;
  width: 100%;
  height: 100%;
`;

const ChatroomModule = ({ id, isBlock = false }) => {
  let roomId = Number(id);
  console.log("roomId : ", roomId);
  //
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [zFile, setZFile] = useState(null);
  const [filepath, setFilePath] = useState(null);
  const [orginFile, setOriginFile] = useState(null);

  //모달
  const [modal, setModal] = useState(false);
  const [zipModal, setZipModal] = useState(false);
  const [imgModal, setImgModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rotateImg, setRotateImg] = useState(0);
  // 파일 목록

  const [fileList, setFileList] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [noFetch, setNoFetch] = useState(false);
  const [msgModal, setMsgModal] = useState(false);
  const [msgInfo, setMsgInfo] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [replyMsg, setReplyMsg] = useState(null);
  const [iReply, setIReply] = useState(false);

  const {
    ref: infiniteRef,
    inView,
    entry,
  } = useInView({
    /* Optional options */
    threshold: 0,
  });

  //
  const me = CheckUserToken();
  const textField = useRef();
  const imgRef = useRef();
  const dropDownRef = useRef();

  const [getSeeMsgs, { data: mdata, loading: mLoading, fetchMore }] =
    useLazyQuery(SEE_MESSAGES);

  useEffect(() => {
    /*
     * roomId가 바뀔때 마다 새로운 데이터를 불러옴
     */

    getSeeMsgs({ variables: { roomId, take: 30 } });
  }, [roomId]);

  const seeMessage = mdata?.seeMessages;
  console.log(`seeMessage : ${seeMessage}`);

  const clickNotiOutside = (e) => {
    if (
      msgModal &&
      !dropDownRef?.current?.contains(e.target) &&
      !Array.from(e.target.classList)?.includes("mouseoutException")
    ) {
      setMsgModal(false);
    }
  };

  const getReplyMsg = (msginfo) => {
    if (msgInfo != null) {
      setMsgModal(false);
      setReplyMsg(msgInfo);
      setIsReply(true);
      // input에 focus주기
      let input = document.getElementsByClassName("textInput");
      input[0].focus();
    }
  };

  useEffect(() => {
    if (!inView) {
      return;
    }
    if (!mLoading) {
      fetchMoreData();
    }
  }, [inView]);

  useEffect(() => {
    document.addEventListener("mousedown", clickNotiOutside);

    return () => {
      document.removeEventListener("mousedown", clickNotiOutside);
    };
  });

  // 메시지 전송시 스크롤을 최하단으로 보내기 위한 함수
  useEffect(() => {
    if (mdata?.seeMessages[mdata?.seeMessages?.length - 1]?.isMine && iReply) {
      //
      textField?.current?.scrollTo(0, textField?.current?.scrollHeight);
    }
    console.log("hi");
    return () => {
      setIReply(false);
    };
  }, [mdata]);

  useEffect(() => {
    if (seeMessage?.length == 30) {
      textField?.current?.scrollTo(0, textField?.current?.scrollHeight);
    }
    console.log("hi1");
  }, [seeMessage]);
  // 메세지 생성 날짜 기준으로 묵기

  // infinity scroll
  const fetchMoreData = async () => {
    // setScroll(true);
    let lastId = seeMessage?.[0]?.id;

    // if (!noFetch && lastId && fetchMore) {
    if (!mLoading) {
      let lastDom = document.getElementById(`msg_${seeMessage[0]?.id}`);
      let fetchData = fetchMore({
        variables: {
          lastId: lastId,
          take: 30,
          roomId: id,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult.seeMessages?.length) {
            setNoFetch(true);
            return prev;
          }

          return {
            ...prev,
            seeMessages: [
              ...fetchMoreResult?.seeMessages,
              ...prev?.seeMessages,
            ],
          };
        },
      });
      fetchData?.then((v) => {
        if (lastDom) {
          lastDom?.scrollIntoView({ block: "end" });
        }
      });
      // }
    }
    // setScroll(false);
  };

  const openDropDown = (e, msg) => {
    if (!isReply) {
      const msgObj = new Object();
      const msgX = e.clientX;
      const msgY = e.clientY;
      msgObj.x = msgX;
      msgObj.y = msgY;
      msgObj.msg = msg;
      setMsgModal(true);
      setMsgInfo(msgObj);
    }
  };

  //원본사진 보기
  const originalPreview = (msgObj) => {
    setImgModal(true);
    setFilePath(msgObj);
  };

  //이미지 돌리기
  const onRotateImg = () => {
    if (rotateImg < 359) {
      let a = rotateImg + 90;
      setRotateImg(a);
    } else {
      setRotateImg(90);
    }
  };

  const onFileClick = (filepath, obj) => {
    downloadClick(filepath);
    addDownLoadCount(obj);
  };

  const downloadClick = useCallback((e) => {
    const blob = e;
    const filename = e.split("/static/")[1];
    // const date = new Date().toJSON();
    saveAs(blob, `${filename}`);
  }, []);

  const { register, getValues, setValue } = useForm();
  const [checkMessageMutation] = useMutation(CHECK_MESSAGE_MUTATION);

  const { data, error } = useSubscription(OPEN_ROOM, {
    variables: {
      id,
    },
    context: {
      token: localStorage.getItem("token"),
    },

    onSubscriptionData: async (data) => {
      checkMessageMutation({
        variables: {
          roomId: id,
        },
      });

      const isMine =
        me?.me?.id == data?.subscriptionData?.data?.roomUpdates.user?.id
          ? true
          : false;

      const {
        client: { cache },
        subscriptionData: {
          data: { roomUpdates: message },
        },
      } = data;

      message.__typename = "Message";
      // message.repliedMessage = null;

      if (message?.deletedAt == null) {
        const newMessage = {
          __ref: `Message:${message?.id}`,
        };

        if (roomId == message?.chatRoom?.id) {
          const beforeMsgs = await client.cache.readQuery({
            query: SEE_MESSAGES,
            variables: {
              roomId: id,
              take: 30,
            },
          });
          const data = {
            seeMessages: [...beforeMsgs.seeMessages, message],
          };
          if (message?.chatRoom?.id == id && data?.seeMessages?.length) {
            //
            const writeCache = client.cache.writeQuery({
              query: SEE_MESSAGES,
              variables: {
                roomId,
                take: 30,
              },
              data,
              broadcast: false,
            });
            if (writeCache && isMine) {
              setIReply(true);
            }
          }
          // client.cache.modify({
          //   id: `ROOT_QUERY`,
          //   fields: {
          //     seeMessages(prev, { storeFieldName }) {
          //       //
          //       let r_id = JSON.parse(
          //         storeFieldName.replace("seeMessages(", "").replace(")", "")
          //       )?.roomId;
          //       if (r_id == roomId) {
          //         return [...prev, newMessage];
          //       }
          //       //
          //     },
          //   },
          //   // broadcast: false,
          // });
        }
        // client.cache.modify({
        //   id: `seeMessage:${message?.chatRoom?.id}`,
        //   fields: {
        //     messages(prev) {
        //
        //       return [...prev, newMessage];
        //     },
        //   },
        // });
        // client.cache.gc();

        // }

        // 메세지 삭제시
        else {
          client.cache.writeFragment({
            id: "Message:" + String(message?.id),
            fragment: gql`
              fragment MyMessage on Message {
                deletedAt
              }
            `,
            data: {
              deletedAt: String(new Date().toJSON()),
            },
          });
        }
      }
    },
  });

  const [updateIsDownloadMutation] = useMutation(UPDATE_ISDOWNLOAD_MUTATION);

  const onZipClick = async (msg, id) => {
    // const newMessage = await mdata?.refetch();
    // const data = newMessage?.data?.seeMessages?.filter((v) => v.id == id);
    // if (data[0]?.isDownloaded === false) {
    //   updateIsDownloadMutation({
    //     variables: {
    //       id: id,
    //     },
    //   });
    window.location.href = `${msg}`;
    // } else {
    //   alert("이미 다운받은 파일이거나 다른 유저가 다운 파일입니다.");
    // }
  };

  const onCompleted = (data) => {
    setValue("message", "");
    // textField?.current?.scrollTo(0, textField?.current?.scrollHeight);
  };

  const [sendMessageMutation] = useMutation(SEND_MESSAGE, {
    onCompleted,
  });

  const onSendClick = () => {
    const { message } = getValues();
    if (!!message.trim()) {
      if (replyMsg != null && isReply) {
        const msgId = replyMsg?.msg?.id;
        sendMessageMutation({
          variables: {
            content: message,
            roomId: id,
            isReply: msgId,
          },
        });
        setIsReply(false);
      } else {
        sendMessageMutation({
          variables: {
            content: message,
            roomId: id,
          },
        });
      }
    }
    setValue("message", "");
  };

  // useEffect(() => {
  //   textField?.current?.scrollTo(0, textField?.current?.scrollHeight);
  // });

  const onMsgEnterPressed = (param) => {
    if (param.which == 13 && !param.shiftKey) {
      onSendClick();
    }
  };

  const getData = async (file) => {
    let zipRegex = new RegExp("image/[a-zA-Z]+", "g");
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 300,
    };
    if (file[0].type.match(zipRegex)) {
      const compressedFile = await imageCompression(file[0], options);
      setZFile(compressedFile);
      setOriginFile(file[0]);
    } else {
      setZFile(file[0]);
      setOriginFile(file[0]);
    }
  };

  //download 수 증가 mutation
  const addDownloadCountCompleted = (data) => {};
  const [addDownloadCountMutation] = useMutation(ADD_DOWNLOAD_COUNT_MUTATION, {
    onCompleted: addDownloadCountCompleted,
  });

  const addDownLoadCount = async ({ id }) => {
    addDownloadCountMutation({
      variables: {
        msgId: Number(id),
      },
    });
  };

  const delMsgMaker = (msgObj) => {
    const { isMine, createdAt } = msgObj;
    const sanitizer = DOMPurify.sanitize;
    if (isMine) {
      return (
        <MsgContainer isMine={isMine}>
          <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
          <MsgContent
            isMine={isMine}
            dangerouslySetInnerHTML={{
              __html: sanitizer("삭제된 메세지입니다."),
            }}
          ></MsgContent>
        </MsgContainer>
      );
    } else if (!isMine) {
      return (
        <MsgContainer isMine={isMine}>
          <MsgContent
            isMine={isMine}
            dangerouslySetInnerHTML={{
              __html: sanitizer("삭제된 메세지입니다."),
            }}
          ></MsgContent>
          <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
        </MsgContainer>
      );
    }
  };

  const msgMaker = (msgObj) => {
    const { content: msg, isMine, createdAt, user, repliedMessage } = msgObj;

    //youtube link detect
    let youtubeUrlRegex = new RegExp(
      "(https://www.youtube.com/|https://youtu.be/)[a-zA-Z0-9?=_-]+",
      "g"
    );
    const isUrl = msg.match(youtubeUrlRegex);

    let fileRegex = msg.match(process.env.REACT_APP_SERVER_URL);

    if (fileRegex?.length > 0) {
      const file = CheckExt(msg);
      if (file?.type == "image") {
        return (
          <TypeImage
            isMine={isMine}
            createdAt={createdAt}
            msgObj={msgObj}
            originalPreview={originalPreview}
            msg={msg}
          />
        );
      } else if (file?.type == "file") {
        return (
          <TypeFile
            isMine={isMine}
            createdAt={createdAt}
            file={file}
            msg={msg}
            msgObj={msgObj}
            onFileClick={onFileClick}
          />
        );
      }
    } else if (isUrl?.length) {
      return <TypeUrl isUrl={isUrl} isMine={isMine} createdAt={createdAt} />;
    } else {
      return (
        <TypeMsg
          isMine={isMine}
          msg={msg}
          createdAt={createdAt}
          repliedMessage={repliedMessage}
          user={user}
        />
      );
    }
  };

  const onImageSendCompleted = (data) => {
    setFile(null);
    setModal(false);
    setZFile(null);
    setZipModal(false);
    setLoading(false);
  };

  const [SendImage] = useMutation(SEND_IMAGE, {
    onCompleted: onImageSendCompleted,
  });

  const onImageSendClick = () => {
    if (!!file) {
      SendImage({
        variables: {
          roomId: id,
          file,
        },
      });
    }
  };
  const onFileSendClick = () => {
    setLoading(true);
    if (!!zFile) {
      SendImage({
        variables: {
          roomId: id,
          file: zFile,
          originalFile: orginFile,
        },
      });
    }
  };

  return (
    <ModuleContainer
      isReply={isReply}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      {msgModal ? <MsgBlur /> : null}
      <Section>
        <TextField ref={textField}>
          {seeMessage?.length > 0 ? (
            <div
              style={{
                display: "list-item",
                width: "100%",
                height: "20px",
                padding: "30px",
                backgroundColor: "rgba(0,0,0,0)",
              }}
              ref={infiniteRef}
            ></div>
          ) : null}
          <MessageList
            delMsgMaker={delMsgMaker}
            msgMaker={msgMaker}
            openDropDown={openDropDown}
            seeMessage={seeMessage}
          />
        </TextField>
      </Section>
      {!!isBlock ? (
        <CardColumns
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "gray",
          }}
        >
          더 이상 채팅을 할 수 없습니다.
        </CardColumns>
      ) : (
        <Section>
          {isReply ? (
            <ReplyMessage replyMsg={replyMsg} setIsReply={setIsReply} />
          ) : null}
          <SendCon>
            <TextInput
              className="textInput"
              {...register("message")}
              onKeyPress={onMsgEnterPressed}
              maxLength={450}
            ></TextInput>
            <SendButtons>
              {/* <IconDiv className="icon" onClick={() => setModal(true)}>
                <i className="bi bi-file-image-fill" />
              </IconDiv> */}
              <div style={{ display: "flex", width: "100%" }}>
                <IconDiv className="icon" onClick={() => setFileList(true)}>
                  <i className="ri-folder-download-fill" />
                </IconDiv>
                {!isReply ? (
                  <IconDiv className="icon" onClick={() => setZipModal(true)}>
                    <i className="ri-file-add-fill" />
                  </IconDiv>
                ) : null}
              </div>
              {/* <IconDiv className="icon" onClick={() => setaModal(true)}>
              <i className="bi bi-file-earmark-person-fill" />
            </IconDiv> */}
              <div>
                <ButtonTag className="btn" onClick={onSendClick}>
                  전송
                </ButtonTag>
              </div>
            </SendButtons>
          </SendCon>
        </Section>
      )}
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
          setFile(null);
        }}
      >
        <Modal.Body
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <ImageCrop file={file} setFile={setFile} />
          <ButtonTag className="btn " onClick={onImageSendClick}>
            사진 전송
          </ButtonTag>
        </Modal.Body>
      </Modal>

      {/* zipModal */}

      <Modal
        centered="true"
        size="sm"
        show={zipModal}
        onHide={() => {
          setZipModal(false);
          setZFile(null);
        }}
      >
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ZipCon>
            <IsDropZone loading={loading} zFile={zFile} getData={getData} />
          </ZipCon>

          <GButton
            variant="create"
            width="100%"
            onClick={onFileSendClick}
            context="파일 전송"
            disabled={!zFile || loading ? true : false}
          />
        </Modal.Body>
      </Modal>
      <Modal
        centered="true"
        size="xl"
        show={imgModal}
        onHide={() => {
          setImgModal(false);
          setRotateImg(0);
        }}
      >
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            padding: "0",
          }}
        >
          <ImageCon ref={imgRef}>
            <ImageHeader>
              <span>{filepath?.user?.username}</span>
              <span>
                {new Date(Number(filepath?.createdAt))?.toJSON()?.split("T")[0]}
              </span>
            </ImageHeader>
            <PreViewCon>
              <ImagePreView
                margin
                rotate={rotateImg}
                src={filepath?.originalFilePath}
              />
            </PreViewCon>
            <ImageFooter>
              {/* 사진 돌리기 */}
              <ImageDownload
                onClick={() => onRotateImg()}
                style={{ fontSize: "20px" }}
                className="bi bi-arrow-clockwise"
              />
              {/* 다운로드 */}
              <ImageDownload
                onClick={() => downloadClick(filepath?.originalFilePath)}
                style={{ fontSize: "20px" }}
                className="bi bi-download"
              />
            </ImageFooter>
          </ImageCon>
        </Modal.Body>
      </Modal>
      <Modal
        show={fileList}
        onHide={() => {
          setFileList(false);
        }}
      >
        <Modal.Body
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <FileList id={roomId} />
        </Modal.Body>
      </Modal>
      {/* 아바타 */}
      {/* MsgModal */}
      <DropDownModal
        className="mouseoutException"
        ref={dropDownRef}
        msgModal={msgModal}
        msgInfo={msgInfo}
        setMsgModal={setMsgModal}
        getReplyMsg={getReplyMsg}
      ></DropDownModal>
    </ModuleContainer>
  );
};

export default ChatroomModule;
