//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//프로필 편짐에서 계정 탭을 클릭시 표시할 컴포넌트 입니다.

import { useMutation } from "@apollo/client";
import { CardColumns } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GButton } from "../component/shared/GButton";
import { DELETE_USER_MUTATION } from "../queries/user/deleteUser";
import CheckUserToken from "../utils/checkUserToken";

const Container = styled.div`
  min-height: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AccountInfo = () => {
  const history = useHistory();
  const me = CheckUserToken();
  const onDeleteCompleted = (data) => {
    const {
      deleteUser: { ok },
    } = data;
    if (ok) {
      history.push("/");
    }
  };
  const [deleteUserMutation] = useMutation(DELETE_USER_MUTATION, {
    onCompleted: onDeleteCompleted,
  });

  const onDeleteAccount = () => {
    const confirmBool = window.confirm("계정을 정말로 삭제하시겠습니까?");
    if (!!confirmBool) {
      deleteUserMutation({
        variables: {
          id: me?.me?.id,
        },
      });
    }
  };
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <CardColumns>
        <CardColumns
          style={{
            width: "100%",
            fontSize: "20px",
            fontWeight: "bold",
            color: "rgb(99, 110, 114)",
            textAlign: "center",
          }}
        >
          <span>계정</span>
        </CardColumns>
      </CardColumns>
      <CardColumns>
        <p style={{ color: "black", fontSize: "14px", lineHeight: "20px" }}>
          계정 삭제 시 진행중인 상품에 대한 접근이 불가능하여 복구 또한 불가능
          합니다.
        </p>
        <p
          style={{
            color: "black",
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          유저님의 개인 정보와 구매하신 상품 내역은 최대 5년까지 보관하며 이후
          폐기됩니다.
        </p>
      </CardColumns>
      <CardColumns>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GButton
            variant="delete"
            context="계정 삭제"
            margin="auto"
            width="125px"
            onClick={() => onDeleteAccount()}
          />
        </div>
      </CardColumns>
    </Container>
  );
};

export default AccountInfo;
