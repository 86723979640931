import styled from "styled-components";

const Empty = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "30px")};
`;

export const EmptyDiv = (props) => {
  return <Empty {...props} />;
};
