//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import PTRoom from "../component/PTroom/PTRoom";
import { SeeMyPTRooms } from "../queries/PTRoom/seeMyPTRoom";
import { routes } from "../routes/routes";
import { RegisterList } from "../component/contract/RegisterList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faBox,
  faEllipsisH,
  faExclamationCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import {
  isMyGymVar,
  isSearchBoxOpenVar,
  myGymTabVar,
} from "../utils/reactiveVar";
import { SeeMyGym } from "../queries/user/seeMyGym";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import Progress from "../component/scan/Progress";
import { Container, Modal } from "react-bootstrap";
import { DELETE_POST } from "../queries/post/deletePost";
import { SEE_MYGYM } from "./../queries/user/seeMyGym";
import trainerCert from "../static/trainerCert.png";
import { DELETE_PTROOM } from "../queries/PTRoom/deletePTRoom";
import { GButton } from "../component/shared/GButton";
import MultiChallengeManager from "../component/multiChallenge/MultiChallengeManager";
import colors from "../utils/colors";
import CheckUserToken from "../utils/checkUserToken";
import { CREATE_CONSULT_MUTATION } from "../queries/consults/createConsults";
import { SELECT_CONSULTS_QUERY } from "../queries/consults/selectConsults";
import { BLOCK_USER_MUTATION } from "../queries/user/blockUser";
import Spinner from "../component/shared/Spinner";

const Header = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
  padding: 20px;
  gap: 70px;
  border-radius: 10px;
  /* margin-bottom: 20px; */
  /* box-shadow: 0 5px 30px 0 rgb(82 63 105 / 20%); */
  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
  }
`;
const ProfilePhotoCon = styled.div`
  margin-left: 20px;
  position: relative;
  width: fit-content;
`;
const ProfileDesCon = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-right: 0;
  width: 100%;
  //margin-left: 80px;
  font-size: 20px;
  gap: 20px;
  @media (max-width: 600px) {
    margin-left: 0;
    img {
      width: 100px;
      height: 100px;
    }
  }
`;

const Username = styled.span`
  font-size: 27px;
  font-weight: 600;
`;

const Description = styled.span`
  font-size: 16px;
`;
const UsernameCon = styled.div`
  width: 100%;
  display: flex;
`;
export const Button = styled.button`
  border-radius: 5px;
  font-size: 13px;
  border-color: #f9fafb;
  background-color: #f9fafb;
  padding: 5px 10px;
  margin-left: 15px;
  ${(props) =>
    props.variant === "danger"
      ? `
    color:white;
    background-color:#dc3545;
    border-color:#dc3545;
  `
      : ""}
  ${(props) =>
    props.variant === "secondary"
      ? `
    color:white;
    background-color:#6c757d;
    border-color:#6c757d;
  `
      : ""}
        ${(props) =>
    props.variant === "primary"
      ? `
    color:white;
    background-color:#0d6efd;
    border-color:#0d6efd;
  `
      : ""}
  &:active {
    opacity: 0.8;
  }
`;
const Gym = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 550px;
  margin-top: 20px;
  background-color: white;
  /* border-radius: 15px;
  box-shadow: 0 5px 30px 0 rgb(82 63 105 / 20%); */
`;
const Tabs = styled.div`
  border-bottom: 2px solid ${colors.defaultGray};
  display: flex;
  width: 100%;

  justify-content: center;
  gap: 30px;
  text-align: center;
  margin-bottom: 10px;
`;
const Tab = styled.div`
  /* width: 100%; */
  margin: -2px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.checked
      ? `2px solid ${colors.bbeego}`
      : `2px solid ${colors.defaultGray}`};
  transition: border-bottom 0.3s;
  @media (max-width: 390px) {
    font-size: 15px;
    padding: 5px 10px;
  }
`;
const PostTab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NewPostLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
`;
const Posts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 40px;
`;

const PTRoomTab = styled.div`
  background-color: rgb(250, 250, 252);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* min-height: 220px; */
  padding: 5px;
`;
const NewPTRoomLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;
const MyPTRooms = styled.div`
  margin-top: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 770px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SubTabContext = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Post = styled.div``;
const Image = styled.img``;
export const Interact = styled.div`
  position: fixed;
  top: 20%;
  left: calc(50%-300px);
  width: 300px;
  height: 400px;
  background-color: white;
  overflow-wrap: break-word;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  //display: ${(props) => (props.displayBool ? "" : "none")};
  box-shadow: 0px 0px 5px -1px gray;
`;
export const InteractHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const CloseBtn = styled.button`
  border: none;
  background-color: white;
  color: gray;
  font-weight: bold;
  font-size: 18px;
  &:hover {
    color: black;
  }
  margin-left: auto;
`;
export const InteractContent = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
`;
export const InteractBtn = styled.button`
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid lightgray;
  padding: 10px;
  background-color: white;
  font-size: 16px;
`;

export const FirstMessage = styled.div`
  display: ${(props) => (props.displayBool ? "flex" : "none")};
  border: 1px solid gray;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  position: absolute;
  top: 0px;
  left: 30px;
  width: 300px;
  justify-content: space-between;
`;
export const MessageInput = styled.input`
  font-size: 16px;
  width: 80%;
  outline: none;
  border: 1px solid lightgray;
`;
const PostHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const IconSpan = styled.span`
  position: absolute;
  z-index: 999999999;
  transition: 0.3s;
  top: ${(props) => (props.top ? props.top : "")};
  right: ${(props) => (props.right ? props.right : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : "gray")};
  &:hover {
    color: ${(props) => (props.hColor ? props.hColor : "#57606f")};
  }
`;
const PostBody = styled.div`
  height: 220px;
  display: flex;
  align-items: center;
  background-color: black;
`;
const TrainerCert = styled.div`
  position: absolute;
  top: -10px;
  right: 0px;
`;

const CNSTitle = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  /* color: #ed5f00; */
  margin: 15px 0;
`;
const CNSContents = styled.div`
  width: 100%;
  display: grid;
  padding: 10px;
  grid-template-columns: 2.4fr 7.6fr;
`;
const ConTitle = styled.div`
  padding: 7px;
  font-weight: 600;
  /* color: #ed5f00; */
  font-size: 16px;
`;
const ConValue = styled.textarea`
  min-height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline-color: #ccc;
`;
const MCMCon = styled.div`
  width: 100%;
`;
const IconText1 = styled.span`
  @media (max-width: 770px) {
    &:after {
      content: "";
    }
  }
  @media (min-width: 771px) {
    &:after {
      content: "프로필 편집";
    }
  }
`;
const IconText2 = styled.span`
  @media (max-width: 770px) {
    &:after {
      content: "";
    }
  }
  @media (min-width: 771px) {
    &:after {
      content: "쿠폰함";
    }
  }
`;

export const Title = styled.h2`
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
`;

const MyGym = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  isSearchBoxOpenVar(false);
  const params = useParams();
  const tab_2 = useRef();
  const tab_3 = useRef();
  const tab_4 = useRef();
  const tab_6 = useRef();
  const me = CheckUserToken();
  const [postModal, setPostModal] = useState(false);
  const [postModalData, setPostModalData] = useState("");
  const [pTRoomModal, setPTRoomModal] = useState(false);
  const [pTRoomModalData, setPTRoomModalData] = useState("");
  const [consultingModal, setconsultingModal] = useState(false);
  const [cnsExstn, setCnsExstn] = useState(false);
  const [cnsText, setCNSText] = useState("");

  //차단 여부
  const [isBlocked, setIsBlocked] = useState(false);
  const history = useHistory();
  const _tabs = useRef();
  const myGymTab = useReactiveVar(myGymTabVar);
  const [subTab, setSubTab] = useState(3);
  const consultsData = useQuery(SELECT_CONSULTS_QUERY)?.data;
  const data = SeeMyGym(Number(params?.id));
  const myPTRoom = data?.seeMyGym?.pTRoom;
  const { data: myPTRooms, loading: pLoading } = SeeMyPTRooms(
    Number(params?.id)
  );
  const myPTRoomsIds = [];

  //consult 확인

  useEffect(() => {
    consultsData?.selectConsults?.filter((cns) => {
      if (!!cns) {
        if (
          data?.seeMyGym?.id === cns?.trainer?.id &&
          me?.me?.id === cns?.trainee?.id
        ) {
          if (cns?.trainerAccepted === false) {
            setCnsExstn(true);
          } else {
            setCnsExstn(false);
          }
        } else if (
          data?.seeMyGym?.id === cns?.trainer?.id &&
          !me?.me?.id === cns?.trainee?.id
        ) {
          setCnsExstn(true);
        } else {
          setCnsExstn(true);
        }
      } else {
        setCnsExstn(true);
      }
    });
    if (!consultsData?.selectConsults?.length) {
      setCnsExstn(true);
    }
  }, [consultsData, data]);

  const onTabClick = (tabNo) => {
    myGymTabVar(tabNo);
    setSubTab(1);
  };

  myPTRooms?.seePTRooms.forEach((v) => {
    const myPTRoomsId = {};
    myPTRoomsId.PTRoomId = v.id;
    myPTRoomsIds.push(myPTRoomsId);
  });
  //상담 요청 보내기
  const onCNSSubmit = () => {
    if (cnsExstn) {
      var result = window.confirm("1대1을 요청합니다.");
      if (result) {
        createConsultMutation({
          variables: {
            trainerId: Number(data?.seeMyGym?.id),
            contents: cnsText,
          },
        });
      }
    } else {
      alert("기존 1대1 요청이 존재합니다.");
      setconsultingModal(false);
    }
  };

  //신고하기
  const onReportClick = () => {
    history.push(routes?.report, {
      id: data?.seeMyGym?.id,
      username: data?.seeMyGym?.username,
    });
  };

  const onConsulting = (e) => {
    setCNSText(e.target.value);
  };

  const showPostModal = (postId, isMine, assets) => {
    setPostModal(true);
    setPostModalData({ postId, isMine, assets });
  };

  const [postDeleteMutation] = useMutation(DELETE_POST, {
    refetchQueries: [{ query: SEE_MYGYM }],
    onCompleted: () => setPostModal(false),
  });

  const [createConsultMutation] = useMutation(CREATE_CONSULT_MUTATION, {
    onCompleted: (data) => {
      const {
        createConsult: { ok },
      } = data;
      if (ok) {
        alert("1대1 요청을 보냈습니다.");
      } else {
        alert("1대1 요청을 보내지 못했습니다.");
      }
      setconsultingModal(false);
    },
    refetchQueries: [{ query: SELECT_CONSULTS_QUERY }],
  });

  const moveToUpdatePost = (postId, assets) => {
    history.push(routes.updatePost, { id: postId, assets });
  };

  const deletePost = (id) => {
    postDeleteMutation({
      variables: { id },
    });
  };

  const [delPTRoomMutation] = useMutation(DELETE_PTROOM, {
    refetchQueries: [{ query: SEE_MYGYM }],
  });

  const deletePTRoom = (modalData) => {
    if (!!modalData?.isMine) {
      const confirmBool = window.confirm("PT룸을 삭제합니다.");
      if (!!confirmBool) {
        delPTRoomMutation({
          variables: {
            id: modalData?.pTRoomId,
          },
        });
        setPTRoomModal(false);
      } else {
        setPTRoomModal(false);
      }
    } else {
      alert("내 PT룸이 아닙니다.");
      setPTRoomModal(false);
    }
  };

  const moveToUpdatePTRoom = () => {
    history.push(routes.updatePTRoom, { id: pTRoomModalData?.pTRoomId });
  };

  //차단하기
  const onBlockCompleted = (data) => {
    const {
      blockUser: { ok },
    } = data;
    if (ok) {
      setIsBlocked(true);
    }
  };

  const [blockUserMutation] = useMutation(BLOCK_USER_MUTATION, {
    onCompleted: onBlockCompleted,
  });

  const onBlockClick = () => {
    const confirmBool = window.confirm(
      "해당 유저를 차단하시겠습니까?\n차단한 유저는 내 프로필의 차단목록에서 확인할 수 있습니다"
    );
    if (!!confirmBool) {
      blockUserMutation({
        variables: {
          userId: Number(params?.id),
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      if (!!data?.seeMyGym?.isMine) {
        isMyGymVar(true);
      } else {
        isMyGymVar(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!!data?.seeMyGym?.isMine && myGymTab) {
      myGymTabVar(3);
    } else if (!data?.seeMyGym?.isMine && myGymTab) {
      myGymTabVar(2);
    }
  }, []);

  useEffect(() => {
    return () => {
      myGymTabVar(3); //화면을 벗어날경우 myGymTab 의 값을 3 로 다시 초기화
    };
  }, []); // 한번만 일어나도록 조치 필수

  useEffect(() => {
    if (!!location?.state?.id) {
      myGymTabVar(3);
    } else {
      myGymTabVar(2);
    }
    if (!!location?.state?.myGymTab) {
      myGymTabVar(Number(location?.state?.myGymTab));
    }
  }, [location]);

  useEffect(() => {
    if (me?.me) {
      if (!!me?.me?.blockList?.split("|")?.includes(params?.id)) {
        setIsBlocked(true);
      } else {
        setIsBlocked(false);
      }
    }
  });

  return (
    <Container style={{ maxWidth: "1140px" }} id="main">
      {pLoading === true ? <Spinner /> : null}
      <Header>
        <ProfilePhotoCon>
          <ProfilePhoto
            src={data?.seeMyGym?.profilePhoto}
            {...data?.seeMyGym}
            width="175px"
            height="175px"
            // borderRadius="20px"
          />
          {data?.seeMyGym?.type === "trainer" ? (
            <TrainerCert>
              <Image
                src={trainerCert}
                width="100px"
                title="트레이너 인증 절차를 통해 발급된 인증 마크입니다."
              />
            </TrainerCert>
          ) : null}
        </ProfilePhotoCon>
        <ProfileDesCon>
          <UsernameCon>
            <Username>{data?.seeMyGym?.username}</Username>

            <div>
              <Link
                to={{
                  pathname: routes.setting,
                  state: { ...data?.seeMyGym },
                }}
              >
                {!!data?.seeMyGym?.isMine ? (
                  <Button variant="primary" type="button">
                    <FontAwesomeIcon icon={faUser} /> <IconText1 />
                  </Button>
                ) : null}
              </Link>
              {data?.seeMyGym?.isMine ? (
                <Link
                  to={{
                    pathname: routes.myCouponBox,
                  }}
                >
                  <Button variant="secondary" type="button">
                    <FontAwesomeIcon icon={faBox} /> <IconText2 />
                  </Button>
                </Link>
              ) : null}
            </div>

            {!data?.seeMyGym?.isMine ? (
              <div>
                {" "}
                {data?.seeMyGym?.type === "trainer" ? (
                  <Button
                    onClick={() => setconsultingModal(true)}
                    variant="primary"
                    type="button"
                    disabled={isBlocked ? true : false}
                    style={isBlocked ? { opacity: "0.5" } : null}
                  >
                    <i
                      style={{ fontSize: "13px" }}
                      className="bi bi-chat-left-text-fill"
                    />{" "}
                    1대1
                  </Button>
                ) : null}
                <Button
                  onClick={onReportClick}
                  variant="danger"
                  type="button"
                  disabled={isBlocked ? true : false}
                  style={isBlocked ? { opacity: "0.5" } : null}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} /> 신고
                </Button>
                {!isBlocked ? (
                  <Button
                    onClick={onBlockClick}
                    variant="secondary"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faBan} /> 차단
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    type="button"
                    disabled={true}
                    style={{ opacity: "0.5" }}
                  >
                    <FontAwesomeIcon icon={faBan} /> 차단됨
                  </Button>
                )}
              </div>
            ) : null}

            {/* {!data?.seeMyGym?.isMine ? (
              <FirstMessageContainer>
                {messageDisplay ? (
                  <SButton
                    onClick={onEnvelopeClick}
                    color="orange"
                    bgColor="white"
                    context={<FontAwesomeIcon icon={faEnvelope} />}
                  />
                ) : (
                  <SButton
                    onClick={onEnvelopeClick}
                    bgColor="white"
                    context={<FontAwesomeIcon icon={faEnvelope} />}
                  />
                )}
                <FirstMessage displayBool={messageDisplay}>
                  <MessageInput
                    {...register("message")}
                    placeholder="인사말을 입력해보세요!"
                  />
                  <SButton context="전송" onClick={onSendClick} />
                </FirstMessage>
              </FirstMessageContainer>
            ) : null} */}
          </UsernameCon>

          {/* <Routrains> */}
          {/* <Routines>게시물 수: {data?.seeMyGym?.posts?.length}</Routines> */}
          {/* <Trains>내 트레이닝수: {data?.seeMyGym?.trains?.length}</Trains> */}
          {/* </Routrains> */}

          <div>
            <Description>{data?.seeMyGym?.description}</Description>
          </div>
        </ProfileDesCon>
      </Header>
      {myGymTab ? (
        !isBlocked ? (
          <Gym>
            <Tabs ref={_tabs}>
              {!!data?.seeMyGym?.isMine ? (
                <>
                  {/* <Tab
                ref={tab_1}
                className="tab"
                checked={myGymTab === 1 ? true : false}
                onClick={(e) => {
                  onTabClick(1, e);
                }}
              >
                내 게시물
              </Tab> */}
                  <Tab
                    ref={tab_3}
                    className="tab"
                    checked={myGymTab ? (myGymTab === 3 ? true : false) : null}
                    onClick={(e) => {
                      onTabClick(3, e);
                    }}
                  >
                    내 PT룸
                  </Tab>
                  <Tab
                    ref={tab_4}
                    className="tab"
                    checked={myGymTab ? (myGymTab === 4 ? true : false) : null}
                    onClick={(e) => {
                      onTabClick(4, e);
                    }}
                  >
                    {me?.me?.type === "trainer"
                      ? "요청받은 목록"
                      : "요청한 목록"}
                  </Tab>
                  {/* <Tab
                ref={tab_5}
                className="tab"
                checked={myGymTab === 5 ? true : false}
                onClick={(e) => {
                  onTabClick(5, e);
                }}
              >
                나의 프로그레스
              </Tab> */}
                  <Tab
                    ref={tab_6}
                    className="tab"
                    checked={myGymTab ? (myGymTab === 6 ? true : false) : null}
                    onClick={(e) => {
                      onTabClick(6, e);
                    }}
                  >
                    챌린지
                  </Tab>
                  {/* {me?.me?.type === "trainer" ? (
                <Tab
                  ref={tab_7}
                  className="tab"
                  checked={myGymTab === 7 ? true : false}
                  onClick={(e) => {
                    onTabClick(7, e);
                  }}
                >
                  판매 내역
                </Tab>
              ) : (
                <Tab
                  ref={tab_8}
                  className="tab"
                  checked={myGymTab === 8 ? true : false}
                  onClick={(e) => {
                    onTabClick(8, e);
                  }}
                >
                  결제 내역
                </Tab>
              )} */}
                </>
              ) : (
                <>
                  {/* <Tab
                ref={tab_1}
                className="tab"
                checked={myGymTab === 1 ? true : false}
                onClick={(e) => {
                  onTabClick(1, e);
                }}
              >
                게시물
              </Tab> */}
                  <Tab
                    ref={tab_2}
                    className="tab"
                    checked={myGymTab ? (myGymTab === 2 ? true : false) : null}
                    onClick={(e) => {
                      onTabClick(2, e);
                    }}
                  >
                    가입한 PT룸
                  </Tab>
                </>
              )}
            </Tabs>
            {/* {!!data?.seeMyGym && myGymTab === 1 ? (
            <PostTab>
              {!!data?.seeMyGym?.isMine ? (
                <NewPostLink>
                  <Link to={routes.createPost}>
                    <GButton
                      margin="10px 10px 5px 0 "
                      width="130px"
                      fontSize="13px"
                      type="create"
                      context="+ 새 게시물 추가"
                    />
                  </Link>
                </NewPostLink>
              ) : null}
              <Posts>
                {data?.seeMyGym?.posts?.map((post) => (
                  <Post key={post.id}>
                    <PostHeader>
                      <IconSpan
                        onClick={() =>
                          showPostModal(post.id, post.isMine, post.assets)
                        }
                        className="interact"
                      >
                        <FontAwesomeIcon icon={faEllipsisH} size="lg" />
                      </IconSpan>
                    </PostHeader>
                    <PostBody>
                      <Link
                        to={{
                          pathname: routes?.postDetail,
                          state: { id: post?.id },
                        }}
                      >
                        <Image src={post?.assets[0]?.filepath} width="290px" />
                      </Link>
                    </PostBody>
                  </Post>
                ))}
              </Posts>
            </PostTab> */}
            {/* ) : null} */}
            {!data?.seeMyGym?.isMine && myGymTab === 2 ? (
              <MyPTRooms>
                {myPTRoom?.id ? (
                  <PTRoom
                    key={myPTRoom?.id}
                    {...myPTRoom}
                    setPTRoomModal={setPTRoomModal}
                    setPTRoomModalData={setPTRoomModalData}
                  />
                ) : myPTRooms?.seePTRooms?.length ? (
                  myPTRooms?.seePTRooms
                    ?.filter((PTRooms) => {
                      if (PTRooms?.id !== myPTRoom?.id) {
                        return true;
                      }
                    })
                    .map((v) => (
                      <PTRoom
                        key={v?.id}
                        {...v}
                        setPTRoomModal={setPTRoomModal}
                        setPTRoomModalData={setPTRoomModalData}
                      />
                    ))
                ) : null}
              </MyPTRooms>
            ) : null}
            {myGymTab === 3 ? (
              <>
                <Title> 내 PT룸</Title>{" "}
                <PTRoomTab>
                  {!!data?.seeMyGym?.isMine &&
                  data?.seeMyGym?.type === "trainer" ? (
                    <NewPTRoomLink>
                      <Link to={routes.createPTRoom}>
                        <GButton
                          variant="create"
                          width="130px"
                          margin="10px 0 5px 0 "
                          context="+내 PT룸 생성"
                        />
                      </Link>
                    </NewPTRoomLink>
                  ) : null}
                  <MyPTRooms>
                    {myPTRoom
                      ? myPTRoom?.map((pTRoom) => {
                          return (
                            <PTRoom
                              key={pTRoom?.id}
                              {...pTRoom}
                              setPTRoomModal={setPTRoomModal}
                              setPTRoomModalData={setPTRoomModalData}
                            />
                          );
                        })
                      : null}
                    {myPTRooms
                      ? myPTRooms?.seePTRooms?.map((pTRooms) => {
                          if (pTRooms?.isMine === false) {
                            return (
                              <PTRoom
                                key={pTRooms?.id}
                                {...pTRooms}
                                setPTRoomModal={setPTRoomModal}
                                setPTRoomModalData={setPTRoomModalData}
                              />
                            );
                          }
                        })
                      : null}
                  </MyPTRooms>
                </PTRoomTab>
              </>
            ) : null}
            {!!data?.seeMyGym?.isMine && myGymTab === 4 && (
              <>
                <Title>
                  {me?.me?.type === "trainer" ? "요청받은 목록" : "요청한 목록"}
                </Title>{" "}
                <PTRoomTab>
                  <SubTabContext>
                    <RegisterList subTab={subTab} />
                  </SubTabContext>
                </PTRoomTab>
              </>
            )}
            {!!data?.seeMyGym?.isMine && myGymTab === 5 && (
              <Progress progresses={data.seeMyGym.progresses} />
            )}
            {!!data?.seeMyGym?.isMine && myGymTab === 6 && (
              <MCMCon>
                <MultiChallengeManager />
              </MCMCon>
            )}
          </Gym>
        ) : null
      ) : null}
      <Modal
        show={postModal}
        onHide={() => setPostModal(false)}
        centered="true"
        size="sm"
        className="postModal"
      >
        <Modal.Body>
          {postModalData?.isMine ? (
            <>
              <InteractBtn onClick={moveToUpdatePost}>게시물 수정</InteractBtn>
              <InteractBtn
                onClick={() => deletePost(postModalData?.postId)}
                style={{ color: "red" }}
              >
                게시물 삭제
              </InteractBtn>
              <InteractBtn onClick={() => setPostModal(false)}>
                취소
              </InteractBtn>
            </>
          ) : (
            <>
              <InteractBtn>해당 게시물 신고</InteractBtn>
              <InteractBtn onClick={() => setPostModal(false)}>
                취소
              </InteractBtn>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={consultingModal}
        onHide={() => setconsultingModal(false)}
        centered="true"
        size="m"
        className="cnsModal"
      >
        <Modal.Body>
          <CNSTitle>상담 요청</CNSTitle>
          <CNSContents>
            <ConTitle>상담 내용 : </ConTitle>
            <ConValue
              onChange={onConsulting}
              placeholder="상담 내용을 입력해주세요..."
            />
          </CNSContents>
          <div style={{ padding: "5px 10px" }}>
            <GButton
              onClick={() => onCNSSubmit()}
              variant="create"
              width="100%"
              context="상담 요청하기"
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={pTRoomModal}
        onHide={() => setPTRoomModal(false)}
        centered="true"
        size="sm"
        className="pTRoomModal"
      >
        <Modal.Body>
          {pTRoomModalData?.isMine ? (
            <>
              <InteractBtn onClick={moveToUpdatePTRoom}>PT룸 수정</InteractBtn>
              <InteractBtn
                style={{ color: "red" }}
                onClick={() => deletePTRoom(pTRoomModalData)}
              >
                PT룸 삭제
              </InteractBtn>
              <InteractBtn onClick={() => setPTRoomModal(false)}>
                취소
              </InteractBtn>
            </>
          ) : (
            <>
              <InteractBtn>해당 PT룸 신고</InteractBtn>
              <InteractBtn onClick={() => setPTRoomModal(false)}>
                취소
              </InteractBtn>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default MyGym;
