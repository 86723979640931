//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import {
  CaseBadge,
  CDate,
  Detail,
  HiddenDiv,
  HisInfo,
  InfoContainer,
  InfoHisCard,
  InfoImage,
  PReqest,
  UserCon,
} from "../../routes/UserHistory";

import { TypeBadge } from "../shared/TypeBadge";

const PayMentHistoryCard = ({ rb, getModalData }) => {
  const onDetails = (data) => {
    getModalData(data);
  };

  return (
    <InfoContainer>
      <HiddenDiv className="appear">
        <CDate>{new Date(Number(rb?.createdAt)).toJSON()?.split("T")[0]}</CDate>
        <Detail>
          <div onClick={() => onDetails(rb)}>결제 상세보기 </div>
        </Detail>
      </HiddenDiv>

      <InfoHisCard>
        <CDate className="hidden">
          {new Date(Number(rb?.createdAt)).toJSON()?.split("T")[0]}
        </CDate>
        <UserCon>
          <InfoImage
            src={
              rb?.contractType === "MultiChallenge"
                ? rb?.multiChallenge?.thumbnail
                : rb?.contract?.multiChallenge?.thumbnail
            }
            onError={(e) =>
              (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
            }
          />
        </UserCon>
        <HisInfo>
          <div style={{ width: "100%" }}>
            <h2>
              {rb?.contractType === "MultiChallenge"
                ? rb?.multiChallenge?.title
                : rb?.contract?.multiChallenge?.title}{" "}
            </h2>
            <div
              style={{
                width: "100%",
                fontSize: "13.5px",
                fontWeight: "600",
              }}
            >
              {rb?.statement?.transactionType}
            </div>{" "}
            <div>{rb?.statement?.amount.toLocaleString()}원</div>
          </div>
          <div
            className="appear"
            style={{
              marginTop: "5px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {rb?.contractType === "MultiChallenge" ? (
              <TypeBadge
                className="hidden"
                width="100px"
                variant="payment"
                context="Payment"
              />
            ) : (
              <TypeBadge
                className="hidden"
                width="100px"
                variant="challenge"
                context="Challenge"
              />
            )}
          </div>
          {/* <div>기간(챌린지)</div> */}
          {/* <div>
          <div style={{ fontWeight: "600" }}>
            {new Date(Number(rb?.createdAt)).toJSON()?.split("T")[0]}
          </div>
          <div style={{ textAlign: "center", fontWeight: "600" }}>~</div>

          <div style={{ fontWeight: "600" }}>
            {" "}
            {new Date(Number(rb?.createdAt)).toJSON()?.split("T")[0]}
          </div>
        </div> */}
        </HisInfo>

        <Detail className="hidden">
          <div onClick={() => onDetails(rb)}>상세보기</div>
        </Detail>
        <PReqest>
          {rb?.contractType === "MultiChallenge" ? (
            <TypeBadge
              className="appear"
              width="80px"
              variant="payment"
              context="Payment"
              fontSize="12px"
              height="25px"
            />
          ) : (
            <TypeBadge
              className="appear"
              width="80px"
              variant="challenge"
              context="Challenge"
              fontSize="12px"
              height="25px"
            />
          )}
          <CaseBadge
            variant={
              rb?.transactionStatus === "거래 진행중"
                ? "progress"
                : rb?.transactionStatus === "거래 성공"
                ? "success"
                : "failed"
            }
          >
            {" "}
            {rb?.transactionStatus === "거래 진행중"
              ? "진행 중"
              : rb?.transactionStatus === "거래 성공"
              ? "지급 완료"
              : "실패"}
          </CaseBadge>
        </PReqest>
      </InfoHisCard>
    </InfoContainer>
  );
};
export default PayMentHistoryCard;
