//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//챌린지 마스터

import { useMutation } from "@apollo/client";
import axios from "axios";
import { Button, Card, Container, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { CREATE_RECEIPT_FOR_MULTICHALLENGE } from "../../queries/Receipt/creatReceiptForMultiChallenge";
import { SelectMultiChallengeById } from "../../queries/multiChallenge/selectMultiChallengeById";
import colors from "../../utils/colors";
import { routes } from "../routes";

const MultiChallengePaymentConfirm = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const orderId = params.get("orderId");
  const paymentKey = params.get("paymentKey");
  const amount = params.get("amount");
  const multiChallengeId = orderId.split("_")[2];

  //multiChallenge 정보
  const { data } = SelectMultiChallengeById(Number(multiChallengeId));

  //Receipt 생성
  const createReceiptUpdate = (cache, data) => {
    const {
      data: {
        createReceiptForMultiChallenge: { ok },
      },
    } = data;
    if (ok) {
      alert("완료");
      cache.modify({
        id: `MultiChallenge:${multiChallengeId}`,
        fields: {
          isPaid(prev) {
            return true;
          },
        },
      });
      history.push(routes.multiChallengeManagement, {
        id: Number(multiChallengeId),
      });
    }
  };
  const [createReceiptForMultiChallengeMutation] = useMutation(
    CREATE_RECEIPT_FOR_MULTICHALLENGE,
    {
      update: createReceiptUpdate,
    }
  );
  const onPurchaseConfirmClick = async () => {
    const confirmURL = `https://api.tosspayments.com/v1/payments/${paymentKey}`;
    const data = {
      orderId,
      amount,
    };
    const res = await axios.post(confirmURL, JSON.stringify(data), {
      headers: {
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_TOSS_SECRET_KEY}:`
        )}`,
        "Content-Type": "application/json",
      },
    });

    createReceiptForMultiChallengeMutation({
      variables: {
        paymentKey: String(paymentKey),
        orderId: String(orderId),
        jsonData: JSON.stringify(res?.data),
        type: "toss",
        contractId: Number(multiChallengeId),
        contractType: "MultiChallenge",
      },
    });
  };
  return (
    <Container style={{ width: "500px" }}>
      <Row>
        <Card>
          <Card.Body
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card.Title>결제 내용</Card.Title>
            <Card.Text>
              상품명 : {data?.selectMultiChallengeById?.title}
            </Card.Text>
            <Card.Text>가격 : {amount}</Card.Text>
            <Card.Text
              style={{
                display: "flex",
                flexDirection: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="primary"
                style={{
                  backgroundColor: colors.bbeego,
                  borderColor: colors.bbeego,
                }}
                onClick={onPurchaseConfirmClick}
              >
                결제 승인
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default MultiChallengePaymentConfirm;
