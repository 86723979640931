//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useRef } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes/routes";

const CarouselLayout = ({ id, assets, width, height }) => {
  const history = useHistory();
  const moveToUpdate = () => {
    history.push(routes.postDetail, { id });
  };
  const refCarousel = useRef();
  window.Carousel = refCarousel;
  useEffect(() => {
    const itemsRef = refCarousel?.current?.itemsRef;
    const CarouselWrapper = refCarousel?.current?.carouselWrapperRef;
    itemsRef?.forEach((v) => {
      v?.setAttribute("style", "align-self:center;");
    });
    CarouselWrapper?.setAttribute("style", "background-color:black;");
  }, []);
  return (
    <Carousel
      ref={refCarousel}
      onClickItem={moveToUpdate}
      showArrows={true}
      showIndicators={false}
      showThumbs={false}
      width={width}
      height={height}
    >
      {assets?.map((asset, idx) => (
        <div key={asset.id}>
          <img src={asset.filepath} />
        </div>
      ))}
    </Carousel>
  );
};
export default CarouselLayout;
