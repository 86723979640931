//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Editor from "../component/PTroom/EditorComponent";
import { GButton } from "../component/shared/GButton";
import { CREATE_REPORT_BY_USER_MUTATION } from "../queries/report/createReportByUserId";
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0;
`;
const ReportCon = styled.div`
  font-family: "Raleway", sans-serif;
  width: 700px;
  //전체 컨테이너
  min-height: 600px;
  margin: 0 auto;
  margin-top: 150px;
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 5px 30px 0 rgb(82 63 105 / 20%);
  @media (max-width: 700px) {
    width: 100%;
  }

  .quill {
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
  }
  .ql-editor {
    min-height: 400px;
  }
`;
const ReTitle = styled.h3`
  width: 100%;
  margin-bottom: 40px;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
`;
const InputCon = styled.div`
  width: 100%;
  margin: 20px auto;
  display: flex;
  vertical-align: middle;
  flex-direction: row;
`;
const ColTitle = styled.span`
  width: 70px;
  font-size: 17px;
  padding: 0.5rem 0;
`;
const ReInput = styled.input`
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
`;

const Btns = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  button {
    margin: 0 10px;
  }
`;

const Report = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();

  const [title, setTitle] = useState("");
  const [quillFiles, setQuillFiles] = useState([]);
  const [Description, setDescription] = useState("");
  const [quillValue, setQuillValue] = useState("");

  const cancelBtn = () => {
    let val = window.confirm("작성한 내용을 지우고 이전페이지로 이동합니다.");
    if (val === true) {
      window.location.replace(`/mygym/${location?.state?.id}`);
    }
  };
  const [createReportMuation] = useMutation(CREATE_REPORT_BY_USER_MUTATION, {
    onCompleted: (data) => {
      const {
        createReportByUser: { ok },
      } = data;
      if (!!ok) {
        alert("신고가 정상적으로 접수되었습니다.");
        window.location.replace(`/mygym/${location?.state?.id}`);
      } else {
        alert("신고가 접수되지 않았습니다..");
      }
    },
  });
  const onCreateReport = () => {
    if (!!title && quillFiles && Description && quillValue) {
      createReportMuation({
        variables: {
          targetUserId: Number(location?.state?.id),
          title: String(title),
          description: String(Description),
          reportAssets: quillFiles,
          ...(location?.state?.targetContentId && {
            targetContentId: location?.state?.targetContentId,
          }),
          ...(location?.state?.targetContentType && {
            targetContentType: location?.state?.targetContentType,
          }),
          ...(location?.state?.isContent && {
            isContent: location?.state?.isContent,
          }),
        },
      });
    } else {
      alert("입력되지 않은 정보가 있습니다.");
    }
  };
  const onEditorChange = (content, delta, source, editor) => {
    let fileArr = [];
    const regex = /(src="data.*?)(?=")/g;
    if (content.match(regex)?.[0]) {
      const imgDatas = content.match(regex);
      for (let i = 0; i < imgDatas.length; i++) {
        try {
          imgDatas[i] = imgDatas[i].replace('src="', "");
          fileArr.push(dataURLtoFile(imgDatas[i], "quillImg.png"));
        } catch (e) {
          //fileArr.push(imgDatas[i]);
        }
      }
    }
    const quillData = content.replace(regex, 'src="|||quill|||');
    //화면 띄우는 용
    setQuillValue(content);
    //서버 전송용
    // setValue("description", quillData);
    setDescription(quillData);
    setQuillFiles(fileArr);
  };

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const dataURLtoFile = (dataurl, fileName) => {
    var arr = dataurl?.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  return (
    <Container>
      <ReportCon>
        <ReTitle>{location?.state?.username} 신고하기</ReTitle>
        <InputCon>
          <ColTitle>제목 : </ColTitle>
          <ReInput onChange={onTitleChange} />
        </InputCon>

        <Editor value={quillValue} onChange={onEditorChange} />
        <Btns>
          <GButton
            onClick={onCreateReport}
            width="75px"
            variant="create"
            context="신고"
            fontSize="15px"
          />
          <GButton
            width="75px"
            variant="delete"
            context="취소"
            fontSize="15px"
            onClick={cancelBtn}
          />
        </Btns>
      </ReportCon>
    </Container>
  );
};

export default Report;
