//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import SeeUsers, { SEE_USERS } from "../../queries/user/seeUsers";
import Grid from "@toast-ui/react-grid";
import { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { SButton } from "../shared/SButton";
import { ADMIN_UPDATE_USER } from "../../queries/admins/user/adminUpdateUser";
import { CREATE_USER } from "../../queries/user/createUser";
import { ADMIN_DELETE_USER } from "../../queries/admins/user/adminDeleteUser";
import { useEffect } from "react";
import { Input, Select } from "../shared/Auth";
import { userListVar } from "../../utils/reactiveVar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  width: 99%;
  background-color: white;
  padding: 10px;
  margin: 15px auto;
  box-shadow: 0 2px 5px #ccc;
  border-radius: 5px;
  .vBtn {
    border: none;
    background-color: white;
    margin: 5px;
    text-align: center;
    width: 100%;
    color: gray;
  }
  .vBtn:hover {
    width: 100%;
    background-color: orange;
    color: white;
    font-size: 25px;
    border-radius: 5px;
  }
`;

const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

const UserRow = styled.div`
  width: 100%;
  display: ${(props) => (props.display ? props.display : "Grid")};
  grid-template-columns: 1fr 5fr;
  text-align: center;
`;

const Change = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .closeBtn {
    background-color: white;
    color: lightgray;
    float: right;
    border: none;
    font-size: 20px;
  }
  .closeBtn:hover {
    color: orange;
    font-size: 25px;
  }
`;

const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  input {
    border: 1px solid #ccc;
    outline: none;
    width: 100%;
  }
`;

const ColTitle = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  width: 100px;
  padding: 10px;
  font-weight: bold;
  background-color: orange;
  color: white;
  line-height: 40px;
`;

const ColValue = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const RowTitle = styled.span`
  width: 100%;
  display: flex;
  font-size: 20px;
  color: black;
  padding-top: 10px;
`;
const FontCon = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const UserGrid = () => {
  const { register, getValues, handleSubmit, setValue } = useForm();
  const [selData, setSelData] = useState();
  const [modal, setModal] = useState(false);
  const gridRef = useRef();
  let data = SeeUsers(100);

  const [aHidden, setHidden] = useState(false);

  const toggleMenu = () => {
    //aHidden = !aHidden;
    setHidden(!aHidden);
  };

  const [createUserMutation] = useMutation(CREATE_USER, {
    refetchQueries: [{ query: SEE_USERS, variables: { quantity: 100 } }],
    onCompleted: () => {
      setModal(false);
    },
  });

  const [deleteUserMutation] = useMutation(ADMIN_DELETE_USER, {
    refetchQueries: [{ query: SEE_USERS }],
    onCompleted: () => {
      setModal(false);
    },
  });

  const [updateUserMutation] = useMutation(ADMIN_UPDATE_USER, {
    refetchQueries: [{ query: SEE_USERS, variables: { quantity: 100 } }],
    onCompleted: () => {
      setModal(false);
    },
  });

  const myChallengesColumns = [
    { name: "__typename", header: "타입이름", align: "center", hidden: true },
    { name: "id", header: "아이디", align: "center", hidden: true },
    { name: "username", header: "유저이름", align: "center" },
    { name: "email", header: "이메일", align: "center" },
    { name: "birthyear", header: "생년", align: "center" },
    { name: "birthmonth", header: "생월", align: "center" },
    { name: "birthday", header: "생일", align: "center" },
    { name: "type", header: "타입", align: "center" },
    { name: "socialLoginType", header: "로그인 타입", align: "center" },
  ];

  const onGridClick = (e) => {
    const rowData = gridRef.current.getInstance().getRow(e?.rowKey);
    window.a = gridRef.current;
    if (!!rowData) {
      setSelData(rowData);
      setValue("id", rowData.id);
      setValue("username", rowData.username);
      setValue("email", rowData.email);
      setValue("birthyear", rowData.birthyear);
      setValue("birthmonth", rowData.birthmonth);
      setValue("birthday", rowData.birthday);
      setValue("type", rowData.type);
      setValue("socialLoginType", rowData.socialLoginType);
      setModal(true);
    }
  };

  const onUserCreateClick = () => {
    setSelData("");
    setValue("id", "");
    setValue("username", "");
    setValue("email", "");
    setValue("birthyear", new Date().getFullYear());
    setValue("birthmonth", new Date().getMonth() + 1);
    setValue("birthday", new Date().getDate());
    setValue("password", "");
    setValue("type", "");
    setValue("socialLoginType", "");
    setModal(true);
  };

  const onSaveClick = (data) => {
    const {
      birthday,
      birthmonth,
      birthyear,
      email,
      id,
      password,
      type,
      username,
    } = data;

    if (!id) {
      createUserMutation({
        variables: {
          username,
          email,
          password,
          birthyear: Number(birthyear),
          birthmonth: Number(birthmonth),
          birthday: Number(birthday),
          type,
        },
      });
    } else {
      updateUserMutation({
        variables: {
          id: Number(id),
          username,
          email,
          birthyear: Number(birthyear),
          birthmonth: Number(birthmonth),
          birthday: Number(birthday),
          type,
        },
      });
    }
  };

  const onDeleteClick = () => {
    const { id } = getValues();

    if (!!id) {
      deleteUserMutation({
        variables: {
          id,
        },
      });
    }
  };
  useEffect(() => {
    gridRef?.current
      ?.getInstance()
      .resetData(data?.seeUsers?.map((v) => ({ ...v })));
    if (data?.seeUsers) {
      userListVar(data.seeUsers);
    }
  }, [data]);

  const date = new Date();
  const year = date?.getFullYear();
  const month = date?.getMonth() + 1;
  const day = date?.getDate();
  const startY = Number(year) - 100;
  const SelYears = [];
  const SelMonths = [];
  const SelDays = [];
  for (let y = startY; y <= year; y++) {
    SelYears.push(y);
  }
  for (let m = 1; m <= 12; m++) {
    SelMonths.push(m);
  }
  for (let d = 1; d <= 31; d++) {
    SelDays.push(d);
  }

  return (
    <Container>
      <button className="vBtn" onClick={() => toggleMenu()}>
        {aHidden ? (
          <FontCon>
            User Close
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </FontCon>
        ) : (
          <FontCon>
            User Open
            <FontAwesomeIcon size="2x" icon={faAngleRight} />
          </FontCon>
        )}
      </button>
      {aHidden ? (
        <UserContainer>
          <div style={{ textAlign: "right" }}>
            <SButton
              display="inline-block"
              onClick={onUserCreateClick}
              width="fit-content"
              context="+ 새 유저 생성"
              fontSize="12px"
              color="black"
              border="1px solid lightgray"
            />
          </div>
          <Row margin="10px 0">
            {data?.seeUsers?.length && (
              <Grid
                ref={gridRef}
                data={data?.seeUsers?.map((v) => {
                  return { ...v };
                })}
                width="auto"
                bodyHeight={300}
                onClick={onGridClick}
                columns={myChallengesColumns}
                rowHeaders={["rowNum"]}
                pageOptions={{
                  perPage: 0,
                  useClient: true,
                  type: "scroll",
                }}
              />
            )}
          </Row>
        </UserContainer>
      ) : null}
      {modal === true ? (
        <Modal size="md" show={modal} onHide={() => setModal(false)}>
          <Change>
            <div style={{ width: "100%", display: "flex" }}>
              <RowTitle>유저 정보</RowTitle>
              <button className="closeBtn" onClick={() => setModal(false)}>
                x
              </button>
            </div>
            <BasicInfo>
              <UserRow display="none">
                <ColTitle>아이디</ColTitle>
                <ColValue>
                  <Input {...register("id")} type="text" autoFocus></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>유저이름</ColTitle>
                <ColValue>
                  <Input
                    {...register("username", {
                      required: { value: true, message: "username required." },
                    })}
                    type="text"
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>이메일</ColTitle>
                <ColValue>
                  <Input
                    {...register("email", {
                      required: { value: true, message: "username required." },
                    })}
                    type="text"
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>생년</ColTitle>
                <ColValue>
                  <Select
                    className="year"
                    {...register("birthyear", {
                      required: "태어난 년도를 선택해주세요.",
                    })}
                  >
                    {SelYears?.map((v) => {
                      if (v == year) {
                        return (
                          <option value={v} selected>
                            {v}년
                          </option>
                        );
                      } else {
                        return <option value={v}>{v}년</option>;
                      }
                    })}
                  </Select>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>생월</ColTitle>
                <ColValue>
                  <Select
                    className="month"
                    {...register("birthmonth", {
                      required: "태어난 달을 선택해주세요.",
                    })}
                  >
                    {SelMonths?.map((v) => {
                      if (v == month) {
                        return (
                          <option value={v} selected>
                            {v}월
                          </option>
                        );
                      } else {
                        return <option value={v}>{v}월</option>;
                      }
                    })}
                  </Select>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>생일</ColTitle>
                <ColValue>
                  <Select
                    className="day"
                    {...register("birthday", {
                      required: "태어난 날을 선택해주세요.",
                    })}
                  >
                    {SelDays?.map((v) => {
                      if (v == day) {
                        return (
                          <option value={v} selected>
                            {v}일
                          </option>
                        );
                      } else {
                        return <option value={v}>{v}일</option>;
                      }
                    })}
                  </Select>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>타입</ColTitle>
                <ColValue>
                  <Input
                    {...register("type", {
                      required: { value: true, message: "username required." },
                    })}
                    type="text"
                  ></Input>
                </ColValue>
              </UserRow>
              {selData?.filepath ? null : (
                <UserRow>
                  <ColTitle>비밀번호</ColTitle>
                  <ColValue>
                    <Input
                      {...register("password", {
                        required: {
                          value: true,
                          message: "username required.",
                        },
                      })}
                      type="password"
                    ></Input>
                  </ColValue>
                </UserRow>
              )}
            </BasicInfo>
          </Change>
          {selData?.id ? null : (
            <div style={{ textAlign: "right", margin: "0 10px 10px 0" }}>
              <SButton
                onClick={handleSubmit(onSaveClick)}
                width="fit-content"
                context="생성"
                bgColor="orange"
                color="white"
                fontWeight="bold"
                border="none"
              />
            </div>
          )}
          <div style={{ display: "flex", margin: "0 auto" }}>
            {selData?.id ? (
              <div
                style={{
                  margin: "0 10px 10px 0",
                }}
              >
                <SButton
                  onClick={handleSubmit(onSaveClick)}
                  width="fit-content"
                  context="수정"
                  bgColor="orange"
                  color="white"
                  fontWeight="bold"
                  border="none"
                />
              </div>
            ) : null}
            {selData?.id ? (
              <div
                style={{
                  margin: "0 10px 10px 0",
                }}
              >
                <SButton
                  onClick={onDeleteClick}
                  width="fit-content"
                  context="삭제"
                  bgColor="orange"
                  color="white"
                  fontWeight="bold"
                  border="none"
                />
              </div>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </Container>
  );
};
export default UserGrid;
