//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { useHistory } from "react-router";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { useLocation } from "react-router-dom";
import { CREATE_POST_MUTATION } from "../queries/post/createPost";
import { SeePost, SEE_POST_QUERY } from "../queries/post/seePost";
import { UPDATE_POST_MUTATION } from "../queries/post/updatePost";
import { routes } from "./routes";
import CheckUserToken from "../utils/checkUserToken";

const CreatePostContainer = styled.div`
  width: 1000px;
  min-height: 750px;
  height: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  .FormValue {
    display: flex;
    padding: 15px;
    Train {
      max-height: 600px;
    }
  }
  .FormValue > input,
  textarea {
    border: 0.5px solid black;
    border-radius: 3px;
    width: 800px;
  }
  .ConfirmBtn {
    grid-column: 1/3;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
    .Create {
      margin-right: 5px;
    }
    .Cancel {
      margin-left: 5px;
    }
  }
`;
const PostEditForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Description = styled.textarea`
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
  border: 0.5px solid black;
  border-radius: 3px;
  width: 800px;
`;
const FilesForm = styled.div`
  border: 0.5px solid black;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  width: 900px;
  justify-content: center;
`;
const AddTrain = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 200px;
  height: 200px;
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
  }
`;
export const ColName = styled.div`
  width: 100px;
  font-weight: bold;
  text-align: center;
`;
const ColValue = styled.div`
  width: 800px;
`;
export const File = styled.input`
  opacity: 0;
  position: absolute;
  left: -9999px;
`;
const Label = styled.label`
  padding: 1.5px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
`;
const AddTrainBtn = styled.div`
  cursor: pointer;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Img = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
`;
const TrainCon = styled.div`
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
  position: relative;
  svg {
    position: absolute;
    left: 170px;
    top: 7px;
    color: red;
    cursor: pointer;
    font-size: x-large;
    z-index: 1;
  }
  video {
    border-radius: 10px;
  }
`;
const IsPublicLabel = styled.label``;
const IsPublicCheck = styled.input`
  margin-right: 5px;
`;
const Title = styled.input`
  border: 1px solid black;
`;

function CreatePost() {
  const location = useLocation();

  const history = useHistory();
  const me = CheckUserToken();

  //update_routine 으로 진입시
  const postId = location?.state?.id;
  const [fileUrl, setFileUrl] = useState([]);
  const [realFiles, setRealFiles] = useState([]);
  //const oldAssetIds = [];
  const [oldAssetIds] = useState(
    location.state ? location.state.assets.map((asset) => asset.id) : ""
  );
  const onCompleted = (data) => {
    history.push(routes.myGym, { id: me?.id });
  };

  const [createPost] = useMutation(CREATE_POST_MUTATION, {
    refetchQueries: [
      {
        query: SEE_POST_QUERY,
        variables: {
          id: postId ? postId : 0,
        },
      },
    ],
    onCompleted,
  });
  const [updatePost] = useMutation(UPDATE_POST_MUTATION, {
    refetchQueries: [
      {
        query: SEE_POST_QUERY,
        variables: {
          id: postId ? postId : 0,
        },
      },
    ],
    onCompleted,
  });

  const { register, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });

  //update_routine 으로 접근한 경우
  let result = SeePost(postId ? postId : 0);
  if (!!postId) {
    setValue("title", result?.seePost?.title);
    setValue("description", result?.seePost?.description);
  }
  const onSubmitValid = (data) => {
    const { title, description, assets, isPublic } = data;
    if (!!postId) {
      alert("update");
      updatePost({
        variables: {
          id: result?.seePost?.id,
          title,
          description,
          oldAssetIds: oldAssetIds.join("|"),
          assets,
        },
      });
    } else {
      alert("creat");

      createPost({
        variables: {
          title,
          description,
          assets: assets,
          isPublic: !isPublic,
        },
      });
    }
  };
  const cancelRoutine = () => {
    let val = window.confirm("작성한 내용을 지우고 이전페이지로 이동합니다.");
    if (val === true) {
      window.location.replace("/myGym");
    } else {
    }
  };
  //train checkbox 클릭시
  const ImgArr = ["png", "jpg", "jpeg"];
  const VidArr = ["avi", "mp4"];

  const onFileUpload = (event) => {
    const fileArr = event?.target?.files;

    let file;
    let fileURLs = [];
    setRealFiles([...realFiles, ...fileArr]);
    for (let i = 0; i < fileArr.length; i++) {
      file = fileArr[i];

      let reader = new FileReader();
      reader.onload = () => {
        fileURLs.push(reader.result);
        setFileUrl([...fileUrl, ...fileURLs]);
      };
      reader.readAsDataURL(file);
    }
  };
  const onDelClick = (e) => {
    const target = e?.target?.parentElement;
    const targetId = target?.id;
    for (let i = 0; i < oldAssetIds.length; i++) {
      if (oldAssetIds[i] === targetId) {
        oldAssetIds.splice(i, 1);
        i--;
      }
    }

    if (target?.tagName === "DIV") {
      target.setAttribute("style", "display:none");
    } else if (target?.tagName === "svg") {
      target?.parentElement.setAttribute("style", "display:none;");
    }
    window.targetId = targetId;
  };
  window.oldAssetIds = oldAssetIds;
  return (
    <CreatePostContainer>
      <PostEditForm onSubmit={handleSubmit(onSubmitValid)}>
        <Row>
          <ColName>제목 : </ColName>
          <ColValue id="Title">
            <Title
              {...register("title", {
                required: { value: true, message: "제목을 입력해주세요!" },
              })}
              className="Routine_Title form-control"
            />
          </ColValue>
        </Row>
        <Row>
          <ColName>설명 : </ColName>
          <ColValue id="Description">
            <Description
              {...register("description", {
                max: {
                  value: 1000,
                  message: "설명은 1000자를 넘길 수 없습니다!",
                },
                validate: {
                  lessThan1000: (value) => value.length <= 1000,
                },
              })}
              className="form-control"
            />
          </ColValue>
        </Row>
        <ColValue className="span2" id="Train">
          <FilesForm>
            <AddTrain>
              <Label>
                <AddTrainBtn>
                  <FontAwesomeIcon icon={faPlus} />
                  사진 추가
                </AddTrainBtn>
                <File
                  {...register("assets")}
                  type="file"
                  onChange={onFileUpload}
                  multiple={true}
                />
              </Label>
            </AddTrain>
            {!!postId
              ? result?.seePost?.assets.map((v) => {
                  if (
                    ImgArr.includes(v?.filepath.split(".").pop().toLowerCase())
                  ) {
                    return (
                      <TrainCon key={v?.id} id={v?.id}>
                        <FontAwesomeIcon
                          id={v?.id}
                          icon={faTimesCircle}
                          onClick={(e) => onDelClick(e)}
                        />
                        <Img key={v?.id} src={v?.filepath} />
                      </TrainCon>
                    );
                  } else if (
                    VidArr.includes(v?.filepath.split(".").pop().toLowerCase())
                  ) {
                    return (
                      <TrainCon key={v?.id} id={v?.id}>
                        <FontAwesomeIcon
                          id={v?.id}
                          icon={faTimesCircle}
                          onClick={(e) => onDelClick(e)}
                        />
                        <ReactPlayer
                          url={v?.filepath}
                          width="200px"
                          height="200px"
                        />
                      </TrainCon>
                    );
                  }
                })
              : null}
            {fileUrl
              ? fileUrl.map((v, idx) => {
                  if (ImgArr.includes(v?.split(";")[0].split("/").pop())) {
                    return (
                      <TrainCon key={idx}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          onClick={(e) => onDelClick(e)}
                        />
                        <Img key={idx} src={v} />
                      </TrainCon>
                    );
                  } else if (
                    VidArr.includes(v?.split(";")[0].split("/").pop())
                  ) {
                    return (
                      <TrainCon key={idx}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          onClick={(e) => onDelClick(e)}
                        />
                        <ReactPlayer
                          key={idx}
                          url={v}
                          controls={true}
                          width="200px"
                          height="200px"
                          id="videofile"
                        />
                      </TrainCon>
                    );
                  }
                })
              : null}
          </FilesForm>
        </ColValue>
        <Row>
          <IsPublicLabel>
            <IsPublicCheck type="checkbox" {...register("isPublic")} />
            비공개
          </IsPublicLabel>
        </Row>
        <div className="ConfirmBtn span2">
          <input
            type="submit"
            className="btn btn-primary Create"
            value="저장"
          />
          <input
            type="button"
            className="btn btn-danger Cancel"
            value="취소"
            onClick={cancelRoutine}
          />
        </div>
      </PostEditForm>
    </CreatePostContainer>
  );
}

export default CreatePost;
