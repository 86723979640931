//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import SimpleCouponLayout from "../component/coupon/SimpleCouponLayout";
import { Container } from "../component/shared/Container";
import { FatText } from "../component/shared/FatText";
import { GButton } from "../component/shared/GButton";
import { CHECK_CONTRACT_QUERY } from "../queries/contract/checkContract";
import { CREATE_CONTRACT_MUTATION } from "../queries/contract/createContract";
import { UPDATE_CONTRACT_MUTATION } from "../queries/contract/updateContract";
import CheckUserToken from "../utils/checkUserToken";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { generateUUID } from "three/src/math/MathUtils";
import { EXTEND_CONTRACT_MUTATION } from "../queries/contract/extendContract";
import { routes } from "./routes";
import { DELETE_COUPON_BY_USER_MUTATION } from "../queries/givenCoupon/deleteCouponByUser";
import colors from "../utils/colors";

const SaleContainer = styled(Container)`
  background-color: ${colors.bbeego};
  color: white;
  padding: 10px;
  border-radius: 5px;
  align-items: flex-start;
  gap: 30px;
  width: fit-content;
  margin: auto;
`;
const Row = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
`;
const Col = styled.div`
  min-width: 100px;
`;
const DenyMessage = styled.span`
  color: red;
`;
const ModalCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const ModalTitle = styled.div``;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const ModalSection = styled.div``;
const CardPurchase = styled.div`
  text-align: center;
`;
const CouponUse = styled.div`
  display: flex;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
  border-top: 1px solid ${colors.bbeego};
  border-bottom: 1px solid ${colors.bbeego};
`;
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;
const SelCoupon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const CouponTitle = styled.div``;
const CouponeContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 10px;
`;
const Clicker = styled.div`
  cursor: pointer;
`;
const ReqButton = styled(GButton)`
  background-color: white;
  box-shadow: none;
  color: ${colors.bbeego};
  border: 0;
`;
const CenterRow = styled.div`
  border-bottom: 1px solid ${colors.bbeego};
  padding-bottom: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

function SaleInfo() {
  const [modal, setModal] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [selGivenCoupon, setSelGivenCoupon] = useState(null); //쿠폰 사용여부

  const history = useHistory();
  const { state } = useLocation();

  const user = CheckUserToken();
  const { data } = useQuery(CHECK_CONTRACT_QUERY, {
    variables: {
      pTPriceId: state?.pTPriceId,
    },
    fetchPolicy: "network-only",
  });
  const createContractUpdate = (cache, data) => {
    const {
      data: {
        createContract: { id },
      },
    } = data;
    const newContract = {
      __ref: `Contract:${id}`,
    };
    cache.modify({
      id: "ROOT_QUERY",
      fields: {
        getRequestContract(cachedName) {
          return [...cachedName, newContract];
        },
      },
    });
  };
  const [createContractMutation] = useMutation(CREATE_CONTRACT_MUTATION, {
    refetchQueries: [
      {
        query: CHECK_CONTRACT_QUERY,
        variables: {
          pTPriceId: state?.pTPriceId,
        },
      },
    ],
    update: createContractUpdate,
  });
  const onRequestClick = () => {
    const confirmBool = window.confirm("등록 요청을 하시겠습니까?");
    if (!!confirmBool) {
      createContractMutation({
        variables: {
          pTPriceId: state?.pTPriceId,
        },
      });
    }
  };
  const onPurchaseClick = () => {
    setModal(true);
  };
  const onOpenPurchaseModuleClick = async () => {
    //토스 결제 모듈
    const tossClientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
    const tossPayments = await loadTossPayments(tossClientKey);
    tossPayments.requestPayment("카드", {
      amount: Number(state?.price) * 10000,
      orderId: `${generateUUID()}_pTPriceId_${state?.pTPriceId}_${
        data?.checkContract?.id
      }${data?.checkContract?.isExpired ? "_extend" : ""}`,
      orderName: state?.title,
      customerName: user?.me?.username,
      successUrl: `${process.env.REACT_APP_CLIENT_URL}saleinfoconfirm`,
      failUrl: `${process.env.REACT_APP_CLIENT_URL}saleinfo`,
    });
    setModal(false);
  };
  const onShowCouponClick = () => {
    setShowCoupon(true);
  };

  const getButton = () => {
    const accepted = data?.checkContract?.accepted;
    const purchased = data?.checkContract?.purchased;
    const denied = data?.checkContract?.denied;
    const denyMessage = data?.checkContract?.denyMessage;
    const hasOther = data?.checkContract?.hasOther;
    const isMine = data?.checkContract?.isMine;
    const isExpired = data?.checkContract?.isExpired;
    if (isMine === true) {
      return <DenyMessage>내 상품 입니다.</DenyMessage>;
    } else if (hasOther === true && isExpired === false) {
      return (
        <>
          <ReqButton
            context="이미 등록한 다른 상품이 있습니다."
            disabled={true}
          />
          <DenyMessage>{denyMessage}</DenyMessage>
        </>
      );
    } else if (accepted === true && purchased === true && denied === false) {
      return <ReqButton context="결제 완료" disabled={true} />;
    } else if (
      accepted === true &&
      purchased === false &&
      denied === false &&
      isExpired === false
    ) {
      return (
        <ReqButton
          context="결제 진행"
          disabled={false}
          onClick={onPurchaseClick}
        />
      );
    } else if (
      accepted === true &&
      purchased === false &&
      denied === false &&
      isExpired === true
    ) {
      return (
        <ReqButton
          context="기간 연장"
          disabled={false}
          onClick={onPurchaseClick}
        />
      );
    } else if (accepted === false && purchased === false && denied === false) {
      return <ReqButton context="요청 완료" disabled={true} />;
    } else if (accepted === false && purchased === false && denied === true) {
      return (
        <>
          <ReqButton context="요청 거절됨" disabled={true} border="0" />
          <DenyMessage>{denyMessage}</DenyMessage>
        </>
      );
    } else {
      return (
        <ReqButton
          context="등록 요청"
          disabled={false}
          onClick={onRequestClick}
        />
      );
    }
  };
  /* 쿠폰 결제 관련 */
  const [updateContractMutation] = useMutation(UPDATE_CONTRACT_MUTATION, {
    refetchQueries: [
      {
        query: CHECK_CONTRACT_QUERY,
        variables: {
          pTPriceId: Number(state?.pTPriceId),
        },
      },
    ],
    onCompleted: (data) => {
      const {
        updateContract: { ok },
      } = data;
      if (ok) {
        alert("결제가 완료되었습니다!");
        //history.push(`${routes.myGym}${user?.me?.id}`);
      }
    },
  });
  const [extendContractMutation] = useMutation(EXTEND_CONTRACT_MUTATION, {
    refetchQueries: [
      {
        query: CHECK_CONTRACT_QUERY,
        variables: {
          pTPriceId: Number(state?.pTPriceId),
        },
      },
    ],
    onCompleted: (data) => {
      const {
        extendContract: { ok },
      } = data;
      if (ok) {
        alert("기간 연장이 완료되었습니다!");
        //history.push(`${routes.myGym}${user?.me?.id}`);
      }
    },
  });
  //사용된 쿠폰 삭제
  const [deleteCouponByUserMutation] = useMutation(
    DELETE_COUPON_BY_USER_MUTATION,
    {
      update: (cache, data) => {
        cache.evict({
          id: `GivenCoupon:${selGivenCoupon?.id}`,
        });
        cache.gc();
        history.push(`${routes.myGym}${user?.me?.id}`);
      },
    }
  );
  //쿠폰사용
  const onCouponClick = (givenCoupon) => {
    if (Number(state?.month) === Number(givenCoupon?.coupon?.month)) {
      const confirmBool = window.confirm("선택한 쿠폰을 사용하시겠습니까?");
      if (!!confirmBool) {
        setSelGivenCoupon(givenCoupon);
        setShowCoupon(false);
      }
    } else {
      return alert(
        "사용할 수 없는 쿠폰입니다! 개월 수가 같은 지 확인해주세요!"
      );
    }
  };
  //쿠폰 사용 결제 버튼 클릭시
  const onPurchaseUsingCoupon = async () => {
    if (data?.checkContract?.isExpired === true) {
      await extendContractMutation({
        variables: {
          id: Number(data?.checkContract?.id),
        },
      });
      await deleteCouponByUserMutation({
        variables: {
          givenCouponId: selGivenCoupon?.id,
        },
      });
    } else {
      await updateContractMutation({
        variables: {
          id: Number(data?.checkContract?.id),
          type: "purchase",
          userId: user?.me?.id,
        },
      });
      await deleteCouponByUserMutation({
        variables: {
          givenCouponId: selGivenCoupon?.id,
        },
      });
    }
  };

  return (
    <SaleContainer id="main">
      <CenterRow style={{ borderBottomColor: "white" }}>
        <Col aria-colspan={2} style={{ margin: "auto", paddingTop: "10px" }}>
          PT룸 등록
        </Col>
      </CenterRow>
      <Row>
        <Col>상품명 : </Col>
        <Col>{state?.title}</Col>
      </Row>
      <Row>
        <Col>개월 수 : </Col>
        <Col>{state?.month}</Col>
      </Row>
      <Row>
        <Col>가격(만원) : </Col>
        <Col>{state?.price}</Col>
      </Row>
      <CenterRow
        style={{
          borderTop: "1px solid white",
          paddingTop: "20px",
          marginTop: "10px",
          marginBottom: "0",
        }}
      >
        {getButton()}
      </CenterRow>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="lg"
        style={{ zIndex: 1050, borderRadius: "0.7rem", overflow: "hidden" }}
      >
        <Modal.Body>
          <ModalCon>
            <ModalTitle style={{ paddingBottom: "10px" }}>
              <FatText fontSize="20px">결제 진행</FatText>
            </ModalTitle>
            <ModalContent>
              <ModalSection>
                <ProductInfo>
                  <CenterRow>
                    <Col aria-colspan={2} style={{ margin: "auto" }}>
                      PT룸 등록
                    </Col>
                  </CenterRow>
                  <Row>
                    <Col>상품명 : </Col>
                    <Col>{state?.title}</Col>
                  </Row>
                  <Row>
                    <Col>개월 수 : </Col>
                    <Col>{state?.month}</Col>
                  </Row>
                  <Row>
                    <Col>가격(만원) : </Col>
                    <Col>{state?.price}</Col>
                  </Row>
                </ProductInfo>
              </ModalSection>
              <ModalSection>
                <CouponUse>
                  <SelCoupon>
                    {!!selGivenCoupon
                      ? selGivenCoupon?.coupon?.name
                      : "선택된 쿠폰이 없습니다."}
                  </SelCoupon>
                  {!!selGivenCoupon ? (
                    <GButton
                      variant="delete"
                      context="쿠폰 삭제"
                      bgColor={colors.bbeego}
                      color="white"
                      border="0"
                      fontWeight="bold"
                      boxShadow="0 0 15px -5px grey"
                      onClick={() => setSelGivenCoupon(null)}
                    />
                  ) : null}
                  <GButton
                    varint="primary"
                    onClick={onShowCouponClick}
                    bgColor={colors.bbeego}
                    color="white"
                    border="0"
                    fontWeight="bold"
                    boxShadow="0 0 15px -5px grey"
                    context="쿠폰 조회"
                  />
                </CouponUse>
              </ModalSection>
              <div>
                <CardPurchase>
                  {!!selGivenCoupon ? (
                    <ReqButton
                      variant="primary"
                      onClick={onPurchaseUsingCoupon}
                      context="결제하기"
                    />
                  ) : (
                    <GButton
                      variant="primary"
                      onClick={onOpenPurchaseModuleClick}
                      bgColor={colors.bbeego}
                      color="white"
                      border="0"
                      boxShadow="0 0 15px -5px grey"
                      context="결제하기"
                    />
                  )}
                </CardPurchase>
              </div>
            </ModalContent>
          </ModalCon>
        </Modal.Body>
      </Modal>
      <Modal
        show={showCoupon}
        onHide={() => setShowCoupon(false)}
        style={{ maxWidth: "550px" }}
      >
        <Modal.Body>
          <CouponContainer>
            <CouponTitle style={{ marginBottom: "10px" }}>
              <FatText fontSize="18px">내 쿠폰 조회</FatText>
            </CouponTitle>
            <CouponeContent>
              {user?.me?.givenCoupons?.length === 0 ? (
                <div
                  style={{
                    minWidth: "50px",
                    minHeight: "20px",
                    borderTop: "1px solid " + colors.bbeego,
                    width: "100%",
                    padding: "10px",
                    paddingTop: "20px",
                  }}
                >
                  쿠폰이 없습니다.
                </div>
              ) : (
                user?.me?.givenCoupons?.map((gp) => (
                  <Clicker key={gp?.id} onClick={() => onCouponClick(gp)}>
                    <SimpleCouponLayout
                      name={gp?.coupon?.name}
                      expiredAt={gp?.expiredAt}
                      style={{
                        container: {
                          minWidth: "50px",
                          minHeight: "140px",
                          border: "2px solid " + colors.bbeego,
                          borderRadius: "10px",
                          boxShadow: "0 0 5px -1px " + colors.bbeego,
                          bgColor: colors.bbeego,
                          padding: "10px",
                        },
                        couponName: {
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                        },
                        couponIcon: {
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                          top: "15px",
                          left: "-5px",
                          transform: "rotate(120deg)",
                          zIndex: "1",
                        },
                        shadow: {
                          width: "inherit",
                          height: "2.1px",
                          bgColor: "#222222",
                          position: "relative",
                          top: "10px",
                          borderRadius: "100%",
                          margin: "0 20px",
                          boxShadow: "0 0 5px 6px #222222",
                          opacity: "0.5",
                        },
                        expiredAtSpan: {
                          color: "white",
                        },
                        iconSize: "3x",
                      }}
                    />
                  </Clicker>
                ))
              )}
            </CouponeContent>
          </CouponContainer>
        </Modal.Body>
      </Modal>
    </SaleContainer>
  );
}

export default SaleInfo;
