//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

//new Date().toJSON() 으로 인한 9시간 당김을 다시 되돌리는 기능입니다.
export const dateConvertToCurrentTime = (numberDate) => {
  if (!Number(numberDate)) {
    return null;
  }
  const currentDate = new Date(Number(numberDate)).toLocaleTimeString("ko", {
    // day: "numeric",
    // month: "long",
    // year: "numeric",
    hour: "numeric",
    minute: "numeric",
    // fractionalSecondDigits: 0,
  });
  return currentDate;
};
