//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { SEARCH_ROOMS } from "../../queries/message/searchRooms";
import colors from "../../utils/colors";
const Searchbox = styled.div`
  width: 100%;
  position: relative;
  z-index: 103;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 15px;
`;
const SearchCon = styled.div`
  //border: 0.5px solid gray;
  width: 100%;
  input {
    //border: none;
    outline: none;
    /* padding: 5px; */
    height: ${(props) => (props?.height ? props?.height : "35px")};
    @media (max-width: 1400px) {
      max-width: 115px;
    }
  }
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
  height: 30px;
`;

const SearchInput = styled.input`
  /* padding: 15px; */
  width: 80%;
  text-align: center;
  border: 2px solid lightgray;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  margin: 0 auto;
  box-shadow: none;
  /* margin-right: 10px; */
  font-size: 14px;
  color: gray;
  @media (max-width: 1200px) {
    margin-right: 0px !important;
  }

  &:focus {
    box-shadow: none;
    border: none;
    transition: 0.3s;
    border: 2px solid ${colors.bbeego};
  }
  /* &:focus {
    border: none;
    outline: none;
    box-shadow: none;
    transition: 0.3s;
    border: 2px solid ${colors.bbeego}; */
  /* } */
`;

const SearchChatRoom = ({ setSearchData, setSearchBoxOpen }) => {
  const { register, watch, getValues } = useForm();

  const { loading, error, data } = useQuery(SEARCH_ROOMS, {
    variables: {
      keyword: watch()?.search,
    },
  });
  //   console.log(data);
  useEffect(() => {
    setSearchData(data);
  }, [data]);

  const onDropBox = (e) => {
    setSearchBoxOpen(true);
  };

  const outFocus = (e) => {
    if (
      !Array.from(e?.target?.classList).includes("Search") &&
      !e?.target?.closest("div.Search")
    ) {
      setSearchBoxOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", outFocus);
    return () => {
      window.removeEventListener("click", outFocus);
      setSearchBoxOpen(false);
    };
  }, []);

  return (
    <Searchbox>
      <SearchCon onClick={(e) => onDropBox(e)}>
        <SearchInput
          {...register("search")}
          className="searchBar Search form-control"
          placeholder="유저 검색"
          autoComplete="off"
        />
      </SearchCon>
    </Searchbox>
  );
};

export default SearchChatRoom;
