//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";

const Badge = styled.i`
  width: ${(props) => (props.width ? props.width : "100px")};
  height: ${(props) => (props.height ? props.height : "25px")};
  margin: ${(props) => (props.margin ? props?.margin : "")};
  /* color: white; */
  font-size: ${(props) => (props.fontSize ? props?.fontSize : "13px")};
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  height: ${(props) => (props.height ? props?.height : "30px")};
  cursor: unset !important;
  padding: 0.525rem 0.425rem;
  ${(props) =>
    props?.variant === "denied"
      ? `
    color:white;
    background-color: #dc3545;
    border-color: #dc3545;
  `
      : ""}
  ${(props) =>
    props?.variant === "success"
      ? `
    color:white;
    background-color: #198754;
    border-color: #198754;
  `
      : ""}
  ${(props) =>
    props.variant === "challenge"
      ? `
    color:#bf8f00;
    background-color: #ffe366;
    border-color: #ffe366;
  `
      : ""}
   ${(props) =>
    props.variant === "ptroom"
      ? `
    color:#08829a;
    background-color: #92e8f9;
    border-color: #0dcaf0;
  `
      : ""};
  ${(props) =>
    props.variant === "payment"
      ? `
    color:#1a4db0;
    background-color: #9ab7f0;
    border-color: #9ab7f0;
  `
      : ""};
  ${(props) =>
    props.variant === "rejected"
      ? `
    color:white;
    background-color: #ff0000;
    border-color: #ff0000;
  `
      : ""};
  ${(props) =>
    props.variant === "pass"
      ? `
    color:white;
    background-color: #27d383;
    border-color: #27d383;
  `
      : ""};
  ${(props) =>
    props.variant === "primary"
      ? `
    color:white;
    background-color: #0d6efd;
    border-color: #0d6efd;
  `
      : ""};
  ${(props) =>
    props.variant === "status"
      ? `
    color:#ed5f00;
    font-size : 14px;
    background-color: unset;
    border-color: unset;
  `
      : ""}
`;
export const TypeBadge = (props) => {
  return <Badge {...props}>{props.context}</Badge>;
};
