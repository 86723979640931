//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const LinkCon = styled.div`
  display: flex;
  align-items: center;
  background-color: #f2dc01;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
`;
const ALink = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  color: #371a1a;
  &:hover {
    color: #371a1a;
  }
  gap: 10px;
  font-weight: bold;
`;
const Icon = styled.div`
  font-size: 30px;
  color: #371a1a;
`;
const IconTxt = styled.div`
  white-space: nowrap;
  width: 60%;
  text-align: center;
`;

const KakaoLogin = () => {
  return (
    <LinkCon>
      <ALink
        href={`https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_OAUTH_REDIRECT_URL}&response_type=code`}
      >
        <div>
          <Icon className="bx bxs-message-rounded"></Icon>
        </div>
        <IconTxt>카카오</IconTxt>
      </ALink>
    </LinkCon>
  );
};

export default KakaoLogin;
