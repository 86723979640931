//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useState } from "react";
import styled from "styled-components";
import { rateStarsVar } from "../../utils/reactiveVar";

const SRateStars = styled.div`
  width: fit-content;
  height: ${(props) => (props?.height ? props?.height : "50px")};
  position: relative;
`;
const ClipStar = styled.div`
  clip-path: polygon(
    49% 10%,
    51% 10%,
    62% 35%,
    87.5% 35%,
    88% 37%,
    68% 55%,
    77% 79.5%,
    75% 81%,
    51% 67%,
    27% 81%,
    25% 79.5%,
    33% 55%,
    12% 37%,
    12.5% 35%,
    39% 35%
  );
  display: inline-block;
  width: ${(props) => (props?.width ? props?.width : "50px")};
  height: ${(props) => (props?.height ? props?.height : "50px")};
  background: lightgray;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    background: gold;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: ${(props) =>
      props?.percentage
        ? props?.percentage
        : "0%"}; //position 이 absolute 가 되면서 50% 가 container 기준으로 적용됨.
    font-size: 40px;
  }
`;

const RateStarsInteraction = (props) => {
  const [percentage, setPercentage] = useState(props?.percentage || "0");
  const rateSetter = (_percentage) => {
    setPercentage(_percentage);
    rateStarsVar(Number(_percentage) / 20);
  };
  useEffect(() => {
    setPercentage(props?.percentage);
  }, [props?.percentage]);
  return (
    <SRateStars {...props}>
      <ClipStar
        {...props}
        percentage={`${percentage}%`}
        onClick={() => rateSetter("20")}
      />
      <ClipStar
        {...props}
        percentage={`${percentage}%`}
        onClick={() => rateSetter("40")}
      />
      <ClipStar
        {...props}
        percentage={`${percentage}%`}
        onClick={() => rateSetter("60")}
      />
      <ClipStar
        {...props}
        percentage={`${percentage}%`}
        onClick={() => rateSetter("80")}
      />
      <ClipStar
        {...props}
        percentage={`${percentage}%`}
        onClick={() => rateSetter("100")}
      />
    </SRateStars>
  );
};

export default RateStarsInteraction;
