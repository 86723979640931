//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import gql from "graphql-tag";

export const ADMIN_CREATE_CHALLENGE = gql`
  mutation AdminCreateChallenge(
    $title: String!
    $description: String!
    $userId: Int!
    $trainerId: Int
    $traineeId: Int
    $baseprice: Int!
    $bonus: Int!
    $reward: Int!
    $currentWeight: Int!
    $targetWeight: Int!
    $currentFatPercentage: Int!
    $targetFatPercentage: Int!
    $age: Int!
    $gender: String!
    $startDate: String
    $endDate: String
    $term: Int!
  ) {
    adminCreateChallenge(
      title: $title
      description: $description
      userId: $userId
      trainerId: $trainerId
      traineeId: $traineeId
      baseprice: $baseprice
      bonus: $bonus
      reward: $reward
      currentWeight: $currentWeight
      targetWeight: $targetWeight
      currentFatPercentage: $currentFatPercentage
      targetFatPercentage: $targetFatPercentage
      age: $age
      gender: $gender
      startDate: $startDate
      endDate: $endDate
      term: $term
    ) {
      ok
      error
    }
  }
`;
