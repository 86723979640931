//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { PTPRICE_FRAGMENT } from "../fragments/pTPriceFragment";
import { REVIEW_FRAGMENT } from "../fragments/reviewFragment";
export const SEE_PTROOM = gql`
  query SeePTRoom($id: Int!) {
    seePTRoom(id: $id) {
      id
      title
      description
      thumbnail
      isMine
      trainer {
        id
        username
        profilePhoto
      }
      reviews {
        ...ReviewFragment
      }
      pTPrices {
        ...PTPriceFragment
      }
      createdAt
    }
  }
  ${PTPRICE_FRAGMENT}
  ${REVIEW_FRAGMENT}
`;

const SeePTRoom = (id) => {
  const { loading, data } = useQuery(SEE_PTROOM, {
    variables: {
      id,
    },
    ...(!id && { skip: true }),
  });
  if (!loading) {
    return { data };
  }
};
export default SeePTRoom;
