//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import axios from "axios";
import { Button, Card, Container, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { SeePTPrice } from "../queries/pTPrice/seePTPrice";
import { CREATE_RECEIPT } from "../queries/Receipt/createReceipt";
import colors from "../utils/colors";
import { routes } from "../routes/routes";
import CheckUserToken from "../utils/checkUserToken";
import { UPDATE_CONTRACT_MUTATION } from "../queries/contract/updateContract";
import { CHECK_CONTRACT_QUERY } from "../queries/contract/checkContract";
import { EXTEND_CONTRACT_MUTATION } from "../queries/contract/extendContract";

//해당 화면은 SaleInfo.js 에서 토스 결제모듈로 결제 성공 시 redirect 되는 화면입니다.
const SaleInfoConfirm = (props) => {
  const me = CheckUserToken();
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const orderId = params.get("orderId");
  const paymentKey = params.get("paymentKey");
  const amount = params.get("amount");
  const pTPriceId = orderId.split("_")[2];
  const contractId = orderId.split("_")[3];
  const isExpired = orderId.split("_")[4];
  const [updateContractMutation] = useMutation(UPDATE_CONTRACT_MUTATION, {
    refetchQueries: [
      {
        query: CHECK_CONTRACT_QUERY,
        variables: {
          pTPriceId: Number(pTPriceId),
        },
      },
    ],
    onCompleted: (data) => {
      const {
        updateContract: { ok },
      } = data;
      if (ok) {
        alert("결제가 완료되었습니다!");
        history.push(`${routes.myGym}${me?.me?.id}`);
      }
    },
  });
  const [extendContractMutation] = useMutation(EXTEND_CONTRACT_MUTATION, {
    refetchQueries: [
      {
        query: CHECK_CONTRACT_QUERY,
        variables: {
          pTPriceId: Number(pTPriceId),
        },
      },
    ],
    onCompleted: (data) => {
      const {
        extendContract: { ok },
      } = data;
      if (ok) {
        alert("기간 연장이 완료되었습니다!");
        history.push(`${routes.myGym}${me?.me?.id}`);
      }
    },
  });
  const [createReceipt] = useMutation(CREATE_RECEIPT, {
    onCompleted: (data) => {
      const {
        createReceipt: { ok },
      } = data;
      if (ok) {
        if (!!isExpired) {
          extendContractMutation({
            variables: {
              id: Number(contractId),
            },
          });
        } else {
          updateContractMutation({
            variables: {
              id: Number(contractId),
              type: "purchase",
              userId: me?.me?.id,
            },
          });
        }
      }
    },
  });

  const pTPriceData = SeePTPrice(Number(pTPriceId));

  const onPurchaseConfirmClick = async () => {
    const confirmURL = `https://api.tosspayments.com/v1/payments/${paymentKey}`;
    const data = {
      orderId,
      amount,
    };
    const res = await axios
      .post(confirmURL, JSON.stringify(data), {
        headers: {
          Authorization: `Basic ${btoa(
            `${process.env.REACT_APP_TOSS_SECRET_KEY}:`
          )}`,
          "Content-Type": "application/json",
        },
      })
      .catch((e) => {
        alert("이미 결제 승인된 건 이거나 만료된 건 입니다.");
        history.push(`${routes.myGym}${me?.me?.id}`);
      });

    createReceipt({
      variables: {
        paymentKey: String(paymentKey),
        orderId: String(orderId),
        jsonData: JSON.stringify(res?.data),
        type: "toss",
        sellerId: Number(pTPriceData?.seePTPrice?.pTRoom?.trainer?.id),
        contractId: Number(contractId),
        contractType: "PTRoom",
      },
    });
  };

  return (
    <Container style={{ width: "500px" }}>
      <Row>
        <Card>
          <Card.Body
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Card.Title>결제 내용</Card.Title>
            <Card.Text>
              상품명 : {pTPriceData?.seePTPrice?.pTRoom?.title}
            </Card.Text>
            <Card.Text>
              기간 : {pTPriceData?.seePTPrice?.month} (개월)
            </Card.Text>
            <Card.Text>
              가격 : {pTPriceData?.seePTPrice?.price * 10000}
            </Card.Text>
            <Card.Text
              style={{
                display: "flex",
                flexDirection: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="primary"
                style={{
                  backgroundColor: colors.bbeego,
                  borderColor: colors.bbeego,
                }}
                onClick={onPurchaseConfirmClick}
              >
                결제 승인
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default SaleInfoConfirm;
