//new Date().toJSON() 으로 인한 9시간 당김을 다시 되돌리는 기능입니다.
export const dateConvertToCurrent = (numberDate) => {
  if (!Number(numberDate)) {
    return null;
  }
  const currentDate = new Date(Number(numberDate) + 1000 * 60 * 60 * 9)
    .toJSON()
    ?.split("T")?.[0];
  return currentDate;
};
