//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import {
  faDumbbell,
  faPersonBooth,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardColumns } from "react-bootstrap";
import styled from "styled-components";
import CheckUserToken from "../../utils/checkUserToken";
import colors from "../../utils/colors";
import { SButton } from "../shared/SButton";

export const Container = styled.div`
  min-width: 150px;
  min-height: 210px;
  border: 2px solid orange;
  border-radius: 10px;
  box-shadow: 0 0 5px -1px orange;
  background-color: orange;
  padding: 10px;
`;
export const CouponInfo = styled.div``;
export const CouponName = styled.div`
  color: white;
  font-size: 18px;
  font-weight: bold;
`;
export const IconContainer = styled.div``;
export const CouponIcon = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 40px;
  left: -10px;
  transform: rotate(120deg);
  z-index: 1;
`;
export const Shadow = styled.div`
  width: inherit;
  height: 2.1px;
  background-color: #222222;
  position: relative;
  top: 40px;
  border-radius: 100%;
  margin: 0 30px;
  box-shadow: 0 0 5px 10px #222222;
  opacity: 0.5;
`;
const CouponInteract = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 50px;
  z-index: 3;
  gap: 5px;
`;

const CouponCard = (props) => {
  const me = CheckUserToken();

  const onGiveClick = () => {
    props.setModal(true);
    props.setSelectCoupon(props);
  };
  const onRemoveClick = () => {
    props.deleteCouponByUserMutation({
      variables: {
        givenCouponId: props?.id,
      },
    });
  };
  return (
    <Container>
      <CouponInfo>
        <CouponName>{props?.coupon?.name}</CouponName>
        <IconContainer>
          <CouponIcon>
            <FontAwesomeIcon icon={faDumbbell} size="4x" />
          </CouponIcon>
          <Shadow />
        </IconContainer>
      </CouponInfo>
      <CouponInteract>
        <CardColumns>
          <span style={{ color: "white" }}>
            ~{new Date(Number(props?.expiredAt)).toJSON()?.split("T")?.[0]}
          </span>
        </CardColumns>
        <CardColumns>
          {me?.me?.type == "trainer" && props?.isExpired === false ? (
            <SButton
              context="지급하기"
              width="100px"
              fontSize="14px"
              fontWeight="bold"
              onClick={onGiveClick}
            />
          ) : me?.me?.type == "trainer" && props?.isExpired === true ? (
            <div style={{ display: "flex", gap: "5px" }}>
              <SButton
                context="만료"
                fontSize="14px"
                fontWeight="bold"
                disabled={true}
                bgColor={colors.defaultGray}
                border={`1px solid ${colors.defaultGray}`}
                color="black"
              />
              <SButton
                context="삭제"
                fontSize="14px"
                fontWeight="bold"
                bgColor="red"
                border={`1px solid red`}
                onClick={onRemoveClick}
              />
            </div>
          ) : null}
        </CardColumns>
      </CouponInteract>
    </Container>
  );
};

export default CouponCard;
