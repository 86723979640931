//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import MainHeader from "../main/MainHeader";
import styled from "styled-components";

const Body = styled.div`
  margin: 0 auto;
  margin-top: 65px;
  display: flex;
  align-items: center;
  background-color: #fffcfa;
`;

const HomeLayout = ({ children }) => {
  return (
    <>
      <MainHeader />
      <Body>{children}</Body>
    </>
  );
};

export default HomeLayout;
