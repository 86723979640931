//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { GButton } from "../component/shared/GButton";
import { SButton } from "../component/shared/SButton";
import { SeeChallenge } from "../queries/challenge/seeChallenge";
import { CREATE_CHALLENGE_CONTRACT_MUTATION } from "../queries/challengeContract/createChallengeContract";
import CheckUserToken from "../utils/checkUserToken";
const Container = styled.div`
  margin: auto;
  width: 1920px;
  align-items: center;
  justify-content: center;
`;
const CardContainer = styled.div`
  width: 700px;
`;
const UserRow = styled.div`
  margin: 30px auto;
  width: 500px;
`;
const Profile = styled.img`
  border: 1px solid #888888;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
  object-position: left top;
`;
const UserName = styled.h3`
  text-align: center;
  font-size: 24px;
`;
const CardTitle = styled.h5`
  padding-bottom: 25px;
  font-size: 22px;
  font-weight: 700;
  color: #ed5f00;
`;
const BasicInfo = styled.div`
  width: 100%;
  min-height: 350px;
  height: 720px;
  margin: 0 auto !important;
  margin-bottom: 10px !important;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid lightgray;
  box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
`;
const DesInfo = styled.div`
  width: 670px;
  min-height: 350px;
  height: 797px;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid lightgray;
  box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 7.5fr;
  margin-bottom: 1.3rem !important;
`;

const ColTitle = styled.div`
  padding: 10px;
  line-height: 1.5;
  font-size: 18px;
  text-align: left;
`;
const ColValue = styled.div`
  border-radius: 5px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 10px;
  word-break: break-all;
`;
const Description = styled.div`
  width: 100%;
  max-height: 700px;
  padding: 20px;
  overflow: auto;
  img {
    max-width: 100%;
  }
`;
const ChallengeInfo = () => {
  const location = useLocation();

  const data = SeeChallenge(location?.state?.id);

  const me = CheckUserToken();
  const [applied, setApplied] = useState(false);

  const onCompleted = (data) => {
    const {
      createChallengeContract: { ok },
    } = data;
    if (ok) {
      alert("등록되었습니다!");
      setApplied(true);
    }
  };

  const [createChallengeContract] = useMutation(
    CREATE_CHALLENGE_CONTRACT_MUTATION,
    {
      onCompleted,
    }
  );

  const onApplyClick = () => {
    createChallengeContract({
      variables: {
        challengeId: data?.seeChallenge?.id,
      },
    });

    // createNotificationMutation({
    //   variables: {
    //     userId: data?.seeChallenge?.user?.id,
    //     type: notificationTypes.challengeRequest.type,
    //     context: notificationTypes.challengeRequest.context,
    //   },
    // });
  };

  useEffect(() => {
    const appliedContracts = data?.seeChallenge?.challengeContracts?.filter(
      (v) => {
        return v?.trainer?.id === me?.me?.id;
      }
    );

    if (appliedContracts?.length) {
      setApplied(true);
    }
  });

  useEffect(() => {
    let Description = document.getElementById("ccc");

    const des = data?.seeChallenge?.description;

    if (!!des) {
      Description.innerHTML = des;
    }
  }, [data]);
  return (
    <Container className="row ">
      {data?.seeChallenge ? (
        <>
          <CardContainer>
            {/* <Title className="card-header">상세보기</Title> */}

            <DesInfo className="card-body">
              <CardTitle>{data?.seeChallenge?.title}</CardTitle>
              <Description id="ccc">
                <div></div>
              </Description>
            </DesInfo>
          </CardContainer>

          <CardContainer>
            {/* <Title className="card-header">상세보기</Title> */}
            {me?.me?.type === "trainer" ? (
              <div style={{ width: "100%", marginBottom: "15px" }}>
                <GButton
                  context="신청하기 >"
                  width="100%"
                  fontSize="20px"
                  fontWeight="700"
                  padding="17px"
                  onClick={onApplyClick}
                  disabled={applied ? true : false}
                />
              </div>
            ) : me?.me?.type === "user" ? (
              <div style={{ width: "100%", marginBottom: "15px" }}>
                <GButton
                  context="신청하기 >"
                  width="100%"
                  fontSize="20px"
                  fontWeight="700"
                  padding="15px"
                  // onClick={onApplyClick}
                  disabled={applied ? true : false}
                />
              </div>
            ) : null}

            <BasicInfo className="card-body">
              <CardTitle>챌린지 정보</CardTitle>

              <UserRow>
                <Profile src={data?.seeChallenge?.trainee?.profilePhoto} />
                <UserName>{data?.seeChallenge?.trainee?.username}</UserName>
              </UserRow>
              <Row>
                <ColTitle>시작 날짜</ColTitle>
                <ColValue>
                  {
                    new Date(Number(data?.seeChallenge?.startDate))
                      ?.toJSON()
                      ?.split("T")[0]
                  }{" "}
                </ColValue>
              </Row>
              <Row>
                <ColTitle>종료 날짜</ColTitle>
                <ColValue>
                  {" "}
                  {
                    new Date(Number(data?.seeChallenge?.endDate))
                      ?.toJSON()
                      ?.split("T")[0]
                  }{" "}
                </ColValue>
              </Row>
              <Row>
                <ColTitle>기간 </ColTitle>
                <ColValue>{data?.seeChallenge?.term}일</ColValue>
              </Row>
              <Row>
                <ColTitle>기본금</ColTitle>
                <ColValue>{data?.seeChallenge?.baseprice}만원 </ColValue>
              </Row>
              <Row>
                <ColTitle>보너스</ColTitle>
                <ColValue>{data?.seeChallenge?.bonus}만원 </ColValue>
              </Row>
              <Row>
                <ColTitle>리워드</ColTitle>
                <ColValue>{data?.seeChallenge?.reward}만원 </ColValue>
              </Row>
            </BasicInfo>
            {me?.me?.type === "parent" ? (
              <div style={{ width: "100%", marginBottom: "15px" }}>
                <GButton
                  context="수정하기"
                  width="100%"
                  fontSize="20px"
                  fontWeight="700"
                  padding="15px"
                  margin="0 0 10px 0"
                  // onClick={onApplyClick}
                  // disabled={applied ? true : false}
                />
              </div>
            ) : null}
          </CardContainer>
        </>
      ) : (
        <div style={{ display: "flex", gap: "10px" }}>
          <SButton margin="0 auto" context="메인화면으로 돌아가기" />
          <h3>현재 존재하지 않는 챌린지입니다.</h3>
        </div>
      )}
    </Container>
  );
};
export default ChallengeInfo;
