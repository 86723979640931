//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//멀티챌린지 관리 화면의 그룹관리 카드의 그룹목록의 그룹 컴포넌트 입니다.

import { Card, CardColumns, CardGroup } from "react-bootstrap";
import styled from "styled-components";
import colors from "../../utils/colors";
import ProfilePhoto from "../shared/ProfilePhoto";

const SCardBody = styled(Card.Body)`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  gap: 10px;
  @media (max-width: 770px) {
    grid-template-columns: 1fr;
  }
`;

const MultiChallengeGroup = ({
  idx,
  group,
  selGroup,
  setSelGroup,
  userCountPerTrainer,
  statements,
}) => {
  const onGroupClick = (e) => {
    e.stopPropagation();
    setSelGroup(group?.id);
  };
  return (
    <Card
      style={
        selGroup === group?.id
          ? {
              borderColor: colors.bbeego,
              cursor: "pointer",
              borderWidth: "2px",
              transition: "border 0.5s",
            }
          : { cursor: "pointer", borderWidth: "2px" }
      }
      onClick={(e) => onGroupClick(e)}
    >
      <SCardBody>
        <CardColumns
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Card.Text style={{ fontWeight: "bold" }}>그룹 {idx + 1}</Card.Text>
          <Card.Text>
            <ProfilePhoto
              src={
                group?.multiChallengeGroupMappers?.filter(
                  (mgm) => mgm?.type === "trainer"
                )?.[0]?.user?.profilePhoto
              }
              id={
                group?.multiChallengeGroupMappers?.filter(
                  (mgm) => mgm?.type === "trainer"
                )?.[0]?.user?.id
              }
            />
          </Card.Text>
          <Card.Text style={{ fontWeight: "bold" }}>
            {
              group?.multiChallengeGroupMappers?.filter(
                (mgm) => mgm?.type === "trainer"
              )?.[0]?.user?.username
            }
          </Card.Text>
        </CardColumns>
        <CardColumns
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <CardColumns>
            <Card.Text style={{ display: "flex", gap: "10px" }}>
              <span style={{ fontWeight: "bold" }}>
                기본급:{" "}
                {Number(
                  statements?.filter(
                    (s) =>
                      s?.transactionType === "기본급" &&
                      s?.user?.type === "trainer"
                  )?.[0]?.amount
                )?.toLocaleString()}
              </span>
              {statements?.filter(
                (s) =>
                  s?.transactionType === "기본급" && s?.user?.type === "trainer"
              )?.[0]?.isTransferred === true ? (
                <span style={{ color: "green", fontWeight: "bold" }}>
                  지급완료
                </span>
              ) : (
                <span style={{ color: "gray", fontWeight: "bold" }}>
                  지급전
                </span>
              )}
            </Card.Text>
          </CardColumns>
          <CardColumns>
            <Card.Text style={{ display: "flex", gap: "10px" }}>
              <span style={{ fontWeight: "bold" }}>
                보상금:{" "}
                {Number(
                  statements?.filter(
                    (s) =>
                      s?.transactionType === "보상금" &&
                      s?.user?.type === "trainer"
                  )?.[0]?.amount
                )?.toLocaleString()}
              </span>
              {statements?.filter(
                (s) =>
                  s?.transactionType === "보상금" && s?.user?.type === "trainer"
              )?.[0]?.isTransferred === true ? (
                <span style={{ color: "green", fontWeight: "bold" }}>
                  지급완료
                </span>
              ) : (
                <span style={{ color: "gray", fontWeight: "bold" }}>
                  지급전
                </span>
              )}
            </Card.Text>
          </CardColumns>
        </CardColumns>
        <CardColumns
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            fontWeight: "bold",
            justifyContent: "flex-end",
          }}
        >
          <Card.Text>유저 수 :</Card.Text>
          <Card.Text>
            {
              group?.multiChallengeGroupMappers?.filter(
                (mgm) => mgm?.type === "user"
              )?.length
            }{" "}
            / {userCountPerTrainer}
          </Card.Text>
        </CardColumns>
      </SCardBody>
    </Card>
  );
};

export default MultiChallengeGroup;
