//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { MULTICHALLENGE_GROUP_FRAGMENT } from "../fragments/multiChallengeGroupFragment";

export const SELECT_MULTICHALLENGE_GROUP_BY_ID_QUERY = gql`
  query SelectMultiChallengeGroupById($id: Int!) {
    selectMultiChallengeGroupById(id: $id) {
      id
      multiChallenge {
        id
        title
        endDate
        isFinished
        bonus
        reward
      }
      multiChallengeGroupMappers {
        id
        multiChallengeGroup {
          id
        }
        user {
          id
          username
          type
          profilePhoto
        }
        type
        isChallengeSuccess
        challengeFailedReason
        MCGProveFiles {
          id
          multiChallengeGroupMapper {
            id
          }
          filepath
          createdAt
          updatedAt
          deletedAt
          editable
        }
        createdAt
        updatedAt
        deletedAt
      }
      chatroom {
        id
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const SelectMultiChallengeGroupById = (id) => {
  const { data } = useQuery(SELECT_MULTICHALLENGE_GROUP_BY_ID_QUERY, {
    variables: {
      id,
    },
  });
  return { data };
};
