//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";

const Btn = styled.button`
  opacity: 0.8;
  color: ${(props) => (props.color ? props.color : "#fff")};
  font-size: 13px;
  padding: ${(props) => (props.padding ? props.padding : "10px")};
  border-radius: ${(props) => (props.radius ? props.radius : "5px")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  position: relative;
  top: ${(props) => (props.top ? props.top : "")};
  right: ${(props) => (props.right ? props.right : "")};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "1px 2.5px 5px -1px #fd780f"};
  border: ${(props) => (props.border ? props.border : "1px solid #fd780f")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#fd780f")};
  &:hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : ""};
  }
  &:active {
    /* box-shadow: ${(props) =>
      props.activeShadow ? props.activeShadow : "1px 1px 0 #0d6efd"}; */
    opacity: 0.9;
    top: 2px;
  }
`;

export const SButton = (props) => {
  return <Btn {...props}>{props.context}</Btn>;
};
