//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { Card, CardColumns } from "react-bootstrap";
import colors from "../../../utils/colors";
import ProfilePhoto from "../../shared/ProfilePhoto";

const MultiChallengeGroupManageUser = ({
  groupMapper,
  selGroupMapper,
  setSelGroupMapper,
  statement,
}) => {
  const onUserClick = (e) => {
    e.stopPropagation();
    setSelGroupMapper(groupMapper);
  };

  return (
    <Card
      style={
        selGroupMapper?.id === groupMapper?.id
          ? {
              borderColor: colors.bbeego,
              cursor: "pointer",
              borderWidth: "2px",
              transition: "border 0.5s",
            }
          : { cursor: "pointer", borderWidth: "2px" }
      }
      onClick={(e) => onUserClick(e)}
    >
      <Card.Body style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CardColumns
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "auto auto 100px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <CardColumns
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Card.Text>
              <ProfilePhoto
                src={groupMapper?.user?.profilePhoto}
                id={groupMapper?.user?.id}
              />
            </Card.Text>
            <Card.Text style={{ fontWeight: "bold" }}>
              {groupMapper?.user?.username}
            </Card.Text>
          </CardColumns>
          <CardColumns style={{ width: "100%" }}>
            <Card.Text style={{ display: "flex", gap: "10px" }}>
              <span style={{ fontWeight: "bold" }}>
                보상금: {Number(statement?.amount || 0)?.toLocaleString()}
              </span>
              {statement?.isTransferred === true ? (
                <span style={{ color: "green", fontWeight: "bold" }}>
                  지급완료
                </span>
              ) : statement?.isTransferred === false ? (
                <span style={{ color: "gray", fontWeight: "bold" }}>
                  지급전
                </span>
              ) : (
                <span style={{ color: "gray", fontWeight: "bold" }}>
                  보상금 없음
                </span>
              )}
            </Card.Text>
          </CardColumns>
          <CardColumns
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Card.Text>
              {groupMapper?.isChallengeSuccess === true ? (
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-success border-2 text-success"
                >
                  성공중
                </span>
              ) : groupMapper?.isChallengeSuccess === false ? (
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-danger border-2 text-danger"
                >
                  실패
                </span>
              ) : (
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-secondary border-2 text-secondary"
                >
                  평가없음
                </span>
              )}
            </Card.Text>
          </CardColumns>
        </CardColumns>
      </Card.Body>
    </Card>
  );
};

export default MultiChallengeGroupManageUser;
