//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { Card, CardColumns, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import RateStars from "../component/review/RateStars";
import RateStarsInteraction from "../component/review/RateStarsInteraction";
import { FatText } from "../component/shared/FatText";
import { GButton } from "../component/shared/GButton";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import { CREATE_REVIEW_BY_USER_MUTATION } from "../queries/review/createReviewByUser";
import { DELETE_REVIEW_BY_ID_MUTATION } from "../queries/review/deleteReviewById";
import {
  SelectReviewByUser,
  SELECT_REVIEW_BY_USER_QUERY,
} from "../queries/review/selectReviewByUser";
import {
  SelectReviewsByProductId,
  SELECT_REVIEWS_BY_PRODUCT_ID_QUERY,
} from "../queries/review/selectReviewsByProductId";
import { UPDATE_REVIEW_BY_USER_MUTATION } from "../queries/review/updateReviewByUser";
import CheckUserToken from "../utils/checkUserToken";
import { rateStarsVar } from "../utils/reactiveVar";

const Rate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { register, setValue, getValues } = useForm();
  const location = useLocation();
  //states
  const [isReviewed, setIsReviewed] = useState(false);
  //로그인한 유저 정보
  const user = CheckUserToken();
  //내가 쓴 리뷰가 있는지 확인
  const { data: myReview } = SelectReviewByUser(
    location?.state?.type,
    Number(location?.state?.typeId)
  );
  //해당 상품의 리뷰들 조회
  const { data: reviews } = SelectReviewsByProductId(
    location?.state?.type,
    Number(location?.state?.typeId)
  );
  //리뷰 생성
  const [createReviewByUserMutation] = useMutation(
    CREATE_REVIEW_BY_USER_MUTATION,
    {
      refetchQueries: [
        {
          query: SELECT_REVIEW_BY_USER_QUERY,
          variables: {
            type: location?.state?.type,
            typeId: Number(location?.state?.typeId),
          },
        },
        {
          query: SELECT_REVIEWS_BY_PRODUCT_ID_QUERY,
          variables: {
            type: location?.state?.type,
            typeId: Number(location?.state?.typeId),
          },
        },
      ],
    }
  );
  //리뷰 수정
  const [updateReviewByUserMutation] = useMutation(
    UPDATE_REVIEW_BY_USER_MUTATION,
    {
      refetchQueries: [
        {
          query: SELECT_REVIEW_BY_USER_QUERY,
          variables: {
            type: location?.state?.type,
            typeId: Number(location?.state?.typeId),
          },
        },
        {
          query: SELECT_REVIEWS_BY_PRODUCT_ID_QUERY,
          variables: {
            type: location?.state?.type,
            typeId: Number(location?.state?.typeId),
          },
        },
      ],
    }
  );
  //리뷰 삭제
  const [deleteReviewByIdMutation] = useMutation(DELETE_REVIEW_BY_ID_MUTATION, {
    refetchQueries: [
      {
        query: SELECT_REVIEW_BY_USER_QUERY,
        variables: {
          type: location?.state?.type,
          typeId: Number(location?.state?.typeId),
        },
      },
      {
        query: SELECT_REVIEWS_BY_PRODUCT_ID_QUERY,
        variables: {
          type: location?.state?.type,
          typeId: Number(location?.state?.typeId),
        },
      },
    ],
  });

  //별점 전역 변수
  const rateStarsNumber = useReactiveVar(rateStarsVar);

  //해당 컴포넌트에서 벗어날 시.
  useEffect(() => {
    return () => {
      rateStarsVar("0");
    };
  }, []);
  //내 리뷰 데이터가 있을 시
  useEffect(() => {
    if (myReview?.selectReviewByUser) {
      setValue("comment", myReview?.selectReviewByUser?.comment);
      setIsReviewed(true);
    } else {
      setValue("comment", "");
      setIsReviewed(false);
    }
  }, [myReview]);

  //생성 기능
  const onCreateReview = () => {
    const { comment } = getValues();

    createReviewByUserMutation({
      variables: {
        type: "PTRoom",
        typeId: Number(location?.state?.typeId),
        rate: Number(rateStarsNumber),
        comment,
      },
    });
  };
  //수정 기능
  const onUpdateReview = () => {
    const confirmBool = window.confirm("리뷰를 수정하시겠습니까?");
    if (!!confirmBool) {
      const { comment } = getValues();

      updateReviewByUserMutation({
        variables: {
          id: myReview?.selectReviewByUser?.id,
          rate: Number(rateStarsNumber),
          comment,
        },
      });
    }
  };
  //삭제 기능
  const onDeleteReview = () => {
    const confirmBool = window.confirm("리뷰를 삭제하시겠습니까?");
    if (!!confirmBool) {
      deleteReviewByIdMutation({
        variables: {
          id: myReview?.selectReviewByUser?.id,
        },
      });
    }
  };
  return (
    <Container
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <CardColumns>
        <Card>
          <Card.Body>
            <CardColumns
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <CardColumns
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Card.Title style={{ margin: 0, fontWeight: "bold" }}>
                  상품 리뷰
                </Card.Title>
                <CardColumns>
                  <RateStars
                    avgRate={
                      reviews?.selectReviewsByProductId?.[0]?.avgRate
                        ? `${
                            reviews?.selectReviewsByProductId?.[0]?.avgRate * 20
                          }%`
                        : "0"
                    }
                    width="30px"
                    height="30px"
                  />
                </CardColumns>
                <CardColumns>
                  <FatText>
                    {reviews?.selectReviewsByProductId?.[0]?.avgRate
                      ? reviews?.selectReviewsByProductId?.[0]?.avgRate
                      : 0}
                  </FatText>
                </CardColumns>
              </CardColumns>
              <CardColumns>
                <span>상품명 : {location?.state?.title}</span>
              </CardColumns>
            </CardColumns>
          </Card.Body>
        </Card>
      </CardColumns>
      {/* 내 리뷰 */}
      <CardColumns>
        <CardColumns
          style={{ display: "flex", flexDirection: "column", gap: "3px" }}
        >
          <CardColumns>
            <RateStarsInteraction
              width="30px"
              height="30px"
              percentage={
                myReview?.selectReviewByUser?.rate
                  ? `${myReview?.selectReviewByUser?.rate * 20}`
                  : "0"
              }
            />
          </CardColumns>
          {location?.state?.pTPrices?.reduce((acc, p) => {
            if (
              !!p?.contracts?.filter(
                (c) =>
                  c?.users?.map((u) => u.id).includes(user?.me?.id) &&
                  !!c?.startDate &&
                  !!c?.endDate
              )?.length
            ) {
              acc = acc + 1;
            }
            return acc;
          }, 0) > 0 ? (
            <CardColumns
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              <CardColumns style={{ width: "100%" }}>
                <Form.Control
                  as="textarea"
                  style={{ resize: "none" }}
                  {...register("comment")}
                />
              </CardColumns>

              {isReviewed ? (
                <CardColumns
                  style={{
                    minWidth: "200px",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <GButton
                    variant="create"
                    context="수정 하기"
                    height="100%"
                    onClick={onUpdateReview}
                  />
                  <GButton
                    variant="delete"
                    context="삭제 하기"
                    height="100%"
                    onClick={onDeleteReview}
                  />
                </CardColumns>
              ) : (
                <CardColumns style={{ minWidth: "100px" }}>
                  <GButton
                    variant="create"
                    context="평가 하기"
                    height="100%"
                    onClick={onCreateReview}
                  />
                </CardColumns>
              )}
            </CardColumns>
          ) : null}
        </CardColumns>
      </CardColumns>
      <div style={{ height: "1px", border: "1px solid lightgray" }}></div>
      {/* 리뷰 리스트 */}
      <CardColumns
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          padding: "10px",
        }}
      >
        {reviews?.selectReviewsByProductId?.map((r, idx) => (
          <CardColumns
            key={idx}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            {/* 유저정보 */}
            <CardColumns>
              <CardColumns
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  paddingLeft: "10px",
                }}
              >
                <ProfilePhoto src={r?.user?.profilePhoto} id={r?.user?.id} />
                <FatText>{r?.user?.username}</FatText>
                <RateStars
                  avgRate={`${r?.rate * 20}%`}
                  width="20px"
                  height="20px"
                />
              </CardColumns>
            </CardColumns>
            {/* 유저 리뷰 */}
            <CardColumns>
              <CardColumns style={{ paddingLeft: "15px" }}>
                <span>{r?.comment}</span>
              </CardColumns>
            </CardColumns>
            <div style={{ height: "1px", border: "1px solid #dddddd" }}></div>
          </CardColumns>
        ))}
      </CardColumns>
    </Container>
  );
};

export default Rate;
