//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import Grid from "@toast-ui/react-grid";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import AdminSelectChallengeContract from "../../queries/admins/adminSelectChallengeContract";

const Container = styled.div`
  width: 99%;
  background-color: white;
  padding: 10px;
  margin: 15px auto;
  box-shadow: 0 2px 5px #ccc;
  border-radius: 5px;
  .vBtn {
    border: none;
    background-color: white;
    margin: 5px;
    text-align: center;
    width: 100%;
    color: gray;
  }
  .vBtn:hover {
    background-color: orange;
    color: white;
    font-size: 25px;
    border-radius: 5px;
  }
`;

const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

const FontCon = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChallengeContractGrid = () => {
  const gridRef = useRef();
  const data = AdminSelectChallengeContract(100);
  const [aHidden, setHidden] = useState(false);

  const toggleMenu = () => {
    setHidden(!aHidden);
  };

  const ContractColumns = [
    { name: "id", header: "아이디", align: "center", hidden: "true" },
    { name: "challengeId", header: "챌린지 아이디", align: "center" },
    { name: "trainerId", header: "트레이너 아이디", align: "center" },
    { name: "accepted", header: "선택 여부", align: "center" },
    { name: "createdAt", header: "생성 날짜", align: "center" },
    { name: "updatedAt", header: "업데이트 날짜", align: "center" },
    { name: "deltedAt", header: "삭제 날짜", align: "center", hidden: "true" },
  ];

  useEffect(() => {
    gridRef?.current?.getInstance().resetData(
      data?.adminSelectChallengeContract?.map((v) => {
        return {
          ...v,
          challengeId: v.challenge.id,
          trainerId: v.trainer.id,
          createdAt: v.createdAt
            ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
            : v.createdAt,
          updatedAt: v.updatedAt
            ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
            : v.updatedAt,
        };
      })
    );
  }, [data]);

  return (
    <Container>
      <button className="vBtn" onClick={() => toggleMenu()}>
        {aHidden ? (
          <FontCon>
            Challenge Contract Close
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </FontCon>
        ) : (
          <FontCon>
            Challenge Contract Open
            <FontAwesomeIcon size="2x" icon={faAngleRight} />
          </FontCon>
        )}
      </button>
      {aHidden ? (
        <UserContainer>
          <Row margin="10px 0">
            {data?.adminSelectChallengeContract?.length && (
              <Grid
                ref={gridRef}
                data={data?.adminSelectChallengeContract?.map((v) => {
                  return {
                    ...v,
                    challengeId: v.challenge.id,
                    trainerId: v.trainer.id,
                    createdAt: v.createdAt
                      ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
                      : v.createdAt,
                    updatedAt: v.updatedAt
                      ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
                      : v.updatedAt,
                  };
                })}
                width="auto"
                bodyHeight={300}
                columns={ContractColumns}
                rowHeaders={["rowNum"]}
                pageOptions={{
                  perPage: 0,
                  useClient: true,
                  type: "scroll",
                }}
              />
            )}
          </Row>
        </UserContainer>
      ) : null}
    </Container>
  );
};
export default ChallengeContractGrid;
