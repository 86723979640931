//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql } from "@apollo/client";
import { CONTRACT_FRAGMENT } from "../fragments/contractFragment";
import { MULTICHALLENGE_GROUP_FRAGMENT } from "../fragments/multiChallengeGroupFragment";

export const SEE_ROOM_LIST = gql`
  query SeeRoomList {
    seeRoomList {
      id
      ownerId
      consult {
        id
        trainee {
          id
          profilePhoto
          type
          username
        }
        trainer {
          id
          profilePhoto
          type
          username
        }
      }
      messages {
        id
        checkedUserIds
      }
      users {
        id
        username
        profilePhoto
      }
      userTo {
        id
        username
        profilePhoto
      }
      multiChallengeGroup {
        id
        multiChallenge {
          id
          title
          endDate
          isFinished
          bonus
          reward
        }
        chatroom {
          id
        }
        createdAt
        updatedAt
        deletedAt
      }
      contract {
        ...ContractFragment
      }
    }
  }

  ${CONTRACT_FRAGMENT}
`;
