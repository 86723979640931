import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Card, CardColumns, Container } from "react-bootstrap";
import { SELECT_STATEMENTS_QUERY } from "../../queries/admins/statement/selectStatements";
import { DataGrid } from "@mui/x-data-grid";
import { GButton } from "../../component/shared/GButton";
import { UPDATE_STATEMENT_BY_ID_MUTATION } from "../../queries/admins/statement/updateStatement";

//어드민만이 접근 가능한 route 입니다.
const AdminManagement = () => {
  //이체 전 방어로직 on/off
  const [askAgain] = useState(true);
  //Statement 불러오기
  const [statements, setStatements] = useState([]);
  const onSelectStatementsCompleted = (data) => {
    setStatements(data?.selectStatements);
  };
  const [selectStatementsLazy] = useLazyQuery(SELECT_STATEMENTS_QUERY, {
    onCompleted: onSelectStatementsCompleted,
    fetchPolicy: "network-only",
  });
  //계좌이체 후 활성화 된 이체 버튼 클릭시
  const onUpdateStatementUpdate = (cache, data) => {
    const {
      data: {
        updateStatementById: { ok },
      },
    } = data;
    if (!!ok) {
      selectStatementsLazy();
    }
  };
  const [updateStatementMutation] = useMutation(
    UPDATE_STATEMENT_BY_ID_MUTATION,
    {
      update: onUpdateStatementUpdate,
    }
  );
  const onTransferClick = async (id, contractType) => {
    //id : statement id
    let confirmBool = () => window.confirm("이체 하시겠습니까?");
    let confirmRes = null;
    if (!!askAgain) {
      confirmRes = confirmBool();
      if (!!confirmRes) {
        updateStatementMutation({
          variables: {
            id,
            contractType,
          },
        });
      }
    } else {
      updateStatementMutation({
        variables: {
          id,
          contractType,
        },
      });
    }
  };
  const columns = [
    { field: "id", headerName: "No.", width: 80, disableColumnMenu: true },
    {
      field: "receipt_contractType",
      headerName: "계약 종류",
      minWidth: 170,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.receipt?.contractType;
      },
    },
    {
      field: "transactionType",
      headerName: "거래 종류",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "user",
      headerName: "판매자",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      valueFormatter: (cellValue) => {
        return cellValue?.value?.username;
      },
    },
    {
      field: "userRealName",
      headerName: "판매자 이름",
      minWidth: 110,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "buyer",
      headerName: "구매자",
      minWidth: 75,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      valueFormatter: (cellValue) => {
        return cellValue?.value?.username;
      },
      headerAlign: "center",
    },
    {
      field: "bank",
      headerName: "판매자 은행명",
      minWidth: 130,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "accountNumber",
      headerName: "판매자 계좌번호",
      minWidth: 140,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "orgAmount",
      headerName: "기존 금액",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "amount",
      headerName: "지급 금액",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remainAmount",
      headerName: "잔여 금액",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isConfirmed",
      headerName: "지급 가능 여부",
      minWidth: 140,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isTransferred",
      headerName: "지급 여부",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "receipt",
      headerName: "거래 상태",
      minWidth: 160,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      valueFormatter: (cellValue) => {
        return cellValue?.value?.buyerTransactionStatus;
      },
    },
    // {
    //   field: "createdAt",
    //   headerName: "생성 날짜",
    //   minWidth: 100,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   align: "center",
    //   valueFormatter: (cellValue) => {
    //     console.log(cellValue);
    //     return cellValue?.value
    //       ? new Date(Number(cellValue?.value)).toJSON()?.split("T")[0]
    //       : null;
    //   },
    // },
    {
      field: "updatedAt",
      headerName: "수정 날짜",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      valueFormatter: (cellValue) => {
        return cellValue?.value
          ? new Date(Number(cellValue?.value)).toJSON()?.split("T")[0]
          : null;
      },
    },
    {
      field: "transfer",
      headerName: "이체 처리",
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      cellClassName: "noLineHeight",
      renderCell: (params) => {
        if (
          params?.row?.isConfirmed === true &&
          params?.row?.isTransferred === false
        ) {
          return (
            <GButton
              variant="create"
              context="이체"
              onClick={() =>
                onTransferClick(
                  params?.row?.id,
                  params?.row?.receipt?.contractType
                )
              }
            />
          );
        } else if (
          params?.row?.isConfirmed === true &&
          params?.row?.isTransferred === true
        ) {
          return (
            <GButton variant="success" context="이체 완료" disabled={true} />
          );
        } else if (params?.row?.isConfirmed === false) {
          return (
            <GButton variant="update" context="구매 확정전" disabled={true} />
          );
        }
      },
    },
    // {
    //   field: "deletedAt",
    //   headerName: "삭제 날짜",
    //   minWidth: 100,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   align: "center",
    //   valueFormatter: (cellValue) => {
    //     console.log(cellValue);
    //     return cellValue?.value
    //       ? new Date(Number(cellValue?.value)).toJSON()?.split("T")[0]
    //       : null;
    //   },
    // },
  ];

  useEffect(() => {
    selectStatementsLazy();
  }, []);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        marginTop: "10px",
        maxWidth: "100%",
      }}
    >
      <Card>
        <Card.Body>
          <CardColumns>
            <Card.Title style={{ margin: 0 }}>계좌 이체 목록</Card.Title>
          </CardColumns>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <CardColumns
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              color: "red",
            }}
          >
            <CardColumns>
              - 하기 목록에서 이체가 가능한 건을 확인할 수 있습니다.
            </CardColumns>
            <CardColumns>
              - "이체" 라고 기입돼있는 파란버튼이 활성화 되어 있다면 이체가
              가능한 건입니다.
            </CardColumns>
            <CardColumns>
              - 이체 기능은 현재 구현되지 않아 수동으로 직접 계좌이체를 해야
              합니다.
            </CardColumns>
            <CardColumns>
              - 이체를 완료하였다면 해당 건의 버튼을 클릭하여 주십시오.
            </CardColumns>
          </CardColumns>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <CardColumns>
            <DataGrid
              rows={statements ? statements : []}
              columns={columns}
              style={{ minHeight: "500px" }}
            />
          </CardColumns>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AdminManagement;
