import { gql } from "@apollo/client/core";
import { CONTRACT_FRAGMENT } from "../fragments/contractFragment";
import { MULTICHALLENGE_CONTRACT_FRAGMENT } from "../fragments/multiChallengeContractFragment";

export const SELECT_RELATED_PRODUCT_QUERY = gql`
  query SelectRelatedProduct($contractType: String!, $contractId: Int!) {
    selectRelatedProduct(contractType: $contractType, contractId: $contractId) {
      ... on Contract {
        ...ContractFragment
      }
      ... on MultiChallengeContract {
        ...MultiChallengeContractFragment
      }
    }
  }
  ${CONTRACT_FRAGMENT}
  ${MULTICHALLENGE_CONTRACT_FRAGMENT}
`;
