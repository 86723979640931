//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useRef } from "react";
import styled from "styled-components";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
const ObjContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "220px")};
  height: ${(props) => (props.height ? props.height : "220px")};
  margin: ${(props) => (props.margin ? props.margin : "")};
`;
const ObjRenderer = ({ filepath, width, height, margin, zoom }) => {
  /*
    기본세팅
     - canvas : 최상위 container
     - scene : object 들이 있는 장소
     - camera : scene 을 주시하는 카메라
     - renderer : scene 과 camera 를 인자로 받아 canvas 에 렌더링
     - controls : 마우스로 object 를 360도 회전시킬 수 있게하는 기능
     - light : object 로 빛 역할을 함. scene 에 추가할 수 있다.
  */
  //filepath = "http://175.208.129.153:4000/static/1631081831405man001.obj";
  const canvas = useRef();
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.1,
    200
  );
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  const controls = new OrbitControls(camera, renderer.domElement);
  let light1 = new THREE.DirectionalLight();
  let light2 = new THREE.DirectionalLight();
  light1.position.set(0, 0, 1);
  light2.position.set(0, 0, -1);
  scene.add(light1);
  scene.add(light2);
  camera.position.z = zoom ? zoom : 2.1;
  controls.update();
  let _object = null; //rendering 된 object 를 담을 전역 변수

  useEffect(() => {
    const loader = new OBJLoader();
    loader.load(
      filepath,
      (object) => {
        _object = object;
        scene.add(_object);
        scene.rotateY(6.2);
      },
      (xhr) => {
        // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (err) => {}
    );
    renderer.setSize(canvas?.current.clientWidth, canvas?.current.clientHeight);
    camera.aspect = canvas?.current.clientWidth / canvas?.current.clientHeight;
    camera.updateProjectionMatrix();
    renderer.render(scene, camera);
    canvas?.current.appendChild(renderer.domElement);
  }, [canvas.current]);
  const animate = () => {
    requestAnimationFrame(animate);
    controls.update();
    renderer.render(scene, camera);
  };
  useEffect(() => {
    animate();
  });
  return (
    <ObjContainer
      margin={margin}
      width={width}
      height={height}
      ref={canvas}
    ></ObjContainer>
  );
};

export default ObjRenderer;
