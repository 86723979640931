//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import axios from "axios";

export const purchaseCancel = async (paymentKey, cancelReason) => {
  const apiURL = `https://api.tosspayments.com/v1/payments/${paymentKey}/cancel`;
  const data = {
    cancelReason,
  };
  const res = await axios
    .post(apiURL, JSON.stringify(data), {
      headers: {
        Authorization: `Basic ${btoa(
          `${process.env.REACT_APP_TOSS_SECRET_KEY}:`
        )}`,
        "Content-Type": "application/json",
      },
    })
    .catch((e) => {
      alert("취소할 수 없는 상품입니다!");
    });

  return res;
};
