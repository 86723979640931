import { gql } from "@apollo/client";

export const CUSTOMERSERVICE_FRAGMENT = gql`
  fragment CustomerServiceFragment on CustomerService {
    id
    title
    type
    etcType
    user {
      id
      username
      profilePhoto
      type
    }
    chatRoom {
      id
      users {
        id
        username
        profilePhoto
        type
      }
    }
    counselor {
      id
      username
      profilePhoto
      type
    }
    finishedAt
    createdAt
    updatedAt
    deletedAt
  }
`;
