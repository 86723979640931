//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useQuery, gql } from "@apollo/client";

const SEE_PTPRICE_QUERY = gql`
  query SeePTPrice($id: Int!) {
    seePTPrice(id: $id) {
      id
      price
      pTRoom {
        id
        title
        description
        trainer {
          id
          username
          profilePhoto
        }
        isMine
      }
      month
      contracts {
        id
        users {
          id
          username
          profilePhoto
        }
        customer {
          id
          username
          profilePhoto
        }
        accepted
        purchased
        denied
      }
      createdAt
    }
  }
`;

export const SeePTPrice = (id) => {
  const { data } = useQuery(SEE_PTPRICE_QUERY, { variables: { id } });
  return data;
};
