import React from "react";
import gif from "../../static/spinner.gif";
import styled from "styled-components";

const Background = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #ffffffb7;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled.div`
  font: 1rem "Noto Sans KR";
  font-weight: 600;
  text-align: center;
`;

const Spinner = () => {
  return (
    <Background>
      <img src={gif} alt="로딩중" width="5%" />
      <LoadingText>잠시만 기다려 주세요.</LoadingText>
    </Background>
  );
};

export default Spinner;
