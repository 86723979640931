//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import Editor from "../../component/PTroom/EditorComponent";
import { CREATE_MULTICHALLENGE_MUTATION } from "../../queries/multiChallenge/createMultiChallenge";
import { GButton } from "../../component/shared/GButton";
import { CardColumns, Container, Modal } from "react-bootstrap";
import ImageCrop from "../../component/shared/ImageCrop";
import { routes } from "../routes";
import { useHistory } from "react-router-dom";
import { Checkbox } from "@material-ui/core";
import UseFormError from "../../Accounts/UseFormError";
import AddPicture from "../../component/shared/AddPicture";
import NoThumbnail from "../../component/shared/NOThumnail";
import IsThumbDropZone from "../../component/shared/isThumbDropZone";

const CreateForm = styled.form`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: inherit;
  justify-content: center;
  align-items: center;
  padding: 30px;

  row-gap: 20px;
  /* border: 1px solid #ced4da; */
  flex-wrap: wrap;
  /* box-shadow: 0 0 29px 0 rgb(18 66 101 / 8%);
  border-radius: 10px; */

  @media (max-width: 1000px) {
    padding: 10px;
  }
`;
const Value = styled.input`
  width: 90%;
  text-align: end;
  @media (max-width: 900px) {
    max-width: 80%;
  }
`;
const Title = styled.input`
  /* margin-right: 10px; */

  max-width: 720px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const BtnForm = styled.div`
  display: flex;
  padding: 10px;
  column-gap: 10px;
`;
const FeeAlarm = styled.div`
  color: red;
  font-weight: bold;
`;
const Submit = styled.input``;
const InputCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 38px;
  /* gap: 10px; */
  @media (max-width: 1000px) {
    margin-bottom: 5px;
    line-height: 38px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
  @media (max-width: 550px) {
    .hidden {
      display: none !important;
    }
  }
`;
const InputDes = styled.div`
  color: #0752ab;
  line-height: 38px;
  font-size: 12px;
  margin-left: 5px;
`;
const InputComment = styled.span`
  color: red;
  @media (max-width: 1000px) {
  }
  /* margin-left: 10px; */
`;
const CropConfirm = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;
  justify-content: center;
`;
const EditCon = styled(CardColumns)`
  display: flex;
  min-height: 700px;
  .ql-container {
    max-width: 680px;
    min-height: 650px;
  }
  @media (max-width: 980px) {
    .ql-editor {
      min-height: 650px;
    }
  }
`;

const ColumnInfo = styled(CardColumns)`
  display: flex;
  flex-direction: column;
  line-height: 38px;
  /* margin-bottom: 10px; */
  /* gap: 10px; */
  /* height: 75px; */
  @media (max-width: 1000px) {
    justify-content: flex-start;
    flex-direction: column;
  }
  @media (max-width: 400px) {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RowInfo = styled(ColumnInfo)`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    display: flex;
    gap: 10px;
    /* flex-direction: column; */
    align-items: flex-start;
  }

  margin-bottom: 10px;
`;

const ThumbCon = styled(CardColumns)`
  display: flex;
  gap: 5px;
  /* grid-template-rows: repeat(2, auto); */
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  @media (min-width: 550px) {
    .appear {
      display: none !important;
    }
  }
  @media (max-width: 550px) {
    width: 50%;
  }
`;

const ThumImg = styled.img`
  width: 150px;
  border: 1px solid rgb(230, 229, 239);
`;

const dataURLtoFile = (dataurl, fileName) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

const MultiChallengeControl = () => {
  const history = useHistory();

  const [thumbnail, setThumbnail] = useState(null);
  const [dropImage, setDropImage] = useState(null);
  const [quillValue, setQuillValue] = useState("");
  const [quillFiles, setQuillFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [challengeId] = useState(0);

  //퍼스널 챌린지 여부
  const [isPersonal, setIsPersonal] = useState(false);

  //썸네일
  const [cropImage, setCropImage] = useState();

  const getModal = (modal) => {
    setModal(modal);
  };

  const getThumbnail = async (file) => {
    setDropImage(file);
  };

  useEffect(() => {
    if (dropImage != null) {
      setLoading(true);
    }
  }, [dropImage]);

  const { register, handleSubmit, setValue, getValues, formState } = useForm({
    mode: "onChange",
  });

  //quill
  const onEditorChange = (content, delta, source, editor) => {
    let fileArr = [];
    const regex = /(src="data.*?)(?=")/g;
    // const regex = /(?<=src=")(data.*?)(?=")/g;
    if (content.match(regex)?.[0]) {
      const imgDatas = content.match(regex);
      for (let i = 0; i < imgDatas.length; i++) {
        try {
          imgDatas[i] = imgDatas[i].replace('src="', "");
          fileArr.push(dataURLtoFile(imgDatas[i], "quillImg.png"));
        } catch (e) {
          //fileArr.push(imgDatas[i]);
        }
      }
    }
    const quillData = content.replace(regex, 'src="|||quill|||');
    //화면 띄우는 용
    setQuillValue(content);
    //서버 전송용
    setValue("description", quillData);
    setQuillFiles(fileArr);
  };
  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const onDateChange = (e) => {
    setDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    let term = e.target.value;
    if (e.target.value > 365) {
      term = 365;
    } else if (e.target.value <= 10) {
      term = 10;
    }
    console.log(getValues());
    setEndDate(term);
  };
  //graphql
  const onCreateMultiChallengeComplete = (data) => {
    const {
      createMultiChallenge: { ok, id },
    } = data;
    if (ok) {
      alert(
        "챌린지가 생성되었습니다!\n이어지는 화면에서 보상금 결제를 진행해주세요!"
      );
      history.push(routes?.multiChallengeManagement, {
        id,
      });
    }
  };
  const [createMultiChallengeMutation] = useMutation(
    CREATE_MULTICHALLENGE_MUTATION,
    {
      onCompleted: onCreateMultiChallengeComplete,
    }
  );

  const onSubmitValid = (challengeData) => {
    if (!challengeId) {
      const {
        title,
        description,
        baseprice,
        bonus,
        reward,
        term,
        startDate,
        endDate,
        trainerCount,
        userCountPerTrainer,
      } = challengeData;
      let val = window.confirm(
        "챌린지 보상금관련 결제는 이어서 나오는 화면에서 진행하시면 됩니다.\n챌린지는 수정이 불가하여 잘못 생성했을 경우\n삭제 후 다시 생성 하셔야 합니다.\n진행 하시겠습니까?"
      );
      if (val === true) {
        if (!description) {
          return alert("설명이 없습니다!");
        }
        if (!thumbnail) {
          return alert("썸네일이 없습니다!");
        }
        createMultiChallengeMutation({
          variables: {
            title,
            thumbnail,
            description,
            descriptionImages: quillFiles,
            baseprice: Number(baseprice),
            bonus: Number(bonus),
            reward: Number(reward),
            term: Number(term),
            startDate,
            endDate,
            trainerCount: Number(trainerCount),
            userCountPerTrainer: Number(userCountPerTrainer),
            ...(!!isPersonal && { isPersonal }),
          },
        });
      }
    } else {
      const { description } = challengeData;
      let val = window.confirm("챌린지를 수정합니다.");
      if (!description) {
        return alert("설명이 없습니다!");
      }
      if (val === true) {
      }
    }
  };
  useEffect(() => {
    const { term } = getValues();
    const finishDate = new Date(
      new Date(date + " 00:00:00").getTime() +
        (term * (1000 * 60 * 60 * 24) - 1)
    )
      .toJSON()
      ?.split("T")[0];

    // setEnd(finishDate);
    setValue("endDate", finishDate);
  }, [date, endDate]);

  useEffect(() => {
    setValue("term", 10);
  }, []);
  //챌린지 대상을 나로 지정
  const getCropImage = (url) => {
    setCropImage(url);
  };
  return (
    <Container
      style={{
        backgroundColor: "rgb(250,250,252)",
        borderRadius: "10px",
        maxWidth: "768px",
      }}
    >
      {/* <CreateChallengeCon id="main"> */}
      <CreateForm onSubmit={handleSubmit(onSubmitValid)}>
        <CardColumns
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <ColumnInfo>
            <InputCon>
              <CardColumns
                style={{
                  color: "rgb(99, 110, 114)",
                  fontWeight: "bold",
                  minWidth: "100px",
                }}
              >
                <InputComment>*</InputComment>제목{" "}
              </CardColumns>{" "}
              <Title
                {...register("title", {
                  required: { value: true, message: "제목은 필수값입니다." },
                })}
                className="form-control"
                type="text"
              />
            </InputCon>
            <UseFormError
              lineHeight="38px"
              message={formState?.errors?.title?.message}
            />
          </ColumnInfo>

          <ColumnInfo>
            <InputCon>
              <CardColumns
                style={{
                  color: "rgb(99, 110, 114)",
                  fontWeight: "bold",
                  minWidth: "100px",
                  height: "38px",
                }}
              >
                <InputComment>*</InputComment>썸네일{" "}
              </CardColumns>
              <ThumbCon>
                <div
                  style={{
                    // width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{ width: "150px" }}
                    onClick={() => setModal(true)}
                  >
                    <AddPicture length="150px" />
                  </div>
                </div>
                {cropImage ? <ThumImg alt="Crop" src={cropImage} /> : null}
                {/* <div
                  className="appear"
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {thumbnail ? thumbnail.name : null}
                </div> */}
              </ThumbCon>
              {/* <div
                className="hidden"
                style={{ display: "flex", alignItems: "end" }}
              >
                {thumbnail ? thumbnail.name : null}
              </div> */}
            </InputCon>
          </ColumnInfo>
          <ColumnInfo style={{ height: "auto" }}>
            <CardColumns
              style={{
                color: "rgb(99, 110, 114)",
                fontWeight: "bold",
                minWidth: "100px",
              }}
            >
              <InputComment>*</InputComment>설명{" "}
            </CardColumns>
            <EditCon>
              <Editor value={quillValue} onChange={onEditorChange} />
            </EditCon>
          </ColumnInfo>
          <ColumnInfo>
            <InputCon>
              <CardColumns
                style={{
                  color: "rgb(99, 110, 114)",
                  fontWeight: "bold",
                  minWidth: "100px",
                }}
              >
                퍼스널 챌린지{" "}
              </CardColumns>
              <CardColumns>
                <Checkbox
                  onClick={(e) => {
                    setIsPersonal(e.target.checked);
                    //퍼스널 챌린지일 경우
                    if (e.target.checked) {
                      setValue("reward", 0);
                      setValue("trainerCount", 1);
                      setValue("userCountPerTrainer", 1);
                    } else {
                      setValue("reward", null);
                      setValue("trainerCount", null);
                      setValue("userCountPerTrainer", null);
                    }
                  }}
                ></Checkbox>
              </CardColumns>
            </InputCon>

            <InputDes>
              *퍼스널 챌린지는 챌린지 생성자 본인과 본인이 선택한 트레이너가
              1대1로 매칭됩니다.
            </InputDes>
          </ColumnInfo>
          <ColumnInfo className="BasePrice">
            <InputCon>
              {" "}
              <CardColumns
                style={{
                  color: "rgb(99, 110, 114)",
                  fontWeight: "bold",
                  minWidth: "100px",
                }}
              >
                <InputComment>*</InputComment>기본급{" "}
              </CardColumns>
              <CardColumns
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Value
                  {...register("baseprice", {
                    required: {
                      value: true,
                      message: "기본급은 필수값입니다.",
                    },
                  })}
                  type="number"
                  min="0"
                  className="form-control"
                />
                만원
              </CardColumns>
            </InputCon>
            {formState?.errors?.baseprice?.message ? (
              <UseFormError
                lineHeight="38px"
                message={formState?.errors?.baseprice?.message}
              />
            ) : (
              <InputDes>
                *트레이너의 참여요청 수락시 지급되는 기본급입니다.
              </InputDes>
            )}
          </ColumnInfo>

          <ColumnInfo>
            <InputCon>
              <CardColumns
                style={{
                  color: "rgb(99, 110, 114)",
                  fontWeight: "bold",
                  minWidth: "100px",
                }}
              >
                <InputComment>*</InputComment>성공보수{" "}
              </CardColumns>
              <CardColumns
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Value
                  {...register("bonus", {
                    required: {
                      value: true,
                      message: "성공보수 필수값입니다.",
                    },
                  })}
                  type="number"
                  min="0"
                  className="form-control"
                />
                만원
              </CardColumns>
            </InputCon>
            {formState?.errors?.bonus?.message ? (
              <UseFormError
                lineHeight="38px"
                message={formState?.errors?.bonus?.message}
              />
            ) : (
              <InputDes>
                *챌린지 성공 시 트레이너에게 지급되는 성공보수입니다.
              </InputDes>
            )}
          </ColumnInfo>

          {!isPersonal ? (
            <>
              <ColumnInfo>
                <InputCon>
                  <CardColumns
                    style={{
                      color: "rgb(99, 110, 114)",
                      fontWeight: "bold",
                      minWidth: "100px",
                    }}
                  >
                    <InputComment>*</InputComment>리워드{" "}
                  </CardColumns>
                  <CardColumns
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Value
                      {...register("reward", {
                        required: {
                          value: true,
                          message: "리워드는 필수값입니다.",
                        },
                      })}
                      type="number"
                      min="0"
                      className="form-control"
                    />
                    만원
                  </CardColumns>
                </InputCon>
                {formState?.errors?.reward?.message ? (
                  <UseFormError
                    lineHeight="38px"
                    message={formState?.errors?.reward?.message}
                  />
                ) : (
                  <InputDes>
                    *챌린지 성공 시 참여한 유저에게 지급되는 성공보수입니다.
                  </InputDes>
                )}
              </ColumnInfo>
            </>
          ) : null}

          <ColumnInfo>
            <InputCon>
              {" "}
              <CardColumns
                style={{
                  color: "rgb(99, 110, 114)",
                  fontWeight: "bold",
                  minWidth: "100px",
                }}
              >
                <InputComment>*</InputComment>시작 날짜
              </CardColumns>
              <CardColumns
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {" "}
                <Value
                  {...register("startDate", {
                    required: {
                      value: true,
                      message: "시작 날짜는 필수값입니다.",
                    },
                  })}
                  onChange={onDateChange}
                  value={date}
                  type="date"
                  className="form-control"
                />{" "}
              </CardColumns>
            </InputCon>

            <UseFormError
              lineHeight="38px"
              message={formState?.errors?.startDate?.message}
            />
          </ColumnInfo>

          <ColumnInfo>
            <InputCon>
              {" "}
              <CardColumns
                style={{
                  color: "rgb(99, 110, 114)",
                  fontWeight: "bold",
                  minWidth: "100px",
                }}
              >
                <InputComment>*</InputComment>기간(일)
              </CardColumns>
              <CardColumns
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Value
                  {...register("term", {
                    required: { value: true },
                  })}
                  type="number"
                  min="10"
                  max="365"
                  onBlur={(e) => {
                    if (e.target.value > 365) {
                      setValue("term", 365);
                    } else if (e.target.value < 10) {
                      setValue("term", 10);
                    }
                    onEndDateChange(e);
                  }}
                  className="form-control"
                />{" "}
                일
              </CardColumns>
            </InputCon>
            <UseFormError
              lineHeight="38px"
              message={formState?.errors?.term?.message}
            />
          </ColumnInfo>
          <ColumnInfo>
            <InputCon>
              <CardColumns
                style={{
                  color: "rgb(99, 110, 114)",
                  fontWeight: "bold",
                  minWidth: "100px",
                }}
              >
                <InputComment>*</InputComment>종료 날짜
              </CardColumns>
              <CardColumns
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Value
                  {...register("endDate")}
                  className="form-control"
                  readOnly
                  type="date"
                />
              </CardColumns>
            </InputCon>
            {formState?.errors?.endDate?.message ? (
              <UseFormError
                lineHeight="38px"
                message={formState?.errors?.endDate?.message}
              />
            ) : (
              <InputDes>*당일 오후 11:59분 까지 입니다.</InputDes>
            )}
          </ColumnInfo>

          {!isPersonal ? (
            <>
              <ColumnInfo>
                <InputCon>
                  <CardColumns
                    style={{
                      color: "rgb(99, 110, 114)",
                      fontWeight: "bold",
                      minWidth: "100px",
                    }}
                  >
                    <InputComment>*</InputComment>총 트레이너 수{" "}
                  </CardColumns>
                  <CardColumns
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {" "}
                    <Value
                      {...register("trainerCount", {
                        required: {
                          value: true,
                          message: "총 트레이너수는 필수값입니다.",
                        },
                      })}
                      className="form-control"
                      type="number"
                      min="0"
                    />
                    명
                  </CardColumns>
                </InputCon>
                <UseFormError
                  lineHeight="38px"
                  message={formState?.errors?.trainerCount?.message}
                />
              </ColumnInfo>
            </>
          ) : null}
          {!isPersonal ? (
            <>
              <ColumnInfo>
                <InputCon>
                  <CardColumns
                    style={{
                      color: "rgb(99, 110, 114)",
                      fontWeight: "bold",
                      minWidth: "100px",
                    }}
                  >
                    <InputComment>*</InputComment>
                    트레이너 별 회원 수 제한
                  </CardColumns>
                  <CardColumns
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Value
                      {...register("userCountPerTrainer", {
                        required: {
                          value: true,
                          message: "트레이너 별 회원수 제한은 필수값입니다.",
                        },
                      })}
                      className="form-control"
                      type="number"
                      min="0"
                    />
                    명
                  </CardColumns>
                </InputCon>
                <UseFormError
                  lineHeight="38px"
                  message={formState?.errors?.userCountPerTrainer?.message}
                />
              </ColumnInfo>
            </>
          ) : null}
        </CardColumns>
        <BtnForm>
          <Submit
            type="submit"
            className="form-control btn-primary"
            value="등록"
          />
          {/* <button className="form-control btn-danger">취소</button> */}
        </BtnForm>
        <FeeAlarm>
          <p>※챌린지 결제시 수수료 20%가 추가로 결제됩니다.</p>
        </FeeAlarm>
      </CreateForm>
      <Modal
        size={loading ? "lg" : null}
        centered={true}
        show={modal}
        onHide={() => setModal(false)}
      >
        <Modal.Body>
          <ImageCrop
            getCropImage={getCropImage}
            file={thumbnail}
            setFile={setThumbnail}
            getModal={getModal}
            aspect={1}
            dropImage={dropImage}
            noDetect={true}
          />
          {loading ? null : <IsThumbDropZone getThumbnail={getThumbnail} />}
        </Modal.Body>
      </Modal>
      {/* </CreateChallengeCon> */}
    </Container>
  );
};
export default MultiChallengeControl;
