//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import CouponCard from "../component/coupon/CouponCard";
import { CouponUserSelect } from "../component/coupon/couponUserSelect";
import { SButton } from "../component/shared/SButton";
import { DELETE_COUPON_BY_USER_MUTATION } from "../queries/givenCoupon/deleteCouponByUser";
import { GIVE_COUPON_MUTATION } from "../queries/givenCoupon/giveCoupon";
import {
  SelectMyGivenCoupons,
  SELECT_MY_GIVEN_COUPONS_QUERY,
} from "../queries/givenCoupon/selectMyGivenCoupons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ScreenTitle = styled.div`
  width: inherit;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
`;
const CouponContainer = styled.div`
  width: inherit;
  background-color: white;
  min-height: 100px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
const SearchUserContainer = styled.div`
  width: inherit;
  padding: 15px;
`;
const ModalInteract = styled.div`
  width: inherit;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const MyCouponbox = () => {
  const [modal, setModal] = useState(false);
  const [selectUser, setSelectUser] = useState();
  const [selectCoupon, setSelectCoupon] = useState();
  const { data } = SelectMyGivenCoupons();
  const onCompleted = () => {
    setModal(false);
  };
  const [giveCouponMutation] = useMutation(GIVE_COUPON_MUTATION, {
    refetchQueries: [
      {
        query: SELECT_MY_GIVEN_COUPONS_QUERY,
      },
    ],
    onCompleted,
  });
  //만료된 쿠폰 삭제
  const [deleteCouponByUserMutation] = useMutation(
    DELETE_COUPON_BY_USER_MUTATION,
    {
      refetchQueries: [
        {
          query: SELECT_MY_GIVEN_COUPONS_QUERY,
        },
      ],
    }
  );

  const onGiveConfirmClick = () => {
    if (!!selectUser) {
      giveCouponMutation({
        variables: {
          userId: selectUser?.id,
          givenCouponId: selectCoupon?.id,
        },
      });
    } else {
      alert("쿠폰을 지급할 유저를 선택해주세요.");
    }
  };
  const onModalHide = () => {
    setModal(false);
    setSelectUser(null);
    setSelectCoupon(null);
  };
  return (
    <Container id="main">
      <ScreenTitle>나의 쿠폰함</ScreenTitle>
      <CouponContainer>
        {data?.selectMyGivenCoupons?.map((mgc) => (
          <CouponCard
            key={mgc.id}
            {...mgc}
            setModal={setModal}
            setSelectCoupon={setSelectCoupon}
            deleteCouponByUserMutation={deleteCouponByUserMutation}
          />
        ))}
      </CouponContainer>
      {/* 유저검색 모달 */}
      <Modal show={modal} size="sm" onHide={onModalHide}>
        <Modal.Body>
          <SearchUserContainer>
            <CouponUserSelect
              selectUser={selectUser}
              setSelectUser={setSelectUser}
              setSelectCoupon={setSelectCoupon}
            />
          </SearchUserContainer>
          <ModalInteract>
            <SButton
              context="확인"
              width="100px"
              fontSize="14px"
              fontWeight="bold"
              onClick={onGiveConfirmClick}
            />
          </ModalInteract>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default MyCouponbox;
