//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//Receipt 조회 : receiptBuyList 와 receiptSellList 를 취합한 object 를 반환한다.
//gql 문법
//Receipt Fragment 생성 필요
import { gql } from "@apollo/client";
import { CONTRACT_FRAGMENT } from "./contractFragment";
import { MULTICHALLENGE_CONTRACT_FRAGMENT } from "./multiChallengeContractFragment";
import { MULTICHALLENGE_FRAGMENT } from "./multiChallengeFragment";
import { PURCHASELOG_FRAGMENT } from "./purchaseLogFragment";

export const RECEIPT_FRAGMENT = gql`
  fragment ReceiptFragment on Receipt {
    id
    contractId
    contractType
    contract {
      ... on Contract {
        ...ContractFragment
      }
      ... on MultiChallengeContract {
        ...MultiChallengeContractFragment
      }
    }
    buyer {
      id
      username
      type
      profilePhoto
    }
    seller {
      id
      username
      type
      profilePhoto
    }
    isBuyerPaid
    isBuyerConfirmed
    isSellerRequested
    isSellerGetPaid
    isTransactionDone
    isBuyerRepaid
    buyerTransactionStatus
    sellerTransactionStatus
    transactionStatus
    transactionFailedReason
    createdAt
    updatedAt
    deletedAt
    receiptLogs {
      id
      type
    }
    statement {
      id
      orgAmount
      amount
      remainAmount
      isConfirmed
      isTransferred
      transactionType
      user {
        id
        username
        type
        profilePhoto
      }
    }
    multiChallenge {
      ...MultiChallengeFragment
    }
    purchaseLog {
      ...PurchageLogFragment
    }
  }
  ${MULTICHALLENGE_FRAGMENT}
  ${CONTRACT_FRAGMENT}
  ${MULTICHALLENGE_CONTRACT_FRAGMENT}
  ${PURCHASELOG_FRAGMENT}
`;
