//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
export const routes = {
  home: "/",
  about: "/about",
  signup: "/signup",
  login: "/login",
  myGym: "/mygym/",
  myGymEdit: "/mygym/edit",
  message: "/message",
  newMessage: "/newMessage",
  posts: "/posts",
  scanning: "/scanning",
  setting: "/setting",
  sale: "/sale",
  createPost: "/post/create",
  updatePost: "/post/update",
  postDetail: "/post_detail",
  pTRooms: "/ptrooms",
  createPTRoom: "/ptroom/create",
  updatePTRoom: "/ptRoom/update",
  pTRoomDetail: "/ptroom_detail",
  createTrain: "/create_train",
  updateTrain: "/update_Train",
  eventDetail: "/event_detail",
  challenges: "/challenges",
  createChallenge: "/challenges/create",
  updateChallenge: "/challenges/update",
  challengeDetail: "/challenges/detail",
  challengeInfo: "/challengeInfo",
  myCouponBox: "/mycouponbox",
  viewProgress: "/message/viewProgress",
  admin: "/admin",
  faq: "/faq",
  kakaoOAuth: "/kakaoRouter/getOAuth",
  kakaoLogout: "/kakaoRouter/kakaoLogout",
  terms: "/terms",
  createMultiChallenge: "/createmultichallenge",
  multiChallengeList: "/multichallengelist",
  multiChallengeInfo: "/multichallengeinfo",
  saleInfo: "/saleinfo",
  saleInfoConfirm: "/saleinfoconfirm",
  multiChallengeManagement: "/multichallengemanagement",
  multiChallengeGroupManagement: "/multiChallengeGroupManagement",
  multiChallengeUserManagement: "/multiChallengeUserManagement",
  browserRender: "/browserrender",
  userHistory: "/userHistory",
  multiChallengePaymentConfirm: "/multiChallengePaymentConfirm",
  findPassword: "/findPassword",
  rate: "/rate",
  report: "/report",
  consulting: "/consulting",
  faceDetection: "/faceDetection",
  customerService: "/customerservice",
  customerServiceManagement: "/customerservicemanagement",
  reportManagement: "/reportmanagement",
  usersInfo: "/usersInfo",
};
