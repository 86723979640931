//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import CarouselLayout from "../component/carousel/carouselLayout";
import { FatText } from "../component/shared/FatText";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import { SeePosts } from "../queries/post/seePosts";

const PostsContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-right: auto;
`;
const PostCon = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px; ;
`;
const Header = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;
const UserName = styled.span`
  margin-left: 10px;
`;
const Title = styled.div`
  height: 40px;
  padding: 10px;
`;
const Description = styled.div`
  height: 80px;
`;
const Comment = styled.div`
  height: 150px;
`;
const CommentCon = styled.div``;
const CommentText = styled.span``;

function Posts() {
  const data = SeePosts();
  return (
    <PostsContainer>
      {data
        ? data?.seePosts?.map((v, idx) => {
            return (
              <PostCon key={idx}>
                <Header>
                  <ProfilePhoto src={v?.user?.profilePhoto} />
                  <UserName>{v?.user?.username}</UserName>
                </Header>
                <Title>
                  <FatText fontsize="18px">제목 : {v?.title}</FatText>
                </Title>
                <CarouselLayout key={idx} assets={v?.assets} {...v} />
                <Description>{v?.description}</Description>
                <CommentCon>
                  Comment Zone
                  {v?.comments
                    ? v?.comments?.map((comments) => {
                        return (
                          <Comment key={comments?.id}>
                            <UserName key={comments?.user?.id}>
                              {comments?.user?.username}
                            </UserName>
                            <CommentText>{comments?.text}</CommentText>
                          </Comment>
                        );
                      })
                    : null}
                </CommentCon>
              </PostCon>
            );
          })
        : null}
    </PostsContainer>
  );
}

export default Posts;
