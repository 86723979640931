//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { MULTICHALLENGE_FRAGMENT } from "../fragments/multiChallengeFragment";

export const SELECT_MULTICHALLNEGE_BY_ID_QUERY = gql`
  query SelectMultiChallengeById($id: Int!) {
    selectMultiChallengeById(id: $id) {
      id
      title
      thumbnail
      description
      user {
        id
        username
        type
        profilePhoto
      }
      isMine
      baseprice
      bonus
      reward
      startDate
      endDate
      term
      userCountPerTrainer
      trainerCount
      multiChallengeContracts {
        id
        user {
          id
          username
          type
          profilePhoto
        }
        type
        height
        weight
        gender
        accepted
        createdAt
        updatedAt
        deletedAt
      }
      multiChallengeGroups {
        id
        multiChallengeGroupMappers {
          id
          type
          user {
            id
          }
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
      deletedAt
      isPaid
      isExpired
      isFinished
      isPersonal
    }
  }
`;

export const SelectMultiChallengeById = (id) => {
  const { data, refetch } = useQuery(SELECT_MULTICHALLNEGE_BY_ID_QUERY, {
    variables: {
      id,
    },
  });
  return { data, refetch };
};
