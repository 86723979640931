//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";

export const SEE_MESSAGES = gql`
  query SeeMessages($roomId: Int!, $take: Int, $lastId: Int) {
    seeMessages(roomId: $roomId, take: $take, lastId: $lastId) {
      id
      user {
        id
        username
        profilePhoto
      }
      chatRoom {
        id
      }
      content
      originalFilePath
      createdAt
      deletedAt
      isMine
      isDownloaded
      downloadCnt
      zipFileCnt
      isReply
      repliedMessage {
        id
        user {
          id
          username
        }
        content
      }
    }
  }
`;

export const SEE_MESSAGES_CACHE = gql`
  query SeeMessages($roomId: Int!, $take: Int, $lastId: Int) {
    seeMessages(roomId: $roomId, take: $take, lastId: $lastId) {
      id
      user {
        id
        username
        profilePhoto
      }
      chatRoom {
        id
      }
      content
      originalFilePath
      createdAt
      deletedAt
      isMine
      isDownloaded
      downloadCnt
      zipFileCnt
      isReply
      repliedMessage {
        id
        user {
          id
          username
        }
        content
      }
    }
  }
`;

// export const SeeMessages = (roomId, take = 30, lastId = 0) => {
//   const { data, loading, refetch, fetchMore } = useQuery(SEE_MESSAGES, {
//     variables: {
//       roomId,
//       take,
//       lastId,
//     },

//     fetchPolicy: "cache-and-network",
//   });
//   return { data, loading, refetch, fetchMore };
// };
