//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//mygym 의 챌린지 탭에서 볼 수 있는 챌린지를 컴포넌트화 합니다.

import { Card, CardColumns, CardGroup, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/routes";
import { GButton } from "../shared/GButton";
import ProfilePhoto from "../shared/ProfilePhoto";

const SCard = styled.div`
  /* margin: 10px; */
  width: 98%;
  margin: 0 auto;

  /* border: none !important; */
  border-bottom: 2px solid #ebeef4;
  /* box-shadow: 0 0 5px -1px gray; */
`;
const SCardBody = styled(Card.Body)`
  border: none !important;
  border-bottom: 2px solid #ebeef4;
`;
const SCardGroup = styled(CardGroup)`
  display: grid;
  grid-template-columns: 0.5fr 0.8fr 0.7fr 0.5fr;
  @media (max-width: 1320px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;
const SCardColumns = styled(CardColumns)`
  display: flex;
  align-items: center;
  justify-self: center;
`;
const SCardColumnsSpan = styled(CardColumns)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 550px) {
    grid-column: span 2;
    justify-self: center;
  }
`;
const SImage = styled(Image)`
  width: ${(props) => (props?.width ? props?.width : "")};
  height: ${(props) => (props?.height ? props?.height : "")};
  border-radius: ${(props) => (props?.borderradius ? props?.borderradius : "")};
  object-fit: ${(props) => (props?.objectfit ? props?.objectfit : "")};
  object-position: ${(props) =>
    props?.objectposition ? props?.objectposition : ""};
  @media (max-width: 770px) {
    width: 100px;
    height: 100px;
  }
`;
const ChallengeInfo = styled(SCardColumns)`
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  height: 100%;
  align-items: center;
  text-align: center;
  gap: 5px;
  h5 {
    display: block;
    font-weight: bold;
    font-size: 18px;
    @media (max-width: 550px) {
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const ChallengeMembers = styled(SCardColumns)`
  display: grid;
  grid-template-columns: repeat(3, auto);
  height: 100%;
  align-items: center;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: 5px;
  div {
    font-weight: bold;
    font-size: 16px;
  }
`;
const ButtonCon = styled(SCardColumns)`
  display: flex;
  flex-direction: column;
  justify-content: right;
  gap: 10px;
  @media (max-width: 1320px) {
    justify-content: center;
  }
  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

const MyMultiChallengeBanner = ({ multiChallenge }) => {
  const history = useHistory();
  return (
    <SCard key={multiChallenge?.id}>
      <SCardBody>
        <SCardGroup>
          <SCardColumns>
            <SImage
              src={multiChallenge?.thumbnail}
              width="250px"
              height="250px"
              borderradius="20px"
            />
          </SCardColumns>
          <SCardColumns>
            <ChallengeInfo>
              <h5>{multiChallenge?.title}</h5>
              <div>
                {/* <SImage
                  src={
                    multiChallenge?.user?.profilePhoto
                      ? multiChallenge?.user?.profilePhoto
                      : ""
                  }
                  width="200px"
                  height="200px"
                  borderradius="100px"
                  objectfit="cover"
                  objectposition="left top"
                  onError={(e) =>
                    (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
                  }
                /> */}
                <ProfilePhoto
                  src={multiChallenge?.user?.profilePhoto}
                  {...multiChallenge?.user}
                  width="200px"
                  height="200px"
                  isRes={true}
                />
              </div>
              <h5>{multiChallenge?.user?.username}</h5>
            </ChallengeInfo>
          </SCardColumns>
          <SCardColumnsSpan>
            <ChallengeMembers>
              <div>총 트레이너 수</div>
              <div>:</div>
              <div>
                {
                  multiChallenge?.multiChallengeContracts?.filter(
                    (mcc) => mcc.type === "trainer" && mcc.accepted === true
                  )?.length
                }{" "}
                / {multiChallenge?.trainerCount}
              </div>
              <div>총 유저 수</div>
              <div>:</div>
              <div>
                {multiChallenge?.multiChallengeContracts?.filter(
                  (mcc) => mcc.type === "trainee" && mcc.accepted === true
                )?.length + (multiChallenge?.isPersonal && 1)}{" "}
                /{" "}
                {multiChallenge?.trainerCount *
                  multiChallenge?.userCountPerTrainer}
              </div>
              <div>총 참여자 수</div>
              <div>:</div>
              <div>
                {multiChallenge?.multiChallengeContracts?.filter(
                  (mcc) => mcc.accepted === true
                )?.length + (multiChallenge?.isPersonal && 1)}{" "}
                /{" "}
                {multiChallenge?.trainerCount *
                  multiChallenge?.userCountPerTrainer +
                  multiChallenge?.trainerCount}
              </div>
            </ChallengeMembers>
          </SCardColumnsSpan>
          <SCardColumnsSpan>
            <ButtonCon>
              <GButton
                variant="create"
                context="챌린지 상세"
                width="175px"
                onClick={() =>
                  history.push(routes.multiChallengeInfo, {
                    id: multiChallenge?.id,
                  })
                }
              />
              <GButton
                variant="update"
                context="챌린지 전체 관리"
                width="175px"
                onClick={() =>
                  history.push(routes.multiChallengeManagement, {
                    id: multiChallenge?.id,
                  })
                }
              />
              {multiChallenge?.multiChallengeGroups?.reduce((acc, mcg) => {
                return mcg?.multiChallengeGroupMappers?.filter(
                  (mcgm) => mcgm?.user?.id === multiChallenge?.user?.id
                );
              }, [])?.length && multiChallenge?.isPersonal === true ? (
                <GButton
                  variant="read"
                  context="내 챌린지 관리"
                  width="175px"
                  onClick={() =>
                    history.push(routes.multiChallengeUserManagement, {
                      id: multiChallenge?.multiChallengeGroups?.reduce(
                        (acc, mcg) => {
                          return mcg?.multiChallengeGroupMappers?.filter(
                            (mcgm) =>
                              mcgm?.user?.id === multiChallenge?.user?.id
                          );
                        },
                        []
                      )?.[0]?.id,
                    })
                  }
                />
              ) : null}
            </ButtonCon>
          </SCardColumnsSpan>
        </SCardGroup>
      </SCardBody>
    </SCard>
  );
};

export default MyMultiChallengeBanner;
