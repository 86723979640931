//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//해당 route component 는 챌린지에 참여한 유저가 자신의 인증 사진등을 업로드하고 트레이너의 승인을 확인하는 화면입니다.

import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Card, CardColumns, CardDeck, Container, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import MCGProveFile from "../../component/multiChallenge/user/MCGProveFile";
import { GButton } from "../../component/shared/GButton";
import ImageCrop from "../../component/shared/ImageCrop";
import ProfilePhoto from "../../component/shared/ProfilePhoto";
import { Ribbon } from "../../component/shared/Ribbon";
import { CREATE_MCGPROVEFILE_MUTATION } from "../../queries/multiChallenge/createMCGProveFile";
import { UPDATE_MCGPROVEFILE_MUTATION } from "../../queries/multiChallenge/updateMCGProveFile";
import { SelectMultiChallengeGroupMapperById } from "../../queries/multiChallenge/selectMultiChallengeGroupMapperById";
import { SELECT_RECEIPT_BY_CONTRACTID_QUERY } from "../../queries/Receipt/selectReceiptByContractId";
import ImageLayout from "../../component/shared/ImageLayout";

const MultiChallengeUserManagement = () => {
  //사진추가모달
  const [modal, setModal] = useState(false);
  //사진변경모달
  const [editModal, setEditModal] = useState(false);
  //사진추가모달의 파일
  const [file, setFile] = useState();
  //사진변경모달의 파일
  const [editFile, setEditFile] = useState();
  //인증사진 클릭시 해당정보
  const [mCGProveFile, setMCGProveFile] = useState();
  //crop
  const [setCropImage] = useState();

  const location = useLocation();
  const { data, refetch } = SelectMultiChallengeGroupMapperById(
    location?.state?.id
  );
  //선택한 인증사진을 클릭시 해당 인증사진이 변경가능한지 여부
  const [selProveFileEditable, setSelProveFileEditable] = useState(false);
  const onCreateCompleted = (data) => {
    const {
      createMCGProveFile: { ok },
    } = data;
    if (ok) {
      refetch();
      setFile(null);
      setModal(false);
    }
  };
  const [createMCGProveFileMutation] = useMutation(
    CREATE_MCGPROVEFILE_MUTATION,
    { onCompleted: onCreateCompleted }
  );
  const onUpdateCompleted = (data) => {
    const {
      updateMCGProveFile: { ok },
    } = data;
    if (ok) {
      refetch();
      setEditFile(null);
      setEditModal(false);
    } else {
      return alert("수정/삭제 기한이 지나 수정할 수 없습니다!");
    }
  };
  const [updateMCGProveFileMutation] = useMutation(
    UPDATE_MCGPROVEFILE_MUTATION,
    { onCompleted: onUpdateCompleted }
  );
  const onAddCertImageClick = () => {
    if (!file) {
      return alert("사진을 먼저 올려주세요!");
    }
    createMCGProveFileMutation({
      variables: {
        mCGMId: Number(location?.state?.id),
        file,
      },
    });
  };
  const onEditCertImageClick = () => {
    if (!editFile) {
      return alert("사진을 먼저 올려주세요!");
    }
    if (!mCGProveFile) {
      return alert("인증 사진이 선택되지 않았습니다!");
    }
    updateMCGProveFileMutation({
      variables: {
        id: mCGProveFile?.id,
        file: editFile,
      },
    });
  };
  //receipt
  const [selectReceiptByContractId_lazy, { data: receiptData }] = useLazyQuery(
    SELECT_RECEIPT_BY_CONTRACTID_QUERY
  );
  useEffect(() => {
    if (!!data) {
      selectReceiptByContractId_lazy({
        variables: {
          id: data?.selectMultiChallengeGroupMapperById?.multiChallengeGroup
            ?.multiChallenge?.id,
        },
      });
    }
  }, [data]);
  return (
    <Container
      style={{ display: "flex", flexDirection: "column", gap: "15px" }}
    >
      <CardDeck
        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
      >
        {data?.selectMultiChallengeGroupMapperById?.isChallengeSuccess ===
        false ? (
          <span
            style={{
              cursor: "auto",
              fontSize: "12px",
            }}
            className="badge border-danger border-2 text-danger"
          >
            실패 사유 :{" "}
            {data?.selectMultiChallengeGroupMapperById?.challengeFailedReason}
          </span>
        ) : null}
        <CardColumns>
          <Card
            style={{
              display: "flex",
            }}
          >
            <CardColumns
              style={{
                position: "absolute",
                top: "-8px",
                left: "-8px",
                width: "fit-content",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Ribbon
                context="Challenge"
                top="-8px"
                left="-8px"
                width="120px"
                padding="3px"
                boxshadow="none"
                borderradius="60px"
                position="unset"
                margin="0 5px 0 0"
              />
              <Card.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                  padding: "5px",
                  borderRadius: "50px",
                  width: "fit-content",
                }}
              >
                {data?.selectMultiChallengeGroupMapperById
                  ?.isChallengeSuccess === true ? (
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-success border-2 text-success"
                  >
                    성공중
                  </span>
                ) : data?.selectMultiChallengeGroupMapperById
                    ?.isChallengeSuccess === false ? (
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-danger border-2 text-danger"
                  >
                    실패
                  </span>
                ) : (
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-danger border-2 text-secondary"
                  >
                    평가없음
                  </span>
                )}
              </Card.Text>
              <Card.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                  padding: "5px",
                  borderRadius: "50px",
                  width: "fit-content",
                }}
              >
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-dark border-2 text-dark"
                >
                  종료날짜 :{" "}
                </span>
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-dark border-2 text-dark"
                >
                  {
                    new Date(
                      Number(
                        data?.selectMultiChallengeGroupMapperById
                          ?.multiChallengeGroup?.multiChallenge?.endDate
                      )
                    )
                      ?.toJSON()
                      ?.split("T")?.[0]
                  }
                </span>
              </Card.Text>
              {data?.selectMultiChallengeGroupMapperById?.multiChallengeGroup
                ?.multiChallenge?.isFinished ? (
                <Card.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "5px",
                    borderRadius: "50px",
                    width: "fit-content",
                  }}
                >
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-danger border-2 text-danger"
                  >
                    챌린지 종료
                  </span>
                </Card.Text>
              ) : null}
            </CardColumns>
            <Card.Title
              style={{ padding: "30px 20px 10px 20px", fontWeight: "bold" }}
            >
              {
                data?.selectMultiChallengeGroupMapperById?.multiChallengeGroup
                  ?.multiChallenge?.title
              }
            </Card.Title>
            <CardColumns
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Card.Text style={{ display: "flex", gap: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  보상금 :{" "}
                  {Number(
                    receiptData?.selectReceiptByContractId?.children?.filter(
                      (r) =>
                        r?.groupId ===
                          data?.selectMultiChallengeGroupMapperById
                            ?.multiChallengeGroup?.id &&
                        r?.type === "user" &&
                        r?.receipt?.statement?.transactionType === "보상금"
                    )?.[0]?.receipt?.statement?.amount || 0
                  )}{" "}
                  원
                </span>
                {receiptData?.selectReceiptByContractId?.children?.filter(
                  (r) =>
                    r?.groupId ===
                      data?.selectMultiChallengeGroupMapperById
                        ?.multiChallengeGroup?.id &&
                    r?.type === "user" &&
                    r?.receipt?.statement?.transactionType === "보상금"
                )?.[0]?.receipt?.statement?.isTransferred === true ? (
                  <span style={{ fontWeight: "bold", color: "green" }}>
                    지급 완료
                  </span>
                ) : receiptData?.selectReceiptByContractId?.children?.filter(
                    (r) =>
                      r?.groupId ===
                        data?.selectMultiChallengeGroupMapperById
                          ?.multiChallengeGroup?.id &&
                      r?.type === "user" &&
                      r?.receipt?.statement?.transactionType === "보상금"
                  )?.[0]?.receipt?.statement?.isTransferred === false ? (
                  <span style={{ fontWeight: "bold", color: "gray" }}>
                    지급전
                  </span>
                ) : (
                  <span style={{ fontWeight: "bold", color: "gray" }}>
                    보상금 없음
                  </span>
                )}
              </Card.Text>
            </CardColumns>
          </Card>
        </CardColumns>
        <CardColumns>
          <Card>
            <Card.Title
              style={{
                padding: "20px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                margin: 0,
              }}
            >
              <Card.Text>내 그룹 담당 트레이너 :</Card.Text>
              <Card.Text>
                <ProfilePhoto
                  src={
                    data?.selectMultiChallengeGroupMapperById?.multiChallengeGroup?.multiChallengeGroupMappers?.filter(
                      (mgm) => mgm?.type === "trainer"
                    )?.[0]?.user?.profilePhoto
                  }
                  id={
                    data?.selectMultiChallengeGroupMapperById?.multiChallengeGroup?.multiChallengeGroupMappers?.filter(
                      (mgm) => mgm?.type === "trainer"
                    )?.[0]?.user?.id
                  }
                />
              </Card.Text>
              <Card.Text style={{ fontWeight: "bold", fontSize: "18px" }}>
                {
                  data?.selectMultiChallengeGroupMapperById?.multiChallengeGroup?.multiChallengeGroupMappers?.filter(
                    (mgm) => mgm?.type === "trainer"
                  )?.[0]?.user?.username
                }
              </Card.Text>
            </Card.Title>
          </Card>
        </CardColumns>
        <CardColumns>
          <Card>
            <Card.Title
              style={{
                padding: "20px",
                fontWeight: "bold",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              내 챌린지 관리
              <Card.Text
                style={{
                  fontWeight: "lighter",
                  fontSize: "14px",
                  color: "red",
                }}
              >
                * 인증 사진의 수정/삭제 가능 기한은 하루(24시간) 입니다.
              </Card.Text>
            </Card.Title>
            <CardColumns
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                width: "fit-content",
              }}
            >
              {data?.selectMultiChallengeGroupMapperById?.multiChallengeGroup
                ?.multiChallenge?.isFinished ? (
                <CardColumns style={{ color: "red" }}>
                  챌린지가 종료되었습니다.
                </CardColumns>
              ) : (
                <GButton
                  variant="create"
                  context="+ 인증 사진 추가"
                  onClick={() => setModal(true)}
                />
              )}
            </CardColumns>
            <Card.Body>
              <CardColumns
                style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}
              >
                {data?.selectMultiChallengeGroupMapperById?.MCGProveFiles?.map(
                  (mf) => (
                    <MCGProveFile
                      key={mf?.id}
                      MCGProveFile={mf}
                      setEditModal={setEditModal}
                      setMCGProveFile={setMCGProveFile}
                      refetch={refetch}
                      isFinished={
                        data?.selectMultiChallengeGroupMapperById
                          ?.multiChallengeGroup?.multiChallenge?.isFinished
                      }
                      setSelProveFileEditable={setSelProveFileEditable}
                    />
                  )
                )}
              </CardColumns>
            </Card.Body>
          </Card>
        </CardColumns>
      </CardDeck>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
          setFile(null);
        }}
      >
        <Modal.Header>
          <Modal.Title>인증 사진 추가</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageCrop
            file={file}
            setFile={setFile}
            aspect={1}
            getCropImage={setCropImage}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <GButton
            variant="create"
            context="추가하기"
            onClick={onAddCertImageClick}
          />
        </Modal.Footer>
      </Modal>
      <Modal
        show={editModal}
        onHide={() => {
          setEditModal(false);
          setEditFile(null);
        }}
      >
        <Modal.Header>
          <Modal.Title>인증 사진 변경</Modal.Title>
          {mCGProveFile ? (
            <Card.Text>
              {
                new Date(Number(mCGProveFile?.createdAt))
                  .toJSON()
                  ?.split("T")?.[0]
              }
            </Card.Text>
          ) : null}
        </Modal.Header>
        <Modal.Body>
          {selProveFileEditable ? (
            <ImageCrop file={editFile} setFile={setEditFile} aspect={1} />
          ) : null}
          <ImageLayout src={mCGProveFile?.filepath} width="100%" />
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          {selProveFileEditable ? (
            <GButton
              variant="create"
              context="변경하기"
              onClick={onEditCertImageClick}
            />
          ) : (
            <CardColumns style={{ color: "red" }}>
              챌린지가 종료되었습니다.
            </CardColumns>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MultiChallengeUserManagement;
