//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import colors from "../../utils/colors";

const Btn = styled.button`
  opacity: 0.8;
  vertical-align: middle;
  color: ${(props) => (props.color ? props.color : "#fff")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  padding: ${(props) => (props.padding ? props.padding : "0.375rem 0.75rem")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  width: ${(props) => (props.width ? props.width : "")};
  /* width: ${(props) => (props.minWidth ? props.minWidth : "130px")}; */
  height: ${(props) => (props.height ? props.height : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  /* position: relative; */
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  bottom: ${(props) => (props.bottom ? props.bottom : "")};
  right: ${(props) => (props.right ? props.right : "")};
  left: ${(props) => (props.left ? props.left : "")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "")};
  transform: ${(props) => (props.transform ? props.transform : "")};
  border: ${(props) => (props.border ? props.border : "")};
  border-radius: ${(props) => (props.radius ? props.radius : "5px")};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "")};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : "#fd780f"};
  line-height: ${(props) => (props?.lineHeight ? props?.lineHeight : "")};

  background-color: ${(props) =>
    props.bgColor ? props.bgColor : colors.bbeego};
  &:hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : ""};
  }
  &:active {
    opacity: 0.8;
    top: 2px;
  }
  ${(props) =>
    props.variant === "bbeego"
      ? `
    color:white;
    background-color:${colors.bbeego};
    border-color:${colors.bbeego};
    
  `
      : ""}
  ${(props) =>
    props.variant === "create"
      ? `
    color:white;
    background-color:#0d6efd;
    border-color:#0d6efd;
  `
      : ""}
  ${(props) =>
    props.variant === "update"
      ? `
    color:white;
    background-color:#6c757d;
    border-color:#6c757d;
  `
      : ""}
  ${(props) =>
    props.variant === "read"
      ? `
    color:white;
    background-color:#0dcaf0;
    border-color:#0dcaf0;
  `
      : ""}
  ${(props) =>
    props.variant === "delete"
      ? `
    color:white;
    background-color: red;
    border-color: red;
  `
      : ""}
  ${(props) =>
    props.variant === "light"
      ? `
    color:#222;
    background-color: #f9fafb;
    border-color: #f9fafb;
  `
      : ""}
  ${(props) =>
    props.variant === "success"
      ? `
    color:white;
    background-color: #198754;
    border-color: #198754;
  `
      : ""}
  ${(props) =>
    props.variant === "payment"
      ? `
    color:white;
    background-color: #0d6efd;
    border-color: #0d6efd;
  `
      : ""}
`;

//props: type, width, height, margin, padding, font-size, font-weight, border, border-radius, color
export const GButton = (props) => {
  return (
    <Btn type="button" {...props}>
      {props.context}
    </Btn>
  );
};
