//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useQuery } from "@apollo/client";
import { CardColumns } from "react-bootstrap";
import styled from "styled-components";
import { DELETE_CONTRACT_MUTATION } from "../../queries/contract/deleteContract";
import {
  GET_REQUESTED_CONTRACT,
  GET_REQUEST_CONTRACT,
} from "../../queries/contract/getContract";
import { HIDE_CUSTOMER_CONTRACT_LIST } from "../../queries/contract/hideCustomerContractList";
import { HIDE_TRAINER_CONTRACT_LIST } from "../../queries/contract/hideTrainerContractList";
import { UPDATE_CONTRACT_MUTATION } from "../../queries/contract/updateContract";
import { CREATE_NOTIFICATION_MUTATION } from "../../queries/notification/createNotification";
import CheckUserToken from "../../utils/checkUserToken";
import colors from "../../utils/colors";
import ProfilePhoto from "../shared/ProfilePhoto";
import { Response } from "./Response";

const SRegisterList = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  /* padding: 2%; */
  /* gap: 20px; */
`;
const RegisterRow = styled.div`
  width: 100%;
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 1.2fr 0.75fr 0.75fr 0.75fr 0.9fr;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-top: 1px solid lightgray;
  //771px부터 모바일 화면으로 조정해야함.
  @media (max-width: 771px) {
    grid-template-columns: 1fr 3.5fr 1fr;
    //text-align: center;
    //word-break: break-all;
    //text-align: justify;
    .hidden {
      display: none;
    }
  }
  @media (max-width: 444px) {
    grid-template-columns: 1fr 3fr 95px;
    //text-align: center;
    //word-break: break-all;
    //text-align: justify;
    .hidden {
      display: none;
    }
  }
`;
const TitleName = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  /* color: rgb(99, 110, 114); */
`;
const RegisterTitle = styled.div`
  /* background-color: #808080; */
  width: 100%;
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 1.2fr 0.75fr 0.75fr 0.75fr 0.9fr;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* border-bottom: 1px solid lightgray; */
  //771px부터 모바일 화면으로 조정해야함.
  @media (max-width: 771px) {
    grid-template-columns: 1fr 3.5fr 1fr;
    //text-align: center;
    //word-break: break-all;
    //text-align: justify;
    .hidden {
      display: none;
    }
  }
  @media (max-width: 444px) {
    grid-template-columns: 1fr 3fr 95px;
    //text-align: center;
    //word-break: break-all;
    //text-align: justify;
    .hidden {
      display: none;
    }
  }
`;
const HiddenRegColumn = styled(CardColumns)`
  display: none;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  @media (max-width: 771px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
    justify-content: center;
    align-items: center;
  }
`;
const RegColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100%;
  /*grid-template-columns: 1fr 4fr;*/
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 770px) {
    /*grid-template-columns: 1fr;*/
  }
`;
const PhotoRegColumn = styled.div`
  display: grid;
  width: 100%;
  gap: 3px;
  justify-content: center;
`;
const CustomerName = styled.span`
  text-align: center;
  font-weight: 700;
  /* text-align: right; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media (max-width: 550px) {
    max-width: 70px;
  }
`;
const PTInfo = styled.div`
  display: flex;
  gap: 50px;
  margin-left: 30px;
`;
const PTPriceInfo = styled.div`
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  /* margin-left: 5%; */
`;
const HiddenInfo = styled.div`
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  text-align: right;
  /* margin-left: 5%; */
`;
const PTTitle = styled.span`
  width: 100%;
  max-width: 230px;
  overflow: hidden;
  font-size: 20px;
  /* text-align: right; */
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 550px) {
    max-width: 190px;
  }
  /*@media (min-width: 945px) {
    max-width: 1040px;
  }
  @media (max-width: 945px) {
    width: 50%;
  }*/
`;
const Status = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${colors.bbeegoText};
`;
export const RegisterList = ({ subTab }) => {
  const me = CheckUserToken();
  const List = ["유저", "타이틀", "기간", "가격", "거래현황"];
  const {
    loading: reqLoading,
    error: reqError,
    data: reqData,
  } = useQuery(GET_REQUEST_CONTRACT);

  const {
    loading: reqedLoading,
    error: reqedError,
    data: reqedData,
  } = useQuery(GET_REQUESTED_CONTRACT);
  if (!reqLoading) {
  }
  if (!reqedLoading) {
  }
  const [createNotificationMutation] = useMutation(
    CREATE_NOTIFICATION_MUTATION
  );
  const [updateContractMutation] = useMutation(UPDATE_CONTRACT_MUTATION, {
    refetchQueries: [
      { query: GET_REQUESTED_CONTRACT },
      { query: GET_REQUEST_CONTRACT },
    ],
  });

  //요청 수락 또는 거절 시 발생
  const onContractUpdateClick = (contractId, type, userId) => {
    updateContractMutation({
      variables: {
        id: contractId,
        type,
        userId,
      },
    });

    // if (type === "accept") {
    //   createNotificationMutation({
    //     variables: {
    //       userId,
    //       type: notificationTypes.registerAccept.type,
    //       context: notificationTypes.registerAccept.context,
    //     },
    //   });
    // } else if (type === "deny") {
    //   createNotificationMutation({
    //     variables: {
    //       userId,
    //       type: notificationTypes.registerDeny.type,
    //       context: notificationTypes.registerDeny.context,
    //     },
    //   });
    // }
  };

  const onReqedListUpdate = (cache, data) => {
    const {
      data: {
        hideTrainerContractList: { ok, id },
      },
    } = data;
    if (ok) {
      cache.evict({
        id: `Contract:${id}`,
      });
      cache.gc();
    }
  };
  const onReqedListUpdate_c = (cache, data) => {
    const {
      data: {
        hideCustomerContractList: { ok, id },
      },
    } = data;
    if (ok) {
      cache.evict({
        id: `Contract:${id}`,
      });
      cache.gc();
    }
  };
  const [hideTrainerContractListMutation] = useMutation(
    HIDE_TRAINER_CONTRACT_LIST,
    {
      update: onReqedListUpdate,
    }
  );
  const [hideCustomerContractListMutation] = useMutation(
    HIDE_CUSTOMER_CONTRACT_LIST,
    {
      update: onReqedListUpdate_c,
    }
  );
  const onDelFromReqedListClick = (contractId) => {
    hideTrainerContractListMutation({
      variables: {
        id: contractId,
      },
    });
  };
  const onDelFromReqListClick = (contractId) => {
    hideCustomerContractListMutation({
      variables: {
        id: contractId,
      },
    });
  };
  const deleteContractUpdate = (cache, data) => {
    const {
      data: {
        deleteContract: { ok, id },
      },
    } = data;
    if (ok) {
      cache.evict({
        id: `Contract:${id}`,
      });
      cache.gc();
    }
  };

  const [deleteContractMutation, { loading }] = useMutation(
    DELETE_CONTRACT_MUTATION,
    {
      update: deleteContractUpdate,
    }
  );
  const onCancelRequestRegister = (contractId, trainerId) => {
    deleteContractMutation({
      variables: {
        id: contractId,
      },
    });
    // createNotificationMutation({
    //   variables: {
    //     userId: trainerId,
    //     type: notificationTypes.registerRequestCancel.type,
    //     context: notificationTypes.registerRequestCancel.context,
    //   },
    // });
  };
  return (
    <SRegisterList>
      <RegisterTitle>
        <TitleName>유저</TitleName>
        <TitleName>타이틀</TitleName>
        <TitleName className="hidden">기간</TitleName>
        <TitleName className="hidden">가격</TitleName>
        <TitleName>거래현황</TitleName>
        <TitleName className="hidden">기타</TitleName>
      </RegisterTitle>
      {me?.me?.type === "user"
        ? reqData?.getRequestContract?.map((contract) => (
            <RegisterRow key={contract.id}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <PhotoRegColumn>
                  <ProfilePhoto
                    src={contract?.trainer?.profilePhoto}
                    id={contract?.trainer?.id}
                  />
                </PhotoRegColumn>
                <CustomerName>{contract?.trainer?.username}</CustomerName>
              </div>
              <RegColumn className="hidden">
                <PTTitle>{contract?.pTPrice?.pTRoom?.title}</PTTitle>
              </RegColumn>
              <RegColumn className="hidden">
                <PTPriceInfo>{contract?.pTPrice?.month}개월</PTPriceInfo>
              </RegColumn>
              <RegColumn className="hidden">
                <PTPriceInfo>{contract?.pTPrice?.price}만원</PTPriceInfo>
              </RegColumn>
              <HiddenRegColumn>
                <PTTitle>{contract?.pTPrice?.pTRoom?.title}</PTTitle>
                <HiddenInfo>{contract?.pTPrice?.month}개월</HiddenInfo>
                <HiddenInfo>{contract?.pTPrice?.price}만원</HiddenInfo>
              </HiddenRegColumn>
              <HiddenRegColumn style={{ marginLeft: "5px" }}>
                <Status>
                  {!contract ? "없음" : null}
                  {contract?.isMine ? (
                    <>
                      {contract?.accepted && !contract?.purchased
                        ? "결제 대기중"
                        : null}
                      {contract?.denied ? "요청 거절됨" : null}
                      {contract?.purchased ? "결제 완료" : null}
                    </>
                  ) : (
                    <>
                      {!contract?.accepted &&
                      !contract?.purchased &&
                      !contract?.denied
                        ? "요청 완료"
                        : null}
                      {contract?.accepted &&
                      !contract?.purchased &&
                      !contract?.denied
                        ? "요청 승인됨"
                        : null}
                      {!contract?.accepted &&
                      !contract?.purchased &&
                      contract?.denied
                        ? "요청 거절됨"
                        : null}

                      {contract?.accepted &&
                      contract?.purchased &&
                      !contract?.denied
                        ? "결제 완료"
                        : null}
                    </>
                  )}
                </Status>
                <Response
                  contract={contract}
                  onContractUpdateClick={onContractUpdateClick}
                  onDelFromReqListClick={onDelFromReqListClick}
                  onCancelRequestRegister={onCancelRequestRegister}
                />
              </HiddenRegColumn>
              <RegColumn className="hidden">
                <Status>
                  {contract?.isMine ? (
                    <>
                      {contract?.accepted && !contract?.purchased
                        ? "결제 대기중"
                        : null}
                      {contract?.denied ? "요청 거절됨" : null}
                      {contract?.purchased ? "결제 완료" : null}
                    </>
                  ) : (
                    <>
                      {!contract?.accepted &&
                      !contract?.purchased &&
                      !contract?.denied
                        ? "요청 완료"
                        : null}
                      {contract?.accepted &&
                      !contract?.purchased &&
                      !contract?.denied
                        ? "요청 승인됨"
                        : null}
                      {!contract?.accepted &&
                      !contract?.purchased &&
                      contract?.denied
                        ? "요청 거절됨"
                        : null}

                      {contract?.accepted &&
                      contract?.purchased &&
                      !contract?.denied
                        ? "결제 완료"
                        : null}
                    </>
                  )}
                </Status>
              </RegColumn>
              <RegColumn className="hidden">
                <Response
                  contract={contract}
                  onContractUpdateClick={onContractUpdateClick}
                  onDelFromReqListClick={onDelFromReqListClick}
                  onCancelRequestRegister={onCancelRequestRegister}
                />
              </RegColumn>
            </RegisterRow>
          ))
        : reqedData?.getRequestedContract?.map((contract) => (
            <RegisterRow key={contract.id}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <PhotoRegColumn>
                  <ProfilePhoto
                    src={contract?.customer?.profilePhoto}
                    id={contract?.customer?.id}
                  />
                </PhotoRegColumn>

                <CustomerName>{contract?.customer?.username}</CustomerName>
              </div>
              <RegColumn className="hidden">
                <PTTitle>{contract?.pTPrice?.pTRoom?.title}</PTTitle>
              </RegColumn>
              <RegColumn className="hidden">
                <PTPriceInfo>{contract?.pTPrice?.month}개월</PTPriceInfo>
              </RegColumn>
              <RegColumn className="hidden">
                <PTPriceInfo>{contract?.pTPrice?.price}만원</PTPriceInfo>
              </RegColumn>
              <HiddenRegColumn>
                <PTTitle>{contract?.pTPrice?.pTRoom?.title}</PTTitle>
                <HiddenInfo>{contract?.pTPrice?.month}개월</HiddenInfo>
                <HiddenInfo>{contract?.pTPrice?.price}만원</HiddenInfo>
              </HiddenRegColumn>
              <HiddenRegColumn style={{ marginLeft: "5px" }}>
                <Status>
                  {!contract ? "없음" : null}
                  {contract?.isMine ? (
                    <>
                      {contract?.accepted && !contract?.purchased
                        ? "결제 대기중"
                        : null}
                      {contract?.denied ? "요청 거절됨" : null}
                      {contract?.purchased ? "결제 완료" : null}
                    </>
                  ) : (
                    <>
                      {!contract?.accepted &&
                      !contract?.purchased &&
                      !contract?.denied
                        ? "요청 완료"
                        : null}
                      {contract?.accepted &&
                      !contract?.purchased &&
                      !contract?.denied
                        ? "요청 승인됨"
                        : null}
                      {!contract?.accepted &&
                      !contract?.purchased &&
                      contract?.denied
                        ? "요청 거절됨"
                        : null}

                      {contract?.accepted &&
                      contract?.purchased &&
                      !contract?.denied
                        ? "결제 완료"
                        : null}
                    </>
                  )}
                </Status>
                <Response
                  contract={contract}
                  onContractUpdateClick={onContractUpdateClick}
                  onDelFromReqListClick={onDelFromReqListClick}
                  onCancelRequestRegister={onCancelRequestRegister}
                />
              </HiddenRegColumn>
              <RegColumn className="hidden">
                <Status>
                  {contract?.isMine ? (
                    <>
                      {contract?.accepted && !contract?.purchased
                        ? "결제 대기중"
                        : null}
                      {contract?.denied ? "요청 거절됨" : null}
                      {contract?.purchased ? "결제 완료" : null}
                    </>
                  ) : (
                    <>
                      {!contract?.accepted &&
                      !contract?.purchased &&
                      !contract?.denied
                        ? "요청 완료"
                        : null}
                      {contract?.accepted &&
                      !contract?.purchased &&
                      !contract?.denied
                        ? "요청 승인됨"
                        : null}
                      {!contract?.accepted &&
                      !contract?.purchased &&
                      contract?.denied
                        ? "요청 거절됨"
                        : null}

                      {contract?.accepted &&
                      contract?.purchased &&
                      !contract?.denied
                        ? "결제 완료"
                        : null}
                    </>
                  )}
                </Status>
              </RegColumn>
              <RegColumn className="hidden">
                <Response
                  contract={contract}
                  onContractUpdateClick={onContractUpdateClick}
                  onDelFromReqListClick={onDelFromReqedListClick}
                  onCancelRequestRegister={onCancelRequestRegister}
                />
              </RegColumn>
            </RegisterRow>
          ))}
    </SRegisterList>
  );
};
