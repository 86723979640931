//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  faArrowLeft,
  faCrown,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { CardColumns } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { CHECK_MESSAGE_MUTATION } from "../../queries/message/checkMessage";
import { routes } from "../../routes/routes";
import CheckUserToken from "../../utils/checkUserToken";
import colors from "../../utils/colors";
import {
  consultsCloseClick,
  consultsSidebarClick,
  consultsSidebarToggleVar,
  isMyGymVar,
  isNewMessageVar,
  messageSidebarClick,
  myChatTabVar,
  myGymTabVar,
  sidebarClick,
} from "../../utils/reactiveVar";
import { FatText } from "./FatText";
import ProfilePhoto from "./ProfilePhoto";

const UserList = styled(CardColumns)`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  min-height: 70vh;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ed5f00;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
  /* padding: 10px; */
`;
const UserOne = styled(CardColumns)`
  display: flex;
  /* width:100 */
  height: 60px;
  align-items: center;
  /* justify-content: space-between; */
  gap: 15px;
  cursor: pointer;
  padding: 0 10px;
  &:hover {
    background-color: rgb(250, 250, 252);
  }
`;
const ConsultOne = styled(CardColumns)`
  width: 100%;
  display: flex;
  vertical-align: top;
  gap: 10px;
  padding: 10px 10px;
  cursor: pointer;
  /* justify-content: space-between; */
  &:hover {
    color: #ed5f00;
    background-color: #f6f9ff;
    span {
      color: #ed5f00;
    }
  }
`;
const ConsultDate = styled(CardColumns)`
  align-self: flex-end;
  width: 100%;
  /* margin-left: 50px; */
  text-align: right;
  align-content: flex-end;
`;
const UserListTitle = styled(CardColumns)`
  font-weight: bold;
  color: #222;
  border: 2px solid #ed5f00;
  border-right: none;
  border-left: none;
  /* border-bottom: none; */
  padding: 10px;
  /* background-color: #ed5f00; */
  font-size: 20px;
  text-align: center;
`;
const NoInfo = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.bbeego};
  gap: 5px;
`;
const NoInfoIcon = styled.i``;
const NoInfoText = styled.div``;
const SelectChatType = styled(CardColumns)`
  width: 100%;
  /* margin-bottom: 10px; */
`;
const Tabs = styled(CardColumns)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  /* margin-bottom: 10px; */
`;
const Tab = styled(CardColumns)`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid ${colors.lightGray};
  cursor: pointer;
  ${(props) =>
    props.checked
      ? `border: 2px solid #ed5f00;  border-bottom: none; color:#ed5f00; background-color: white;`
      : props.cnum === 1
      ? ` border-left:none;  border-bottom: 2px solid #ed5f00;  `
      : ` border-right:none;  border-bottom: 2px solid #ed5f00; `};
  /* transition: border-bottom 0.3s; */
`;
const ModalCon = styled(CardColumns)`
  width: 100%;
  margin: 0;
  padding: 0;
`;
const UserContainer = styled(CardColumns)`
  background-color: #f5f6fa;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
`;
const UserName = styled(CardColumns)`
  color: #222;
  padding: 5px 0 10px 0;
  font-size: 23px;
  font-weight: 700;
`;
const Contents = styled(CardColumns)`
  width: 400px;
  padding: 10px;
  background-color: white;
  min-height: 150px;

  box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
  border-radius: 10px;
  margin-bottom: 10px;
`;
const TypeCon = styled(CardColumns)`
  @media (max-width: 899px) {
    display: none;
  }
`;
const ButtonCon = styled(CardColumns)`
  position: absolute;
  background-color: inherit;
  display: flex;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  padding: 10px 0;
`;

const ChatIcon = styled.i`
  display: none;
  border-radius: 50%;
  background-color: #e4e6eb;
  color: #050505;
  width: 40px;
  height: 40px;
  font-size: 18px;
  padding: 11px;
  margin-left: 10px;
  cursor: pointer;
  @media (max-width: 899px) {
    display: block;
  }
`;
const UserIcon = styled.i`
  display: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e4e6eb;
  color: #050505;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  font-size: 20px;
  padding: 10px;
  @media (max-width: 1199px) {
    display: flex;
  }
`;

const Container = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.maxWidh ? props.maxWidth : "100%")};
  background-color: white;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: unset !important;
  display: flex;
  flex-direction: column;
  gap: 20px;

  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  left: ${(props) => (props.left ? props.left : "")};
  border: ${(props) => (props.containerBorder ? props.containerBorder : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  display: ${(props) => (props.display ? props.display : "")};
  box-shadow: 0 0 15px -5px gray;
  z-index: 100;
`;
const LeftMenu = styled.span`
  cursor: pointer;
  color: gray;
  font-size: 20px;
  font-weight: bold;
  &:hover {
    color: ${colors.bbeego};
  }
`;

const ConsultsNavigationLeft = ({
  iconShow,
  fontSize,
  fontWeight,
  position,
  containerBorder,
  borderRadius,
  margin,
  width,
  top,
  left,
  display,
  roomList,
  consultsData,
  showChatRoom,
  getCosultData,
}) => {
  const history = useHistory();

  const chatRoomList = useRef();
  const loggedInUser = CheckUserToken();
  const cstNavRef = useRef();
  const cstSidebarToggle = useReactiveVar(consultsSidebarToggleVar);
  const tab_1 = useRef();
  const tab_2 = useRef();
  const myChatTab = useReactiveVar(myChatTabVar);
  const [cnsModal, setCNSModal] = useState(false);
  const [modalData, setModalData] = useState();

  const onBarsClick = () => {
    messageSidebarClick();
  };
  const onConsultClick = (v) => {
    getCosultData(v);
    consultsCloseClick();
  };
  const clickModalOutside = (event) => {
    if (cstSidebarToggle && !cstNavRef.current.contains(event.target)) {
      consultsCloseClick();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", clickModalOutside);

    return () => {
      document.removeEventListener("mousedown", clickModalOutside);
    };
  });
  const update = (cache, data) => {
    cache.modify({
      id: `ChatRoom:${showChatRoom}`,
      fields: {
        messages(prev, { fieldName, storeFieldName }) {},
      },
    });
  };

  const onTabClick = (tabNo, { target }) => {
    myChatTabVar(tabNo);
  };

  return (
    <Container
      className="sidebar_custom"
      position={position}
      containerBorder={containerBorder}
      borderRadius={borderRadius}
      margin={margin}
      width={width}
      top={top}
      left={left}
      display={display}
      ref={cstNavRef}
    >
      <CardColumns
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            color: "gray",
            cursor: "pointer",
          }}
          size="lg"
          onClick={() => consultsSidebarClick()}
        />
      </CardColumns>
      <CardColumns
        style={{
          minHeight: "70vh",
        }}
      >
        <UserList>
          <UserListTitle>
            {myChatTab === 1
              ? "유저 목록"
              : loggedInUser?.me?.type === "trainer"
              ? "상담 요청 목록"
              : "유저 목록"}
          </UserListTitle>

          {myChatTab === 1 ||
          (myChatTab === 2 && loggedInUser?.me?.type === "user")
            ? roomList?.seeRoomList //방장
                ?.filter((room) => room?.id == showChatRoom)[0]
                ?.users?.map((user) => {
                  if (
                    user?.id ==
                    roomList?.seeRoomList?.filter(
                      (room) => room?.id == showChatRoom
                    )[0].ownerId
                  ) {
                    return (
                      <UserOne
                        onClick={() =>
                          history.push(routes?.myGym + user?.id, {
                            state: user?.id,
                          })
                        }
                        key={user?.id}
                      >
                        <ProfilePhoto src={user?.profilePhoto} />
                        <FatText fontSize="19px"> {user?.username}</FatText>
                        <FontAwesomeIcon icon={faCrown} color="gold" />
                      </UserOne>
                    );
                  }
                })
            : null}
          {myChatTab === 1 ||
          (myChatTab === 2 && loggedInUser?.me?.type === "user")
            ? roomList?.seeRoomList //방장외 : 위와 합치지 않은 이유는 채팅에 연결된 user 들이 id 순서대로 select 가 되어 방장이 첫번째가 아닌경우가 발생했다.
                ?.filter((room) => room?.id == showChatRoom)[0]
                ?.users?.map((user) => {
                  if (
                    user?.id ==
                    roomList?.seeRoomList?.filter(
                      (room) => room?.id == showChatRoom
                    )[0].ownerId
                  ) {
                  } else {
                    return (
                      <UserOne
                        onClick={() =>
                          history.push(routes?.myGym + user?.id, {
                            state: user?.id,
                          })
                        }
                        key={user?.id}
                      >
                        <ProfilePhoto src={user?.profilePhoto} />
                        <FatText fontSize="19px"> {user?.username}</FatText>
                      </UserOne>
                    );
                  }
                })
            : null}

          {myChatTab === 1 ||
          (myChatTab === 2 && loggedInUser?.me?.type === "user") ? (
            !roomList?.seeRoomList?.filter(
              (room) => room?.id == showChatRoom
            )[0]?.users?.length ? (
              <NoInfo>
                <NoInfoIcon className="bx bx-user-x" />
                <NoInfoText>유저가 존재하지 않습니다.</NoInfoText>
              </NoInfo>
            ) : null
          ) : null}

          {
            myChatTab === 2 && loggedInUser?.me?.type === "trainer"
              ? consultsData?.selectConsults?.map((v) => {
                  if (v?.trainerAccepted === null) {
                    return (
                      <ConsultOne onClick={() => onConsultClick(v)} key={v?.id}>
                        <ProfilePhoto
                          id={v?.trainee?.id}
                          src={v?.trainee?.profilePhoto}
                        />
                        <FatText padding="0 0 0 10px" fontSize="19px">
                          {v?.trainee?.username}
                        </FatText>
                        <ConsultDate>
                          {v.createdAt
                            ? new Date(Number(v.createdAt))
                                .toJSON()
                                .split("T")[0]
                            : v.createdAt}
                        </ConsultDate>
                      </ConsultOne>
                    );
                  }
                })
              : null
            // <NoConsult>
            //   <div>상담 요청 목록이 존재하지 않습니다.</div>
            // </NoConsult>
          }
          {myChatTab === 2 &&
          loggedInUser?.me?.type === "trainer" &&
          !consultsData?.selectConsults?.filter(
            (v) => v.trainerAccepted === null || false
          ).length ? (
            <NoInfo>
              <NoInfoIcon className="bi bi-telephone-x" />
              <NoInfoText>상담요청이 존재하지 않습니다.</NoInfoText>
            </NoInfo>
          ) : null}
        </UserList>
      </CardColumns>
    </Container>
  );
};

export default ConsultsNavigationLeft;
