//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { makeVar } from "@apollo/client";

export const isLoggedInVar = makeVar(Boolean(localStorage.getItem("token")));
export const isNewMessageVar = makeVar(false);
export const isSearchBoxOpenVar = makeVar(false);
export const isMyGymVar = makeVar(false);
export const myGymTabVar = makeVar(3);
export const myHisTabVar = makeVar(2);
export const myChatTabVar = makeVar(1);
export const userTypeVar = makeVar(localStorage.getItem("usertype"));
export const copyVar = makeVar("");
export const userListVar = makeVar([]);
export const challengeListVar = makeVar([]);
export const pTPriceListVar = makeVar([]);
//별점
export const rateStarsVar = makeVar("0");

//side bar toggle
export const sidebarToggleVar = makeVar(
  localStorage.getItem("isSidebarOpen") === "true" ? true : false
);

export const sidebarClick = () => {
  const isSidebarOpen = localStorage.getItem("isSidebarOpen");
  if (isSidebarOpen) {
    if (isSidebarOpen === "true") {
      localStorage.setItem("isSidebarOpen", "false");
      sidebarToggleVar(false);
    } else if (isSidebarOpen === "false") {
      localStorage.setItem("isSidebarOpen", "true");
      sidebarToggleVar(true);
    }
  } else {
    localStorage.setItem("isSidebarOpen", "false");
    sidebarToggleVar(false);
  }
};
export const sidebarCloseClick = () => {
  const isSidebarOpen = localStorage.getItem("isSidebarOpen");
  if (isSidebarOpen) {
    if (isSidebarOpen === "true") {
      localStorage.setItem("isSidebarOpen", "false");
      sidebarToggleVar(false);
    }
  }
};
//message side bar toggle
export const messageSidebarToggleVar = makeVar(
  localStorage.getItem("isMessageSidebarOpen") === "true" ? true : false
);

export const messageSidebarClick = () => {
  const isMessageSidebarOpen = localStorage.getItem("isMessageSidebarOpen");
  if (isMessageSidebarOpen) {
    if (isMessageSidebarOpen === "true") {
      localStorage.setItem("isMessageSidebarOpen", "false");
      messageSidebarToggleVar(false);
    } else if (isMessageSidebarOpen === "false") {
      localStorage.setItem("isMessageSidebarOpen", "true");
      messageSidebarToggleVar(true);
    }
  } else {
    localStorage.setItem("isMessageSidebarOpen", "false");
    messageSidebarToggleVar(false);
  }
};

export const messageCloseClick = () => {
  const isMessageSidebarOpen = localStorage.getItem("isMessageSidebarOpen");
  if (isMessageSidebarOpen) {
    if (isMessageSidebarOpen === "true") {
      localStorage.setItem("isMessageSidebarOpen", "false");
      messageSidebarToggleVar(false);
    }
  }
};

export const consultsSidebarToggleVar = makeVar(
  localStorage.getItem("isConsultsSidebarOpen") === "true" ? true : false
);

export const consultsSidebarClick = () => {
  const isConsultsSidebarOpen = localStorage.getItem("isConsultsSidebarOpen");
  if (isConsultsSidebarOpen) {
    if (isConsultsSidebarOpen === "true") {
      localStorage.setItem("isConsultsSidebarOpen", "false");
      consultsSidebarToggleVar(false);
    } else if (isConsultsSidebarOpen === "false") {
      localStorage.setItem("isConsultsSidebarOpen", "true");
      consultsSidebarToggleVar(true);
    }
  } else {
    localStorage.setItem("isConsultsSidebarOpen", "false");
    consultsSidebarToggleVar(false);
  }
};

export const consultsCloseClick = () => {
  const isConsultsSidebarOpen = localStorage.getItem("isConsultsSidebarOpen");
  if (isConsultsSidebarOpen) {
    if (isConsultsSidebarOpen === "true") {
      localStorage.setItem("isConsultsSidebarOpen", "false");
      consultsSidebarToggleVar(false);
    }
  }
};
export const userLogIn = (token, type, refreshtoken) => {
  localStorage.setItem("token", token);
  localStorage.setItem("usertype", type);
  localStorage.setItem("refreshtoken", refreshtoken);
  isLoggedInVar(true);
  userTypeVar(type);
};
export const userLogOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("usertype");
  localStorage.removeItem("refreshtoken");
  localStorage.removeItem("user_backup");
  isLoggedInVar(false);
  userTypeVar("");
  window.history.replaceState(null, null, "/login");
  window.location.reload();
};
