//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { set } from "react-hook-form";
import styled from "styled-components";
import { GButton } from "../shared/GButton";
import popupimg from "../../static/popup5.png";
import { routes } from "../../routes/routes";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { saveAs } from "file-saver";

import bbeego_menual from "../../static/BBEEGO_menual.pdf";

const PopUpCon = styled.div`
  display: ${(props) => (props.display ? props.display : "none")};
  width: ${(props) => (props.width ? props.width : "300px")};
  height: ${(props) => (props.height ? props.height : "auto")};
  /* max-width: ${(props) => (props.width ? props.width : "500px")};
  max-height: ${(props) => (props.height ? props.height : "1000px")}; */
  position: absolute;
  top: ${(props) => (props.top ? props.top : "50px")};
  left: ${(props) => (props.left ? props.left : "50px")};
  z-index: 99999999;
  box-shadow: 0 2px 6px rgb(0 0 0 / 25%), 0 2px 3px rgb(0 0 0 / 24%);
  border-radius: 5px;
`;
const PopUpImage = styled.div`
  width: 100%;

  height: ${(props) => (props.imgHeight ? props.imgHeight : "660px")};
  background-image: url(${(props) => (props.bgImg ? props.bgImg : "")});
  /* background-image: url(${(props) => (props.bgImg ? props.bgImg : "")}); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  background-color: black;
  z-index: 99999999;
`;

const PopUpClose = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  color: white;
  border: none;
  font-size: 16px;
  padding: 4px;
  text-align: center;
  line-height: 10px;
  visibility: ${(props) => (props.visible ? props.visible : "hidden")};
  transition: 0.3s;
`;

const PopUpButton = styled(GButton)`
  position: absolute;
  top: ${(props) => (props.top ? props.top : "")};
  bottom: ${(props) => (props.bottom ? props.bottom : "")};
  right: ${(props) => (props.right ? props.right : "")};
  left: ${(props) => (props.left ? props.left : "")};
`;

const CheckCon = styled.div`
  width: 100%;
  background-color: black;
  color: white;
  padding: 5px;
  font-size: 13px;
  font-weight: bold;
  /* visibility: ${(props) => (props.visible ? props.visible : "hidden")}; */
  transition: 0.3s;
`;
const PopUp = ({ display, getData, top, bottom, right, left, imgHeight }) => {
  const history = useHistory();
  const [vis, setVis] = useState(false);
  const [checked, setChecked] = useState(false);

  const closeRef = useRef();

  const onClose = () => {
    let array = [{ close: false, check: checked }];
    getData(array);
  };

  const downloadClick = useCallback((e) => {
    const blob = bbeego_menual;
    const filename = blob.split("/static/")[1];
    // const date = new Date().toJSON();
    saveAs(blob, `${filename}`);
  }, []);

  const hoverOutside = (e) => {
    if (closeRef.current?.contains(e.target)) {
      setVis(true);
    } else {
      setVis(false);
    }
  };
  const onChecked = (e) => {
    setChecked(!checked);
  };

  useEffect(() => {
    document.addEventListener("mouseover", hoverOutside);

    return () => {
      document.removeEventListener("mouseover", hoverOutside);
    };
  });

  return (
    <PopUpCon
      top={top}
      bottom={bottom}
      right={right}
      left={left}
      ref={closeRef}
      display={display}
      className="popup"
    >
      <PopUpImage imgHeight={imgHeight} bgImg={popupimg}>
        {" "}
        <PopUpClose
          visible={vis === true ? "visible" : "hidden"}
          onClick={onClose}
        >
          ×
        </PopUpClose>
        <PopUpButton
          onClick={() => {
            downloadClick();
          }}
          width="50%"
          variant="update"
          context="가입 매뉴얼"
          radius="none"
          fontSize="18px"
          fontWeight="bold"
          hoverBgColor="#0DCAF0"
          border="none"
          bottom="35px"
          right="150px"
        />
        <PopUpButton
          onClick={() => {
            history.push(routes?.signup);
          }}
          width="49%"
          variant="create"
          context="지원하기"
          radius="none"
          fontSize="18px"
          fontWeight="bold"
          hoverBgColor="#0DCAF0"
          border="none"
          bottom="35px"
          right="0"
        />
      </PopUpImage>
      <CheckCon>
        <input onChange={onChecked} type="checkbox" value={checked} /> 오늘 하루
        열지않음{" "}
      </CheckCon>
    </PopUpCon>
  );
};
export default PopUp;
