//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//상담 클릭시 옮겨지는 화면으로 트레이너들의 리스트가 보이는 화면입니다.

import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Card, CardColumns, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FatText } from "../component/shared/FatText";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import { SEARCH_USERS_QUERY } from "../queries/user/searchUsers";
import colors from "../utils/colors";
import { routes } from "./routes";

export const SInput = styled.input`
  padding: 10px;
  outline: none;
  width: 30%;
  min-width: 200px;
  text-align: center;
  border: 2px solid gray;
  border-radius: 5px;
  font-weight: bold;
  font-weight : normal;
  &:after {
    content: "BBEEGO";
  }
  &:focus {
    transition: 0.3s;
    border: 2px solid ${colors.bbeegoText};
  }
`;

const TrainerList = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  row-gap: 15px;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(5, 1fr);
    row-gap: 23px;
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 33px;
  }
  @media (max-width: 990px) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 32px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 28px;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 28px;
  }
`;

const Consulting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { register, watch } = useForm();
  const { data } = useQuery(SEARCH_USERS_QUERY, {
    variables: {
      keyword: watch()?.search,
      type: "trainer",
    },
  });

  return (
    <Container
      style={{ display: "flex", flexDirection: "column", gap: "30px" }}
    >
      <CardColumns
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        {/* <Card> */}
        <Card.Body>
          <CardColumns>
            <Card.Title
              style={{
                margin: 0,
                fontWeight: 700,
                textAlign: "center",
                fontSize: "48px",
              }}
            >
              트레이너 채팅 상담
            </Card.Title>
          </CardColumns>
        </Card.Body>
        {/* </Card> */}
        {/* <Card> */}
        <Card.Body
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            textAlign: "center",
            color: "grey",
          }}
        >
          <CardColumns>
            트레이너 채팅 상담을 통해서 PT룸을 등록하기 전에 원하시는 목표에
            대해 상담해보세요!
          </CardColumns>
          <CardColumns>
            채팅 상담은 트레이너의 프로필에서 요청할 수 있습니다.
          </CardColumns>
        </Card.Body>
        {/* </Card> */}
      </CardColumns>
      {/* 트레이너목록 */}
      <CardColumns
        style={{ display: "flex", flexDirection: "column", gap: "30px" }}
      >
        {/* 검색창 */}
        <CardColumns style={{ display: "flex", justifyContent: "center" }}>
          <SInput
            type="text"
            placeholder="트레이너 검색"
            {...register("search")}
          />
        </CardColumns>
        {/* 검색된 트레이너들 목록 */}
        <TrainerList>
          {data?.searchUsers?.map((su) => (
            <Link
              to={{ pathname: `${routes.myGym}${su?.id}`, state: `${su?.id}` }}
            >
              <CardColumns
                key={su?.id}
                style={{
                  width: "200px",
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  backgroundColor: `${colors.lightGray}`,
                  alignItems: "center",
                  boxShadow: "0 5px 10px -1px #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <CardColumns
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <ProfilePhoto
                    width="75px"
                    height="75px"
                    src={su?.profilePhoto}
                    id={su?.id}
                  />
                  <FatText
                    fontSize="18px"
                    color="rgb(99,110,114)"
                    style={{ height: "25px", overflow: "hidden" }}
                  >
                    {su?.username}
                  </FatText>
                </CardColumns>
                <CardColumns></CardColumns>
                <CardColumns style={{ overflow: "hidden", lineHeight: "18px" }}>
                  {su?.description?.length > 100
                    ? su?.description?.substring(0, 100) + "..."
                    : su?.description}
                </CardColumns>
              </CardColumns>
            </Link>
          ))}
        </TrainerList>
      </CardColumns>
    </Container>
  );
};

export default Consulting;
