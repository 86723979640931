import { dateConvertToCurrentTime } from "../../../utils/date/dateConvertToCurrentTime";
import {
  DateP,
  LinkMsgContent,
  MsgCon,
  YoutubeThumCon,
} from "../../../utils/msgStyles";
import YoutubeThumb from "../../shared/youtubeThumb";

const TypeUrl = ({ isUrl, isMine, createdAt }) => {
  return (
    <MsgCon>
      <LinkMsgContent
        onClick={() => window.open(isUrl[0])}
        isMine={isMine}
        style={{ padding: "10px" }}
      >
        {isUrl[0]}
      </LinkMsgContent>
      <YoutubeThumCon>
        {isMine ? (
          <>
            <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
            <YoutubeThumb link={isUrl} isMine={isMine} />
          </>
        ) : (
          <>
            <YoutubeThumb link={isUrl} isMine={isMine} />
            <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
          </>
        )}
      </YoutubeThumCon>
    </MsgCon>
  );
};
export default TypeUrl;
