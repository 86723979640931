//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ChallengeCard from "../component/challenge/ChallengeCard";
import { SeeChallenges } from "../queries/challenge/seeChallenges";
import { InteractBtn } from "./MyGym";
import { routes } from "./routes";
import { useMutation } from "@apollo/client";
import { SEE_CHALLENGES } from "./../queries/challenge/seeChallenges";
import { DELETE_CHALLENGE } from "./../queries/challenge/deleteChallenge";
import { Modal } from "react-bootstrap";
import CheckUserToken from "../utils/checkUserToken";

const ChallengesCon = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 10px;
  row-gap: 10px;
`;
const AddChallengeCon = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px -5px gray;
`;
const Button = styled.button`
  border-radius: 3px;
  font-size: 12px;
  border: 0.5px solid lightgray;
  background-color: white;
  padding: 5px;
  margin-left: auto;
`;
const Explain = styled.div``;
const ChallengeCon = styled.div`
  display: flex;
  width: inherit;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const Challenges = () => {
  const me = CheckUserToken();

  const data = SeeChallenges();

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [deleteChallenge] = useMutation(DELETE_CHALLENGE, {
    refetchQueries: [{ query: SEE_CHALLENGES }],
  });
  const onDeleteChallenge = () => {
    const ChallengeId = modalData?.id;
    const val = window.confirm("챌린지를 삭제합니다.");
    if (!!val) {
      deleteChallenge({
        variables: {
          id: Number(ChallengeId),
        },
      });
    }
  };
  useEffect(() => {
    data?.seeChallenges.map((challenge) => {
      return {
        ...challenge,
      };
    });
  }, [data]);

  return (
    <ChallengesCon id="main">
      <AddChallengeCon>
        {me?.me?.type !== "trainer" ? (
          <Button>
            <Link to={routes?.createChallenge}>
              <FontAwesomeIcon icon={faPlus} />
              챌린지 등록
            </Link>
          </Button>
        ) : null}

        <Explain>
          챌린지는 자녀의 체중감량 또는 증량을 원하는 부모님들이나, 이에
          관련해서 전문적인 도움이 필요한 개인이 챌린지를 생성해 전문인들과
          매칭되도록 도움을 주는 공간입니다.
        </Explain>
      </AddChallengeCon>
      <ChallengeCon>
        {data
          ? data?.seeChallenges?.map((challenge) => {
              return (
                <ChallengeCard
                  key={challenge?.id}
                  {...challenge}
                  setModal={setModal}
                  setModalData={setModalData}
                />
              );
            })
          : null}
      </ChallengeCon>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        centered="true"
        size="sm"
      >
        <Modal.Body>
          {modalData?.isMine ? (
            <>
              {/* <InteractBtn onClick={moveToDetail}>상세페이지 이동</InteractBtn> */}
              <InteractBtn style={{ color: "red" }} onClick={onDeleteChallenge}>
                챌린지 삭제
              </InteractBtn>
              <InteractBtn onClick={() => setModal(false)}>취소</InteractBtn>
            </>
          ) : (
            <>
              {/* <InteractBtn
                onClick={() => {
                  history.push(routes?.challengeInfo, {
                    id: modalData?.id,
                  });
                }}
              >
                상세페이지 이동
              </InteractBtn> */}
              <InteractBtn onClick={() => alert("신고 가즈아ㅏㅏㅏㅏ")}>
                챌린지 신고
              </InteractBtn>
              <InteractBtn onClick={() => setModal(false)}>취소</InteractBtn>
            </>
          )}
        </Modal.Body>
      </Modal>
    </ChallengesCon>
  );
};

export default Challenges;
