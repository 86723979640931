//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FatText } from "../component/shared/FatText";
import { GButton } from "../component/shared/GButton";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import SeeUser from "../queries/user/seeUser";
import { UPDATE_USER_PW } from "../queries/user/updateUesrPW";
import colors from "../utils/colors";
import { userLogOut } from "../utils/reactiveVar";
import UseFormError from "./UseFormError";

const PasswordForm = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 660px) {
    flex-direction: column;
  }
  gap: 15px;
  .FormName {
    min-width: 100%;

    align-self: center;
    text-align: start;

    @media (max-width: 660px) {
      align-self: flex-start;
      margin-bottom: 10px;
      text-align: left;
    }
  }
  .FormValue {
    width: 100%;
  }
  span {
    align-self: center;
    margin-left: 5px;
  }
`;
const PWContainer = styled.div`
  padding: 30px;

  display: flex;
  flex-direction: column;
  .PWEditForm {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .pwnotice {
      color: red;
      font-size: smaller;
    }
  }
  .ProfilenName {
    display: flex;
    align-items: center;
    flex-direction: column;
    span {
      font-size: 27px;
      /* padding: 20px; */
      font-weight: 700;
    }
  }
`;

const EditPassword = () => {
  const location = useLocation();
  const myId = location?.state?.id;
  const myInfo = SeeUser(myId);
  const Info = myInfo?.data?.seeUser;
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState,
  } = useForm({ mode: "onChange" });

  const [updateUserMutation, { loading }] = useMutation(UPDATE_USER_PW, {
    onCompleted: (date) => {
      const {
        updateUser: { ok, error },
      } = date;
      if (!ok) {
        if (error == "socialLoginType") {
          alert("소셜 로그인을 이용한 사용자는 비밀번호를 변경할 수 없습니다.");
        } else {
          alert("비밀번호가 변경 되지 않았습니다.");
        }
      } else {
        alert("비밀번호가 변경 되었습니다. 다시 로그인 해주세요.");
        userLogOut();
      }
    },
  });
  const onSubmitValid = (data) => {
    const { oldpassword, newpassword } = data;
    updateUserMutation({
      variables: {
        id: Info?.id,
        oldpassword,
        newpassword,
      },
    });
  };
  const clearSingupError = (event) => {
    const { oldpassword, newpassword, newpasswordCheck } = getValues();
    if (newpassword !== newpasswordCheck) {
      setError("password_equality", { message: "비밀번호가 다릅니다." });
    } else {
      clearErrors("password_equality");
    }
    if (event.keyCode !== 13) {
      clearErrors("result");
    }
  };
  return (
    <PWContainer>
      <form className="PWEditForm" onSubmit={handleSubmit(onSubmitValid)}>
        <div className="ProfilenName">
          <ProfilePhoto
            width="150px"
            height="150px"
            src={Info?.profilePhoto}
            id={Info?.id}
            margin=" 0 0 10px 0"
          />
          <FatText className="userName">{Info?.username}</FatText>
        </div>
        <PasswordForm>
          <div className="oldpassword FormName">
            <FatText color="rgb(99, 110, 114)">현재 비밀번호</FatText>
          </div>
          <input
            {...register("oldpassword", {
              required: "현재 사용중인 비밀번호를 입력해 주세요.",
              pattern: {
                value:
                  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                message:
                  "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
              },
            })}
            type="password"
            className="oldpassword FormValue form-control"
            onKeyUp={clearSingupError}
          />
        </PasswordForm>
        <UseFormError message={formState?.errors?.oldpassword?.message} />
        <PasswordForm>
          <div className="newpassword FormName">
            <FatText color="rgb(99, 110, 114)">새 비밀번호</FatText>
          </div>
          <input
            {...register("newpassword", {
              required:
                "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
              pattern: {
                value:
                  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                message:
                  "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
              },
            })}
            type="password"
            onKeyUp={clearSingupError}
            className="newpassword FormValue form-control"
          />
        </PasswordForm>
        <UseFormError message={formState?.errors?.newpassword?.message} />
        <PasswordForm>
          <div className="newpasswordCheck FormName">
            <FatText color="rgb(99, 110, 114)">새 비밀번호 확인</FatText>
          </div>
          <input
            {...register("newpasswordCheck", {
              required:
                "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
              pattern: {
                value:
                  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                message:
                  "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
              },
            })}
            type="password"
            className="newpasswordCheck FormValue form-control"
            onKeyUp={clearSingupError}
          />
        </PasswordForm>
        <UseFormError message={formState?.errors?.newpasswordCheck?.message} />
        <div style={{ display: "flex", width: "100%" }}>
          <GButton
            border="none"
            fontSize="16px"
            width="100%"
            padding="0.475rem 0.75rem"
            fontWeight="600"
            type="submit"
            variant="update"
            context="비밀번호 변경"
            className="Submit"
          />
        </div>
      </form>
    </PWContainer>
  );
};

export default EditPassword;
