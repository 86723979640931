//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useState } from "react";
import styled from "styled-components";
import CheckUserToken from "../../utils/checkUserToken";
import ProfilePhoto from "../shared/ProfilePhoto";
import { TypeBadge } from "../shared/TypeBadge";

const MsgCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 10px;
`;
const MsgInfo = styled.div`
  width: 100%;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  /* gap: 3px; */
`;
const MsgProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MsgTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
  padding-bottom: 5px;
  font-weight: 600;
  color: #ed5f00;
  width: 130px;
  text-align: left;
  /* margin-bottom: 10px; */
`;
const MsgMonth = styled.div`
  text-align: left;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 3px;
`;
const MsgPrice = styled.div`
  text-align: left;
  font-size: 17px;
`;

const MsgTypeCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LastMsg = styled.div`
  font-size: 14px;
  max-width: 130px;
  text-align: left;
  padding: 3px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ChallengeChatRoomList = (chatRoom) => {
  const me = CheckUserToken();
  // const mdata = SeeMessages(chatRoom?.id, 1);
  // const seeMessages = mdata?.data?.seeMessages[0];
  const [photo, setPhoto] = useState();
  const lastMsgMaker = (msg) => {
    const isImage = msg?.match(process.env.REACT_APP_SERVER_URL);

    //youtube link detect
    let youtubeUrlRegex = new RegExp(
      "(https://www.youtube.com/|https://youtu.be/)[a-zA-Z0-9?=_-]+",
      "g"
    );
    const isUrl = msg?.match(youtubeUrlRegex);

    //zip file detect
    let zipRegex = new RegExp(
      `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.zip`,
      "g"
    );
    const isZip = msg?.match(zipRegex);

    //pdf file detect
    let pdfRegex = new RegExp(
      `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.pdf`,
      "g"
    );
    //apk file detect
    let apkRegex = new RegExp(
      `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.apk`,
      "g"
    );
    const isApk = msg?.match(apkRegex);

    const isPdf = msg?.match(pdfRegex);
    if (isZip?.length || isApk?.length || isPdf?.length) {
      return "파일";
    } else if (isUrl?.length) {
      return "링크";
    } else if (isImage?.length) {
      return "사진";
    } else {
      return msg;
    }
  };
  useEffect(() => {
    let src = chatRoom?.multiChallengeGroup?.multiChallengeGroupMappers?.filter(
      (v) => chatRoom?.ownerId === v?.user?.id
    )[0].user?.profilePhoto;

    setPhoto(src);
  }, [chatRoom]);
  return (
    <MsgCon>
      <MsgProfile>
        <ProfilePhoto
          width="50px"
          height="50px"
          border="none"
          src={
            chatRoom?.multiChallengeGroup?.multiChallengeGroupMappers?.filter(
              (v) => chatRoom?.ownerId === v?.user?.id
            )[0].user?.profilePhoto
          }
          id={
            chatRoom?.multiChallengeGroup?.multiChallengeGroupMappers?.filter(
              (v) => chatRoom?.ownerId === v?.user?.id
            )[0].user?.id
          }
        />
      </MsgProfile>

      <MsgInfo>
        <MsgTitle>
          {chatRoom?.multiChallengeGroup?.multiChallenge?.title}{" "}
        </MsgTitle>
        <div>
          <MsgMonth>
            {
              new Date(
                Number(chatRoom?.multiChallengeGroup?.multiChallenge?.endDate)
              )
                .toJSON()
                .split("T")[0]
            }{" "}
            종료
          </MsgMonth>
          {/* <LastMsg>{lastMsgMaker(seeMessages?.content)}</LastMsg> */}
          {/* <MsgPrice>
            {me?.me?.type === "trainer"
              ? chatRoom?.multiChallengeGroup?.multiChallenge?.bonus
              : chatRoom?.multiChallengeGroup?.multiChallenge?.reward}
            만원
          </MsgPrice>{" "} */}
        </div>
        {/* <div className="badge bg-primary"></div>{" "}
        <div className="badge bg-warning"></div> */}
      </MsgInfo>
      <MsgTypeCon>
        <TypeBadge width="80px" variant="challenge" context="Challenge" />
      </MsgTypeCon>
    </MsgCon>
  );
};

export default ChallengeChatRoomList;
