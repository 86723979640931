//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import UseFormError from "../Accounts/UseFormError";
import { useHistory, useLocation } from "react-router-dom";
import { UPDATE_PTROOM } from "../queries/PTRoom/updatePTRoom";
import SeePTRoom, { SEE_PTROOM } from "../queries/PTRoom/seePTRoom";
import CheckUserToken from "../utils/checkUserToken";
import { routes } from "./routes";
import { SButton } from "../component/shared/SButton";
import Editor from "../component/PTroom/EditorComponent";
import ImageCrop from "../component/shared/ImageCrop";
import { GButton } from "../component/shared/GButton";
import { CardColumns, Container, Modal } from "react-bootstrap";
import { DELETE_PTROOM } from "../queries/PTRoom/deletePTRoom";
import colors from "../utils/colors";
import AddPicture from "../component/shared/AddPicture";
import NoThumbnail from "../component/shared/NOThumnail";
import IsThumbDropZone from "../component/shared/isThumbDropZone";

const ColName = styled.div`
  width: 100px;
  display: flex;
  line-height: 2rem;
  font-size: 16px;
  color: rgb(99, 110, 114);
  font-weight: bold;
`;

const EditCon = styled(CardColumns)`
  display: flex;
  min-height: 700px;

  .ql-container {
    background-color: white;
    max-width: 694px;
    min-height: 650px;
  }
  @media (max-width: 1000px) {
    .ql-editor {
      width: 100%;
      min-height: 650px;
    }
  }
`;

const PriceRow = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
  margin: 0 0 10px;
  text-align: center;
  align-items: center;
  padding: 3px;
  border-radius: 10px;
  .btn {
    margin-left: auto;
  }
  @media (max-width: 770px) {
    input {
      margin: 0;
    }
    gap: 5px;
  }
`;
const Price = styled.div`
  max-width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  .selMonth {
    width: auto;
  }
  .PTRoom_Price {
    width: auto;
  }

  @media (max-width: 1000px) {
    width: 100%;
    margin: 0;
    /* padding: 5px; */
    .selMonth {
      width: 40%;
      margin: 0 5px;
    }
    .PTRoom_Price {
      width: 35%;
    }
  }
`;

const Btns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 10px;
  justify-content: center;
  button {
    width: 25%;
  }
  input {
    width: 25%;
  }
`;
const FeeAlarm = styled.div`
  color: red;
  font-weight: bold;
`;

const CropConfirm = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;
  justify-content: center;
`;

const CreateForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 30px;
  /* row-gap: 20px; */
  border-radius: 10px;
  background-color: rgb(250, 250, 252);
  @media (max-width: 550px) {
    padding: 15px;
  }
`;
const Title = styled.input`
  /* margin-right: 10px; */

  width: 100%;
`;

const CPTitle = styled.h2`
  margin-top: 100px;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
`;
const SubTitle = styled.h3`
  text-align: center;
  margin: 20px 0 50px 0;
  font-weight: 22px !important;
  color: #6b7280;
`;
const InputCon = styled.div`
  display: flex;
  /* flex-direction: column; */
  line-height: 38px;
  color: rgb(99, 110, 114);
  font-weight: 600;
  /* gap: 10px; */
  @media (max-width: 1000px) {
    margin-bottom: 5px;
    line-height: 38px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
  @media (max-width: 550px) {
    .hidden {
      display: none !important;
    }
  }
`;

const InputComment = styled.span`
  color: red;
  @media (max-width: 1000px) {
  }
  /* margin-left: 10px; */
`;

const ColumnInfo = styled(CardColumns)`
  display: flex;
  flex-direction: row;
  line-height: 38px;
  /* margin-bottom: 10px; */
  /* gap: 10px; */
  /* height: 75px; */
  @media (max-width: 1000px) {
    justify-content: flex-start;
    flex-direction: column;
  }
  @media (max-width: 400px) {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;
const PriceDiv = styled(CardColumns)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const RowInfo = styled(ColumnInfo)`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    display: flex;
    gap: 10px;
    /* flex-direction: column; */
    align-items: flex-start;
  }

  margin-bottom: 10px;
`;

const ColumnEdit = styled(ColumnInfo)`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  margin-bottom: 10px;
`;

const ThumbCon = styled(CardColumns)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* gap: 5px;
  grid-template-rows: repeat(2, auto); */
  /* width: 30%; */
  @media (min-width: 550px) {
    .appear {
      display: none !important;
    }
  }
  @media (max-width: 550px) {
    width: 50%;
  }
`;

const ThumImg = styled.img`
  width: 150px;
  height: 150px;
  border: 1px solid rgb(230, 229, 239);
`;
const dataURLtoFile = (dataurl, fileName) => {
  var arr = dataurl?.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

const CREATE_PT_ROOM = gql`
  mutation CreatePTROOM(
    $title: String!
    $thumbnail: Upload!
    $description: String
    $descriptionImages: [Upload]
    $prices: [PriceInput]
  ) {
    createPTRoom(
      title: $title
      thumbnail: $thumbnail
      description: $description
      descriptionImages: $descriptionImages
      prices: $prices
    ) {
      ok
      error
    }
  }
`;
function CreatePTRoom() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const me = CheckUserToken();
  const [priceArr, setPriceArr] = useState([]);
  const [quillValue, setQuillValue] = useState("");
  const [quillFiles, setQuillFiles] = useState([]);
  const [Description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dropImage, setDropImage] = useState(null);
  const editorRef = useRef();
  const [modal, setModal] = useState(false);
  // console.log(dropImage);

  const getModal = (modal) => {
    setModal(modal);
  };

  // 파일 드랍
  const getThumbnail = async (file) => {
    setDropImage(file);
  };

  useEffect(() => {
    if (dropImage != null) {
      setLoading(true);
    }
  }, [dropImage]);

  const getCropImage = (url) => {
    setCropImage(url);
  };
  const onEditorChange = (content, delta, source, editor) => {
    let fileArr = [];
    const regex = /(src="data.*?)(?=")/g;
    if (content.match(regex)?.[0]) {
      const imgDatas = content.match(regex);
      for (let i = 0; i < imgDatas.length; i++) {
        try {
          imgDatas[i] = imgDatas[i].replace('src="', "");
          fileArr.push(dataURLtoFile(imgDatas[i], "quillImg.png"));
        } catch (e) {
          //fileArr.push(imgDatas[i]);
        }
      }
    }
    const quillData = content.replace(regex, 'src="|||quill|||');
    //화면 띄우는 용
    setQuillValue(content);
    //서버 전송용
    setValue("description", quillData);
    setDescription(quillData);
    setQuillFiles(fileArr);
  };
  const addBtn = () => {
    if (document.getElementsByClassName("Price_row").length < 5) {
      setPriceArr([...priceArr, { month: 1, price: "" }]);
    } else {
      alert("최대 5개 까지 가능합니다.");
    }
  };
  const delBtn = (e) => {
    e.target.parentElement.remove();
  };

  const createCompleted = (data) => {
    const {
      createPTRoom: { ok },
    } = data;
    if (ok) {
      alert("PT룸이 생성 되었습니다.");
      history.push(`${routes.myGym}${me?.me?.id}`);
    }
  };
  const updateCompleted = (data) => {
    const {
      updatePTRoom: { ok },
    } = data;
    if (ok) {
      alert("수정이 완료되었습니다. 이전 페이지로 이동합니다.");
      history.push(routes.sale, {
        ...JSON.parse(JSON.stringify(location?.state?.ptroom)),
      });
    }
  };
  const deleteCompleted = (data) => {
    const {
      deletePTRoom: { ok },
    } = data;
    if (ok) {
      alert("삭제가 완료되었습니다.");
      history.push(routes.pTRooms);
    } else if (!ok) {
      alert("진행중인 PT가 존재합니다.");
      history.push(routes.sale, {
        ...JSON.parse(JSON.stringify(location?.state?.ptroom)),
      });
    }
  };
  const [createPTRoom] = useMutation(CREATE_PT_ROOM, {
    onCompleted: createCompleted,
  });

  const [deletePTRoom] = useMutation(DELETE_PTROOM, {
    onCompleted: deleteCompleted,
  });
  const { register, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });
  //update_PTRoom 으로 접근한 경우
  const location = useLocation();

  const myPTRoomId = location?.state?.ptroom?.id;
  const myPTRoom = SeePTRoom(myPTRoomId)?.data?.seePTRoom;

  const [updatePTRoom] = useMutation(UPDATE_PTROOM, {
    refetchQueries: [
      { query: SEE_PTROOM, variables: { id: Number(myPTRoom?.id) } },
    ],
    onCompleted: updateCompleted,
  });

  const cancelBtn = () => {
    let val = window.confirm("작성한 내용을 지우고 이전페이지로 이동합니다.");
    if (val === true) {
      // window.location.replace(`ptroom/${location?.state?.ptroom[0]}`);
      history.push(routes.sale, {
        ...JSON.parse(JSON.stringify(location?.state?.ptroom)),
      });
    } else {
    }
  };
  const updateSet = () => {
    if (!!myPTRoom) {
      setValue("title", myPTRoom?.title);
      let des = myPTRoom?.description;

      //setQuillValue(des);
      let Prices = myPTRoom?.pTPrices;
      setPriceArr(Prices);
      const qleditor = document.getElementsByClassName("ql-editor")?.[0];
      qleditor.innerHTML = des;
    }
  };
  useEffect(updateSet, [myPTRoom]);
  const onDeleteClick = (data) => {
    let confirm = window.confirm("PT룸을 삭제합니다");
    if (confirm === true) {
      deletePTRoom({
        variables: {
          id: myPTRoom?.id,
        },
      });
    }
  };
  const onSubmitValid = (data) => {
    const { title } = data;
    const pricesCon = document.getElementById("price");
    const prices = pricesCon.getElementsByTagName("div");
    let priceInput = [];

    for (let i = 0; i < prices.length; i++) {
      const pTRoomId = prices[i]?.id;
      const month = Number(prices[i].getElementsByTagName("select")[0].value);
      const price = Number(prices[i].getElementsByTagName("input")[0].value);
      const priceObj = {
        id: Number(pTRoomId),
        month,
        price,
      };
      if (priceObj.price > 0) {
        priceInput.push(priceObj);
      }
    }
    if (
      priceInput.length !==
      priceInput.filter(
        (arr, index, callback) =>
          index === callback.findIndex((p) => p.month === arr.month)
      ).length
    ) {
      return alert("중복된 개월이 존재합니다.");
    } else {
      let agree = window.confirm("PT룸을 생성합니다.");
      if (agree === true) {
        if (!!myPTRoom?.id) {
          updatePTRoom({
            variables: {
              pTRoomId: myPTRoom?.id,
              title,
              thumbnail: thumbnail,
              description: Description,
              descriptionImages: quillFiles,
              pTPrices: priceInput,
            },
          });
        } else {
          if (!!priceInput.length) {
            if (!thumbnail) {
              return alert("썸네일을 첨부해주세요!");
            }
            if (!Description) {
              return alert("설명이 없습니다.");
            }
            createPTRoom({
              variables: {
                title,
                thumbnail: thumbnail,
                description: Description,
                descriptionImages: quillFiles,
                prices: priceInput,
              },
            });
          } else if (!priceInput.length) {
            alert("생성된 가격표가 없습니다.");
          }
        }
      }
    }
  };
  useEffect(() => {}, [thumbnail]);

  return (
    <Container style={{ maxWidth: "768px" }}>
      <CardColumns>
        <CPTitle>PT룸 생성</CPTitle>
        <SubTitle>자신만의 PT룸을 생성해보세요!</SubTitle>
      </CardColumns>
      <CreateForm onSubmit={handleSubmit(onSubmitValid)}>
        <CardColumns
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <RowInfo>
            <CardColumns
              style={{
                color: "rgb(99, 110, 114)",
                fontWeight: "bold",
                minWidth: "100px",
              }}
            >
              <InputComment>*</InputComment>제목{" "}
            </CardColumns>{" "}
            <Title
              {...register("title", {
                required: { value: true, message: "제목은 필수값입니다." },
              })}
              className="form-control"
              type="text"
            />
            <UseFormError
              width="100%"
              color="red"
              message={formState?.errors?.title?.message}
            />
          </RowInfo>
          <RowInfo>
            <CardColumns
              style={{
                color: "rgb(99, 110, 114)",
                fontWeight: "bold",
                minWidth: "100px",
              }}
            >
              <InputComment>*</InputComment>썸네일{" "}
            </CardColumns>
            <ThumbCon>
              <div style={{ width: "150px" }} onClick={() => setModal(true)}>
                <AddPicture length="150px" />
              </div>

              {cropImage ? (
                <ThumImg alt="Crop" src={cropImage} />
              ) : myPTRoom?.thumbnail ? (
                <ThumImg alt="Crop" src={myPTRoom?.thumbnail} />
              ) : null}
              {/* <div
                className="appear"
                style={{ display: "flex", alignItems: "end", padding: "5px" }}
              >
                {thumbnail
                  ? thumbnail.name
                  : myPTRoom?.thumbnail
                  ? String(myPTRoom?.thumbnail).split("static/")[1]
                  : null}
              </div> */}
            </ThumbCon>
            {/* <div
              className="hidden"
              style={{ display: "flex", alignItems: "end", padding: "5px" }}
            >
              {thumbnail
                ? thumbnail.name
                : myPTRoom?.thumbnail
                ? String(myPTRoom?.thumbnail).split("static/")[1]
                : null}
            </div> */}
          </RowInfo>
          <ColumnEdit style={{ height: "auto" }}>
            <CardColumns
              style={{
                color: "rgb(99, 110, 114)",
                fontWeight: "bold",
                minWidth: "100px",
              }}
            >
              <InputComment>*</InputComment>설명{" "}
            </CardColumns>
            <EditCon>
              <Editor
                value={quillValue}
                onChange={onEditorChange}
                ref={editorRef}
              />
            </EditCon>
          </ColumnEdit>

          <PriceDiv>
            <ColName className="FormName">가격표</ColName>
            <SButton
              margin=" 0 0 0 auto"
              context="+ 열 추가"
              type="button"
              className="AddRowBtn"
              bgColor="#6c757d"
              boxShadow="1px 2.5px 5px -1px #6c757d"
              border="none"
              onClick={addBtn}
            />
          </PriceDiv>
          <Price
            border={priceArr < 1 ? "" : "0.5px solid lightgray"}
            className="FormValue"
            id="price"
          >
            {priceArr.map((v, idx) => (
              <PriceRow className="Price_row" key={idx} id={v?.id}>
                <select
                  className="selMonth form-select"
                  id={"Month" + idx}
                  defaultValue={v.month}
                >
                  <option value="1">1개월</option>
                  <option value="2">2개월</option>
                  <option value="3">3개월</option>
                  <option value="6">6개월</option>
                  <option value="12">12개월</option>
                </select>
                <input
                  type="number"
                  min="0"
                  className="PTRoom_Price form-control"
                  defaultValue={v.price}
                  {...register(`price${idx}`, {
                    required: {
                      value: true,
                      message: `가격은 필수값입니다.`,
                    },
                  })}
                />
                <span>만원</span>
                <button
                  style={{ fontSize: "13px" }}
                  type="button"
                  className="btn btn-danger"
                  onClick={delBtn}
                >
                  X
                </button>
                <UseFormError
                  color="red"
                  message={
                    formState?.errors
                      ? formState?.errors[`price${idx}`]?.message
                      : ""
                  }
                />
              </PriceRow>
            ))}
          </Price>
          <Btns>
            <input
              // onChange={onEditorChange}
              type="submit"
              className="btn btn-primary"
              id="CreatPTRoom"
              value={myPTRoomId ? "수정" : "생성"}
            />
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancelBtn}
            >
              취소
            </button>
            {!!location?.state?.ptroom ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={onDeleteClick}
              >
                삭제
              </button>
            ) : null}
          </Btns>
        </CardColumns>
        <FeeAlarm>
          <p>※PT룸 결제시 수수료 20%가 차감됩니다.</p>
        </FeeAlarm>
      </CreateForm>
      <Modal
        size={loading ? "lg" : null}
        // size="lg"
        centered={true}
        show={modal}
        onHide={() => setModal(false)}
      >
        <Modal.Body>
          {/* thumbnail ? (
            <>
              <ImageCrop
                getCropImage={getCropImage}
                file={thumbnail}
                setFile={setThumbnail}
                aspect={1}
                dropImage={dropImage}
                noDetect={true}
              />
            </>
          ) : // <NoThumbnail /> */}
          <ImageCrop
            getCropImage={getCropImage}
            file={thumbnail}
            setFile={setThumbnail}
            getModal={getModal}
            aspect={1}
            dropImage={dropImage}
            noDetect={true}
          />
          {loading ? null : <IsThumbDropZone getThumbnail={getThumbnail} />}
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default CreatePTRoom;
