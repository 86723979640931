//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import gql from "graphql-tag";

export const CREATE_CHALLENGE = gql`
  mutation CreateChallenge(
    $title: String!
    $description: String!
    $descriptionImages: [Upload]
    $age: Int!
    $currentWeight: Int!
    $currentFatPercentage: Int!
    $baseprice: Int!
    $bonus: Int!
    $reward: Int!
    $gender: String!
    $targetWeight: Int!
    $targetFatPercentage: Int!
    $traineeId: Int
    $term: Int!
    $startDate: String!
    $endDate: String
  ) {
    createChallenge(
      title: $title
      description: $description
      descriptionImages: $descriptionImages
      age: $age
      currentWeight: $currentWeight
      currentFatPercentage: $currentFatPercentage
      baseprice: $baseprice
      bonus: $bonus
      reward: $reward
      gender: $gender
      targetWeight: $targetWeight
      targetFatPercentage: $targetFatPercentage
      traineeId: $traineeId
      term: $term
      startDate: $startDate
      endDate: $endDate
    ) {
      ok
      error
    }
  }
`;
