//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//멀티챌린지 관리의 그룹관리 컴포넌트 입니다

import { useState } from "react";
import { Card, CardColumns } from "react-bootstrap";
import styled from "styled-components";
import colors from "../../utils/colors";
import MultiChallengeGroup from "./MultiChallengeGroup";
import MultiChallengeGroupUser from "./MultiChallengeGroupUser";

const SCardTitle = styled(Card.Title)`
  padding: 20px;
  font-weight: bold;
  display: flex;
  gap: 10px;
`;
const Tabs = styled.div`
  width: inherit;
  display: flex;
`;
const Tab = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 2px solid ${colors.defaultGray};
  transition: border-bottom 0.3s;
`;
const SCardBody = styled(Card.Body)`
  display: flex;
  gap: 10px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const MultiChallengeGroupList = ({
  groups,
  trainerCount,
  userCountPerTrainer,
  receipts,
}) => {
  //그룹 선택시 바뀌는 state
  const [selGroup, setSelGroup] = useState();
  return (
    <Card>
      <SCardTitle>
        <Card.Text style={{ width: "100%" }}>그룹 관리</Card.Text>
        <Card.Subtitle
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            fontWeight: "bold",
            gap: "10px",
          }}
        >
          <CardColumns style={{ display: "flex", gap: "10px" }}>
            <Card.Text>그룹 수 :</Card.Text>
            <Card.Text>
              {groups?.length} / {trainerCount}
            </Card.Text>
          </CardColumns>
          <CardColumns style={{ display: "flex", gap: "10px" }}>
            <Card.Text>총 유저 수 :</Card.Text>
            <Card.Text>
              {groups?.reduce((acc, g) => {
                return (acc += g?.multiChallengeGroupMappers?.filter(
                  (mgm) => mgm.type !== "trainer"
                )?.length);
              }, 0)}{" "}
              / {trainerCount * userCountPerTrainer}
            </Card.Text>
          </CardColumns>
        </Card.Subtitle>
      </SCardTitle>

      <SCardBody>
        <Tabs
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tab style={{ cursor: "auto", height: "40px" }}>그룹 목록</Tab>
          <Card.Body
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {groups?.map((g, idx) => (
              <MultiChallengeGroup
                key={g?.id}
                idx={idx}
                group={g}
                selGroup={selGroup}
                setSelGroup={setSelGroup}
                userCountPerTrainer={userCountPerTrainer}
                statements={receipts
                  ?.filter((r) => r?.groupId === g?.id)
                  ?.map((fr) => fr?.receipt?.statement)}
              />
            ))}
          </Card.Body>
        </Tabs>
        <Tabs
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <Tab style={{ cursor: "auto", height: "40px" }}>유저 목록</Tab>
          <Card.Body
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {groups
              ?.filter((g) => g?.id === selGroup)?.[0]
              ?.multiChallengeGroupMappers?.filter(
                (mgm) => mgm?.type === "user"
              )
              ?.map((gm) => (
                <MultiChallengeGroupUser
                  key={gm?.id}
                  groupMapper={gm}
                  statement={
                    receipts
                      ?.filter(
                        (r) => r?.groupId === gm?.multiChallengeGroup?.id
                      )
                      ?.map((fr) => fr?.receipt?.statement)
                      ?.filter(
                        (s) =>
                          s?.user?.type === "user" &&
                          s?.user?.id === gm?.user?.id
                      )?.[0]
                  }
                />
              ))}
          </Card.Body>
        </Tabs>
      </SCardBody>
    </Card>
  );
};

export default MultiChallengeGroupList;
