//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
export default {
  noti: "#ff3f34",
  bbeego: "#fd780f",
  bbeegoText: "#ed5f00",
  setting: "#dfe6e9",
  lightGray: "rgb(250,250,252)",
  defaultGray: "#ebeef4",
  danger: "#dc3545",
  warning: "#ffca2c",
  info: "#31d2f2",
  background: "#fffcfa",
  listBackground: "#f5f6fa",
};
