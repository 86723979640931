//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql } from "@apollo/client";
import { STATEMENT_FRAGMENT } from "../../fragments/statementFragment";

export const SELECT_STATEMENTS_QUERY = gql`
  query SelectStatements(
    $search: String
    $userId: Int
    $take: Int
    $lastId: Int
    $startDate: String
    $endDate: String
  ) {
    selectStatements(
      search: $search
      userId: $userId
      take: $take
      lastId: $lastId
      startDate: $startDate
      endDate: $endDate
    ) {
      ...StatementFragment
    }
  }
  ${STATEMENT_FRAGMENT}
`;
