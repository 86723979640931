//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";

const SRateStars = styled.div`
  width: fit-content;
  height: ${(props) => (props?.height ? props?.height : "50px")};
  position: relative;
`;
const ClipStar = styled.div`
  clip-path: polygon(
    49% 10%,
    51% 10%,
    62% 35%,
    87.5% 35%,
    88% 37%,
    68% 55%,
    77% 79.5%,
    75% 81%,
    51% 67%,
    27% 81%,
    25% 79.5%,
    33% 55%,
    12% 37%,
    12.5% 35%,
    39% 35%
  );
  display: inline-block;
  width: ${(props) => (props?.width ? props?.width : "50px")};
  height: ${(props) => (props?.height ? props?.height : "50px")};
  background: lightgray;
  &::after {
    content: "";
    position: absolute;
    background: gold;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: ${(props) =>
      props?.avgRate
        ? props?.avgRate
        : "0%"}; //position 이 absolute 가 되면서 50% 가 container 기준으로 적용됨.
    font-size: 40px;
  }
`;

const RateStars = (props) => {
  return (
    <SRateStars {...props}>
      <ClipStar {...props} />
      <ClipStar {...props} />
      <ClipStar {...props} />
      <ClipStar {...props} />
      <ClipStar {...props} />
    </SRateStars>
  );
};

export default RateStars;
