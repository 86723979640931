//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import ProfilePhoto from "../shared/ProfilePhoto";
import { TypeBadge } from "../shared/TypeBadge";

const MsgCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 10px;
`;
const MsgInfo = styled.div`
  width: 100%;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  /* gap: 3px; */
`;
const MsgProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MsgTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  width: 160px;
  font-size: 15px;
  padding-bottom: 5px;
  font-weight: 600;
  color: #ed5f00;
  text-align: left;
  /* margin-bottom: 10px; */
`;
const MsgMonth = styled.div`
  text-align: left;
  font-size: 13px;
  font-weight: bold;
`;
const MsgPrice = styled.div`
  text-align: left;
  font-size: 17px;
`;

const MsgTypeCon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LastMsg = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: black;
  max-width: 130px;
  padding: 3px 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ConsultChatRoomList = (chatRoom) => {
  // const mdata = SeeMessages(chatRoom?.id, 1);
  // const seeMessages = mdata?.data?.seeMessages[0];
  const type = chatRoom?.type;
  const lastMsgMaker = (msg) => {
    const isImage = msg?.match(process.env.REACT_APP_SERVER_URL);

    //youtube link detect
    let youtubeUrlRegex = new RegExp(
      "(https://www.youtube.com/|https://youtu.be/)[a-zA-Z0-9?=_-]+",
      "g"
    );
    const isUrl = msg?.match(youtubeUrlRegex);

    //zip file detect
    let zipRegex = new RegExp(
      `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.zip`,
      "g"
    );
    const isZip = msg?.match(zipRegex);

    //pdf file detect
    let pdfRegex = new RegExp(
      `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.pdf`,
      "g"
    );
    //apk file detect
    let apkRegex = new RegExp(
      `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.apk`,
      "g"
    );
    const isApk = msg?.match(apkRegex);

    const isPdf = msg?.match(pdfRegex);
    if (isZip?.length || isApk?.length || isPdf?.length) {
      return "파일";
    } else if (isUrl?.length) {
      return "링크";
    } else if (isImage?.length) {
      return "사진";
    } else {
      return msg;
    }
  };
  return (
    <MsgCon>
      <MsgProfile>
        <ProfilePhoto
          width="50px"
          height="50px"
          border="none"
          id={
            type === "trainer"
              ? chatRoom?.consult?.trainee?.id
              : chatRoom?.consult?.trainer?.id
          }
          src={
            type === "trainer"
              ? chatRoom?.consult?.trainee?.profilePhoto
              : chatRoom?.consult?.trainer?.profilePhoto
          }
        />
      </MsgProfile>

      <MsgInfo>
        <MsgTitle>
          {" "}
          <span className="name">
            {" "}
            {type === "trainer"
              ? chatRoom?.consult?.trainee?.username
              : chatRoom?.consult?.trainer?.username}
          </span>
        </MsgTitle>
        {/* <LastMsg>{lastMsgMaker(seeMessages?.content)}</LastMsg> */}
        {/* <div className="badge bg-primary"></div>{" "}
      <div className="badge bg-warning"></div> */}
      </MsgInfo>
      {/* <MsgTypeCon> */}
      {/* <TypeBadge
          color="#ee0100"
          width="80px"
          variant="denied"
          context="개인"
        /> */}
      {/* </MsgTypeCon> */}
    </MsgCon>
  );
};

export default ConsultChatRoomList;
