//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { REVIEW_FRAGMENT } from "../fragments/reviewFragment";

export const SELECT_REVIEW_BY_USER_QUERY = gql`
  query SelectReviewByUser($type: String!, $typeId: Int!) {
    selectReviewByUser(type: $type, typeId: $typeId) {
      ...ReviewFragment
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const SelectReviewByUser = (type, typeId) => {
  const { data } = useQuery(SELECT_REVIEW_BY_USER_QUERY, {
    variables: {
      type,
      typeId,
    },
  });
  return { data };
};
