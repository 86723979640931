//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { MULTICHALLENGE_FRAGMENT } from "../fragments/multiChallengeFragment";
import { RECEIPT_FRAGMENT } from "../fragments/receiptFragment";
import { MULTICHALLENGE_CONTRACT_FRAGMENT } from "../fragments/multiChallengeContractFragment";

export const SELECT_RECEIPT_BY_CONTRACTID_QUERY = gql`
  query SelectReceiptByContractId($id: Int!) {
    selectReceiptByContractId(id: $id) {
      parent {
        multiChallenge {
          ...MultiChallengeFragment
        }
        receipt {
          ...ReceiptFragment
        }
      }
      children {
        multiChallengeContract {
          ...MultiChallengeContractFragment
        }
        receipt {
          ...ReceiptFragment
        }
        groupId
        type
      }
    }
  }
  ${MULTICHALLENGE_FRAGMENT}
  ${RECEIPT_FRAGMENT}
  ${MULTICHALLENGE_CONTRACT_FRAGMENT}
`;

export const SelectReceiptByContractId = (id) => {
  const { data, refetch } = useQuery(SELECT_RECEIPT_BY_CONTRACTID_QUERY, {
    variables: {
      id,
    },
  });
  return { data, refetch };
};
