//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import Grid from "@toast-ui/react-grid";
import { useRef } from "react";
import { useEffect } from "react";
import {
  ADMIN_SEE_CHALLENGES,
  AdminSeeChallenges,
} from "../../queries/challenge/adminSeeChallenges";

import { SButton } from "../shared/SButton";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Input, Select } from "../shared/Auth";
import { useMutation, useReactiveVar } from "@apollo/client";
import { ADMIN_CREATE_CHALLENGE } from "../../queries/admins/challenge/adminCreateChallenge";
import { userListVar } from "../../utils/reactiveVar";
import { ADMIN_UPDATE_CHALLENGE } from "../../queries/admins/challenge/adminUpdateChallenge";
import { ADMIN_DELETE_CHALLENGE } from "../../queries/admins/challenge/adminDeleteChallenge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  width: 99%;
  background-color: white;
  padding: 10px;
  margin: 15px auto;
  box-shadow: 0 2px 5px #ccc;
  border-radius: 5px;
  .vBtn {
    border: none;
    background-color: white;
    margin: 5px;
    text-align: center;
    width: 100%;
    color: gray;
  }
  .vBtn:hover {
    background-color: orange;
    color: white;
    font-size: 25px;
    border-radius: 5px;
  }
`;
const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

const UserRow = styled.div`
  width: 100%;
  display: ${(props) => (props.display ? props.display : "Grid")};
  grid-template-columns: 1fr 5fr;
  text-align: center;
`;

const Change = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .closeBtn {
    background-color: white;
    color: lightgray;
    float: right;
    border: none;
    font-size: 20px;
  }
  .closeBtn:hover {
    color: orange;
    font-size: 25px;
  }
`;

const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  input {
    border: 1px solid #ccc;
    outline: none;
    width: 100%;
  }
`;

const ColTitle = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  width: 175px;
  padding: 10px;
  font-weight: bold;
  background-color: orange;
  color: white;
  line-height: 40px;
`;

const ColValue = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const RowTitle = styled.span`
  width: 100%;
  display: flex;
  font-size: 20px;
  color: black;
  padding-top: 10px;
`;
const FontCon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
`;

const ChallengerGrid = () => {
  const userList = useReactiveVar(userListVar);
  const { register, getValues, handleSubmit, setValue, watch } = useForm();
  const [selData, setSelData] = useState();

  const [aHidden, setHidden] = useState(false);

  const toggleMenu = () => {
    //aHidden = !aHidden;
    setHidden(!aHidden);
  };
  const gridRef = useRef();
  const [modal, setModal] = useState(false);
  let data = AdminSeeChallenges(100);
  const [createChallengeMutation] = useMutation(ADMIN_CREATE_CHALLENGE, {
    refetchQueries: [
      { query: ADMIN_SEE_CHALLENGES, variables: { quantity: 100 } },
    ],
    onCompleted: () => {
      setModal(false);
    },
  });

  const [updateChallengeMutation] = useMutation(ADMIN_UPDATE_CHALLENGE, {
    refetchQueries: [
      { query: ADMIN_SEE_CHALLENGES, variables: { quantity: 100 } },
    ],
    onCompleted: () => {
      setModal(false);
    },
  });

  const [deleteChallengeMutation] = useMutation(ADMIN_DELETE_CHALLENGE, {
    refetchQueries: [{ query: ADMIN_SEE_CHALLENGES }],
    onCompleted: () => {
      setModal(false);
    },
  });
  const seeChallengesColumns = [
    { name: "id", header: "아이디", align: "center", hidden: true },
    { name: "title", header: "타이틀", align: "center", width: 100 },
    { name: "description", header: "설명", align: "center", width: 100 },
    { name: "username", header: "유저이름", align: "center", width: 100 },
    {
      name: "trainerId",
      header: "트레이너 아이디",
      align: "center",
      width: 125,
    },
    { name: "traineeId", header: "회원 아이디", align: "center", width: 125 },
    { name: "baseprice", header: "가격", align: "center", width: 125 },
    { name: "bonus", header: "보너스", align: "center", width: 125 },
    { name: "reward", header: "보상", align: "center", width: 125 },
    {
      name: "currentWeight",
      header: "현재 몸무게",
      align: "center",
      width: 125,
    },
    {
      name: "targetWeight",
      header: "목표 몸무게",
      align: "center",
      width: 125,
    },
    {
      name: "currentFatPercentage",
      header: "현재 체지방률",
      align: "center",
      width: 125,
    },
    {
      name: "targetFatPercentage",
      header: "목표 체지방률",
      align: "center",
      width: 125,
    },
    { name: "age", header: "나이", align: "center", width: 125 },
    { name: "gender", header: "성별", align: "center", width: 125 },
    { name: "startDate", header: "시작 날짜", align: "center", width: 125 },
    { name: "endDate", header: "종료 날짜", align: "center", width: 125 },
    { name: "term", header: "기간", align: "center", width: 125 },
    { name: "userId", header: "유저 아이디", align: "center", hidden: true },
  ];

  const onGridClick = (e) => {
    const rowData = gridRef.current.getInstance().getRow(e?.rowKey);
    window.a = gridRef.current;
    if (!!rowData) {
      setSelData(rowData);
      setValue("id", rowData.id);
      setValue("title", rowData.title);
      setValue("description", rowData.description);
      setValue("userId", rowData.userId);
      setValue("trainerId", rowData.trainerId);
      setValue("traineeId", rowData.trainerId);
      setValue("baseprice", rowData.baseprice);
      setValue("bonus", rowData.bonus);
      setValue("reward", rowData.reward);
      setValue("currentWeight", rowData.currentWeight);
      setValue("targetWeight", rowData.targetWeight);
      setValue("currentFatPercentage", rowData.currentFatPercentage);
      setValue("targetFatPercentage", rowData.targetFatPercentage);
      setValue("age", rowData.age);
      setValue("gender", rowData.gender);
      setValue("startDate", rowData.startDate);
      setValue("endDate", rowData.endDate);
      setValue("term", rowData.term);
      setModal(true);
    }
  };

  const onChallengeCreateClick = () => {
    setSelData("");
    setValue("id", "");
    setValue("title", "");
    setValue("description", "");
    setValue("userId", "");
    setValue("trainerId", "");
    setValue("traineeId", "");
    setValue("baseprice", "");
    setValue("bonus", "");
    setValue("reward", "");
    setValue("currentWeight", "");
    setValue("targetWeight", "");
    setValue("currentFatPercentage", "");
    setValue("targetFatPercentage", "");
    setValue("age", "");
    setValue("gender", "");
    setValue("startDate", "");
    setValue("endDate", "");
    setValue("term", "");
    setModal(true);
  };

  const onChallengeSaveClick = (data) => {
    const {
      id,
      title,
      description,
      userId,
      trainerId,
      traineeId,
      baseprice,
      bonus,
      reward,
      currentWeight,
      targetWeight,
      currentFatPercentage,
      targetFatPercentage,
      age,
      gender,
      startDate,
      endDate,
      term,
    } = data;

    if (!id) {
      createChallengeMutation({
        variables: {
          title,
          description,
          userId: Number(userId),
          trainerId: Number(trainerId),
          traineeId: Number(traineeId),
          baseprice: Number(baseprice),
          bonus: Number(bonus),
          reward: Number(reward),
          currentWeight: Number(currentWeight),
          targetWeight: Number(targetWeight),
          currentFatPercentage: Number(currentFatPercentage),
          targetFatPercentage: Number(targetFatPercentage),
          age: Number(age),
          gender,
          startDate: startDate ? startDate : undefined,
          endDate: endDate ? endDate : undefined,
          term: Number(term),
        },
      });
    } else {
      updateChallengeMutation({
        variables: {
          id: Number(id),
          title,
          description,
          userId: Number(userId),
          trainerId: Number(trainerId),
          traineeId: Number(traineeId),
          baseprice: Number(baseprice),
          bonus: Number(bonus),
          reward: Number(reward),
          currentWeight: Number(currentWeight),
          targetWeight: Number(targetWeight),
          currentFatPercentage: Number(currentFatPercentage),
          targetFatPercentage: Number(targetFatPercentage),
          age: Number(age),
          gender,
          startDate: startDate ? startDate : undefined,
          endDate: endDate ? endDate : undefined,
          term: Number(term),
        },
      });
    }
  };
  const onChallengeDelteClick = () => {
    const id = getValues().id;
    if (!!id) {
      deleteChallengeMutation({
        variables: {
          id: id,
        },
      });
    }
  };
  useEffect(() => {
    gridRef?.current?.getInstance().resetData(
      data?.adminSeeChallenges?.map((v) => {
        return {
          ...v,
          username: v.user.username,
          userId: v.user.id,
          startDate: v.startDate
            ? new Date(Number(v.startDate)).toJSON().split("T")[0]
            : v.startDate,
          endDate: v.endDate
            ? new Date(Number(v.endDate)).toJSON().split("T")[0]
            : v.endDate,
        };
      })
    );
  }, [data]);

  const selectGen = () => {
    return userList.map((v) => {
      return (
        <option key={v.id} value={v.id}>
          {v.username}
        </option>
      );
    });
  };
  useEffect(() => {}, [userList]);
  watch();
  return (
    <Container>
      <button className="vBtn" onClick={() => toggleMenu()}>
        {aHidden ? (
          <FontCon>
            Challenge Close
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </FontCon>
        ) : (
          <FontCon>
            Challenge Open
            <FontAwesomeIcon size="2x" icon={faAngleRight} />
          </FontCon>
        )}
      </button>
      {aHidden ? (
        <UserContainer>
          <div style={{ textAlign: "right" }}>
            <SButton
              display="inline-block"
              onClick={onChallengeCreateClick}
              width="fit-content"
              context="+ 새 챌린지 생성"
              fontSize="12px"
              color="black"
              border="1px solid lightgray"
            />
          </div>
          <Row margin="10px 0">
            {data?.adminSeeChallenges?.length && (
              <Grid
                ref={gridRef}
                data={data?.adminSeeChallenges?.map((v) => {
                  return {
                    ...v,
                    username: v.user.username,
                    userId: v.user.id,
                    startDate: v.startDate
                      ? new Date(Number(v.startDate)).toJSON().split("T")[0]
                      : v.startDate,
                    endDate: v.endDate
                      ? new Date(Number(v.endDate)).toJSON().split("T")[0]
                      : v.endDate,
                  };
                })}
                width="auto"
                bodyHeight={300}
                onClick={onGridClick}
                columns={seeChallengesColumns}
                rowHeaders={["rowNum"]}
                pageOptions={{
                  perPage: 0,
                  useClient: true,
                  type: "scroll",
                }}
              />
            )}
          </Row>
        </UserContainer>
      ) : null}

      {modal === true ? (
        <Modal size="md" show={modal} onHide={() => setModal(false)}>
          <Change>
            <div style={{ width: "100%", display: "flex" }}>
              <RowTitle>챌린지 생성</RowTitle>
              <button className="closeBtn" onClick={() => setModal(false)}>
                x
              </button>
            </div>
            <BasicInfo>
              <UserRow hidden={true}>
                <ColTitle>아이디</ColTitle>
                <ColValue>
                  <Input {...register("id")} type="text" autoFocus></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>타이틀</ColTitle>
                <ColValue>
                  <Input
                    {...register("title", {
                      required: { value: true, message: "title required." },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>설명</ColTitle>
                <ColValue>
                  <Input
                    {...register("description", {
                      required: {
                        value: true,
                        message: "description required.",
                      },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>유저 이름</ColTitle>
                <ColValue>
                  <Select
                    className="username"
                    {...register("userId", {
                      required: { value: true, message: "user required." },
                    })}
                  >
                    {selectGen()}
                  </Select>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>트레이너 아이디</ColTitle>
                <ColValue>
                  <Select
                    {...register("trainerId", {
                      required: {
                        value: false,
                        message: "trainerId required.",
                      },
                    })}
                    type="text"
                    autoFocus
                  >
                    {selectGen()}
                  </Select>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>회원 아이디</ColTitle>
                <ColValue>
                  <Select
                    {...register("traineeId", {
                      required: {
                        value: false,
                        message: "traineeId required.",
                      },
                    })}
                    type="text"
                  >
                    {selectGen()}
                  </Select>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>가격</ColTitle>
                <ColValue>
                  <Input
                    {...register("baseprice", {
                      required: {
                        value: true,
                        message: "baseprice required.",
                      },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>보너스</ColTitle>
                <ColValue>
                  <Input
                    {...register("bonus", {
                      required: { value: true, message: "bonus required." },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>보상</ColTitle>
                <ColValue>
                  <Input
                    {...register("reward", {
                      required: { value: true, message: "reward required." },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>현재 몸무게</ColTitle>
                <ColValue>
                  <Input
                    {...register("currentWeight", {
                      required: {
                        value: true,
                        message: "currentWeight required.",
                      },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>목표 몸무게</ColTitle>
                <ColValue>
                  <Input
                    {...register("targetWeight", {
                      required: {
                        value: true,
                        message: "targetWeight required.",
                      },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>현재 체지방률</ColTitle>
                <ColValue>
                  <Input
                    {...register("currentFatPercentage", {
                      required: {
                        value: true,
                        message: "currentFatPercentage required.",
                      },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>목표 체지방률</ColTitle>
                <ColValue>
                  <Input
                    {...register("targetFatPercentage", {
                      required: {
                        value: true,
                        message: "targetFatPercentage required.",
                      },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>나이</ColTitle>
                <ColValue>
                  <Input
                    {...register("age", {
                      required: { value: true, message: "age required." },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>성별</ColTitle>
                <ColValue>
                  <Select
                    {...register("gender", {
                      required: { value: true, message: "gender required." },
                    })}
                  >
                    <option value="">성별</option>
                    <option value="male">남자</option>
                    <option value="female">여자</option>
                  </Select>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>시작 날짜</ColTitle>
                <ColValue>
                  <Input
                    {...register("startDate", {
                      message: "startDate required.",
                    })}
                    type="date"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>종료 날짜</ColTitle>
                <ColValue>
                  <Input
                    {...register("endDate", {
                      message: "endDate required.",
                    })}
                    type="date"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>기간</ColTitle>
                <ColValue>
                  <Input
                    {...register("term", {
                      required: { value: true, message: "term required." },
                    })}
                    type="text"
                    autoFocus
                  ></Input>
                </ColValue>
              </UserRow>
            </BasicInfo>
          </Change>
          {selData?.id ? null : (
            <div style={{ textAlign: "right", margin: "0 10px 10px 0" }}>
              <SButton
                onClick={handleSubmit(onChallengeSaveClick)}
                width="fit-content"
                context="생성"
                bgColor="orange"
                color="white"
                fontWeight="bold"
                border="none"
              />
            </div>
          )}
          <div style={{ display: "flex", margin: "0 auto" }}>
            {selData?.id ? (
              <div
                style={{
                  margin: "0 10px 10px 0",
                }}
              >
                <SButton
                  onClick={handleSubmit(onChallengeSaveClick)}
                  width="fit-content"
                  context="수정"
                  bgColor="orange"
                  color="white"
                  fontWeight="bold"
                  border="none"
                />
              </div>
            ) : null}
            {selData?.id ? (
              <div
                style={{
                  margin: "0 10px 10px 0",
                }}
              >
                <SButton
                  onClick={onChallengeDelteClick}
                  width="fit-content"
                  context="삭제"
                  bgColor="#FFA500"
                  color="white"
                  fontWeight="bold"
                  border="none"
                />
              </div>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </Container>
  );
};
export default ChallengerGrid;
