//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faCog, faDoorOpen, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../routes/routes";
import CheckUserToken, { ME } from "../utils/checkUserToken";
import { userLogOut } from "../utils/reactiveVar";
import { useHistory, useParams } from "react-router";
import { SeeMyGym } from "../queries/user/seeMyGym";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
const DropMenu = styled.div`
  inset: 0px auto auto 0px;
  /* transform: translate(-116px, 22px); */
`;
const ImgContainer = styled.div`
  row-gap: -2px;
  width: 220px;
  padding: 10px 10px 5px 10px !important;
  margin: 0;
  padding: 0;
`;
const Profile = styled.img`
  /* border: solid #c8d6e5; */
  display: block;
  margin: 5px auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: left top;
  /* box-shadow: 0 2px 10px 0 rgb(0 0 0 / 6%); */
`;
const PName = styled.div`
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #444444;
`;
const HeaderIcon = styled.i`
  font-size: 22px;
  padding: 5px;
  &:hover {
    color: #ed5f00;
  }
`;
const Button = styled.button`
  display: block;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid #e9ecef;
  /* row-gap: 0px; */
  text-align: left;
  align-items: center;
  align-content: center;
  padding: 10px 15px;
  margin: 0;
  width: 100%;
  /* height: 32px; */
  span {
    color: #888888;
    margin-left: 15px;
  }
  &:hover {
    background-color: #f6f9ff;
    color: #ed5f00;
    span {
    }
  }
`;

const ButtonCon = styled.div`
  /* /* border-right: 1px solid #e9ecef; */
  border: none;
  border-top: 1px solid #e9ecef;
`;
function Accounts() {
  const accountRef = useRef();
  const [show, setShow] = useState(false);
  const clickNotiOutside = (e) => {
    if (show && !accountRef.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickNotiOutside);

    return () => {
      document.removeEventListener("mousedown", clickNotiOutside);
    };
  });
  const params = useParams();
  const history = useHistory();
  // const myAccount = CheckUserToken();
  const {
    data: myAccount,
    loading,
    refetch,
  } = useQuery(ME, {
    fetchPolicy: "network-only",
  });
  const DropdownIcon = <HeaderIcon className="bi bi-gear-fill" />;
  // <FontAwesomeIcon icon={faCog} />;
  const data = SeeMyGym(Number(params?.id));

  const moveToMygym = () => {
    history.push(`${routes.myGym}${myAccount?.me?.id}`, {
      id: myAccount?.me?.id,
    });
  };
  const moveToSetting = () => {
    history.push(routes.setting, {
      id: myAccount?.me?.id,
    });
  };

  const moveToHistory = () => {
    history.push(routes.userHistory, {
      id: myAccount?.me?.id,
    });
  };

  const logoutController = () => {
    if (myAccount?.me?.socialLoginType === "kakao") {
      localStorage.removeItem("token");
      let a = document.createElement("a");
      a.href = `https://kauth.kakao.com/oauth/logout?client_id=${process.env.REACT_APP_REST_API_KEY}&logout_redirect_uri=${process.env.REACT_APP_KAKAO_LOGOUT_REDIRECT_URL}`;
      a.click();
    } else {
      userLogOut();
    }
  };
  return (
    <NavDropdown
      style={{
        // inset: "0px 0px auto auto",
        display: "block",
        gap: "20px",
        padding: "none",
      }}
      title={DropdownIcon}
      id="nav-dropdown"
      show={show}
      onClick={() => setShow(!show)}
      ref={accountRef}
    >
      <ImgContainer>
        <ProfilePhoto
          width="100px"
          height="100px"
          margin="5px auto"
          display="block"
          className="rounded-circle"
          src={myAccount?.me?.profilePhoto}
        />
        <PName>{myAccount?.me?.username}</PName>
      </ImgContainer>
      <ButtonCon>
        <Button onClick={moveToMygym}>
          <HeaderIcon className="bi bi-person-fill" />
          <span>프로필</span>
        </Button>

        <Button onClick={moveToHistory}>
          <HeaderIcon className="bi bi-clock-history" />
          <span>거래내역</span>
        </Button>

        <Button onClick={moveToSetting}>
          <HeaderIcon className="bi bi-gear-fill" />
          <span>환경설정</span>
        </Button>
        {/* <Button
          onClick={() => {
            location.href = "http://csp.waterai.co.kr/";
          }}
        >
          <HeaderIcon className="bi bi-square-fill" />
          <span>Water AI 로 이동</span>
        </Button> */}
        <Button onClick={logoutController}>
          <HeaderIcon className="bi bi-door-open-fill" />
          <span>로그아웃</span>
        </Button>
      </ButtonCon>
    </NavDropdown>
  );
}

export default Accounts;
