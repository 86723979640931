//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//해당 컴포넌트는 트레이너가 참여한 멀티챌린지에서 자신의 그룹을 관리하는 화면에서
//유저를 클릭시 보여지는 인증 사진 컴포넌트 입니다.

import { Card, CardColumns } from "react-bootstrap";

const MCGProveFile = ({ proveFile, setModal, setSelProveFile }) => {
  const onPFClick = () => {
    setModal(true);
    setSelProveFile(proveFile);
  };
  return (
    <Card style={{ cursor: "pointer", borderWidth: "1px" }} onClick={onPFClick}>
      <Card.Body style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CardColumns
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Card.Text>생성날짜 :</Card.Text>
          <Card.Text style={{ fontWeight: "bold" }}>
            {new Date(Number(proveFile?.createdAt)).toJSON()?.split("T")?.[0]}
          </Card.Text>
          <Card.Text>수정날짜 :</Card.Text>
          <Card.Text style={{ fontWeight: "bold" }}>
            {new Date(Number(proveFile?.updatedAt)).toJSON()?.split("T")?.[0]}
          </Card.Text>
        </CardColumns>
      </Card.Body>
    </Card>
  );
};

export default MCGProveFile;
