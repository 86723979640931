//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { TypeBadge } from "../shared/TypeBadge";
import {
  CaseBadge,
  CDate,
  Detail,
  HiddenDiv,
  HisInfo,
  InfoContainer,
  InfoHisCard,
  InfoImage,
  PReqest,
  UserCon,
} from "../../routes/UserHistory";

const RewardHistoryCard = ({ rs, getModalData }) => {
  const onDetails = (data) => {
    getModalData(data);
  };

  return (
    <InfoContainer>
      <HiddenDiv className="appear">
        <CDate>{new Date(Number(rs?.createdAt)).toJSON()?.split("T")[0]}</CDate>
        <Detail>
          <div onClick={() => onDetails(rs)}>결제 상세보기 </div>
        </Detail>
      </HiddenDiv>

      <InfoHisCard>
        <CDate className="hidden">
          {new Date(Number(rs?.createdAt)).toJSON()?.split("T")[0]}
        </CDate>
        <UserCon>
          <InfoImage
            borderRa
            src={rs?.contract?.multiChallenge?.thumbnail}
            onError={(e) =>
              (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
            }
          />
        </UserCon>
        <HisInfo>
          <div style={{ width: "100%" }}>
            <h2> {rs?.contract?.multiChallenge?.title} </h2>
            <span
              style={{
                fontSize: "13.5px",
                fontWeight: "600",
                marginBottom: "5px",
              }}
            >
              {rs?.statement?.transactionType}
            </span>{" "}
            <div>{rs?.statement?.amount.toLocaleString()}원</div>
          </div>
          {/* <div>기간(챌린지)</div> */}
          {/* <div>
            <div style={{ fontWeight: "600" }}>
              {new Date(Number(rs?.createdAt)).toJSON()?.split("T")[0]}
            </div>
            <div style={{ textAlign: "center", fontWeight: "600" }}>~</div>

            <div style={{ fontWeight: "600" }}>
              {" "}
              {new Date(Number(rs?.createdAt)).toJSON()?.split("T")[0]}
            </div>
          </div> */}
          <div
            className="appear"
            style={{
              marginTop: "5px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <TypeBadge
              className="hidden"
              variant="challenge"
              context="Challenge"
            />
          </div>
        </HisInfo>

        <Detail className="hidden">
          <div onClick={() => onDetails(rs)}>상세보기</div>
        </Detail>
        <PReqest>
          <TypeBadge
            variant="challenge"
            context="Challenge"
            className="appear"
            width="80px"
            fontSize="12px"
            height="25px"
          />
          <CaseBadge
            variant={
              rs?.transactionStatus === "거래 진행중"
                ? "progress"
                : rs?.transactionStatus === "거래 성공"
                ? "success"
                : "failed"
            }
          >
            {" "}
            {rs?.transactionStatus === "거래 진행중"
              ? "진행 중"
              : rs?.transactionStatus === "거래 성공"
              ? "지급 완료"
              : "실패"}
          </CaseBadge>
        </PReqest>
      </InfoHisCard>
    </InfoContainer>
  );
};
export default RewardHistoryCard;
