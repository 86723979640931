//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import "./App.css";
import { GlobalStyle } from "./styles";
import { HelmetProvider } from "react-helmet-async";
import { routes } from "./routes/routes";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar, userTypeVar } from "./utils/reactiveVar";
import MainLayout, {
  MainLayoutAbout,
  MainLayoutShort,
} from "./component/shared/MainLayout";
import HomeLayout from "./component/shared/HomeLayout";
import Main from "./routes/Main";
import MyGym from "./routes/MyGym";
import Message from "./routes/Message";
import PostDetail from "./routes/PostDetail";
import Scanning from "./routes/Scanning";
import Setting from "./routes/Setting";
import CreatePost from "./routes/CreatePost";
import CreateTrain from "./routes/CreateTrain";
import CreatePTRoom from "./routes/CreatePTRoom";
import PTRooms from "./routes/PTRooms";
import MyGymEdit from "./routes/MyGymEdit";
import Sale from "./routes/Sales";
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import PTRoomDetail from "./routes/PTRoomDetail";
import Posts from "./routes/Posts";
import Rank from "./component/shared/rank";
import EventDetail from "./routes/EventDetail";
import Challenges from "./routes/Challenges";
import ChallengeDetail from "./routes/ChallengeDetail";
import Admin from "./routes/Admin";
import MyCouponbox from "./routes/MyCouponBox";
import About from "./routes/About";
import KakaoOAuth from "./routes/OAuth/KakaoOAuth";
import KakaoLogout from "./routes/OAuth/KakaoLogout";
import ViewProgress from "./routes/ViewProgress";
import NeedLogin from "./component/main/NeedLogin";
import ChallengeInfo from "./routes/ChallengeInfo";
import ScrollToTop from "./utils/ScrollToTop";
import FAQ from "./routes/FAQ";
import NoReach from "./component/error/NoReach";
import Terms from "./routes/Terms";
import MultiChallengeControl from "./routes/multiChallenge/MultiChallengeControl";
import MultiChallengeList from "./routes/multiChallenge/MultiChallengeList";
import MultiChallengeInfo from "./routes/multiChallenge/MultiChallengeInfo";
import SaleInfo from "./routes/SaleInfo";
import SaleInfoConfirm from "./routes/SaleInfoConfirm";
import LoginCert from "./routes/cert/LoginCert";
import MultiChallengeManagement from "./routes/multiChallenge/MultiChallengeManagement";
import MultiChallengeGroupManagement from "./routes/multiChallenge/MultiChallengeGroupManagement";
import MultiChallengeUserManagement from "./routes/multiChallenge/MultiChallengeUserManagement";
import BrowserRender from "./routes/pytorch/BrowserRender";
import MainIntro from "./routes/MainIntro";
import MultiChallengePaymentConfirm from "./routes/multiChallenge/MultiChallengePaymentConfirm";
import AdminManagement from "./routes/admins/AdminManagement";
import FindPassword from "./routes/FindPassword";
import MainLayoutNF from "./component/shared/MainLayoutNF";
import Rate from "./routes/Rate";
import Report from "./routes/Report";
import Consulting from "./routes/Consulting";
import FaceDetection from "./routes/faceDetection/FaceDetection";
//style
// import "./assets_home/assets/vendor/aos/aos.css";
import "./assets_home/assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets_home/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets_home/assets/vendor/boxicons/css/boxicons.min.css";
import "./assets_home/assets/vendor/glightbox/css/glightbox.min.css";
import "./assets_home/assets/vendor/remixicon/remixicon.css";
import "./assets_home/assets/vendor/swiper/swiper-bundle.min.css";
import CustomerService from "./routes/customerService/CustomerService";
import UserHistory from "./routes/UserHistory";
import AdminLayout from "./component/shared/AdminLayout";
import CustomerServiceManagement from "./routes/admins/customerServiceManagement/CustomerServiceManagement";
import ReportManagement from "./routes/reportManagement/ReportManagement";
import UsersInfo from "./routes/admins/UsersInfo";

function App() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const userType = useReactiveVar(userTypeVar);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <GlobalStyle />
        {isLoggedIn ? (
          <LoginCert>
            {userType === "admin" ? (
              <Switch>
                <Route path={routes?.home} exact>
                  <AdminLayout>
                    <AdminManagement />
                  </AdminLayout>
                </Route>
                <Route path={routes?.usersInfo} exact>
                  <AdminLayout>
                    <UsersInfo />
                  </AdminLayout>
                </Route>
                <Route path={routes?.customerServiceManagement} exact>
                  <AdminLayout>
                    <CustomerServiceManagement />
                  </AdminLayout>
                </Route>
                <Route path={routes?.reportManagement} exact>
                  <AdminLayout>
                    <ReportManagement />
                  </AdminLayout>
                </Route>
                <Route path={`${routes.myGym}:id`} exact>
                  <AdminLayout>
                    <MyGym />
                  </AdminLayout>
                </Route>
                <Route path={routes.sale} exact>
                  <AdminLayout>
                    <Sale />
                  </AdminLayout>
                </Route>
                <Route path={routes.multiChallengeInfo} exact>
                  <AdminLayout>
                    <MultiChallengeInfo />
                  </AdminLayout>
                </Route>
              </Switch>
            ) : (
              <Switch>
                <Route path={routes.home} exact>
                  <MainLayout>
                    <Main isLoggedIn={isLoggedIn} />
                  </MainLayout>
                </Route>
                <Route path={`${routes.myGym}:id`} exact>
                  <MainLayout>
                    <MyGym />
                  </MainLayout>
                </Route>
                <Route path={routes.myGymEdit} exact>
                  <MainLayout>
                    <MyGymEdit />
                  </MainLayout>
                </Route>
                <Route path={routes.message} exact>
                  <MainLayoutNF>
                    <Message />
                  </MainLayoutNF>
                </Route>
                <Route path={routes.viewProgress} exact>
                  <ViewProgress />
                </Route>
                <Route path={routes.posts} exact>
                  <MainLayout>
                    <Posts />
                    <Rank />
                  </MainLayout>
                </Route>
                <Route path={routes.postDetail} exact>
                  <MainLayout>
                    <PostDetail />
                  </MainLayout>
                </Route>
                <Route path={routes.scanning} exact>
                  <MainLayout>
                    <Scanning />
                  </MainLayout>
                </Route>
                <Route path={routes.setting} exact>
                  <MainLayout>
                    <Setting />
                  </MainLayout>
                </Route>
                <Route path={routes.sale} exact>
                  <MainLayout>
                    <Sale />
                  </MainLayout>
                </Route>
                <Route path={routes.createPost} exact>
                  <MainLayout>
                    <CreatePost />
                  </MainLayout>
                </Route>
                <Route path={routes.createTrain} exact>
                  <MainLayout>
                    <CreateTrain />
                  </MainLayout>
                </Route>
                <Route path={routes.updateTrain} exact>
                  <MainLayout>
                    <CreateTrain />
                  </MainLayout>
                </Route>
                <Route path={routes.updatePost} exact>
                  <MainLayout>
                    <CreatePost />
                  </MainLayout>
                </Route>
                <Route path={routes.createPTRoom} exact>
                  <MainLayout>
                    <CreatePTRoom />
                  </MainLayout>
                </Route>
                <Route path={routes.updatePTRoom} exact>
                  <MainLayout>
                    <CreatePTRoom />
                  </MainLayout>
                </Route>
                <Route path={routes.pTRooms} exact>
                  <MainLayout>
                    <PTRooms />
                  </MainLayout>
                </Route>
                <Route path={routes?.pTRoomDetail} exact>
                  <MainLayout>
                    <PTRoomDetail />
                  </MainLayout>
                </Route>
                <Route path={routes?.eventDetail} exact>
                  <MainLayout>
                    <EventDetail />
                  </MainLayout>
                </Route>
                <Route path={routes?.challenges} exact>
                  <MainLayout>
                    <Challenges />
                  </MainLayout>
                </Route>
                <Route path={routes?.challengeDetail} exact>
                  <MainLayout>
                    <ChallengeDetail />
                  </MainLayout>
                </Route>
                <Route path={routes?.challengeInfo} exact>
                  <MainLayout>
                    <ChallengeInfo />
                  </MainLayout>
                </Route>
                <Route path={routes.about} exact>
                  <MainLayoutAbout>
                    <About />
                  </MainLayoutAbout>
                </Route>
                <Route path={routes?.myCouponBox} exact>
                  <MainLayout>
                    <MyCouponbox />
                  </MainLayout>
                </Route>
                <Route path={routes?.faq} exact>
                  <MainLayout>
                    <FAQ />
                  </MainLayout>
                </Route>
                <Route path={routes?.terms} exact>
                  <MainLayout>
                    <Terms />
                  </MainLayout>
                </Route>
                <Route path={routes?.createMultiChallenge} exact>
                  <MainLayout>
                    <MultiChallengeControl />
                  </MainLayout>
                </Route>
                <Route path={routes?.multiChallengeList} exact>
                  <MainLayout>
                    <MultiChallengeList />
                  </MainLayout>
                </Route>
                <Route path={routes?.multiChallengeInfo} exact>
                  <MainLayout>
                    <MultiChallengeInfo />
                  </MainLayout>
                </Route>
                <Route path={routes?.saleInfo} exact>
                  <MainLayoutShort>
                    <SaleInfo />
                  </MainLayoutShort>
                </Route>
                <Route path={routes?.saleInfoConfirm} exact>
                  <MainLayout>
                    <SaleInfoConfirm />
                  </MainLayout>
                </Route>
                <Route path={routes?.multiChallengeManagement} exact>
                  <MainLayout>
                    <MultiChallengeManagement />
                  </MainLayout>
                </Route>
                <Route path={routes?.multiChallengeGroupManagement} exact>
                  <MainLayout>
                    <MultiChallengeGroupManagement />
                  </MainLayout>
                </Route>
                <Route path={routes?.multiChallengeUserManagement} exact>
                  <MainLayout>
                    <MultiChallengeUserManagement />
                  </MainLayout>
                </Route>
                <Route path={routes?.userHistory} exact>
                  <MainLayout>
                    <UserHistory />
                  </MainLayout>
                </Route>
                {/* 신고 기능 */}
                <Route path={routes.report} exact>
                  <MainLayout>
                    <Report />
                  </MainLayout>
                </Route>
                <Route path={routes?.browserRender} exact>
                  <MainLayout>
                    <BrowserRender />
                  </MainLayout>
                </Route>
                <Route path={routes?.multiChallengePaymentConfirm} exact>
                  <MainLayout>
                    <MultiChallengePaymentConfirm />
                  </MainLayout>
                </Route>
                <Route path={routes?.rate} exact>
                  <MainLayout>
                    <Rate />
                  </MainLayout>
                </Route>
                <Route path={routes?.consulting} exact>
                  <MainLayout>
                    <Consulting />
                  </MainLayout>
                </Route>
                <Route path={routes?.customerService} exact>
                  <MainLayout>
                    <CustomerService />
                  </MainLayout>
                </Route>
                <Route path={routes?.login} exact>
                  <Redirect to={{ pathname: routes.home }} />
                </Route>
                {/* <Route path={"*"}>
                  <NoReach />
                </Route> */}
              </Switch>
            )}
          </LoginCert>
        ) : (
          <Switch>
            <Route path={routes.home} exact>
              <MainLayout>
                <MainIntro />
              </MainLayout>
            </Route>
            <Route path={routes.login} exact>
              <MainLayout>
                <Login />
              </MainLayout>
            </Route>
            <Route path={routes.signup} exact>
              <MainLayoutShort>
                <Signup />
              </MainLayoutShort>
            </Route>
            <Route path={routes.findPassword} exact>
              <MainLayout>
                <FindPassword />
              </MainLayout>
            </Route>
            <Route path={routes?.kakaoOAuth} exact>
              <KakaoOAuth />
            </Route>
            <Route path={routes?.kakaoLogout} exact>
              <KakaoLogout />
            </Route>
            <Route path={routes?.terms} exact>
              <MainLayout>
                <Terms />
              </MainLayout>
            </Route>
            <Route path={routes.about} exact>
              <MainLayoutAbout>
                <About />
              </MainLayoutAbout>
            </Route>
            <Route path={routes?.faq} exact>
              <MainLayout>
                <FAQ />
              </MainLayout>
            </Route>
            <Route path={routes?.multiChallengeList} exact>
              {/* <LoginCert> */}
              <MainLayout>
                <MultiChallengeList />
              </MainLayout>
              {/* </LoginCert> */}
            </Route>
            <Route path={routes.pTRooms} exact>
              {/* <LoginCert> */}
              <MainLayout>
                <PTRooms />
              </MainLayout>
              {/* </LoginCert> */}
            </Route>
            <Route path={routes.scanning} exact>
              <LoginCert>
                <MainLayout>
                  <Scanning />
                </MainLayout>
              </LoginCert>
            </Route>
            <Route path={routes.message} exact>
              <LoginCert>
                <MainLayoutNF>
                  <Message />
                </MainLayoutNF>
              </LoginCert>
            </Route>
            <Route path={`${routes.myGym}:id`} exact>
              <LoginCert>
                <MainLayout>
                  <MyGym />
                </MainLayout>
              </LoginCert>
            </Route>
            <Route path={routes.consulting} exact>
              <LoginCert>
                <MainLayout>
                  <Consulting />
                </MainLayout>
              </LoginCert>
            </Route>
            <Route path={routes.customerService} exact>
              <LoginCert>
                <MainLayout>
                  <CustomerService />
                </MainLayout>
              </LoginCert>
            </Route>
            {/* <Route path={"*"}>
              <NoReach />
            </Route> */}
          </Switch>
        )}
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
