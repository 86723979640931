//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/public/createUploadLink.js";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_SERVER_URL}graphql`,
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_SERVER_URL_WS}graphql`,
  options: {
    reconnect: true,
    connectionParams: () => ({
      token: localStorage.getItem("token"),
      refreshtoken: localStorage.getItem("refreshtoken"),
    }),
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("token");
  const refreshtoken = localStorage.getItem("refreshtoken");
  return {
    headers: {
      ...headers,
      ...(token && { token }),
      ...(refreshtoken && { refreshtoken }),
    },
  };
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(uploadLink)
);
export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      // Query: {
      //   fields: {
      //     seeMessages: {
      //       keyArgs: false,
      //       merge(existing = [], incoming = []) {
      //         console.log(existing, incoming);
      //         if (!!incoming.length) {
      //           return [...existing, ...incoming];
      //         } else {
      //           return existing;
      //         }
      //       },
      //     },
      //   },
      // },
      seeMyGym: {
        keyFields: false,
        merge(existing = [], incoming = []) {
          return [...existing, ...incoming];
        },
      },
      seeUsers: {
        keyFields: false,
        merge(existing = [], incoming = []) {
          return [...existing, ...incoming];
        },
      },
      selectReceiptsByUserId: {
        keyFields: false,
        merge(existing = [], incoming = []) {
          return [...existing, ...incoming];
        },
      },
    },
  }),
});

export default client;
