//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//멀티 챌린지는 그룹당 담당 트레이너 한명이 연결되는데, 담당 트레이너가 해당 그룹의 유저들을 관리하는 라우트 컴포넌트 입니다.

import { useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardColumns,
  CardDeck,
  CardGroup,
  Container,
  Form,
  FormCheck,
  FormControl,
  Image,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { SelectMultiChallengeGroupById } from "../../queries/multiChallenge/selectMultiChallengeGroupById";
import { Ribbon } from "../../component/shared/Ribbon";
import styled from "styled-components";
import colors from "../../utils/colors";
import { useState } from "react";
import MultiChallengeGroupManageUser from "../../component/multiChallenge/trainer/MultiChallengeGroupManageUser";
import MCGProveFile from "../../component/multiChallenge/trainer/MCGProveFile";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MULTICHALLENGE_GROUP_MAPPER_JUDGEMENT_MUTATION } from "../../queries/multiChallenge/multiChallengeGroupMapperJudgement";
import { Controller, useForm } from "react-hook-form";
import UseFormError from "../../Accounts/UseFormError";
import { useEffect } from "react";
import { SELECT_RECEIPT_BY_CONTRACTID_QUERY } from "../../queries/Receipt/selectReceiptByContractId";

const Tabs = styled.div`
  width: inherit;
  display: flex;
`;
const Tab = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 2px solid ${colors.defaultGray};
  transition: border-bottom 0.3s;
`;
const SCardBody = styled(Card.Body)`
  display: flex;
  gap: 10px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const MultiChallengeGroupManagement = () => {
  const location = useLocation();

  const { data } = SelectMultiChallengeGroupById(location?.state?.id);
  const [selGroupMapper, setSelGroupMapper] = useState();
  const [modal, setModal] = useState(false);
  const [selProveFile, setSelProveFile] = useState();
  //useform
  const {
    register,
    getValues,
    handleSubmit,
    formState,
    setValue,
    control,
    watch,
  } = useForm();
  //모달 성공 radio
  //챌린지 성공여부 mutation
  const onJudgeUpdate = async (cache, data) => {
    const {
      data: {
        multiChallengeGroupMapperJudgement: { ok },
      },
    } = data;
    if (ok) {
      await cache.modify({
        id: `MultiChallengeGroupMapper:${selGroupMapper?.id}`,
        fields: {
          isChallengeSuccess(prev) {
            return getValues()?.isChallengeSuccess === "success" ? true : false;
          },
          challengeFailedReason(prev) {
            if (getValues()?.isChallengeSuccess === "success") {
              setValue("failedReason", null);
              return null;
            } else {
              return getValues().failedReason;
            }
          },
        },
      });
      await setModal(false);
    }
  };
  const [multiChallengeGroupMapperJudgementMutation] = useMutation(
    MULTICHALLENGE_GROUP_MAPPER_JUDGEMENT_MUTATION,
    {
      update: onJudgeUpdate,
    }
  );
  //챌린지 성공여부 확인 버튼 클릭시
  const onJudgementClick = (values) => {
    const { failedReason, isChallengeSuccess } = values;
    if (!isChallengeSuccess) {
      return alert("성공 실패 여부를 선택해주세요!");
    }
    if (isChallengeSuccess === "failed" && !failedReason) {
      return;
    }
    const confirmBool = window.confirm("진행하시겠습니까?");
    if (!!confirmBool) {
      multiChallengeGroupMapperJudgementMutation({
        variables: {
          id: selGroupMapper?.id,
          isChallengeSuccess: isChallengeSuccess === "success" ? true : false,
          ...(isChallengeSuccess === "failed" && {
            challengeFailedReason: failedReason,
          }),
        },
      });
    }
  };
  //유저 선택시
  useEffect(() => {
    if (!!selGroupMapper) {
      //setIsChallengeSuccess(selGroupMapper?.isChallengeSuccess);
      setValue("failedReason", selGroupMapper?.challengeFailedReason);
      setValue(
        "isChallengeSuccess",
        selGroupMapper?.isChallengeSuccess === true
          ? "success"
          : selGroupMapper?.isChallengeSuccess === false
          ? "failed"
          : ""
      );
    }
  }, [selGroupMapper]);
  //receipt
  const [selectReceiptByContractId_lazy, { data: receiptData }] = useLazyQuery(
    SELECT_RECEIPT_BY_CONTRACTID_QUERY
  );
  useEffect(() => {
    if (!!data) {
      selectReceiptByContractId_lazy({
        variables: {
          id: data?.selectMultiChallengeGroupById?.multiChallenge?.id,
        },
      });
    }
  }, [data]);
  return (
    <Container
      style={{ display: "flex", flexDirection: "column", gap: "15px" }}
    >
      <CardColumns>
        <CardDeck
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          {data?.selectMultiChallengeGroupById?.multiChallengeGroupMappers?.filter(
            (mcgm) =>
              mcgm?.type === "trainer" && mcgm?.isChallengeSuccess === false
          )?.length ? (
            <span
              style={{
                cursor: "auto",
                fontSize: "12px",
              }}
              className="badge border-danger border-2 text-danger"
            >
              실패 사유 :{" "}
              {
                data?.selectMultiChallengeGroupById?.multiChallengeGroupMappers?.filter(
                  (mcgm) =>
                    mcgm?.type === "trainer" &&
                    mcgm?.isChallengeSuccess === false
                )?.[0]?.challengeFailedReason
              }
            </span>
          ) : null}
          <Card>
            <CardColumns
              style={{
                position: "absolute",
                top: "-8px",
                left: "-8px",
                width: "fit-content",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Ribbon
                context="Challenge"
                top="-8px"
                left="-8px"
                width="120px"
                padding="3px"
                boxshadow="none"
                borderradius="60px"
                position="unset"
                margin="0 5px 0 0"
              />
              <Card.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                  padding: "5px",
                  borderRadius: "50px",
                  width: "fit-content",
                }}
              >
                {data?.selectMultiChallengeGroupById?.multiChallengeGroupMappers
                  ?.filter((mcgm) => mcgm?.type === "trainer")
                  ?.map((fmcgm) => {
                    if (fmcgm?.isChallengeSuccess === true) {
                      return (
                        <span
                          style={{ cursor: "auto" }}
                          className="badge border-success border-2 text-success"
                        >
                          성공중
                        </span>
                      );
                    } else if (fmcgm?.isChallengeSuccess === false) {
                      return (
                        <span
                          style={{ cursor: "auto" }}
                          className="badge border-danger border-2 text-danger"
                        >
                          실패
                        </span>
                      );
                    } else {
                      return (
                        <span
                          style={{ cursor: "auto" }}
                          className="badge border-danger border-2 text-secondary"
                        >
                          평가없음
                        </span>
                      );
                    }
                  })}
              </Card.Text>
              <Card.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                  padding: "5px",
                  borderRadius: "50px",
                  width: "fit-content",
                }}
              >
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-dark border-2 text-dark"
                >
                  종료날짜 :{" "}
                </span>
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-dark border-2 text-dark"
                >
                  {
                    new Date(
                      Number(
                        data?.selectMultiChallengeGroupById?.multiChallenge
                          ?.endDate
                      )
                    )
                      ?.toJSON()
                      ?.split("T")?.[0]
                  }
                </span>
              </Card.Text>
              {data?.selectMultiChallengeGroupById?.multiChallenge
                ?.isFinished ? (
                <Card.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "5px",
                    borderRadius: "50px",
                    width: "fit-content",
                  }}
                >
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-danger border-2 text-danger"
                  >
                    챌린지 종료
                  </span>
                </Card.Text>
              ) : null}
            </CardColumns>
            <Card.Title
              style={{ padding: "30px 20px 10px 20px", fontWeight: "bold" }}
            >
              {data?.selectMultiChallengeGroupById?.multiChallenge?.title}
            </Card.Title>
            <CardColumns
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Card.Text style={{ display: "flex", gap: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  기본급 :{" "}
                  {Number(
                    receiptData?.selectReceiptByContractId?.children?.filter(
                      (r) =>
                        r?.groupId ===
                          data?.selectMultiChallengeGroupById?.id &&
                        r?.type === "trainer" &&
                        r?.receipt?.statement?.transactionType === "기본급"
                    )?.[0]?.receipt?.statement?.amount
                  )}{" "}
                  원
                </span>
                {receiptData?.selectReceiptByContractId?.children?.filter(
                  (r) =>
                    r?.groupId === data?.selectMultiChallengeGroupById?.id &&
                    r?.type === "trainer" &&
                    r?.receipt?.statement?.transactionType === "기본급"
                )?.[0]?.receipt?.statement?.isTransferred === true ? (
                  <span style={{ fontWeight: "bold", color: "green" }}>
                    지급 완료
                  </span>
                ) : (
                  <span style={{ fontWeight: "bold", color: "gray" }}>
                    지급전
                  </span>
                )}
              </Card.Text>
              <Card.Text style={{ display: "flex", gap: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  보상금 :{" "}
                  {Number(
                    receiptData?.selectReceiptByContractId?.children?.filter(
                      (r) =>
                        r?.groupId ===
                          data?.selectMultiChallengeGroupById?.id &&
                        r?.type === "trainer" &&
                        r?.receipt?.statement?.transactionType === "보상금"
                    )?.[0]?.receipt?.statement?.amount
                  )}{" "}
                  원
                </span>
                {receiptData?.selectReceiptByContractId?.children?.filter(
                  (r) =>
                    r?.groupId === data?.selectMultiChallengeGroupById?.id &&
                    r?.type === "trainer" &&
                    r?.receipt?.statement?.transactionType === "보상금"
                )?.[0]?.receipt?.statement?.isTransferred === true ? (
                  <span style={{ fontWeight: "bold", color: "green" }}>
                    지급 완료
                  </span>
                ) : (
                  <span style={{ fontWeight: "bold", color: "gray" }}>
                    지급전
                  </span>
                )}
              </Card.Text>
            </CardColumns>
          </Card>
        </CardDeck>
      </CardColumns>
      <CardColumns>
        <Card>
          <Card.Title style={{ padding: "20px", fontWeight: "bold" }}>
            내 그룹 관리
          </Card.Title>
          <SCardBody>
            <Tabs
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab style={{ cursor: "auto", height: "40px" }}>유저 목록</Tab>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {data?.selectMultiChallengeGroupById?.multiChallengeGroupMappers
                  ?.filter((mgm) => mgm?.type !== "trainer")
                  ?.map((gm) => (
                    <MultiChallengeGroupManageUser
                      key={gm?.id}
                      groupMapper={gm}
                      selGroupMapper={selGroupMapper}
                      setSelGroupMapper={setSelGroupMapper}
                      statement={
                        receiptData?.selectReceiptByContractId?.children
                          ?.filter(
                            (r) =>
                              r?.type === "user" &&
                              r?.receipt?.statement?.user?.id === gm?.user?.id
                          )
                          ?.map((fr) => fr?.receipt?.statement)?.[0]
                      }
                    />
                  ))}
              </Card.Body>
            </Tabs>
            <Tabs
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Tab style={{ cursor: "auto", height: "40px" }}>
                유저 인증 목록
              </Tab>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {data?.selectMultiChallengeGroupById?.multiChallengeGroupMappers
                  ?.filter(
                    (mgm) =>
                      mgm?.type === "user" && mgm?.id === selGroupMapper?.id
                  )?.[0]
                  ?.MCGProveFiles?.map((pf) => (
                    <MCGProveFile
                      key={pf?.id}
                      proveFile={pf}
                      setModal={setModal}
                      setSelProveFile={setSelProveFile}
                    />
                  ))}
              </Card.Body>
            </Tabs>
          </SCardBody>
        </Card>
      </CardColumns>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
        }}
      >
        <Modal.Body>
          {selProveFile ? (
            <CardGroup
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <CardColumns
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "bold",
                  gap: "10px",
                }}
              >
                <CardColumns style={{ display: "flex", gap: "10px" }}>
                  <Card.Text>생성날짜 : </Card.Text>
                  <Card.Text>
                    {
                      new Date(Number(selProveFile?.createdAt))
                        .toJSON()
                        ?.split("T")?.[0]
                    }
                  </Card.Text>
                </CardColumns>
                <CardColumns style={{ display: "flex", gap: "10px" }}>
                  <Card.Text>수정날짜 : </Card.Text>
                  <Card.Text>
                    {
                      new Date(Number(selProveFile?.updatedAt))
                        .toJSON()
                        ?.split("T")?.[0]
                    }
                  </Card.Text>
                </CardColumns>
              </CardColumns>
              <CardColumns style={{ width: "100%" }}>
                <Image src={selProveFile?.filepath} width="100%" />
              </CardColumns>
              {data?.selectMultiChallengeGroupById?.multiChallenge
                ?.isFinished ? (
                <CardColumns style={{ color: "red" }}>
                  챌린지가 종료되었습니다.
                </CardColumns>
              ) : (
                <CardColumns
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Controller
                    name="isChallengeSuccess"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Form.Group {...field}>
                        <FormCheck
                          type="radio"
                          id="challengeSuccess"
                          label="성공"
                          name={field.name}
                          value="success"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          checked={field.value === "success"}
                        />
                        <FormCheck
                          type="radio"
                          id="challengeFailed"
                          label="실패"
                          name={field.name}
                          value="failed"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          checked={field.value === "failed"}
                        />
                      </Form.Group>
                    )}
                  />
                  {watch().isChallengeSuccess === "failed" ? (
                    <>
                      <InputGroup>
                        <FormControl
                          placeholder="실패 사유"
                          {...register("failedReason", {
                            required: {
                              value: true,
                              message: "실패 사유가 필요합니다!",
                            },
                          })}
                        />
                      </InputGroup>
                      <UseFormError
                        message={formState?.errors?.failedReason?.message}
                      />
                    </>
                  ) : null}
                  <Button
                    variant="primary"
                    onClick={handleSubmit(onJudgementClick)}
                  >
                    확인
                  </Button>
                </CardColumns>
              )}
            </CardGroup>
          ) : null}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default MultiChallengeGroupManagement;
