//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import ChallengeContractGrid from "../component/admins/ChallengeContractGrid";
import ChallengerGrid from "../component/admins/ChallengeGrid";
import ChatRoomGrid from "../component/admins/ChatRoomGrid";
import ContractGrid from "../component/admins/ContractGrid";
import CouponGrid from "../component/admins/CouponGrid";
import EventBannerGrid from "../component/admins/EventBannerGrid";
import GivenCouponGrid from "../component/admins/GivenCouponGrid";
import PTPriceGrid from "../component/admins/PTPriceGrid";
import PTRoomGrid from "../component/admins/PTRoomGrid";
import UserGrid from "../component/admins/UserGrid";

const Container = styled.div`
  width: 100%;
`;
const Admin = () => {
  return (
    <Container>
      <UserGrid />
      <ChallengerGrid />
      <EventBannerGrid />
      <ChallengeContractGrid />
      <CouponGrid />
      <GivenCouponGrid />
      <PTRoomGrid />
      <PTPriceGrid />
      <ContractGrid />
      <ChatRoomGrid />
    </Container>
  );
};
export default Admin;
