//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useQuery, useReactiveVar } from "@apollo/client";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { SEARCH_USERS_QUERY } from "../../queries/user/searchUsers";
import { routes } from "../../routes/routes";
import colors from "../../utils/colors";
import { isSearchBoxOpenVar } from "../../utils/reactiveVar";
import ProfilePhoto from "../shared/ProfilePhoto";

const SearchCon = styled.div`
  //border: 0.5px solid gray;
  input {
    //border: none;
    outline: none;
    /* padding: 5px; */
    height: ${(props) => (props?.height ? props?.height : "35px")};
    @media (max-width: 1400px) {
      max-width: 115px;
    }
  }
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
  height: 30px;
`;
const Searchbox = styled.div`
  width: auto;
  position: relative;
  z-index: 103;
`;
export const Triangle = styled.div`
  position: absolute;
  border: 1px solid lightgray;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  left: 90px;
  top: 35px;
  @media (max-width: 600px) {
    left: 40px;
  }
`;
export const DropBox = styled.div`
  position: absolute;
  /* border: 1px solid lightgray; */
  border-radius: 5px;
  box-shadow: 0 5px 30px 0 rgb(82 63 105 / 20%);
  background-color: white;
  min-width: 250px;
  min-height: 60px;
  max-height: 300px;
  padding: 10px;
  padding-bottom: none;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
  display: flex;
  flex-direction: column;
  /* left: -100px; */
  top: 33px;
  @media (max-width: 600px) {
    min-width: 300px !important;
  }
`;
export const UserCon = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 18px;
  padding: 0 5px;
  border-radius: 8px;
  .Name_Desc {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    div {
      width: 190px;
      font-weight: 700;
      line-height: 30px;
    }
  }
  /* padding-bottom: 5px; */
  cursor: pointer;
  &:hover {
    background-color: #e9ecef;
  }
`;

const NoUserCon = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  font-size: 16px;
  padding: 10px 10px 0 10px;
  text-align: center;
  color: #b2bec3;
  width: 100%;
  font-weight: 600;
  text-align: center;
  margin-right: 15px;

  .Name_Desc {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    height: 100%;
    vertical-align: top;
    div {
      width: 190px;
      padding-bottom: 10px;
      font-weight: 500;
      line-height: 30px;
    }
  }
`;
const SearchInput = styled.input`
  /* padding: 15px; */
  width: 100%;
  text-align: center;
  border: 2px solid lightgray;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  margin-right: 10px;
  color: gray;
  @media (max-width: 1200px) {
    margin-right: 0px !important;
  }

  &:focus {
    box-shadow: none;
    border: none;
    transition: 0.3s;
    border: 2px solid ${colors.bbeego};
  }
  /* &:focus {
    border: none;
    outline: none;
    box-shadow: none;
    transition: 0.3s;
    border: 2px solid ${colors.bbeego}; */
  /* } */
`;
const SearchBox = ({ height, another, setValue, type }) => {
  //const isSearchBoxOpen = useReactiveVar(isSearchBoxOpenVar);
  const history = useHistory();
  const [searchBoxOpen, setSearchBoxOpen] = useState(false);
  const { register, watch, getValues } = useForm();
  const SearchRes = useRef();
  const { loading, error, data } = useQuery(SEARCH_USERS_QUERY, {
    variables: {
      keyword: watch()?.search || watch()?.traineeName,
      type: "trainer",
    },
    ...(!watch()?.search && !watch()?.traineeName && { skip: true }),
  });

  const onDropBox = (e) => {
    setSearchBoxOpen(true);
  };
  const outFocus = (e) => {
    if (
      !Array.from(e?.target?.classList).includes("Search") &&
      !e?.target?.closest("div.Search")
    ) {
      setSearchBoxOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", outFocus);
    return () => {
      window.removeEventListener("click", outFocus);
      setSearchBoxOpen(false);
    };
  }, []);
  const toMyGym = (v) => {
    setSearchBoxOpen(false);
    history.push(routes.myGym + `${v?.id}`);
  };
  const getUserId = (v) => {
    setSearchBoxOpen(false);

    setValue("traineeId", v?.id);
  };
  return (
    <Searchbox>
      <SearchCon onClick={(e) => onDropBox(e)} height={height}>
        <SearchInput
          {...register("search")}
          className="searchBar Search form-control"
          placeholder="트레이너 검색"
          autoComplete="off"
          ref={SearchRes}
        />
      </SearchCon>
      {searchBoxOpen && data?.searchUsers.length ? (
        <div>
          {/* <Triangle></Triangle> */}
          <DropBox className="Search">
            {/* {data?.searchUsers?.length ? ( */}
            {data?.searchUsers?.map((v) => (
              <div key={v?.id}>
                <UserCon key={v?.id} id={v?.username}>
                  <ProfilePhoto
                    // border="none"
                    key={v?.id}
                    src={v?.profilePhoto}
                    id={v?.id}
                    width="35px"
                    height="35px"
                    another={another}
                  />
                  <div
                    onClick={another ? () => getUserId(v) : () => toMyGym(v)}
                    className="Name_Desc"
                  >
                    <div>{v?.username}</div>
                  </div>
                </UserCon>
              </div>
            ))}
            {/* ) : (
              <NoUserCon>검색 결과가 존재하지 않습니다.</NoUserCon>
            )} */}
          </DropBox>
        </div>
      ) : null}
    </Searchbox>
  );
};

export default SearchBox;
