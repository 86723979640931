//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import colors from "../utils/colors";

const TabContentCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 30px;
`;
const TabContentHeader = styled.h3`
  font-size: 20px;
  font-weight: bold;
`;
const TabsCon = styled.div`
  .mb-3 {
    a {
      color: #333;
    }
    a.active {
      color: ${colors.bbeego};
    }
  }
`;

const Terms = () => {
  const location = useLocation();

  const [activeKey, setActiveKey] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (location?.state?.defaultActiveKey) {
      setActiveKey(location?.state?.defaultActiveKey);
    }
  }, [location]);
  return (
    <div className="container position-relative">
      <TabsCon className="row">
        <Tabs
          activeKey={activeKey}
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(e) => {
            setActiveKey(e);
          }}
        >
          <Tab eventKey="term" title="이용약관">
            <TabContentCon>
              <TabContentHeader>BBEEGO 이용약관</TabContentHeader>

              <div>
                <p>
                  제1조(목적) 이 약관은 주재넷이 운영하는 BBEEGO 사이버 몰(이하
                  “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라
                  한다)를 이용함에 있어 사이버 몰과 이용자의 권리의무 및
                  책임사항을 규정함을 목적으로 합니다.
                </p>

                <p>
                  ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에
                  반하지 않는 한 이 약관을 준용합니다」
                </p>
              </div>
              <div>
                <p>제2조(정의)</p>

                <p>
                  ① “몰”이란 회사가 재화 또는 용역(이하 “재화등”이라 함)을
                  이용자에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여
                  재화등을 거래할 수 있도록 설정한 가상의 영업장을 말하며,
                  아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
                </p>

                <p>
                  ② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는
                  서비스를 받는 회원 및 비회원을 말합니다.
                </p>

                <p>
                  ③ ‘회원’이라 함은 “몰”에 개인정보를 제공하여 회원등록을 한
                  자로서, “몰”의 정보를 지속적으로 제공받으며, “몰”이 제공하는
                  서비스를 계속적으로 이용할 수 있는 자를 말합니다.
                </p>

                <p>
                  ④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는
                  서비스를 이용하는 자를 말합니다.
                </p>
              </div>
              <div>
                <p>제3조 (약관등의 명시와 설명 및 개정) </p>

                <p>
                  ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
                  주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
                  전화번호, 모사전송번호, 전자우편주소, 사업자등록번호,
                  통신판매업신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수
                  있도록 사이버몰의 초기 서비스화면(전면)에 게시합니다. 다만,
                  약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수
                  있습니다.
                </p>

                <p>
                  ② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는
                  내용 중 청약철회,배송책임,환불조건 등과 같은 중요한 내용을
                  이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을
                  제공하여 이용자의 확인을 구하여야 합니다.
                </p>

                <p>
                  ③ “몰”은 전자상거래등에서의소비자보호에관한법률,
                  약관의규제에관한법률, 전자거래기본법, 전자서명법,
                  정보통신망이용촉진등에관한법률, 방문판매등에관한법률,
                  소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을
                  개정할 수 있습니다.
                </p>

                <p>
                  ④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를
                  명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자
                  7일이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게
                  불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전
                  유예기간을 두고 공지합니다. 이 경우 "몰“은 개정전 내용과
                  개정후 내용을 명확하게 비교하여 이용자가 알기 쉽도록
                  표시합니다.{" "}
                </p>

                <p>
                  ⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자
                  이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에
                  대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미
                  계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는
                  뜻을 제3항에 의한 개정약관의 공지기간내에 ‘몰“에 송신하여
                  ”몰“의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
                </p>

                <p>
                  ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
                  전자상거래등에서의소비자보호에관한법률,
                  약관의규제등에관한법률, 공정거래위원회가 정하는
                  전자상거래등에서의소비자보호지침 및 관계법령 또는 상관례에
                  따릅니다.
                </p>
              </div>
              <div>
                <p>제4조(서비스의 제공 및 변경) </p>

                <p>
                  ① “몰”은 다음과 같은 업무를 수행합니다.
                  <ul>
                    <li>
                      {" "}
                      1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
                    </li>

                    <li>2. 구매계약이 체결된 재화 또는 용역의 배송</li>

                    <li>3. 기타 “몰”이 정하는 업무</li>
                  </ul>
                </p>

                <p>
                  ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의
                  경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의
                  내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의
                  내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을
                  게시한 곳에 즉시 공지합니다.
                </p>

                <p>
                  ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을
                  재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할
                  경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시
                  통지합니다.
                </p>

                <p>
                  ④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를
                  배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는
                  경우에는 그러하지 아니합니다.
                </p>
              </div>
              <div>
                제5조(서비스의 중단)
                <p>
                  {" "}
                  ① “몰”은 컴퓨터 등 정보통신설비의 보수점검교체 및 고장, 통신의
                  두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로
                  중단할 수 있습니다.
                </p>
                <p>
                  ② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로
                  인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단,
                  “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
                  아니합니다.
                </p>
                <p>
                  ③ 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로
                  서비스를 제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한
                  방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라
                  소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지
                  아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서
                  통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게
                  지급합니다.
                </p>
              </div>
              <div>
                <p>제6조(회원가입) </p>

                <p>
                  ① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후
                  이 약관에 동의한다는 의사표시를 함으로서 회원가입을
                  신청합니다.
                </p>

                <p>
                  ② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중
                  다음 각호에 해당하지 않는 한 회원으로 등록합니다.
                  <ul>
                    <li>
                      {" "}
                      1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에
                      회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한
                      회원자격 상실후 3년이 경과한 자로서 “몰”의 회원재가입
                      승낙을 얻은 경우에는 예외로 한다.
                    </li>

                    <li>2. 등록 내용에 허위, 기재누락, 오기가 있는 경우</li>

                    <li>
                      3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이
                      있다고 판단되는 경우
                    </li>
                  </ul>
                </p>

                <p>
                  ③ 회원가입계약의 성립시기는 “몰”의 승낙이 회원에게 도달한
                  시점으로 합니다.
                </p>

                <p>
                  ④ 회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시
                  전자우편 기타 방법으로 “몰”에 대하여 그 변경사항을 알려야
                  합니다.
                </p>
              </div>
              <div>
                <p>제7조(회원 탈퇴 및 자격 상실 등) </p>

                <p>
                  ① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시
                  회원탈퇴를 처리합니다.
                </p>

                <p>
                  ② 회원이 다음 각호의 사유에 해당하는 경우, “몰”은 회원자격을
                  제한 및 정지시킬 수 있습니다.
                </p>

                <ul>
                  <li>1. 가입 신청시에 허위 내용을 등록한 경우</li>

                  <li>
                    2. “몰”을 이용하여 구입한 재화등의 대금, 기타 “몰”이용에
                    관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
                  </li>

                  <li>
                    3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등
                    전자상거래 질서를 위협하는 경우
                  </li>

                  <li>
                    4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에
                    반하는 행위를 하는 경우
                  </li>
                </ul>

                <p>
                  ③ “몰”이 회원 자격을 제한정지 시킨후, 동일한 행위가 2회이상
                  반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 “몰”은
                  회원자격을 상실시킬 수 있습니다.
                </p>

                <p>
                  ④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다.
                  이 경우 회원에게 이를 통지하고, 회원등록 말소전에 최소한 30일
                  이상의 기간을 정하여 소명할 기회를 부여합니다.
                </p>
              </div>
              <div>
                <p>제8조(회원에 대한 통지)</p>

                <p>
                  ① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과 미리
                  약정하여 지정한 전자우편 주소로 할 수 있습니다.
                </p>

                <p>
                  ② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “몰”
                  게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만,
                  회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
                  대하여는 개별통지를 합니다.
                </p>
              </div>

              <div>
                <p>
                  제9조(구매신청) “몰”이용자는 “몰”상에서 다음 또는 이와 유사한
                  방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매신청을 함에
                  있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. 단,
                  회원인 경우 제2호 내지 제4호의 적용을 제외할 수 있습니다.
                </p>

                <ul>
                  <li>1. 재화등의 검색 및 선택</li>

                  <li>
                    2. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호)
                    등의 입력
                  </li>

                  <li>
                    3. 약관내용, 청약철회권이 제한되는 서비스, 배송료 설치비
                    등의 비용부담과 관련한 내용에 대한 확인
                  </li>

                  <li>
                    4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는
                    표시(예, 마우스 클릭)
                  </li>

                  <li>
                    5. 재화등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에
                    대한 동의
                  </li>

                  <li>6. 결제방법의 선택</li>
                </ul>
              </div>

              <div>
                <p>제10조 (계약의 성립)</p>

                <p>
                  {" "}
                  ① “몰”은 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면
                  승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는
                  경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는
                  법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
                  <ul>
                    <li>1. 신청 내용에 허위, 기재누락, 오기가 있는 경우</li>

                    <li>
                      2. 미성년자가 담배, 주류등 청소년보호법에서 금지하는 재화
                      및 용역을 구매하는 경우
                    </li>

                    <li>
                      3. 기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히 지장이
                      있다고 판단하는 경우
                    </li>
                  </ul>
                </p>

                <p>
                  {" "}
                  ② “몰”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게
                  도달한 시점에 계약이 성립한 것으로 봅니다.
                </p>

                <p>
                  ③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및
                  판매가능 여부, 구매신청의 정정 취소등에 관한 정보등을
                  포함하여야 합니다.
                </p>
                <p>
                  ④ “몰”의 판매자와 구매자의 연결점을 제공하는 "서비스 플랫폼"
                  으로써, 서비스를 제공함에 있어 받은 수익 및 수수료에 대해서
                  반환할 의무가 없으며, 판매자와 구매자 사이의 분쟁은 제 3자인
                  “몰”의 개입 없이 당사자간의 합의가 이루어져야 합니다.
                </p>
              </div>
              <div>
                <p>
                  제11조(지급방법) “몰”에서 구매한 재화 또는 용역에 대한
                  대금지급방법은 다음 각호의 방법중 가용한 방법으로 할 수
                  있습니다. 단, “몰”은 이용자의 지급방법에 대하여 재화 등의
                  대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
                </p>

                <ul>
                  <li>1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체 </li>

                  <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>

                  <li>3. 온라인무통장입금</li>

                  <li>4. 전자화폐에 의한 결제</li>

                  <li>5. 수령시 대금지급</li>

                  <li>6. 마일리지 등 “몰”이 지급한 포인트에 의한 결제</li>

                  <li>
                    7. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제{" "}
                  </li>

                  <li>8. 기타 전자적 지급 방법에 의한 대금 지급 등</li>
                </ul>
              </div>

              <div>
                <p>제12조(수신확인통지 구매신청 변경 및 취소)</p>

                <p>
                  ① “몰”은 이용자의 구매신청이 있는 경우 이용자에게
                  수신확인통지를 합니다.
                </p>

                <p>
                  ② 수신확인통지를 받은 이용자는 의사표시의 불일치등이 있는
                  경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를
                  요청할 수 있고 “몰”은 배송전에 이용자의 요청이 있는 경우에는
                  지체없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을
                  지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.
                </p>
              </div>

              <div>
                <p>제13조(재화등의 공급)</p>

                <p>
                  {" "}
                  ① “몰”은 이용자와 재화등의 공급시기에 관하여 별도의 약정이
                  없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을
                  배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를
                  취합니다. 다만, “몰”이 이미 재화 등의 대금의 전부 또는 일부를
                  받은 경우에는 대금의 전부 또는 일부를 받은 날부터 2영업일
                  이내에 조치를 취합니다. 이때 “몰”은 이용자가 재화등의 공급
                  절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
                </p>

                <p>
                  ② “몰”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용
                  부담자, 수단별 배송기간 등을 명시합니다. 만약 “몰”이 약정
                  배송기간을 초과한 경우에는 그로 인한 이용자의 손해를
                  배상하여야 합니다. 다만 “몰”이 고의,과실이 없음을 입증한
                  경우에는 그러하지 아니합니다.
                </p>
              </div>
              <div>
                <p>
                  제14조(환급) “몰”은 이용자가 구매신청한 재화등이 품절 등의
                  사유로 인도 또는 제공을 할 수 없을 때에는 지체없이 그 사유를
                  이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는
                  대금을 받은 날부터 2영업일 이내에 환급하거나 환급에 필요한
                  조치를 취합니다.
                </p>
              </div>

              <div>
                <p>제15조(청약철회 등)</p>

                <p>
                  ① “몰”과 재화등의 구매에 관한 계약을 체결한 이용자는
                  수신확인의 통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수
                  있습니다.
                </p>

                <p>
                  ② 이용자는 재화등을 배송받은 경우 다음 각호의 1에 해당하는
                  경우에는 반품 및 교환을 할 수 없습니다.
                  <ul>
                    <li>
                      1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된
                      경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을
                      훼손한 경우에는 청약철회를 할 수 있습니다)
                    </li>

                    <li>
                      2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가
                      현저히 감소한 경우
                    </li>

                    <li>
                      3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의
                      가치가 현저히 감소한 경우
                    </li>

                    <li>
                      4. 같은 성능을 지닌 재화등으로 복제가 가능한 경우 그
                      원본인 재화 등의 포장을 훼손한 경우
                    </li>
                  </ul>
                </p>

                <p>
                  ③ 제2항제2호 내지 제4호의 경우에 “몰”이 사전에 청약철회 등이
                  제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나
                  시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의
                  청약철회등이 제한되지 않습니다.
                </p>

                <p>
                  ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화등의 내용이
                  표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해
                  재화등을 공급받은 날부터 3월이내, 그 사실을 안 날 또는 알 수
                  있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
                </p>
              </div>
              <div>
                <p>제16조(청약철회 등의 효과)</p>

                <p>
                  ① “몰”은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에
                  이미 지급받은 재화등의 대금을 환급합니다. 이 경우 “몰”이
                  이용자에게 재화등의 환급을 지연한 때에는 그 지연기간에 대하여
                  공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한
                  지연이자를 지급합니다.
                </p>

                <p>
                  ② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는
                  전자화폐 등의 결제수단으로 재화등의 대금을 지급한 때에는
                  지체없이 당해 결제수단을 제공한 사업자로 하여금 재화등의
                  대금의 청구를 정지 또는 취소하도록 요청합니다.
                </p>

                <p>
                  ③ 청약철회등의 경우 공급받은 재화등의 반환에 필요한 비용은
                  이용자가 부담합니다. “몰”은 이용자에게 청약철회등을 이유로
                  위약금 또는 손해배상을 청구하지 않습니다. 다만 재화등의 내용이
                  표시·광고 내용과 다르거나 계약내용과 다르게 이행되어
                  청약철회등을 하는 경우 재화등의 반환에 필요한 비용은 “몰”이
                  부담합니다.
                </p>

                <p>
                  ④ 이용자가 재화등을 제공받을때 발송비를 부담한 경우에 “몰”은
                  청약철회시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록
                  명확하게 표시합니다.
                </p>
              </div>
              <div>
                <p>제17조(개인정보보호)</p>
                <p>
                  ① “몰”은 이용자의 정보수집시 구매계약 이행에 필요한 최소한의
                  정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은
                  선택사항으로 합니다.{" "}
                </p>
                <ul>
                  <li>1. 성명</li>

                  <li>2. 생년월일</li>

                  <li>3. 주소</li>

                  <li>4. 전화번호</li>

                  <li>5. 희망ID(회원의 경우)</li>

                  <li>6. 비밀번호(회원의 경우)</li>

                  <li>7. 전자우편주소(또는 이동전화번호)</li>
                </ul>
                <p>
                  ② “몰”이 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는
                  반드시 당해 이용자의 동의를 받습니다.
                </p>
                <p>
                  ③ 제공된 개인정보는 당해 이용자의 동의없이 목적외의 이용이나
                  제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 몰이 집니다.
                  다만, 다음의 경우에는 예외로 합니다.
                </p>
                <ul>
                  <li>
                    1. 배송업무상 배송업체에게 배송에 필요한 최소한의 이용자의
                    정보(성명, 주소, 전화번호)를 알려주는 경우
                  </li>

                  <li>
                    2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서
                    특정 개인을 식별할 수 없는 형태로 제공하는 경우
                  </li>

                  <li>3. 재화등의 거래에 따른 대금정산을 위하여 필요한 경우</li>

                  <li>4. 도용방지를 위하여 본인확인에 필요한 경우</li>

                  <li>
                    5. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가
                    있는 경우
                  </li>
                </ul>
                <p>
                  ④ “몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는
                  경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호,
                  기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한
                  정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용)
                  등 정보통신망이용촉진등에관한법률 제22조제2항이 규정한 사항을
                  미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를
                  철회할 수 있습니다.
                </p>
                <p>
                  ⑤ 이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에 대해
                  열람 및 오류정정을 요구할 수 있으며 “몰”은 이에 대해 지체없이
                  필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을
                  요구한 경우에는 “몰”은 그 오류를 정정할 때까지 당해 개인정보를
                  이용하지 않습니다.
                </p>
                <p>
                  ⑥ “몰”은 개인정보 보호를 위하여 관리자를 한정하여 그 수를
                  최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의
                  분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든
                  책임을 집니다.
                </p>
                <p>
                  ⑦ “몰” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의
                  수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를
                  지체없이 파기합니다.
                </p>
                ⑧ “몰”은 3D 렌더링을 위해 제공받은 개인정보를 통해 생성하는
                창작물은 이용자의 동의하에 진행합니다. “몰”은 해당 창작물의
                소유권을 갖고 있으며, 제공받은 개인정보를 동의없이
                무단배포하거나 사용하지 않습니다.
                <p></p>
              </div>
              <div>
                <p>제18조(“몰“의 의무)</p>

                <p>
                  ① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를
                  하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
                  재화, 용역을 제공하는데 최선을 다하여야 합니다.
                </p>
                <p>② “몰”은 이용자의 문의에 성실히 답변해야 합니다</p>
                <p>
                  ③ “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록
                  이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을
                  갖추어야 합니다.
                </p>

                <p>
                  ④ “몰”이 상품이나 용역에 대하여
                  「표시,광고의공정화에관한법률」 제3조 소정의 부당한
                  표시,광고행위를 함으로써 이용자가 손해를 입은 때에는 이를
                  배상할 책임을 집니다.
                </p>

                <p>
                  ⑤ “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을
                  발송하지 않습니다.
                </p>
                <p>
                  ⑥ 판매 등록을 위해 제공한 신원정보의 명의자와 실제 서비스를
                  제공하는 “몰”은 동일해야 합니다. 하도급 행위 등 본 조항에 따른
                  권리∙의무를 회사의 사전 서면 동의 없이 타인에게 양도,
                  증여하거나 담보로 제공하는 행위가 적발될 경우 회사는 내부
                  규정에 따라 조치할 수 있으며, 이로 인한 모든 책임은 “몰”에게
                  귀속됩니다.
                </p>
                <p>
                  ⑦ “몰”은 거래가 무사히 완료 되었다는 점을 증명할 책임이 있고,
                  회사가 대금 정산 등 목적으로 “몰”에게 거래 완료 여부에 대한
                  증빙을 요청할 수 있으며 “몰”은 회사의 위와 같은 요청에
                  성실하게 응하여야 합니다. “몰”이 위 요청에 성실하게 응하지
                  아니하여 발생한 회사의 손해 및 제반 문제에 대한 일체의 책임은
                  “몰”이 부담합니다.
                </p>
                <p>
                  ⑧ 회사는 “몰”이 본 약관 및 운영정책에서 제한 또는 금지하는
                  행위를 한 경우 해당 “몰”이 회사로부터 부수적으로 제공 받은
                  포인트 등 혜택의 전부 또는 일부를 회수하거나 계정 접속을 제한
                  또는 회원 자격을 박탈할 수 있고, 해당 “몰”의 게시글을 임시
                  삭제 또는 영구 삭제 할 수 있습니다. 이에 따라 회원 자격을 박탈
                  당한 자의 재가입은 제한될 수 있습니다
                </p>
              </div>

              <div>
                <p>제19조(회원의 ID 및 비밀번호에 대한 의무)</p>

                <p>
                  ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은
                  회원에게 있습니다.
                </p>

                <p>
                  ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
                  안됩니다.
                </p>

                <p>
                  ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
                  있음을 인지한 경우에는 바로 “몰”에 통보하고 “몰”의 안내가 있는
                  경우에는 그에 따라야 합니다.
                </p>
              </div>

              <div>
                <p>
                  제20조(이용자의 의무) 이용자는 다음 행위를 하여서는 안됩니다.
                </p>

                <ul>
                  <li>① 신청 또는 변경시 허위 내용의 등록</li>

                  <li>② 타인의 정보 도용</li>

                  <li>③ “몰”에 게시된 정보의 변경</li>

                  <li>
                    ④ “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
                    또는 게시
                  </li>

                  <li>⑤ “몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>

                  <li>
                    ⑥ “몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                  </li>

                  <li>
                    ⑦ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
                    반하는 정보를 몰에 공개 또는 게시하는 행위
                  </li>

                  <li>
                    ⑧ 회사는 “이용자”가 본 약관 및 운영정책에서 제한 또는
                    금지하는 행위를 한 경우 해당 “이용자”가 회사로부터
                    부수적으로 제공 받은 포인트 등 혜택의 전부 또는 일부를
                    회수하거나 계정 접속을 제한 또는 회원 자격을 박탈할 수 있고,
                    해당 “이용자”의 게시글을 임시 삭제 또는 영구 삭제 할 수
                    있습니다. 이에 따라 회원 자격을 박탈 당한 자의 재가입은
                    제한될 수 있습니다
                  </li>
                </ul>
              </div>
              <div>
                <p>제21조(“몰”과 “이용자” 간의 환불 규정 및 책임 소재)</p>
                <p>
                  ① 통신판매중개자로서 회원들에게 온라인 거래장소와 부가 정보를
                  제공하며, 서비스를 통해 이루어지는 회원 상호 간의 거래에
                  대해서는 당사자 간의 합의가 원칙입니다. 다만, 거래 당사자 간의
                  합의가 원만하게 이루어지지 않을 경우 당사자 중 한 명 이상에게
                  신청을 받아 원만한 문제 해결을 위해 '분쟁 조정' 절차를
                  진행하고 있습니다.
                </p>
                <p>
                  ② 분쟁이 발생한 경우, '진행·취소 및 환불' 등에 대해 당사자 간
                  1차 합의를 진행합니다.
                </p>
                <p>
                  ③ 합의가 원만하게 이루어지지 않을 경우, 아래 내용을 준비하여
                  고객센터를 통해 접수합니다. - 회원 정보 - 분쟁 상황 및 당사자
                  간 1차 합의 진행 상황에 대한 설명 - 거래와 관련한 대화 내역,
                  트레이너의 태도 등의 증빙자료
                </p>
                <p>
                  ③ 영업일 기준 6일 이내 담당자가 회신을 드리며, 안내에 따라
                  조정 절차에 적극 협조해주세요.{" "}
                </p>
                <p>
                  ④ BBEEGO의 조정으로도 합의되지 않는 거래 건은 안내에 따라
                  '외부 기관'으로 조정을 신청합니다.
                </p>
                <p>
                  ⑤ 조정 절차가 완료되면, 결과에 따라 비용 및 기타 의무사항을
                  이행합니다.
                </p>
              </div>
              <div>
                <p>제22조(연결“몰”과 피연결“몰” 간의 관계)</p>

                <p>
                  ① 상위 “몰”과 하위 “몰”이 하이퍼 링크(예: 하이퍼 링크의
                  대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된
                  경우, 전자를 연결 “몰”(웹 사이트)이라고 하고 후자를 피연결
                  “몰”(웹사이트)이라고 합니다.
                </p>

                <p>
                  ② 연결“몰”은 피연결“몰”이 독자적으로 제공하는 재화등에 의하여
                  이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을
                  연결“몰”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한
                  경우에는 그 거래에 대한 보증책임을 지지 않습니다.
                </p>
              </div>
              <div>
                <p>제23조(저작권의 귀속 및 이용제한)</p>

                <p>
                  ① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에
                  귀속합니다.
                </p>

                <p>
                  ② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게
                  지적재산권이 귀속된 정보를 “몰”의 사전 승낙없이 복제, 송신,
                  출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나
                  제3자에게 이용하게 하여서는 안됩니다.
                </p>

                <p>
                  ③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우
                  당해 이용자에게 통보하여야 합니다.
                </p>
              </div>

              <div>
                <p>제24조(분쟁해결)</p>

                <p>
                  ① “몰”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그
                  피해를 보상처리하기 위하여 피해보상처리기구를 설치,운영합니다.
                </p>

                <p>
                  ② “몰”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로
                  그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
                  이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
                </p>

                <p>
                  ③ “몰”과 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의
                  피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가
                  의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
                </p>
              </div>

              <div>
                <p>제25조(재판권 및 준거법)</p>

                <p>
                  ① “몰”과 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소
                  당시의 회사의 주소에 의하여 관할하는 지방법원의 전속관할로
                  합니다.
                </p>

                <p>
                  ② “몰”과 이용자간에 제기된 전자상거래 소송에는 한국법을
                  적용합니다.
                </p>
              </div>

              <div>
                <p>부칙</p>

                <p>[시행일] 본 이용약관은 2022년 3월 1일부터 적용됩니다. </p>

                <p>공고일자 : 2022년 3월 1일</p>

                <p>시행일자 : 2022년 3월 1일</p>
              </div>
            </TabContentCon>
          </Tab>
          <Tab eventKey="privacyPolicy" title="개인정보 보호정책">
            <TabContentCon>
              <TabContentHeader>BBEEGO 개인정보 보호정책</TabContentHeader>
              <div>
                <p>
                  '빼고'는 (이하 '회사'는)고객님의 개인정보를 중요시하며,
                  "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고
                  있습니다.
                </p>

                <p>
                  회사는 개인정보취급방침을 통하여 고객님께서 제공하시는
                  개인정보가 어떠한 용도와 방식으로 이용되고 있으며,
                  개인정보보호를 위해 어떠한 조치가 취해지고 있는지
                  알려드립니다.
                </p>

                <p>
                  회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는
                  개별공지)을 통하여 공지할 것입니다.
                </p>
              </div>

              <div>
                <p>ο 본 방침은 : 2022 년 3 월 1 일 부터 시행됩니다.</p>
              </div>

              <div>
                <p>■ 수집하는 개인정보 항목</p>

                <p>
                  회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은
                  개인정보를 수집하고 있습니다.
                </p>

                <ul>
                  <li>
                    ο 수집항목 : 이름 , 로그인ID , 비밀번호 , 비밀번호 질문과
                    답변 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 ,
                    서비스 이용기록 , 접속 로그 , 쿠키 , 접속 IP 정보 , 결제기록
                  </li>

                  <li>ο 개인정보 수집방법 : 홈페이지(www.bbeego.com) </li>
                </ul>
              </div>
              <div>
                <p>■ 개인정보의 수집 및 이용목적</p>

                <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다..</p>

                <ul>
                  <li>
                    ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른
                    요금정산 콘텐츠 제공
                  </li>

                  <li>
                    ο 회원 관리 개인 식별 , 불량회원의 부정 이용 방지와 비인가
                    사용 방지 , 가입 의사 확인 , 고지사항 전달
                  </li>

                  <li>
                    ο 마케팅 및 광고에 활용 신규 서비스(제품) 개발 및 특화 ,
                    이벤트 등 광고성 정보 전달 , 접속 빈도 파악 또는 회원의
                    서비스 이용에 대한 통계
                  </li>
                </ul>
              </div>
              <div>
                <p>■ 개인정보의 보유 및 이용기간</p>

                <p>
                  원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당
                  정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의
                  이유로 명시한 기간 동안 보존합니다.
                </p>

                <ul>
                  <li>
                    보존 항목 : 이름 , 로그인ID , 비밀번호 , 비밀번호 질문과
                    답변 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 ,
                    서비스 이용기록 , 접속 로그 , 접속 IP 정보 , 결제기록
                  </li>

                  <li>보존 근거 : 구매 및 거래 내역에 대한 기록보전</li>

                  <li>보존 기간 : 5년</li>
                </ul>
              </div>

              <div>
                <p>■ 개인정보의 파기절차 및 방법</p>

                <p>
                  회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는
                  해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과
                  같습니다.
                </p>

                <p>
                  <p>ο 파기절차</p>

                  <ul>
                    <li>
                      회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된
                      후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침
                      및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및
                      이용기간 참조) 일정 기간 저장된 후 파기되어집니다.
                    </li>

                    <li>
                      별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는
                      보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
                    </li>
                  </ul>
                </p>

                <p>
                  <p>ο 파기방법 </p>

                  <ul>
                    <li>
                      - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
                      없는 기술적 방법을 사용하여 삭제합니다.{" "}
                    </li>
                  </ul>
                </p>
              </div>
              <div>
                <p>■ 개인정보 제공</p>

                <p>
                  <p>
                    회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지
                    않습니다. 다만, 아래의 경우에는 예외로 합니다.
                  </p>

                  <ul>
                    <li>- 이용자들이 사전에 동의한 경우</li>

                    <li>
                      - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진
                      절차와 방법에 따라 수사기관의 요구가 있는 경우
                    </li>
                  </ul>
                </p>
              </div>
              <div>
                <p>■ 수집한 개인정보의 위탁</p>

                <p>
                  회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지
                  않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁
                  업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를
                  받도록 하겠습니다.
                </p>
              </div>
              <div>
                <p>■ 이용자 및 법정대리인의 권리와 그 행사방법</p>

                <ul>
                  <li>
                    이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은
                    당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수
                    있으며 가입해지를 요청할 수도 있습니다.
                  </li>

                  <li>
                    이용자 혹은 만 14세 미만 아동의 개인정보 조회,수정을
                    위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을
                    가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭하여 본인
                    확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가
                    가능합니다.
                  </li>

                  <li>
                    혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로
                    연락하시면 지체없이 조치하겠습니다.
                  </li>

                  <li>
                    귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는
                    정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
                    않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한
                    경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이
                    이루어지도록 하겠습니다.
                  </li>

                  <li>
                    주재넷은 이용자 혹은 법정 대리인의 요청에 의해 해지 또는
                    삭제된 개인정보는 “주재넷이 수집하는 개인정보의 보유 및
                    이용기간”에 명시된 바에 따라 처리하고 그 외의용도로 열람
                    또는 이용할 수 없도록 처리하고 있습니다.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  ■ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
                </p>

                <p>
                  회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’
                  등을 운용합니다. 쿠키란 주재넷의 웹사이트를 운영하는데
                  이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트
                  파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사은(는)
                  다음과 같은 목적을 위해 쿠키를 사용합니다.
                </p>
              </div>
              <div>
                <p>▶ 쿠키 등 사용 목적</p>
                <ul>
                  <li>
                    - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의
                    취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도
                    및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스
                    제공
                  </li>
                </ul>

                <p>
                  귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
                  귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
                  허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
                  쿠키의 저장을 거부할 수도 있습니다.
                </p>
              </div>
              <div>
                <p>▶ 쿠키 설정 거부 방법</p>

                <p>
                  예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹
                  브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를
                  저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수
                  있습니다.
                </p>

                <p>설정방법 예(인터넷 익스플로어의 경우)</p>

                <p>: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보</p>

                <p>
                  단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에
                  어려움이 있을 수 있습니다.
                </p>
              </div>

              <div>
                <p>■ 개인정보에 관한 민원서비스</p>

                <p>
                  회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을
                  처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를
                  지정하고 있습니다..
                </p>

                <p>
                  <p>고객서비스담당 부서 : 빼고사업본부</p>

                  <p>전화번호 : 02-2043-9112</p>

                  <p>이메일 : service@bbeego.com</p>

                  <p>개인정보관리책임자 성명 : 박종호</p>

                  <p>전화번호 : 02-2043-9112</p>

                  <p>이메일 : contact@bbeego.com</p>
                </p>

                <p>
                  귀하께서는 회사의 서비스를 이용하시며 발생하는 모든
                  개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로
                  신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해
                  신속하게 충분한 답변을 드릴 것입니다.
                </p>

                <p>
                  기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
                  기관에 문의하시기 바랍니다.
                </p>

                <ul>
                  <li>1.개인분쟁조정위원회 (www.1336.or.kr/1336)</li>

                  <li>
                    2.정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)
                  </li>

                  <li>
                    3.대검찰청 인터넷범죄수사센터
                    (http://icic.sppo.go.kr/02-3480-3600)
                  </li>

                  <li>
                    4.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)
                  </li>
                </ul>

                <p>개인정보 처리방침에 대한 내용을 입력하십시오.</p>
              </div>
            </TabContentCon>
          </Tab>
          <Tab eventKey="refusal" title="이메일 수집거부">
            <TabContentCon>
              <TabContentHeader>이메일 수집거부</TabContentHeader>
              <div>
                <p>
                  본 웹사이트에 게재된 이메일 주소가 전자우편 수집 프로그램이나
                  그밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며,
                </p>
                <p>
                  이를 위반시 정보통신망법에 의해 형사처벌 됨을 유념하시기
                  바랍니다.
                </p>

                <p>[게시일 2022. 3. 1]-</p>
              </div>
            </TabContentCon>
          </Tab>
        </Tabs>
      </TabsCon>
    </div>
  );
};

export default Terms;
