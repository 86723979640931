//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { GIVENCOUPON_FRAGMENT } from "../queries/fragments/givenCouponFragment";
import { userLogOut } from "./reactiveVar";

export const ME = gql`
  query Me {
    me {
      id
      email
      username
      profilePhoto
      type
      trainerAuthUnlockAt
      givenCoupons {
        ...GivenCouponFragment
      }
      socialLoginType
      token
      refreshtoken
      blockList
    }
  }
  ${GIVENCOUPON_FRAGMENT}
`;

const CheckUserToken = (noLogOut) => {
  const { data, loading, refetch } = useQuery(ME, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loading) {
      if (data?.me === null) {
        //alert("it's null!");
        if (noLogOut) {
        } else {
          userLogOut();
        }
      } else {
        localStorage.setItem("token", data?.me?.token);
        //localStorage.setItem("refreshtoken", data?.me?.refreshtoken);
        localStorage.setItem("user_backup", JSON.stringify(data));
      }
    }
  }, [data]);
  if (!data && localStorage.getItem("user_backup") !== "undefined") {
    return JSON.parse(localStorage.getItem("user_backup"));
  }
  return data;
};

export default CheckUserToken;
