//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import {
  faAlignLeft,
  faCommentDots,
  faTextWidth,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import PostHeader from "../Accounts/PostHeader";
import CarouselLayout from "../component/carousel/carouselLayout";
import { FatText } from "../component/shared/FatText";
import { SeePost } from "../queries/post/seePost";

const PostDetailContainer = styled.div`
  width: 1000px;
  max-height: 575px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;
const PostDetailCon = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;
const PostHeaderCon = styled.div``;
const Title = styled.div`
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
`;
const PostBody = styled.div``;
const DescriptionCon = styled.div`
  padding: 10px;
`;
const Description = styled.span`
  margin-left: 10px;
`;
const CommentContainer = styled.div`
  width: 30%;
  height: inherit;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr 10fr 1fr;
`;
const UserName = styled.span``;
const Comment = styled.span;
const CommentCon = styled.div``;
const CommentHeader = styled.div`
  border-bottom: 0.1px solid lightgrey;
`;
const CommentInsertCon = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  border-top: 1px solid lightgray;
`;
const Submit = styled.button`
  width: 25%;
  background-color: white;
  color: skyblue;
  border: none;
`;
const InsertComment = styled.textarea`
  border: none;
  width: 75%;
  resize: none;
  padding: 5px;
`;
const CommentBody = styled.div`
  width: 100%;
  height: 450px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
const Tab = styled.div``;
const TabHeaderCon = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
`;
const TabHeader = styled.div`
  border-bottom: 2px solid white;
  cursor: pointer;
`;

const PostDetail = () => {
  const [tab, setTab] = useState(1);
  const {
    state: { id },
  } = useLocation();
  const Post = SeePost(id);
  const onSelected = (tabNo) => {
    setTab(tabNo);
    const selTab = document.getElementById(`tab_${tabNo}`);
    const tabCon = selTab.parentElement.getElementsByTagName("div");

    for (let i = 0; i < tabCon.length; i++) {
      tabCon[i].style.borderBottom = "2px solid white";
      tabCon[i].style.color = "black";
    }
    selTab.style.borderBottom = "2px solid orange";
    selTab.style.color = "orange";
  };
  useEffect(() => {}, [tab]);
  return (
    <PostDetailContainer>
      <PostDetailCon>
        <PostHeaderCon>
          {/* <PostHeader user={Post?.seePost?.user}/> */}
          <Title>
            <FatText fontSize="20px">제목 : {Post?.seePost?.title}</FatText>
          </Title>
        </PostHeaderCon>
        <PostBody>
          <CarouselLayout
            id={Post?.seePost?.id}
            key={Post?.seePost?.id}
            assets={Post?.seePost?.assets}
            width="100%"
          />
        </PostBody>
      </PostDetailCon>
      <CommentContainer>
        <CommentHeader>
          <PostHeader user={Post?.seePost?.user} />
        </CommentHeader>
        <CommentBody>
          <TabHeaderCon>
            <TabHeader
              id="tab_1"
              onClick={(e) => onSelected(1)}
              style={{ borderBottom: "2px solid orange", color: "orange" }}
            >
              <FontAwesomeIcon icon={faAlignLeft} size="lg" />
            </TabHeader>
            <TabHeader id="tab_2" onClick={(e) => onSelected(2)}>
              <FontAwesomeIcon icon={faCommentDots} size="lg" />
            </TabHeader>
          </TabHeaderCon>
          <Tab>
            {tab === 1 ? (
              <DescriptionCon>
                <Description>{Post?.seePost?.description}</Description>
              </DescriptionCon>
            ) : null}
            {tab === 2 ? (
              <>
                {Post?.seePost?.comments
                  ? Post?.seePost?.comments?.map((comment, idx) => {
                      return (
                        <CommentCon key={idx}>
                          <UserName
                            key={comment?.id + "_" + idx + "user"}
                            id={comment?.user?.username}
                          >
                            <FatText fontSize="15px">
                              {comment?.user?.username}
                            </FatText>
                          </UserName>
                          <Comment key={idx + "comment"}>
                            {comment?.text}
                          </Comment>
                        </CommentCon>
                      );
                    })
                  : null}
              </>
            ) : null}
          </Tab>
        </CommentBody>
        <CommentInsertCon>
          <InsertComment placeholder="댓글 입력.." aria-setsize={false} />
          <Submit type="submit">등록</Submit>
        </CommentInsertCon>
      </CommentContainer>
    </PostDetailContainer>
  );
};

export default PostDetail;
