//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import React, { useEffect, useState } from "react";
import { Card, CardColumns, Container, Image } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import RateStars from "../component/review/RateStars";
import { GButton } from "../component/shared/GButton";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import { TypeBadge } from "../component/shared/TypeBadge";
import SeePTRoom from "../queries/PTRoom/seePTRoom";
import { ShowPTRooms } from "../queries/PTRoom/showPTRooms";
import CheckUserToken from "../utils/checkUserToken";
import { routes } from "./routes";

const SaleContainer = styled(Container)`
  max-width: 1140px;
`;
const Line = styled(CardColumns)`
  height: 2px;
  background-color: #222;
  margin: 0 auto;
  margin-bottom: 15px;
`;
const Con = styled(Container)`
  display: flex;
  flex-direction: row;
  min-height: 550px;
  margin: 0 auto;
  @media (max-width: 1209px) {
    flex-direction: column;
  }
`;
const PTCon = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  padding: 30px 0 0 0;
  text-align: center;
`;
const ThumbCon = styled(CardColumns)`
  display: flex;
  align-content: center;
  text-align: center;
  border: none;
  margin-right: 50px;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 1209px) {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }
`;
const PTitle = styled(CardColumns)`
  margin: 0 auto;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #ed5f00;
`;
const UserName = styled.h2`
  padding: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;
const PTTitle = styled(CardColumns)`
  margin-bottom: 12px;
  padding: 10px 0;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: -0.25px;
  line-height: 38px;
  color: #16181a;
`;
const Thumbnail = styled(Image)`
  position: relative;
  height: 550px;
  width: 550px;
  border: none;
  margin: 0 auto;
  background-image: url(${(props) => (props.bgImg ? props.bgImg : "")});
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 1209px) {
    height: auto;
    width: 100%;
  }
`;
const DesCon = styled(CardColumns)`
  display: flex;
  min-height: 700px;
  .ql-container {
    min-height: 650px;
  }
`;
const Description = styled(CardColumns)`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  img {
    max-width: 100%;
  }
`;
const PriceContainer = styled(CardColumns)`
  display: flex;
  font-size: 22px;
  margin-bottom: 10px;
`;
const Month = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 130px;
  text-align: start;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.12px;
  color: #16181a;
  .data {
    text-align: right;
    font-weight: 700;
  }
`;
const Price = styled.div`
  min-width: 170px;
  text-align: start;

  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.12px;
  color: #16181a;
  .data {
    text-align: right;
    font-weight: 700;
  }
`;

const TogglerCon = styled.div`
  width: 100%;
`;
const Toggler = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  line-height: 20px;
  height: 30px;
  cursor: pointer;
  .btncon {
    display: flex;
    vertical-align: middle;
    width: 100%;
  }
  @media (max-width: 449px) {
    height: unset;
    .btncon {
      display: flex;
      vertical-align: middle;
      width: 100%;
    }
  }
`;
const PriceCon = styled(CardColumns)`
  text-align: left;
  border: none;
  margin: 15px 0;
`;

export const PtIcon = styled.span`
  width: 75px;
  color: #1bb0cf !important;
  font-size: 14px;
  border: 1px solid #1bb0cf !important;
  padding: 0.25rem 0.325rem;
`;

const ProctInfo = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 5fr;
  width: 100%;
  border: none;
  gap: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 10px auto 0;
  padding: 20px 5px;
  text-align: left;
  .infotitle {
    display: flex;
    height: 35px;
    align-items: center;
    align-self: center;
    font-size: 20px;
    letter-spacing: -0.12px;
    color: #16181a;
  }
  .infodes {
    display: flex;
    height: 35px;
    font-size: 19px;
    align-items: center;
    font-weight: 700;
    align-self: center;
    letter-spacing: -0.12px;
  }
  .tab {
    padding-left: 3px;
  }
  @media (max-width: 449px) {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
  }
`;
const DealInfo = styled.div`
  width: 153px;
  padding: 8px 0;
  border: 1px solid #e3e5e8;
  .options {
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.12px;
    color: #16181a;
    text-align: center;
    width: 75px;
  }
  .start {
    border-right: 1px solid #ccc;
  }
  .end {
    border: none;
  }
`;
const InfoCon = styled(CardColumns)`
  display: flex;
  flex-direction: row;
  gap: 15px;
  @media (max-width: 449px) {
    flex-direction: column;
  }
`;

function Sale() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { state } = useLocation();

  const ptData = SeePTRoom(state?.id)?.data?.seePTRoom;

  window.state = state;
  const user = CheckUserToken();

  const ChatRoomId = ptData?.pTPrices?.reduce((acc, p) => {
    for (let i = 0; i < p?.pTPrices?.length; i++) {
      for (let j = 0; j < p?.pTPrices[i]?.contracts?.length; j++) {
        for (let k = 0; k < p?.pTPrices[i]?.contracts[j]?.user?.length; k++) {
          if (p?.pTPrices[i]?.contracts[j]?.user[k]?.id === user?.me?.id) {
            acc = p?.pTPrices[i]?.contracts[j]?.chatRoom?.id;
          }
        }
      }
    }
    return acc;
  })?.contracts[0]?.chatRoom?.id;

  const users = [];
  const history = useHistory();
  const [dis, setDis] = useState();
  const register = (price) => {
    if (user?.me?.type === "user") {
      history.push(routes.saleInfo, {
        title: ptData.title,
        description: ptData.description,
        month: price.month,
        price: price.price,
        trainer: ptData.trainer,
        pTPriceId: price.id,
      });
    } else {
      alert("트레이너는 등록이 불가능합니다.");
    }
  };
  //const [conId, setConId] = useState();
  //const [accepted, setaccepted] = useState(false);
  const data = ptData?.pTPrices?.filter((price) => price?.contracts.length);
  useEffect(() => {
    let isExpired = [];
    let isNotExpired = [];
    data?.filter((v) =>
      v.contracts.filter((a) => {
        return a?.users?.filter((u) => {
          return u.id === user?.me?.id && a?.isExpired === false
            ? isNotExpired.push(true)
            : //? setConId(a.id) + setDis(true) + setaccepted(a.accepted)
            u.id === user?.me?.id && a?.isExpired === true
            ? isExpired.push(true)
            : //? setConId(a.id) + setDis(false) + setaccepted(a.accepted)
              null;
        });
      })
    );
    if (isNotExpired?.length > 0) {
      setDis(true);
    } else if (isExpired?.length > 0) {
      setDis(false);
    } else {
      setDis(false);
    }
  }, [data]);

  ptData?.pTPrices.forEach((v) => {
    let userObj = {};
    const priceId = v?.id;
    userObj.id = priceId;
    let customers = [];
    v?.contracts?.forEach((v2) => {
      if (v2?.customer) {
        customers.push(v2?.customer);
      }
    });
    userObj.customers = customers;
    users.push(userObj);
    window.users = users;
  });

  useEffect(() => {
    let Description = document.getElementById("ccc");
    const des = ptData?.description;
    if (!!des) {
      Description.innerHTML = des;
    }
  }, [ptData]);

  return (
    <SaleContainer>
      <Con>
        <ThumbCon>
          <Thumbnail src={ptData?.thumbnail}></Thumbnail>
        </ThumbCon>

        <PTCon>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <TypeBadge variant="ptroom" context="PT Room" />{" "}
            {ptData?.isMine ? (
              <GButton
                onClick={() =>
                  history.push(routes.updatePTRoom, { ptroom: ptData })
                }
                variant="update"
                context="수정하기"
              />
            ) : null}
            {/* {dis ? (
              <GButton
                onClick={() => history.push(routes.message)}
                variant="create"
                context="Pt 시작 >"
              />
            ) : null} */}
          </div>

          <PTTitle>{ptData?.title}</PTTitle>
          <ProfilePhoto
            width="150px"
            height="150px"
            margin="0 auto"
            src={ptData?.trainer?.profilePhoto}
            id={ptData?.trainer?.id}
          />
          <UserName>{ptData?.trainer?.username}</UserName>
          <ProctInfo>
            <div className="infotitle">등록일자</div>
            <div className="infodes tab">
              {new Date(Number(ptData?.createdAt)).toJSON()?.split("T")[0]}
            </div>
            <div className="infotitle">리뷰</div>
            <div className="infodes" style={{ alignItems: "start" }}>
              {" "}
              <CardColumns
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  gap: "5px",
                }}
              >
                <CardColumns>
                  <RateStars
                    width="25px"
                    height="25px "
                    avgRate={`${ptData?.avgRate * 20}%`}
                  ></RateStars>
                </CardColumns>
                <CardColumns
                  style={{
                    paddingBottom: "3px",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  {ptData?.avgRate
                    ? Math.round(Number(ptData?.avgRate) * 10) / 10
                    : 0}
                </CardColumns>
                {/* {ptData?.pTPrices?.reduce((acc, p) => {
                  if (
                    !!p?.contracts?.filter(
                      (c) =>
                        c?.users?.map((u) => u.id).includes(user?.me?.id) &&
                        !!c?.startDate &&
                        !!c?.endDate
                    )?.length
                  ) {
                    acc = acc + 1;
                  }
                  return acc;
                }, 0) > 0 ? ( */}
              </CardColumns>
            </div>
            <div className="infotitle">기타</div>
            <DealInfo className="infodes">
              <div
                className="start options"
                onClick={() =>
                  history.push(routes.rate, {
                    type: "PTRoom",
                    typeId: ptData?.id,
                    title: ptData?.title,
                    avgRate: ptData?.avgRate
                      ? Math.round(Number(ptData?.avgRate) * 10) / 10
                      : 0,
                    pTPrices: ptData?.pTPrices,
                  })
                }
              >
                리뷰{" "}
                <i
                  style={{
                    marginLeft: "5px",
                    fontSize: "16px",
                    color: "#0d6efd",
                  }}
                  className="bi bi-chat-left-text-fill"
                />{" "}
              </div>
              <div
                className="end options"
                onClick={() =>
                  history.push(routes?.report, {
                    id: ptData?.trainer?.id,
                    username: ptData?.trainer?.username,
                    targetContentId: ptData?.id,
                    targetContentType: "PTRoom",
                    isContent: true,
                  })
                }
              >
                신고
                <i
                  style={{
                    marginLeft: "5px",
                    fontSize: "16px",
                    color: "#dc3545",
                  }}
                  className="bi bi-exclamation-octagon-fill"
                />{" "}
              </div>
              {/* <CardColumns>
                <GButton
                  variant="create"
                  width="75px"
                  context="리뷰"
              
                />
              </CardColumns>
              <CardColumns>
         
              </CardColumns> */}
            </DealInfo>
          </ProctInfo>
          {ptData?.pTPrices?.length ? (
            <PriceCon>
              {ptData?.pTPrices?.map((price) => (
                <PriceContainer key={price?.id} id={"Price" + price?.id}>
                  <TogglerCon>
                    <Toggler>
                      <InfoCon>
                        <Month>
                          <span>기간 : </span>
                          <span className="data">{price?.month}개월</span>
                        </Month>
                        <Price>
                          <span>가격 : </span>
                          <span className="data">
                            {" "}
                            {(price?.price * 10000).toLocaleString()}원
                          </span>
                        </Price>
                      </InfoCon>

                      <div className="btncon">
                        {ptData?.isMine ? null : price?.contracts?.reduce(
                            (acc, p) => {
                              for (let i = 0; i < p?.users?.length; i++) {
                                if (
                                  p?.users?.[i]?.id === user?.me?.id &&
                                  p?.isExpired === true &&
                                  p?.accepted === true &&
                                  p?.purchased === false
                                ) {
                                  acc += 1;
                                }
                              }
                              return acc;
                            },
                            0
                          ) > 0 ? (
                          <GButton
                            className="btn"
                            fontSize="15px"
                            padding="0.3rem 0.75rem"
                            context="기간 연장"
                            width="100%"
                            variant="update"
                            onClick={() =>
                              history.push(routes.saleInfo, {
                                title: ptData.title,
                                description: ptData.description,
                                month: price.month,
                                price: price.price,
                                trainer: ptData.trainer,
                                pTPriceId: price.id,
                              })
                            }
                          />
                        ) : price?.contracts?.reduce((acc, p) => {
                            for (let i = 0; i < p?.users?.length; i++) {
                              if (
                                p?.users?.[i]?.id === user?.me?.id &&
                                p?.isExpired === false &&
                                p?.accepted === true &&
                                p?.purchased === false
                              ) {
                                acc += 1;
                              }
                            }
                            return acc;
                          }, 0) > 0 ? (
                          <GButton
                            className="btn"
                            fontSize="15px"
                            padding="0.3rem 0.75rem"
                            context="결제 하기"
                            width="100%"
                            variant="create"
                            onClick={() =>
                              history.push(routes.saleInfo, {
                                title: ptData.title,
                                description: ptData.description,
                                month: price.month,
                                price: price.price,
                                trainer: ptData.trainer,
                                pTPriceId: price.id,
                              })
                            }
                          />
                        ) : price?.contracts?.reduce((acc, p) => {
                            for (let i = 0; i < p?.users?.length; i++) {
                              if (
                                p?.users?.[i]?.id === user?.me?.id &&
                                p?.isExpired === false &&
                                p?.accepted === true &&
                                p?.purchased === true
                              ) {
                                acc += 1;
                              }
                            }
                            return acc;
                          }, 0) > 0 ? (
                          <GButton
                            onClick={() =>
                              history.push(routes.message, {
                                ChatRoomId: ChatRoomId,
                              })
                            }
                            variant="create"
                            context="PT 시작"
                            padding="0"
                            fontSize="15px"
                            width="100%"
                          />
                        ) : (
                          <GButton
                            className="btn"
                            context="등록"
                            padding="0.3rem 0.75rem"
                            fontSize="15px"
                            width="100%"
                            onClick={() => register(price)}
                            disabled={dis}
                          />
                        )}
                      </div>
                    </Toggler>
                  </TogglerCon>
                </PriceContainer>
              ))}
            </PriceCon>
          ) : null}
        </PTCon>
      </Con>
      <Line />
      <CardColumns>
        <PTitle>상세내용</PTitle>
        <DesCon>
          <Description id="ccc" className="ql-editor">
            <div></div>
          </Description>
        </DesCon>
      </CardColumns>
    </SaleContainer>
  );
}

export default Sale;
