//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Card, CardColumns, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import UseFormError from "../Accounts/UseFormError";
import { Input } from "../component/shared/Auth";
import { GButton } from "../component/shared/GButton";
import { EMAIL_VERIFICATION_MUTATION } from "../queries/mail/emailVerfication";
import { CHECK_USER_BY_EMAIL_MUTATION } from "../queries/user/checkUserByEmail";
import { CHANGE_PW_BY_EMAIL_MUTATION } from "../queries/user/changePWByEmail";
import colors from "../utils/colors";
import { useHistory } from "react-router-dom";
import { routes } from "./routes";
import styled from "styled-components";
const PasswordEmailInput = styled.input`
  margin: 2px 0;
  padding: 5px;
  width: 100%;
  height: 70px;
  border: 10px;
  border-radius: 10px;
  box-shadow: 0 0 0 2px #b2bec3 inset;
  color: #0984e3;
  -webkit-text-fill-color: #0984e3;
  outline: none;

  &:focus {
    box-shadow: none;
    border: none;
    transition: 0.01s;
    border: 2px solid ${colors.bbeego};
  }
  &:invalid {
    background: #cfe6ff;
    color: black;
    -webkit-text-fill-color: black;
  }
  &:valid {
    background: white;
    color: black;
    -webkit-text-fill-color: black;
  }
  &:-webkit-autofill {
    background: #cfe6ff;
    -webkit-box-shadow-: 0 0 0 50px ${colors.bbeego} inset;
    -webkit-text-fill-color: ${colors.bbeego};
  }
`;
const PasswordEmailCodeInput = styled.input`
  margin: 2px 0;
  padding: 5px;
  width: 85%;
  height: 50px;
  border: 10px;
  border-radius: 10px;
  box-shadow: 0 0 0 2px #b2bec3 inset;
  color: #0984e3;
  -webkit-text-fill-color: #0984e3;
  outline: none;

  &:focus {
    box-shadow: none;
    border: none;
    transition: 0.01s;
    border: 2px solid ${colors.bbeego};
  }
  &:invalid {
    background: #cfe6ff;
    color: black;
    -webkit-text-fill-color: black;
  }
  &:valid {
    background: white;
    color: black;
    -webkit-text-fill-color: black;
  }
  &:-webkit-autofill {
    background: #cfe6ff;
    -webkit-box-shadow-: 0 0 0 50px ${colors.bbeego} inset;
    -webkit-text-fill-color: ${colors.bbeego};
  }
`;
const FindPassword = () => {
  const history = useHistory();
  const [isMailSent, setIsMailSent] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const { register, formState, getValues, setError, clearErrors } = useForm({
    mode: "onChange",
  });
  //이메일로 비밀번호 변경
  const onChangePWCompleted = (data) => {
    const {
      changePWByEmail: { ok },
    } = data;
    if (ok) {
      alert("비밀번호가 성공적으로 변경되었습니다!\n로그인을 진행해주세요.");
      history.push(routes.login);
    }
  };
  const [changePWByEmailMutation] = useMutation(CHANGE_PW_BY_EMAIL_MUTATION, {
    onCompleted: onChangePWCompleted,
  });
  //암호 코드 이메일로 전송
  const onEmailVerifyCompleted = (data) => {
    const {
      emailVerification: { ok },
    } = data;
    if (ok) {
      // setIsMailSent(true);
    }
  };
  const [emailVerficationMutation] = useMutation(EMAIL_VERIFICATION_MUTATION, {
    onCompleted: onEmailVerifyCompleted,
  });
  const onCheckUserCompleted = (data) => {
    const { email } = getValues();
    const verifyCode = Math.random().toString(36).substring(2).toUpperCase();
    const {
      checkUserByEmail: { ok },
    } = data;
    if (ok) {
      emailVerficationMutation({
        variables: {
          to: email,
          title: "BBEEGO 비밀번호 찾기",
          context: `<div style="width:100%;display:flex;flex-direction:column;align-items:center;gap:10px">
          <p><h1 style="font-weight:bold">비밀번호 찾기</h1></p>
          <p><h2><span>대체 비밀번호 : </span><span style="color:${colors.bbeego}">${verifyCode}</span></h2></p>
        </div>`,
        },
      });
      setVerifyCode(verifyCode);
      setIsMailSent(true);
    } else {
      alert("해당 이메일로 가입한 유저가 존재하지 않습니다!");
    }
  };
  const [checkUserByEmailMutation] = useMutation(CHECK_USER_BY_EMAIL_MUTATION, {
    onCompleted: onCheckUserCompleted,
  });
  const onCheckAndSend = () => {
    const { email } = getValues();
    if (!email) {
      return alert("이메일을 입력해주세요!");
    }
    if (!!formState?.errors?.email) {
      return alert("잘못된 형식의 이메일입니다!");
    }
    checkUserByEmailMutation({
      variables: {
        email,
      },
    });
  };
  //전송된 암호코드 인증
  const onVerifyConfirm = () => {
    const { verifyCodeInput } = getValues();
    if (verifyCode === verifyCodeInput) {
      setIsVerified(true);
    } else {
      return alert("잘못된 암호코드 입니다. 다시 입력해주세요!");
    }
  };
  //비밀번호 변경
  const onUpdateNewPassword = () => {
    const { email, newPassword, newPasswordConfirm } = getValues();
    if (!newPassword || !newPasswordConfirm) {
      return alert("입력된 값을 확인해주세요!");
    }
    if (newPassword === newPasswordConfirm) {
      changePWByEmailMutation({
        variables: {
          email,
          newPassword,
        },
      });
    } else {
      return setError("passwordDiff", {
        message: "비밀번호가 서로 다릅니다!",
      });
    }
  };

  return (
    <Container style={{ height: "100%", marginTop: "20%" }}>
      <Card
        style={{
          width: "350px",
          height: "300px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "space-around",
          alignItems: "flex-end",
        }}
      >
        <CardColumns
          style={{
            backgroundColor: "#dfe6e9",
            display: "flex",
            width: "100%",
            height: "13%",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Card.Title
            style={{
              height: "100%",
              fontSize: "18px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "justify",
            }}
          >
            비밀번호 찾기
          </Card.Title>
        </CardColumns>
        <Card.Body style={{ width: "100%" }}>
          <CardColumns
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              //gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <CardColumns
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <PasswordEmailInput
                id="email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value:
                      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                    message: "이메일 형식이 아닙니다.",
                  },
                })}
                type="text"
                placeholder="이메일 입력"
              />
            </CardColumns>
            <CardColumns
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                //gap: "5px",
                //width: "100%",
              }}
            >
              {!isMailSent ? (
                <div
                  style={{
                    color: "#636e72",
                    fontSize: "14px",
                    textAlign: "left",
                    lineHeight: "150%",
                  }}
                >
                  가입 시 등록하신 이메일 주소를 입력해 주세요. <br />
                  비밀번호 재설정 코드를 보내드립니다.
                </div>
              ) : !isVerified ? (
                <>
                  <div
                    style={{
                      color: "#636e72",
                      fontSize: "14px",
                      textAlign: "left",
                      lineHeight: "150%",
                    }}
                  >
                    이메일을 전송했습니다.
                    <br />
                    전송된 이메일에 기입된 암호코드를 입력해주세요.
                  </div>
                </>
              ) : (
                <div
                  style={{
                    color: "#636e72",
                    fontSize: "14px",
                    textAlign: "left",
                    lineHeight: "150%",
                  }}
                >
                  비밀번호를 변경해주세요.
                </div>
              )}
            </CardColumns>
            {!isMailSent ? (
              <CardColumns
                key={0}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CardColumns style={{ width: "100%" }}>
                  <GButton
                    variant="bbeego"
                    context="비밀번호 변경 코드 전송"
                    onClick={onCheckAndSend}
                    width="100%"
                    //color="red"
                    //borderColor="red"
                    //bgColor="red"
                    fontSize="15px"
                    fontWeight="bold"
                  />
                </CardColumns>
              </CardColumns>
            ) : !isVerified ? (
              <CardColumns
                key={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <CardColumns
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <PasswordEmailCodeInput
                    id="verifyCodeInput"
                    {...register("verifyCodeInput")}
                    type="text"
                    placeholder="암호 코드 입력"
                  />
                </CardColumns>
                <CardColumns>
                  <GButton
                    variant="create"
                    context="확인"
                    onClick={onVerifyConfirm}
                  />
                </CardColumns>
              </CardColumns>
            ) : (
              <CardColumns
                key={2}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <CardColumns>
                  <Input
                    id="newPassword"
                    {...register("newPassword", {
                      pattern: {
                        value:
                          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                        message:
                          "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
                      },
                    })}
                    type="password"
                    placeholder="새로운 비밀번호"
                    onKeyUp={() => clearErrors("passwordDiff")}
                  />
                </CardColumns>
                <CardColumns>
                  <UseFormError
                    message={formState?.errors?.newPassword?.message}
                  />
                </CardColumns>
                <CardColumns>
                  <Input
                    id="newPasswordConfirm"
                    {...register("newPasswordConfirm", {
                      pattern: {
                        value:
                          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                        message:
                          "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
                      },
                    })}
                    type="password"
                    placeholder="새로운 비밀번호 확인"
                    onKeyUp={() => clearErrors("passwordDiff")}
                  />
                </CardColumns>
                <CardColumns>
                  <UseFormError
                    message={formState?.errors?.newPasswordConfirm?.message}
                  />
                </CardColumns>
                <CardColumns>
                  <UseFormError
                    message={formState?.errors?.passwordDiff?.message}
                  />
                </CardColumns>
                <CardColumns>
                  <GButton
                    variant="create"
                    context="확인"
                    onClick={onUpdateNewPassword}
                  />
                </CardColumns>
              </CardColumns>
            )}

            <CardColumns>
              <UseFormError message={formState?.errors?.email?.message} />
            </CardColumns>
          </CardColumns>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FindPassword;
