//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect } from "react";

const BrowserRender = () => {
  useEffect(async () => {
    if (window?.onnx) {
      const sess = new window.onnx.InferenceSession();
      await sess.loadModel("/models/onnx_bodyshape_pifuhd_model.onnx");
    }
  }, [window?.onnx]);
  return <div>browser render</div>;
};

export default BrowserRender;
