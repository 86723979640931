//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useState } from "react";
import styled from "styled-components";
import PostHeader from "../../Accounts/PostHeader";
import CheckUserToken from "../../utils/checkUserToken";
import { useWindowSize } from "./useWindowSize";

const RankContainer = styled.div`
  width: 390px;
  height: 800px;
  border: 1px solid lightgray;
  background-color: white;
  align-self: flex-start;
  position: fixed;
  right: 440px;
`;
const MyCon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;
const MyName = styled.span``;
const RankCon = styled.div`
  height: 350px;
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
`;

const Rank = () => {
  const Me = CheckUserToken();
  const size = useWindowSize();
  return (
    <>
      {size?.width > 1000 ? (
        <RankContainer>
          <PostHeader user={Me?.me} />
          <RankCon></RankCon>
        </RankContainer>
      ) : null}
    </>
  );
};

export default Rank;
