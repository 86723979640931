//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useHistory } from "react-router";
import styled from "styled-components";
import { routes } from "../../routes/routes";
import colors from "../../utils/colors";
import { ButtonLayout } from "../shared/ButtonLayout";
import { GButton } from "../shared/GButton";
import { TypeBadge } from "../shared/TypeBadge";

const SResponse = styled.div`
  //margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  //justify-content: space-between;
  position: relative;
`;
const CaseCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: 1200px) {
    grid-template-columns: none;
    flex-direction: column;
  }
`;

const Status = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${colors.bbeegoText};
`;
const ResponseBtn = styled(ButtonLayout)`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "white")};
  border: ${(props) => (props.bgColor ? "none" : "1px solid gray")};
  color: ${(props) => (props.color ? props.color : "black")};
  padding: 10px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

export const Response = ({
  contract,
  onApplyClick,
  onContractUpdateClick,
  onDelFromReqListClick,
  onDelFromReqedListClick,
  onCancelRequestRegister,
}) => {
  const history = useHistory();

  const onDenyReasonBtnClick = (e) => {
    e.target.nextSibling.style.display = "block";
  };
  const onPurchaseClick = () => {
    alert("결제화면으로 이동");
  };

  return (
    <SResponse>
      {contract?.isMine ? (
        <CaseCon>
          {/* {contract?.accepted && !contract?.purchased ? (
            <TypeBadge
              variant="challenge"
              //bgColor="rgba(0, 230, 0, 1)"
              //color="white"
              fontWeight="bold"
              //disabled={true}
              context="결제 대기중"
              width="73px"
              //82
            ></TypeBadge>
          ) : null}
          {contract?.denied ? (
            <TypeBadge
              //variant="denied"
              bgColor="#ccc"
              //color="white"
              disabled={true}
              context="요청 거절됨"
              width="73px"
              //82
              margin="0 5px 0 0"
            ></TypeBadge>
          ) : null}
          {contract?.purchased ? (
            <TypeBadge
              variant="success"
              //bgColor="rgba(0, 230, 0, 1)"
              //color="white"
              disabled={true}
              context="결제 완료"
              width="73px"
              //82
              margin="0 5px 0 0"
            ></TypeBadge>
          ) : null} */}

          {!contract?.accepted && !contract?.purchased && !contract?.denied ? (
            <CaseCon>
              <GButton
                variant="create"
                //bgColor="#1DC31D"
                color="white"
                width="73px"
                responsiv="true"
                onClick={() => {
                  onContractUpdateClick(
                    contract?.id,
                    "accept",
                    contract?.customer?.id
                  );
                }}
                context="수락"
              ></GButton>
              <GButton
                fontSize="12px"
                variant="delete"
                bgColor="#f5002d"
                color="white"
                width="73px"
                responsiv="true"
                onClick={() => {
                  onContractUpdateClick(
                    contract?.id,
                    "deny",
                    contract?.customer?.id
                  );
                }}
                context="거절"
              ></GButton>
            </CaseCon>
          ) : null}

          {contract?.purchased || contract?.denied ? (
            <CaseCon>
              <GButton
                fontSize="12px"
                responsiv="true"
                variant="delete"
                onClick={() => {
                  onDelFromReqedListClick(contract?.id);
                }}
                context="삭제 "
                width="73px"
                //82
              ></GButton>
            </CaseCon>
          ) : null}
        </CaseCon>
      ) : (
        <CaseCon>
          {/* {!contract?.accepted && !contract?.purchased && !contract?.denied ? (
            <TypeBadge
              variant="success"
              bgColor="orange"
              color="white"
              disabled={true}
              width="73px"
              context="요청 완료"
            ></TypeBadge>
          ) : null} */}
          {contract?.accepted && !contract?.purchased && !contract?.denied ? (
            <CaseCon>
              {/* <TypeBadge
                variant="status"
                disabled={true}
                width="73px"
                context="요청 승인됨"
              ></TypeBadge> */}
              <GButton
                fontSize="12px"
                responsiv="true"
                variant="payment"
                color="white"
                disabled={false}
                onClick={() => {
                  // onContractUpdateClick(
                  //   contract?.id,
                  //   "purchase",
                  //   contract?.customer?.id
                  // );
                  history.push(routes.saleInfo, {
                    title: contract?.pTPrice?.pTRoom?.title,
                    description: contract?.pTPrice?.pTRoom?.description,
                    month: contract?.pTPrice?.month,
                    price: contract?.pTPrice?.price,
                    trainer: contract?.trainer,
                    pTPriceId: contract?.pTPrice?.id,
                  });
                }}
                context="결제"
                width="73px"
              ></GButton>
            </CaseCon>
          ) : null}
          {!contract?.accepted && !contract?.purchased && contract?.denied ? (
            <>
              {/* <TypeBadge
                variant="rejected"
                color="white"
                disabled={true}
                context="요청 거절됨"
                width="73px"
              ></TypeBadge> */}
              {/* <GButton
                disabled={false}
                onClick={(e) => onDenyReasonBtnClick(e)}
                context="거절 사유"
                width="73px"
                bgColor="#c0c0c0"
                border="unset"
              ></GButton>
              <FloatingBox
                display="none"
                padding="15px"
                color="red"
                borderRadius="20px"
                width="95px"
              >
                {contract?.denyMessage}
              </FloatingBox> */}
            </>
          ) : null}
          {/* {!contract?.denied ? (
            <ResponseBtn
              bgColor="red"
              color="white"
              disabled={false}
              onClick={() =>
                onCancelRequestRegister(contract?.id, contract?.trainer?.id)
              }
            >
              요청 취소
            </ResponseBtn>
          ) : null} */}
          {/* {contract?.accepted && contract?.purchased && !contract?.denied ? (
            <TypeBadge
              variant="success"
              bgColor="rgba(0, 230, 0, 1)"
              color="white"
              disabled={true}
              context="결제 완료"
              width="73px"
            ></TypeBadge>
          ) :
           <GButton
            variant="success"
            bgColor="rgba(0, 230, 0, 1)"
            color="white"
            disabled={true}
            onClick={() =>
               onCancelRequestRegister(contract?.id, contract?.trainer?.id)
             }
            context="결제 완료"
          ></GButton>
          null} */}
          {contract?.purchased || contract?.denied ? (
            <GButton
              fontSize="12px"
              responsiv="true"
              variant="delete"
              onClick={() => {
                onDelFromReqListClick(contract?.id);
              }}
              context=" 삭제"
              width="73px"
            ></GButton>
          ) : null}
        </CaseCon>
      )}
    </SResponse>
  );
};
