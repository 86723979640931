//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

import { CardColumns } from "react-bootstrap";
import styled from "styled-components";
import colors from "../../../utils/colors";
import ProfilePhoto from "../../shared/ProfilePhoto";

const MsgRow = styled(CardColumns)`
  max-width: 270px;
  align-self: ${(props) => (props.align ? props.align : "flex-start")};
  margin: 1px;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.align ? props.align : "flex-start")};
`;
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
`;
const Username = styled.div`
  font-weight: bold;
`;

const DateCon = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const DateTitle = styled.div`
  width: 25%;
  margin: 60px 0 40px 0;
  padding: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  border-radius: 20px;
  color: ${colors.bbeego};
  /* background-color: #ccc;
  border-radius: 20px; */
`;

const MessageList = ({ seeMessage, delMsgMaker, msgMaker, openDropDown }) => {
  const smsg = seeMessage?.reduce((acc, curr) => {
    const { createdAt } = curr;
    const days = new Date(Number(createdAt)).toLocaleDateString("kor");
    if (acc[days]) acc[days].push(curr);
    else acc[days] = [curr];
    return acc;
  }, {});

  return seeMessage?.length
    ? Object.keys(smsg).length
      ? Object.keys(smsg)?.map((obj) => {
          return (
            <DateCon id={`datecon_${obj}`} key={`datecon_${obj}`}>
              <DateTitle id={`date_${obj}`} key={`date_${obj}`}>
                {obj}
              </DateTitle>
              {smsg[obj]?.map((msg, idx) =>
                msg.isMine ? (
                  !msg.deletedAt ? (
                    <MsgRow
                      id={`msg_${msg.id}`}
                      key={msg.id}
                      align={"flex-end"}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        openDropDown(e, msg);

                        // setMsgId(msg.id);
                      }}
                    >
                      {msg?.user.id == smsg[obj][idx - 1]?.user.id ? null : (
                        <UserInfo justify={"flex-end"}>
                          <ProfilePhoto
                            src={msg.user.profilePhoto}
                            id={msg?.user?.id}
                          />
                          <Username>{msg.user.username}</Username>
                        </UserInfo>
                      )}
                      {msgMaker(msg)}
                    </MsgRow>
                  ) : (
                    <MsgRow
                      id={`msg_${msg.id}`}
                      key={msg.id}
                      align={"flex-end"}
                    >
                      {msg?.user.id == smsg[obj][idx - 1]?.user.id ? null : (
                        <UserInfo justify={"flex-end"}>
                          <ProfilePhoto
                            src={msg.user.profilePhoto}
                            id={msg?.user?.id}
                          />
                          <Username>{msg.user.username}</Username>
                        </UserInfo>
                      )}
                      {delMsgMaker(msg)}
                    </MsgRow>
                  )
                ) : !msg.deletedAt ? (
                  <MsgRow
                    onContextMenu={(e) => {
                      e.preventDefault();
                      openDropDown(e, msg);

                      // setMsgId(msg.id);
                    }}
                    id={`msg_${msg.id}`}
                    key={msg.id}
                  >
                    {msg?.user.id == smsg[obj][idx - 1]?.user.id ? null : (
                      <UserInfo>
                        <ProfilePhoto
                          src={msg.user.profilePhoto}
                          id={msg?.user?.id}
                        />
                        <Username>{msg.user.username}</Username>
                      </UserInfo>
                    )}
                    {msgMaker(msg)}
                  </MsgRow>
                ) : (
                  <MsgRow id={`msg_${msg.id}`} key={msg.id}>
                    {msg?.user.id == smsg[obj][idx - 1]?.user.id ? null : (
                      <UserInfo>
                        <ProfilePhoto
                          src={msg.user.profilePhoto}
                          id={msg?.user?.id}
                        />
                        <Username>{msg.user.username}</Username>
                      </UserInfo>
                    )}
                    {delMsgMaker(msg)}
                  </MsgRow>
                )
              )}
            </DateCon>
          );
        })
      : null
    : null;
};

export default MessageList;
