//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useQuery } from "@apollo/client";
import { faChild, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ChatroomModule from "../component/chatroom/ChatroomModule";
import { Container } from "../component/shared/Container";
import { FatText } from "../component/shared/FatText";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import { SeePTPrice } from "../queries/pTPrice/seePTPrice";

const PTRoomHeader = styled.div`
  width: 100%;
  height: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Title {
    height: 50px;
    font-weight: bold;
    font-size: large;
  }
  .Desc {
    width: 100%;
    height: 100px;
  }
`;
const UserList = styled.div`
  width: 30%;
  height: auto;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  box-shadow: 0 0 15px -5px gray;
  border-radius: 10px;
  flex-wrap: wrap;
  overflow-y: auto;
`;

const CustomerRow = styled.div`
  display: flex;
  width: 200px;
  height: 40px;
  align-items: center;
  gap: 10px;
  .userName {
    min-width: 100px;
  }
`;
const PTRoomContents = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  gap: 10px;
`;
const EmptyCustomerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  span {
    align-items: center;
  }
`;
const Title = styled.div``;
const Description = styled.div``;
const UserListTitle = styled.div`
  font-weight: bold;
`;

const PTRoomDetail = () => {
  const { state } = useLocation();
  const seePTPrice = SeePTPrice(state?.pTPriceId)?.seePTPrice;

  return (
    <Container id="main">
      <PTRoomHeader>
        <Title>{seePTPrice?.pTRoom?.title}</Title>
        <Description>{seePTPrice?.pTRoom?.description}</Description>
      </PTRoomHeader>
      <PTRoomContents>
        {seePTPrice?.chatRoom?.id ? (
          <ChatroomModule id={seePTPrice?.chatRoom?.id} />
        ) : null}
        <UserList>
          <>
            <UserListTitle>회원 목록</UserListTitle>
            {seePTPrice?.contracts?.length ? (
              seePTPrice?.contracts?.map((customer) => {
                return (
                  <CustomerRow key={customer?.customer?.id}>
                    <ProfilePhoto
                      src={customer?.customer?.profilePhoto}
                      id={customer?.customer?.id}
                    />
                    <div className="userName">
                      {customer?.customer?.username}
                    </div>
                    <FontAwesomeIcon icon={faChild} />
                  </CustomerRow>
                );
              })
            ) : (
              <EmptyCustomerRow>
                <FatText>등록된 유저가 없습니다.</FatText>
              </EmptyCustomerRow>
            )}
          </>
        </UserList>
      </PTRoomContents>
    </Container>
  );
};

export default PTRoomDetail;
