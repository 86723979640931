//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//해당 화면은 route 로, mygym 의 챌린지 탭에서 자신의 챌린지 화면에서 챌린지 관리 버튼을 클릭시 연결되는 화면 입니다.

import { useMutation } from "@apollo/client";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardColumns,
  CardDeck,
  CardGroup,
  Container,
  Modal,
  Row,
  TabContent,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { generateUUID } from "three/src/math/MathUtils";
import MultiChallengeContractInteraction from "../../component/multiChallenge/MultiChallengeContractInteraction";
import MultiChallengeGroupList from "../../component/multiChallenge/MultiChallengeGroupList";
import { GButton } from "../../component/shared/GButton";
import { Ribbon } from "../../component/shared/Ribbon";
import { EXPIRE_MULTICHALLENGE_MUTATION } from "../../queries/multiChallenge/expireMultiChallenge";
import { FINISH_MULTICHALLENGE_MUTATION } from "../../queries/multiChallenge/finishMultiChallenge";
import { SelectMultiChallengeById } from "../../queries/multiChallenge/selectMultiChallengeById";
import { SelectReceiptByContractId } from "../../queries/Receipt/selectReceiptByContractId";
import colors from "../../utils/colors";

const Tabs = styled.div`
  width: inherit;
  display: flex;
`;
const Tab = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 2px solid ${colors.defaultGray};
  transition: border-bottom 0.3s;
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 64px;
`;
const SCardTitle = styled(Card.Title)`
  padding: 30px 20px 10px 20px;
  font-weight: bold;
`;
const SContent = styled(TabContent)`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
`;
const FeeAlarm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: red;
  font-weight: bold;
`;
const SCardGroup = styled(CardGroup)``;
const HeaderCardColumns = styled(CardColumns)`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 770px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
const HeaderCard = styled(Card)`
  display: flex;
  flex-direction: row;
  @media (max-width: 770px) {
    flex-direction: column;
  }
`;
const HeaderCardText = styled(Card.Text)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
const TitleCardColumns = styled(CardColumns)`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
  @media (max-width: 770px) {
    justify-content: flex-start;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const MultiChallengeManagement = () => {
  const location = useLocation();
  //modal
  const [modal, setModal] = useState(false);
  //refs
  const tabsRef = useRef();
  const tabs2Ref = useRef();
  //states
  const [tab, setTab] = useState(0);
  const [tab2] = useState(0);
  //queries
  const { data: multiChallengeData, refetch: refetchMultiChallengeData } =
    SelectMultiChallengeById(location?.state?.id);
  useEffect(() => {
    if (tabsRef?.current) {
      const tabs = Array.from(tabsRef?.current?.childNodes);
      tabs.forEach(
        (tab) => (tab.style.borderBottom = `2px solid ${colors.defaultGray}`)
      );
      tabs[tab].style.borderBottom = `2px solid ${colors.bbeego}`;
    }
  }, [tab]);
  useEffect(() => {
    if (tabs2Ref?.current) {
      const tabs = Array.from(tabs2Ref?.current?.childNodes);
      tabs.forEach(
        (tab) => (tab.style.borderBottom = `2px solid ${colors.defaultGray}`)
      );
      tabs[tab2].style.borderBottom = `2px solid ${colors.bbeego}`;
    }
  }, [tab2]);
  //expireMultiChallenge
  const onExpireUpdate = (cache, data) => {
    const {
      data: {
        expireMultiChallenge: { ok },
      },
    } = data;
    if (ok) {
      cache.modify({
        id: `MultiChallenge:${multiChallengeData?.selectMultiChallengeById?.id}`,
        fields: {
          isExpired(prev) {
            return true;
          },
        },
      });
      alert("신청 마감 되었습니다.");
    }
  };
  const [expireMultiChallengeMutation] = useMutation(
    EXPIRE_MULTICHALLENGE_MUTATION,
    { update: onExpireUpdate }
  );
  const onExpireClick = () => {
    const confirmBool = window.confirm(
      "신청 마감 시 더 이상 챌린지 목록에 나타나지 않게됩니다.\n진행 하시겠습니까?"
    );
    if (!!confirmBool) {
      expireMultiChallengeMutation({
        variables: {
          id: multiChallengeData?.selectMultiChallengeById?.id,
        },
      });
    }
  };
  //finishMultiChallenge
  const onFinishUpdate = (cache, data) => {
    const {
      data: {
        finishMultiChallenge: { ok },
      },
    } = data;
    if (ok) {
      cache.modify({
        id: `MultiChallenge:${multiChallengeData?.selectMultiChallengeById?.id}`,
        fields: {
          isFinished(prev) {
            return true;
          },
        },
      });
    }
  };
  const [finishMultiChallengeMutation] = useMutation(
    FINISH_MULTICHALLENGE_MUTATION,
    { update: onFinishUpdate }
  );
  const onFinishClick = () => {
    const confirmBool = window.confirm("챌린지를 종료하시겠습니까?");
    if (!!confirmBool) {
      finishMultiChallengeMutation({
        variables: {
          id: multiChallengeData?.selectMultiChallengeById?.id,
        },
      });
    }
  };
  //토스 모듈
  //토스 결제 모듈
  const purchase = async () => {
    const tossClientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
    const tossPayments = await loadTossPayments(tossClientKey);
    tossPayments.requestPayment("카드", {
      amount:
        multiChallengeData?.selectMultiChallengeById?.baseprice *
          10000 *
          multiChallengeData?.selectMultiChallengeById?.trainerCount +
        multiChallengeData?.selectMultiChallengeById?.bonus *
          10000 *
          multiChallengeData?.selectMultiChallengeById?.trainerCount +
        multiChallengeData?.selectMultiChallengeById?.reward *
          10000 *
          multiChallengeData?.selectMultiChallengeById?.trainerCount *
          multiChallengeData?.selectMultiChallengeById?.userCountPerTrainer +
        (multiChallengeData?.selectMultiChallengeById?.baseprice *
          10000 *
          multiChallengeData?.selectMultiChallengeById?.trainerCount +
          multiChallengeData?.selectMultiChallengeById?.bonus *
            10000 *
            multiChallengeData?.selectMultiChallengeById?.trainerCount +
          multiChallengeData?.selectMultiChallengeById?.reward *
            10000 *
            multiChallengeData?.selectMultiChallengeById?.trainerCount *
            multiChallengeData?.selectMultiChallengeById?.userCountPerTrainer) *
          0.1,
      orderId: `${generateUUID()}_multiChallenge_${
        multiChallengeData?.selectMultiChallengeById?.id
      }`,
      orderName: multiChallengeData?.selectMultiChallengeById?.title,
      customerName:
        multiChallengeData?.selectMultiChallengeById?.user?.username,
      successUrl: `${process.env.REACT_APP_CLIENT_URL}multiChallengePaymentConfirm`,
      failUrl: "http://localhost:8080/fail", //결제실패시 route 생성 필요
    });
  };
  //statement
  const { data: receiptData, refetch: receiptDataRefetch } =
    SelectReceiptByContractId(location?.state?.id);

  return (
    <Container style={{ marginBottom: "15px" }}>
      <Row>
        <CardDeck
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          {!multiChallengeData?.selectMultiChallengeById?.isPaid ? (
            <span
              style={{
                cursor: "auto",
                fontSize: "12px",
              }}
              className="badge border-danger border-2 text-danger"
            >
              *결제가 아직 완료되지 않았습니다. 결제하기 버튼을 클릭하여 결제를
              진행해주세요.
            </span>
          ) : null}
          <Card style={{ zIndex: 100, border: "none" }}>
            <HeaderCardColumns>
              <Ribbon
                context="Challenge"
                top="-8px"
                left="-8px"
                width="120px"
                padding="3px"
                boxshadow="none"
                borderradius="60px"
                position="unset"
                margin="0 5px 0 0"
              />
              {multiChallengeData?.selectMultiChallengeById?.isPaid ? (
                <Card.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "5px",
                    borderRadius: "50px",
                    width: "fit-content",
                  }}
                >
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-success border-2 text-success"
                  >
                    결제 완료
                  </span>
                </Card.Text>
              ) : (
                <Card.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "5px",
                    borderRadius: "50px",
                    width: "fit-content",
                  }}
                >
                  <span
                    style={{ cursor: "auto", width: "100%" }}
                    className="badge border-danger border-2 text-danger"
                  >
                    결제 필요
                  </span>
                </Card.Text>
              )}
              {multiChallengeData?.selectMultiChallengeById?.isExpired ? (
                <Card.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "5px",
                    borderRadius: "50px",
                    width: "fit-content",
                  }}
                >
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-success border-2 text-success"
                  >
                    신청 마감 완료
                  </span>
                </Card.Text>
              ) : (
                <Card.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "5px",
                    borderRadius: "50px",
                    width: "fit-content",
                  }}
                >
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-danger border-2 text-danger"
                  >
                    신청 마감 전
                  </span>
                </Card.Text>
              )}
              <Card.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                  padding: "5px",
                  borderRadius: "50px",
                  width: "fit-content",
                  display: "flex",
                }}
              >
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-dark border-2 text-dark"
                >
                  종료날짜 :{" "}
                </span>
                <span
                  style={{ cursor: "auto" }}
                  className="badge border-dark border-2 text-dark"
                >
                  {
                    new Date(
                      Number(
                        multiChallengeData?.selectMultiChallengeById?.endDate
                      )
                    )
                      ?.toJSON()
                      ?.split("T")?.[0]
                  }
                </span>
              </Card.Text>
              {multiChallengeData?.selectMultiChallengeById?.isFinished ? (
                <Card.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid lightgray",
                    padding: "5px",
                    borderRadius: "50px",
                    width: "fit-content",
                  }}
                >
                  <span
                    style={{ cursor: "auto" }}
                    className="badge border-danger border-2 text-danger"
                  >
                    챌린지 종료
                  </span>
                </Card.Text>
              ) : null}
            </HeaderCardColumns>
            <FeeAlarm>
              <p>※챌린지 결제 시 수수료 20%가 추가로 결제됩니다.</p>
            </FeeAlarm>
          </Card>
          <CardGroup>
            <HeaderCard>
              <Card.Body style={{ width: "100%" }}>
                <Card.Title style={{ fontWeight: "bold", margin: 0 }}>
                  {multiChallengeData?.selectMultiChallengeById?.title}
                </Card.Title>
              </Card.Body>
              {!multiChallengeData?.selectMultiChallengeById?.isPaid ? (
                <HeaderCardText>
                  <GButton
                    variant="create"
                    context="결제하기"
                    onClick={() => setModal(true)}
                  />
                </HeaderCardText>
              ) : (
                <TitleCardColumns>
                  <CardColumns
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      지원 금액 :{" "}
                      {Number(
                        receiptData?.selectReceiptByContractId?.parent?.receipt
                          ?.statement?.amount
                      )?.toLocaleString()}{" "}
                      원
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      잔여 금액 :{" "}
                      {Number(
                        receiptData?.selectReceiptByContractId?.parent?.receipt
                          ?.statement?.remainAmount
                      )?.toLocaleString()}{" "}
                      원
                    </span>
                  </CardColumns>

                  {multiChallengeData?.selectMultiChallengeById?.isExpired ===
                    false &&
                  multiChallengeData?.selectMultiChallengeById?.isFinished ===
                    false ? (
                    <CardColumns>
                      <GButton
                        variant="create"
                        context="신청 마감하기"
                        height="50px"
                        onClick={onExpireClick}
                      />
                    </CardColumns>
                  ) : multiChallengeData?.selectMultiChallengeById
                      ?.isExpired === true &&
                    multiChallengeData?.selectMultiChallengeById?.isFinished ===
                      false &&
                    new Date(
                      Number(
                        multiChallengeData?.selectMultiChallengeById?.endDate
                      )
                    ).getTime() < new Date().getTime() ? (
                    <CardColumns>
                      <GButton
                        variant="create"
                        context="종료 하기"
                        height="50px"
                        onClick={onFinishClick}
                      />{" "}
                    </CardColumns>
                  ) : null}
                </TitleCardColumns>
              )}
            </HeaderCard>
          </CardGroup>
          <SCardGroup>
            <Card>
              <SCardTitle>신청 내역</SCardTitle>
              <Card.Body>
                {multiChallengeData?.selectMultiChallengeById?.isFinished ? (
                  <CardColumns style={{ color: "red" }}>
                    챌린지가 종료되었습니다.
                  </CardColumns>
                ) : (
                  <CardColumns>
                    <Tabs ref={tabsRef}>
                      <Tab onClick={() => setTab(0)}>
                        <span></span>
                        <span>트레이너 신청 내역</span>{" "}
                        {multiChallengeData?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                          (mcc) =>
                            mcc?.type === "trainer" && mcc?.accepted === null
                        )?.length > 0 ? (
                          <span>
                            <i
                              className="bi bi-exclamation"
                              style={{ color: "red" }}
                            ></i>
                          </span>
                        ) : null}
                      </Tab>
                      <Tab onClick={() => setTab(1)}>
                        <span></span>
                        <span>유저 신청 내역</span>
                        {multiChallengeData?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                          (mcc) =>
                            mcc?.type === "trainee" && mcc?.accepted === null
                        )?.length > 0 ? (
                          <span>
                            <i
                              className="bi bi-exclamation"
                              style={{ color: "red" }}
                            ></i>
                          </span>
                        ) : null}
                      </Tab>
                    </Tabs>
                    {tab === 0 ? (
                      <SContent>
                        {multiChallengeData?.selectMultiChallengeById?.multiChallengeContracts
                          ?.filter(
                            (mcc) =>
                              mcc?.type === "trainer" && mcc?.accepted === null
                          )
                          .map((tc) => (
                            <MultiChallengeContractInteraction
                              key={tc?.id}
                              contract={tc}
                              refetchMultiChallengeData={
                                refetchMultiChallengeData
                              }
                              receiptDataRefetch={receiptDataRefetch}
                            />
                          ))}
                      </SContent>
                    ) : null}
                    {tab === 1 ? (
                      <SContent>
                        {multiChallengeData?.selectMultiChallengeById?.multiChallengeContracts
                          ?.filter(
                            (mcc) =>
                              mcc?.type === "trainee" && mcc?.accepted === null
                          )
                          .map((tc) => (
                            <MultiChallengeContractInteraction
                              key={tc?.id}
                              contract={tc}
                              refetchMultiChallengeData={
                                refetchMultiChallengeData
                              }
                              receiptDataRefetch={receiptDataRefetch}
                            />
                          ))}
                      </SContent>
                    ) : null}
                  </CardColumns>
                )}
              </Card.Body>
            </Card>
          </SCardGroup>
          <SCardGroup>
            <MultiChallengeGroupList
              groups={
                multiChallengeData?.selectMultiChallengeById
                  ?.multiChallengeGroups
              }
              trainerCount={
                multiChallengeData?.selectMultiChallengeById?.trainerCount
              }
              userCountPerTrainer={
                multiChallengeData?.selectMultiChallengeById
                  ?.userCountPerTrainer
              }
              receipts={receiptData?.selectReceiptByContractId?.children}
            />
          </SCardGroup>
        </CardDeck>
      </Row>
      <Modal show={modal} size="lg" onHide={() => setModal(false)}>
        <Modal.Header>
          <Modal.Title
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            결제 내용{" "}
            {multiChallengeData?.selectMultiChallengeById?.baseprice *
              10000 *
              multiChallengeData?.selectMultiChallengeById?.trainerCount +
              multiChallengeData?.selectMultiChallengeById?.bonus *
                10000 *
                multiChallengeData?.selectMultiChallengeById?.trainerCount +
              multiChallengeData?.selectMultiChallengeById?.bonus *
                10000 *
                multiChallengeData?.selectMultiChallengeById?.trainerCount *
                multiChallengeData?.selectMultiChallengeById
                  ?.userCountPerTrainer +
              (multiChallengeData?.selectMultiChallengeById?.baseprice *
                10000 *
                multiChallengeData?.selectMultiChallengeById?.trainerCount +
                multiChallengeData?.selectMultiChallengeById?.bonus *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                multiChallengeData?.selectMultiChallengeById?.bonus *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount *
                  multiChallengeData?.selectMultiChallengeById
                    ?.userCountPerTrainer) *
                0.1 >
            2000000 ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                *200만원 이상 결제시 토스 결제는 불가능 합니다. 결제 방식 선택
                시 신용카드 결제로 진행해주세요.
              </span>
            ) : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardColumns
            style={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: "3.5fr 3fr 0.5fr 2fr",
            }}
          >
            <CardColumns>트레이너 기본금 X 트레이너 수 = </CardColumns>
            <CardColumns>
              {(
                multiChallengeData?.selectMultiChallengeById?.baseprice * 10000
              ).toLocaleString()}{" "}
              X {multiChallengeData?.selectMultiChallengeById?.trainerCount}
            </CardColumns>
            <CardColumns>=</CardColumns>
            <CardColumns
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(
                multiChallengeData?.selectMultiChallengeById?.baseprice *
                10000 *
                multiChallengeData?.selectMultiChallengeById?.trainerCount
              ).toLocaleString()}
            </CardColumns>
            <CardColumns>트레이너 보상금 X 트레이너 수 = </CardColumns>
            <CardColumns>
              {(
                multiChallengeData?.selectMultiChallengeById?.bonus * 10000
              ).toLocaleString()}{" "}
              X {multiChallengeData?.selectMultiChallengeById?.trainerCount}{" "}
            </CardColumns>
            <CardColumns>=</CardColumns>
            <CardColumns
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(
                multiChallengeData?.selectMultiChallengeById?.bonus *
                10000 *
                multiChallengeData?.selectMultiChallengeById?.trainerCount
              ).toLocaleString()}
            </CardColumns>
            <CardColumns>참여유저 보상금 X 참여유저 수 = </CardColumns>
            <CardColumns>
              {(
                multiChallengeData?.selectMultiChallengeById?.reward * 10000
              ).toLocaleString()}{" "}
              X{" "}
              {multiChallengeData?.selectMultiChallengeById?.trainerCount *
                multiChallengeData?.selectMultiChallengeById
                  ?.userCountPerTrainer}
            </CardColumns>
            <CardColumns>=</CardColumns>
            <CardColumns
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(
                multiChallengeData?.selectMultiChallengeById?.reward *
                10000 *
                multiChallengeData?.selectMultiChallengeById?.trainerCount *
                multiChallengeData?.selectMultiChallengeById
                  ?.userCountPerTrainer
              ).toLocaleString()}
            </CardColumns>
            <CardColumns>수수료</CardColumns>
            <CardColumns>
              {(
                multiChallengeData?.selectMultiChallengeById?.baseprice *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                multiChallengeData?.selectMultiChallengeById?.bonus *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                multiChallengeData?.selectMultiChallengeById?.reward *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount *
                  multiChallengeData?.selectMultiChallengeById
                    ?.userCountPerTrainer
              ).toLocaleString()}{" "}
              X 10%(0.1)
            </CardColumns>
            <CardColumns>=</CardColumns>
            <CardColumns
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(
                (multiChallengeData?.selectMultiChallengeById?.baseprice *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                  multiChallengeData?.selectMultiChallengeById?.bonus *
                    10000 *
                    multiChallengeData?.selectMultiChallengeById?.trainerCount +
                  multiChallengeData?.selectMultiChallengeById?.reward *
                    10000 *
                    multiChallengeData?.selectMultiChallengeById?.trainerCount *
                    multiChallengeData?.selectMultiChallengeById
                      ?.userCountPerTrainer) *
                0.1
              ).toLocaleString()}
            </CardColumns>
            <CardColumns>합계</CardColumns>
            <CardColumns>
              {(
                multiChallengeData?.selectMultiChallengeById?.baseprice *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                multiChallengeData?.selectMultiChallengeById?.bonus *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                multiChallengeData?.selectMultiChallengeById?.reward *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount *
                  multiChallengeData?.selectMultiChallengeById
                    ?.userCountPerTrainer
              ).toLocaleString()}{" "}
              +{" "}
              {(
                (multiChallengeData?.selectMultiChallengeById?.baseprice *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                  multiChallengeData?.selectMultiChallengeById?.bonus *
                    10000 *
                    multiChallengeData?.selectMultiChallengeById?.trainerCount +
                  multiChallengeData?.selectMultiChallengeById?.reward *
                    10000 *
                    multiChallengeData?.selectMultiChallengeById?.trainerCount *
                    multiChallengeData?.selectMultiChallengeById
                      ?.userCountPerTrainer) *
                0.1
              ).toLocaleString()}
            </CardColumns>
            <CardColumns>=</CardColumns>
            <CardColumns
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(
                multiChallengeData?.selectMultiChallengeById?.baseprice *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                multiChallengeData?.selectMultiChallengeById?.bonus *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                multiChallengeData?.selectMultiChallengeById?.reward *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount *
                  multiChallengeData?.selectMultiChallengeById
                    ?.userCountPerTrainer +
                (multiChallengeData?.selectMultiChallengeById?.baseprice *
                  10000 *
                  multiChallengeData?.selectMultiChallengeById?.trainerCount +
                  multiChallengeData?.selectMultiChallengeById?.bonus *
                    10000 *
                    multiChallengeData?.selectMultiChallengeById?.trainerCount +
                  multiChallengeData?.selectMultiChallengeById?.reward *
                    10000 *
                    multiChallengeData?.selectMultiChallengeById?.trainerCount *
                    multiChallengeData?.selectMultiChallengeById
                      ?.userCountPerTrainer) *
                  0.1
              ).toLocaleString()}
            </CardColumns>
          </CardColumns>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <GButton variant="create" context="결제하기" onClick={purchase} />
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MultiChallengeManagement;
