//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useReactiveVar } from "@apollo/client";
import { faArrowLeft, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { CardColumns } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { CHECK_MESSAGE_MUTATION } from "../../queries/message/checkMessage";
import { routes } from "../../routes/routes";
import CheckUserToken from "../../utils/checkUserToken";
import colors from "../../utils/colors";
import {
  isMyGymVar,
  isNewMessageVar,
  messageCloseClick,
  messageSidebarClick,
  messageSidebarToggleVar,
  myChatTabVar,
  myGymTabVar,
  sidebarClick,
} from "../../utils/reactiveVar";
import ChallengeContractGrid from "../admins/ChallengeContractGrid";
import ChallengeChatRoomList from "../chatroom/ChallengeChatRoomList";
import ChList from "../chatroom/ChatRoomList";
import ConsultChatRoomList from "../chatroom/ConsultChatRoomList";
import PtRoomChatRoomList from "../chatroom/PtRoomChatRoomList";
import { FatText } from "./FatText";
import ProfilePhoto from "./ProfilePhoto";

const ChatRoomList = styled(CardColumns)`
  background-color: white;
  /* border: 2px solid rgb(215, 215, 215); */
  border-top: none;
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  /* overflow-y: scroll; */
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
`;
const UserCon = styled(CardColumns)`
  /* max-width: 300px; */
  display: flex;
  height: 80px;
  text-align: center;
  /* padding: 5px; */
  /* border: 1px solid white; */
  cursor: pointer;
  word-break: break-all;
  position: relative;
  &:hover {
    background-color: rgb(250, 250, 252);
  }
  ${(props) =>
    props.chatRoomChecked === true
      ? `
      span{
        color: #ed5f00;
      }
     
    background-color: #f5f6fa;
  `
      : null}
`;
const UserConB = styled(CardColumns)`
  /* max-width: 300px; */
  display: flex;
  height: 60px;
  text-align: center;
  padding: 5px;
  border: 1px solid white;
  cursor: pointer;
  word-break: break-all;
  position: relative;
  ${(props) =>
    props.chatRoomChecked === true
      ? `
      span{
        color: #ed5f00;
      }
     
    background-color: #f6f9ff;
  `
      : null}
`;
const NewMessage = styled.span`
  color: orange;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
`;

const MessageThumbnail = styled(CardColumns)`
  align-self: center;
  padding: 10px;
`;

const SelectChatType = styled(CardColumns)`
  width: 100%;
  /* margin-bottom: 10px; */
`;
const Tabs = styled(CardColumns)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  /* margin-bottom: 10px; */
`;
const Tab = styled(CardColumns)`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid rgb(215, 215, 215);
  cursor: pointer;
  ${(props) =>
    props.checked
      ? `border: 2px solid #ed5f00;  border-bottom: none; color:#ed5f00; background-color: white;`
      : props.cnum === 1
      ? ` border-left:none;  border-bottom: 2px solid #ed5f00;  `
      : ` border-right:none;  border-bottom: 2px solid #ed5f00; `};
  /* transition: border-bottom 0.3s; */
`;

const TypeCon = styled(CardColumns)`
  height: 100%;
`;

const Container = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};

  max-width: ${(props) => (props.maxWidh ? props.maxWidth : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  background-color: white;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: unset !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  left: ${(props) => (props.left ? props.left : "")};
  border: ${(props) => (props.containerBorder ? props.containerBorder : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  display: ${(props) => (props.display ? props.display : "")};
  box-shadow: 0 0 15px -5px gray;
  z-index: 100;
`;
const LeftMenu = styled.span`
  cursor: pointer;
  color: gray;
  font-size: 20px;
  font-weight: bold;
  &:hover {
    color: ${colors.bbeego};
  }
`;
const NoInfo = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.bbeego};
  gap: 5px;
`;
const NoInfoIcon = styled.i``;
const NoInfoText = styled.div``;
const MsgNavigationLeft = ({
  iconShow,
  fontSize,
  fontWeight,
  position,
  containerBorder,
  borderRadius,
  margin,
  width,
  top,
  left,
  display,
  roomList,
  getdata,
}) => {
  const chatRoomList = useRef();
  const msgNavRef = useRef();
  const loggedInUser = CheckUserToken();
  const tab_1 = useRef();
  const tab_2 = useRef();
  const myChatTab = useReactiveVar(myChatTabVar);
  const msgSidebarToggle = useReactiveVar(messageSidebarToggleVar);
  const [showChatRoom, setShowChatRoom] = useState(0); //chatroomId
  const onBarsClick = () => {
    messageSidebarClick();
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickModalOutside);

    return () => {
      document.removeEventListener("mousedown", clickModalOutside);
    };
  });

  const clickModalOutside = (event) => {
    if (msgSidebarToggle && !msgNavRef.current.contains(event.target)) {
      messageCloseClick();
    }
  };
  const update = (cache, data) => {
    cache.modify({
      id: `ChatRoom:${showChatRoom}`,
      fields: {
        messages(prev, { fieldName, storeFieldName }) {},
      },
    });
  };

  // const onChatRoom = (chatRoomId) => {
  //   getdata(chatRoomId);
  // };

  const onTabClick = (tabNo, { target }) => {
    myChatTabVar(tabNo);
  };
  const getChdata = (chatRoomId) => {
    getdata(chatRoomId);
    setShowChatRoom(chatRoomId);
    messageCloseClick();
  };
  return (
    <Container
      className="sidebar_custom"
      position={position}
      containerBorder={containerBorder}
      borderRadius={borderRadius}
      margin={margin}
      width={width}
      top={top}
      left={left}
      display={display}
      ref={msgNavRef}
    >
      <CardColumns style={{ display: "flex", justifyContent: "flex-end" }}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{
            marginTop: "20px",
            marginRight: "10px",
            color: "gray",
            cursor: "pointer",
          }}
          size="lg"
          onClick={() => messageSidebarClick()}
        />
      </CardColumns>
      <CardColumns style={{ height: "100%" }}>
        <TypeCon>
          <SelectChatType>
            {" "}
            <Tabs>
              <Tab
                ref={tab_1}
                className="tab"
                checked={myChatTab === 1 ? true : false}
                cnum={myChatTab === 1 ? 1 : 2}
                onClick={(e) => {
                  onTabClick(1, e);
                }}
              >
                채팅
              </Tab>
              <Tab
                ref={tab_2}
                className="tab"
                checked={myChatTab === 2 ? true : false}
                cnum={myChatTab === 2 ? 2 : 1}
                onClick={(e) => {
                  onTabClick(2, e);
                }}
              >
                상담
              </Tab>
            </Tabs>
          </SelectChatType>
          <ChList getChdata={getChdata} />
        </TypeCon>
      </CardColumns>
    </Container>
  );
};

export default MsgNavigationLeft;
