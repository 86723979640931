//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "../../static/Logo.png";
import Navigation from "./Navigation";
import styled from "styled-components";
import CheckUserToken from "../../utils/checkUserToken";
import { routes } from "../../routes/routes";
import SearchBox from "../header/SearchBox";
import HeaderMenu from "./HeaderMenu";
import { Button, CardColumns } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faBars,
  faHamburger,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { useReactiveVar } from "@apollo/client";
import colors from "../../utils/colors";
import {
  consultsCloseClick,
  messageCloseClick,
  messageSidebarClick,
  sidebarClick,
} from "../../utils/reactiveVar";

const SMainHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: auto;
  background-color: white;
  border-bottom: 1px solid rgb(219, 219, 219);
  padding: 10px;
  z-index: 101;
  height: 65px;
`;
const MainWrapper = styled.div`
  //display: flex;
  display: grid;
  //grid-template-columns: 0.3fr 3.7fr 6fr 4fr;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  justify-content: space-between;
  .Logo {
    margin-right: auto;
  }
  .nav {
    align-items: center;
    a {
      padding: 0 5px 0 5px;
    }
    .svg-inline--fa {
      font-size: larger;
    }
    margin-left: auto;
  }
  height: 40px;
`;

export const Logoh1 = styled.h1`
  color: ${colors.bbeego} !important;
  display: flex;
  font-size: 40px;
  font-weight : normal;
  @media (max-width: 499px) {
    &:after {
      content: "B";
    }
  }
  @media (min-width: 500px) {
    &:after {
      content: "BBEEGO";
    }
  }
`;

export const Logoh2 = styled.h1`
  color: white !important;
  display: flex;
  font-size: 40px;
  font-weight : normal;
  &:after {
    content: "BBEEGO";
  }
`;

const LoginButton = styled.button`
  min-width: 131px;
  max-width: 200px;
  font-weight: bold;
  padding: 8px 30px;
  box-shadow: none;
  border: none;
  border-radius: 5px;
  background-color: #fd780f;
  color: white;
  transition: 0.5s;
  justify-self: flex-end;
  .ri-login-box-line {
    @media (max-width: 500px) {
      display: none !important;
    }
  }
  span {
    font-size: 15px;
    vertical-align: middle;
    margin-right: 5px;
  }
  &:hover {
    background-color: #3194db;
  }
`;
const Container_small = styled.div`
  width: fit-content;
  display: flex;
  justify-self: left;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  @media (min-width: 1051px) {
    display: none;
  }
`;
const BarCon = styled.div`
  cursor: pointer;
  color: #ff8200;
`;

function MainHeader() {
  const history = useHistory();
  const me = CheckUserToken(true);
  const onBarsClick = () => {
    sidebarClick();
    messageCloseClick();
    consultsCloseClick();
  };
  return (
    <SMainHeader>
      <MainWrapper>
        <CardColumns
          style={{
            display: "flex",
            width: "fit-content",
            alignItems: "center",
          }}
        >
          {/* <Link to={routes.home} className="Logo" style={{ zIndex: 103 }}>
            <Logoh1 className="logo"></Logoh1>
          </Link> */}
          <CardColumns
            onClick={() => (window.location.href = routes.home)}
            style={{ cursor: "pointer" }}
          >
            <Logoh1 className="logo"></Logoh1>
          </CardColumns>
          <Container_small>
            <BarCon onClick={onBarsClick}>
              <FontAwesomeIcon icon={faBars} size="2x" />
            </BarCon>
          </Container_small>
        </CardColumns>
        <HeaderMenu />
        {me?.me ? (
          <Navigation me={me && me} />
        ) : (
          <LoginButton
            // data-aos="fade-up"
            // data-aos-delay="100"
            onClick={() => history.push("/login")}
          >
            <span>Log In</span>
            <i
              style={{
                display: "inline-block",
                fontSize: "20px",
                verticalAlign: "middle",
              }}
              className=" ri-login-box-line"
              // onClick={() => history.push("/login")}
            />
          </LoginButton>
        )}
      </MainWrapper>
    </SMainHeader>
  );
}
export default MainHeader;
