//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import MultiChallengeCard from "../../component/multiChallenge/MultiChallengeCard";
import Spinner from "../../component/shared/Spinner";
import { SelectMultiChallenges } from "../../queries/multiChallenge/selectMultiChallenges";
import CheckUserToken from "../../utils/checkUserToken";
import colors from "../../utils/colors";
import { InteractBtn } from "../MyGym";
import { routes } from "../routes";

const ChallengeCon = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, auto));
`;

const CHHeader = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  margin-top: 120px;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
`;
const SubTitle = styled.h3`
  text-align: center;
  margin: 20px 0 20px 0;
  font-weight: 22px !important;
  color: #6b7280;
`;

const MultiChallengeList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();
  const { data, loading } = SelectMultiChallenges();
  const history = useHistory();
  const me = CheckUserToken(true);
  const onDeleteChallenge = () => {
    const ChallengeId = modalData?.id;
    const val = window.confirm("챌린지를 삭제합니다.");
    if (!!val) {
      alert("멀티 챌린지 삭제 기능 구현 필요 !!!");
    }
  };
  //페이지 이동
  useEffect(() => {
    if (!me && data?.selectMultiChallenges?.length) {
      history.push(routes.login);
    }
  }, [data?.selectMultiChallenges]);
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {loading === true ? <Spinner /> : null}
      <Row style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <CHHeader>
          <Title>챌린지</Title>
          <SubTitle>
            챌린지의 총 참여 가능한 트레이너 수와 트레이너 별 유저의 수는 챌린지
            마스터에 의해 정해지며, <br />
            참여 요청 수락/거절 또한 챌린지 마스터에 의해 결정됩니다.
          </SubTitle>
        </CHHeader>
        <SubTitle>
          {me?.me?.type === "user" ? (
            <Button
              variant="primary"
              style={{
                backgroundColor: colors.bbeego,
                border: "none",
                height: "40px",
                width: "130px",
              }}
            >
              <Link
                to={routes?.createMultiChallenge}
                style={{ color: "white" }}
              >
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
                챌린지 등록
              </Link>
            </Button>
          ) : null}
        </SubTitle>
      </Row>
      <Row>
        <ChallengeCon>
          {data?.selectMultiChallenges?.length
            ? data?.selectMultiChallenges?.map((MultiChallenge) => {
                return (
                  <MultiChallengeCard
                    key={MultiChallenge?.id}
                    {...MultiChallenge}
                    setModal={setModal}
                    setModalData={setModalData}
                  />
                );
              })
            : null}
        </ChallengeCon>
      </Row>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        centered="true"
        size="sm"
      >
        <Modal.Body>
          {modalData?.isMine ? (
            <>
              {/* <InteractBtn onClick={moveToDetail}>상세페이지 이동</InteractBtn> */}
              <InteractBtn style={{ color: "red" }} onClick={onDeleteChallenge}>
                챌린지 삭제
              </InteractBtn>
              <InteractBtn onClick={() => setModal(false)}>취소</InteractBtn>
            </>
          ) : (
            <>
              {/* <InteractBtn
                onClick={() => {
                  history.push(routes?.challengeInfo, {
                    id: modalData?.id,
                  });
                }}
              >
                상세페이지 이동
              </InteractBtn> */}
              <InteractBtn onClick={() => alert("신고 가즈아ㅏㅏㅏㅏ")}>
                챌린지 신고
              </InteractBtn>
              <InteractBtn onClick={() => setModal(false)}>취소</InteractBtn>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default MultiChallengeList;
