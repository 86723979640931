//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { GIVENCOUPON_FRAGMENT } from "../fragments/givenCouponFragment";
import { PROGRESS_FRAGMENT } from "../fragments/progressFragment";
import { PTROOM_FRAGMENT } from "../fragments/pTRoomFragment";

export const SEE_MYGYM = gql`
  query SeeMyGym($id: Int) {
    seeMyGym(id: $id) {
      id
      username
      email
      type
      description
      profilePhoto
      realName
      bank
      accountNumber
      birthyear
      birthmonth
      birthday
      gender
      contracts {
        id
      }
      posts {
        id
        title
        description
        isPublic
        comments {
          id
          user {
            id
            username
          }
          text
        }
        assets {
          id
          filepath
        }
        isMine
      }
      groups {
        id
        title
        description
        users {
          id
          username
        }
        gRequests {
          id
          user {
            id
            username
            profilePhoto
          }
        }
      }
      pTRoom {
        ...PTRoomFragment
      }
      isMine
      progresses {
        ...ProgressFragment
      }
      givenCoupons {
        ...GivenCouponFragment
      }
    }
  }
  ${PROGRESS_FRAGMENT}
  ${GIVENCOUPON_FRAGMENT}
  ${PTROOM_FRAGMENT}
`;

export const SeeMyGym = (id) => {
  const { data } = useQuery(SEE_MYGYM, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });
  return data;
};
