//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import ObjRenderer from "./objRenderer";
import React, { useEffect } from "react";
import { SButton } from "../shared/SButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const ObjWrap = styled.div`
  text-align: center;
  padding: 10px;
`;
const Title = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
`;
const Header = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

const Progress = ({ progresses }) => {
  const objRenderers = (progress) => {
    const createdAt = new Date(Number(progress.createdAt))
      .toJSON()
      .match(/[0-9]{1,4}-[0-9]{1,2}-[0-9]{1,2}/);
    const weight = progress?.weight;
    const fatPercentage = progress?.fatPercentage;

    return (
      <ObjWrap key={progress.id}>
        <Header>
          <Title>{createdAt}/</Title>
          <Title>{weight}KG/</Title>
          <Title>{fatPercentage}%</Title>
        </Header>
        <ObjRenderer filepath={progress.avatar} />
        <CopyToClipboard
          text={`/message/viewProgress/${JSON.stringify(progress)}`}
        >
          <SButton context="복사" padding="7px" fontSize="12px" radius="5px" />
        </CopyToClipboard>
      </ObjWrap>
    );
  };
  return (
    <>
      <ProgressContainer>
        {progresses?.length && progresses?.map(objRenderers)}
      </ProgressContainer>
    </>
  );
};

export default Progress;
