//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { CardColumns, Container, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import ChatroomModule from "../../component/chatroom/ChatroomModule";
import { GButton } from "../../component/shared/GButton";
import { CREATE_CUSTOMER_SERVICE_MUTATION } from "../../queries/customerService/createCustomerService";
import { SelectMyProducts } from "../../queries/customerService/selectMyProducts";
import { csTypes } from "../../utils/csTypes";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { useEffect, useRef, useState } from "react";
import { Input } from "@material-ui/core";
import { SelectCSChatRoomByUserId } from "../../queries/customerService/selectCSChatRoomByUserId";
import colors from "../../utils/colors";
import { UPDATE_CUSTOMER_SERVICE_MUTATION } from "../../queries/customerService/updateCustomerService";
import { SELECT_RELATED_PRODUCT_QUERY } from "../../queries/customerService/selectRelatedProduct";
import Profile from "../../component/shared/Profile";
import { dateConvertToCurrent } from "../../utils/date/dateConvertToCurrent";
import zIndex from "@material-ui/core/styles/zIndex";

const CHHeader = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  margin-top: 120px;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
`;
const SubTitle = styled.h3`
  text-align: center;
  margin: 20px 0 40px 0;
  font-weight: 22px !important;
  color: #6b7280;
`;

const ChatRoomCardColumns = styled(CardColumns)`
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 10px;

  @media (max-width: 1200px) {
    width: 100%;
    grid-template-columns: 0fr 1fr;
    column-gap: 0px;
  }
`;

const AnimatedCardColumns = styled(CardColumns)`
  @media (max-width: 1200px) {
    width: 0px;
    position: absolute;
    top: 0px;
    transition: width 0.3s;
  }
  @media (min-width: 1201px) {
    width: 300px !important;
    z-index: 103;
  }
`;

const ChatRoomContainer = styled(CardColumns)`
  position: relative;
  border: 1px solid rgb(215, 215, 215);
  background-color: ${colors.lightGray};
  width: 100%;
  height: 100%;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
`;
const ChatIcon = styled.i`
  position: absolute;
  top: -60px;
  display: none;
  border-radius: 50%;
  background-color: #e4e6eb;
  color: #050505;
  width: 40px;
  height: 40px;
  font-size: 18px;
  padding: 11px;
  margin: 10px;
  cursor: pointer;
  @media (max-width: 1199px) {
    display: block;
  }
  &:hover {
    background-color: #e4e6eb;
    color: ${colors.bbeego};
  }
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 5px -1px gray;
  height: 500px;
  overflow-y: auto;
  background-color: white;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fd780f;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
`;
const Select = styled.select`
  height: 35px;
  outline: none;
  /* width: 100%; */
  /* text-align: center; */
  border: 2px solid lightgray;
  border-radius: 5px;
  /* font-weight: bold; */
  outline: none;
  box-shadow: none;
  padding: 0.325rem 0.9rem;
  /* margin-right: 10px; */
  &:focus {
    box-shadow: none;
    border: none;
    transition: 0.3s;
    border: 2px solid ${colors.bbeego};
  }
`;
const CustomerService = () => {
  //states
  const [isTypeEtc, setIsTypeEtc] = useState(false);
  const [selChatRoomId, setSelChatRoomId] = useState(false);
  const [isFinishedCS, setIsFinishedCS] = useState(false);
  const [modal, setModal] = useState(false);
  const [isCRListConOpen, setIsCRListConOpen] = useState(false);

  //refs
  const typeRef = useRef();
  const contractRef = useRef();
  const etcTypeRef = useRef();
  const titleRef = useRef();
  const crListRef = useRef();
  const listConRef = useRef();

  //내가 계약한 상품 데이터
  const { data: productsList } = SelectMyProducts();

  //내가 생성한 상담 채팅 목록 데이터
  const { data: csChatRooms, refetch: csChatRoomsRefetch } =
    SelectCSChatRoomByUserId();
  //상담 생성
  const onCreateCSCompleted = async (data) => {
    const {
      createCustomerService: { ok, error, id },
    } = data;
    if (ok) {
      await csChatRoomsRefetch();
      await onCSChatRoomClick(id);
    } else {
      if (error === "Customer Service Already Exist.") {
        alert("이미 생성된 상담내역이 존재합니다!");
      } else {
        alert("상담 생성 도중 문제가 발생했습니다. 다시 시도해주세요.");
      }
    }
  };
  const [createCustomerServiceMutation] = useMutation(
    CREATE_CUSTOMER_SERVICE_MUTATION,
    {
      onCompleted: onCreateCSCompleted,
    }
  );

  //상담 완료 또는 삭제
  const onUpdateCSCompleted = (data) => {
    const {
      updateCustomerService: { ok },
    } = data;
    if (ok) {
      csChatRoomsRefetch();
    }
  };
  const [updateCustomerServiceMutation] = useMutation(
    UPDATE_CUSTOMER_SERVICE_MUTATION,
    {
      onCompleted: onUpdateCSCompleted,
    }
  );

  //내가 계약한 상품 단독 정보
  const [selectRelatedProduct, { data: contract }] = useLazyQuery(
    SELECT_RELATED_PRODUCT_QUERY
  );

  //상담 종류 목록 컴포넌트 생성 함수
  const onTypeChange = (e) => {
    if (e.target.value === "기타") {
      setIsTypeEtc(true);
    } else {
      setIsTypeEtc(false);
      if (etcTypeRef?.current) {
        etcTypeRef.current.value = "";
      }
    }
  };
  const csTypeGen = () => {
    return (
      <Select ref={typeRef} onChange={onTypeChange}>
        {csTypes?.map((csType, idx) => (
          <option key={idx}>{csType}</option>
        ))}
      </Select>
    );
  };
  //내가 계약한 상품 목록 컴포넌트 생성 함수
  const productsGen = () => {
    return (
      <Select ref={contractRef}>
        <option>없음</option>
        {productsList?.selectMyProducts?.map((product, idx) => (
          <option
            key={idx}
            data-contracttype={product.contractType}
            data-contractid={product.contractId}
            value={product?.contractId}
          >
            {product?.title}
          </option>
        ))}
      </Select>
    );
  };
  //내가 생성한 상담 채팅 목록 컴포넌트 생성 함수
  const onCSChatRoomClick = (chatRoomId) => {
    setSelChatRoomId(chatRoomId);
    const list = crListRef?.current?.querySelectorAll("div");
    list.forEach((el) => {
      if (Number(el?.id) === Number(chatRoomId)) {
        el.style.backgroundColor = `${colors.listBackground}`;
        el.style.color = `${colors.bbeego}`;
      } else {
        el.style.backgroundColor = "white";
        el.style.color = "black";
      }
    });
  };
  const csChatRoomListGen = () => {
    return (
      <List ref={crListRef}>
        {csChatRooms?.selectCSChatRoomByUserId?.length ? (
          csChatRooms?.selectCSChatRoomByUserId?.map((cr) => (
            <CardColumns
              style={{
                padding: "15px",
                cursor: "pointer",
              }}
              onClick={() => onCSChatRoomClick(cr?.chatRoom?.id)}
              key={cr?.chatRoom?.id}
              id={cr?.chatRoom?.id}
            >
              <span
                style={{
                  lineHeight: "1.5rem",
                }}
              >
                <span>[{cr?.type}]</span> <span>{cr?.title}</span>
              </span>
            </CardColumns>
          ))
        ) : (
          <CardColumns
            style={{
              width: "100%",
              height: "100%",
              padding: "20px",
              // color: "gray",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: `${colors.bbeegoText}`,
            }}
          >
            <i className="bi bi-chat-right-text"></i>
            상담을 생성해보세요.
          </CardColumns>
        )}
      </List>
    );
  };

  //상담 생성 이벤트
  const onCSCreateClick = () => {
    const selOption = Array.from(contractRef?.current?.childNodes)?.filter(
      (cn) => cn?.value === contractRef?.current?.value
    );
    const title = titleRef?.current?.querySelector("input")?.value;
    const type = typeRef?.current?.value;
    const etcType = etcTypeRef?.current?.querySelector("input")?.value;
    const { contracttype: contractType, contractid: contractId } =
      selOption?.[0]?.dataset;

    if (!title) {
      return alert("제목을 입력해주세요!");
    }

    let createConfirm = null;
    if (!contractType && !contractId) {
      createConfirm = window.confirm(
        "선택된 관련 상품이 없습니다. 진행하시겠습니까?"
      );
    } else {
      createConfirm = window.confirm("상담을 시작하시겠습니까?");
    }

    if (!!createConfirm) {
      createCustomerServiceMutation({
        variables: {
          title,
          type,
          etcType,
          contractType,
          contractId: Number(contractId),
        },
      });
    }
  };

  //선택한 상담 완료하기 버튼 클릭시
  const onUpdateCS = (type) => {
    const selCS = csChatRooms?.selectCSChatRoomByUserId?.filter(
      (csc) => csc?.chatRoom?.id === selChatRoomId
    )?.[0];
    const confirmBool =
      type === "finish"
        ? window.confirm("상담을 완료하시겠습니까?")
        : type === "delete"
        ? window.confirm("상담을 삭제하시겠습니까?")
        : null;
    if (!!confirmBool) {
      updateCustomerServiceMutation({
        variables: {
          id: selCS?.id,
          type,
        },
      });
      if (type === "delete") {
        setSelChatRoomId(false);
      }
    }
  };

  //관련 상품 확인하기 버튼 클릭시
  const onCheckClick = async () => {
    setModal(true);
    const selOption = Array.from(contractRef?.current?.childNodes)?.filter(
      (cn) => cn?.value === contractRef?.current?.value
    );
    if (!!Object.keys(selOption?.[0]?.dataset)?.length) {
      const { contracttype: contractType, contractid: contractId } =
        selOption?.[0]?.dataset;
      selectRelatedProduct({
        variables: {
          contractType,
          contractId: Number(contractId),
        },
      });
    } else {
      selectRelatedProduct({
        variables: {
          contractType: "",
          contractId: 0,
        },
      });
    }
  };

  //화면 작아질 시 생기는 말풍선 아이콘 클릭시
  const onChatIconClick = () => {
    setIsCRListConOpen(!isCRListConOpen);
  };
  useEffect(() => {
    if (!!isCRListConOpen) {
      listConRef.current.style.width = "300px";
    } else {
      listConRef.current.style.width = "0px";
    }
  }, [isCRListConOpen]);

  //modal 내용 생성 함수
  const modalInfoGen = () => {
    if (!!contract?.selectRelatedProduct) {
      switch (contract?.selectRelatedProduct?.__typename) {
        case "Contract":
          return (
            <CardColumns
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                rowGap: "10px",
                alignItems: "center",
              }}
            >
              <CardColumns>상품명 : </CardColumns>
              <CardColumns>
                {contract?.selectRelatedProduct?.pTPrice?.pTRoom?.title}
              </CardColumns>
              <CardColumns>상품 종류 : </CardColumns>
              <CardColumns>퍼스널 트레이닝</CardColumns>
              <CardColumns>트레이너 : </CardColumns>
              <CardColumns>
                <Profile
                  src={contract?.selectRelatedProduct?.trainer?.profilePhoto}
                  id={contract?.selectRelatedProduct?.trainer?.id}
                  username={contract?.selectRelatedProduct?.trainer?.username}
                />
              </CardColumns>
              <CardColumns>기간 : </CardColumns>
              <CardColumns>
                {contract?.selectRelatedProduct?.pTPrice?.month} 개월
              </CardColumns>
              <CardColumns>가격 : </CardColumns>
              <CardColumns>
                {contract?.selectRelatedProduct?.pTPrice?.price} 만원
              </CardColumns>
              <CardColumns>시작 날짜 : </CardColumns>
              <CardColumns>
                {dateConvertToCurrent(
                  Number(contract?.selectRelatedProduct?.startDate)
                )}
              </CardColumns>
              <CardColumns>종료 날짜 : </CardColumns>
              <CardColumns>
                {
                  new Date(Number(contract?.selectRelatedProduct?.endDate))
                    .toJSON()
                    ?.split("T")?.[0]
                }
              </CardColumns>
            </CardColumns>
          );
        case "MultiChallengeContract":
          const myTrainer =
            contract?.selectRelatedProduct?.multiChallenge?.multiChallengeGroups?.reduce(
              (acc, mg) => {
                const myGroup = mg?.multiChallengeGroupMappers?.filter(
                  (mgm) =>
                    mgm?.user?.id === contract?.selectRelatedProduct?.user?.id
                );
                if (myGroup?.length) {
                  acc = mg?.multiChallengeGroupMappers?.filter(
                    (mgm) => mgm?.type === "trainer"
                  )?.[0]?.user;
                }
                return acc;
              },
              {}
            );
          return (
            <CardColumns
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr",
                rowGap: "10px",
                alignItems: "center",
              }}
            >
              <CardColumns>상품명 : </CardColumns>
              <CardColumns>
                {contract?.selectRelatedProduct?.multiChallenge?.title}
              </CardColumns>
              <CardColumns>상품 종류 : </CardColumns>
              <CardColumns>챌린지</CardColumns>
              <CardColumns>트레이너 : </CardColumns>
              <CardColumns>
                <Profile
                  src={myTrainer?.profilePhoto}
                  id={myTrainer?.id}
                  username={myTrainer?.username}
                />
              </CardColumns>
              <CardColumns>기본금 : </CardColumns>
              <CardColumns>
                {contract?.selectRelatedProduct?.multiChallenge?.baseprice} 만원
              </CardColumns>
              <CardColumns>보상금 : </CardColumns>
              <CardColumns>
                {contract?.selectRelatedProduct?.multiChallenge?.bonus} 만원
              </CardColumns>
              <CardColumns>리워드 : </CardColumns>
              <CardColumns>
                {contract?.selectRelatedProduct?.multiChallenge?.reward} 만원
              </CardColumns>
              <CardColumns>시작 날짜 : </CardColumns>
              <CardColumns>
                {dateConvertToCurrent(
                  Number(
                    contract?.selectRelatedProduct?.multiChallenge?.startDate
                  )
                )}
              </CardColumns>
              <CardColumns>종료 날짜 : </CardColumns>
              <CardColumns>
                {dateConvertToCurrent(
                  Number(
                    contract?.selectRelatedProduct?.multiChallenge?.endDate
                  )
                )}
              </CardColumns>
            </CardColumns>
          );
      }
    }
  };

  useEffect(() => {
    if (!!selChatRoomId) {
      const cs = csChatRooms?.selectCSChatRoomByUserId?.filter(
        (csc) => csc?.chatRoom?.id === selChatRoomId
      )?.[0];
      if (!!cs?.finishedAt) {
        setIsFinishedCS(true);
      } else {
        setIsFinishedCS(false);
      }
    }
  }, [selChatRoomId]);

  useEffect(() => {
    if (!!contract) {
    }
  }, [contract]);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        minHeight: "1000px",
        maxWidth: "1140px",
      }}
    >
      <Row style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <CHHeader>
          <Title>고객 센터</Title>
          <SubTitle>
            BBEEGO 고객 센터에서는 유저들의 불만사항이나 문제점들을 상품별로
            선택하여
            <br />
            채팅을 통해 접수합니다.
          </SubTitle>
        </CHHeader>
      </Row>
      <Row
        style={{
          backgroundColor: "rgb(250,250,252)",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <CardColumns
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <CardColumns
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              minHeight: "32px",
            }}
          >
            <CardColumns>{csTypeGen()}</CardColumns>
            {!!isTypeEtc ? (
              <CardColumns>
                <Input
                  type="text"
                  ref={etcTypeRef}
                  placeholder="기타 사항 입력"
                />
              </CardColumns>
            ) : null}
          </CardColumns>
          <CardColumns>
            <Input type="text" placeholder="제목" ref={titleRef} />
          </CardColumns>
          <CardColumns
            style={{ display: "flex", flexDirection: "column", gap: "5px" }}
          >
            <CardColumns style={{ fontSize: "12px" }}>관련 상품</CardColumns>
            <CardColumns
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <CardColumns>{productsGen()}</CardColumns>
              <CardColumns>
                <GButton
                  variant="create"
                  context="확인하기"
                  height="34px"
                  fontSize="14px"
                  paddi300pxng="0.475rem 0.75rem"
                  // fontWeight="600"
                  onClick={onCheckClick}
                />
              </CardColumns>
            </CardColumns>
          </CardColumns>
          <CardColumns>
            <GButton
              variant="create"
              context="상담 생성"
              height="34px"
              fontSize="14px"
              padding="0.475rem 0.75rem"
              onClick={onCSCreateClick}
            />
            {/* <GButton context="refresh" onClock={() => csChatRoomsRefetch} /> */}
          </CardColumns>
        </CardColumns>
      </Row>
      <Row style={{ height: "2px", backgroundColor: "#ebeef4" }}></Row>
      <Row>
        <ChatRoomCardColumns>
          <CardColumns
            style={{
              gridColumn: "span 2",
              justifySelf: "flex-end",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              minHeight: "32px",
              gap: "10px",
            }}
          >
            {!!selChatRoomId &&
            csChatRooms?.selectCSChatRoomByUserId?.filter(
              (csc) =>
                csc?.chatRoom?.id === selChatRoomId && csc?.finishedAt === null
            )?.length ? (
              <>
                <CardColumns
                  style={{
                    color: colors.danger,
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  해당 상담의 삭제를 희망하실 경우 먼저 상담을 완료해주세요.
                </CardColumns>
                <GButton
                  variant="create"
                  context="선택한 상담 완료하기"
                  width="fit-content"
                  onClick={() => onUpdateCS("finish")}
                />
              </>
            ) : csChatRooms?.selectCSChatRoomByUserId?.filter(
                (csc) =>
                  csc?.chatRoom?.id === selChatRoomId &&
                  csc?.finishedAt !== null &&
                  csc?.deletedAt === null
              )?.length ? (
              <GButton
                variant="delete"
                context="선택한 상담 삭제하기"
                onClick={() => onUpdateCS("delete")}
              />
            ) : null}
          </CardColumns>
          <CardColumns style={{ position: "relative" }}>
            <AnimatedCardColumns ref={listConRef}>
              {csChatRoomListGen()}
            </AnimatedCardColumns>
            <ChatIcon
              onClick={onChatIconClick}
              className="bi bi-chat-dots-fill"
            />
          </CardColumns>
          <ChatRoomContainer>
            {!!selChatRoomId ? (
              <CardColumns
                style={{
                  height: "100%",
                  minHeight: "500px",
                  boxShadow: "0 0 5px -1px gray",
                }}
              >
                <ChatroomModule id={selChatRoomId} isBlock={isFinishedCS} />
              </CardColumns>
            ) : null}{" "}
          </ChatRoomContainer>
        </ChatRoomCardColumns>
      </Row>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header>
          <Modal.Title>관련 상품</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalInfoGen()}</Modal.Body>
      </Modal>
    </Container>
  );
};

export default CustomerService;
