//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//mygym 의 챌린지 탭에서 볼 수 있는 챌린지를 컴포넌트화 합니다.

import { Card, CardColumns, CardGroup, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/routes";
import CheckUserToken from "../../utils/checkUserToken";
import { GButton } from "../shared/GButton";
import ProfilePhoto from "../shared/ProfilePhoto";

const SCard = styled.div`
  /* margin: 10px;  */
  width: 98%;
  margin: 0 auto;
  border-bottom: 2px solid #ebeef4;
  /* box-shadow: 0 0 5px -1px gray; */
`;
const SCardBody = styled(Card.Body)``;
const SCardGroup = styled(CardGroup)`
  display: grid;
  grid-template-columns: 0.5fr 0.8fr 0.7fr 0.5fr;
  @media (max-width: 1320px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;
const SCardColumns = styled(CardColumns)`
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SCardColumnsSpan = styled(CardColumns)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 550px) {
    grid-column: span 2;
    justify-self: center;
  }
`;
const SImage = styled(Image)`
  width: ${(props) => (props?.width ? props?.width : "")};
  height: ${(props) => (props?.height ? props?.height : "")};
  border-radius: ${(props) => (props?.borderradius ? props?.borderradius : "")};
  object-fit: ${(props) => (props?.objectfit ? props?.objectfit : "")};
  object-position: ${(props) =>
    props?.objectposition ? props?.objectposition : ""};
  @media (max-width: 770px) {
    width: 100px;
    height: 100px;
  }
`;
const ChallengeInfo = styled(SCardColumns)`
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  height: 100%;
  align-items: center;
  text-align: center;
  gap: 5px;
  h5 {
    display: block;
    font-weight: bold;
    font-size: 18px;
    @media (max-width: 550px) {
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
const ChallengeMembers = styled(SCardColumns)`
  display: grid;
  grid-template-columns: repeat(3, auto);
  height: 100%;
  align-items: center;
  text-align: center;
  gap: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  div {
    font-weight: bold;
    font-size: 16px;
  }
`;
const ButtonCon = styled(SCardColumns)`
  display: flex;
  flex-direction: column;
  justify-content: right;
  gap: 10px;
  @media (max-width: 1320px) {
    justify-content: center;
  }
  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

const ContractedMultiChallengeBanner = ({ multiChallengeContract }) => {
  const history = useHistory();
  const me = CheckUserToken();
  return (
    <SCard>
      <SCardBody>
        <SCardGroup>
          <SCardColumns>
            <SImage
              src={multiChallengeContract?.contract?.multiChallenge?.thumbnail}
              width="250px"
              height="250px"
              borderradius="20px"
            />
          </SCardColumns>
          <SCardColumns>
            <ChallengeInfo>
              <h5>{multiChallengeContract?.contract?.multiChallenge?.title}</h5>
              <div>
                {/* <SImage
                  src={
                    multiChallengeContract?.contract?.multiChallenge?.user
                      ?.profilePhoto
                      ? multiChallengeContract?.contract?.multiChallenge?.user
                          ?.profilePhoto
                      : ""
                  }
                  width="200px"
                  height="200px"
                  borderradius="100px"
                  objectfit="cover"
                  objectposition="left top"
                  onError={(e) =>
                    (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
                  }
                /> */}
                <ProfilePhoto
                  src={
                    multiChallengeContract?.contract?.multiChallenge?.user
                      ?.profilePhoto
                  }
                  {...multiChallengeContract?.contract?.multiChallenge?.user}
                  width="200px"
                  height="200px"
                  isRes={true}
                />
              </div>
              <h5>
                {
                  multiChallengeContract?.contract?.multiChallenge?.user
                    ?.username
                }
              </h5>
            </ChallengeInfo>
          </SCardColumns>
          <SCardColumnsSpan>
            <ChallengeMembers>
              <div>총 트레이너 수</div>
              <div>:</div>
              <div>
                {
                  multiChallengeContract?.contract?.multiChallenge?.multiChallengeContracts?.filter(
                    (mcc) => mcc.type === "trainer" && mcc.accepted === true
                  )?.length
                }{" "}
                /{" "}
                {multiChallengeContract?.contract?.multiChallenge?.trainerCount}
              </div>
              <div>총 유저 수</div>
              <div>:</div>
              <div>
                {
                  multiChallengeContract?.contract?.multiChallenge?.multiChallengeContracts?.filter(
                    (mcc) => mcc.type === "trainee" && mcc.accepted === true
                  )?.length
                }{" "}
                /{" "}
                {multiChallengeContract?.contract?.multiChallenge
                  ?.trainerCount *
                  multiChallengeContract?.contract?.multiChallenge
                    ?.userCountPerTrainer}
              </div>
              <div>총 참여자 수</div>
              <div>:</div>
              <div>
                {
                  multiChallengeContract?.contract?.multiChallenge?.multiChallengeContracts?.filter(
                    (mcc) => mcc.accepted === true
                  )?.length
                }{" "}
                /{" "}
                {multiChallengeContract?.contract?.multiChallenge
                  ?.trainerCount *
                  multiChallengeContract?.contract?.multiChallenge
                    ?.userCountPerTrainer +
                  multiChallengeContract?.contract?.multiChallenge
                    ?.trainerCount}
              </div>
              {multiChallengeContract?.contract?.accepted === true ? (
                <>
                  <div>그룹 트레이너</div>
                  <div>:</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <ProfilePhoto
                      src={
                        multiChallengeContract?.group?.multiChallengeGroupMappers?.filter(
                          (gm) => gm.type === "trainer"
                        )?.[0]?.user?.profilePhoto
                      }
                      {...multiChallengeContract?.group?.multiChallengeGroupMappers?.filter(
                        (gm) => gm.type === "trainer"
                      )?.[0]?.user}
                      width="30px"
                      height="30px"
                    />
                    <div style={{ fontSize: "16px" }}>
                      {
                        multiChallengeContract?.group?.multiChallengeGroupMappers?.filter(
                          (gm) => gm.type === "trainer"
                        )?.[0]?.user?.username
                      }
                    </div>
                  </div>
                  <div>내 그룹 유저 수</div>
                  <div>:</div>
                  <div>
                    {
                      multiChallengeContract?.group?.multiChallengeGroupMappers?.filter(
                        (gm) => gm.type !== "trainer"
                      )?.length
                    }{" "}
                    /{" "}
                    {
                      multiChallengeContract?.contract?.multiChallenge
                        ?.userCountPerTrainer
                    }
                  </div>
                </>
              ) : null}
            </ChallengeMembers>
          </SCardColumnsSpan>
          <SCardColumnsSpan>
            <ButtonCon>
              <GButton
                variant="create"
                context="챌린지 상세"
                width="175px"
                onClick={() =>
                  history.push(routes.multiChallengeInfo, {
                    id: multiChallengeContract?.contract?.multiChallenge?.id,
                  })
                }
              />
              {multiChallengeContract?.group?.multiChallengeGroupMappers?.filter(
                (mgm) => mgm?.type === "trainer"
              )?.[0]?.user?.id === me?.me?.id ? (
                <GButton
                  variant="read"
                  context="그룹 관리"
                  width="175px"
                  onClick={() =>
                    history.push(routes.multiChallengeGroupManagement, {
                      id: multiChallengeContract?.group?.id,
                    })
                  }
                />
              ) : null}
              {multiChallengeContract?.contract?.type === "trainee" ? (
                <GButton
                  variant="read"
                  context="내 챌린지 관리"
                  width="175px"
                  onClick={() =>
                    history.push(routes.multiChallengeUserManagement, {
                      id: multiChallengeContract?.group?.multiChallengeGroupMappers?.filter(
                        (mgm) => mgm?.user?.id === me?.me?.id
                      )?.[0]?.id,
                    })
                  }
                />
              ) : null}
              {multiChallengeContract?.contract?.accepted === true ? (
                <GButton
                  variant="update"
                  context="채팅룸으로 이동"
                  width="175px"
                  onClick={() =>
                    history.push(routes.message, {
                      id: multiChallengeContract?.group?.chatroom?.id,
                    })
                  }
                />
              ) : (
                <GButton
                  variant="update"
                  context="승인 대기중"
                  width="175px"
                  disabled={true}
                />
              )}
            </ButtonCon>
          </SCardColumnsSpan>
        </SCardGroup>
      </SCardBody>
    </SCard>
  );
};

export default ContractedMultiChallengeBanner;
