//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import colors from "../../utils/colors";

export const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*height: fit-content;*/
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  gap: 10px;
  border-radius: 25px;
  overflow: hidden;
`;

export const Form = styled.form``;

export const Input = styled.input`
  margin: 2px 0;
  padding: 5px;
  width: 100%;
  border: 0;
  border-bottom: 0.5px solid white;
  border-radius: 0px;
  box-shadow: 0 0 0 50px ${colors.bbeego} inset;
  color: white;
  -webkit-text-fill-color: white;
  outline: none;
  &:focus {
    -webkit-text-fill-color: white;
    box-shadow: 0 0 0 50px ${colors.bbeego} inset;
  }
  &:invalid {
    background: ${colors.bbeego};
    color: white;
    -webkit-text-fill-color: white;
  }
  &:valid {
    background: ${colors.bbeego};
    color: white;
    -webkit-text-fill-color: white;
  }
  &:-webkit-autofill {
    background: ${colors.bbeego};
    -webkit-box-shadow-: 0 0 0 50px ${colors.bbeego} inset;
    -webkit-text-fill-color: white;
  }
`;
//${(props) => (props.error ? "tomato" : "rgb(219, 219, 219)")};
export const Submit = styled.input`
  margin-top: 20px;
  padding: 5px;
  width: 100%;
  border: none;
  border-radius: 3px;
  background-color: white;
  color: ${colors.bbeego};
  font-weight: 600;
  opacity: ${(props) => (props.hasError ? "0.5" : "1")};
`;

export const Cancle = styled.input`
  margin-top: 20px;
  padding: 5px;
  width: 100%;
  height: 26px;
  border: none;
  border-radius: 3px;
  background-color: red;
  color: white;
  font-weight: 600;
  opacity: ${(props) => (props.hasError ? "0.5" : "1")};
  text-align: center;
`;

export const SLogo = styled.img`
  width: 150px;
  margin-bottom: 10px;
`;
export const Container = styled.div`
  width: 100%;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 3px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
export const HeaderContainer = styled(Container)`
  background: ${colors.bbeego};
  border: 0;
  flex-direction: column;
  gap: 10px;
`;
export const BottomContainer = styled(Container)`
  background: ${colors.bbeego};
  border: 0;
  color: white;
  a,
  div > a {
    margin-left: 5px;
    color: white;
    font-weight: 600;
  }
`;

export const Span = styled.span`
  font-size: 12px;
`;

export const Select = styled.select`
  margin: 2px 0;
  padding: 5px;
  width: 100%;
  color: ${colors.bbeego};
  border: 0.5px solid
    ${(props) => (props.error ? "tomato" : "rgb(219, 219, 219)")};
  border-radius: 3px;
  outline: none;
  &:focus {
    border: 0.5px solid black;
  }
`;
