import { gql, useQuery } from "@apollo/client";

export const SELECT_MY_PRODUCTS_QUERY = gql`
  query SelectMyProducts {
    selectMyProducts {
      contractId
      contractType
      title
      trainer {
        id
        username
        profilePhoto
        type
      }
    }
  }
`;

export const SelectMyProducts = () => {
  const { data } = useQuery(SELECT_MY_PRODUCTS_QUERY);
  return { data };
};
