//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
export const SInput = styled.input`
  width: ${(props) => (props?.width ? props?.width : "")};
  height: ${(props) => (props?.height ? props?.height : "")};
  padding: ${(props) => (props?.padding ? props?.padding : "")};
  font-size: ${(props) => (props?.fontSize ? props?.fontSize : "")};
  border: ${(props) => (props?.border ? props?.border : "")};
  border-top: ${(props) => (props?.borderTop ? props?.borderTop : "")};
  border-bottom: ${(props) => (props?.borderBottom ? props?.borderBottom : "")};
  border-left: ${(props) => (props?.borderLeft ? props?.borderLeft : "")};
  border-right: ${(props) => (props?.borderRight ? props?.borderRight : "")};
  outline: ${(props) => (props?.outline ? props?.outline : "")};
`;

const InputLayout = (props) => {
  return <SInput {...props} />;
};

export default InputLayout;
