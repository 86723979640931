//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";

const SImage = styled.img`
  width: ${(props) => (props?.width ? props.width : "")};
  height: ${(props) => (props?.height ? props.height : "")};
  border: ${(props) => (props?.border ? props.border : "")};
  border-radius: ${(props) => (props?.borderRadius ? props.borderRadius : "")};
  margin: ${(props) => (props?.margin ? props.margin : "")};
  max-width: ${(props) => (props?.maxWidth ? props.maxWidth : "")};
  min-width: ${(props) => (props?.minWidth ? props.minWidth : "")};
  ${(props) =>
    props.variant === "chat"
      ? `
      max-width:250px;
      @media (max-width: 500px) {
        max-width:70%;}
  `
      : ""}
`;

const ImageLayout = (props) => {
  return (
    <SImage
      {...props}
      onError={(e) =>
        (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
      }
      loading="lazy"
    />
  );
};

export default ImageLayout;
