//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useRef } from "react";
import styled from "styled-components";
import * as THREE from "three";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 466px;
  height: 500px;
`;
const BtnCon = styled.div`
  display: flex;
  width: 250px;
  padding: 10px;
  justify-content: space-between;
`;
const RotateBtn = styled.button`
  margin: 10px;
  background-color: rgba(0, 255, 255, 1);
  padding: 6px 12px;
  border-radius: 5px;
`;
const BodyShape = ({ scanfilepath }) => {
  let rotate_id = 0;

  let _object = null;
  let spin = false;
  const canvas = useRef();
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(50, 466 / 500, 0.1, 200);
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  //const controls = new OrbitControls(camera, renderer.domElement);
  let light1 = new THREE.DirectionalLight();
  let light2 = new THREE.DirectionalLight();
  light1.position.set(0, 0, 1);
  light2.position.set(0, 0, -1);
  scene.add(light1);
  scene.add(light2);

  camera.position.z = 2.5;
  //controls.update();
  useEffect(() => {
    const loader = new OBJLoader();
    loader.load(
      scanfilepath,
      function (object) {
        _object = object;
        scene.add(object);
        scene.rotateY(6.2);
      },
      function (xhr) {},
      function (error) {}
    );
    //renderer.setSize(window.innerWidth, window.innerHeight);
    // renderer.setSize(
    //   canvas?.current?.clientWidth,
    //   canvas?.current?.clientHeight
    // );
    renderer.setSize(466, 450);
    camera.aspect = canvas?.current.clientWidth / canvas?.current.clientHeight;
    camera.updateProjectionMatrix();
    renderer.render(scene, camera);
    canvas?.current.appendChild(renderer.domElement);
  }, []);
  const rotate = () => {
    rotate_id = requestAnimationFrame(rotate);

    _object.rotation.y += 0.01;
    renderer.render(scene, camera);
  };
  const onRotateClick = () => {
    spin = !spin;
    if (!!spin) {
      rotate();
    } else {
      cancelAnimationFrame(rotate_id);
    }
  };
  const animate = () => {
    requestAnimationFrame(animate);
    //controls.update();
    renderer.render(scene, camera);
  };
  useEffect(() => {
    animate();
  });
  return (
    <Container ref={canvas}>
      <RotateBtn onClick={onRotateClick}>Rotate</RotateBtn>
    </Container>
  );
};

export default BodyShape;
