//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import { FormControlLabel, Input, Radio, RadioGroup } from "@material-ui/core";
import { SInput } from "../component/shared/InputLayout";
import { useEffect } from "react";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const QuestionCon = styled.div``;
const Question = styled.div`
  font-weight: bold;
  color: ${(props) => (props?.color ? props?.color : "")};
`;
const Answer = styled.div``;
const QuestionSet = ({ register, setValue, formState, clearErrors }) => {
  useEffect(() => {
    register("q1", { required: { value: true, message: "q1 Required." } });
    register("q2", { required: { value: true, message: "q2 Required." } });
    register("q3", { required: { value: true, message: "q3 Required." } });
    register("q4", { required: { value: true, message: "q4 Required." } });
    register("q5", { required: { value: true, message: "q5 Required." } });
    register("q6", { required: { value: true, message: "q6 Required." } });
    register("q7", { required: { value: true, message: "q7 Required." } });
    register("q8", { required: { value: true, message: "q8 Required." } });
    register("q9", { required: { value: true, message: "q9 Required." } });
    register("q10", { required: { value: true, message: "q10 Required." } });
  }, []);
  return (
    <Container>
      <QuestionCon>
        <Question color={formState?.errors?.q1 ? "red" : ""}>
          1. 퍼스널 트레이너, 건강관리사 등 관련 자격증을 소유하고 있습니까?
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" />}
              label="예"
              onClick={(e) => {
                e.target.checked && setValue("q1", "yes");
                clearErrors("q1");
              }}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label="아니오"
              onClick={(e) => {
                e.target.checked && setValue("q1", "no");
                clearErrors("q1");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q2 ? "red" : ""}>
          2. 현재 건강 관련 직업에 종사하고 있습니까?
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="yes"
              control={<Radio color="primary" />}
              label="예"
              onClick={(e) => {
                e.target.checked && setValue("q2", "yes");
                clearErrors("q2");
              }}
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label="아니오"
              onClick={(e) => {
                e.target.checked && setValue("q2", "no");
                clearErrors("q2");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q3 ? "red" : ""}>
          3. 건강 관련 직업 경력을 기입해주세요.
        </Question>
        <Answer>
          <SInput
            type="number"
            min="0"
            outline="none"
            border="none"
            borderBottom="1px solid gray"
            padding="5px"
            onChange={(e) => {
              setValue("q3", e.target.value);
              clearErrors("q3");
            }}
          />
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q4 ? "red" : ""}>
          4. 다음 중 가슴 운동이 아닌 것을 고르세요.
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="벤치 프레스"
              onClick={(e) => {
                e.target.checked && setValue("q4", "0");
                clearErrors("q4");
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="인클라인 덤벨 프레스"
              onClick={(e) => {
                e.target.checked && setValue("q4", "1");
                clearErrors("q4");
              }}
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="오버헤드 덤벨 프레스"
              onClick={(e) => {
                e.target.checked && setValue("q4", "2");
                clearErrors("q4");
              }}
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="푸쉬업"
              onClick={(e) => {
                e.target.checked && setValue("q4", "3");
                clearErrors("q4");
              }}
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label="딥스"
              onClick={(e) => {
                e.target.checked && setValue("q4", "4");
                clearErrors("q4");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q5 ? "red" : ""}>
          5. 다음 중 등 운동이 아닌 것을 고르세요.
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="바벨 로우"
              onClick={(e) => {
                e.target.checked && setValue("q5", "0");
                clearErrors("q5");
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="원암 덤벨 로우"
              onClick={(e) => {
                e.target.checked && setValue("q5", "1");
                clearErrors("q5");
              }}
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="펜들레이 로우"
              onClick={(e) => {
                e.target.checked && setValue("q5", "2");
                clearErrors("q5");
              }}
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="라잉 트라이셉스 익스텐션"
              onClick={(e) => {
                e.target.checked && setValue("q5", "3");
                clearErrors("q5");
              }}
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label="풀업"
              onClick={(e) => {
                e.target.checked && setValue("q5", "4");
                clearErrors("q5");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q6 ? "red" : ""}>
          6. 다음 중 하체 운동이 아닌 것을 고르세요.
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="얼터네이트 덤벨 컬"
              onClick={(e) => {
                e.target.checked && setValue("q6", "0");
                clearErrors("q6");
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="레그 컬"
              onClick={(e) => {
                e.target.checked && setValue("q6", "1");
                clearErrors("q6");
              }}
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="스쿼트"
              onClick={(e) => {
                e.target.checked && setValue("q6", "2");
                clearErrors("q6");
              }}
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="런지"
              onClick={(e) => {
                e.target.checked && setValue("q6", "3");
                clearErrors("q6");
              }}
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label="레그 익스텐션"
              onClick={(e) => {
                e.target.checked && setValue("q6", "4");
                clearErrors("q6");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q7 ? "red" : ""}>
          7. 다음 중 삼대 운동에 포함되지 않은 운동을 고르세요.
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="벤치 프레스"
              onClick={(e) => {
                e.target.checked && setValue("q7", "0");
                clearErrors("q7");
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="오버헤드 바벨 프레스"
              onClick={(e) => {
                e.target.checked && setValue("q7", "1");
                clearErrors("q7");
              }}
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="스쿼트"
              onClick={(e) => {
                e.target.checked && setValue("q7", "2");
                clearErrors("q7");
              }}
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="데드 리프트"
              onClick={(e) => {
                e.target.checked && setValue("q7", "3");
                clearErrors("q7");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q8 ? "red" : ""}>
          8. 다음 중 올바른 내용을 고르세요.
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="벤치프레스는 하체 강화에 효율적인 운동이다."
              onClick={(e) => {
                e.target.checked && setValue("q8", "0");
                clearErrors("q8");
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="대흉근은 신체에서 큰 근육중 하나로 대둔근 보다 크다."
              onClick={(e) => {
                e.target.checked && setValue("q8", "1");
                clearErrors("q8");
              }}
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="풀업의 수행능력은 삼두근 강화로 발전시킬 수 있다."
              onClick={(e) => {
                e.target.checked && setValue("q8", "2");
                clearErrors("q8");
              }}
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="데드리프트 수행시 허리 및 코어 근육은 최대한 이완시킨 후 진행해야 한다."
              onClick={(e) => {
                e.target.checked && setValue("q8", "3");
                clearErrors("q8");
              }}
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label="벤트오버 레터럴 레이즈를 통해 후면 삼각근의 성장을 기대할 수 있다."
              onClick={(e) => {
                e.target.checked && setValue("q8", "4");
                clearErrors("q8");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q9 ? "red" : ""}>
          9. 다음 중 올바르지 않은 내용을 고르세요.
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="근성장을 위해서는 단백질이 충분히 포함된 식단을 해야한다."
              onClick={(e) => {
                e.target.checked && setValue("q9", "0");
                clearErrors("q9");
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="근성장을 위해서는 충분한 수면을 해야한다."
              onClick={(e) => {
                e.target.checked && setValue("q9", "1");
                clearErrors("q9");
              }}
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="무릎관절은 회전근개로 운동전 충분한 스트레칭이 필요하다."
              onClick={(e) => {
                e.target.checked && setValue("q9", "2");
                clearErrors("q9");
              }}
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="승모근은 등세모근이라고도 불린다."
              onClick={(e) => {
                e.target.checked && setValue("q9", "3");
                clearErrors("q9");
              }}
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label="레그레이즈를 통해 코어근육의 강화를 기대할 수 있다."
              onClick={(e) => {
                e.target.checked && setValue("q9", "4");
                clearErrors("q9");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
      <QuestionCon>
        <Question color={formState?.errors?.q10 ? "red" : ""}>
          10. 다음 중 올바른 내용을 고르세요.
        </Question>
        <Answer>
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label="해머 컬을 통해 전면 삼각근의 강화를 기대할 수 있다."
              onClick={(e) => {
                e.target.checked && setValue("q10", "0");
                clearErrors("q10");
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="후면 삼각근은 어깨근육의 가장 많은 비중을 차지한다."
              onClick={(e) => {
                e.target.checked && setValue("q10", "1");
                clearErrors("q10");
              }}
            />
            <FormControlLabel
              value="2"
              control={<Radio color="primary" />}
              label="덤벨 킥백을 통해 삼두근 강화를 기대할 수 있다."
              onClick={(e) => {
                e.target.checked && setValue("q10", "2");
                clearErrors("q10");
              }}
            />
            <FormControlLabel
              value="3"
              control={<Radio color="primary" />}
              label="체지방 감량에 가장 좋은 방법은 금식이다."
              onClick={(e) => {
                e.target.checked && setValue("q10", "3");
                clearErrors("q10");
              }}
            />
            <FormControlLabel
              value="4"
              control={<Radio color="primary" />}
              label="삼대영양소 중 열량이 가장 높은 영양소는 탄수화물이다."
              onClick={(e) => {
                e.target.checked && setValue("q10", "4");
                clearErrors("q10");
              }}
            />
          </RadioGroup>
        </Answer>
      </QuestionCon>
    </Container>
  );
};

export default QuestionSet;
