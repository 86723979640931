//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const SEE_CHALLENGE_QUERY = gql`
  query SeeChallenge($id: Int!) {
    seeChallenge(id: $id) {
      id
      title
      description
      user {
        id
        username
        profilePhoto
      }
      trainer {
        id
        username
        profilePhoto
      }
      trainee {
        id
        username
        profilePhoto
      }
      baseprice
      bonus
      reward
      reward
      currentWeight
      targetWeight
      currentFatPercentage
      targetFatPercentage
      startDate
      endDate
      age
      gender
      isMine
      term
      challengeContracts {
        id
        trainer {
          id
          username
          profilePhoto
        }
        accepted
        createdAt
      }
      chatroom {
        id
      }
      createdAt
    }
  }
`;

export const SeeChallenge = (id) => {
  const { data } = useQuery(SEE_CHALLENGE_QUERY, {
    variables: {
      id,
    },
  });
  return data;
};
