//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const SEE_CHALLENGES = gql`
  query SeeChallenges($take: Int, $lastId: Int) {
    seeChallenges(take: $take, lastId: $lastId) {
      id
      title
      description
      user {
        id
        username
        profilePhoto
        type
      }
      age
      gender
      traineeId
      trainerId
      baseprice
      bonus
      reward
      currentWeight
      currentFatPercentage
      targetWeight
      targetFatPercentage
      isMine
      startDate
      endDate
      term
      challengeContracts {
        id
        trainer {
          id
          username
        }
        accepted
      }
      cancelAgree
    }
  }
`;
export const SEE_CHALLENGES_NS = gql`
  query SeeChallenges($take: Int, $lastId: Int) {
    seeChallenges(take: $take, lastId: $lastId) {
      id
      title
      description
      user {
        id
        username
        profilePhoto
        type
      }
      age
      gender
      traineeId
      trainerId
      baseprice
      bonus
      reward
      currentWeight
      currentFatPercentage
      targetWeight
      targetFatPercentage
      startDate
      endDate
      term
      createdAt
      challengeContracts {
        id
        trainer {
          id
          username
        }
        accepted
      }
      cancelAgree
    }
  }
`;

export const SeeChallenges = () => {
  const { loading, data } = useQuery(SEE_CHALLENGES);
  if (!loading) {
    return data;
  }
};
export const SeeChallenges_NS = (take, lastId) => {
  const { loading, data } = useQuery(SEE_CHALLENGES_NS, {
    variables: {
      ...(take && { take }),
      ...(lastId && { lastId }),
    },
  });
  return { loading, data };
};
