import { gql, useQuery } from "@apollo/client";
import { REPORT_FRAGMENT } from "../../fragments/reportFragment";

export const SELECT_REPORTS_QUERY = gql`
  query SelectReports($isContent: Boolean!) {
    selectReports(isContent: $isContent) {
      ...ReportFragment
    }
  }
  ${REPORT_FRAGMENT}
`;

export const SelectReports = (isContent) => {
  const { data } = useQuery(SELECT_REPORTS_QUERY, {
    variables: {
      isContent,
    },
  });
  return { data };
};
