//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { messageCloseClick, myChatTabVar } from "../../utils/reactiveVar";

import { useEffect, useRef, useState } from "react";
import { SEE_ROOM_LIST } from "../../queries/message/seeRoomList";
import { CardColumns } from "react-bootstrap";
import { FatText } from "../shared/FatText";
import ChallengeChatRoomList from "./ChallengeChatRoomList";
import SelectOrderChatRoom from "../../queries/user/selectOrderChatRoom";
import ConsultChatRoomList from "./ConsultChatRoomList";
import PtRoomChatRoomList from "./PtRoomChatRoomList";
import CheckUserToken from "../../utils/checkUserToken";
import Spinner from "../shared/Spinner";
import styled from "styled-components";
import colors from "../../utils/colors";
import SearchChatRoom from "./SearchChatRoom";

const ChatListCon = styled(CardColumns)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.lightGray};
  /* border: 2px solid rgb(215, 215, 215); */
  /* border-radius: 5px; */
  border-top: none;
  height: calc(90vh - 40px);
`;
const ChatRoomList = styled(CardColumns)`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : ""};
  /* gap: 5px; */
  padding: 5px;
  overflow: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ed5f00;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
  .chatListSelect {
    span {
      color: #ed5f00;
    }
    /* background-color: #f5f6fa; */
    /* transition: 0.3s; */
    /* margin: -2px; */
    border: 2px solid #ffa970;
    /* box-shadow: 0 3px 6px #ed5f00; */
  }
  .ConsultListSelect {
    span {
      color: #ed5f00;
    }
    /* transition: 0.3s; */
    /* margin: -2px; */
    border: 2px solid #ffa970;
    /* transition: 0.3s; */
  }
`;

const UserCon = styled(CardColumns)`
  display: flex;
  height: 80px;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  background-color: white;
  box-shadow: 0 5px 5px 0 rgba(82, 63, 105, 0.2);
  /* cursor: pointer; */
  word-break: break-all;
  position: relative;
  /* border-radius: 5px; */
  gap: 10px;
  &:hover {
    background-color: rgb(250, 250, 252);
  }
`;
const NewMessage = styled.span`
  color: orange;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
`;

const MessageThumbnail = styled(CardColumns)`
  align-self: center;
  padding: 10px;
`;

const UpdateButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  outline: none;
  border: none;
  border-top: 1px solid #ccc;
  font-weight: bold;
  color: #636e72;
  &:active {
    border: 2px solid ${colors.bbeegoText};
    color: ${colors.bbeegoText};
    border-radius: 5px;
  }
`;
export const NoInfo = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.bbeego};
  gap: 5px;
`;
const NoInfoIcon = styled.i``;
const NoInfoText = styled.div``;

const ChatroomSelect = styled.select`
  height: 35px;
  outline: none;
  /* width: 100%; */
  text-align: center;
  border: 2px solid lightgray;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  /* margin-right: 10px; */
  &:focus {
    box-shadow: none;
    border: none;
    transition: 0.3s;
    border: 2px solid ${colors.bbeego};
  }
`;
const ToolCon = styled.div`
  width: 100%;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
`;
const ChList = ({ getChdata }) => {
  const myChatTab = useReactiveVar(myChatTabVar);
  const loggedInUser = CheckUserToken();
  const chatRoomList = useRef();
  const [tags, setTags] = useState([]);
  const [conTags, setConTags] = useState([]);
  const [isBoxSelect, setBoxSelect] = useState([false, false]); //클릭 이벤트에서 한개의 값만 적용시키기
  const [isCBoxSelect, setCBoxSelect] = useState([false, false]);
  const [showChatRoom, setShowChatRoom] = useState(0); //chatroomId
  const [searchData, setSearchData] = useState(null);
  const [searchBoxOpen, setSearchBoxOpen] = useState(false);

  const selectList = ["전체", "PT 룸", "챌린지"];
  const [selected, setType] = useState("전체");
  const handleSelect = (e) => {
    setType(e.target.value);
  };

  //채팅룸 리스트
  const { data: roomList, loading: rLoading } = useQuery(SEE_ROOM_LIST, {
    fetchPolicy: "network-only",
  });

  //그룹 채팅

  // 채팅 룸 클릭시 효과2
  const onChatRoomClick = (chatRoomId, idx, users) => {
    setSearchBoxOpen(false);
    setSearchData(null);
    getChdata(chatRoomId);
    setShowChatRoom(chatRoomId); // 클릭한 채팅룸 id
    messageCloseClick();
    //roomlist의 length길이의 배열 생성후 false로 채우고 클릭한 채팅룸 만 true
    if (myChatTab === 1) {
      const newArr = Array(
        tags?.filter((c) => {
          if (c?.consult === null) {
            return c;
          }
        }).length
      ).fill(false);
      newArr[idx] = true;
      setBoxSelect(newArr);
    } else {
      const newArr = Array(
        conTags?.filter((c) => {
          if (c?.consult !== null) {
            return c;
          }
        })?.length
      ).fill(false);
      //roomlist의 length길이의 배열 생성후 false로 채움
      newArr[idx] = true;
      setCBoxSelect(newArr);
    }
  };

  useEffect(() => {
    let array = [];
    if (!searchBoxOpen) {
      if (roomList?.seeRoomList?.length) {
        let data = JSON.parse(JSON.stringify(roomList.seeRoomList));

        //그룹 채팅 필터링
        let chatData = data?.filter((c) => {
          if (c.contract !== null || c.multiChallengeGroup !== null) return c;
        });

        // 1 : 1 채팅 필터링
        let conData = data?.filter((c) => {
          if (c.consult !== null) return c;
        });

        setTags(chatData);

        setConTags(conData);
      }
    }
  }, [roomList]);

  useEffect(() => {
    if (searchBoxOpen) {
      if (searchData?.searchRooms?.length > 0) {
        let data = JSON.parse(JSON.stringify(searchData?.searchRooms));

        //그룹 채팅 필터링
        let chatData = data?.filter((c) => {
          if (c.contract !== null || c.multiChallengeGroup !== null) return c;
        });

        // 1 : 1 채팅 필터링
        let conData = data?.filter((c) => {
          if (c.consult !== null) return c;
        });
        setTags(chatData);

        setConTags(conData);
      }
    }
    // console.log(searchData);
  }, [searchData]);

  // useEffect(() => {
  //   if (roomList) {
  //     let array = [];
  //     let data = JSON.parse(JSON.stringify(roomList.seeRoomList));
  //     console.log(data);
  //     //그룹 채팅 필터링
  //     let chatData = data?.filter((c) => {
  //       if (c.contract !== null || c.multiChallengeGroup !== null) return c;
  //     });
  //     chatData.filter((cd) => {
  //       if (selected === "챌린지") {
  //         if (cd.multiChallengeGroup !== null) {
  //           array.push(cd);
  //         }
  //       } else if (selected === "PT 룸") {
  //         if (cd.contract !== null) {
  //           array.push(cd);
  //         }
  //       } else {
  //         array.push(cd);
  //       }
  //     });
  //     setTags(array);
  //   }
  // }, [selected]);

  useEffect(() => {
    let array = [];
    if (tags.length) {
      tags.map((v) => {
        array.push({ id: v.id });
      });
    }
    let idx = tags?.findIndex((f) => f.id === showChatRoom);
    const newArr = Array(
      tags?.filter((c) => {
        if (c?.consult === null) {
          return c;
        }
      }).length
    ).fill(false);
    //roomlist의 length 배열 생성후 false로 채움
    newArr[idx] = true;
    setBoxSelect(newArr);
  }, [tags]);

  useEffect(() => {
    if (myChatTab === 1) {
      let selected = conTags?.filter((t) => t.id === showChatRoom);
      if (!!selected.length) {
        setBoxSelect([false, false]);
      }
    } else {
      let selected = tags?.filter((t) => t.id === showChatRoom);
      if (!!selected.length) {
        setCBoxSelect([false, false]);
      }
    }
  }, [myChatTab]);

  return (
    <ChatListCon>
      <ChatRoomList ref={chatRoomList}>
        <ToolCon></ToolCon>
        <SearchChatRoom
          setSearchBoxOpen={setSearchBoxOpen}
          setSearchData={setSearchData}
        />
        <div>
          {rLoading === true ? <Spinner /> : null}
          {myChatTab === 1 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              {" "}
              {/* <ChatroomSelect
                style={{
                  marginRight: "5px",
                  marginBottom: "5px",
                }}
                onChange={handleSelect}
                value={selected}
              >
                {selectList.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </ChatroomSelect> */}
              {tags?.filter((c) => {
                if (c?.consult === null) {
                  return c;
                }
              })?.length ? (
                tags
                  ?.filter((c) => {
                    if (c?.consult === null) {
                      return c;
                    }
                  })
                  .map((chatRoom, index) =>
                    chatRoom.consult === null &&
                    chatRoom.contract === null &&
                    chatRoom.multiChallengeGroup === null ? null : (
                      <UserCon
                        className={`chatList${
                          isBoxSelect[index] ? "Select" : "noSelect" //isBoxSelect index번째의 값이 true라면 className 변경
                        }`}
                        key={chatRoom.id}
                        id={chatRoom.id}
                        onClick={(e) =>
                          onChatRoomClick(chatRoom.id, index, chatRoom.users)
                        }
                      >
                        <MessageThumbnail>
                          {chatRoom?.contract?.pTPrice ? (
                            <PtRoomChatRoomList {...chatRoom} />
                          ) : chatRoom?.multiChallengeGroup ? (
                            <ChallengeChatRoomList {...chatRoom} />
                          ) : null}
                        </MessageThumbnail>
                        <NewMessage>
                          {chatRoom?.messages?.filter((msg) => {
                            let checkedUserIds =
                              msg?.checkedUserIds?.split("|");

                            if (
                              !checkedUserIds.includes(
                                String(loggedInUser?.me?.id)
                              )
                            ) {
                              return msg;
                            }
                          }).length > 0
                            ? chatRoom?.messages?.filter((msg) => {
                                let checkedUserIds =
                                  msg?.checkedUserIds?.split("|");
                                if (
                                  !checkedUserIds.includes(
                                    String(loggedInUser?.me?.id)
                                  )
                                ) {
                                  return msg;
                                }
                              }).length
                            : null}
                        </NewMessage>
                      </UserCon>
                    )
                  )
              ) : (
                <NoInfo>
                  <NoInfoIcon className="ri-chat-delete-line" />
                  <NoInfoText>채팅룸이 존재하지 않습니다.</NoInfoText>
                </NoInfo>
              )}
            </div>
          ) : null}
          {myChatTab === 2 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              {conTags?.filter((c) => {
                if (c?.consult !== null) {
                  return c;
                }
              })?.length ? (
                conTags
                  ?.filter((c) => {
                    if (c?.consult !== null) {
                      return c;
                    }
                  })
                  .map((chatRoom, index) =>
                    chatRoom.consult === null &&
                    chatRoom.contract === null &&
                    chatRoom.multiChallengeGroup === null ? null : (
                      <UserCon
                        className={`ConsultList${
                          isCBoxSelect[index] ? "Select" : "noSelect"
                        }`}
                        key={chatRoom.id}
                        id={chatRoom.id}
                        onClick={(e) =>
                          onChatRoomClick(chatRoom.id, index, chatRoom.users)
                        }
                        // chatRoomChecked={
                        //   showChatRoom === chatRoom?.id ? true : false
                        // }
                      >
                        <MessageThumbnail>
                          <FatText>
                            {chatRoom?.consult ? (
                              loggedInUser?.me?.type === "trainer" ? (
                                <ConsultChatRoomList
                                  {...chatRoom}
                                  type="trainer"
                                />
                              ) : (
                                <ConsultChatRoomList
                                  {...chatRoom}
                                  type="user"
                                />
                              )
                            ) : (
                              <NoInfo>
                                <NoInfoIcon className="ri-chat-delete-line" />
                                <NoInfoText>
                                  채팅룸이 존재하지 않습니다.
                                </NoInfoText>
                              </NoInfo>
                            )}
                          </FatText>
                        </MessageThumbnail>
                        <NewMessage>
                          {chatRoom?.messages?.filter((msg) => {
                            let checkedUserIds =
                              msg?.checkedUserIds?.split("|");
                            if (
                              !checkedUserIds.includes(
                                String(loggedInUser?.me?.id)
                              )
                            ) {
                              return msg;
                            }
                          }).length > 0
                            ? chatRoom?.messages?.filter((msg) => {
                                let checkedUserIds =
                                  msg?.checkedUserIds?.split("|");
                                if (
                                  !checkedUserIds.includes(
                                    String(loggedInUser?.me?.id)
                                  )
                                ) {
                                  return msg;
                                }
                              }).length
                            : null}
                        </NewMessage>
                      </UserCon>
                    )
                  )
              ) : (
                <NoInfo>
                  <NoInfoIcon className="ri-chat-delete-line" />
                  <NoInfoText>채팅룸이 존재하지 않습니다.</NoInfoText>
                </NoInfo>
              )}
            </div>
          ) : null}
        </div>
      </ChatRoomList>
    </ChatListCon>
  );
};
export default ChList;
