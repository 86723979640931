//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { Link } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/routes";

const SProfilePhoto = styled.img`
  width: ${(props) => (props.width ? props.width : "40px")};
  height: ${(props) => (props.height ? props.height : "40px")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "50%"};
  border: ${(props) => (props.border ? props.border : "")};
  object-fit: cover;
  object-position: bottom top;
  display: ${(props) => (props.display ? props.display : "")};
  ${(props) =>
    props?.isRes
      ? `@media (max-width: 770px) {
    width: 60px;
    height: 60px;
  }`
      : ""};
`;

const ProfilePhoto = ({
  src,
  id,
  width,
  height,
  margin,
  borderRadius,
  border,
  display,
  another,
  isRes, //반응형
}) => {
  //console.log("process.env.SERVER_URL : ", process.env);
  return (
    <>
      {another ? (
        <SProfilePhoto
          src={src ? src : ""}
          onError={(e) =>
            (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
          }
          width={width}
          height={height}
          margin={margin}
          borderRadius={borderRadius}
          border={border}
          display={display}
        />
      ) : (
        <Link to={{ pathname: `${routes.myGym}${id}`, state: { id } }}>
          <SProfilePhoto
            src={src ? src : ""}
            onError={(e) =>
              (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
            }
            width={width}
            height={height}
            margin={margin}
            borderRadius={borderRadius}
            border={border}
            isRes={isRes}
            display={display}
          />
        </Link>
      )}
    </>
  );
};

export default ProfilePhoto;
