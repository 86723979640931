//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation, useQuery } from "@apollo/client";

import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GET_NOTIFICATION_QUERY } from "../../queries/notification/getNotification";
import { UPDATE_NOTIFICATION_MUTATION } from "../../queries/notification/updateNotification";
import { NoInfo } from "../../routes/Message";
import { routes } from "../../routes/routes";
import CheckUserToken from "../../utils/checkUserToken";
import colors from "../../utils/colors";
import { myGymTabVar } from "../../utils/reactiveVar";
import { EmptyDiv } from "../shared/EmptyDiv";
import { FatText } from "../shared/FatText";
import ProfilePhoto from "../shared/ProfilePhoto";

const NotiCon = styled.div`
  position: relative;
`;

const Icon = styled.div`
  color: ${(props) => (props.visible ? colors.noti : "#2c2c2c")};
  cursor: pointer;
`;

const DropBox = styled.div`
  position: absolute;
  width: 100%;
  top: 35px;
  left: -400px;
  background-color: white;
  width: 32.5em;
  overflow: hidden;
  text-overflow: ellipsis;

  /* border: 1px solid #ccc; */
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  color: #888888;
  font-size: 15px;
  line-height: 1.2;
  /* box-shadow: 1px 1px 5px -1px gray; */
  box-shadow: 0 5px 30px 0px lightgrey;
  @media (max-width: 660px) {
    width: 300px;
    left: -200px;
  }
`;
const DropList = styled.div`
  width: 100%;
  display: flex;
  height: 318px; //53 * n
  flex-direction: column;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fd780f;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
`;
const Triangle = styled.div`
  position: absolute;
  top: 30px;
  left: 14px;
  /* border: 1px solid #ccc; */
  /* box-shadow: 0 5px 30px 0px lightgrey; */
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: white;
  z-index: 1;
`;
const Notice = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
  &:hover {
    background-color: rgb(250, 250, 252);
    .des {
      color: ${colors.bbeego};
    }
  }
`;
const DumIcon = styled.i`
  width: 22px;
  font-size: 22px;
  position: absolute;
  top: 0px;
  right: 8px;
  line-height: 1;
  ${(props) =>
    props.newNotice && !props.visible
      ? `
      @keyframes changePosition {
        from {
          top: 0px

        }

        to {
          top: 4px
        }
      }
      @keyframes changeColor {
        from {
          color: #2c2c2c,

        }

        to {
          color: ${colors.noti}
        }
      }
      animation-duration: 0.15s;
      animation-name: changeColor, changePosition;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      `
      : null}

  &:hover {
    color: ${colors.noti};
  }
`;

const CountNoti = styled.div`
  position: absolute;
  top: -16px;
  right: -5px;
  border-radius: 5px;
  background-color: green;
  color: white;
  padding: 2px;
  align-items: center;
  justify-items: center;
  width: 18px;
  height: 18px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

const ActionText = styled.span`
  color: #2c2c2c;
  cursor: pointer;
`;
const UserCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NotiHeader = styled.div`
  width: 100%;
  position: absolute;
  padding: 15px 10px;
  overflow: hidden;
  background-color: white;
  font-size: 17px;
  font-weight: 600;
  color: black;
  border-bottom: 1px solid #ccc;
`;

const Notification = () => {
  const history = useHistory();
  const me = CheckUserToken();
  const notiRef = useRef();
  const [visible, setVisible] = useState(false);
  const [newNotice, setNewNotice] = useState(false);
  const [countNoti, setCountNoti] = useState(0);
  const { loading, error, data } = useQuery(GET_NOTIFICATION_QUERY, {
    fetchPolicy: "network-only",
  });

  const [updateNotificationMutation] = useMutation(
    UPDATE_NOTIFICATION_MUTATION
  );
  const iconRef = useRef();

  const isNoticeNew = () => {
    const newNotification = data?.getNotification?.filter((v) => {
      if (v.checked === false) {
        return v;
      }
    });

    if (newNotification?.length) {
      setNewNotice(true);
    } else {
      setNewNotice(false);
    }
  };
  const onIconClick = async (e) => {
    await setVisible(!visible);

    const uncheckedNoticeIds = data?.getNotification
      ? data?.getNotification
          .filter((v) => v.checked === false)
          .map((v) => v.id)
      : [];

    updateNotificationMutation({
      variables: {
        ids: uncheckedNoticeIds,
      },
      refetchQueries: [{ query: GET_NOTIFICATION_QUERY }],
    });
  };

  const clickNotiOutside = (e) => {
    if (
      visible &&
      !notiRef.current.contains(e.target) &&
      !Array.from(e.target.classList)?.includes("mouseoutException")
    ) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickNotiOutside);

    return () => {
      document.removeEventListener("mousedown", clickNotiOutside);
    };
  });

  // useEffect(() => {
  //   const Counter = data?.getNotification;
  //   setCountNoti(Object.keys(Counter).length);
  //   console.log(countNoti);
  // }, [data]);
  useEffect(() => {});
  const getNotice = ({
    id,
    type,
    notice,
    userFrom,
    context,
    relatedRoute,
    relatedId,
  }) => {
    switch (relatedRoute) {
      //내 프로필로 옮길경우
      case "myGym":
        switch (type) {
          //트레이너 관점
          case "PTRoomRequested":
          case "PTRoomPurchased":
          case "PTRoomExtended":
            return (
              <Notice
                onClick={() => {
                  history.push(`${routes[relatedRoute]}${me?.me?.id}`, {
                    myGymTab: 4,
                    subTab: 2,
                  });
                  myGymTabVar(4);
                }}
                key={id}
              >
                <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
                {/* <Link to={{ pathname: `${routes[relatedRoute]}${me?.me?.id}` }}>
                  {notice}
                </Link> */}
                <UserCon>
                  <FatText>{userFrom?.username}</FatText>
                  <ActionText className="des">{context}</ActionText>
                </UserCon>
              </Notice>
            );
          //유저 관점
          case "PTRoomAccepted":
          case "PTRoomDenied":
          case "PTRoomRejected":
            return (
              <Notice
                onClick={() =>
                  history.push(`${routes[relatedRoute]}${me?.me?.id}`, {
                    myGymTab: 4,
                  })
                }
                key={id}
              >
                <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
                {/* <Link to={{ pathname: `${routes[relatedRoute]}${me?.me?.id}` }}>
                  {notice}
                </Link> */}
                <UserCon>
                  <FatText>{userFrom?.username}</FatText>
                  <ActionText className="des">{context}</ActionText>
                </UserCon>
              </Notice>
            );
          case "PTRoomContractExpired":
            return (
              <Notice
                onClick={() =>
                  history.push(`${routes[relatedRoute]}${me?.me?.id}`, {
                    myGymTab: 3,
                  })
                }
                key={id}
              >
                <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
                {/* <Link to={{ pathname: `${routes[relatedRoute]}${me?.me?.id}` }}>
                  {notice}
                </Link> */}
                <UserCon>
                  <FatText>{userFrom?.username}</FatText>
                  <ActionText className="des">{context}</ActionText>
                </UserCon>
              </Notice>
            );
          default:
            return (
              <Notice
                onClick={() =>
                  history.push(`${routes[relatedRoute]}${me?.me?.id}`)
                }
                key={id}
              >
                <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
                <UserCon>
                  <FatText>{userFrom?.username}</FatText>
                  <ActionText className="des">{context}</ActionText>
                </UserCon>
              </Notice>
            );
        }
      //상담
      case "message":
        switch (type) {
          case "ConsultRequested":
          case "ConsultAccepted":
            return (
              <Notice
                onClick={() =>
                  history.push(routes[relatedRoute], { myChatTab: 2 })
                }
                key={id}
              >
                <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
                <UserCon>
                  <FatText>{userFrom?.username}</FatText>
                  <ActionText className="des">{context}</ActionText>
                </UserCon>
              </Notice>
            );
        }
      default:
        return (
          <Notice
            onClick={() =>
              history.push(routes[relatedRoute], { id: relatedId })
            }
            key={id}
          >
            <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
            <UserCon>
              <FatText>{userFrom?.username}</FatText>
              <ActionText className="des">{context}</ActionText>
            </UserCon>
          </Notice>
        );
    }
    // switch (type) {
    //   case "multiChallengeDelete":
    //     return (
    //       <Notice key={id}>
    //         <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
    //         {context}
    //       </Notice>
    //     );
    //   case "challengeCancel":
    //   case "challengeDeny":
    //   case "challengeAccept":
    //   case "challengeSelect":
    //   case "registerRequest":
    //   case "registerRequestCancel":
    //   case "registerAccept":
    //   case "registerDeny":
    //     return (
    //       <Notice>
    //         <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
    //         <Link to={{ pathname: `/mygym/${me?.me?.id}` }}>{notice}</Link>
    //       </Notice>
    //     );
    //   case "challengeRequest":
    //     return (
    //       <Notice key={id}>
    //         <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
    //         <Link to={{ pathname: `/mygym/${me?.me?.id}` }}>{notice}</Link>
    //       </Notice>
    //     );
    //   default:
    //     return (
    //       <Notice key={id}>
    //         <ProfilePhoto src={userFrom?.profilePhoto} {...userFrom} />
    //         <Link to={{ pathname: `/mygym/${me?.me?.id}` }} key={id}>
    //           {notice}
    //         </Link>
    //       </Notice>
    //     );
    // }
  };

  useEffect(() => {
    if (data) {
      isNoticeNew();
    }
  }, [data]);
  return (
    <NotiCon className="mouseoutException">
      <Icon
        visible={visible}
        onClick={onIconClick}
        ref={iconRef}
        newNotice={newNotice}
        className="mouseoutException"
      >
        <EmptyDiv
          width="42px"
          height="25px"
          padding="5px"
          className="mouseoutException"
        />
        <DumIcon
          newNotice={newNotice}
          className="bi bi-bell-fill mouseoutException"
        />
        {/* <CountNoti>{countNoti}</CountNoti> */}

        {/* <FontAwesomeIcon icon={faDumbbell} /> */}
      </Icon>
      {visible ? (
        <div style={{ width: "100%" }}>
          <Triangle></Triangle>

          <DropBox ref={notiRef}>
            <EmptyDiv width="30px" minHeight="51.3px" />

            <NotiHeader>새로운 알림</NotiHeader>
            <DropList>
              {data?.getNotification?.length ? (
                data?.getNotification?.map((value) => getNotice(value))
              ) : (
                <NoInfo>
                  <i className="bx bx-bell-off" />
                  <div>알림이 존재하지 않습니다.</div>
                </NoInfo>
              )}
            </DropList>
          </DropBox>
        </div>
      ) : null}
    </NotiCon>
  );
};

export default Notification;
