//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import React from "react";
import Collapsible from "react-collapsible";

const FAQCon = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
`;
const FAQHeader = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  margin-top: 120px;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
`;
const SubTitle = styled.h3`
  text-align: center;
  margin: 20px 0 80px 0;
  font-weight: 22px !important;
  color: #6b7280;
`;
const FAQBody = styled.div`
  width: 100%;
  padding: 50px;
  min-height: 650px;
  background-color: rgb(249 250 251); ;
`;
const FAQCard = styled.div`
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0/0.1), 0 2px 4px -2px rgb(0 0 0/0.1);
  border-top: 1px solid lightgray;
  width: 100%;
  margin: auto;
  padding: 25px;
  text-align: center;
`;

const CollapseCon = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 1100px;
  display: block;
  border-top: 2px solid #222;
  .Collapsible {
    width: 100%;
    border-bottom: 1px solid lightgray;
    span {
      display: flex;
      position: relative;
      font-size: 16px;
      width: 100%;
      color: #222;
      padding: 12px;
    }
    .is-open {
      color: #ed5f00;
      span {
        color: #ed5f00;
      }
    }
  }
`;

const ColIcon = styled.i`
  margin: 8px;
  font-size: 1.5rem;
`;
const FAQ = () => {
  return (
    <FAQCon>
      <FAQHeader>
        <Title>FAQ</Title>
        <SubTitle>BBEEGO에 대한 모든것을 물어보세요!</SubTitle>
      </FAQHeader>
      <FAQBody>
        <CollapseCon>
          <Collapsible
            transitionCloseTime={200}
            trigger={
              <>
                <span>챌린지라는 서비스는 어떤것인지 궁금합니다?</span>
                <ColIcon className="bi-chevron-down" />
              </>
            }
          >
            {/* <ColIcon className="bi bi-chevron-down" /> */}
            <FAQCard style={{ backgroundColor: "white" }}>
              <p>
                This is the collapsible content. It can be any element or React
                component you like.
              </p>
              <p>
                It can even be another Collapsible component. Check out the next
                section!
              </p>
            </FAQCard>
          </Collapsible>
          <Collapsible
            transitionCloseTime={200}
            trigger={
              <>
                <span>
                  3D 바디스캐팅은 어떤것인지 전체적인 헬스 관련 조회하는
                  시스템인가요?
                </span>
                <ColIcon className="bi-chevron-down" />
              </>
            }
          >
            <FAQCard style={{ backgroundColor: "white" }}>
              <p>
                This is the collapsible content. It can be any element or React
                component you like.
              </p>
              <p>
                It can even be another Collapsible component. Check out the next
                section!
              </p>
            </FAQCard>
          </Collapsible>
          <Collapsible
            transitionCloseTime={200}
            trigger={
              <>
                <span>
                  PT룸을 어떻게 개인 맞춤형으로 잡을 수 있는 것인지 궁금하네요?
                </span>
                <ColIcon className="bi-chevron-down" />
              </>
            }
          >
            <FAQCard style={{ backgroundColor: "white" }}>
              <p>
                This is the collapsible content. It can be any element or React
                component you like.
              </p>
              <p>
                It can even be another Collapsible component. Check out the next
                section!
              </p>
            </FAQCard>
          </Collapsible>
          <Collapsible
            transitionCloseTime={200}
            trigger={
              <>
                <span>실시간 채팅은 어떨 때 하는 것인지 문의드립니다.</span>
                <ColIcon className="bi-chevron-down" />
              </>
            }
          >
            <FAQCard style={{ backgroundColor: "white" }}>
              <p>
                This is the collapsible content. It can be any element or React
                component you like.
              </p>
              <p>
                It can even be another Collapsible component. Check out the next
                section!
              </p>
            </FAQCard>
          </Collapsible>
          <Collapsible
            transitionCloseTime={200}
            trigger={
              <>
                <span>로그인 방법. 계정 찾기. 수정. 탈퇴 등</span>
                <ColIcon className="bi-chevron-down" />
              </>
            }
          >
            <FAQCard style={{ backgroundColor: "white" }}>
              <p>
                This is the collapsible content. It can be any element or React
                component you like.
              </p>
              <p>
                It can even be another Collapsible component. Check out the next
                section!
              </p>
            </FAQCard>
          </Collapsible>
        </CollapseCon>
      </FAQBody>
    </FAQCon>
  );
};

export default FAQ;
