//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//내가 만든 멀티챌린지와 내가 참여한 멀티 챌린지를 같이 조회합니다.
import { MULTICHALLENGE_FRAGMENT } from "../fragments/multiChallengeFragment";

import { gql, useQuery } from "@apollo/client";
import { MULTICHALLENGE_CONTRACT_FRAGMENT } from "../fragments/multiChallengeContractFragment";
import { MULTICHALLENGE_GROUP_FRAGMENT } from "../fragments/multiChallengeGroupFragment";

export const SELECT_MULTICHALLENGES_BY_USERID_QUERY = gql`
  query SelectMultiChallengesByUserId($userId: Int!) {
    selectMultiChallengesByUserId(userId: $userId) {
      myMultiChallenges {
        ...MultiChallengeFragment
      }
      contractedMultiChallenges {
        contract {
          ...MultiChallengeContractFragment
        }
        group {
          ...MultiChallengeGroupFragment
        }
      }
    }
  }
  ${MULTICHALLENGE_FRAGMENT}
  ${MULTICHALLENGE_CONTRACT_FRAGMENT}
  ${MULTICHALLENGE_GROUP_FRAGMENT}
`;

export const SelectMultiChallengesByUserId = (userId) => {
  const { data } = useQuery(SELECT_MULTICHALLENGES_BY_USERID_QUERY, {
    variables: { userId },
  });
  return { data };
};
