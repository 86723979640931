import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react";
import { CUSTOMERSERVICE_FRAGMENT } from "../fragments/customerServiceFragment";

export const SELECT_CS_CHATROOM_BY_USERID_QUERY = gql`
  query SelectCSChatRoomByUserId {
    selectCSChatRoomByUserId {
      ...CustomerServiceFragment
    }
  }
  ${CUSTOMERSERVICE_FRAGMENT}
`;

export const SelectCSChatRoomByUserId = () => {
  const { data, refetch } = useQuery(SELECT_CS_CHATROOM_BY_USERID_QUERY, {
    fetchPolicy: "no-cache",
  });
  return { data, refetch };
};
