//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faArrowRight, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  CloseBtn,
  IconSpan,
  Interact,
  InteractBtn,
  InteractContent,
  InteractHeader,
} from "../../routes/MyGym";
import { routes } from "../../routes/routes";
import ProfilePhoto from "../shared/ProfilePhoto";
import SeeUser from "../../queries/user/seeUser";
import { useMutation } from "@apollo/client";
import { DELETE_CHALLENGE } from "../../queries/challenge/deleteChallenge";
import {
  SeeChallenges,
  SEE_CHALLENGES,
} from "../../queries/challenge/seeChallenges";
import { NumberKeyframeTrack } from "three";
import { SButton } from "../shared/SButton";
import { CREATE_CHALLENGE_CONTRACT_MUTATION } from "../../queries/challengeContract/createChallengeContract";
import CheckUserToken from "../../utils/checkUserToken";
import { CREATE_NOTIFICATION_MUTATION } from "../../queries/notification/createNotification";
import { notificationTypes } from "../../utils/notificationTypes";
import { Response } from "../contract/Response";

const ChallengeCon = styled.div`
  background-color: white;
  width: 225px;
  max-width: 225px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 15px -5px gray;
`;
const Header = styled.div`
  padding: 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid lightgrey;
  column-gap: 10px;
`;
const Contents = styled.div`
  width: 100%;
  max-height: 300px;
  padding: 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  border: 1px solid lightgrey;
  border-top: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;
const Title = styled.div`
  margin: 2px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
`;
const Description = styled.textarea`
  width: 100%;
  padding: 5px;
  margin: 5px auto;
  border: 1px solid lightgrey;
  height: 75px;
  overflow-y: scroll;
  resize: none;
  outline: none;
`;
const BasePrice = styled.div`
  padding: 3px 0;
`;
const Bonus = styled(BasePrice)``;
const Reward = styled(BasePrice)``;
const Prices = styled(BasePrice)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  row-gap: 10px;
  margin-bottom: 7px;
`;
const Weight = styled(BasePrice)`
  display: flex;
  flex-direction: row;
`;
const FatPercentage = styled(BasePrice)`
  display: flex;
  flex-direction: row;
`;

const Target = styled(BasePrice)`
  display: flex;
  flex-direction: column;
`;
const Current = styled(BasePrice)``;
const InteractDiv = styled.div`
  margin-left: auto;
  width: 15px;
`;

const ChallengeCard = (props) => {
  const me = CheckUserToken();
  const history = useHistory();
  const [applied, setApplied] = useState(false);
  const onCompleted = (data) => {
    const {
      createChallengeContract: { ok },
    } = data;
    if (ok) {
      alert("등록되었습니다!");
      setApplied(true);
    }
  };
  const [createChallengeContract] = useMutation(
    CREATE_CHALLENGE_CONTRACT_MUTATION,
    {
      onCompleted,
    }
  );
  const [createNotificationMutation] = useMutation(
    CREATE_NOTIFICATION_MUTATION
  );
  const showInteract = (props) => {
    props?.setModal(true);
    props?.setModalData({ ...props });
  };

  useEffect(() => {
    const appliedContracts = props?.challengeContracts?.filter((v) => {
      return v?.trainer?.id == me?.me?.id;
    });

    if (appliedContracts.length) {
      setApplied(true);
    }
  }, [applied]);
  return (
    <ChallengeCon className="card">
      <Header>
        <ProfilePhoto src={props?.user?.profilePhoto} id={props?.user?.id} />
        <div
          style={{
            margin: "0 auto",
            padding: "4px",
          }}
          id="UserName"
        >
          {props?.user?.username}
        </div>
        <InteractDiv>
          <IconSpan onClick={() => showInteract(props)} className="Interact">
            <FontAwesomeIcon icon={faEllipsisV} size="lg" />
          </IconSpan>
        </InteractDiv>
      </Header>
      <Contents>
        {/* <Description>{props?.description}</Description> */}
        <Prices>
          <Title>{props?.title}</Title>
          <BasePrice>기본금 : {props?.baseprice}만원</BasePrice>
          <Bonus>성공보수 : {props?.bonus}만원</Bonus>
          <Reward>리워드 : {props?.reward}만원</Reward>

          <Weight>
            <Current>체중 : {props?.currentWeight}kg</Current>
            <FontAwesomeIcon icon={faArrowRight} />
            <Target>{props?.targetWeight}kg</Target>
          </Weight>
          <FatPercentage>
            <Current>체지방률 : {props?.currentFatPercentage}%</Current>
            <FontAwesomeIcon icon={faArrowRight} />
            <Target>{props?.targetFatPercentage}%</Target>
          </FatPercentage>
        </Prices>

        <SButton
          context="상세보기"
          bgColor="#fd780f"
          border="none"
          color="white"
          onClick={() => {
            history.push(routes?.challengeInfo, {
              id: props?.id,
            });
          }}
          // disabled={applied ? true : false}
        />
      </Contents>
    </ChallengeCon>
  );
};

export default ChallengeCard;
