//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";

import { useEffect } from "react";
import { useState } from "react";
import {
  Card,
  CardColumns,
  Container,
  Form,
  Image,
  Modal,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { GButton } from "../../component/shared/GButton";
import CheckUserToken from "../../utils/checkUserToken";
import { CREATE_MULTICHALLENGE_CONTRACT_MUTATION } from "../../queries/multichallengeContract/createMultiChallengeContract";
import { useForm } from "react-hook-form";
import { ToggleGender, ToggleState } from "../CreateChallenge";
import { routes } from "../routes";
import ProfilePhoto from "../../component/shared/ProfilePhoto";
import { TypeBadge } from "../../component/shared/TypeBadge";
import { SelectMultiChallengeById } from "../../queries/multiChallenge/selectMultiChallengeById";
import { Button } from "../MyGym";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
const Line = styled(Container)`
  height: 2px;
  background-color: #222;
  margin: 0 auto;
  margin-bottom: 15px;
`;
const Con = styled(Container)`
  display: flex;
  flex-direction: row;
  min-height: 550px;
  margin: 0 auto;
  @media (max-width: 1209px) {
    flex-direction: column;
  }
`;
const ChallengeCon = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  padding: 30px 0;
  text-align: center;
`;
const ThumbCon = styled(CardColumns)`
  display: flex;
  align-content: center;
  text-align: center;
  border: none;
  margin-right: 50px;
  margin-top: 30px;
  text-align: center;
  @media (max-width: 1209px) {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }
`;
const CTitle = styled(CardColumns)`
  margin: 0 auto;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #ed5f00;
`;
const UserName = styled.h2`
  padding: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;
const ChallengeTitle = styled(CardColumns)`
  margin-bottom: 12px;
  padding: 10px 0;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: -0.25px;
  line-height: 38px;
  color: #16181a;
`;
const Thumbnail = styled(Image)`
  position: relative;
  height: 550px;
  width: 550px;
  border: none;
  margin: 0 auto;
  background-image: url(${(props) => (props.bgImg ? props.bgImg : "")});
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 1209px) {
    height: auto;
    width: 100%;
  }
`;
const DesCon = styled(CardColumns)`
  display: flex;
  min-height: 700px;
  .ql-container {
    min-height: 650px;
  }
`;
const Description = styled(CardColumns)`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  img {
    max-width: 100%;
  }
`;

export const ChallengeIcon = styled.span`
  width: 90px;
  color: #ffc107 !important;
  font-size: 14px;
  border: 1px solid #ffc107 !important;
  padding: 0.25rem 0.325rem;
  margin-bottom: 10px;
`;

const ProctInfo = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 5fr;
  width: 100%;
  border: none;
  gap: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 10px auto 0;
  padding: 20px 5px;
  text-align: left;
  .infotitle {
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 19px;
    align-self: center;
    letter-spacing: -0.12px;
  }
  .infodes {
    display: flex;
    height: 35px;
    align-items: center;
    align-self: center;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.12px;
    color: #16181a;
  }
  .tab {
    padding-left: 3px;
  }
  @media (max-width: 549px) {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
  }
`;

const CardTitle = styled.h5`
  width: 100%;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
  color: #ed5f00;
`;
const ApplyRow = styled.div`
  margin: 0.5rem !important;
  text-align: center;
  display: grid;
  grid-template-columns: 1.2fr 2.5fr;
  @media (max-width: 999px) {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
  }
`;

const CountRow = styled.div`
  display: grid;
  margin: 0.5rem !important;
  text-align: center;
  line-height: 1.5;
  grid-template-columns: 2fr 0.5fr 1.5fr;
  font-size: 18px !important;
  font-weight: 700 !important;
  padding: 10px;
  @media (max-width: 999px) {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    text-align: left;
  }
`;
const ColTitle = styled.div`
  padding: 10px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
`;
const CountTitle = styled.div`
  width: 160px;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
`;
const ApplyValue = styled.input`
  width: 140px;
  border-radius: 5px;
  border: 1px solid lightgray;
  display: flex;
`;

const ApplyCon = styled(CardColumns)`
  display: grid;
  grid-template-columns: 1.2fr 1.5fr;
  width: 100%;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  @media (max-width: 999px) {
    grid-template-columns: none;
    grid-template-rows: auto;
  }
`;
const CreateForm = styled(Form)`
  display: flex;
  width: 85%;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
`;
const Submit = styled.input`
  margin-top: 5px;
`;
const PersonalInfo = styled.div``;
const Count = styled.div`
  /* width: 180px; */
`;

const GInput = styled.input`
  margin-left: 5px;
`;

const MultiChallengeInfo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const history = useHistory();
  const data = SelectMultiChallengeById(location?.state?.id)?.data;

  const { register, handleSubmit, setValue } = useForm();
  const me = CheckUserToken();

  const [modal, setModal] = useState(false);
  const [applied, setApplied] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const isAcc = data?.selectMultiChallengeById?.multiChallengeGroups.reduce(
    (acc, mcg) => {
      for (let i = 0; i < mcg?.multiChallengeGroupMappers.length; i++) {
        if (mcg?.multiChallengeGroupMappers[i].user?.id === me?.me?.id) {
          acc.push(mcg);
        }
      }
      return acc;
    },
    []
  );

  // console.log(
  //   "id? : ",
  //   isAcc?.[0]?.multiChallengeGroupMappers?.filter((isAcc) => {
  //     return isAcc.user.id === me?.me?.id;
  //   })?.[0]?.id
  // );

  const toggleHandler = () => {
    setIsOn(!isOn);
  };
  useEffect(() => {
    const Gender = isOn === false ? "male" : "female";
    setValue("gender", Gender);
  }, [isOn]);

  const onCompleted = (data) => {
    const {
      createMultiChallengeContract: { ok, error },
    } = data;
    if (ok && me?.me?.type === "user") {
      alert("유저 신청완료");
      setApplied(true);
    } else if (ok && me?.me?.type === "trainer") {
      alert("트레이너 신청완료");
      setApplied(true);
    } else if (!ok && error === "Trainer Count Reached to Limit.") {
      alert("해당 챌린지의 트레이너 수 제한으로 더 이상 참여할 수 없습니다!");
    } else if (
      !ok &&
      error === "User Count Reached to Limit or Not Enough Trainer."
    ) {
      alert("해당 챌린지의 유저 수 제한으로 더 이상 참여할 수 없습니다!");
    }
    setModal(false);
  };

  // const [createNotificationMutation] = useMutation(
  //   CREATE_NOTIFICATION_MUTATION
  // );

  const [createMultiChallengeContract] = useMutation(
    CREATE_MULTICHALLENGE_CONTRACT_MUTATION,
    {
      onCompleted,
    }
  );

  const onSubmitValid = (challengeData) => {
    if (!!data) {
      const { height, weight, gender } = challengeData;

      if (me?.me?.type === "trainer") {
        createMultiChallengeContract({
          variables: {
            multiChallengeId: Number(data?.selectMultiChallengeById?.id),
          },
        });
      } else {
        createMultiChallengeContract({
          variables: {
            multiChallengeId: Number(data?.selectMultiChallengeById?.id),
            height: Number(height),
            weight: Number(weight),
            gender: gender,
          },
        });
      }
    }
  };
  useEffect(() => {
    const appliedContracts =
      data?.selectMultiChallengeById?.multiChallengeContracts?.filter((v) => {
        return v?.user?.id === me?.me?.id;
      });

    if (appliedContracts?.length) {
      setApplied(true);
    }
  }, [data]);
  useEffect(() => {
    let Description = document.getElementById("ccc");

    const des = data?.selectMultiChallengeById?.description;

    if (!!des) {
      Description.innerHTML = des;
    }
  }, [data]);

  return (
    <Container style={{ maxWidth: "1140px" }}>
      {
        data?.selectMultiChallengeById ? (
          <>
            <Con>
              <ThumbCon>
                <Thumbnail
                  src={data?.selectMultiChallengeById?.thumbnail}
                ></Thumbnail>
              </ThumbCon>
              <ChallengeCon>
                <TypeBadge
                  width="100px"
                  variant="challenge"
                  context="Challenge"
                />
                <ChallengeTitle>
                  {data?.selectMultiChallengeById?.title}
                </ChallengeTitle>
                <ProfilePhoto
                  width="150px"
                  height="150px"
                  margin="0 auto"
                  src={data?.selectMultiChallengeById?.user?.profilePhoto}
                  id={data?.selectMultiChallengeById?.user?.id}
                />
                <UserName>{data?.user?.username}</UserName>
                <ProctInfo>
                  {/* <div className="infotitle">등록일자</div>
                  <div className="infodes tab">
                    {
                      new Date(
                        Number(data?.selectMultiChallengeById?.createdAt)
                      )
                        .toJSON()
                        .split("T")[0]
                    }
                  </div> */}
                  <div className="infotitle">시작 / 종료날짜</div>
                  <div className="infodes tab">
                    {
                      new Date(
                        Number(data?.selectMultiChallengeById?.startDate)
                      )
                        .toJSON()
                        .split("T")[0]
                    }{" "}
                    /{" "}
                    {
                      new Date(Number(data?.selectMultiChallengeById?.endDate))
                        .toJSON()
                        .split("T")[0]
                    }
                  </div>

                  <CardColumns className="infotitle">기간</CardColumns>
                  <CardColumns className="infodes tab">
                    {data?.selectMultiChallengeById?.term}일
                  </CardColumns>
                  <CardColumns className="infotitle">
                    기본금 / 보너스
                  </CardColumns>
                  <CardColumns className="infodes tab">
                    {data?.selectMultiChallengeById?.baseprice} /{" "}
                    {data?.selectMultiChallengeById?.bonus} 만원
                  </CardColumns>

                  <CardColumns className="infotitle">리워드</CardColumns>
                  <CardColumns className="infodes tab">
                    {data?.selectMultiChallengeById?.reward}만원
                  </CardColumns>
                  <div className="infotitle">신고</div>
                  <div className="infodes">
                    <Button
                      style={{ margin: "0px" }}
                      variant="danger"
                      type="button"
                      onClick={() =>
                        history.push(routes?.report, {
                          id: data?.selectMultiChallengeById?.user?.id,
                          username:
                            data?.selectMultiChallengeById?.user?.username,
                          targetContentId: data?.selectMultiChallengeById?.id,
                          targetContentType: "MultiChallenge",
                          isContent: true,
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faExclamationCircle} /> 신고
                    </Button>
                  </div>
                </ProctInfo>
                <CardColumns style={{ marginTop: "15px" }}>
                  {/* <Title className="card-header">상세보기</Title> */}
                  {/* 내가 만든 챌린지인지 */}
                  {!data?.selectMultiChallengeById?.isMine ? (
                    <div style={{ width: "100%", marginBottom: "15px" }}>
                      {!!data?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                        (v) => {
                          return (
                            v.accepted === true && v.user?.id === me?.me?.id
                          );
                        }
                      ).length ? (
                        isAcc?.[0]?.multiChallengeGroupMappers?.reduce(
                          (acc, mcgm) => {
                            if (
                              mcgm?.user?.id === me?.me?.id &&
                              mcgm?.type === "trainer"
                            ) {
                              acc += 1;
                            }
                            return acc;
                          },
                          0
                        ) > 0 ? (
                          <>
                            <GButton
                              variant="read"
                              context="그룹 관리"
                              width="100%"
                              fontSize="20px"
                              fontWeight="700"
                              padding="10px"
                              margin="0 0 10px 0"
                              onClick={() =>
                                history.push(
                                  routes.multiChallengeGroupManagement,
                                  {
                                    id: isAcc[0]?.multiChallengeGroupMappers?.filter(
                                      (v) => {
                                        return v?.user?.id === me?.me?.id;
                                      }
                                    )?.multiChallengeGroup?.id,
                                  }
                                )
                              }
                            />
                            <GButton
                              onClick={() => history.push(routes.message)}
                              variant="create"
                              width="100%"
                              fontSize="20px"
                              fontWeight="700"
                              padding="10px"
                              margin="0 0 10px 0"
                              context="채팅룸 이동"
                            />
                          </>
                        ) : (
                          <>
                            <GButton
                              variant="read"
                              context="내 챌린지 관리"
                              width="100%"
                              fontSize="20px"
                              fontWeight="700"
                              padding="10px"
                              margin="0 0 10px 0"
                              onClick={() =>
                                history.push(
                                  routes.multiChallengeUserManagement,
                                  {
                                    id: isAcc[0]?.multiChallengeGroupMappers?.filter(
                                      (isAcc) => {
                                        return isAcc.user.id === me?.me?.id;
                                      }
                                    )[0]?.id,
                                  }
                                )
                              }
                            />
                            <GButton
                              onClick={() => history.push(routes.message)}
                              variant="create"
                              width="100%"
                              fontSize="20px"
                              fontWeight="700"
                              padding="10px"
                              context="채팅룸 이동"
                            />
                          </>
                        )
                      ) : (
                        <GButton
                          context="챌린지 신청"
                          width="100%"
                          fontSize="20px"
                          fontWeight="700"
                          padding="17px"
                          // onClick={() => onApplyClick(me?.me?.type)}
                          onClick={() => {
                            setModal(true);
                          }}
                          disabled={applied ? true : false}
                        />
                      )}
                    </div>
                  ) : // me?.me?.type === "user" ? (
                  //   <div style={{ width: "100%", marginBottom: "15px" }}>
                  //     <GButton
                  //       context="신청하기 >"
                  //       width="100%"
                  //       fontSize="20px"
                  //       fontWeight="700"
                  //       padding="15px"
                  //       // onClick={onApplyClick}
                  //       disabled={applied ? true : false}
                  //     />
                  //   </div>
                  // ) :
                  null}
                  {!!data?.selectMultiChallengeById?.isMine ? (
                    <>
                      <GButton
                        context="챌린지 관리"
                        variant="update"
                        width="100%"
                        fontSize="20px"
                        fontWeight="700"
                        padding="10px"
                        margin="0 0 10px 0"
                        onClick={() =>
                          history.push(routes?.multiChallengeManagement, {
                            id: data?.selectMultiChallengeById?.id,
                          })
                        }

                        // disabled={applied ? true : false}
                      />
                      <GButton
                        onClick={() => history.push(routes.message)}
                        variant="create"
                        width="100%"
                        fontSize="20px"
                        fontWeight="700"
                        padding="10px"
                        context="채팅룸 이동"
                      />
                    </>
                  ) : null}
                </CardColumns>
              </ChallengeCon>
            </Con>
            <Line />
            <CardColumns>
              <CTitle>상세내용</CTitle>
              <DesCon>
                <Description id="ccc" className="ql-editor">
                  <div></div>
                </Description>
              </DesCon>
            </CardColumns>
          </>
        ) : null
        // <div
        //   style={{
        //     display: "flex",
        //     flexDirection: "column",
        //     alignItems: "center",
        //     gap: "10px",
        //   }}
        // >
        //   <SButton
        //     margin="0 auto"
        //     context="메인화면으로 돌아가기"
        //     onClick={() => history.push("/")}
        //   />
        //   <h3>현재 존재하지 않는 챌린지입니다.</h3>
        // </div>
      }
      <Modal
        size={me?.me?.type === "trainer" ? "" : "lg"}
        centered="true"
        show={modal}
        onHide={() => setModal(false)}
      >
        <Modal.Body>
          <CreateForm onSubmit={handleSubmit(onSubmitValid)}>
            <CardTitle style={{ textAlign: "center" }}>
              챌린지 신청하기
            </CardTitle>
            {me?.me?.type === "user" ? (
              <ApplyCon>
                <PersonalInfo>
                  <ApplyRow>
                    <ColTitle>키 </ColTitle>
                    <ApplyValue
                      {...register("height", {
                        required: {
                          value: true,
                          message: "키를 입력하세요.",
                        },
                      })}
                      type="number"
                      className="form-control"
                    />
                  </ApplyRow>
                  <ApplyRow>
                    <ColTitle>몸무게 </ColTitle>
                    <ApplyValue
                      {...register("weight")}
                      type="number"
                      className="form-control"
                    />
                  </ApplyRow>
                  <ApplyRow>
                    <ColTitle> 성별 </ColTitle>
                    {/* <Gender>
                      <GInput
                        type="radio"
                        name="gender"
                        value={"male" || ""}
                        defaultChecked
                      />
                      남
                      <GInput
                        type="radio"
                        name="gender"
                        value={"female" || ""}
                      />
                      여
                    </Gender> */}
                    <ToggleGender {...register("gender")}>
                      <GInput
                        className={
                          isOn ? "toggle-box toggle__checked" : "toggle-box"
                        }
                      />

                      <GInput
                        className={
                          isOn ? "toggle-icon toggle__checked" : "toggle-icon"
                        }
                      />

                      <ToggleState onClick={toggleHandler} isOn={!isOn}>
                        남자
                      </ToggleState>
                      <ToggleState
                        onClick={toggleHandler}
                        left="105px"
                        isOn={isOn}
                      >
                        여자
                      </ToggleState>
                    </ToggleGender>
                  </ApplyRow>
                </PersonalInfo>
                <Count>
                  <CountRow>
                    <CountTitle>총 트레이너 수 </CountTitle>
                    <div>:</div>
                    <div>
                      {
                        data?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                          (mcc) =>
                            mcc.type === "trainer" && mcc.accepted === true
                        )?.length
                      }{" "}
                      / {data?.selectMultiChallengeById?.trainerCount}
                    </div>
                  </CountRow>
                  <CountRow>
                    <CountTitle>총 유저 수 </CountTitle>
                    <div>:</div>
                    <div>
                      {
                        data?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                          (mcc) =>
                            mcc.type === "trainee" && mcc.accepted === true
                        )?.length
                      }{" "}
                      /{" "}
                      {data?.selectMultiChallengeById?.trainerCount *
                        data?.selectMultiChallengeById?.userCountPerTrainer}
                    </div>
                  </CountRow>
                  <CountRow>
                    <CountTitle>총 참여자 수 </CountTitle>
                    <div>:</div>
                    <div>
                      {
                        data?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                          (mcc) => mcc.accepted === true
                        )?.length
                      }{" "}
                      /{" "}
                      {data?.selectMultiChallengeById?.trainerCount *
                        data?.selectMultiChallengeById?.userCountPerTrainer +
                        data?.selectMultiChallengeById?.trainerCount}
                    </div>
                  </CountRow>
                </Count>
              </ApplyCon>
            ) : (
              <ApplyCon style={{ gridTemplateColumns: "1fr" }}>
                <Count>
                  <CountRow>
                    <CountTitle>총 트레이너 수 </CountTitle>:
                    <div>
                      {
                        data?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                          (mcc) =>
                            mcc.type === "trainer" && mcc.accepted === true
                        )?.length
                      }{" "}
                      / {data?.selectMultiChallengeById?.trainerCount}
                    </div>
                  </CountRow>
                  <CountRow>
                    <CountTitle>총 유저 수 </CountTitle>:
                    <div>
                      {
                        data?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                          (mcc) =>
                            mcc.type === "trainee" && mcc.accepted === true
                        )?.length
                      }{" "}
                      /{" "}
                      {data?.selectMultiChallengeById?.trainerCount *
                        data?.selectMultiChallengeById?.userCountPerTrainer}
                    </div>
                  </CountRow>
                  <CountRow>
                    <CountTitle>총 참여자 수 </CountTitle>
                    <div>:</div>
                    <div>
                      {
                        data?.selectMultiChallengeById?.multiChallengeContracts?.filter(
                          (mcc) => mcc.accepted === true
                        )?.length
                      }{" "}
                      /{" "}
                      {data?.selectMultiChallengeById?.trainerCount *
                        data?.selectMultiChallengeById?.userCountPerTrainer +
                        data?.selectMultiChallengeById?.trainerCount}
                    </div>
                  </CountRow>
                </Count>
              </ApplyCon>
            )}
            {me?.me?.type === "user" &&
            !data?.selectMultiChallengeById?.multiChallengeContracts?.filter(
              (mcc) => mcc.type === "trainer" && mcc.accepted === true
            )?.length ? (
              <Submit
                type="submit"
                className="form-control btn-danger"
                value="등록된 트레이너가 없습니다."
                disabled
              />
            ) : (
              <Submit
                type="submit"
                className="form-control btn-primary"
                value="등록"
              />
            )}
          </CreateForm>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default MultiChallengeInfo;
