import { gql } from "@apollo/client";

export const CREATE_CUSTOMER_SERVICE_MUTATION = gql`
  mutation CreateCustomerService(
    $title: String!
    $type: String!
    $etcType: String
    $contractType: String
    $contractId: Int
  ) {
    createCustomerService(
      title: $title
      type: $type
      etcType: $etcType
      contractType: $contractType
      contractId: $contractId
    ) {
      ok
      error
      id
    }
  }
`;
