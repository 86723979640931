import { gql } from "@apollo/client";

export const UNBLOCK_USER_MUTATION = gql`
  mutation UnblockUser($userId: Int!) {
    unblockUser(userId: $userId) {
      ok
      error
      id
    }
  }
`;
