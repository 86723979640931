//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//챌린지가 마스터의 챌린지 관리화면에서 신청 내역 기능을 담당하는 컴포넌트 입니다.
import { useMutation } from "@apollo/client";
import { Card, CardColumns } from "react-bootstrap";
import styled from "styled-components";
import { UPDATE_MULTICHALLENGE_CONTRACT_MUTATION } from "../../queries/multichallengeContract/updateMultiChallengeContract";
import { CREATE_RECEIPT_FOR_MULTICHALLENGE } from "../../queries/Receipt/creatReceiptForMultiChallenge";
import { GButton } from "../shared/GButton";
import ProfilePhoto from "../shared/ProfilePhoto";

const ContractCard = styled(Card)``;
const ContractCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const MultiChallengeContractInteraction = ({
  contract,
  refetchMultiChallengeData,
  receiptDataRefetch,
}) => {
  const update = (cache, data) => {
    const {
      data: {
        updateMultiChallengeContract: { ok, error },
      },
    } = data;
    if (ok) {
      cache.evict({
        id: `MultiChallengeContract:${contract?.id}`,
      });
      cache.gc();
      refetchMultiChallengeData();
    } else if (!ok && error === "MultiChallenge Reached to Maximum Count.") {
      alert("제한된 트레이너 수로 인해 더 이상 수락할 수 없습니다!");
    } else if (
      !ok &&
      error === "User Count Reached to Limit or Not Enough Trainer."
    ) {
      alert("제한된 참여유저 수로 인해 더 이상 수락할 수 없습니다!");
    }
  };
  const [updateMultiChallengeContract] = useMutation(
    UPDATE_MULTICHALLENGE_CONTRACT_MUTATION,
    {
      update,
      onCompleted: (data) => {
        const {
          updateMultiChallengeContract: { ok, error, id },
        } = data;

        if (ok) {
          createReceiptForMultiChallenge({
            variables: {
              type: "toss",
              sellerId: Number(contract?.user?.id),
              buyerId: Number(contract?.multiChallenge?.user?.id),
              parentId: Number(contract?.multiChallenge?.id),
              contractId: Number(contract?.id),
              contractType: "MultiChallengeContract",
            },
          });
        }
      },
    }
  );
  const [updateMultiChallengeContractfalse] = useMutation(
    UPDATE_MULTICHALLENGE_CONTRACT_MUTATION,
    {
      update,
    }
  );
  const [createReceiptForMultiChallenge] = useMutation(
    CREATE_RECEIPT_FOR_MULTICHALLENGE,
    {
      onCompleted: (data) => {
        const {
          createReceiptForMultiChallenge: { ok },
        } = data;
        if (ok) {
          receiptDataRefetch();
        }
      },
    }
  );
  const selectAcceptance = (accepted) => {
    const confirmBool = window.confirm(
      !!accepted ? "수락 하시겠습니까?" : "거절 하시겠습니까?"
    );

    if (!!confirmBool && accepted === true) {
      updateMultiChallengeContract({
        variables: {
          id: contract?.id,
          accepted,
        },
      });
    } else if (!!confirmBool && accepted === false) {
      updateMultiChallengeContractfalse({
        variables: {
          id: contract?.id,
          accepted,
        },
      });
    }
  };
  return (
    <ContractCard>
      <ContractCardBody>
        <Card.Text>
          <ProfilePhoto
            src={contract?.user?.profilePhoto}
            id={contract?.user?.id}
          />
        </Card.Text>
        <CardColumns style={{ width: "100%", fontWeight: "bold" }}>
          {contract?.user?.username}
        </CardColumns>
        <CardColumns
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Card.Text>
            <GButton
              variant="create"
              context="수락"
              fontWeight="bold"
              width="100px"
              onClick={() => selectAcceptance(true)}
            />
          </Card.Text>
          <Card.Text>
            <GButton
              variant="delete"
              context="거절"
              fontWeight="bold"
              width="100px"
              onClick={() => selectAcceptance(false)}
            />
          </Card.Text>
        </CardColumns>
      </ContractCardBody>
    </ContractCard>
  );
};

export default MultiChallengeContractInteraction;
