//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//mygym 에서 내가 만든 챌린지나 내가 참여한 챌린지를 구현한 컴포넌트 입니다.
import { useEffect, useRef } from "react";
import { useState } from "react";
import { CardDeck } from "react-bootstrap";
import styled from "styled-components";
import { SelectMultiChallengesByUserId } from "../../queries/multiChallenge/selectMultiChallengesByUserId";
import { Title } from "../../routes/MyGym";
import CheckUserToken from "../../utils/checkUserToken";
import colors from "../../utils/colors";
import ContractedMultiChallengeBanner from "./ContractedMultiChallengeBanner";
import MyMultiChallengeBanner from "./MyMultiChallengeBanner";

const Container = styled.div`
  width: 100%;
`;
const Tabs = styled.div`
  width: 100%;
  /* border-top: 2px solid ${colors.defaultGray}; */
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
`;
const Tab = styled.div`
  font-size: 14px;
  /* width: 100%; */
  gap: 10px;
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  margin: -2px;

  cursor: pointer;
  border-bottom: 2px solid ${colors.defaultGray};
  transition: border-top 0.3s;
`;
const TabContentCon = styled.div`
  background-color: rgb(250, 250, 252);
  border-radius: 10px;
  /* max-width: 90%; */
  /* max-height: 1680px; */

  /* padding: 10px 0; */
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
  }
`;

const MultiChallengeManager = () => {
  //state
  const [tab, setTab] = useState(0);
  //ref
  const tabsRef = useRef();
  //useEffect dependency[tab]
  useEffect(() => {
    if (tabsRef?.current) {
      const tabs = Array.from(tabsRef?.current.childNodes);
      tabs.forEach(
        (tab) => (tab.style.borderBottom = `2px solid ${colors.defaultGray}`)
      );
      tabs[tab].style.borderBottom = `2px solid ${colors.bbeego}`;
    }
  }, [tab]);
  //loggedInUser
  const me = CheckUserToken();
  //my multi challenges
  const { data } = SelectMultiChallengesByUserId(me?.me?.id);

  return (
    <Container>
      <Tabs ref={tabsRef}>
        <Tab onClick={(e) => setTab(0)}>내가 만든 챌린지</Tab>
        <Tab onClick={(e) => setTab(1)}>내가 참여한 챌린지</Tab>
      </Tabs>
      <Title> 챌린지</Title>{" "}
      <TabContentCon>
        {tab === 0 ? (
          <CardDeck>
            {data?.selectMultiChallengesByUserId?.myMultiChallenges?.map(
              (mmc) => (
                <MyMultiChallengeBanner
                  key={mmc?.id}
                  multiChallenge={mmc}
                  type="mine"
                />
              )
            )}
          </CardDeck>
        ) : null}
        {tab === 1 ? (
          <CardDeck>
            {data?.selectMultiChallengesByUserId?.contractedMultiChallenges?.map(
              (cmc, idx) => (
                <ContractedMultiChallengeBanner
                  key={idx}
                  multiChallengeContract={cmc}
                  type="contracted"
                />
              )
            )}
          </CardDeck>
        ) : null}
      </TabContentCon>
    </Container>
  );
};

export default MultiChallengeManager;
