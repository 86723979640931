//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { CONTRACT_FRAGMENT } from "../fragments/contractFragment";
import { PTROOM_FRAGMENT } from "../fragments/pTRoomFragment";

const SHOW_PTROOMS_QUERY = gql`
  query ShowPTRooms($take: Int, $lastId: Int) {
    showPTRooms(take: $take, lastId: $lastId) {
      ...PTRoomFragment
    }
  }
  ${PTROOM_FRAGMENT}
`;
const SHOW_PTROOMS_QUERY_NS = gql`
  query ShowPTRooms($take: Int, $lastId: Int) {
    showPTRooms(take: $take, lastId: $lastId) {
      id
      title
      description
      thumbnail
      trainer {
        id
        username
        profilePhoto
      }
      pTPrices {
        id
        month
        price
        contracts {
          ...ContractFragment
        }
      }
      isMine
      createdAt
      avgRate
    }
  }
  ${CONTRACT_FRAGMENT}
`;

export const ShowPTRooms = () => {
  const { loading, data } = useQuery(SHOW_PTROOMS_QUERY, {
    fetchPolicy: "network-only",
  });
  if (!loading) {
    return data;
  }
};

export const ShowPTRooms_NS = (take, lastId) => {
  const { loading, data } = useQuery(SHOW_PTROOMS_QUERY_NS, {
    variables: {
      ...(take && { take }),
      ...(lastId && { lastId }),
    },
    fetchPolicy: "network-only",
  });

  return { loading, data };
};
