//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import CheckUserToken from "../utils/checkUserToken";
import { useHistory } from "react-router";
import { useEffect } from "react";
import Collapsible from "react-collapsible";
import { useLocation } from "react-router-dom";
//counter
import { purecounter_exec } from "../assets_home/assets/vendor/purecounter/purecounter_exec";
import { useLazyQuery } from "@apollo/client";

import { ShowPTRooms_NS } from "../queries/PTRoom/showPTRooms";
import { useState } from "react";
import { routes } from "./routes";
import { CardColumns, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faMale } from "@fortawesome/free-solid-svg-icons";
import PTRoom from "../component/PTroom/PTRoom";
import MultiChallengeCard from "../component/multiChallenge/MultiChallengeCard";
import { SelectMultiChallenges } from "../queries/multiChallenge/selectMultiChallenges";
import { SEND_MAIL_QUERY } from "../queries/mail/sendMail";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import ObjAutoRotate from "../component/scan/ObjAutoRotate";
import faceDetection1 from "../static/faceDetection_1.png";
import faceDetection2 from "../static/faceDetection_2.png";
import objModels from "../static/objModels.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import diet from "../static/diet.jpg";
import study from "../static/study2.jpg";
import label from "../static/label.jpg";
import piano from "../static/piano.jpg";

//device detect
import { isDesktop } from "react-device-detect";
import Spinner from "../component/shared/Spinner";
import colors from "../utils/colors";

const MainContainer = styled.div`
  position: relative;
  margin-top: -15px;
`;
const Container = styled.div`
  margin-top: 40px;
  display: grid;
  /* grid-template-columns: repeat(6, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
`;
const Description = styled.textarea`
  resize: none;
  height: 100px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  outline: none;
`;
const PtCon = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto !important;
  margin-bottom: 10px !important;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid lightgray;
  flex-direction: column;
  box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 7.5fr;
  margin-bottom: 1rem;
`;

const PtInfo = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
`;
const PtButton = styled.button`
  position: relative;
  border: 1px solid #fd780f;
  border-radius: 5px;
  margin-left: auto;
  font-size: 13px;
  padding: 4px;
  color: white;
  background-color: #fd780f;
  box-shadow: 1px 2.5px 5px -1px #ed5f00;
  &:active {
    opacity: 0.9;
    top: 2px;
  }
`;

const PtTitle = styled.div`
  padding-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
  color: #ed5f00;
`;
const PtValue = styled.div`
  display: flex;
  padding: 5px;
  .pTIcon {
    margin-right: 5px;
  }
`;
const ColTitle = styled.div`
  padding: 5px;
  line-height: 1.5;
  font-size: 18px;
  text-align: left;
`;
const ColValue = styled.div`
  border-radius: 5px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 5px;
  word-break: break-all;
`;
const UserRow = styled.div`
  margin: 0 auto;
  padding-bottom: 20px;
  width: 300px;
  /* border: 1px solid lightgray; */
`;
const Profile = styled.img`
  border: 1px solid lightgray;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  object-position: left top;
`;
const UserName = styled.h3`
  text-align: center;
  font-size: 24px;
`;

const PtText = styled.div`
  display: flex;
  min-width: 110px;
`;
const PtIcon = styled.div`
  display: flex;
  margin-left: 25px;
  text-align: center;
  font-weight: 700;
`;
const FAQCard = styled.div`
  background-color: white;
  color: #333;
  box-shadow: 0 4px 6px -1px rgb(0 0 0/0.1), 0 2px 4px -2px rgb(0 0 0/0.1);
  border-top: 1px solid lightgray;
  width: 100%;
  margin: auto;
  font-size: 15px;
  /* border-bottom: 1px solid lightgray; */
  /* border-radius: 10px; */
  /* margin-bottom: 25px; */
  padding: 25px;
  text-align: center;
  /* &:hover {
    color: white;
    background-color: #fd780f;
  } */
`;

const CollapseCon = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  //width: 1100px;
  display: block;
  border-top: 2px solid #222;
  .Collapsible {
    width: 100%;
    /* padding: 3px; */
    /* min-height: 50px; */
    /* padding: 14px !important; */
    border-bottom: 1px solid lightgray;
    /* text-align: center !important; */
    /* background-color: blue; */
    span {
      display: flex;
      position: relative;
      font-size: 16px;
      width: 100%;
      color: #222;

      /* font-weight: bold; */
      padding: 12px;
    }
    .is-open {
      color: #ed5f00;
      span {
        color: #ed5f00;
      }
    }
  }
`;

const ColIcon = styled.i`
  margin: 10px;
  font-size: 1.5rem;
`;
const DetectionCon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
const DetectImage = styled.img`
  width: 50%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

//react-slick custom
export const CusSlider = styled(Slider)`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
  background-attachment: fixed;
  .slick-prev::before,
  .slick-next::before {
    opacity: 0;
    display: none;
  }
`;
const Pre = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: -15%;
  top: 120%;
  /* bottom: -40%; */
  z-index: 3;
  text-align: center;

  i {
    width: 40px !important;
    height: 40px !important;
    color: rgba(255, 255, 255, 0.4);
    &:hover {
      color: white;
      font-size: 2.65rem;

      /* transition: all 300ms ease-in 0s; */
    }
  }
`;

const NextTo = styled(Pre)`
  left: unset;
  right: -15%;
`;

const useScroll = () => {
  const [state] = useState({ x: 0, y: 0 });
  return state;
};

const Section = styled.section`
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${label}); */
  background-repeat: no-repeat;
  background-position: top !important;
  @media (max-width: 700px) {
    background-size: 100% !important;
  }

  /* transition: background-image 1s ease-in-out; */
`;

const Main = () => {
  const location = useLocation();
  useEffect(() => {
    if (!!location?.state?.isFaq) {
      //window.scrollTo(0, 4400);
      window.location.replace("#faq");
    }
  }, [location]);
  const [sendMailQuery] = useLazyQuery(SEND_MAIL_QUERY, {
    onCompleted: (md) => {
      const {
        sendMail: { ok },
      } = md;

      if (ok) {
        setLoading(false);
        alert("메일이 전송되었습니다!");
        onReset();
      }
    },
  });
  const me = CheckUserToken();
  // 새로고침시 제일 위로 감
  const { handleSubmit } = useForm();
  const [_, setMergeData] = useState();

  const inputRef = useRef();
  const [mLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [mData, setMData] = useState();
  const history = useHistory();
  const { y } = useScroll();
  const { data: cData, loading: cLoading } = SelectMultiChallenges(4);
  const { data: pData, loading: pLoading } = ShowPTRooms_NS(4);

  const [newIdx, setNewIdx] = useState(0);
  //slider setting
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    // lazyLoad: "ondemand",
    // cssEase: "liner",
    // dots 감싸기
    appendDots: (dots) => (
      <div
        style={{
          padding: "50px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    nextArrow: (
      <NextTo>
        <i className="bi bi-arrow-right-circle" />
      </NextTo>
    ),
    prevArrow: (
      <Pre>
        <i className="bi bi-arrow-left-circle" />
      </Pre>
    ),

    beforeChange: (oldIndex, newIndex) => {
      if (newIndex == 1) {
        document.getElementsByClassName(
          "b_section"
        )[0].style.backgroundImage = `url(${label})`;
      } else if (newIndex == 0) {
        document.getElementsByClassName(
          "b_section"
        )[0].style.backgroundImage = `url(${diet})`;
      } else if (newIndex == 2) {
        document.getElementsByClassName(
          "b_section"
        )[0].style.backgroundImage = `url(${study})`;
      } else if (newIndex == 3) {
        document.getElementsByClassName(
          "b_section"
        )[0].style.backgroundImage = `url(${piano})`;
      }
    },
  };
  const [inputs, setInpuText] = useState({
    title: "",
    name: "",
    eMail: "",
    context: "",
  });

  const { title, name, eMail, context } = inputs; //객체 비구조화 할당

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInpuText({ ...inputs, [name]: value });
  };

  const onReset = () => {
    setInpuText({
      title: "",
      name: "",
      eMail: "",
      context: "",
    });
  };

  const onSubmitValid = (mailData) => {
    // const { title, context, eMail, name } = mailData;

    if (title !== "" && name !== "" && eMail !== "" && context !== "") {
      sendMailQuery({
        variables: {
          title: String(title),
          context: String(
            '<div style="width:400px; ">' +
              "<strong>이름 : </strong>" +
              name +
              "<br/><br/>" +
              "<strong>이메일 : </strong>" +
              eMail +
              "<br/><br/>" +
              "<strong>문의 내용 : </strong>" +
              context +
              "</div>"
          ),
        },
      });
      setLoading(true);
    } else {
      alert("모두 입력 해주세요.");
    }
  };

  useEffect(() => {
    if (mData) {
      setModal(true);
    }
  }, [mData]);

  useEffect(() => {
    if (modal === false) {
      setMData("");
    }
  }, [modal]);

  useEffect(() => {
    purecounter_exec();
  }, []);

  const makeObjComponents = () => {
    return (
      <CardColumns style={{ display: "flex" }}>
        {false ? (
          <>
            <ObjAutoRotate
              filepath={`${process.env.REACT_APP_SERVER_URL}static/mainmodelw.obj`}
              x={0}
              direction="+"
              zoom={0.2}
              width={window?.innerWidth < 700 ? 200 : 350}
              height={window?.innerWidth < 700 ? 400 : 700}
              aspect={1 / 2}
            />
            <ObjAutoRotate
              filepath={`${process.env.REACT_APP_SERVER_URL}static/mainmodelm.obj`}
              x={-0.3}
              direction="-"
              zoom={-1}
              width={window?.innerWidth < 700 ? 200 : 350}
              height={window?.innerWidth < 700 ? 400 : 700}
              aspect={1 / 2}
            />{" "}
          </>
        ) : isDesktop ? (
          <DetectImage src={objModels} />
        ) : (
          <DetectImage src={objModels} />
        )}
      </CardColumns>
    );
  };

  return (
    <MainContainer>
      {me?.me?.type === "trainer" ? (
        cLoading === true ? (
          <Spinner />
        ) : null
      ) : pLoading === true ? (
        <Spinner />
      ) : null}
      <a
        style={{
          visibility: y < 10 ? "hidden" : "visible",
        }}
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center active"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
      {/* banner */}
      <Section
        id="hero"
        className="b_section d-flex align-items-center "
        style={{ backgroundImage: "url(" + label + ")" }}
      >
        <div
          className="container position-relative"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <CusSlider {...settings}>
            <div className="row justify-content-center">
              <div
                className="col-xl-7 col-lg-9 text-center"
                style={{ margin: "0 auto" }}
              >
                <h1 style={{ color: "white", margin: "0 auto" }}>
                  라벨링 챌린지로 <br />
                  집에서 돈벌자!
                </h1>
                <h2 style={{ color: "white", margin: "0 auto" }}>
                  실시간 개인피팅룸을 경험하세요!
                </h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-xl-7 col-lg-9 text-center"
                style={{ margin: "0 auto" }}
              >
                <h1 style={{ color: "white", margin: "0 auto" }}>
                  다이어트 챌린지로 <br />살 빼고 상금 받자!
                </h1>
                <h2 style={{ color: "white", margin: "0 auto" }}>
                  실시간 개인피팅룸을 경험하세요!
                </h2>
              </div>
            </div>

            <div className="row justify-content-center">
              <div
                className="col-xl-7 col-lg-9 text-center"
                style={{ margin: "0 auto" }}
              >
                <h1 style={{ color: "white", margin: "0 auto" }}>
                  모의고사 챌린지로 <br />
                  등업하고 상금받자!
                </h1>
                <h2 style={{ color: "white" }}>
                  실시간 개인피팅룸을 경험하세요!
                </h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-xl-7 col-lg-9 text-center"
                style={{ margin: "0 auto" }}
              >
                <h1 style={{ color: "white", margin: "0 auto" }}>
                  K-콩쿠르 챌린지로
                  <br />
                  입상하고 스타되자!
                </h1>
                <h2 style={{ color: "white", margin: "0 auto" }}>
                  실시간 개인피팅룸을 경험하세요!
                </h2>
              </div>
            </div>
          </CusSlider>
          <div className="text-center">
            <a
              style={{ cursor: "pointer" }}
              // onClick={() =>
              //   isLoggedIn
              //     ? history.push("/multichallengelist")
              //     : history.push("/login")
              // }
              onClick={() => history.push(routes.multiChallengeList)}
              className="btn-get-started scrollto"
            >
              챌린지 경험
            </a>
          </div>

          {/* <div className="row icon-boxes"> */}
          <Container>
            {me?.me?.type === "trainer"
              ? cData?.selectMultiChallenges?.map((MultiChallenge) => {
                  return (
                    <MultiChallengeCard
                      key={MultiChallenge?.id}
                      {...MultiChallenge}
                      // setModal={setModal}
                      // setModalData={setModalData}
                    />
                  );
                })
              : me?.me?.type === "user"
              ? pData?.showPTRooms?.map((pTRoom, idx) => {
                  return <PTRoom key={idx} {...pTRoom} />;
                })
              : null}
          </Container>
        </div>
      </Section>
      <main id="main">
        <section style={{ lineHeight: "1.4rem" }} id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>About Us</h2>
              <p>
                BBEEGO는 실시간 개인 맞춤형 다이어트 플랫폼으로 바쁜 일상 생활에
                건강 관리를 전문적으로 케어받을 수 있는 서비스입니다.
              </p>
            </div>

            <div className="row content">
              <div className="col-lg-6">
                <p>
                  맞춤형 개인 실시간 PT 관리와 기간별 개인 트레이너 선택과
                  다양한 기능 제공으로 개인의 건강을 관리해 줍니다.
                </p>
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i> 맞춤형 개인
                    트레이너를 기간별로 선택하여 퍼스널 트레이닝을 경험할 수
                    있음
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> 바쁜일상에 실시간
                    채팅 서비스로 1대1 다이어트 자문 서비스
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> 단 1장의 사진으로
                    만드는 AI 기반 체형 3D 스캐닝 서비스(챌린지 준우승자 이상)
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                  가상현실 기반으로 바쁜일상에서 건강관리 다이어트를 전문
                  트레이너와의 실시간 채팅 상담을 통해 언제 어느때나 체험할 수
                  있습니다.
                </p>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/about")}
                  className="btn-learn-more"
                >
                  자세히보기
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="counts" className="counts section-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-5 col-4 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="85"
                    data-purecounter-duration="2"
                    className="purecounter"
                  ></span>
                  <p>전문트레이너</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-4 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="65"
                    data-purecounter-duration="2"
                    className="purecounter"
                  ></span>
                  <p>PT룸</p>
                </div>
              </div>

              {/* <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="30"
                    data-purecounter-duration="2"
                    className="purecounter"
                  ></span>
                  <p>실시간 바디3D 스케닝</p>
                </div>
              </div> */}

              <div className="col-lg-3 col-md-5 col-4 d-md-flex align-items-md-stretch">
                <div className="count-box">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="20"
                    data-purecounter-duration="2"
                    className="purecounter"
                  ></span>
                  <p>챌린지</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          style={{ lineHeight: "1.75rem" }}
          id="about-video"
          className="about-video"
        >
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-6 align-self-baseline">
                {/* <img
                  src="assets/img/about-video.jpg"
                  className="img-fluid"
                  alt=""
                />
                <a
                  href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
                  className="glightbox play-btn mb-4"
                  data-vbtype="video"
                  data-autoplay="true"
                ></a> */}
                {makeObjComponents()}
              </div>

              <div
                className="col-lg-6 pt-3 pt-lg-0 content"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <h3>
                  BBEEGO만의 실시간 채팅을 통한 전문 트레이너와의 온라인 PT와
                  식단관리 서비스를 경험해 보세요!
                </h3>
                <p className="fst-italic">
                  목동의 김○○님은 BBEEGO 서비스를 받으시고 만족도가 올라가
                  유료서비스로 진행하고 계신 고객님이십니다.
                </p>
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i> 맞춤형 개인
                    트레이너를 기간별로 선택하여 개인건강을 관리할수 있음
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> 바쁜일상에 실시간
                    채팅 서비스로 1대1 헬스 건강 체크 시스템
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> 실시간 체형 3D
                    스캐닝 서비스(챌린지 준우승자 이상)
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i> 다양한 서비스로
                    만족스러운 다이어트를 체험하세요!
                  </li>
                </ul>
                <p>
                  저는 바쁜 회사일로 하루 종일 움직이지 않고 일하고 있는데
                  BBEEGO 시스템을 이용하고부터 건강에 적신호였던 것이 다시
                  그린라이트로 변화하고 있습니다 그래서 이 플랫폼 서비스로
                  다양한 관리를 받고 있어 만족스럽습니다.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img
                  src="assets/img/clients/client-1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img
                  src="assets/img/clients/client-2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img
                  src="assets/img/clients/client-3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img
                  src="assets/img/clients/client-4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img
                  src="assets/img/clients/client-5.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div
                className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center"
                data-aos="zoom-in"
              >
                <img
                  src="assets/img/clients/client-6.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section id="testimonials" className="testimonials">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>BBEEGO의 평가</h2>
              <p>
                BBEEGO 플랫폼을 사용하시는 고객님들의 만족도가 높고 극적인
                다이어트 효과를 누린 것에 같은 고민을 가진 타인에게 추천하고
                싶습니다.
              </p>
            </div>

            <div
              className="testimonials-slider swiper"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div style={{ lineHeight: "35px" }}>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.
                      <br />
                      실시간으로 개인 맞춤 서비스를 전문트레이너를 통해
                      비대면으로 지도받을 수 있어 만족스럽습니다.
                      <br /> 특히 저같은 여성의 경우 온라인으로 자신의 몸매를
                      관리받고 싶으나 얼굴 노출 등의 위험성 때문에 꺼려졌는데,
                      <br />
                      빼고 사이트에서는 인공지능 AI 기술로 노출을 차단해버리니
                      트레이너와의 상담이 한결 편해졌어요!
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </div>
                    <img
                      src="assets/img/testimonials/testimonials-1.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>이○경</h3>
                    <h4>챌린지 성공 경험자</h4>
                  </div>
                  <div>
                    <DetectionCon>
                      <DetectImage src={faceDetection1} />
                      <DetectImage src={faceDetection2} />
                    </DetectionCon>
                  </div>
                </div>

                {/* <div className="swiper-slide">
                  <div className="testimonial-item">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/img/testimonials/testimonials-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>홍**</h3>
                    <h4>디자이너</h4>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/img/testimonials/testimonials-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>김**</h3>
                    <h4>대표이사</h4>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/img/testimonials/testimonials-4.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>김**</h3>
                    <h4>프리랜서</h4>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img
                      src="assets/img/testimonials/testimonials-5.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>이**</h3>
                    <h4>대표이사</h4>
                  </div>
                </div> */}
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </section>

        <section
          style={{ lineHeight: "1.75rem" }}
          id="services"
          className="services section-bg"
        >
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>BBEEGO의 서비스</h2>
              <p>
                BBEEGO의 서비스는 전문 트레이너를 통한 실시간 PT룸과 스폰서에
                의한 실시간 챌린지 그리고 챌린지 준우승자 이상에게 제공되는 3D
                스캐닝 서비스로 나눠집니다.
              </p>
            </div>

            <div className="row">
              {/* <div
                onClick={() =>
                  isLoggedIn ? history.push("/ptrooms") : history.push("/login")
                }
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                      ></path>
                    </svg>
                    <i className="ri-virus-fill"></i>
                  </div>
                  <h4>
                    <a>실시간 전문트레이너 예약</a>
                  </h4>
                  <p>
                    각종 바이러스로 인한 건강 면연력이 떨어진 수험생 및 입시
                    준비에 스트레스로 인한 학생 식단 및 건강관리를 동시에
                    검색할수 있는 새로운 온라인 플랫폼
                  </p>
                </div>
              </div> */}

              <div
                // onClick={() =>
                //   isLoggedIn
                //     ? history.push("/challenges")
                //     : history.push("/login")
                // }
                onClick={() => history.push("/challenges")}
                style={{ cursor: "pointer" }}
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="icon-box iconbox-orange ">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                      ></path>
                    </svg>
                    <i className="ri-lungs-line"></i>
                  </div>
                  <h4>
                    <a>실시간 챌린지</a>
                  </h4>
                  <p>
                    자녀의 체중감량을 원하는 부모님들이나 직원의 건강관리를
                    원하는 기업들이 이와 관련해서 전문적인 트레이너와 개인을
                    매칭하여 다이어트 성공을 도울 수 있는 공간입니다. 다이어트
                    성공시 트레이너와 트레이니에게 공히 상금이 주어집니다.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                data-aos="zoom-in"
                data-aos-delay="300"
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   isLoggedIn ? history.push("/ptrooms") : history.push("/login")
                // }
                onClick={() => history.push("/ptrooms")}
              >
                <div className="icon-box iconbox-pink">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"
                      ></path>
                    </svg>
                    <i className="ri-heart-pulse-fill"></i>
                  </div>
                  <h4>
                    <a>실시간 PT룸</a>
                  </h4>
                  <p>
                    여러 전문 트레이너들이 자신만의 노하우를 살려 만든
                    PT룸입니다. 이 공간에서는 회원분들과 트레이너간의 실시간
                    소통을 통해 자신만의 서비스를 받을 수 있습니다.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                data-aos="zoom-in"
                data-aos-delay="100"
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   isLoggedIn
                //     ? history.push("/scanning")
                //     : history.push("/login")
                // }
                onClick={() => history.push("/scanning")}
              >
                <div className="icon-box iconbox-yellow">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"
                      ></path>
                    </svg>
                    <i className="ri-pulse-line"></i>
                  </div>
                  <h4>
                    <a>3D 스캐닝서비스</a>
                  </h4>
                  <p id="chatroom">
                    실시간으로 전신 촬영으로 인한 전체적인 바디 균형과
                    체형교정관리를 할수 있는 실시간 맞춤형서비스 입니다.
                  </p>
                  <br />
                  <p>
                    고성능의 인공지능 AI 서버가 필요한 기능으로 챌린지 준우승자
                    이상에게만 제공되는 특별 서비스입니다. 우승자에게는 3D
                    프린팅 출력물도 제공해드립니다.
                  </p>
                </div>
              </div>

              {/* <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="icon-box iconbox-red">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572"
                      ></path>
                    </svg>
                    <i className="bx bx-slideshow"></i>
                  </div>
                  <h4>
                    <a href="">동영상 강의로 자세교정시스템</a>
                  </h4>
                  <p>
                    유트브로 BBEEGO의 플랫폼은 자세 교정과 간단한 운동 요법과
                    실시간으로 플랫폼 사용자의 후기 영상등 많은 서비스를
                    제공하고 있습니다.
                  </p>
                </div>
              </div> */}

              {/* <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div className="icon-box iconbox-teal">
                  <div className="icon">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 600 600"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke="none"
                        strokeWidth="0"
                        fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                      ></path>
                    </svg>
                    <i className="ri-empathize-fill"></i>
                  </div>
                  <h4>
                    <a href="">맞춤형 나만의 홈트레이너</a>
                  </h4>
                  <p>
                    코로나19로 인한 바이러스로 헬스장을 못가는 고객들의 실시간
                    바디컨설팅을 조회 및 관리할 수 있는 나만의 전문적인 바디
                    균형감을 맞추고 건강관리를 실시간으로 할 수 있습니다.
                  </p>
                </div>
              </div>*/}
            </div>
          </div>
        </section>

        <section style={{ lineHeight: "1.5rem" }} id="cta" className="cta">
          <div className="container" data-aos="zoom-in">
            <div className="text-center">
              <h3 style={{ marginBottom: "5px" }}>BBEEGO만의 실시간 소통</h3>
              <p>
                실시간 채팅으로 전문 트레이너로부터 건강과 균형잡힌 체형, 그리고
                다이어트를 체계적으로 관리 받을 수 있는 상담을 즉시 제공합니다.
              </p>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(routes.consulting);
                }}
                className="cta-btn"
              >
                개인 채팅 상담하기
              </a>
            </div>
          </div>
        </section>

        {/* <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>이벤트</h2>
              <p>
                실시간으로 이벤트를 진행하여 사용자의 헬스 관리를 끌어올리고
                다양한 컨텐츠 와 이벤트 진행으로 참여자의 이용룰을 높이고자
                노력하고 있습니다{" "}
              </p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="150">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">
                    All
                  </li>
                  <li data-filter=".filter-app">공지</li>
                  <li data-filter=".filter-card">이벤트</li>
                  <li data-filter=".filter-web">경품행사</li>
                </ul>
              </div>
            </div>

            <div
              className="row portfolio-container"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-1.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>공지 1</h4>
                    <p>공지</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-1.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="App 1"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>경품행사 3</h4>
                    <p>경품행사</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-2.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Web 3"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-3.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>공지 2</h4>
                    <p>공지</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-3.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="App 2"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-4.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>이벤트 2</h4>
                    <p>이벤트</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-4.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Card 2"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-5.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>경품행사 2</h4>
                    <p>경품행사</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-5.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Web 2"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-6.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>공지 3</h4>
                    <p>공지</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-6.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="App 3"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-7.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>이벤트 1</h4>
                    <p>이벤트</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-7.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Card 1"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-8.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>이벤트 3</h4>
                    <p>이벤트</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-8.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Card 3"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                <div className="portfolio-wrap">
                  <img
                    src="assets/img/portfolio/portfolio-9.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="portfolio-info">
                    <h4>경품행사 3</h4>
                    <p>경품행사</p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-9.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Web 3"
                      >
                        <i className="bx bx-plus"></i>
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section id="team" className="team section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>전문트레이너</h2>
              <p>
                국내의 독보적인 트레이너 커뮤니티를 하고 있고 다양한 피팅을
                경험합니다
              </p>
            </div>

            <div className="row">
              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="assets/img/team/team-1.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>데이비드홍</h4>
                    <span>전문 헬스 트레이너</span>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="assets/img/team/team-2.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Sarah Jhonson</h4>
                    <span>전문 헬스 트레이너</span>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="assets/img/team/team-3.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>William Anderson</h4>
                    <span>전문 헬스 트레이너</span>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="member">
                  <div className="member-img">
                    <img
                      src="assets/img/team/team-4.jpg"
                      className="img-fluid"
                      alt=""
                    />
                    <div className="social">
                      <a href="">
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Amanda Jepson</h4>
                    <span>전문 헬스 트레이너</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section id="pricing" className="pricing">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>BBEEGO의 유료서비스</h2>
              <p>
                BBEEGO의 유료서비스로 다양한 다이어트 컨설팅을 받아 보시기
                바랍니다.
              </p>
            </div>

            <div className="row">
              <div
                className="col-lg-4 col-md-6"
                data-aos="zoom-im"
                data-aos-delay="100"
              >
                <div className="box">
                  <h3>서비스</h3>
                  <h4>
                    {/* <sup>$</sup>  */}
                    {pData?.showPTRooms[0]?.pTPrices[0]?.price}
                    <sup>만원</sup>
                    <span>
                      {" "}
                      /{" "}
                      {pData?.showPTRooms[0]?.pTPrices[0]?.month === 1
                        ? "1개월"
                        : pData?.showPTRooms[0]?.pTPrices[0]?.month === 2
                        ? "2개월"
                        : pData?.showPTRooms[0]?.pTPrices[0]?.month === 3
                        ? "3개월"
                        : pData?.showPTRooms[0]?.pTPrices[0]?.month === 6
                        ? "6개월"
                        : pData?.showPTRooms[0]?.pTPrices[0]?.month === 12
                        ? "12개월"
                        : ""}
                    </span>
                  </h4>
                  <ul>
                    <li>개인프로필등록</li>
                    <li>실시간 3D 스캐닝</li>
                    <li>매주 식단 제공</li>
                    <li>운동 루틴 제공</li>
                  </ul>
                  <div className="btn-wrap">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push("/ptrooms");
                      }}
                      className="btn-buy"
                    >
                      구매하기
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 mt-4 mt-md-0"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="box featured">
                  <h3>서비스</h3>
                  <h4>
                    {/* <sup>$</sup>  */}
                    {pData?.showPTRooms[1]?.pTPrices[0]?.price}
                    <sup>만원</sup>
                    <span>
                      {" "}
                      /{" "}
                      {pData?.showPTRooms[1]?.pTPrices[0]?.month === 1
                        ? "1개월"
                        : pData?.showPTRooms[1]?.pTPrices[0]?.month === 2
                        ? "2개월"
                        : pData?.showPTRooms[1]?.pTPrices[0]?.month === 3
                        ? "3개월"
                        : pData?.showPTRooms[1]?.pTPrices[0]?.month === 6
                        ? "6개월"
                        : pData?.showPTRooms[1]?.pTPrices[0]?.month === 12
                        ? "12개월"
                        : ""}
                    </span>
                  </h4>
                  <ul>
                    <li>개인프로필등록</li>
                    <li>실시간 채팅 상담</li>
                    <li>매주 식단 제공</li>
                    <li>운동 루틴 제공</li>
                  </ul>
                  <div className="btn-wrap">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push("/ptrooms");
                      }}
                      className="btn-buy"
                    >
                      구매하기
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 mt-4 mt-lg-0"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="box">
                  <h3>서비스</h3>
                  <h4>
                    {/* <sup>$</sup> */}
                    {pData?.showPTRooms[2]?.pTPrices[0]?.price}
                    <sup>만원</sup>
                    <span>
                      {" "}
                      /{" "}
                      {pData?.showPTRooms[2]?.pTPrices[0]?.month === 1
                        ? "1개월"
                        : pData?.showPTRooms[2]?.pTPrices[0]?.month === 2
                        ? "2개월"
                        : pData?.showPTRooms[2]?.pTPrices[0]?.month === 3
                        ? "3개월"
                        : pData?.showPTRooms[2]?.pTPrices[0]?.month === 6
                        ? "6개월"
                        : pData?.showPTRooms[2]?.pTPrices[0]?.month === 12
                        ? "12개월"
                        : ""}
                    </span>
                  </h4>
                  <ul>
                    <li>개인프로필등록</li>
                    <li>실시간 3D 스캐닝</li>
                    <li>매주 식단 제공</li>
                    <li>운동 루틴 제공</li>
                  </ul>
                  <div className="btn-wrap">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push("/ptrooms");
                      }}
                      className="btn-buy"
                    >
                      구매하기
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          style={{ lineHeight: "1.5rem" }}
          id="faq"
          className="faq section-bg"
        >
          <div className="container" data-aos="fade-up">
            <div className="section-title" style={{ lineHeight: "40px" }}>
              <h2>BBEEGO는 어떻게 사용해야 하는 것인가요?</h2>
              <p>플랫폼 사용방법과 FAQ 공간입니다.</p>
            </div>
            <CollapseCon>
              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>챌린지라는 서비스는 어떤 것인지 궁금합니다.</span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <p>챌린지는 크게 두가지 종류로 나뉩니다.</p>
                  <p>
                    개인적으로 1대1 챌린지를 생성하여 트레이너의 신청을 받아
                    진행하는 챌린지가 있고,
                  </p>
                  <p>
                    후원자가 되어 챌린지를 생성해서 다른 유저들과 트레이너의
                    참여를 받아 진행하는 챌린지가 있습니다.
                  </p>
                  <p>
                    1대1 챌린지는 혼자 결심해서 다이어트나 몸매 관리가 힘드신
                    분들이 자신에게 제약을 만들어 성공하도록 고무할 수 있고,
                  </p>
                  <p>
                    후원자가 되어 챌린지를 생성하는 경우 자녀의 건강, 체중관리를
                    위해 트레이너와 매칭 시키거나, 기업이 임직원들의
                  </p>
                  <p>
                    건강관리 목적으로 이벤트를 여는 목적으로 사용될 수 있습니다.
                  </p>
                </FAQCard>
              </Collapsible>
              {/* <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>
                      3D 바디스캐팅은 어떤것인지 전체적인 헬스 관련 조회하는
                      시스템인가요?
                    </span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard style={{ backgroundColor: "white" }}>
                  <p>
                    This is the collapsible content. It can be any element or
                    React component you like.
                  </p>
                  <p>
                    It can even be another Collapsible component. Check out the
                    next section!
                  </p>
                </FAQCard>
              </Collapsible> */}
              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>
                      PT룸을 어떻게 개인 맞춤형으로 잡을 수 있는것인지
                      궁금하네요?
                    </span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <p>
                    상단 탭의 PT룸 탭을 클릭하시면 전문 트레이너들의 PT룸
                    상품들을 확인하실 수 있습니다.
                  </p>
                  <p>
                    이 트레이너 분들의 자기소개 또는 PT룸의 목적 등을 확인해서
                    신청하실 수 있고,
                  </p>
                  <p>
                    부족한 부분이나 해당 상품에 궁금한 부분이 있다면 등록 전
                    이더라도 해당 트레이너의 프로필을 방문해서
                  </p>
                  <p>상담 신청을 진행하실 수도 있습니다.</p>
                </FAQCard>
              </Collapsible>
              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>실시간 채팅은 어떨 때 하는 것인지 문의드립니다.</span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <p>실시간 채팅은 첫째로 상담 목적으로 사용됩니다.</p>
                  <p>
                    유저들이 등록을 하기 전에 트레이너에게 궁금한 점이나
                    트레이너가 만든 상품들에 궁금한 점에 대해서
                  </p>
                  <p>질문하고 궁금점을 해소하는 목적으로 사용됩니다.</p>
                  <p>
                    둘째, PT룸이나 챌린지를 등록해서 참여하게 되면 그룹별로
                    채팅룸이 생성됩니다.
                  </p>
                  <p>
                    이 공간에서 트레이너와 소통하면서 운동과 식단 등을 여러
                    필요한 사항들을 관리받을 수 있습니다.
                  </p>
                </FAQCard>
              </Collapsible>
              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>챌린지 스폰서는 어떻게 될 수 있나요?</span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <p>
                    회원가입 후, 챌린지 탭에서 챌린지 등록 버튼을 클릭합니다.
                  </p>
                  <p>
                    다음, 챌린지 성공 조건과 보수금에 대해서 설정을 하신 후
                    등록을 진행합니다.
                  </p>
                  <p>
                    이후 나오는 화면에서 결제하기를 통해 스폰싱 금액을
                    입금하시면 됩니다.
                  </p>
                  <p>이후 자녀 또는 직원이 신청하면 승인을 하십시오.</p>
                </FAQCard>
              </Collapsible>

              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>주문취소 요청은 어떻게 하나요?</span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <p>
                    원칙적으로는 트레이닝이 게시된 이후에는 취소가 불가능합니다.
                    다만,
                  </p>
                  <p>
                    {" "}
                    가분적 용역 또는 콘텐츠(무형재화)의 경우 제공이
                    개시되지(사용하지) 아니한 부분에 대해서는 환불이
                    가능합니다.(일자별 일할 계산)
                  </p>
                  <p>
                    다만, 거래를 정상적으로 완료할 수 없다고 판단될 경우, 당사자
                    간 합의에 따라 '거래를 취소'하실 수 있습니다. '거래 취소
                    요청' 버튼을 클릭합니다.
                  </p>

                  <p>
                    취소를 요청하는 사유를 상대방에게 메시지를 작성하여
                    발송합니다.
                  </p>
                  <p>
                    ※ '주문취소 요청' 은 구매확정된 거래 건에 대해서는
                    활성화되지 않습니다.
                  </p>
                </FAQCard>
              </Collapsible>
              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>부분 취소가 가능한가요?</span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <p>
                    거래를 정상적으로 완료할 수 없다고 판단될 경우 당사자 간
                    합의에 따라 '주문을 취소'하실 수 있으나, 전액 취소만
                    가능하며 부분 취소는 불가합니다.
                  </p>
                </FAQCard>
              </Collapsible>

              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>주문이 취소되면 사용한 할인 쿠폰은 반환되나요?</span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <p>거래가 취소되면 사용한 할인 쿠폰은 반환되지 않습니다.</p>
                </FAQCard>
              </Collapsible>

              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span> 거래 중 분쟁이 발생하면 어떻게 해야 하나요?</span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <>
                    <strong>
                      통신판매중개자로서 회원들에게 온라인 거래장소와 부가
                      정보를 제공하며, 서비스를 통해 이루어지는 회원 상호 간의
                      거래에 대해서는 당사자 간의 합의가 원칙입니다.
                      <br /> 다만, 거래 당사자 간의 합의가 원만하게 이루어지지
                      않을 경우 당사자 중 한 명 이상에게 신청을 받아 원만한 문제
                      해결을 위해 '분쟁 조정' 절차를 진행하고 있습니다.
                    </strong>
                    <br />
                    <p>
                      1. 분쟁이 발생한 경우, '진행·취소 및 환불' 등에 대해
                      당사자 간 1차 합의를 진행합니다.
                    </p>{" "}
                    <p>
                      2. 합의가 원만하게 이루어지지 않을 경우, 아래 내용을
                      준비하여 고객센터를 통해 접수합니다. <br />- 회원 정보 -
                      분쟁 상황 및 당사자 간 1차 합의 진행 상황에 대한 설명 -
                      거래와 관련한 대화 내역, 트레이너의 태도 등의 증빙자료
                    </p>
                    <p>
                      3. 영업일 기준 6일 이내 담당자가 회신을 드리며, 안내에
                      따라 조정 절차에 적극 협조해주세요.
                    </p>
                    4. BBEEGO의 조정으로도 합의되지 않는 거래 건은 안내에 따라
                    '외부 기관'으로 조정을 신청합니다.
                    <p>
                      5. 조정 절차가 완료되면, 결과에 따라 비용 및 기타
                      의무사항을 이행합니다.
                    </p>
                  </>
                </FAQCard>
              </Collapsible>
              <Collapsible
                transitionCloseTime={200}
                trigger={
                  <>
                    <span>회원가입 방법. 계정 찾기. 수정. 탈퇴 등</span>
                    <ColIcon className="bi-chevron-down" />
                  </>
                }
              >
                <FAQCard
                  style={{ backgroundColor: "white", textAlign: "left" }}
                >
                  <p>
                    회원가입은 이메일 인증을 통해 진행되며, 다른 타사 플랫폼
                    정보를 통해서도 회원가입할 수 있습니다.
                  </p>
                  <p>
                    계정 찾기는 회원가입 당시 사용한 이메일을 통해 인증을 하시면
                    암호화된 코드를 통해 로그인하실 수 있습니다.
                  </p>
                  <p>
                    계정 관련 수정 및 삭제 등은 프로필의 프로필 편집을 통해서
                    하실 수 있습니다.
                  </p>
                </FAQCard>
              </Collapsible>
            </CollapseCon>
            {/* <div className="faq-list">
              <ul>
                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    개인 1:1 PT룸 예약을 하면 인기있는 트레이너 겹치면 어떻게
                    컨설팅을 받을수 있나요?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                  >
                    챌린지라는 서비스는 어떤것인지 궁금합니다?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                  >
                    실시간 채팅은 어떨 때 하는 것인지 문의드립니다.{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                  >
                    3D 바디스캐팅은 어떤것인지 전체적인 헬스 관련 조회하는
                    시스템인가요?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                  >
                    PT룸을 어떻게 개인 맞춤형으로 잡을 수 있는 것인지 궁금하네요?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      바쁜 일상 생활에서 운동이 많이 부족했는데 BBEEGO 플랫폼
                      서비스를 받은 후 업무에 활력이 생기고 있습니다.실시간으로
                      개인 맞춤형 서비스에 실시간 전문트레이너를 예약을 잡을 수
                      있어서 편리한 경험에 만족스럽습니다.
                    </p>
                  </div>
                </li>
              </ul>
            </div> */}
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>찾아오시는 길</h2>
              <p></p>
            </div>
            {/* <Map /> */}
            {/* <div>
              
              <iframe
                style={{ border: 0, width: "100%", height: "270px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div> */}

            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <a
                      href="https://map.kakao.com/link/to/파트너스2,37.48349494983248,
      127.12130688850309"
                    >
                      {" "}
                      <i className="bi bi-geo-alt"></i>
                    </a>

                    <h4>사무실 장소:</h4>
                    <p>서울특별시 송파구 법원로 90 파트너스2 502호</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>이메일:</h4>
                    <p>contact@bbeego.com</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>대표전화:</h4>
                    <p>02-2043-9112</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">
                <form
                  onSubmit={handleSubmit(onSubmitValid)}
                  // onSubmit={submitHandler}
                  // action="forms/contact.php"
                  // method="post"
                  // role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group mt-3 mt-md-3">
                      <input
                        name="name"
                        // {...register("name", {
                        //   required: {
                        //     message: "이름은 필수값입니다.",
                        //   },
                        // })}
                        required
                        onChange={onChangeInput}
                        value={name}
                        type="text"
                        className="form-control"
                        placeholder="이름"
                        ref={inputRef}
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-3">
                      <input
                        name="eMail"
                        required
                        // {...register("eMail", {
                        //   required: {
                        //     message: "이메일은 필수값입니다.",
                        //   },
                        // })}
                        onChange={onChangeInput}
                        value={eMail}
                        type="email"
                        className="form-control"
                        placeholder="이메일"
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      required
                      name="title"
                      // {...register("title", {
                      //   required: {
                      //     message: "제목은 필수값입니다.",
                      //   },
                      // })}
                      onChange={onChangeInput}
                      value={title}
                      type="text"
                      className="form-control"
                      placeholder="제목"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      required
                      name="context"
                      onChange={onChangeInput}
                      // {...register("context", {
                      //   required: {
                      //     message: "설명은 필수값입니다.",
                      //   },
                      // })}
                      className="form-control"
                      value={context}
                      rows="5"
                      placeholder="문의 내용"
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">잠시만 로딩중입니다.</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      고객님 메시지 상담 잘받았습니다. 감사합니다.
                    </div>
                  </div>
                  <div className="text-center">
                    {!mLoading ? (
                      <button type="submit">문의 내용 전송</button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled=""
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal centered="true" show={modal} onHide={() => setModal(false)}>
        <Modal.Body>
          <PtCon>
            <PtTitle>{mData?.title}</PtTitle>
            <UserRow>
              <Profile src={mData?.trainer?.profilePhoto} />
              <UserName>{mData?.trainer?.username}</UserName>
            </UserRow>
            <Row>
              <ColTitle>트레이너</ColTitle>
              <ColValue>{mData?.trainer?.username}</ColValue>
            </Row>
            <Row>
              <ColTitle>설명</ColTitle>
              <Description value={mData?.description} readOnly={true} />
            </Row>
          </PtCon>
          <PtCon>
            {mData?.pTPrices?.map((v) => {
              return (
                <PtInfo key={v?.id}>
                  <PtValue>
                    <PtText>기간 : {v?.month}개월 </PtText>
                    <PtText>가격 : {v?.price}만원 </PtText>
                    <PtIcon>
                      <FontAwesomeIcon className="pTIcon" icon={faMale} />{" "}
                      {v?.contracts?.length}
                    </PtIcon>
                  </PtValue>
                  <PtButton
                    onClick={() =>
                      history.push(routes.sale, {
                        title: mData?.title,
                        description: mData?.description,
                        month: v?.month,
                        price: v?.price,
                        trainer: mData?.trainer,
                        pTPriceId: v?.id,
                      })
                    }
                  >
                    등록 요청 <FontAwesomeIcon size="lg" icon={faAngleRight} />
                  </PtButton>
                </PtInfo>
              );
            })}
          </PtCon>
        </Modal.Body>
      </Modal>
    </MainContainer>
  );
};
export default Main;
