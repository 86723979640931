import { useLazyQuery } from "@apollo/client";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  Badge,
  Card,
  CardColumns,
  Container,
  Tab,
  Tabs,
} from "react-bootstrap";
import styled from "styled-components";
import ChatroomModule from "../../../component/chatroom/ChatroomModule";
import { TypeBadge } from "../../../component/shared/TypeBadge";
import { ADMIN_SELECT_CUSTOMER_SERVICES } from "../../../queries/admins/adminSelectCustomerServices/adminSelectCustomerServices";
import colors from "../../../utils/colors";
import { csTypes } from "../../../utils/csTypes";

const RCardColumns = styled(CardColumns)`
  @media (max-width: 1200px) {
    position: absolute;
    width: 0px;
    transition: width 0.3s;
  }
  @media (min-width: 1201px) {
    position: relative;
    width: 250px !important;
    transition: width 0.3s;
  }
`;
const ChatIcon = styled.i`
  position: absolute;
  top: 40px;
  display: none;
  border-radius: 50%;
  background-color: #e4e6eb;
  color: #050505;
  width: 40px;
  height: 40px;
  font-size: 18px;
  padding: 11px;
  margin: 10px;
  cursor: pointer;
  @media (max-width: 1199px) {
    display: block;
  }
  &:hover {
    background-color: #e4e6eb;
    color: ${colors.bbeego};
  }
`;

const CardColumnsCon = styled(CardColumns)`
  @media (max-width: 1200px) {
    position: relative;
    top: 20px;
    display: grid;
    grid-template-columns: 1fr;
  }
  @media (min-width: 1201px) {
    display: grid;
    grid-template-columns: 1fr 5fr;
    column-gap: 10px;
    position: relative;
    top: 20px;
  }
`;

const CustomerServiceManagement = () => {
  //refs
  const listConRef = useRef();
  //states
  const [type, setType] = useState("질문/문의사항");
  const [isListConOpen, setIsListConOpen] = useState(false);
  const [selCS, setSelCS] = useState(false);
  //queries
  const [
    adminSelectCustomerServicesLazyQuery,
    { data: adminCustomerServices },
  ] = useLazyQuery(ADMIN_SELECT_CUSTOMER_SERVICES);
  //functions
  const onChatIconClick = () => {
    setIsListConOpen(!isListConOpen);
  };
  //components
  const onListClick = (cs, e) => {
    setSelCS(cs);
    e.currentTarget.parentNode.childNodes?.forEach(
      (cn) => (cn.style.backgroundColor = "white")
    );
    e.currentTarget.style.backgroundColor = `${colors.listBackground}`;
  };
  const listGen = (list) => {
    const listComps = list?.map((el, idx) => (
      <CardColumns
        key={idx}
        style={{ padding: "15px", cursor: "pointer" }}
        onClick={(e) => onListClick(el, e)}
      >
        <CardColumns>
          [{el?.type}] {el?.title}
        </CardColumns>
      </CardColumns>
    ));
    return (
      <CardColumns
        style={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "0 0 10px -5px gray",
          overflowY: "auto",
          backgroundColor: "white",
          height: "500px",
        }}
      >
        {listComps}
      </CardColumns>
    );
  };
  const tabGen = () => {
    const tabs = csTypes?.map((csType, idx) => (
      <Tab key={idx} title={csType} eventKey={csType}></Tab>
    ));
    const onSelect = (e) => {
      setType(e);
    };
    return (
      <Tabs
        defaultActiveKey={csTypes[0]}
        className="mb-3"
        variant="pills"
        onSelect={onSelect}
        style={{ position: "relative" }}
      >
        {tabs}
      </Tabs>
    );
  };
  //상담 상태
  const csStatusGen = () => {
    if (!!selCS) {
      if (!selCS?.deletedAt && !selCS?.finishedAt) {
        return <TypeBadge variant="primary" context="상담중" />;
      } else if (!selCS?.deletedAt && !!selCS?.finishedAt) {
        return <TypeBadge variant="success" context="상담완료" />;
      } else if (!!selCS?.deletedAt && !!selCS?.finishedAt) {
        return <TypeBadge variant="rejected" context="상담삭제됨" />;
      }
    }
  };

  //useEffects
  useEffect(() => {
    if (!!type) {
      adminSelectCustomerServicesLazyQuery({
        variables: {
          type,
        },
      });
    }
  }, [type]);
  useEffect(() => {
    if (!!isListConOpen) {
      listConRef.current.style.width = "250px";
    } else {
      listConRef.current.style.width = "0px";
    }
  }, [isListConOpen]);
  return (
    <Container style={{ position: "relative" }}>
      <Card style={{ marginBottom: "20px" }}>
        <Card.Body>
          <Card.Title style={{ margin: 0 }}>고객센터관리</Card.Title>
        </Card.Body>
      </Card>
      {tabGen()}
      <ChatIcon onClick={onChatIconClick} className="bi bi-chat-dots-fill" />
      <CardColumns
        style={{
          display: "flex",
          justifyContent: "flex-end",
          minHeight: "30px",
        }}
      >
        {csStatusGen()}
      </CardColumns>
      <CardColumnsCon>
        <RCardColumns ref={listConRef}>
          {listGen(adminCustomerServices?.adminSelectCustomerServices)}
        </RCardColumns>
        <CardColumns
          style={{ height: "500px", boxShadow: "0 0 10px -5px gray" }}
        >
          {!!selCS ? <ChatroomModule id={selCS?.chatRoom?.id} /> : null}
        </CardColumns>
      </CardColumnsCon>
    </Container>
  );
};

export default CustomerServiceManagement;
