import { gql } from "@apollo/client";

export const ADD_DOWNLOAD_COUNT_MUTATION = gql`
  mutation AddDownloadCount($msgId: Int!) {
    addDownloadCount(msgId: $msgId) {
      ok
      error
      id
    }
  }
`;
