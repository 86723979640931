import { useLazyQuery } from "@apollo/client";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
  Card,
  CardColumns,
  Container,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { GButton } from "../../component/shared/GButton";
import Profile from "../../component/shared/Profile";
import { TypeBadge } from "../../component/shared/TypeBadge";
import { SELECT_REPORTS_QUERY } from "../../queries/admins/report/selectReports";
import { dateConvertToCurrent } from "../../utils/date/dateConvertToCurrent";
import { routes } from "../routes";

const ReportManagement = () => {
  //history
  const history = useHistory();
  //refs
  const modalDesRef = useRef();
  //state
  const [tabType, setTabType] = useState("reportOnUser");
  const [modalData, setModalData] = useState(null);
  const [modal, setModal] = useState(false);
  //queries
  const [selectReportsLazyQuery, { data: selectReports }] = useLazyQuery(
    SELECT_REPORTS_QUERY,
    { fetchPolicy: "no-cache" }
  );
  const tabGen = () => {
    const tabTypes = [
      { eventKey: "reportOnUser", title: "신고된 유저" },
      { eventKey: "reportOnContent", title: "신고된 컨텐츠" },
    ];
    const onTabSelect = (e) => {
      setTabType(e);
      switch (e) {
        case "reportOnUser":
          selectReportsLazyQuery({
            variables: {
              isContent: false,
            },
          });
          break;
        case "reportOnContent":
          selectReportsLazyQuery({
            variables: {
              isContent: true,
            },
          });
          break;
      }
    };
    return (
      <Tabs
        defaultActiveKey={"reportOnUser"}
        className="mb-3"
        variant="pills"
        onSelect={(e) => onTabSelect(e)}
      >
        {tabTypes?.map((t, idx) => (
          <Tab key={idx} {...t} />
        ))}
      </Tabs>
    );
  };
  const contextGen = () => {
    const onTitleClick = (sr) => {
      setModalData(sr);
      setModal(true);
    };
    switch (tabType) {
      case "reportOnUser":
        return (
          <CardColumns
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {selectReports?.selectReports?.map((sr) => (
              <CardColumns
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <CardColumns>
                  <Profile
                    src={sr?.user?.profilePhoto}
                    id={sr?.user?.id}
                    username={sr?.user?.username}
                  />
                </CardColumns>
                <CardColumns
                  style={{
                    width: "1px",
                    height: "30px",
                    backgroundColor: "lightgray",
                  }}
                ></CardColumns>
                <CardColumns
                  onClick={() => onTitleClick(sr)}
                  style={{ cursor: "pointer" }}
                >
                  {sr?.title}
                </CardColumns>
              </CardColumns>
            ))}
          </CardColumns>
        );
      case "reportOnContent":
        return (
          <CardColumns
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {selectReports?.selectReports?.map((sr) => (
              <CardColumns
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <CardColumns>
                  {sr?.targetContentType === "PTRoom" ? (
                    <TypeBadge
                      variant="ptroom"
                      context={sr?.targetContentType}
                    />
                  ) : (
                    <TypeBadge
                      variant="challenge"
                      context={sr?.targetContentType}
                    />
                  )}
                </CardColumns>
                <CardColumns
                  style={{
                    width: "1px",
                    height: "30px",
                    backgroundColor: "lightgray",
                  }}
                ></CardColumns>
                <CardColumns>
                  <Profile
                    src={sr?.user?.profilePhoto}
                    id={sr?.user?.id}
                    username={sr?.user?.username}
                  />
                </CardColumns>
                <CardColumns
                  style={{
                    width: "1px",
                    height: "30px",
                    backgroundColor: "lightgray",
                  }}
                ></CardColumns>
                <CardColumns
                  onClick={() => onTitleClick(sr)}
                  style={{ cursor: "pointer" }}
                >
                  {sr?.title}
                </CardColumns>
              </CardColumns>
            ))}
          </CardColumns>
        );
    }
  };
  useEffect(() => {
    selectReportsLazyQuery({
      variables: {
        isContent: false,
      },
    });
  }, []);
  useEffect(() => {}, [selectReports]);
  useEffect(() => {
    if (!!modal) {
      modalDesRef.current.innerHTML = modalData?.description;
    }
  }, [modal]);
  //모달에서 확인하기 버튼 클릭시
  const onContentCheckClick = (modalData) => {
    switch (modalData?.targetContentType) {
      case "PTRoom":
        history.push(routes.sale, { id: modalData?.targetContentId });
        break;
      case "MultiChallenge":
        history.push(routes.multiChallengeInfo, {
          id: modalData?.targetContentId,
        });
        break;
    }
  };
  return (
    <Container
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <Card>
        <Card.Body>
          <Card.Title style={{ margin: 0 }}>신고접수</Card.Title>
        </Card.Body>
      </Card>
      <CardColumns>{tabGen()}</CardColumns>
      <CardColumns>{contextGen()}</CardColumns>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false);
          setModalData(null);
        }}
      >
        <Modal.Header>
          <Modal.Title>신고 내용</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CardColumns
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <CardColumns
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 0.1fr 5fr",
                alignItems: "center",
                rowGap: "10px",
              }}
            >
              <CardColumns>신고자</CardColumns>
              <CardColumns
                style={{
                  width: "1px",
                  height: "30px",
                  backgroundColor: "lightgray",
                }}
              ></CardColumns>
              <CardColumns>
                <Profile
                  src={modalData?.user?.profilePhoto}
                  id={modalData?.user?.id}
                  username={modalData?.user?.username}
                />
              </CardColumns>
              <CardColumns>신고날짜</CardColumns>
              <CardColumns
                style={{
                  width: "1px",
                  height: "30px",
                  backgroundColor: "lightgray",
                }}
              ></CardColumns>
              <CardColumns>
                {dateConvertToCurrent(Number(modalData?.createdAt))}
              </CardColumns>
              <CardColumns>신고대상</CardColumns>
              <CardColumns
                style={{
                  width: "1px",
                  height: "30px",
                  backgroundColor: "lightgray",
                }}
              ></CardColumns>
              <CardColumns>
                <Profile
                  src={modalData?.targetUser?.profilePhoto}
                  id={modalData?.targetUser?.id}
                  username={modalData?.targetUser?.username}
                />
              </CardColumns>
              {modalData?.isContent ? (
                <>
                  <CardColumns>대상상품</CardColumns>
                  <CardColumns
                    style={{
                      width: "1px",
                      height: "30px",
                      backgroundColor: "lightgray",
                    }}
                  ></CardColumns>
                  <CardColumns>
                    <GButton
                      variant="primary"
                      context="확인하기"
                      onClick={() => onContentCheckClick(modalData)}
                    />
                  </CardColumns>
                </>
              ) : null}
            </CardColumns>
            <CardColumns className="ql-editor" ref={modalDesRef}></CardColumns>
          </CardColumns>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ReportManagement;
