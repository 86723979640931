//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ObjRenderer from "../component/scan/objRenderer";

const Container = styled.div`
  width: 100%;
  height: 49vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #2c2c2c;
`;
const Description = styled.div`
  position: fixed;
  bottom: 110px;
  min-width: 200px;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 3px 3px 5px -2px #2c2c2c;
  background-color: white;
  color: black;
`;
const ViewProgress = () => {
  const location = useLocation();
  return (
    <Container>
      <ObjRenderer
        width="100%"
        height="49vw"
        zoom={3}
        filepath={`${location?.state?.avatar}`}
      />
      <Description className="des">{`${
        location?.state?.createdAt
          ? new Date(Number(location?.state?.createdAt)).toJSON().split("T")[0]
          : ""
      }  ${
        location?.state?.weight ? " / " + location?.state?.weight + "KG / " : ""
      }  ${
        location?.state?.fatPercentage
          ? location?.state?.fatPercentage + "%"
          : ""
      }`}</Description>
    </Container>
  );
};
export default ViewProgress;
