import styled from "styled-components";

export const MsgCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
// onlyText
export const MsgContainer = styled.div`
  width: 150%;
  ${(props) =>
    props?.isMine
      ? `
  display: flex;
  flex-direction:row;
  align-items: flex-end;
  justify-content: end;
  `
      : `display: flex;
  flex-direction:row;
  align-items: flex-end;
  justify-content: start;
  `};
  margin-top: 5px;
  gap: 5px;
`;

export const MsgTypeContainer = styled.div`
  width: 100%;
  ${(props) =>
    props?.isMine
      ? `
  display: flex;
  flex-direction:row;
  align-items: flex-end;
  justify-content: end;
  `
      : `display: flex;
  flex-direction:row;
  align-items: flex-end;
  justify-content: start;
  `};
  margin-top: 5px;
  gap: 5px;
`;

export const MsgContent = styled.div`
  // background-color: #d6f4ff;
  /* background-color: ${(props) => (props?.isMine ? "#d6f4ff" : "white")}; */
  ${(props) =>
    props?.isMine
      ? `
background-color:#e15f41;
color:white;
`
      : `background-color: white;`}
  padding: 7px;
  border-radius: 5px;
  margin-top: 5px;
  word-break: break-all;
  text-align: justify;
  max-width: 80%;
  width: fit-content;
  line-height: 20px;
`;

export const MsgFileContent = styled.div`
  // background-color: #d6f4ff;
  /* background-color: ${(props) => (props?.isMine ? "#d6f4ff" : "white")}; */
  ${(props) =>
    props?.isMine
      ? `
background-color:white;
color:white;
`
      : `background-color: white;`}
  padding: 7px;
  border-radius: 5px;
  margin-top: 5px;
  word-break: break-all;
  text-align: justify;
  max-width: 80%;
  width: fit-content;
  line-height: 20px;
`;

export const LinkMsgContent = styled.div`
  ${(props) =>
    props?.isMine
      ? `
  background-color: #d6f4ff;
  `
      : `background-color: white;`}
  padding: 7px;
  border-radius: 5px;
  margin-top: 5px;
  word-break: break-all;
  text-align: justify;
  /* max-width: 80%; */
  width: fit-content;
  line-height: 20px;
  text-decoration: underline;
  color: #0d6efd;
`;

export const YoutubeThumCon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: ${(props) => (props.isMine ? "flex-end" : "flex-start")};
  align-items: "flex-end";
`;

export const DateP = styled.p`
  font-size: 10px;
  padding-bottom: 3px;
  white-space: nowrap;
`;

// 답장

export const ReplyCon = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReplyToTitle = styled.h4`
  font-size: 14px;
  /* color: white; */
  width: 100%;
  ${(props) => (props?.isMine ? `color : white;` : null)}/* line-height: 0.9; */
`;

export const ReplyToContent = styled.div`
  width: 100%;
  font-size: 13px;
  padding: 3px 0;
  border-bottom: 1px solid #ccc;
`;

export const ReplyContent = styled.div`
  padding: 3px;
`;

// 이미지
export const ImgContent = styled.div`
  max-width: 250px;
  margin-top: 5px;
`;

export const LinkMsg = styled.a`
  color: ${(props) => (props.color ? props.color : "black")};
  text-decoration: ${(props) =>
    props.decoration ? props.decoration : "underline"};
  /* &:hover {
    color: ${(props) => (props.hColor ? props.hColor : "orange")};
    text-decoration: ${(props) =>
    props.hDecoration ? props.hDecoration : "underline"};
  } */
`;

export const DownladFile = styled.i`
  width: 50px;
  height: 50px;
  margin: 10px auto;
  border-radius: 50%;
  color: ${(props) => (props.color ? props.color : "#0d6efd")};
  border: 1px solid ${(props) => (props.color ? props.color : "#0d6efd")};
  /* border: 1px solid #222; */
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* color: blue; */
  font-size: 30px;
  &:hover {
  }
`;
export const FileName = styled.p`
  font-size: 13px;
  color: #222;
  text-align: center;
  &:hover {
    color: ${(props) => (props.color ? props.color : "#222")};
    text-decoration: underline;
  }
`;
