//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "")};
  border: ${(props) => (props.border ? props.border : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;
const CouponInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 110px;
`;
const CouponName = styled.div`
  color: ${(props) => (props.color ? props.color : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
`;
const IconContainer = styled.div``;
const CouponIcon = styled.div`
  display: ${(props) => (props.display ? props.display : "")};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : ""};
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  left: ${(props) => (props.left ? props.left : "")};
  transform: ${(props) => (props.transform ? props.transform : "")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "")};
`;
const Shadow = styled.div`
  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "")};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
`;
const ExpiredAtSpan = styled.span`
  color: ${(props) => (props.color ? props.color : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
`;

const SimpleCouponLayout = ({
  style: { container, couponName, couponIcon, iconSize, shadow, expiredAtSpan },
  name,
  expiredAt,
}) => {
  return (
    <Container {...container}>
      <CouponInfo>
        <CouponName {...couponName}>{name}</CouponName>
        <IconContainer>
          <CouponIcon {...couponIcon}>
            <FontAwesomeIcon icon={faDumbbell} size={iconSize} />
          </CouponIcon>
          <Shadow {...shadow} />
        </IconContainer>
        <ExpiredAtSpan {...expiredAtSpan}>
          ~{new Date(Number(expiredAt)).toJSON()?.split("T")?.[0]}
        </ExpiredAtSpan>
      </CouponInfo>
    </Container>
  );
};

export default SimpleCouponLayout;
