//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useReactiveVar } from "@apollo/client";
import { faArrowLeft, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { CardColumns } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/routes";
import colors from "../../utils/colors";
import {
  isMyGymVar,
  myGymTabVar,
  sidebarClick,
  sidebarCloseClick,
  sidebarToggleVar,
} from "../../utils/reactiveVar";

const Container = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  background-color: white;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  left: ${(props) => (props.left ? props.left : "")};
  border: ${(props) => (props.containerBorder ? props.containerBorder : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  display: ${(props) => (props.display ? props.display : "")};
  box-shadow: 0 0 15px -5px gray;
  z-index: 100;
`;
const LeftMenu = styled.span`
  cursor: pointer;
  color: gray;
  font-size: 20px;
  font-weight: bold;
  &:hover {
    color: ${colors.bbeego};
  }
`;

const NavigationLeft = ({
  iconShow,
  fontSize,
  fontWeight,
  position,
  containerBorder,
  borderRadius,
  margin,
  width,
  top,
  left,
  display,
  isAdmin,
}) => {
  const history = useHistory();
  const navRef = useRef();
  const sidebarToggle = useReactiveVar(sidebarToggleVar);
  useEffect(() => {
    document.addEventListener("mousedown", navOutSideClick);
    return () => {
      document.removeEventListener("mousedown", navOutSideClick);
    };
  });
  const navOutSideClick = (event) => {
    if (sidebarToggle && !navRef.current.contains(event.target)) {
      sidebarCloseClick();
    }
  };

  const list = isAdmin
    ? [
        { name: "이체관리", pathname: routes.home },
        { name: "고객센터관리", pathname: routes.customerServiceManagement },
        { name: "신고접수", pathname: routes.report },
      ]
    : [
        { name: "ABOUT US", pathname: routes.about },
        { name: "챌린지", pathname: routes.multiChallengeList },
        { name: "PT룸", pathname: routes.pTRooms },
        { name: "FAQ", pathname: routes.home },
        { name: "고객센터", pathname: routes.customerService },
      ];
  const onLeftMenuClick = (pathname, name) => {
    sidebarClick();
    if (name === "FAQ") {
      history.push({ pathname: routes.home, state: { isFaq: true } });
    } else {
      history.push(pathname);
    }
  };
  const listGen = () => {
    return list?.map((l, idx) => (
      <li key={idx}>
        <LeftMenu onClick={() => onLeftMenuClick(l?.pathname, l?.name)}>
          {l?.name}
        </LeftMenu>
      </li>
    ));
  };
  return (
    <Container
      className="sidebar_custom"
      position={position}
      containerBorder={containerBorder}
      borderRadius={borderRadius}
      margin={margin}
      width={width}
      top={top}
      left={left}
      display={display}
      ref={navRef}
    >
      <CardColumns style={{ display: "flex", justifyContent: "flex-end" }}>
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{
            color: "gray",
            cursor: "pointer",
          }}
          onClick={() => sidebarClick()}
        />
      </CardColumns>
      <CardColumns>
        <ul
          className="sidebar-nav"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {listGen()}
        </ul>
      </CardColumns>
    </Container>
  );
};

export default NavigationLeft;
