//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";

const SRibbon = styled.div`
  width: ${(props) => (props?.ribbonWidth ? props?.ribbonWidth : "142px")};
  height: ${(props) => (props?.ribbonHeight ? props?.ribbonHeight : "142px")};
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
  &::before {
    top: 0;
    left: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #f19066;
    box-sizing: content-box;
  }
  &::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #f19066;
  }
`;
const SRibbonContent = styled.span`
  top: ${(props) => (props?.top ? props?.top : "")};
  bottom: ${(props) => (props?.bottom ? props?.bottom : "")};
  left: ${(props) => (props?.left ? props?.left : "")};
  right: ${(props) => (props?.right ? props?.right : "")};
  margin: ${(props) => (props?.margin ? props?.margin : "")};
  //-webkit-transform: rotate(45deg);
  //-ms-transform: rotate(45deg);
  //transform: rotate(45deg);
  position: ${(props) => (props?.position ? props?.position : "absolute")};
  display: block;
  width: ${(props) => (props?.width ? props?.width : "225px")};
  padding: ${(props) => (props?.padding ? props?.padding : "10px 0")};
  background-color: ${(props) => (props?.bgcolor ? props?.bgcolor : "#f5cd79")};
  box-shadow: ${(props) =>
    props?.boxshadow ? props?.boxshadow : "0 5px 10px rgba(0, 0, 0, 0.192)"};
  border-radius: ${(props) => (props?.borderradius ? props?.borderradius : "")};
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  border: 2px dotted #fff;
  outline: ${(props) =>
    props?.outline ? props?.outline : "5px solid #f5cd79"};
`;

export const Ribbon = (props) => {
  return <SRibbonContent {...props}>{props?.context}</SRibbonContent>;
};
