import { gql, useQuery } from "@apollo/client";
import { CUSTOMERSERVICE_FRAGMENT } from "../../fragments/customerServiceFragment";

export const ADMIN_SELECT_CUSTOMER_SERVICES = gql`
  query AdminSelectCustomerServices($type: String) {
    adminSelectCustomerServices(type: $type) {
      ...CustomerServiceFragment
    }
  }
  ${CUSTOMERSERVICE_FRAGMENT}
`;

export const AdminSelectCustomerServices = (type) => {
  const { data } = useQuery(ADMIN_SELECT_CUSTOMER_SERVICES, {
    variables: {
      type,
    },
  });
  return { data };
};
