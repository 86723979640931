//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql } from "@apollo/client";
import { RECEIPT_FRAGMENT } from "../fragments/receiptFragment";

export const STATEMENT_FRAGMENT = gql`
  fragment StatementFragment on Statement {
    id
    user {
      id
      username
      type
      profilePhoto
    }
    buyer {
      id
      username
      type
      profilePhoto
    }
    bank
    accountNumber
    orgAmount
    amount
    remainAmount
    isConfirmed
    isTransferred
    receipt {
      ...ReceiptFragment
    }
    transactionType
    createdAt
    updatedAt
    deletedAt
  }
  ${RECEIPT_FRAGMENT}
`;
