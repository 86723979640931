//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useReactiveVar } from "@apollo/client";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/routes";
import {
  messageSidebarToggleVar,
  sidebarToggleVar,
} from "../../utils/reactiveVar";
import FooterInteraction from "../main/FooterInteraction";
import MainHeader from "../main/MainHeader";
import NavigationLeft from "./NavigationLeft";

const Body = styled.div`
  margin: 0 auto;
  margin-top: 80px;
  //max-width: 1000px;
  //display: grid;
  //grid-template-columns: 1fr 5fr;
  gap: 10px;
  //padding: 10px;
  //justify-content: center;
  min-height: 1000px;
  @media (max-width: 700px) {
    min-height: unset;
  }
`;

const BodyShort = styled.div`
  margin: 0 auto;
  margin-top: 80px;
  //max-width: 1000px;
  //display: grid;
  //grid-template-columns: 1fr 5fr;
  gap: 10px;
  //padding: 10px;
  //justify-content: center;
  margin-bottom: 100px;
`;

const BodyAbout = styled.div`
  margin: 0 auto;
  //max-width: 1000px;
  //display: grid;
  //grid-template-columns: 1fr 5fr;
  overscroll-behavior-y: contain;
  gap: 10px;
  //padding: 10px;
  //justify-content: center;
  margin-bottom: 100px;
`;

const MainLayout = ({ children }) => {
  const sidebarToggle = useReactiveVar(sidebarToggleVar);
  const msgSidebarToggle = useReactiveVar(messageSidebarToggleVar);
  return (
    <>
      <MainHeader className={sidebarToggle ? "" : "toggle-sidebar"} />
      <Body>
        <NavigationLeft
          iconShow={true}
          borderRadius="10px"
          width="300px"
          margin={sidebarToggle ? "" : "0 0 0 -300px"}
        />
        {children}
      </Body>
      <FooterInteraction />
    </>
  );
};

export default MainLayout;

export const MainLayoutShort = ({ children }) => {
  const sidebarToggle = useReactiveVar(sidebarToggleVar);
  const msgSidebarToggle = useReactiveVar(messageSidebarToggleVar);
  return (
    <>
      <MainHeader className={sidebarToggle ? "" : "toggle-sidebar"} />
      <BodyShort>
        <NavigationLeft
          iconShow={true}
          borderRadius="10px"
          width="300px"
          margin={sidebarToggle ? "" : "0 0 0 -300px"}
        />
        {children}
      </BodyShort>
      <FooterInteraction />
    </>
  );
};

export const MainLayoutAbout = ({ children }) => {
  const sidebarToggle = useReactiveVar(sidebarToggleVar);
  const msgSidebarToggle = useReactiveVar(messageSidebarToggleVar);
  return (
    <>
      <MainHeader className={sidebarToggle ? "" : "toggle-sidebar"} />
      <BodyAbout>
        <NavigationLeft
          iconShow={true}
          borderRadius="10px"
          width="300px"
          margin={sidebarToggle ? "" : "0 0 0 -300px"}
        />
        {children}
      </BodyAbout>
      <FooterInteraction />
    </>
  );
};
