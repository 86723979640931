//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

/*
 * 파일의 확장자(Extension)를 체크하기 위한 함수
 * filename --> serverURL을 포함한 파일명
 * lastDot --> 파일 이름에 '.'이 포함되는 경우가 있기 때문에 lastIndexOf() 사용
 * fileExt --> 확장자 명만 추출한 후 소문자로 변경
 *
 */

const CheckExt = (filename) => {
  let fileLen = filename.length;
  let lastDot = filename.lastIndexOf(".");
  let fileExt = filename
    .substring(lastDot, fileLen)
    .toLowerCase()
    .replace(".", "");

  const fileObj = {
    txt: { type: "file", icon: "bx bxs-file-txt", color: "#222" },
    pdf: { type: "file", icon: "bi bi-file-pdf", color: "#DB0001" },
    apk: { type: "file", icon: "ri-file-download-line", color: "#222" },
    ppt: { type: "file", icon: "ri-file-ppt-2-line", color: "#B43115" },
    doc: { type: "file", icon: "bx bxs-file-doc", color: "#2F4C9F" },
    docx: { type: "file", icon: "bx bxs-file-doc", color: "#2F4C9F" },
    xls: { type: "file", icon: "ri-file-excel-2-line", color: "#206F44" },
    xlsx: { type: "file", icon: "ri-file-excel-2-line", color: "#206F44" },
    zip: { type: "file", icon: "bi bi-file-earmark-zip", color: "#222" },
    hwp: { type: "file", icon: "ri-file-hwp-line", color: "#3F84C5" },
    jpg: { type: "image" },
    jpeg: { type: "image" },
    png: { type: "image" },
    gif: { type: "image" },
  };

  return fileObj[fileExt];
};

export default CheckExt;
