//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";

const Span = styled.span`
  width: ${(props) => (props.width ? props?.width : "")};
  font-size: 12px;
  padding: ${(props) => (props.padding ? props.padding : "")};
  color: ${(props) => (props.color ? props.color : "red")};
  margin-left: ${(props) => (props.marginBottom ? props?.marginBottom : "5px")};
  line-height: ${(props) => (props.lineHeight ? props?.lineHeight : "")};
  display: flex;
  align-items: center;
`;

const UseFormError = ({ message, color }) => {
  return (
    <Span color={color}>
      {message && (
        <>
          <i
            className="bi bi-exclamation-triangle-fill"
            style={{ fontSize: "12px" }}
          />{" "}
          {message}
        </>
      )}
    </Span>
  );
};

export default UseFormError;
