//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import * as faceapi from "face-api.js";

const MODEL_URL = "/models";
export const loader = async () => {
  await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
  await faceapi.loadFaceLandmarkModel(MODEL_URL);
  await faceapi.loadFaceRecognitionModel(MODEL_URL);
};
loader();

export const faceDetection = async (rawFile) => {
  // await faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL);
  //<elements>
  //root
  const root = document.getElementById("root");
  //container
  const container = document.createElement("div");
  container.style.position = "absolute";
  container.style.left = "-9999px";
  //img and canvas container
  const subContainer = document.createElement("div");
  subContainer.style.position = "relative";
  //img
  const img = document.createElement("img");
  img.crossOrigin = "anonymous";
  img.src = rawFile;
  //canvas
  const canvas = document.createElement("canvas");
  canvas.style.position = "absolute";
  canvas.top = 0;
  canvas.left = 0;
  //append all
  subContainer.append(img);
  subContainer.append(canvas);
  container.append(subContainer);
  root.append(container);
  //detect
  const fullFaceDescriptions = await faceapi
    .detectAllFaces(img)
    .withFaceLandmarks();
  canvas.width = img.width;
  canvas.height = img.height;
  if (!fullFaceDescriptions?.[0]) {
    return rawFile;
  }
  const _width = fullFaceDescriptions[0]?.detection._box._width;
  canvas.getContext("2d").drawImage(img, 0, 0);
  const faceLandmarkDrawer = new faceapi.draw.DrawFaceLandmarks(
    fullFaceDescriptions[0].landmarks,
    { lineWidth: _width / 10, drawPoints: false }
  );
  faceLandmarkDrawer.draw(canvas);
  const imgDataUrl = canvas.toDataURL("image/png");
  return imgDataUrl;
};
