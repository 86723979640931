import { gql } from "@apollo/client/core";

export const UPDATE_CUSTOMER_SERVICE_MUTATION = gql`
  mutation UpdateCustomerService($id: Int!, $type: String!) {
    updateCustomerService(id: $id, type: $type) {
      ok
      error
      id
    }
  }
`;
