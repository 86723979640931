//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import gql from "graphql-tag";
import { MULTICHALLENGE_GROUP_FRAGMENT } from "./multiChallengeGroupFragment";

export const MULTICHALLENGE_CONTRACT_FRAGMENT = gql`
  fragment MultiChallengeContractFragment on MultiChallengeContract {
    id
    multiChallenge {
      id
      thumbnail
      title
      reward
      bonus
      baseprice
      user {
        id
        username
        type
        profilePhoto
      }
      multiChallengeContracts {
        type
        accepted
      }
      multiChallengeGroups {
        id
      }
      trainerCount
      userCountPerTrainer
      startDate
      endDate
    }
    user {
      id
      username
      type
      profilePhoto
    }
    type
    height
    weight
    gender
    accepted
    createdAt
    updatedAt
    deletedAt
  }
  ${MULTICHALLENGE_GROUP_FRAGMENT}
`;
