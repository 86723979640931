//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/routes";

const Container = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  background-color: white;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "")};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: ${(props) => (props.position ? props.position : "")};
  top: ${(props) => (props.top ? props.top : "")};
  left: ${(props) => (props.left ? props.left : "")};
  border: ${(props) => (props.containerBorder ? props.containerBorder : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  display: ${(props) => (props.display ? props.display : "")};
`;
const Category = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
`;

const NavigationLeft = ({
  iconShow,
  fontSize,
  fontWeight,
  position,
  containerBorder,
  borderRadius,
  margin,
  width,
  top,
  left,
  display,
}) => {
  return (
    <Container
      position={position}
      containerBorder={containerBorder}
      borderRadius={borderRadius}
      margin={margin}
      width={width}
      top={top}
      left={left}
      display={display}
    >
      <Category fontSize={fontSize} fontWeight={fontWeight}>
        <Link
          to={routes.pTRooms}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100px",
          }}
        >
          PT룸
          {iconShow ? <FontAwesomeIcon icon={faPlay} color="orange" /> : null}
        </Link>
      </Category>
      <Category fontSize={fontSize} fontWeight={fontWeight}>
        <Link
          to={routes.multiChallengeList}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100px",
          }}
        >
          챌린지
          {iconShow ? <FontAwesomeIcon icon={faPlay} color="orange" /> : null}
        </Link>
      </Category>
      {/* <Category fontSize={fontSize} fontWeight={fontWeight}>
        <Link
          to={routes.scanning}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100px",
          }}
        >
          3D 스캔
          {iconShow ? <FontAwesomeIcon icon={faPlay} color="orange" /> : null}
        </Link>
      </Category> */}
    </Container>
  );
};

export default NavigationLeft;
