//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템 서버
//
import styled from "styled-components";
import colors from "../../utils/colors";

const Picture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgb(230, 229, 239);
  /* gap: 2px; */
  color: #b2bec3;
  cursor: pointer;
  width: ${(props) => (props.length ? props.length : "")};
  height: ${(props) => (props.length ? props.length : "")};
  /* border: 2px dashed rgb(99, 110, 114); */
  background-color: white;
  &:hover {
    color: ${colors.bbeegoText};
    border: 2px solid ${colors.bbeegoText};
  }
`;
const AddPicture = (props) => {
  return (
    <Picture length={props.length}>
      <i className="bi bi-camera" />
      <p>썸네일 생성</p>
    </Picture>
  );
};
export default AddPicture;
