//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import Grid from "@toast-ui/react-grid";
import { useRef } from "react";
import { useState } from "react";
import { SButton } from "../shared/SButton";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import AdminSelectChatRoom from "../../queries/admins/chatroom/adminSelectChatRoom";

const Container = styled.div`
  width: 99%;
  background-color: white;
  padding: 10px;
  margin: 15px auto;
  box-shadow: 0 2px 5px #ccc;
  border-radius: 5px;
  .vBtn {
    border: none;
    background-color: white;
    margin: 5px;
    text-align: center;
    width: 100%;
    color: gray;
  }
  .vBtn:hover {
    background-color: orange;
    color: white;
    font-size: 25px;
    border-radius: 5px;
  }
`;

const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

const FontCon = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatRoomGrid = () => {
  const gridRef = useRef();
  let data = AdminSelectChatRoom(100);
  const [aHidden, setHidden] = useState(false);
  const toggleMenu = () => {
    //aHidden = !aHidden;
    setHidden(!aHidden);
  };

  const chatRoomColumns = [
    { name: "id", header: "아이디", align: "center", hidden: true },
    { name: "userId", header: "유저 아이디", align: "center", hidden: true },
    { name: "userName", header: "유저 이름", align: "center", width: 175 },
    { name: "ownerId", header: "오너 아이디", align: "center" },
    { name: "messages", header: "메시지", align: "center" },
    { name: "ptPrice", header: "PTPRICE 아이디", align: "center" },
    { name: "title", header: "챌린지", align: "center" },
    {
      name: "challengeId",
      header: "챌린지 아이디",
      align: "center",
      hidden: true,
    },
    { name: "createdAt", header: "생성 날짜", align: "center" },
    { name: "updatedAt", header: "업데이트 날짜", align: "center" },
    { name: "deletedAt", header: "삭제 날짜", align: "center", hidden: true },
  ];

  useEffect(() => {
    gridRef?.current?.getInstance().resetData(
      data?.adminSelectChatRoom?.map((v) => ({
        ownerId: v.ownerId,
        userId: v.users.reduce((acc, v) => {
          if (!acc) {
            acc += v.id;
            return acc;
          }
          acc += " | " + v.id;
          return acc;
        }, ""),
        userName: v.users.reduce((acc, v) => {
          if (!acc) {
            acc += v.username;
            return acc;
          }
          acc += " | " + v.username;
          return acc;
        }, ""),
        messages: v.messages.length,
        ptPrice: v.ptPrice ? v.ptPrice.id : "",
        title: v.challenge ? v.challenge.title : "",
        challengeId: v.challenge ? v.challenge.id : "",
        createdAt: v.createdAt
          ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
          : v.createdAt,
        updatedAt: v.updatedAt
          ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
          : v.updatedAt,
        deletedAt: v.deletedAt
          ? new Date(Number(v.deletedAt)).toJSON().split("T")[0]
          : v.deletedAt,
      }))
    );
  });
  return (
    <Container>
      <button className="vBtn" onClick={() => toggleMenu()}>
        {aHidden ? (
          <FontCon>
            ChatRoom Close
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </FontCon>
        ) : (
          <FontCon>
            ChatRoom Open
            <FontAwesomeIcon size="2x" icon={faAngleRight} />
          </FontCon>
        )}
      </button>
      {aHidden ? (
        <UserContainer>
          <div style={{ textAlign: "right" }}>
            <SButton
              display="inline-block"
              // onClick={onChallengeCreateClick}
              width="fit-content"
              context="+ 새 챌린지 생성"
              fontSize="12px"
              color="black"
              border="1px solid lightgray"
            />
          </div>
          <Row margin="10px 0">
            {data?.adminSelectChatRoom?.length && (
              <Grid
                ref={gridRef}
                data={data?.adminSelectChatRoom?.map((v) => {
                  return {
                    ownerId: v.ownerId,
                    userId: v.users.reduce((acc, v) => {
                      if (!acc) {
                        acc += v.id;
                        return acc;
                      }
                      acc += " | " + v.id;
                      return acc;
                    }, ""),
                    userName: v.users.reduce((acc, v) => {
                      if (!acc) {
                        acc += v.username;
                        return acc;
                      }
                      acc += "  |" + v.username;
                      return acc;
                    }, ""),
                    messages: v.messages.length,
                    ptPrice: v.ptPrice ? v.ptPrice.id : "",
                    title: v.challenge ? v.challenge.title : "",
                    challengeId: v.challenge ? v.challenge.id : "",
                    createdAt: v.createdAt
                      ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
                      : v.createdAt,
                    updatedAt: v.updatedAt
                      ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
                      : v.updatedAt,
                    deletedAt: v.deletedAt
                      ? new Date(Number(v.deletedAt)).toJSON().split("T")[0]
                      : v.deletedAt,
                  };
                })}
                width="auto"
                bodyHeight={300}
                // onClick={onGridClick}
                columns={chatRoomColumns}
                rowHeaders={["rowNum"]}
                pageOptions={{
                  perPage: 0,
                  useClient: true,
                  type: "scroll",
                }}
              />
            )}
          </Row>
        </UserContainer>
      ) : null}
    </Container>
  );
};

export default ChatRoomGrid;
