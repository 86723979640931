//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

import { useMutation } from "@apollo/client";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { ADD_DOWNLOAD_COUNT_MUTATION } from "../../queries/message/addDownloadCount";
import { SeeFiles } from "../../queries/message/seeFiles";
import colors from "../../utils/colors";

const LinkMsg = styled.a`
  width: 100%;
  color: ${(props) => (props.color ? props.color : "black")};
  text-decoration: ${(props) =>
    props.decoration ? props.decoration : "underline"};
  &:hover {
    color: ${(props) => (props.hColor ? props.hColor : "orange")};
    text-decoration: ${(props) =>
      props.hDecoration ? props.hDecoration : "underline"};
  }
`;
const DownladFile = styled.i`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: ${(props) => (props.color ? props.color : "#0d6efd")};
  border: 2px solid ${(props) => (props.color ? props.color : "#0d6efd")};
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* color: blue; */
  font-size: 25px;
  &:hover {
  }
`;

const FileCon = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 10px;
  word-break: break-all;
`;

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  gap: 5px;
  word-break: break-all;
`;

const FileColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
`;
const FileInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  .date {
    width: 25%;
    text-align: center;
    margin: auto 0;
  }
  .filename {
    /* width: 60%; */
    display: --webkit-box;
    --webkit-line-clamp:3
    --webkit-box-orient:vertical;
    max-width: 65%;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: normal;
  }
`;

const TypeSelect = styled.select`
  max-width: 100%;
  margin: 0 10px 5px 10px;
  padding: 5px 0;
  border: 2px solid lightgray;
  border-radius: 5px;
  color: gray;

  &:focus {
    color: black;
    border: 2px solid ${colors.bbeego};
  }
`;

const FileList = (props) => {
  const data = SeeFiles(props?.id)?.data?.seeFiles;
  const [type, setType] = useState("");

  let userList = []; //파일을 올린 유저
  const fileType = [
    { value: "", name: "모두" },
    { value: "zip", name: "zip" },
    { value: "pdf", name: "pdf" },
    { value: "apk", name: "apk" },
  ]; //파일 타입

  const SelectBox = (props) => {
    const handleChange = (e) => {
      let fType = e.target.value;
      setType(fType);

      //event handler
    };
    return (
      <TypeSelect onChange={handleChange} value={type}>
        {props.options.map((t) => (
          <option
            key={t.value}
            value={t.value}
            // defaultValue={props.defaultValue === t.value}
          >
            {t.name}
          </option>
        ))}
      </TypeSelect>
    );
  };

  data?.forEach((e) => {
    if (!userList.includes(e?.user?.username)) {
      userList.push(e?.user?.username);
    }
  });
  // console.log(userList);

  let zipRegex = new RegExp(
    `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.zip`,
    "g"
  );

  //pdf file detect
  let pdfRegex = new RegExp(
    `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.pdf`,
    "g"
  );
  //apk file detect
  let apkRegex = new RegExp(
    `${process.env.REACT_APP_SERVER_URL}[a-zA-Z0-9가-힣/\s_()-.]+\.apk`,
    "g"
  );

  const onFileClick = (filepath, obj) => {
    downloadClick(filepath);
    // console.log(filepath);
    addDownLoadCount(obj);
  };

  const downloadClick = useCallback((e) => {
    const blob = e;
    const filename = e.split("/static/")[1];
    // const date = new Date().toJSON();
    saveAs(blob, `${filename}`);
  }, []);

  const [addDownloadCountMutation] = useMutation(ADD_DOWNLOAD_COUNT_MUTATION);

  const addDownLoadCount = async ({ id }) => {
    addDownloadCountMutation({
      variables: {
        msgId: Number(id),
      },
    });
  };
  // 파일 분류
  const fileMaker = (obj) => {
    // console.log(obj);
    const { content, createdAt, id, user } = obj;

    const isZip = content?.match(zipRegex);
    const isApk = content?.match(apkRegex);
    const isPdf = content?.match(pdfRegex);

    const ZipFile = (data) => {
      return (
        <div>
          <LinkMsg
            hColor="#0d6efd"
            decoration="unset"
            href={data?.content}
            // onClick={() => onZipClick(z, id)}
            style={{ cursor: "pointer" }}
            key={data?.id}
          >
            <FileCon>
              <DownladFile className="bi bi-file-zip" />
              <FileColumn>
                <div style={{ fontSize: "17px ", fontWeight: "bold" }}>
                  {data?.user?.username}
                </div>{" "}
                <FileRow>
                  <FileInfo>
                    <div className="filename">
                      {data?.content.split("/static/")[1]}
                    </div>
                    <div className="date">
                      {
                        new Date(Number(data?.createdAt))
                          ?.toJSON()
                          ?.split("T")[0]
                      }
                    </div>
                  </FileInfo>
                </FileRow>
              </FileColumn>
            </FileCon>
          </LinkMsg>
        </div>
      );
    };

    const PdfFile = (data) => {
      return (
        <div>
          <LinkMsg
            hColor="red"
            decoration="unset"
            href={obj?.content}
            // onClick={() => onZipClick(z, id)}
            style={{ cursor: "pointer" }}
            key={obj?.id}
          >
            <FileCon>
              <DownladFile color="red" className=" bi bi-file-pdf" />
              <FileColumn>
                <div style={{ fontSize: "17px ", fontWeight: "bold" }}>
                  {data?.user?.username}
                </div>{" "}
                <FileRow>
                  <FileInfo>
                    <div className="filename">
                      {data?.content.split("/static/")[1]}
                    </div>
                    <div className="date">
                      {
                        new Date(Number(data?.createdAt))
                          ?.toJSON()
                          ?.split("T")[0]
                      }
                    </div>
                  </FileInfo>
                </FileRow>
              </FileColumn>
            </FileCon>
          </LinkMsg>
        </div>
      );
    };

    const ApkFile = (data) => {
      return (
        <div>
          <LinkMsg
            hColor="black"
            decoration="unset"
            href={obj?.content}
            // onClick={() => onZipClick(z, id)}
            style={{ cursor: "pointer" }}
            key={obj?.id}
          >
            <FileCon>
              <DownladFile
                color="black"
                className=" bi bi-file-earmark-arrow-down"
              />
              <FileColumn>
                <div style={{ fontSize: "17px ", fontWeight: "bold" }}>
                  {data?.user?.username}
                </div>{" "}
                <FileRow>
                  <FileInfo>
                    <div className="filename">
                      {data?.content.split("/static/")[1]}
                    </div>
                    <div className="date">
                      {
                        new Date(Number(data?.createdAt))
                          ?.toJSON()
                          ?.split("T")[0]
                      }
                    </div>
                  </FileInfo>
                </FileRow>
              </FileColumn>
            </FileCon>
          </LinkMsg>
        </div>
      );
    };

    if (type === "zip" && isZip?.length) {
      return ZipFile(obj);
    } else if (type === "pdf" && isPdf?.length) {
      return PdfFile(obj);
    } else if (type === "apk" && isApk?.length) {
      return ApkFile(obj);
    }
    if (type === "") {
      if (isZip?.length) {
        return ZipFile(obj);
      } else if (isApk?.length) {
        return ApkFile(obj);
      } else if (isPdf?.length) {
        return PdfFile(obj);
      }
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SelectBox options={fileType}></SelectBox>
      {data
        ? data?.map((obj) => {
            return fileMaker(obj);
          })
        : null}
    </div>
  );
};
export default FileList;
