//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { MULTICHALLENGE_FRAGMENT } from "../fragments/multiChallengeFragment";

const SELECT_MULTICHALLENGES_QUERY = gql`
  query SelectMultiChallenges($take: Int, $lastId: Int) {
    selectMultiChallenges(take: $take, lastId: $lastId) {
      ...MultiChallengeFragment
    }
  }
  ${MULTICHALLENGE_FRAGMENT}
`;

const SELECT_MULTICHALLENGES_CARD_QUERY = gql`
  query SelectMultiChallenges($take: Int, $lastId: Int) {
    selectMultiChallenges(take: $take, lastId: $lastId) {
      id
      title
      thumbnail
      description
      user {
        id
        username
        type
        profilePhoto
      }
      isMine
      baseprice
      bonus
      reward
      startDate
      endDate
      term
    }
  }
`;

export const SelectMultiChallenges = (take, lastId) => {
  const { data, loading } = useQuery(SELECT_MULTICHALLENGES_CARD_QUERY, {
    variables: {
      ...(take && { take }),
      ...(lastId && { lastId }),
    },
  });
  return { data, loading };
};
