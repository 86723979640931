//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import client from "./client";
import "bootstrap/dist/css/bootstrap.min.css";
import HttpsRedirect from "react-https-redirect";

ReactDOM.render(
  <ApolloProvider client={client}>
    {process.env.NODE_ENV == "production" ? (
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    ) : null}
    {process.env.NODE_ENV == "development" ? <App /> : null}
  </ApolloProvider>,
  document.getElementById("root")
);
