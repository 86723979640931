//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql } from "@apollo/client";

export const CREATE_REVIEW_BY_USER_MUTATION = gql`
  mutation CreateReviewByUser(
    $type: String!
    $typeId: Int!
    $rate: Int!
    $comment: String
  ) {
    createReviewByUser(
      type: $type
      typeId: $typeId
      rate: $rate
      comment: $comment
    ) {
      ok
      error
      id
    }
  }
`;
