//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@material-ui/core";
import { useState } from "react";
import { Accordion, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import QuestionSet from "./QuestionSet";
import CheckUserToken, { ME } from "../utils/checkUserToken";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../queries/user/updateUser";
import { CREATE_GIVEN_COUPON_MUTATION } from "../queries/givenCoupon/createGivenCoupon";
import { GButton } from "../component/shared/GButton";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  gap: 20px;
`;
const TabTitle = styled.div`
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
  color: rgb(99, 110, 114);
  /* color: white; */
`;
const TabDescription = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const TextRow = styled.div`
  color: ${(props) => (props.color ? props.color : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
`;
const Certificate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
`;
const ModalCon = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const ModalConTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
const TermAgreement = styled.div`
  width: inherit;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`;
const TermTitle = styled.span`
  color: white;
  font-weight: bold;
  font-size: 14px;
`;
const TermIcon = styled.span``;
const TermContextCon = styled.div`
  display: flex;
`;
const TermContext = styled.div`
  padding: 10px;
  font-weight: bold;
`;
const CheckCon = styled.div``;
const CertProgress = styled.div``;
const Message = styled.div`
  color: ${(props) => (props?.color ? props?.color : "")};
  font-weight: bold;
`;

const TrainerCert = () => {
  const me = CheckUserToken();
  const [updateUserMutation] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: ME }],
    // update: (cache, data) => {
    //   const {
    //     data: {
    //       updateUser: { ok },
    //     },
    //   } = data;
    //   if (ok) {
    //     cache.modify({
    //       id: `User:${me?.me?.id}`,
    //       fields: {
    //         type(prev) {
    //           return "trainer";
    //         },
    //       },
    //     });
    //   }
    // },
  });
  const [createGivenCouponMutation] = useMutation(
    CREATE_GIVEN_COUPON_MUTATION,
    {
      onCompleted: (data) => {
        const {
          createGivenCoupon: { ok },
        } = data;
        if (ok) {
          alert("쿠폰이 지급되었습니다. 프로필의 쿠폰함에서 확인하세요!");
        }
      },
    }
  );
  const { register, handleSubmit, setValue, formState, clearErrors } =
    useForm();
  const [modal, setModal] = useState(false);
  const onAcordianclick = (e) => {
    e.target
      .closest(".accContainer")
      .querySelector(".toggle")
      .classList.toggle("open");
    const classList = Array.from(
      e.target.closest(".accContainer").querySelector(".toggle").classList
    );
    if (classList.includes("open")) {
      e.target.closest(".accContainer").querySelector(".icon").style.transform =
        "rotate(90deg)";
    } else {
      e.target.closest(".accContainer").querySelector(".icon").style.transform =
        "rotate(0deg)";
    }
  };
  const evalQuestions = (data) => {
    const { q4, q5, q6, q7, q8, q9, q10 } = data;
    if (
      q4 === "2" &&
      q5 === "3" &&
      q6 === "0" &&
      q7 === "1" &&
      q8 === "4" &&
      q9 === "2" &&
      q10 === "2"
    ) {
      return true;
    } else {
      return false;
    }
  };
  // const onApplyCertificate = (data) => {
  //   const { termCheck1 } = data;
  //   if (!termCheck1) {
  //     return alert("인증 신청을 진행하기 위해서는 약관에 동의해야 합니다.");
  //   }
  //   const result = evalQuestions(data);
  //   if (result) {
  //     updateUserMutation({
  //       variables: {
  //         id: Number(me?.me?.id),
  //         type: "trainer",
  //       },
  //     });
  //     alert("인증 과정을 통과하셨습니다.");
  //     setModal(false);
  //     //트레이너 인증 성공 시 쿠폰 회원들에게 지급할 쿠폰 5개 지급
  //     createGivenCouponMutation({
  //       variables: {
  //         userId: Number(me?.me?.id),
  //         couponId: 1,
  //         count: 5,
  //       },
  //     });
  //   } else {
  //     updateUserMutation({
  //       variables: {
  //         id: Number(me?.me?.id),
  //         trainerAuthUnlockAt: new Date(
  //           new Date().getTime() + 1000 * 60 * 60 * 24
  //         )
  //           .toJSON()
  //           .split("T")[0],
  //       },
  //     });
  //     alert("체점기준에 부합하지 못했습니다. 내일 다시 시도할 수 있습니다.");
  //     setModal(false);
  //   }
  // };
  const changeToTrainer = () => {
    const confirmBool = window.confirm(
      "트레이너로 전환 시 일반 유저로 돌아올 수 없습니다.\n진행하시겠습니까?"
    );
    if (!!confirmBool) {
      updateUserMutation({
        variables: {
          id: Number(me?.me?.id),
          type: "trainer",
        },
      });
      alert("트레이너로 전환되었습니다.");
      window.location.reload();
      // setModal(false);
      //트레이너 인증 성공 시 쿠폰 회원들에게 지급할 쿠폰 5개 지급
      createGivenCouponMutation({
        variables: {
          userId: Number(me?.me?.id),
          couponId: 1,
          count: 5,
        },
      });
    }
  };
  return (
    <Container>
      <TabTitle>트레이너 인증</TabTitle>
      {/* <TabDescription>
        <TextRow color="white">
          트레이너 인증을 통해 트레이너로 승격될 경우 내 PT룸 생성, 챌린지
          트레이너 신청등의 자격이 부여됩니다.
        </TextRow>
        <TextRow color={colors.background} fontSize="12px">
          * 트레이너 인증 절차는 몇가지 질문을 통해 진행됩니다. 하단의 버튼을
          통해 진행해주세요.
        </TextRow>
      </TabDescription> */}
      <Certificate>
        {me?.me?.trainerAuthUnlockAt ? (
          <Message color="red">
            오늘은 더 이상 인증 테스트를 할 수 없습니다. 내일 다시 시도해주세요.
          </Message>
        ) : me?.me?.type === "trainer" ? (
          <Message color="orange">트레이너 인증 완료</Message>
        ) : (
          <GButton
            // onClick={() => setModal(true)}
            onClick={changeToTrainer}
            // context="인증 하기"
            context="트레이너로 전환"
            // bgColor="white"
            margin="auto"
            variant="update"
            // color={colors.bbeego}
          />
        )}
      </Certificate>
      <Modal size="lg" show={modal} onHide={() => setModal(false)}>
        <Modal.Body>
          <ModalCon>
            <ModalConTitle>트레이너 인증</ModalConTitle>
            <CertProgress>
              <QuestionSet
                register={register}
                setValue={setValue}
                formState={formState}
                clearErrors={clearErrors}
              />
            </CertProgress>
            <Accordion className="accContainer">
              <Accordion.Toggle
                className="toggle"
                eventKey="0"
                style={{ width: "100%", border: "none", padding: 0 }}
                onClick={(e) => onAcordianclick(e)}
              >
                <TermAgreement>
                  <TermTitle>동의 약관</TermTitle>
                  <TermIcon className="icon">
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      size="2x"
                      color="white"
                    />
                  </TermIcon>
                </TermAgreement>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0" className="accContent">
                <TermContextCon>
                  <CheckCon>
                    <Checkbox
                      {...register("termCheck1")}
                      style={{ color: "orange" }}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setValue("termCheck1", true);
                        } else {
                          setValue("termCheck1", false);
                        }
                      }}
                    />
                  </CheckCon>
                  <TermContext>
                    트레이너 인증시 사용된 질문과 답변은 통계 목적으로만
                    사용됩니다.
                  </TermContext>
                </TermContextCon>
              </Accordion.Collapse>
            </Accordion>
            <Row style={{ width: "100%", marginTop: "20px" }}>
              <GButton
                variant="delete"
                context="인증 신청"
                margin="auto"
                width="fit-content"
                // onClick={handleSubmit(onApplyCertificate)}
              />
            </Row>
          </ModalCon>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default TrainerCert;
