//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import NavigationHead from "../shared/NavigationHead";
import { Link } from "react-router-dom";
import { routes } from "../../routes/routes";
import { Category } from "@material-ui/icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sidebarClick, sidebarToggleVar } from "../../utils/reactiveVar";
import { CardColumns } from "react-bootstrap";

const Container = styled.div`
  width: fit-content;
  display: flex;
  gap: minmax(auto, 35px);
  align-items: center;
  text-align: center;
  justify-self: center;
  @media (max-width: 1050px) {
    display: none;
  }
`;
const Menu = styled(CardColumns)`
  min-width: 100px;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  &:hover {
    color: #fd780f;
    div {
      display: flex;

      &:hover {
        display: flex;
      }
    }
  }
  a {
    min-width: 100px;
  }
`;
const DummyContainer = styled.div`
  @media (min-width: 1051px) {
    display: none;
  }
`;

const AdminMenu = () => {
  return (
    <>
      <Container>
        <Menu>
          <Link to={routes.home}>이체관리</Link>
        </Menu>
        <Menu>
          <Link to={routes.usersInfo}>유저관리</Link>
        </Menu>
        <Menu>
          <Link to={routes.customerServiceManagement}>고객센터관리</Link>
        </Menu>
        <Menu>
          <Link to={routes.reportManagement}>신고접수</Link>
        </Menu>
      </Container>
      <DummyContainer></DummyContainer>
    </>
  );
};

export default AdminMenu;
