import styled from "styled-components";
import { Container } from "react-bootstrap";
import colors from "../../utils/colors";
import ProfilePhoto from "../shared/ProfilePhoto";
import { FatText } from "../shared/FatText";
import { TypeBadge } from "../shared/TypeBadge";
import { CaseBadge } from "../../routes/UserHistory";
import { GButton } from "../shared/GButton";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes/routes";
const DetailsCon = styled(Container)`
  width: 100%;
  background-color: rgb(250, 250, 252);
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 20px;
  .badge {
    position: absolute;
    right: 20px;
  }
  @media (max-width: 475px) {
    .badge {
      position: unset;
    }
  } ;
`;
const DetailsTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 22px;
  font-weight: 600;
  padding: 0.325rem 0.475rem;
  text-align: center;
  color: ${colors.bbeegoText};
`;
const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const UserInfo = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
`;
const UserCon = styled.div`
  width: ${(props) => (props.width ? props.width : "50%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  gap: 5px;
`;
const ContractType = styled.h2`
  padding: 5px;
  color: rgb(99, 110, 114);
  font-size: 18px;
  font-weight: 600;
`;
const DetailsInfo = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  background-color: white;
  gap: 5px;
  padding: 10px;
`;
const DeInfoTitle = styled.div`
  color: ${colors.bbeegoText};
  font-size: 18px;
  text-align: center;
  font-weight: 600;
`;
const DetailCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 15px;
`;
const InfoCon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  gap: 10px;
`;
const InfoTitle = styled(ContractType)`
  min-width: 125px;
  font-size: 17px;
  @media (max-width: 475px) {
    min-width: 50px;
  }
`;
const InfoValue = styled.div`
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ButtonCon = styled.div`
  width: 95%;
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 10px;
`;

const HistoryDetails = ({ getModal, modalData }) => {
  const history = useHistory();
  return (
    <DetailsCon>
      <DetailsTitle>
        {modalData?.contractType === "MultiChallenge"
          ? modalData?.multiChallenge?.title
          : modalData?.contractType === "PTRoom"
          ? modalData?.contract?.pTPrice?.pTRoom?.title
          : modalData?.contract?.multiChallenge?.title}

        <TypeBadge
          className="badge"
          variant={
            modalData?.contractType === "MultiChallenge"
              ? "payment"
              : modalData?.contractType === "PTRoom"
              ? "ptroom"
              : "challenge"
          }
          context={
            modalData?.contractType === "MultiChallenge"
              ? "Payment"
              : modalData?.contractType === "PTRoom"
              ? "PT Room"
              : "Challenge"
          }
        />
      </DetailsTitle>
      <Info>
        <UserInfo>
          {modalData?.contractType === "MultiChallenge" ? null : (
            <UserCon>
              <ContractType>
                {" "}
                {modalData?.contractType === "PTRoom" ? "판매자" : "참가자"}
              </ContractType>
              <ProfilePhoto
                width="80px"
                height="80px"
                src={modalData?.seller?.ProfilePhoto}
                id={modalData?.seller?.id}
              />
              <FatText fontSize="18px">{modalData?.seller?.username}</FatText>
            </UserCon>
          )}

          <UserCon
            width={
              modalData?.contractType === "MultiChallenge" ? "100%" : "50%"
            }
          >
            <ContractType>
              {modalData?.contractType === "PTRoom" ? "구매자" : "주최자"}
            </ContractType>
            <ProfilePhoto
              width="80px"
              height="80px"
              src={modalData?.buyer?.ProfilePhoto}
              id={modalData?.buyer?.id}
            />
            <FatText fontSize="18px">{modalData?.buyer?.username}</FatText>
          </UserCon>
        </UserInfo>
        <DetailsInfo>
          <DeInfoTitle>거래정보</DeInfoTitle>
          <DetailCon>
            <InfoCon>
              <InfoTitle>제목</InfoTitle>
              <InfoValue>
                {" "}
                {modalData?.contractType === "MultiChallenge"
                  ? modalData?.multiChallenge?.title
                  : modalData?.contractType === "PTRoom"
                  ? modalData?.contract?.pTPrice?.pTRoom?.title
                  : modalData?.contract?.multiChallenge?.title}
              </InfoValue>
            </InfoCon>
            {modalData?.contractType === "MultiChallenge" ? null : (
              <InfoCon>
                <InfoTitle>
                  {modalData?.contractType === "PTRoom" ? "판매자" : "참가자"}
                </InfoTitle>
                <InfoValue>{modalData?.seller?.username}</InfoValue>
              </InfoCon>
            )}
            <InfoCon>
              <InfoTitle>
                {" "}
                {modalData?.contractType === "PTRoom" ? "구매자" : "주최자"}
              </InfoTitle>
              <InfoValue>{modalData?.buyer?.username}</InfoValue>
            </InfoCon>

            <InfoCon>
              <InfoTitle>가격</InfoTitle>
              <InfoValue>
                {modalData?.contractType === "PTRoom"
                  ? (
                      Number(modalData?.contract?.pTPrice?.price) * 10000
                    ).toLocaleString()
                  : modalData?.statement?.amount.toLocaleString()}
                원
              </InfoValue>
            </InfoCon>
            <InfoCon>
              <InfoTitle>생성 날짜</InfoTitle>
              <InfoValue>
                {new Date(Number(modalData?.createdAt)).toJSON().split("T")[0]}
              </InfoValue>
            </InfoCon>
            <InfoCon>
              <InfoTitle>지급여부</InfoTitle>
              <InfoValue>
                <>
                  <CaseBadge
                    variant={
                      modalData?.transactionStatus === "거래 진행중"
                        ? "progress"
                        : modalData?.transactionStatus === "거래 성공"
                        ? "success"
                        : "failed"
                    }
                  >
                    {" "}
                    {modalData?.transactionStatus === "거래 진행중"
                      ? "진행 중"
                      : modalData?.transactionStatus === "거래 성공"
                      ? "성공"
                      : "실패"}
                  </CaseBadge>
                </>
              </InfoValue>
            </InfoCon>
          </DetailCon>
        </DetailsInfo>
        <ButtonCon>
          <GButton
            variant="create"
            onClick={() =>
              modalData?.contractType === "PTRoom"
                ? history.push(routes.sale, {
                    id: modalData?.contract?.pTPrice?.pTRoom?.id,
                  })
                : history.push(routes.multiChallengeInfo, {
                    id:
                      modalData?.contractType === "MultiChallenge"
                        ? modalData?.multiChallenge?.id
                        : modalData?.contract?.multiChallenge?.id,
                  })
            }
            context={
              modalData?.contractType === "PTRoom" ? "PT룸 이동" : "챌린지 이동"
            }
          />
          <GButton
            variant="delete"
            onClick={() => getModal(false)}
            context="나가기"
          />
        </ButtonCon>
      </Info>
    </DetailsCon>
  );
};
export default HistoryDetails;
