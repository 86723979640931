//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { CardColumns } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { routes } from "../../routes/routes";
import { FatText } from "./FatText";

const SProfilePhoto = styled.img`
  width: ${(props) => (props.width ? props.width : "40px")};
  height: ${(props) => (props.height ? props.height : "40px")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "50%"};
  border: ${(props) => (props.border ? props.border : "2px solid #ccc")};
  object-fit: cover;
  object-position: left top;
  display: ${(props) => (props.display ? props.display : "")};
  ${(props) =>
    props?.isRes
      ? `@media (max-width: 770px) {
    width: 60px;
    height: 60px;
  }`
      : ""};
`;

const Profile = ({
  src,
  id,
  fontSize,
  color,
  width,
  height,
  margin,
  borderRadius,
  border,
  display,
  another,
  isRes, //반응형
  username,
}) => {
  //console.log("process.env.SERVER_URL : ", process.env);
  return (
    <Link
      to={{ pathname: `${routes.myGym}${id}`, state: { id } }}
      style={{ display: "block", width: "fit-content" }}
    >
      <CardColumns
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <SProfilePhoto
          src={src ? src : ""}
          onError={(e) =>
            (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
          }
          width={width}
          height={height}
          margin={margin}
          borderRadius={borderRadius}
          border={border}
          isRes={isRes}
          display={display}
        />
        <FatText fontSize={fontSize} color={color}>
          {username}
        </FatText>
      </CardColumns>
    </Link>
  );
};

export default Profile;
