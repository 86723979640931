//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { PURCHASE_CANCEL_MUTATION } from "../../queries/Receipt/purchaseCancel";
import { UPDATE_RECEIPT } from "../../queries/Receipt/updateReceipt";
import { purchaseCancel } from "../../utils/toss/purchaseCancel";
import { GButton } from "../shared/GButton";
import { TypeBadge } from "../shared/TypeBadge";
import {
  CaseBadge,
  CDate,
  CNSContents,
  CNSTitle,
  ConTitle,
  ConValue,
  Detail,
  HiddenDiv,
  HisInfo,
  InfoContainer,
  InfoHisCard,
  InfoImage,
  PReqest,
  UserCon,
} from "../../routes/UserHistory";

const BuyerHistoryCard = ({ rb, getModalData }) => {
  const [disabled, setDisabled] = useState(false);
  const [modal, setModal] = useState(false);
  const [cancelText, setCancelText] = useState("");

  //mutation
  const onPurchaseCancelCompleted = (data) => {
    const {
      purchaseCancel: { ok },
    } = data;
    if (ok) {
      rb?.refetch();
      rb?.setOnce(false);
      setModal(false);
    }
  };
  const [purchaseCancelMutation] = useMutation(PURCHASE_CANCEL_MUTATION, {
    onCompleted: onPurchaseCancelCompleted,
  });
  const onDetails = (data) => {
    getModalData(data);
  };

  const onCancel = (e) => {
    setCancelText(e.target.value);
  };
  const onSubmit = () => {
    var result = window.confirm("취소를 요청합니다.");
    if (result) {
      purchaseCancel(rb?.purchaseLog?.paymentKey, String(cancelText)).then(
        (res) => {
          const { data } = res;
          purchaseCancelMutation({
            variables: {
              receiptId: rb?.id,
              cancelJson: JSON.stringify(data),
            },
          });
        }
      );
    }
  };

  useEffect(() => {
    rb?.buyerTransactionStatus === "구매자 금액 지불 함 "
      ? setDisabled(false)
      : setDisabled(true);
  }, []);

  const [updateReceipt] = useMutation(UPDATE_RECEIPT, {
    onCompleted: (data) => {
      const {
        updateReceipt: { ok },
      } = data;
      if (ok) {
        alert("확정 되었습니다.");
      }
    },
  });

  const OnConfirm = () => {
    updateReceipt({
      variables: {
        id: Number(rb?.id),
        sellerId: Number(rb?.seller?.id),
        buyerId: Number(rb.buyer.id),
        contractId: Number(rb.contract.id),
        contractType: "PTRoom",
        type: "구매자 구매 확정",
      },
    });
  };

  return (
    <InfoContainer>
      <HiddenDiv className="appear">
        <CDate>{new Date(Number(rb?.createdAt)).toJSON()?.split("T")[0]}</CDate>
        <Detail>
          <div onClick={() => onDetails(rb)}>결제 상세보기 </div>
        </Detail>
      </HiddenDiv>

      <InfoHisCard>
        <CDate className="hidden">
          {new Date(Number(rb?.createdAt)).toJSON()?.split("T")[0]}
        </CDate>
        <UserCon>
          <InfoImage
            borderRa
            src={rb?.contract?.pTPrice?.pTRoom?.thumbnail}
            onError={(e) =>
              (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
            }
          />
        </UserCon>
        <HisInfo>
          <div style={{ width: "100%" }}>
            <h2>{rb?.contract?.pTPrice?.pTRoom?.title} </h2>
            <div
              style={{
                fontSize: "13.5px",
                fontWeight: "600",
              }}
            >
              {" "}
              {rb?.contract?.pTPrice?.month}개월
            </div>{" "}
            <div>{rb?.contract?.pTPrice?.price}0,000원</div>
          </div>
          <div
            className="hidden"
            style={{
              marginTop: "5px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <TypeBadge
              className="hidden"
              width="100px"
              variant="ptroom"
              context="PT Room"
            />
          </div>
          {/* <div>기간(챌린지)</div> */}
          {/* <div>
          <div style={{ fontWeight: "600" }}>
            {new Date(Number(rb?.createdAt)).toJSON()?.split("T")[0]}
          </div>
          <div style={{ textAlign: "center", fontWeight: "600" }}>~</div>

          <div style={{ fontWeight: "600" }}>
            {" "}
            {new Date(Number(rb?.createdAt)).toJSON()?.split("T")[0]}
          </div>
        </div> */}
        </HisInfo>

        <Detail className="hidden">
          <div onClick={() => onDetails(rb)}>상세보기</div>
        </Detail>
        <PReqest>
          <TypeBadge
            className="appear"
            width="80px"
            variant="ptroom"
            context="PT Room"
            fontSize="12px"
            height="25px"
          />
          {!rb?.purchaseLog?.deletedAt ? (
            disabled ? (
              <CaseBadge
                variant={
                  rb?.transactionStatus === "거래 진행중"
                    ? "progress"
                    : rb?.transactionStatus === "거래 성공"
                    ? "success"
                    : "failed"
                }
              >
                {" "}
                {rb?.transactionStatus === "거래 진행중"
                  ? "진행 중"
                  : rb?.transactionStatus === "거래 성공"
                  ? "성공"
                  : "실패"}
              </CaseBadge>
            ) : (
              <GButton
                fontSize="12px"
                variant="create"
                disabled={disabled}
                context="구매확정"
                onClick={OnConfirm}
              />
            )
          ) : null}
          {rb?.purchaseLog?.deletedAt ? (
            <CaseBadge variant="success">취소 완료</CaseBadge>
          ) : (
            <GButton
              fontSize="12px"
              variant="delete"
              // disabled={disabled}
              context="취소요청"
              width="80px"
              onClick={() => setModal(true)}
            />
          )}
        </PReqest>
      </InfoHisCard>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        centered="true"
        size="m"
        className="cnsModal"
      >
        <Modal.Body>
          <CNSTitle>취소 요청</CNSTitle>
          <CNSContents>
            <ConTitle>취소 사유 : </ConTitle>
            <ConValue
              onChange={onCancel}
              placeholder="취소 사유를 입력해주세요..."
            />
          </CNSContents>
          <div style={{ padding: "5px 10px" }}>
            <GButton
              onClick={() => onSubmit()}
              variant="create"
              width="100%"
              context="취소 요청하기"
            />
          </div>
        </Modal.Body>
      </Modal>
    </InfoContainer>
  );
};
export default BuyerHistoryCard;
