//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//setState : 부모 component 에서 setState 함수를 받아서 사용한다.
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { SEARCH_USERS_QUERY } from "../../queries/user/searchUsers";
import colors from "../../utils/colors";
import ProfilePhoto from "../shared/ProfilePhoto";

const Container = styled.div`
  width: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Input = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
`;
const SearchUserListBox = styled.div`
  width: 100%;
  max-height: 280px;
  border-radius: 5px;
  background-color: white;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: column;
  padding: 5px;
  gap: 10px;
  overflow-y: auto;
`;
const UserRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;
const Username = styled.div`
  font-weight: bold;
`;

export const CouponUserSelect = ({ selectUser, setSelectUser }) => {
  const { register, watch, setValue } = useForm();
  const [showSearchBox, setShowSearchBox] = useState("none");
  const { data } = useQuery(SEARCH_USERS_QUERY, {
    variables: {
      keyword: watch().searchText,
      type: "user",
    },
    ...(!watch().searchText && { skip: true }),
  });
  const onInputFocus = () => {
    setShowSearchBox("flex");
  };
  const outFocus = (e) => {
    if (!Array.from(e?.target?.classList).includes("searchUserList")) {
      setShowSearchBox("none");
    }
  };
  useEffect(() => {
    window.addEventListener("click", outFocus);
    return () => {
      window.removeEventListener("click", outFocus);
    };
  }, []);
  useEffect(() => {
    setValue("searchText", selectUser?.username);
  }, [selectUser]);
  const onUserSelect = (user) => {
    setSelectUser(user);
  };
  return (
    <Container>
      <Input
        {...register("searchText")}
        className="searchUserList"
        type="text"
        onFocus={onInputFocus}
        autoComplete="off"
        placeholder="유저 검색"
      />
      <SearchUserListBox display={showSearchBox} className="searchUserList">
        {data?.searchUsers?.map((user) => (
          <UserRow key={user.id} onClick={() => onUserSelect(user)}>
            <ProfilePhoto
              src={user.profilePhoto}
              width="30px"
              height="30px"
              borderRadius="50%"
              another={true}
            />
            <Username>{user.username}</Username>
          </UserRow>
        ))}
      </SearchUserListBox>
    </Container>
  );
};
