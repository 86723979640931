//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import AccountInfo from "../Accounts/AccountInfo";
import ApplySticker from "../Accounts/ApplySticker";
import BlockList from "../Accounts/BlockList";
import EditPassword from "../Accounts/EditPassword";
import TrainerCert from "../Accounts/TrainerCert";
import colors from "../utils/colors";
import MyGymEdit from "./MyGymEdit";

const SettingsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 768px;
  background-color: rgb(250, 250, 252);
  padding: 0 !important;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  @media (max-width: 660px) {
    width: 90%;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  width: 100%;
  border-bottom: 1px solid #ccc;
`;
const Tab = styled.div`
  padding: 20px 30px;
  cursor: pointer;
  font-weight: bold;
  @media (max-width: 660px) {
    max-width: 85px;
    text-align: center;
    padding: 20px 10px;
  }
`;
const Settings = styled.div``;
const TabCon = styled.div`
  width: 100%;
`;

function Setting() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const _tabs = useRef();
  const [tab, setTab] = useState(1);
  const onTabClick = (tabNo, { target }) => {
    target.parentElement.childNodes.forEach(
      (t) => (t.style.borderBottom = "1px solid white")
    );
    target.parentElement.childNodes.forEach((t) => (t.style.color = "#636e72"));
    target.parentElement.childNodes.forEach(
      (t) => (t.style.color = `3px solid${colors.bbeego}`)
    );
    target.style.borderBottom = `3px solid${colors.bbeego}`;
    target.style.color = `${colors.bbeego}`;
    target.style.fontWeight = `bold`;
    setTab(tabNo);
  };
  useEffect(() => {
    if (_tabs?.current) {
      const tabs = _tabs?.current?.childNodes;
      tabs[tab - 1].style.borderBottom = `3px solid${colors.bbeego}`;
      tabs[tab - 1].style.color = `${colors.bbeego}`;
    }
  }, []);
  return (
    <SettingsContainer id="main">
      <Tabs className="Tabs" ref={_tabs}>
        <Tab
          className="tab"
          onClick={(e) => {
            onTabClick(1, e);
          }}
        >
          프로필 변경
        </Tab>
        <Tab
          className="tab"
          onClick={(e) => {
            onTabClick(2, e);
          }}
        >
          비밀번호 변경
        </Tab>
        <Tab
          className="tab"
          onClick={(e) => {
            onTabClick(3, e);
          }}
        >
          트레이너 인증
        </Tab>
        {/* {me?.me?.type === "trainer" ? (
          <Tab
            className="tab"
            onClick={(e) => {
              onTabClick(4, e);
            }}
          >
            스티커 발급 신청
          </Tab>
        ) : null} */}
        <Tab
          className="tab"
          onClick={(e) => {
            onTabClick(5, e);
          }}
        >
          계정
        </Tab>
        <Tab
          className="tab"
          onClick={(e) => {
            onTabClick(6, e);
          }}
        >
          차단목록
        </Tab>
      </Tabs>
      <Settings className="SettingsCon">
        {tab === 1 ? (
          <TabCon>
            <MyGymEdit />
          </TabCon>
        ) : null}
        {tab === 2 ? (
          <TabCon>
            <EditPassword {...location} />
          </TabCon>
        ) : null}
        {tab === 3 ? (
          <TabCon>
            <TrainerCert />
          </TabCon>
        ) : null}
        {tab === 4 ? (
          <TabCon>
            <ApplySticker />
          </TabCon>
        ) : null}
        {tab === 5 ? (
          <TabCon>
            <AccountInfo />
          </TabCon>
        ) : null}
        {tab === 6 ? (
          <TabCon>
            <BlockList />
          </TabCon>
        ) : null}
      </Settings>
    </SettingsContainer>
  );
}
export default Setting;
