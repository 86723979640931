import DOMPurify from "dompurify";
import { useState } from "react";
import { useEffect } from "react";
import { dateConvertToCurrentTime } from "../../../utils/date/dateConvertToCurrentTime";
import {
  DateP,
  MsgContainer,
  MsgContent,
  ReplyCon,
  ReplyContent,
  ReplyToContent,
  ReplyToTitle,
} from "../../../utils/msgStyles";
import { getExt } from "../ReplyMessage";

const TypeMsg = ({ isMine, createdAt, msg, repliedMessage, user }) => {
  const sanitizer = DOMPurify.sanitize;
  const reply = repliedMessage;
  const replyMsg = repliedMessage?.content;

  const [content, setContent] = useState(null);
  // console.log("repliedMessage:", replyMsg);

  const imgArr = ["jpg", "jpeg", "png", "gif"];
  const fileArr = [
    "txt",
    "pdf",
    "apk",
    "ppt",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "zip",
    "hwp",
  ];

  useEffect(() => {
    let fileRegex = replyMsg?.match(process.env.REACT_APP_SERVER_URL);
    if (fileRegex?.length > 0) {
      let filename = String(replyMsg?.split("/static/")[1]);
      let ext = getExt(filename);
      if (imgArr.includes(ext)) {
        // console.log("Ext : ", ext);
        setContent("이미지");
      } else if (fileArr.includes(ext)) {
        setContent(ext + " 파일 : " + filename);
      } else {
        setContent(replyMsg);
      }
    }
  }, [replyMsg]);

  return (
    <MsgContainer isMine={isMine}>
      {reply ? (
        isMine ? (
          <>
            <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
            <MsgContent isMine={isMine}>
              <ReplyCon>
                <ReplyToTitle isMine={isMine}>
                  {reply?.user?.username + "에게 답장"}
                </ReplyToTitle>
                <ReplyToContent
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(content),
                  }}
                />
              </ReplyCon>
              <ReplyContent
                dangerouslySetInnerHTML={{
                  __html: sanitizer(msg),
                }}
              />{" "}
            </MsgContent>
          </>
        ) : (
          <>
            <MsgContent isMine={isMine}>
              <ReplyCon>
                <ReplyToTitle>
                  {reply?.user?.username + "에게 답장"}
                </ReplyToTitle>
                <ReplyToContent
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(content),
                  }}
                />
              </ReplyCon>
              <ReplyContent
                dangerouslySetInnerHTML={{
                  __html: sanitizer(msg),
                }}
              />{" "}
            </MsgContent>
            <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
          </>
        )
      ) : isMine ? (
        <>
          <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
          <MsgContent
            isMine={isMine}
            dangerouslySetInnerHTML={{
              __html: sanitizer(msg),
            }}
          />
        </>
      ) : (
        <>
          <MsgContent
            isMine={isMine}
            dangerouslySetInnerHTML={{
              __html: sanitizer(msg),
            }}
          />
          <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
        </>
      )}
    </MsgContainer>
  );
};
export default TypeMsg;
