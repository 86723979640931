//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { GIVENCOUPON_FRAGMENT } from "../fragments/givenCouponFragment";

export const SELECT_MY_GIVEN_COUPONS_QUERY = gql`
  query SelectMyGivenCoupons {
    selectMyGivenCoupons {
      ...GivenCouponFragment
    }
  }
  ${GIVENCOUPON_FRAGMENT}
`;

export const SelectMyGivenCoupons = () => {
  const { data } = useQuery(SELECT_MY_GIVEN_COUPONS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  return { data };
};
