//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useLazyQuery, useReactiveVar, useSubscription } from "@apollo/client";
import React from "react";
import { CardColumns } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Accounts from "../../Accounts/Accounts";
import { MESSAGE_NOTIFICATION_SUBSCRIPTION } from "../../queries/message/messageNotification";
import { SEE_ROOM_LIST } from "../../queries/message/seeRoomList";
import { routes } from "../../routes/routes";
import { isNewMessageVar } from "../../utils/reactiveVar";
import Notification from "../header/Notification";
import SearchBox from "../header/SearchBox";

const NavCon = styled.div`
  min-width: 300px;
  z-index: 103;
  .show {
    display: flex;
    flex-direction: column;
    //row-gap: 2px;
  }
  .dropdownItem {
    display: flex;
    align-items: baseline;
    svg {
      min-width: 25px;
      margin-right: 5px;
    }
  }
  display: flex;
`;
const Icon = styled.div`
  cursor: pointer;
  ${(props) =>
    props.newMessage
      ? `
      @keyframes changeColor {
        from {
          color: #2c2c2c
        }

        to {
          color: orange
        }
      }
      animation-duration: 0.7s;
      animation-name: changeColor;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      `
      : null}
`;
const HeaderIcon = styled.i`
  width: 25px;
  height: 25px;
  font-size: 22px;
  padding: 5px;
  &:hover {
    color: #ed5f00;
  }
`;
const AccountCon = styled.div`
  .dropdown-menu {
    inset: 10px auto auto 0 !important;
    padding: 0px !important;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 30px 0 rgb(82 63 105 / 20%);
  }
`;
function Navigation({ me }) {
  const isNewMessage = useReactiveVar(isNewMessageVar);

  const [seeRoomListLazyQuery] = useLazyQuery(SEE_ROOM_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const { loading, error, data } = useSubscription(
    MESSAGE_NOTIFICATION_SUBSCRIPTION,
    {
      onSubscriptionData: (data) => {
        const {
          subscriptionData: {
            data: {
              messageNotification: { ok, id },
            },
          },
        } = data;
        if (ok && id !== me?.me?.id) {
          isNewMessageVar(true);
          seeRoomListLazyQuery();
        }
      },
    }
  );
  return (
    <NavCon className="nav">
      <SearchBox />
      <CardColumns style={{ display: "flex", alignItems: "center" }}>
        <Link to={routes.home}>
          <HeaderIcon className="bi bi-house-fill" />
          {/* <FontAwesomeIcon icon={faHome} /> */}
        </Link>
        <Link to={routes.message}>
          <Icon
            newMessage={isNewMessage}
            onClick={() => isNewMessageVar(false)}
          >
            <HeaderIcon className="bi bi-chat-dots-fill" />
            {/* <FontAwesomeIcon icon={faEnvelope} /> */}
          </Icon>
        </Link>
        <Notification />
        <AccountCon>
          <Accounts me={me} />
        </AccountCon>
      </CardColumns>
    </NavCon>
  );
}

export default Navigation;
