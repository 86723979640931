//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useReactiveVar } from "@apollo/client";
import styled from "styled-components";
import { sidebarToggleVar } from "../../utils/reactiveVar";
import FooterInteraction from "../main/FooterInteraction";
import AdminHeader from "./AdminHeader";
import NavigationLeft from "./NavigationLeft";

const Body = styled.div`
  margin: 0 auto;
  margin-top: 80px;
  gap: 10px;
  min-height: 1000px;
  @media (max-width: 700px) {
    min-height: unset;
  }
`;

const AdminLayout = ({ children }) => {
  const sidebarToggle = useReactiveVar(sidebarToggleVar);
  return (
    <>
      <AdminHeader className={sidebarToggle ? "" : "toggle-sidebar"} />
      <Body>
        <NavigationLeft
          iconShow={true}
          borderRadius="10px"
          width="300px"
          margin={sidebarToggle ? "" : "0 0 0 -300px"}
          isAdmin={true}
        />
        {children}
      </Body>
      <FooterInteraction />
    </>
  );
};
export default AdminLayout;
