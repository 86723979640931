//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useMutation, useQuery } from "@apollo/client";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import UseFormError from "../Accounts/UseFormError";
import { Select } from "../component/shared/Auth";
import { ButtonLayout } from "../component/shared/ButtonLayout";
import { FatText } from "../component/shared/FatText";
import { SEE_MYGYM } from "../queries/user/seeMyGym";
import { UPDATE_USER } from "../queries/user/updateUser";
import colors from "../utils/colors";
import { GButton } from "../component/shared/GButton";
import CheckUserToken, { ME } from "../utils/checkUserToken";
import { useState } from "react";

const DELETE_PROFILE = gql`
  mutation DeleteProfile($id: Int!) {
    deleteProfile(id: $id) {
      ok
      error
    }
  }
`;
const MotherContainer = styled.div`
  width: 100%;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 30px;
  width: 100%;
  height: 100%;
  form {
    width: 100%;
  }
`;
const ProfilePhoto = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  gap: 10px;
`;

const ProfileImg = styled.img`
  width: 150px;
  height: 150px;
  border: 2px solid #ccc;
  object-fit: cover;
  object-position: bottom top;
  border-radius: 50%;
  background-color: gray;
`;
const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;
const ColHeader = styled.div`
  width: 100%;
  text-align: left;
  @media (max-width: 660px) {
    width: 100%;
    text-align: start;
  }
`;
const ColBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 660px) {
    width: 100%;
  }
`;
const Input = styled.input`
  border: 0.5px solid lightgray;
  outline: none;
  border-radius: 3px;
  padding: 5px;
  margin-top: 10px;
`;

const ButtonCon = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  text-align: left;
`;
const Button = styled(ButtonLayout)`
  border: 0;
  border-radius: 3px;
  width: 30%;
  text-align: center;
  cursor: pointer;
  padding: 0.425rem 0.75rem;
  font-weight: bold;
  background-color: ${colors.danger};
  color: white;
  min-width: 150px;
`;
const File = styled.input`
  opacity: 0;
  position: absolute;
  left: -9999px;
`;
const Label = styled.label`
  border: 0;
  padding: 0.425rem 0.75rem;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  background-color: ${colors.info};
  color: white;
  font-weight: bold;

  width: 30%;
  min-width: 150px;
`;
export const Submit = styled.input`
  border-radius: 3px;
  padding: 5px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "white")};
  border: ${(props) => (props.border ? props.border : "0.5px solid lightgray")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  width: ${(props) => (props.width ? props.width : "50px")};
  height: ${(props) => (props.height ? props.height : "30px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  margin: ${(props) => (props.margin ? props.margin : "")};
`;
const Textarea = styled.textarea`
  margin-top: 10px;
  height: 100px;
  resize: none;
`;
const ProfileCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const UserName = styled.div`
  font-size: 27px;
  font-weight: 700;
`;

const MyGymEdit = () => {
  const profileImage = useRef();
  const {
    state: { id },
  } = useLocation();
  const { data: mygymData } = useQuery(SEE_MYGYM, {
    variables: {
      id,
    },
  });

  const {
    register,
    formState,
    handleSubmit,
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useForm();
  useEffect(() => {
    if (!!mygymData) {
      setValue("username", mygymData?.seeMyGym?.username);
      setValue("description", mygymData?.seeMyGym?.description);
      setValue("birthyear", mygymData?.seeMyGym?.birthyear);
      setValue("birthmonth", mygymData?.seeMyGym?.birthmonth);
      setValue("birthday", mygymData?.seeMyGym?.birthday);
      setValue("realName", mygymData?.seeMyGym?.realName);
      setValue("bank", mygymData?.seeMyGym?.bank);
      setValue("accountNumber", mygymData?.seeMyGym?.accountNumber);
      setValue("gender", mygymData?.seeMyGym?.gender);
    }
  }, [mygymData]);
  // if (!mygymLoading) {

  // }
  const [updateUserMutation] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      alert("수정되었습니다.");
    },
    refetchQueries: [{ query: ME }],
  });

  const [deleteProfileMutation] = useMutation(DELETE_PROFILE, {
    refetchQueries: [{ query: SEE_MYGYM }],
  });
  const onSubmitValid = (data) => {
    const { gender } = getValues();
    const {
      username,
      description,
      profilePhoto,
      birthyear,
      birthmonth,
      birthday,
      realName,
      bank,
      accountNumber,
    } = data;

    updateUserMutation({
      variables: {
        id: mygymData?.seeMyGym?.id,
        username: username && username,
        description: description && description,
        profilePhoto: profilePhoto[0] && profilePhoto[0],
        birthyear: Number(birthyear) && Number(birthyear),
        birthmonth: Number(birthmonth) && Number(birthmonth),
        birthday: Number(birthday) && Number(birthday),
        realName: realName && realName,
        bank: bank && bank,
        accountNumber: accountNumber && accountNumber,
        gender: gender && gender,
      },
    });
  };
  const onFileChange = (e) => {
    //clearErrors("fileError");
    const ext = e?.target?.files?.[0]?.name?.split(".").pop().toLowerCase();
    const extType = ["png", "jpg", "jpeg"];
    if (!!ext && extType.includes(ext)) {
      const file = e?.target?.files?.[0];

      const reader = new FileReader();
      reader.onload = (event) => {
        //const profileImg = document.getElementById("profileImg");

        profileImage.current.setAttribute("src", event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("파일에 문제가 있습니다. 다시 시도해주세요.");
      // setError("fileError", {
      //   message: "파일에 문제가 있습니다. 다시 시도해주세요.",
      // });
      e.target.value = "";
    }
  };
  const onDeleteProfileClick = (e) => {
    const confirmBool = window.confirm("프로필 사진을 삭제 하시겠습니까?");
    if (!!confirmBool) {
      deleteProfileMutation({
        variables: {
          id: mygymData?.seeMyGym?.id,
        },
      });
    }
  };
  const date = new Date();
  const year = date?.getFullYear();
  const startY = Number(year) - 100;
  const SelYears = [];
  const SelMonths = [];
  const SelDays = [];
  for (let y = startY; y <= year; y++) {
    SelYears.push(y);
  }
  for (let m = 1; m <= 12; m++) {
    SelMonths.push(m);
  }
  for (let d = 1; d <= 31; d++) {
    SelDays.push(d);
  }
  useEffect(() => {
    register("gender", { required: "성별을 선택해주세요." });
  }, []);
  return (
    <MotherContainer>
      <ProfileContainer>
        <form onSubmit={handleSubmit(onSubmitValid)} className="GymEditForm">
          <ProfilePhoto>
            <ProfileCon>
              <ProfileImg
                ref={profileImage}
                id="profileImg"
                src={
                  mygymData?.seeMyGym?.profilePhoto
                    ? mygymData?.seeMyGym?.profilePhoto
                    : ""
                }
                {...mygymData?.seeMyGym}
                onError={(e) =>
                  (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
                }
              />
              <ButtonCon>
                <UserName>{mygymData?.seeMyGym?.username}</UserName>

                <UseFormError message={formState?.errors?.fileError?.message} />
                <Label>
                  사진 변경
                  <File
                    {...register("profilePhoto")}
                    type="file"
                    onChange={onFileChange}
                  />
                </Label>
                <Button type="button" onClick={onDeleteProfileClick}>
                  사진 삭제
                </Button>
              </ButtonCon>
            </ProfileCon>
          </ProfilePhoto>
          <Table>
            <Row>
              <ColHeader>
                <FatText color="rgb(99, 110, 114)">사용자 이름</FatText>
              </ColHeader>
              <ColBody>
                <Input
                  {...register("username", {
                    maxLength: {
                      value: 20,
                      message: "유저이름은 20자를 넘길 수 없습니다!",
                    },
                    required: {
                      value: true,
                      message: "유저이름은 공백일 수 없습니다!",
                    },
                    validate: {
                      lessThanTwenty: (value) => value?.length <= 20,
                    },
                  })}
                  className="form-control"
                  id="username"
                  type="text"
                />
              </ColBody>
            </Row>
            <Row>
              <div style={{ margin: "auto" }}>
                <UseFormError message={formState?.errors?.username?.message} />
              </div>
            </Row>
            <Row>
              <ColHeader>
                <FatText color="rgb(99, 110, 114)">소개</FatText>
              </ColHeader>
              <ColBody>
                <Textarea
                  {...register("description", {
                    maxLength: {
                      value: 150,
                      message: "소개는 150자를 넘길 수 없습니다!",
                    },
                    validate: {
                      lessThan150: (value) => value?.length <= 150,
                    },
                  })}
                  className="form-control"
                  id="description"
                />
              </ColBody>
            </Row>
            <Row>
              <div style={{ margin: "auto" }}>
                <UseFormError
                  message={formState?.errors?.description?.message}
                />
              </div>
            </Row>
            <Row>
              <ColHeader>
                <FatText color="rgb(99, 110, 114)">생년월일</FatText>
              </ColHeader>
              <ColBody
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <Select
                  style={{ width: "30%" }}
                  className="year"
                  {...register("birthyear", {
                    required: "태어난 년도를 선택해주세요.",
                  })}
                >
                  {SelYears?.map((v, idx) => (
                    <option key={idx} value={v} selected>
                      {v}년
                    </option>
                  ))}
                </Select>
                <Select
                  style={{ width: "30%" }}
                  className="month"
                  {...register("birthmonth", {
                    required: "태어난 달을 선택해주세요.",
                  })}
                >
                  {SelMonths?.map((v, idx) => (
                    <option key={idx} value={v} selected>
                      {v}월
                    </option>
                  ))}
                </Select>
                <Select
                  style={{ width: "30%" }}
                  className="day"
                  {...register("birthday", {
                    required: "태어난 날을 선택해주세요.",
                  })}
                >
                  {SelDays?.map((v, idx) => (
                    <option key={idx} value={v} selected>
                      {v}일
                    </option>
                  ))}
                </Select>
              </ColBody>
            </Row>
            <Row>
              <ColHeader>
                <FatText color="rgb(99, 110, 114)"> 성별</FatText>
              </ColHeader>
              <ColBody>
                <RadioGroup
                  aria-label="gender"
                  name="radio-buttons-group"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="남"
                    control={<Radio color="primary" />}
                    label="남"
                    onClick={(e) => {
                      e.target.checked && setValue("gender", "male");
                      clearErrors("gender");
                    }}
                    checked={watch()?.gender === "male" && true}
                  />
                  <FormControlLabel
                    value="여"
                    control={<Radio color="primary" />}
                    label="여"
                    onClick={(e) => {
                      e.target.checked && setValue("gender", "female");
                      clearErrors("gender");
                    }}
                    checked={watch()?.gender === "female" && true}
                  />
                </RadioGroup>
              </ColBody>
            </Row>
            <Row>
              <FatText
                style={{ width: "100%", textAlign: "left" }}
                color={colors.bbeego}
              >
                하기 정보는 PT룸 등록비 지급, 챌린지 보상급 지급등에 사용되는
                정보입니다.
              </FatText>
            </Row>
            <Row>
              <ColHeader>
                <FatText color="rgb(99, 110, 114)">실명</FatText>
              </ColHeader>
              <ColBody>
                <Input
                  {...register("realName", {
                    maxLength: {
                      value: 20,
                      message: "실명은 20자를 넘길 수 없습니다!",
                    },
                    required: {
                      value: true,
                      message: "실명은 공백일 수 없습니다!",
                    },
                    validate: {
                      lessThanTwenty: (value) => value?.length <= 20,
                    },
                  })}
                  className="form-control"
                  id="realName"
                  type="text"
                />
              </ColBody>
            </Row>
            <Row>
              <div style={{ margin: "auto" }}>
                <UseFormError message={formState?.errors?.realName?.message} />
              </div>
            </Row>
            <Row>
              <ColHeader>
                <FatText color="rgb(99, 110, 114)">은행명</FatText>
              </ColHeader>
              <ColBody>
                <Input
                  {...register("bank", {
                    maxLength: {
                      value: 20,
                      message: "은행명은 20자를 넘길 수 없습니다!",
                    },
                    required: {
                      value: true,
                      message: "은행명은 공백일 수 없습니다!",
                    },
                    validate: {
                      lessThanTwenty: (value) => value?.length <= 20,
                    },
                  })}
                  className="form-control"
                  id="bank"
                  type="text"
                />
              </ColBody>
            </Row>
            <Row>
              <div style={{ margin: "auto" }}>
                <UseFormError message={formState?.errors?.bank?.message} />
              </div>
            </Row>
            <Row>
              <ColHeader>
                <FatText color="rgb(99, 110, 114)">계좌번호</FatText>
              </ColHeader>
              <ColBody>
                <Input
                  {...register("accountNumber", {
                    maxLength: {
                      value: 30,
                      message: "계좌번호는 30자를 넘길 수 없습니다!",
                    },
                    required: {
                      value: true,
                      message: "계좌번호는 공백일 수 없습니다!",
                    },
                    validate: {
                      lessThanTwenty: (value) => value?.length <= 30,
                    },
                  })}
                  className="form-control"
                  placeholder="'-' 없이 입력"
                  id="accountNumber"
                  type="text"
                />
              </ColBody>
            </Row>
            <Row>
              <div style={{ margin: "auto" }}>
                <UseFormError
                  message={formState?.errors?.accountNumber?.message}
                />
              </div>
            </Row>
          </Table>
          <Row style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <GButton
              border="none"
              color={colors.bbeego}
              fontSize="16px"
              width="100%"
              padding="0.475rem 0.75rem"
              fontWeight="600"
              type="submit"
              variant="create"
              context="수정"
              className="Submit"
            />
          </Row>
        </form>
      </ProfileContainer>
    </MotherContainer>
  );
};

export default MyGymEdit;
