//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useEffect, useState } from "react";
import { Modal, Container, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import styled from "styled-components";
import PTRoom from "../component/PTroom/PTRoom";
import { ShowPTRooms, ShowPTRooms_NS } from "../queries/PTRoom/showPTRooms";
import { InteractBtn } from "./MyGym";
import { routes } from "./routes";
import { useMutation } from "@apollo/client";
import { DELETE_PTROOM } from "../queries/PTRoom/deletePTRoom";
import { SEE_MYGYM } from "../queries/user/seeMyGym";
import colors from "../utils/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CheckUserToken from "../utils/checkUserToken";
import Spinner from "../component/shared/Spinner";

const PTRoomsContainer = styled.div`
  margin: 0 10px;
`;
const SContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
`;
const PageDescription = styled.div`
  box-shadow: 0 0 15px -5px gray;
  padding: 20px;
  border-radius: 5px;
`;
const PTHeader = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  margin-top: 120px;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
`;
const SubTitle = styled.h3`
  text-align: center;
  margin: 20px 0 80px 0;
  font-weight: 22px !important;
  color: #6b7280;
`;

const PTRooms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const me = CheckUserToken(true);
  const { data, loading } = ShowPTRooms_NS();

  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();
  //페이지 이동
  useEffect(() => {
    if (!me && data?.showPTRooms.length) {
      history.push(routes.login);
    }
  }, [data]);
  const moveToPTRoomUpdate = () => {
    if (!!modalData?.isMine) {
      history.push(routes?.updatePTRoom, {
        id: modalData?.pTRoomId,
      });
    }
  };
  const [delPTRoomMutation] = useMutation(DELETE_PTROOM, {
    refetchQueries: [{ query: SEE_MYGYM }],
  });
  const deletePTRoom = (modalData) => {
    if (!!modalData?.isMine) {
      const confirmBool = window.confirm("PT룸을 삭제합니다.");
      if (!!confirmBool) {
        delPTRoomMutation({
          variables: {
            id: modalData?.pTRoomId,
          },
        });
        setModal(false);
      } else {
        setModal(false);
      }
    } else {
      alert("내 PT룸이 아닙니다.");
      setModal(false);
    }
  };
  return (
    <Container
      id="main"
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      {loading === true ? <Spinner /> : null}
      {/*<Card>
        <Card.Body>
          <Card.Title style={{ margin: 0, fontWeight: "bold" }}>
            PT룸
          </Card.Title>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          PT룸을 선택해서 등록하고 트레이너와 소통하면서 원하는 목표를
          달성하세요!
        </Card.Body>
      </Card>*/}
      <PTHeader>
        <Title>PT룸</Title>
        <SubTitle>
          PT룸을 선택해서 등록하고 트레이너와 소통하면서 원하는 목표를
          달성하세요!
        </SubTitle>
      </PTHeader>
      {me?.me?.type === "trainer" ? (
        <SubTitle>
          <Button
            variant="primary"
            style={{
              backgroundColor: colors.bbeego,
              border: "none",
              height: "40px",
              width: "130px",
            }}
          >
            <Link to={routes?.createPTRoom} style={{ color: "white" }}>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
              PT룸 생성
            </Link>
          </Button>
        </SubTitle>
      ) : null}

      <SContainer>
        {data?.showPTRooms?.map((pTRoom, idx) => (
          <PTRoom
            key={idx}
            {...pTRoom}
            setPTRoomModal={setModal}
            setPTRoomModalData={setModalData}
          />
        ))}
      </SContainer>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        className="PTRoomModal"
        centered="true"
        size="sm"
      >
        <Modal.Body>
          {modalData?.isMine ? (
            <>
              <InteractBtn onClick={moveToPTRoomUpdate}>PT룸 수정</InteractBtn>
              <InteractBtn
                onClick={() => deletePTRoom(modalData)}
                style={{ color: "red" }}
              >
                PT룸 삭제
              </InteractBtn>
              <InteractBtn onClick={() => setModal(false)}>취소</InteractBtn>
            </>
          ) : (
            <>
              <InteractBtn onClick={() => alert("신고")}>
                PTRoom 신고
              </InteractBtn>
              <InteractBtn onClick={() => setModal(false)}>취소</InteractBtn>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default PTRooms;
<></>;
