//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { SEARCH_USERS_QUERY } from "../queries/user/searchUsers";
import ProfilePhoto from "../component/shared/ProfilePhoto";
import { CREATE_CHALLENGE } from "../queries/challenge/createChallenge";
import { UPDATE_CHALLENGE } from "../queries/challenge/updateChallenge";
import "react-datepicker/dist/react-datepicker.css";
import { SeeChallenge } from "../queries/challenge/seeChallenge";
import { Modal } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import CheckUserToken from "../utils/checkUserToken";
import Editor from "../component/PTroom/EditorComponent";

const CreateChallengeCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .datePicker {
    display: block;
    width: 180px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;
const CreateForm = styled.form`
  display: flex;
  flex-direction: column;
  width: inherit;
  justify-content: center;
  padding: 50px;
  row-gap: 20px;
  border: 1px solid #ced4da;
  background-color: white;
  flex-wrap: wrap;
  box-shadow: 0 0 29px 0 rgb(18 66 101 / 8%);
  border-radius: 10px;
`;
const Name = styled.div`
  min-width: 115px;
  margin-right: 20px;
  text-align: right;
`;
const Value = styled.input`
  margin-right: 10px;
  width: 180px;
  text-align: end;
`;
const Title = styled.input`
  margin-right: 10px;
  width: 600px;
`;
const NameValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const BtnForm = styled.div`
  display: flex;
  padding: 10px;
  column-gap: 10px;
`;
const Submit = styled.input``;
const SearchBtn = styled.input`
  max-width: 100px;
`;
const Search = styled.input``;
const UserRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  border-bottom: 1px solid lightgrey;
`;
const UserName = styled.div``;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const InputComment = styled.span`
  color: red;
  margin-left: 10px;
`;
export const ToggleGender = styled.div`
  margin-left: -5px;
  padding: 25px;
  position: relative;

  > .toggle-box {
    top: 5px;
    left: 0px;
    background-color: #e9ecef;
    border-radius: 5px;
    border: 1px solid #ced4da;
    width: 140px;
    height: 38px;
    position: absolute;
    transition: all 0.15s ease-in-out;
  }

  > .toggle-box.toggle__checked {
    background-color: #ecf0f1;
  }

  > .toggle-icon {
    top: 5px;
    left: 0px;
    background-color: #ed5f00;
    width: 70px;
    height: 38px;
    border: 2px solid #e67e22;
    border-radius: 5px;
    position: absolute;
    padding: 5px;
    transform: scale(1.05);
    transition: all 0.15s ease-in-out;
  }

  > .toggle-icon.toggle__checked {
    left: 70px;
    background-color: #ed5f00;
    transform: scale(1.05);
  }
`;
const InputGender = styled.div``;

export const ToggleState = styled.p`
  padding: 15px 0;
  user-select: none;
  text-align: center;
  width: 70px;
  color: ${({ isOn }) => (isOn ? "white" : "#bdc3c7")};
  font-weight: ${({ isOn }) => (isOn ? "800" : "600")};
  font-size: ${({ isOn }) => (isOn ? "18px" : "16px")};
  top: 25px;
  left: ${(props) => (props?.left ? props.left : "35px")};
  margin-right: -50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

const dataURLtoFile = (dataurl, fileName) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

const CreateChallenge = () => {
  const { register, handleSubmit, setValue, getValues, watch } = useForm();
  //quill
  const [quillValue, setQuillValue] = useState("");
  const [quillFiles, setQuillFiles] = useState([]);
  const onEditorChange = (content, delta, source, editor) => {
    let fileArr = [];
    const regex = /(src="data.*?)(?=")/g;
    if (content.match(regex)?.[0]) {
      const imgDatas = content.match(regex);
      for (let i = 0; i < imgDatas.length; i++) {
        imgDatas[i] = imgDatas[i].replace('src="', "");
        fileArr.push(dataURLtoFile(imgDatas[i], "quillImg.png"));
      }
    }
    const quillData = content.replace(regex, 'src="|||quill|||');
    //화면 띄우는 용
    setQuillValue(content);
    //서버 전송용
    setValue("description", quillData);
    setQuillFiles(fileArr);
  };
  const [date, setDate] = useState("");
  const me = CheckUserToken();
  const [endDate, setEndDate] = useState("");
  const [check, setCheck] = useState(false);
  const onDateChange = (e) => {
    setDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    let term = e.target.value;
    if (e.target.value > 365) {
      term = 365;
    } else if (e.target.value <= 10) {
      term = 10;
    }

    setEndDate(term);
  };
  const onCheck = (e) => {
    let a = e.target.checked;

    setCheck(a);
  };
  const [isOn, setIsOn] = useState(false);

  const toggleHandler = () => {
    setIsOn(!isOn);
  };

  const { state } = useLocation();
  const challengeId = state?.id;
  const [searchResult, setSearchResult] = useState([]);
  const [interactVisible, setInteractVisible] = useState(false);
  const challengeData = SeeChallenge(challengeId || 0)?.seeChallenge;

  useEffect(() => {
    if (!!challengeId) {
      setValue("title", challengeData?.title);
      setValue("description", challengeData?.description);
      setValue("age", challengeData?.age);
      setValue("currentWeight", challengeData?.currentWeight);
      setValue("currentFatPercentage", challengeData?.currentFatPercentage);
      setValue("baseprice", challengeData?.baseprice);
      setValue("bonus", challengeData?.bonus);
      setValue("reward", challengeData?.reward);
      setValue("targetWeight", challengeData?.targetWeight);
      setValue("targetFatPercentage", challengeData?.targetFatPercentage);
      setValue("traineeId", challengeData?.traineeId);
      setValue("traineeName", challengeData?.trainee?.username);
      setValue("term", challengeData?.term);
      setValue("startDate", challengeData?.startDate);
      setValue("endDate", challengeData?.endDate);
      setValue("gender", challengeData?.gender);
    }
  }, [challengeData]);
  const onCompleted = (data) => {
    const {
      createChallenge: { ok },
    } = data;
    if (ok) {
      window.location.replace("/challenges");
    }
  };
  const challengeUpdate = (data) => {
    const {
      updateChallenge: { ok },
    } = data;
    if (ok) {
      window.location.replace("/challenges");
    }
  };
  const userQuery = (data) => {
    const Users = data?.searchUsers;
    setSearchResult(Users);
  };
  const [updateChallenge] = useMutation(UPDATE_CHALLENGE, {
    onCompleted: challengeUpdate,
  });
  const [createChallenge] = useMutation(CREATE_CHALLENGE, { onCompleted });
  const [searchUserQuery] = useLazyQuery(SEARCH_USERS_QUERY, {
    onCompleted: userQuery,
  });
  const onSubmitValid = (challengeData) => {
    if (!challengeId) {
      const {
        title,
        description,
        age,
        currentWeight,
        currentFatPercentage,
        baseprice,
        bonus,
        reward,
        targetWeight,
        targetFatPercentage,
        traineeId,
        term,
        startDate,
        endDate,
        gender,
      } = challengeData;
      let val = window.confirm("챌린지를 등록합니다.");
      if (!description) {
        return alert("설명이 없습니다!");
      }
      if (val === true) {
        createChallenge({
          variables: {
            title,
            description: description,
            descriptionImages: quillFiles,
            age: Number(age),
            currentWeight: Number(currentWeight),
            currentFatPercentage: Number(currentFatPercentage),
            baseprice: Number(baseprice),
            bonus: Number(bonus),
            reward: Number(reward),
            gender,
            targetWeight: Number(targetWeight),
            targetFatPercentage: Number(targetFatPercentage),
            traineeId: Number(traineeId),
            term: Number(term),
            startDate,
            endDate,
          },
        });
      }
    } else {
      const {
        title,
        description,
        age,
        gender,
        currentWeight,
        currentFatPercentage,
        baseprice,
        bonus,
        reward,
        targetWeight,
        targetFatPercentage,
        traineeId,
        startDate,
        endDate,
      } = challengeData;
      let val = window.confirm("챌린지를 수정합니다.");
      if (!description) {
        return alert("설명이 없습니다!");
      }
      if (val === true) {
        updateChallenge({
          variables: {
            id: challengeId,
            title,
            description: description,
            descriptionImages: quillFiles,
            age: Number(age),
            currentWeight: Number(currentWeight),
            currentFatPercentage: Number(currentFatPercentage),
            baseprice: Number(baseprice),
            bonus: Number(bonus),
            reward: Number(reward),
            gender,
            targetWeight: Number(targetWeight),
            targetFatPercentage: Number(targetFatPercentage),
            traineeId: Number(traineeId),
            startDate,
            endDate,
          },
        });
      }
    }
  };
  const onSearch = (event) => {
    searchUserQuery({ variables: { keyword: watch()?.searchUser } });
    if (event?.keyCode === 13) {
    }
  };
  const setUserInfo = (user) => {
    setInteractVisible(false);
    setValue("traineeName", user?.username);
    setValue("traineeId", user?.id);
  };
  const onHide = () => {
    setInteractVisible(false);
    setValue("searchUser", "");
  };
  useEffect(() => {
    const { term } = getValues();
    const finishDate = new Date(
      new Date(date + " 00:00:00").getTime() +
        (term * (1000 * 60 * 60 * 24) - 1)
    )
      .toJSON()
      ?.split("T")[0];

    // setEnd(finishDate);
    setValue("endDate", finishDate);
  }, [date, endDate]);

  useEffect(() => {
    setValue("term", 10);
  }, []);
  //챌린지 대상을 나로 지정
  useEffect(() => {
    let traineeId = "";
    let traineeName = "";
    if (check) {
      traineeId = me?.me?.id;
      traineeName = me?.me?.username;
    }

    setValue("traineeId", traineeId);
    setValue("traineeName", traineeName);
  }, [check]);

  useEffect(() => {
    const Gender = isOn === false ? "male" : "female";
    setValue("gender", Gender);
  }, [isOn]);
  return (
    <CreateChallengeCon id="main">
      <CreateForm onSubmit={handleSubmit(onSubmitValid)}>
        <InputContainer>
          <NameValue>
            <Name>
              <InputComment>*</InputComment>제목 :{" "}
            </Name>
            <Title
              {...register("title", {
                required: { value: true, message: "제목은 필수값입니다." },
              })}
              className="form-control"
            />
          </NameValue>
          <NameValue>
            <Name>
              <InputComment>*</InputComment>설명 :{" "}
            </Name>
            {/* <Description
              {...register("description", {
                required: { value: true, message: "설명은 필수값입니다." },
              })}
              className="form-control"
            /> */}
            <Editor value={quillValue} onChange={onEditorChange} />
          </NameValue>
          <NameValue className="Age">
            <Name>
              <InputComment>*</InputComment>나이 :{" "}
            </Name>
            <Value
              {...register("age", {
                required: { value: true, message: "나이는 필수값입니다." },
              })}
              type="number"
              className="form-control"
            />{" "}
            세
          </NameValue>
          <NameValue className="CurrentWeight">
            <Name>
              <InputComment>*</InputComment>시작 체중 :{" "}
            </Name>
            <Value
              {...register("currentWeight", {
                required: { value: true, message: "제목은 필수값입니다." },
              })}
              type="number"
              className="form-control"
            />{" "}
            (KG)
          </NameValue>
          <NameValue className="TargetWeight">
            <Name>
              <InputComment>*</InputComment>목표 체중 :
            </Name>
            <Value
              {...register("targetWeight", {
                required: { value: true },
              })}
              type="number"
              className="form-control"
            />{" "}
            (KG)
          </NameValue>
          <NameValue className="CurrentFatPercentage">
            <Name>시작 체지방률 :</Name>
            <Value
              {...register("currentFatPercentage")}
              type="number"
              className="form-control"
              max="100"
            />{" "}
            %
          </NameValue>
          <NameValue className="TargetFatPercentage">
            <Name>목표 체지방률 : </Name>
            <Value
              {...register("targetFatPercentage")}
              type="number"
              className="form-control"
              max="100"
            />{" "}
            %
          </NameValue>
          <NameValue className="BasePrice">
            <Name>
              <InputComment>*</InputComment>기본급 :{" "}
            </Name>
            <Value
              {...register("baseprice", {
                required: { value: true },
              })}
              type="number"
              className="form-control"
            />{" "}
            만원
          </NameValue>
          <NameValue className="Bonus">
            <Name>
              <InputComment>*</InputComment>성공보수 :{" "}
            </Name>
            <Value
              {...register("bonus", {
                required: { value: true },
              })}
              type="number"
              className="form-control"
            />{" "}
            만원
          </NameValue>
          <NameValue className="Reward">
            <Name>
              <InputComment>*</InputComment>리워드 :{" "}
            </Name>
            <Value
              {...register("reward", {
                required: { value: true },
              })}
              type="number"
              className="form-control"
            />{" "}
            만원
          </NameValue>
          <NameValue className="Gender">
            <Name>
              <InputComment>*</InputComment>성별 :{" "}
            </Name>
            <ToggleGender {...register("gender")}>
              <InputGender
                className={isOn ? "toggle-box toggle__checked" : "toggle-box"}
              />

              <InputGender
                className={isOn ? "toggle-icon toggle__checked" : "toggle-icon"}
              />

              <ToggleState onClick={toggleHandler} isOn={!isOn}>
                남자
              </ToggleState>
              <ToggleState onClick={toggleHandler} left="105px" isOn={isOn}>
                여자
              </ToggleState>
            </ToggleGender>
          </NameValue>
          <NameValue>
            <Name>
              <InputComment>*</InputComment>시작 날짜 :
            </Name>
            <Value
              {...register("startDate", {
                required: {
                  value: true,
                  message: "시작 날짜는 필수값입니다.",
                },
              })}
              onChange={onDateChange}
              value={date}
              type="date"
              className="form-control"
            />
          </NameValue>

          <NameValue>
            <Name>
              <InputComment>*</InputComment>기간(일) :
            </Name>
            <Value
              {...register("term", {
                required: { value: true, message: "기간은 필수값입니다." },
              })}
              type="number"
              min="10"
              max="365"
              onBlur={(e) => {
                if (e.target.value > 365) {
                  setValue("term", 365);
                } else if (e.target.value < 10) {
                  setValue("term", 10);
                }
                onEndDateChange(e);
              }}
              className="form-control"
            />
            일
          </NameValue>
          <NameValue>
            <Name>종료 날짜 :</Name>
            <Value
              {...register("endDate")}
              className="form-control"
              readOnly
              type="date"
            />
            당일 오후 11:59분 까지 입니다.
          </NameValue>

          <NameValue className="TargetUser">
            <Name>관리 대상자 : </Name>
            <Value
              type="text"
              {...register("traineeName")}
              className="form-control"
              readOnly
              style={{
                textAlign: "center",
              }}
            />
            {!check ? (
              <SearchBtn
                type="button"
                className="form-control btn-secondary"
                value="검색"
                onClick={() => setInteractVisible(true)}
                // disabled={check}
                // style={{
                //   backgroundColor: check ? "#9ba1a6" : "#6c757d",
                //   borderColor: check ? "unset" : "#6c757d",
                // }}
              />
            ) : null}
            <input {...register("traineeId")} hidden id="traineeId" />
            <Checkbox
              size="medium"
              id="traineeId"
              onChange={onCheck}
              value={me?.me?.id}
            />
            대상을 자신으로 설정
          </NameValue>
        </InputContainer>
        <BtnForm>
          <Submit
            type="submit"
            className="form-control btn-primary"
            value="등록"
          />
          {/* <button className="form-control btn-danger">취소</button> */}
        </BtnForm>
      </CreateForm>
      <Modal show={interactVisible} onHide={onHide} centered="true" size="sm">
        <Modal.Body>
          <Search
            {...register("searchUser")}
            type="text"
            className="form-control"
            placeholder="검색"
            autoComplete="off"
            onKeyUp={onSearch}
          />
          {searchResult
            ? searchResult?.map((user) => {
                return (
                  <UserRow key={user?.id} onClick={() => setUserInfo(user)}>
                    <ProfilePhoto
                      src={user?.profilePhoto}
                      {...user}
                      another="true"
                    />
                    <UserName>{user?.username}</UserName>
                  </UserRow>
                );
              })
            : null}
        </Modal.Body>
      </Modal>
    </CreateChallengeCon>
  );
};
export default CreateChallenge;
