//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useReactiveVar } from "@apollo/client";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import BuyerHistoryCard from "../component/history/BuyerHistoryCard";
import PayMentHistoryCard from "../component/history/PayMentHistoryCard";
import RewardHistoryCard from "../component/history/RewardHistoryCard";
import SellerHistoryCard from "../component/history/SellerHistoryCard";
import { SelectReceiptsByUserId } from "../queries/Receipt/selectReceiptsByUserId";
import CheckUserToken from "../utils/checkUserToken";
import colors from "../utils/colors";
import { myHisTabVar } from "../utils/reactiveVar";
import HistoryDetails from "../component/history/HistoryDetails";

const Title = styled.div`
  width: 100%;
  padding: 10px 0 20px 0;
  color: #222;
  /* background-color: #ed5f00; */
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
`;
const HistoryCon = styled(Container)`
  width: 100%;
  margin: 0 auto;
  min-height: 100px;
  /* max-height: 1800px; */
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  /* overflow-y: scroll; */
  /* border-top: 1px solid lightgray; */
  /* border: 2px solid rgb(235, 238, 244); */
`;
const History = styled.div`
  width: 100%;
  /* max-height: 3500px; */
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 10px;
  }
  border: 2px solid rgb(235, 238, 244);
  border-top: none;
  /* min-height: 300px; */
  /* border-bottom: 2px solid rgb(235, 238, 244); */
  vertical-align: baseline;
`;

const HisHead = styled.div`
  padding-right: 5px;
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 0.7fr 0.25fr 0.3fr;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #808080;
  border-top: 5px solid #2f3542;
  border-bottom: 2px solid #dfe4ea;
  div {
    margin: 0 auto;
  }
  .historyTitle {
    padding: 10px 0;
  }
  @media (max-width: 800px) {
    grid-template-columns: 0.5fr 1fr 0.5fr;
    .hidden {
      display: none;
    }
  }
`;
const HistoryDate = styled.div`
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  &:hover {
    background-color: #666666;
  }
  i {
    font-size: 17px;
  }
`;
const InfoHead = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.55fr 0.45fr;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
export const NoHistory = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 10px 0;
  margin: 0 auto;
  /* border-radius: 20px; */
  border: none;
  border-bottom: 2px solid rgb(235, 238, 244);
  div {
    margin: 0 auto;
    color: #b2bec3;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
`;
const LoadMore = styled(NoHistory)`
  cursor: pointer;
  &:hover {
    div {
      color: #fd780f;
    }
  }
`;
const Loading = styled.div`
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    text-align: center;
    opacity: 0.8;
    border: 1px solid rgba(0, 0, 0, 0.05);
    color: white;
    z-index: 1;
  }
  width: 100%;
  height: ${(props) => (props.height ? props.height : "400px")};
  div {
    div {
      z-index: 50;
      margin-top: 100px;
      width: 100px;
      height: 100px;
    }
  }
`;
const LoadingText = styled.div`
  margin: 20px auto;
  color: #b2bec3;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  text-align: center;
`;
const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  /* margin-bottom: 10px; */
`;
const Tab = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  background-color: rgb(249, 249, 249);
  cursor: pointer;
  ${(props) =>
    props.checked
      ? `border: 1px solid #ed5f00;  border-bottom: none; color:#ed5f00; background-color: white;`
      : `border:1px solid rgb(215, 215, 215);   border-bottom:1px solid #ed5f00; `};

  transition: border-bottom 0.3s;
  @media (max-width: 800px) {
    font-size: 17px;
  } ;
`;

const HisSelect = styled.select`
  height: 35px;
  outline: none;
  /* width: 100%; */
  text-align: center;
  border: 2px solid lightgray;
  border-radius: 5px;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  /* margin-right: 10px; */
  &:focus {
    box-shadow: none;
    border: none;
    transition: 0.3s;
    border: 2px solid ${colors.bbeego};
  }
`;

//Info

export const InfoContainer = styled.div`
  width: 100%;
  margin: 0;
  display: grid;
  border-bottom: 2px solid rgb(235, 238, 244);
  @media (max-width: 800px) {
    grid-template-rows: 1fr 3fr;
    height: 140px;
    padding: 10px;
  }

  @media (min-width: 801px) {
    .appear {
      display: none;
    }
  }
`;
export const CNSContents = styled.div`
  width: 100%;
  display: grid;
  padding: 10px;
  grid-template-columns: 2.4fr 7.6fr;
`;
export const ConTitle = styled.div`
  padding: 7px;
  font-weight: 600;
  /* color: #ed5f00; */
  font-size: 16px;
`;
export const ConValue = styled.textarea`
  min-height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline-color: #ccc;
`;
export const InfoImage = styled.img`
  /* margin: 10px; */
  width: 70px;
  height: 70px;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  margin: 0 auto;
  object-fit: cover;
  object-position: left top;
  @media (min-width: 801px) {
    align-self: flex-start;
  }
  @media (max-width: 800px) {
    margin: unset;
    margin-right: auto;
  }
`;
export const CNSTitle = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  /* color: #ed5f00; */
  margin: 15px 0;
`;
export const InfoHisCard = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: 0.3fr 0.3fr 0.7fr 0.25fr 0.3fr;
  width: 100%;
  padding: 10px 0;
  margin: 0 auto;
  /* border-radius: 20px; */
  border: none;

  div {
    align-items: center;
    div {
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 0.4fr 1.2fr 0.4fr;
    .hidden {
      display: none;
    }
  }
  @media (min-width: 801px) {
    .appear {
      display: none;
    }
  }
`;
export const HisInfo = styled.div`
  display: grid;
  width: 100%;
  font-size: 17px;
  /* padding: 5px; */
  grid-template-columns: 0.55fr 0.45fr;
  div {
    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 90%;
      max-width: 175px;
      font-size: 20px;
      font-weight: 600;
      color: #ed5f00;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    div {
      h2 {
        font-size: 17px;
        max-width: 100px;
      }
    }
  }
  /* text-align: center; */
`;
export const Detail = styled.div`
  display: grid;
  div {
    cursor: pointer;
    color: #ed5f00;
    text-align: center;
    font-size: 14.5px;
  }
  div:hover {
    color: #0984e3;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    div {
      min-width: 100px;
      text-align: right;
    }
  }
`;
export const CDate = styled.div`
  margin: 0 auto;
  display: grid;
  /* padding: 5px; */
  font-size: 17px;
  font-weight: 700;
  @media (max-width: 800px) {
    width: 100%;
    /* display: none; */
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    padding: none;
    vertical-align: middle;
  } ;
`;

export const HiddenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
`;

export const PReqest = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
`;
export const CaseBadge = styled.i`
  /* margin: 0 auto; */
  display: grid;
  padding: 0.325rem 0.425rem;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: white;
  border-radius: 5px;
  width: ${(props) => (props.width ? props.width : "80px")};
  ${(props) =>
    props.variant === "success"
      ? `
    background-color: #198754;
    border-color: #198754;
  `
      : ""}
  ${(props) =>
    props.variant === "failed"
      ? `
    background-color: #dc3545;
    border-color: #dc3545;
  `
      : ""}
    ${(props) =>
    props.variant === "progress"
      ? `
    background-color: #0d6efd;
    border-color: #0d6efd;
  `
      : ""};
`;
export const UserCon = styled.div`
  /* vertical-align: middle; */
  /* height: 88px; */
  display: grid;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
`;
//Modal

const UserHistory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const me = CheckUserToken();
  const selectList = ["5", "10", "20"];
  const selectTypeList = ["전체", "결제 내역", "챌린지 내역", "PT 내역"];
  const selelctDesc = ["최신순", "오래된순"];
  const [type, setType] = useState("전체");
  const [desc, setDesc] = useState("desc");
  const [selected, setSelected] = useState("10");
  const [typeSelected, setTypeSelected] = useState("all");
  const [descSelected, setDescSelected] = useState("최신순");
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");

  const getModalData = (data) => {
    setModalData(data);
    setModal(true);
  };

  const getModal = (data) => {
    setModal(data);
  };
  const data = SelectReceiptsByUserId(
    me?.me?.id,
    Number(selected),
    null,
    null,
    typeSelected === "all" ? null : `${typeSelected}`,
    desc
  );

  const [once, setOnce] = useState(false);
  const [sData, setSData] = useState([]);
  const [bData, setBData] = useState([]);
  const [lastId, setLastId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const tab_1 = useRef();
  const tab_2 = useRef();
  const loader = useRef();
  const myHisTab = useReactiveVar(myHisTabVar);
  const handleSelect = (e) => {
    setSelected(e.target.value);
  };
  const handleTypeSelect = (e) => {
    if (e.target.value === "결제 내역") {
      setTypeSelected("MultiChallenge");
      setType(e.target.value);
    } else if (e.target.value === "챌린지 내역") {
      setTypeSelected("MultiChallengeContract");
      setType(e.target.value);
    } else if (e.target.value === "PT 내역") {
      setTypeSelected("PTRoom");
      setType(e.target.value);
    } else {
      setTypeSelected("");
      setType(e.target.value);
    }
    setIsLoading(true);
  };

  const handleDesc = (e) => {
    if (e.target.value === "최신순") {
      setDesc("desc");
      setDescSelected(e.target.value);
    } else {
      setDesc("asc");
      setDescSelected(e.target.value);
    }
  };
  const onDesc = (e) => {
    setDesc(desc === "asc" ? "desc" : "asc");
    if (desc === "asc") {
      setDescSelected("최신순");
    } else {
      setDescSelected("오래된순");
    }
  };
  useEffect(() => {}, [descSelected]);
  //남은 개수 계산
  const Quota =
    myHisTab === 1
      ? SelectReceiptsByUserId(
          me?.me?.id,
          null,
          null,
          null,
          typeSelected === "all" ? null : `${typeSelected}`
        )?.data?.selectReceiptsByUserId?.receiptSellList.length
      : SelectReceiptsByUserId(
          me?.me?.id,
          null,
          null,
          null,
          typeSelected === "all" ? null : `${typeSelected}`
        )?.data?.selectReceiptsByUserId?.receiptBuyList.length;
  const Completed = myHisTab === 1 ? sData?.length : bData?.length;
  const remaining = Quota - Completed;

  const onTabClick = (tabNo, { target }) => {
    myHisTabVar(tabNo);
  };

  //loadmore클릭시 lastid 받아서 refetch 후 기존 데이터와 merge
  const onLoadMore = async (e) => {
    const any = await data?.refetch({
      userId: me?.me?.id,
      take: Number(selected),
      sellLastId: myHisTab === 1 ? lastId : null,
      buyLastId: myHisTab === 2 ? lastId : null,
      dateType: desc,
    });

    if (myHisTab === 1) {
      let SData = [
        ...sData,
        ...any?.data?.selectReceiptsByUserId?.receiptSellList,
      ];

      setSData(SData);
    } else {
      let BData = [
        ...bData,
        ...any?.data?.selectReceiptsByUserId?.receiptBuyList,
      ];

      setBData(BData);
    }

    // setIsLoading(true);
  };

  //quantity refetch
  useEffect(() => {
    const fetchData = async (e) => {
      const any = await data?.refetch({
        userId: me?.me?.id,
        take: Number(selected),
        contractType: typeSelected === "all" ? null : `${typeSelected}`,
        dateType: descSelected == "최신순" ? "desc" : "asc",
      });
      const fetchdata =
        myHisTab === 1
          ? any?.data?.selectReceiptsByUserId?.receiptSellList
          : any?.data?.selectReceiptsByUserId?.receiptBuyList;
      myHisTab === 1 ? setSData(fetchdata) : setBData(fetchdata);
      setIsLoading(false);
    };

    fetchData();
  }, [selected]);
  //contractType refetch

  useEffect(() => {
    const fetchData = async (e) => {
      const any = await data?.refetch({
        userId: me?.me?.id,
        take: Number(selected),
        contractType: typeSelected === "all" ? null : `${typeSelected}`,
        dateType: descSelected == "최신순" ? "desc" : "asc",
      });
      const fetchdata =
        myHisTab === 1
          ? any?.data?.selectReceiptsByUserId?.receiptSellList
          : any?.data?.selectReceiptsByUserId?.receiptBuyList;
      myHisTab === 1 ? setSData(fetchdata) : setBData(fetchdata);
      setIsLoading(false);
    };

    fetchData();
  }, [typeSelected]);

  //dateType refetch
  // useEffect(() => {
  //   console.log("desc : ", desc);

  //   const fetchData = async (e) => {
  //     const any = await data?.refetch({
  //       userId: me?.me?.id,
  //       take: Number(selected),
  //       contractType: typeSelected === "all" ? null : `${typeSelected}`,
  //       dateType: descSelected == "최신순" ? "desc" : "asc",
  //     });
  //     const fetchdata =
  //       myHisTab === 1
  //         ? any?.data?.selectReceiptsByUserId?.receiptSellList
  //         : any?.data?.selectReceiptsByUserId?.receiptBuyList;
  //     myHisTab === 1 ? setSData(fetchdata) : setBData(fetchdata);
  //     setIsLoading(false);
  //   };

  //   fetchData();
  // }, [desc]);
  useEffect(() => {
    const fetchData = async (e) => {
      const any = await data?.refetch({
        userId: me?.me?.id,
        take: Number(selected),
        contractType: typeSelected === "all" ? null : `${typeSelected}`,
        dateType: descSelected == "최신순" ? "desc" : "asc",
      });
      const fetchdata =
        myHisTab === 1
          ? any?.data?.selectReceiptsByUserId?.receiptSellList
          : any?.data?.selectReceiptsByUserId?.receiptBuyList;
      myHisTab === 1 ? setSData(fetchdata) : setBData(fetchdata);
      setIsLoading(false);
    };

    fetchData();
  }, [descSelected]);

  useEffect(() => {
    if (
      data?.data?.selectReceiptsByUserId?.receiptSellList?.length &&
      myHisTab === 1
    ) {
      setLastId(
        data?.data?.selectReceiptsByUserId?.receiptSellList[
          data?.data?.selectReceiptsByUserId?.receiptSellList?.length - 1
        ].id
      );
    } else if (
      data?.data?.selectReceiptsByUserId?.receiptBuyList?.length &&
      myHisTab === 2
    ) {
      setLastId(
        data?.data?.selectReceiptsByUserId?.receiptBuyList[
          data?.data?.selectReceiptsByUserId?.receiptBuyList?.length - 1
        ].id
      );
    }
  }, []);

  useEffect(() => {
    if (
      data?.data?.selectReceiptsByUserId?.receiptSellList?.length &&
      myHisTab === 1
    ) {
      setLastId(
        data?.data?.selectReceiptsByUserId?.receiptSellList[
          data?.data?.selectReceiptsByUserId?.receiptSellList?.length - 1
        ].id
      );
    } else if (
      data?.data?.selectReceiptsByUserId?.receiptBuyList?.length &&
      myHisTab === 2
    ) {
      setLastId(
        data?.data?.selectReceiptsByUserId?.receiptBuyList[
          data?.data?.selectReceiptsByUserId?.receiptBuyList?.length - 1
        ].id
      );
    }
  }, [data?.data?.selectReceiptsByUserId]);

  useEffect(() => {
    if (data?.data && !once) {
      setSData(data?.data?.selectReceiptsByUserId?.receiptSellList);
      setBData(data?.data?.selectReceiptsByUserId?.receiptBuyList);
      setOnce(true);
    }
  }, [data?.data?.selectReceiptsByUserId]);

  useEffect(() => {
    return () => {
      myHisTabVar(1); //화면을 벗어날경우 myGymTab 의 값을 1 로 다시 초기화
    };
  }, []);

  const [badge, setBadge] = useState("trainer");
  useEffect(() => {}, [bData]);
  useEffect(() => {
    const type = me?.me?.type;
    if (type === "trainer") {
      setBadge("trainer");
    } else {
      setBadge("user");
    }
  }, []);
  return (
    <Container style={{ maxWidth: "950px" }}>
      <Title>
        {me?.me?.username} 님의{" "}
        {myHisTab == 1 ? "판매 및 수령" : "구매 및 결제"}내역 (
        {Quota ? Quota : 0})
      </Title>
      <HistoryCon>
        <div style={{ width: "100%", margin: "0 auto", marginBottom: "10px" }}>
          <HisSelect
            style={{
              marginRight: "5px",
              marginBottom: "5px",
            }}
            onChange={handleSelect}
            value={selected}
          >
            {selectList.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </HisSelect>
          <HisSelect
            style={{
              marginRight: "5px",
              marginBottom: "5px",
            }}
            onChange={handleTypeSelect}
            value={type}
          >
            {selectTypeList?.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </HisSelect>
          <HisSelect
            style={{
              marginRight: "5px",
              marginBottom: "5px",
            }}
            onChange={handleDesc}
            value={descSelected}
          >
            {selelctDesc?.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </HisSelect>
          <Tabs>
            <Tab
              ref={tab_1}
              className="tab"
              checked={myHisTab === 1 ? true : false}
              onClick={(e) => {
                onTabClick(1, e);
              }}
            >
              판매 및 수령 내역
            </Tab>
            <Tab
              ref={tab_2}
              className="tab"
              checked={myHisTab === 2 ? true : false}
              onClick={(e) => {
                onTabClick(2, e);
              }}
            >
              구매 및 결제 내역
            </Tab>
          </Tabs>
        </div>
        <HisHead>
          <HistoryDate
            className="historyTitle hidden"
            onClick={(e) => onDesc()}
          >
            날짜 <i className="bi bi-chevron-expand" />
          </HistoryDate>
          <div className="historyTitle">
            {" "}
            {myHisTab === 1 ? "구매자" : "판매자"}
          </div>
          <InfoHead>
            <div className="historyTitle">상품정보</div>
            {/* <div>기간</div> */}
            <div className="historyTitle hidden">구분</div>
          </InfoHead>

          <div className="historyTitle hidden">상세</div>
          <div className="historyTitle">거래현황</div>
        </HisHead>
        <History>
          {data?.loading ? (
            <Loading>
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border text-secondary"
                  role="status"
                ></div>
              </div>
              <LoadingText>Loading</LoadingText>
            </Loading>
          ) : myHisTab === 1 ? (
            sData?.length ? (
              sData?.map((rs, idx) => {
                return rs?.contractType === "PTRoom" ? (
                  <SellerHistoryCard
                    getModalData={getModalData}
                    className="card"
                    key={idx}
                    rs={rs}
                  />
                ) : (
                  <RewardHistoryCard
                    getModalData={getModalData}
                    className="card"
                    key={idx}
                    rs={rs}
                  />
                );
              })
            ) : (
              <NoHistory>
                <div>판매 및 수령 내역이 존재하지 않습니다...</div>
              </NoHistory>
            )
          ) : bData?.length ? (
            bData?.map((rb, idx) => {
              return rb?.contractType === "PTRoom" ? (
                <BuyerHistoryCard
                  className="card"
                  key={idx}
                  getModalData={getModalData}
                  rb={rb}
                  refetch={async () =>
                    await data?.refetch({
                      userId: me?.me?.id,
                      take: Number(selected),
                      contractType:
                        typeSelected === "all" ? null : `${typeSelected}`,
                      dateType: descSelected == "최신순" ? "desc" : "asc",
                    })
                  }
                  setOnce={setOnce}
                />
              ) : (
                <PayMentHistoryCard
                  getModalData={getModalData}
                  className="card"
                  key={idx}
                  rb={rb}
                />
              );
            })
          ) : !data?.loading ? (
            <NoHistory>
              <div>현재 구매 및 결제내역이 존재하지 않습니다...</div>
            </NoHistory>
          ) : null}
          {remaining > 0 && !data?.loading ? (
            <LoadMore onClick={onLoadMore} className="ioRoot">
              {" "}
              <div ref={loader}>Load More({remaining})...</div>
            </LoadMore>
          ) : null}
        </History>
      </HistoryCon>
      <Modal show={modal} onHide={() => setModal(false)} centered="true">
        <HistoryDetails getModal={getModal} modalData={modalData} />
      </Modal>
    </Container>
  );
};

export default UserHistory;
