//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import client from "../../client";
import { DELETE_MESSAGE_MUTATION } from "../../queries/message/deleteMessage";

const DropCon = styled.div`
  width: 100px;
  z-index: 105;
  /* padding: 10px 0; */
  display: ${(props) => (props.display ? props?.display : "none")};
  position: fixed;
  top: ${(props) => (props.top ? props.top : "0px")};
  left: ${(props) => (props.right ? props.right : "0px")};
  background-color: white;
  text-align: center;
  color: white;
  border-radius: 5px;
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  overflow: hidden;
`;
const MenuCon = styled.ul`
  display: flex;
  flex-direction: column;
  /* padding: 0 0.425rem; */
`;
const Menu = styled.li`
  width: 100%;
`;

const MenuBtn = styled.button`
  width: 100%;
  font-size: 13px;
  padding: 0.525rem 0.425rem;
  background-color: white;
  color: black;
  border: none;
  border-bottom: 1px solid #ccc;
  &:hover {
    color: white;
    background-color: #f58057;
    font-weight: bold;
  }
`;
const DelBtn = styled(MenuBtn)`
  /* background-color: ${(props) =>
    props.bgColor ? props.bgColor : "white"}; */
  border: none;
  &:hover {
    color: white;
    background-color: ${(props) => (props.bgColor ? props.bgColor : "red")};
  }
`;
const DropDownModal = ({ msgModal, msgInfo, setMsgModal, getReplyMsg }) => {
  // console.log(msgInfo);
  const MsgModal = msgModal;
  const msgToTop = msgInfo?.x + "px";
  const msgToRight = msgInfo?.y + "px";
  const createdAt = Number(msgInfo?.msg?.createdAt);
  const date = new Date().getTime();
  const isMine = msgInfo?.msg?.isMine;
  const MsgId = Number(msgInfo?.msg?.id);

  const onCompleted = async (data) => {
    const {
      deleteMessage: { ok },
    } = data;
    if (ok) {
      client.cache.writeFragment({
        id: "Message:" + String(msgInfo?.msg?.id),
        fragment: gql`
          fragment MyMessage on Message {
            deletedAt
          }
        `,
        data: {
          deletedAt: String(new Date().toJSON()),
        },
      });
    }
    setMsgModal(false);
  };

  const [deleteMessagMutation] = useMutation(DELETE_MESSAGE_MUTATION, {
    onCompleted,
  });

  const DeleteMsg = () => {
    // console.log(MsgId);
    const result = window.confirm("메세지를 삭제합니다");
    if (result) {
      deleteMessagMutation({
        variables: {
          id: MsgId,
        },
      });
    }
  };

  //   console.log(MsgModal, msgToTop, msgToRight);
  return (
    <DropCon
      className="mouseoutException"
      display={MsgModal ? "block" : "none"}
      top={msgToRight}
      right={msgToTop}
    >
      <MenuCon className="mouseoutException">
        <Menu className="mouseoutException">
          <MenuBtn
            className="mouseoutException"
            onClick={() => getReplyMsg(msgInfo)}
          >
            답장
          </MenuBtn>{" "}
        </Menu>
        <Menu className="mouseoutException">
          <DelBtn
            className="mouseoutException"
            onClick={() => DeleteMsg()}
            disabled={
              !isMine || createdAt + 1000 * 60 * 5 < date ? true : false
            }
            bgColor={
              !isMine || createdAt + 1000 * 60 * 5 < date
                ? "rgb(215,215,215)"
                : "red"
            }
          >
            삭제
          </DelBtn>
        </Menu>
      </MenuCon>
    </DropCon>
  );
};
export default DropDownModal;
