//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import Grid from "@toast-ui/react-grid";
import { useRef } from "react";
import { useState } from "react";

import { SButton } from "../shared/SButton";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import AdminSelectContract from "../../queries/admins/contract/adminSelectContract";

const Container = styled.div`
  width: 99%;
  background-color: white;
  padding: 10px;
  margin: 15px auto;
  box-shadow: 0 2px 5px #ccc;
  border-radius: 5px;
  .vBtn {
    border: none;
    background-color: white;
    margin: 5px;
    text-align: center;
    width: 100%;
    color: gray;
  }
  .vBtn:hover {
    background-color: orange;
    color: white;
    font-size: 25px;
    border-radius: 5px;
  }
`;

const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

const FontCon = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContractGrid = () => {
  const gridRef = useRef();
  let data = AdminSelectContract(100);
  const [aHidden, setHidden] = useState(false);
  const toggleMenu = () => {
    //aHidden = !aHidden;
    setHidden(!aHidden);
  };

  const contractColumns = [
    { name: "id", header: "아이디", align: "center", hidden: true },
    { name: "users", header: "유저이름", align: "center" },
    { name: "accepted", header: "확인", align: "center" },
    { name: "purchased", header: "구입", align: "center" },
    { name: "denied", header: "거절", align: "center" },
    { name: "type", header: "타입", align: "center" },
    { name: "denyMessage", header: "거절 사유", align: "center" },
    { name: "createdAt", header: "생성 날짜", align: "center" },
    { name: "updatedAt", header: "업데이트 날짜", align: "center" },
    { name: "deletedAt", header: "삭제 날짜", align: "center", hidden: true },
  ];

  useEffect(() => {
    gridRef?.current?.getInstance().resetData(
      data?.adminSelectContract?.map((v) => ({
        ...v,
        users: v.users[0].username + " / " + v.users[1].username,
        type:
          v.accepted === true && v.purchased === false && v.denied === false
            ? "accept"
            : v.accepted === true && v.purchased === true && v.denied === false
            ? "purchase"
            : v.accepted === false && v.purchased === false && v.denied === true
            ? "deny"
            : "기본 값",

        createdAt: v.createdAt
          ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
          : v.createdAt,
        updatedAt: v.updatedAt
          ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
          : v.updatedAt,
        deletedAt: v.deletedAt
          ? new Date(Number(v.deletedAt)).toJSON().split("T")[0]
          : v.deletedAt,
      }))
    );
  });

  return (
    <Container>
      <button className="vBtn" onClick={() => toggleMenu()}>
        {aHidden ? (
          <FontCon>
            Contract Close
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </FontCon>
        ) : (
          <FontCon>
            Contract Open
            <FontAwesomeIcon size="2x" icon={faAngleRight} />
          </FontCon>
        )}
      </button>
      {aHidden ? (
        <UserContainer>
          <div style={{ textAlign: "right" }}>
            <SButton
              display="inline-block"
              //   onClick={onUserCreateClick}
              width="fit-content"
              context="+ 새 Contract 생성"
              fontSize="12px"
              color="black"
              border="1px solid lightgray"
            />
          </div>
          <Row margin="10px 0">
            {data?.adminSelectContract?.length && (
              <Grid
                ref={gridRef}
                data={data?.adminSelectContract?.map((v) => {
                  return {
                    ...v,
                    users: v.users[0].username + " / " + v.users[1].username,
                    createdAt: v.createdAt
                      ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
                      : v.createdAt,
                    updatedAt: v.updatedAt
                      ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
                      : v.updatedAt,
                  };
                })}
                width="auto"
                bodyHeight={300}
                columns={contractColumns}
                rowHeaders={["rowNum"]}
                pageOptions={{
                  perPage: 0,
                  useClient: true,
                  type: "scroll",
                }}
              />
            )}
          </Row>
        </UserContainer>
      ) : null}
    </Container>
  );
};
export default ContractGrid;
