//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../utils/colors";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom: 2px solid rgb(230, 229, 239);
  height: 60px;
`;
const ReplyToCon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 5px;
`;

const ReplyTo = styled.p`
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: ${colors.bbeegoText};
`;

const Content = styled.h6`
  width: 100%;
  font-size: 14px;
  overflow: hidden;
  line-height: 1.5;
  padding: 0 15px;
`;

const CloseBtn = styled.div`
  font-size: 16px;
  cursor: pointer;
  color: #ccc;
`;

export const getExt = (filename) => {
  let fileLen = filename.length;

  /*
   * lastIndexOf('.')
   * 뒤에서부터 '.'의 위치를 찾기위한 함수
   * 검색 문자의 위치를 반환한다.
   * 파일 이름에 '.'이 포함되는 경우가 있기 때문에 lastIndexOf() 사용
   */
  let lastDot = filename.lastIndexOf(".");

  // 확장자 명만 추출한 후 소문자로 변경
  let fileExt = filename
    .substring(lastDot, fileLen)
    .toLowerCase()
    .replace(".", "");

  return fileExt;
};

const ReplyMessage = ({ setIsReply, replyMsg }) => {
  const msg = replyMsg.msg;
  const sanitizer = DOMPurify.sanitize;
  const [content, setContent] = useState(null);

  const imgArr = ["jpg", "jpeg", "png", "gif"];
  const fileArr = [
    "txt",
    "pdf",
    "apk",
    "ppt",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "zip",
    "hwp",
  ];

  useEffect(() => {
    const message = msg?.content;
    const ext = getExt(message);

    /*
     * 40자 를 넘어간 텍스트를 '...'로 반환
     * 이미지 or 파일은 "이미지" 와 "파일"로 반환
     */
    if (imgArr.includes(ext)) {
      // console.log("Ext : ", ext);
      setContent("이미지");
    } else if (fileArr.includes(ext)) {
      const filename = String(message?.split("/static/")[1]);
      if (filename?.length >= 30) {
        let newContent = filename.substr(0, 30) + "..." + ext;

        setContent(ext + " 파일 : " + newContent);
      } else {
        setContent(ext + " 파일 : " + filename);
      }
    } else {
      if (message?.length >= 40) {
        let newContent = message.substr(0, 40);
        // console.log(newContent);
        if (newContent?.includes("<br />")) {
          let splitContent = newContent?.split("<br />")[0] + "...";
          setContent(splitContent);
        } else {
          let overContent = newContent + "...";
          setContent(overContent);
        }
      } else {
        let newContent = message;
        if (newContent?.includes("<br />")) {
          let splitContent = newContent?.split("<br />")[0];
          setContent(splitContent);
        } else {
          // console.log(newContent);
          setContent(newContent);
        }
      }
    }
  }, [msg]);
  return (
    <Container>
      <ReplyToCon>
        <ReplyTo>{`${msg?.user?.username}에게 답장`}</ReplyTo>
        <CloseBtn onClick={() => setIsReply(false)}>X</CloseBtn>
      </ReplyToCon>
      {content ? (
        <Content
          dangerouslySetInnerHTML={{
            __html: sanitizer(content),
          }}
        ></Content>
      ) : null}
    </Container>
  );
};
export default ReplyMessage;
