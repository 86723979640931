import styled from "styled-components";

const ThumbCon = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
  }
`;

const YoutubeThumb = ({ link }) => {
  //   console.log(link);
  let replaceUrl = link[0];
  let finUrl = "";
  replaceUrl = replaceUrl.replace("https://youtu.be/", "");
  replaceUrl = replaceUrl.replace("https://www.youtube.com/embed/", "");
  replaceUrl = replaceUrl.replace("https://www.youtube.com/watch?v=", "");
  finUrl = replaceUrl.split("&")[0];
  //   console.log(finUrl);

  const onClick = () => {
    window.open(link[0]);
  };
  return (
    <ThumbCon onClick={() => onClick()}>
      <img src={`https://img.youtube.com/vi/${finUrl}/mqdefault.jpg`} />
    </ThumbCon>
  );
};

export default YoutubeThumb;
