import { useMutation } from "@apollo/client";
import { CardColumns, Container } from "react-bootstrap";
import { GButton } from "../component/shared/GButton";
import Profile from "../component/shared/Profile";
import { SelectBlockList } from "../queries/user/selectBlockList";
import { UNBLOCK_USER_MUTATION } from "../queries/user/unblockUser";

const BlockList = () => {
  //queries
  const { data: blockList, refetch: refetchBlockList } = SelectBlockList();
  const onUnblockCompleted = (data) => {
    const {
      unblockUser: { ok },
    } = data;
    if (ok) {
      refetchBlockList();
    }
  };
  const [unblockUserMutation] = useMutation(UNBLOCK_USER_MUTATION, {
    onCompleted: onUnblockCompleted,
  });
  //event
  const onUnblockClick = (user) => {
    const confirmBool = window.confirm("해당 유저를 차단 해제 하시겠습니까?");
    if (!!confirmBool) {
      unblockUserMutation({
        variables: {
          userId: user?.id,
        },
      });
    }
  };
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        padding: "10px",
      }}
    >
      <CardColumns>
        <CardColumns
          style={{
            width: "100%",
            fontSize: "20px",
            fontWeight: "bold",
            color: "rgb(99, 110, 114)",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "15px",
          }}
        >
          <span>차단목록</span>
        </CardColumns>
      </CardColumns>
      <CardColumns
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          height: "500px",
          overflowY: "auto",
          alignItems: "center",
        }}
      >
        {blockList?.selectBlockList?.map((b, idx) => (
          <CardColumns
            key={idx}
            style={{
              backgroundColor: "white",
              width: "96%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              gap: "20px",
              boxShadow: "0 5px 20px 0 rgb(82 63 105 / 20%)",
            }}
          >
            <CardColumns
              style={{
                maxWidth: "200px",
                overflow: "hidden",
                lineBreak: "anywhere",
              }}
            >
              <Profile
                fontSize="18px"
                src={b?.profilePhoto}
                id={b?.id}
                username={b?.username}
                color="rgb(99, 110, 114)"
              />
            </CardColumns>
            <CardColumns>
              <GButton
                variant="create"
                context="차단 해제"
                fontSize="14px"
                onClick={() => onUnblockClick(b)}
              />
            </CardColumns>
          </CardColumns>
        ))}
      </CardColumns>
    </Container>
  );
};

export default BlockList;
