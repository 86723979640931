//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { REVIEW_FRAGMENT } from "../fragments/reviewFragment";

export const SELECT_REVIEWS_BY_PRODUCT_ID_QUERY = gql`
  query SelectReviewsByProductId(
    $type: String!
    $typeId: Int!
    $take: Int
    $lastId: Int
  ) {
    selectReviewsByProductId(
      type: $type
      typeId: $typeId
      take: $take
      lastId: $lastId
    ) {
      ...ReviewFragment
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const SelectReviewsByProductId = (type, typeId, take, lastId) => {
  const { data } = useQuery(SELECT_REVIEWS_BY_PRODUCT_ID_QUERY, {
    variables: {
      type,
      typeId,
      ...(take && { take }),
      ...(lastId && { lastId }),
    },
  });
  return { data };
};
