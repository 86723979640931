//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//Challenge Detail 화면.
//부모 main 화면(Main_p.js) 에서 challenge 를 클릭했을시 넘어오는 화면.
//신청한 트레이너 목록과 여러 정보들을 보여주고, 트레이너 선정할 수 있도록 하는 목적으로 만들어졌다.

import { useLocation } from "react-router";
import styled from "styled-components";
import { SeeChallenge } from "../queries/challenge/seeChallenge";
import React from "react";
import ChatroomModule from "../component/chatroom/ChatroomModule";

const Container = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 1000px;
`;
const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
`;
const TrainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
`;
const TraineeInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
`;
const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
`;
const ColTitle = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 10px;
  font-weight: bold;
  background-color: orange;
  color: white;
`;
const ColValue = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 10px;
  word-break: break-all;
`;

const RowTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const ChatroomCon = styled.div`
  width: 100%;
  height: 500px;
`;

const ChallengeDetail = () => {
  const location = useLocation();
  const data = SeeChallenge(location?.state?.id);

  return (
    <Container id="main">
      <RowTitle>챌린지 정보</RowTitle>
      <BasicInfo>
        <Row>
          <ColTitle>챌린지 제목</ColTitle>
          <ColValue>{data?.seeChallenge?.title}</ColValue>
        </Row>
        <Row>
          <ColTitle>챌린지 설명</ColTitle>
          <ColValue>{data?.seeChallenge?.description}</ColValue>
        </Row>
        <Row>
          <ColTitle>챌린지 생성 날짜</ColTitle>
          <ColValue>
            {
              new Date(Number(data?.seeChallenge?.createdAt))
                .toJSON()
                ?.split("T")[0]
            }
          </ColValue>
        </Row>
        <Row>
          <ColTitle>챌린지 기간</ColTitle>
          <ColValue>{data?.seeChallenge?.term}</ColValue>
        </Row>
      </BasicInfo>
      <RowTitle>트레이너 정보</RowTitle>
      <TrainerInfo>
        <Row>
          <ColTitle>트레이너</ColTitle>
          <ColValue>{data?.seeChallenge?.trainer?.username}</ColValue>
        </Row>
        <Row>
          <ColTitle>기본금</ColTitle>
          <ColValue>{data?.seeChallenge?.baseprice}</ColValue>
        </Row>
        <Row>
          <ColTitle>추가금</ColTitle>
          <ColValue>{data?.seeChallenge?.bonus}</ColValue>
        </Row>
      </TrainerInfo>
      <RowTitle>챌린지 대상 정보</RowTitle>
      <TraineeInfo>
        <Row>
          <ColTitle>챌린지 대상</ColTitle>
          <ColValue>{data?.seeChallenge?.trainee?.username}</ColValue>
        </Row>
        <Row>
          <ColTitle>보상금</ColTitle>
          <ColValue>{data?.seeChallenge?.reward}</ColValue>
        </Row>
        <Row>
          <ColTitle>시작 몸무게</ColTitle>
          <ColValue>{data?.seeChallenge?.currentWeight}</ColValue>
        </Row>
        <Row>
          <ColTitle>목표 몸무게</ColTitle>
          <ColValue>{data?.seeChallenge?.targetWeight}</ColValue>
        </Row>
        <Row>
          <ColTitle>시작 체지방률</ColTitle>
          <ColValue>{data?.seeChallenge?.currentFatPercentage}</ColValue>
        </Row>
        <Row>
          <ColTitle>목표 체지방률</ColTitle>
          <ColValue>{data?.seeChallenge?.targetFatPercentage}</ColValue>
        </Row>
      </TraineeInfo>
      <ChatroomCon>
        <ChatroomModule id={data?.seeChallenge?.chatroom?.id} />
      </ChatroomCon>
    </Container>
  );
};

export default ChallengeDetail;
