//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import UseFormError from "../Accounts/UseFormError";
import {
  AuthContainer,
  BottomContainer,
  Form,
  HeaderContainer,
  Input,
  Select,
  Span,
  Submit,
} from "../component/shared/Auth";
import { routes } from "../routes/routes";
import { useEffect, useState } from "react";
import { CardColumns } from "react-bootstrap";
import { GButton } from "../component/shared/GButton";
import { EMAIL_VERIFICATION_MUTATION } from "../queries/mail/emailVerfication";
import colors from "../utils/colors";
import { Logoh2 } from "../component/main/MainHeader";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const CREATE_USER = gql`
  mutation CreateUser(
    $username: String!
    $email: String!
    $password: String!
    $realName: String
    $bank: String
    $accountNumber: String
    $birthyear: Int
    $birthmonth: Int
    $birthday: Int
    $type: String!
    $gender: String
  ) {
    createUser(
      username: $username
      email: $email
      password: $password
      realName: $realName
      bank: $bank
      accountNumber: $accountNumber
      birthyear: $birthyear
      birthmonth: $birthmonth
      birthday: $birthday
      type: $type
      gender: $gender
    ) {
      ok
      error
    }
  }
`;

const Signup = () => {
  const history = useHistory();
  const [isMailSent, setIsMailSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [verifyCode, setVerifyCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [termAgreement, setTermAgreement] = useState(false);
  const {
    register,
    formState,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const onCompleted = (data) => {
    const {
      createUser: { ok },
    } = data;
    if (!ok) {
      return setError("result", {
        message:
          "해당 이메일의 계정이 있거나 회원가입이 정상적으로 이루어지지 않았습니다. 다시 시도해주세요.",
      });
    }
    history.push(routes.home, {
      message: "회원가입이 완료되었습니다. 로그인 해주세요.",
    });
  };
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted,
  });
  const onSubmitValid = (data) => {
    const { gender } = getValues();

    const {
      username,
      email,
      password,
      realName,
      bank,
      accountNumber,
      birthyear,
      birthmonth,
      birthday,
    } = data;
    createUser({
      variables: {
        username,
        email,
        password,
        realName,
        bank,
        accountNumber,
        birthyear: Number(birthyear),
        birthmonth: Number(birthmonth),
        birthday: Number(birthday),
        type: "user",
        gender,
      },
    });
  };
  const clearSignupError = (event) => {
    const { password, password_check } = getValues();
    if (password !== password_check) {
      setError("password_equality", { message: "비밀번호가 다릅니다." });
    } else {
      clearErrors("password_equality");
    }
    if (event.keyCode !== 13) {
      clearErrors("result");
    }
  };
  const date = new Date();
  const year = date?.getFullYear();
  const month = date?.getMonth() + 1;
  const day = date?.getDate();
  const startY = Number(year) - 100;
  const SelYears = [];
  const SelMonths = [];
  const SelDays = [];
  for (let y = startY; y <= year; y++) {
    SelYears.push(y);
  }
  for (let m = 1; m <= 12; m++) {
    SelMonths.push(m);
  }
  for (let d = 1; d <= 31; d++) {
    SelDays.push(d);
  }
  //이메일 인증 관련
  //인증 코드 전송
  const onEmailVeriCompleted = (data) => {
    const {
      emailVerification: { ok },
    } = data;
    if (ok) {
      setIsMailSent(true);
    }
  };
  const [emailVerificationMutation] = useMutation(EMAIL_VERIFICATION_MUTATION, {
    onCompleted: onEmailVeriCompleted,
  });
  const sendVerificationMail = () => {
    setIsLoading(true);
    const { email } = getValues();
    const verifyCode = Math.random().toString(36).substring(2, 7).toUpperCase();
    emailVerificationMutation({
      variables: {
        to: email,
        title: "BBEEGO 인증 코드",
        context: `<div style="width:100%;display:flex;flex-direction:column;align-items:center;gap:10px">
        <p><h1 style="font-weight:bold">이메일 인증</h1></p>
        <p><h2><span>인증 코드 : </span><span style="color:${colors.bbeego}">${verifyCode}</span></h2></p>
      </div>`,
      },
    });
    setVerifyCode(verifyCode);
  };
  //인증 확인
  const confirmVerifyCode = () => {
    const { verifyCodeInput } = getValues();
    if (verifyCode === verifyCodeInput) {
      alert("인증 성공!");
      setIsVerified(true);
      setIsLoading(false);
    } else {
      alert("잘못된 인증 코드입니다.\n다시 입력해주세요.");
    }
  };
  //진입시
  useEffect(() => {
    register("gender", { required: "성별을 선택해주세요." });
  }, []);

  return (
    <AuthContainer>
      <CardColumns
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          borderRadius: "25px",
          overflow: "hidden",
        }}
      >
        <CardColumns style={{ width: "350px" }}>
          <HeaderContainer>
            <Link to={routes.home} className="Logo" style={{ zIndex: 103 }}>
              <Logoh2
                className="logo"
                style={{ color: "white !important" }}
              ></Logoh2>
            </Link>
            <Form
              onSubmit={handleSubmit(onSubmitValid)}
              style={{ width: "100%" }}
            >
              <CardColumns>
                <Input
                  {...register("username", {
                    required: "username 을 입력해주세요.",
                    minLength: {
                      value: 5,
                      message: "username 은 5자 이상이어야 합니다.",
                    },
                  })}
                  onKeyDown={clearSignupError}
                  type="text"
                  placeholder="username"
                  className="form-control"
                />
                <UseFormError
                  color="red"
                  message={formState?.errors?.username?.message}
                />
              </CardColumns>
              <CardColumns>
                <CardColumns
                  style={{
                    display: "grid",
                    gap: "5px",
                    gridTemplateColumns: "1fr auto",
                    justifyContent: "space-between",
                  }}
                >
                  <Input
                    {...register("email", {
                      required: "email 을 입력해주세요.",
                      pattern: {
                        value:
                          /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                        message: "이메일 형식이 아닙니다.",
                      },
                    })}
                    onKeyDown={clearSignupError}
                    type="text"
                    placeholder="email"
                    className="form-control"
                    // readOnly={isVerified ? true : false}
                  />
                  {/* {!isMailSent && !isVerified ? (
                    !isLoading ? (
                      <GButton
                        context="인증코드 전송"
                        width="fit-content"
                        margin="2px 0"
                        border="0"
                        bgColor="white"
                        color={colors.bbeego}
                        onClick={sendVerificationMail}
                      />
                    ) : (
                      <button className="btn" type="submit" disabled="">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </button>
                    )
                  ) : isMailSent && !isVerified ? (
                    <GButton
                      context="인증코드 전송"
                      width="fit-content"
                      disabled={true}
                      border="0"
                      bgColor="white"
                      color={colors.bbeego}
                      margin="2px 0"
                    />
                  ) : isMailSent && isVerified ? (
                    <GButton
                      variant="success"
                      context="인증 완료"
                      width="fit-content"
                      disabled={true}
                      margin="2px 0"
                    />
                  ) : null} */}
                </CardColumns>
                {/* {isMailSent && !isVerified ? (
                  <CardColumns
                    style={{
                      display: "grid",
                      gap: "5px",
                      gridTemplateColumns: "1fr auto",
                      justifyContent: "space-between",
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="인증코드 입력"
                      {...register("verifyCodeInput")}
                    />
                    <GButton
                      variant="create"
                      context="인증코드 확인"
                      width="fit-content"
                      margin="2px 0"
                      onClick={confirmVerifyCode}
                    />
                  </CardColumns>
                ) : null} */}
                <UseFormError
                  color="red"
                  message={formState?.errors?.email?.message}
                />
              </CardColumns>
              <CardColumns>
                <Input
                  {...register("realName", {
                    required: "실명을 입력해주세요.",
                  })}
                  type="text"
                  placeholder="성명"
                  className="form-control"
                />
                <UseFormError
                  color="red"
                  message={formState?.errors?.realName?.message}
                />
              </CardColumns>
              <CardColumns>
                <CardColumns
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <CardColumns style={{ color: "white", paddingLeft: "5px" }}>
                    성별
                  </CardColumns>
                  <CardColumns>
                    <RadioGroup
                      aria-label="gender"
                      name="radio-buttons-group"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="남"
                        control={
                          <Radio color="primary" style={{ color: "white" }} />
                        }
                        label="남"
                        onClick={(e) => {
                          e.target.checked && setValue("gender", "male");
                          clearErrors("gender");
                        }}
                        style={{ color: "white" }}
                      />
                      <FormControlLabel
                        value="여"
                        control={
                          <Radio color="primary" style={{ color: "white" }} />
                        }
                        label="여"
                        onClick={(e) => {
                          e.target.checked && setValue("gender", "female");
                          clearErrors("gender");
                        }}
                        style={{ color: "white" }}
                      />
                    </RadioGroup>
                  </CardColumns>
                </CardColumns>
                <UseFormError
                  color="red"
                  message={formState?.errors?.gender?.message}
                />
              </CardColumns>
              <CardColumns>
                <Input
                  {...register("bank", {
                    required: "은행명을 입력해주세요.",
                  })}
                  type="text"
                  placeholder="은행명"
                  className="form-control"
                />
                <UseFormError
                  color="red"
                  message={formState?.errors?.bank?.message}
                />
              </CardColumns>
              <CardColumns>
                <Input
                  {...register("accountNumber", {
                    required: "계좌번호를 입력해주세요.",
                  })}
                  type="text"
                  placeholder="계좌번호('-' 없이 입력)"
                  className="form-control"
                />
                <UseFormError
                  color="red"
                  message={formState?.errors?.accountNumber?.message}
                />
              </CardColumns>
              <CardColumns>
                <Select
                  className="year"
                  {...register("birthyear", {
                    required: "태어난 년도를 선택해주세요.",
                  })}
                >
                  {SelYears?.map((v) => {
                    if (v === year) {
                      return (
                        <option value={v} selected>
                          {v}년
                        </option>
                      );
                    } else {
                      return <option value={v}>{v}년</option>;
                    }
                  })}
                </Select>
              </CardColumns>
              <CardColumns>
                <Select
                  className="month"
                  {...register("birthmonth", {
                    required: "태어난 달을 선택해주세요.",
                  })}
                >
                  {SelMonths?.map((v) => {
                    if (v === month) {
                      return (
                        <option value={v} selected>
                          {v}월
                        </option>
                      );
                    } else {
                      return <option value={v}>{v}월</option>;
                    }
                  })}
                </Select>
              </CardColumns>
              <CardColumns>
                <Select
                  className="day"
                  {...register("birthday", {
                    required: "태어난 날을 선택해주세요.",
                  })}
                >
                  {SelDays?.map((v) => {
                    if (v === day) {
                      return (
                        <option value={v} selected>
                          {v}일
                        </option>
                      );
                    } else {
                      return <option value={v}>{v}일</option>;
                    }
                  })}
                </Select>
              </CardColumns>
              <CardColumns>
                <Input
                  {...register("password", {
                    required: "password 를 입력해주세요.",
                    pattern: {
                      value:
                        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                      message:
                        "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
                    },
                  })}
                  onKeyDown={clearSignupError}
                  type="password"
                  placeholder="password"
                  className="form-control"
                />
                <UseFormError
                  color="red"
                  message={formState?.errors?.password?.message}
                />
              </CardColumns>
              <CardColumns>
                <Input
                  {...register("password_check", {
                    required: "password check 를 입력해주세요.",
                    pattern: {
                      value:
                        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
                      message:
                        "비밀번호는 8자 이상 문자, 숫자, 특수문자 모두 포함해야합니다.",
                    },
                  })}
                  onKeyUp={clearSignupError}
                  type="password"
                  placeholder="password_check"
                  className="form-control"
                />
                <UseFormError
                  color="red"
                  message={formState?.errors?.password_check?.message}
                />
              </CardColumns>
              <CardColumns>
                <UseFormError
                  color="red"
                  message={formState?.errors?.password_equality?.message}
                />
              </CardColumns>
              <CardColumns>
                <CardColumns
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox
                      onClick={(e) => setTermAgreement(e.target.checked)}
                      style={{ color: "white" }}
                    />
                    이용약관 동의
                  </label>

                  <Link
                    to={{ pathname: routes.terms }}
                    style={{ color: "white", fontSize: "12px" }}
                    target="_blank"
                  >
                    이용약관 보기
                  </Link>
                </CardColumns>
              </CardColumns>
              <CardColumns>
                <p
                  style={{
                    // marginLeft: "13px",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  * 가입후 일감은 조기 마감될수 있습니다
                </p>
              </CardColumns>
              <CardColumns>
                {termAgreement ? (
                  <Submit type="submit" value="Sign up" />
                ) : (
                  <Submit
                    type="submit"
                    value="Sign up"
                    disabled={true}
                    style={{ opacity: 0.5 }}
                  />
                )}
                <UseFormError
                  color="red"
                  message={formState?.errors?.result?.message}
                />
              </CardColumns>
            </Form>
          </HeaderContainer>
        </CardColumns>
        <CardColumns>
          <BottomContainer>
            <Span>계정이 있으신가요?</Span>
            <Link to={routes.home}>Log in</Link>
          </BottomContainer>
        </CardColumns>
      </CardColumns>
    </AuthContainer>
  );
};

export default Signup;
