//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//kakao 로 로그인 시 redirect 된 url 에서 code 를 도출하는 route

import { useHistory } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { KAKAO_ACCESS_TOKEN_MUTATION } from "../../queries/oauth/kakaoAccessToken";
import { KAKAO_REVOKE_EMAIL_AGREEMENT_MUTATION } from "../../queries/oauth/kakaoRevokeEmailAgreement";
import { userLogIn } from "../../utils/reactiveVar";

const KakaoOAuth = () => {
  const history = useHistory();
  const [token, setToken] = useState();
  const onCompleted = async (data) => {
    const {
      kakaoAccessToken: { ok, token: localToken, refreshtoken, type },
    } = data;
    if (!ok) {
      history.push("/");
    }
    // await userTypeVar(type);
    await userLogIn(localToken, type, refreshtoken);
    history.push("/");
  };
  const [accessTokenMutation] = useMutation(KAKAO_ACCESS_TOKEN_MUTATION, {
    onCompleted,
  });
  //인가 코드
  const code = new URL(window.location.href).searchParams.get("code");
  //AccessToken 받기
  let param = [
    "grant_type=authorization_code",
    `client_id=${process.env.REACT_APP_REST_API_KEY}`,
    `${encodeURIComponent(
      `redirect_uri=${process.env.REACT_APP_KAKAO_OAUTH_REDIRECT_URL}`
    )}`,
    `code=${code}`,
    `client_secret=${process.env.REACT_APP_CLIENT_SECRET}`,
  ];
  const getAccessToken = async () => {
    const res = await axios.post(
      "https://kauth.kakao.com/oauth/token",
      param.join("&"),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      }
    );

    setToken(res?.data?.access_token);
    accessTokenMutation({
      variables: {
        token: res?.data?.access_token,
      },
    });
  };
  useEffect(() => {
    if (code) {
      getAccessToken();
    }
  }, [code]);
  //이메일 동의 철회
  const onRevokeCompleted = (data) => {};
  const [kakaoRevokeEmailAgreementMutation] = useMutation(
    KAKAO_REVOKE_EMAIL_AGREEMENT_MUTATION,
    {
      onCompleted: onRevokeCompleted,
    }
  );
  const onRevokeEmailAgreement = async () => {
    kakaoRevokeEmailAgreementMutation({
      variables: {
        token,
      },
    });
  };
  return (
    <>
      <a
        href={`https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_OAUTH_REDIRECT_URL}&response_type=code&scope=account_email`}
      >
        카카오 이메일 제공 동의
      </a>
      <a
        href={`https://kauth.kakao.com/oauth/logout?client_id=${process.env.REACT_APP_REST_API_KEY}&logout_redirect_uri=${process.env.REACT_APP_KAKAO_LOGOUT_REDIRECT_URL}`}
      >
        카카오 로그아웃
      </a>
      <button className="btn btn-warning" onClick={onRevokeEmailAgreement}>
        카카오 이메일 제공 동의 철회
      </button>
    </>
  );
};

export default KakaoOAuth;
