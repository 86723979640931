//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import { ButtonLayout } from "../component/shared/ButtonLayout";
import { FatText } from "../component/shared/FatText";
import ProfilePhoto from "../component/shared/ProfilePhoto";

//게시물의 헤더, profilePhoto, username
const UserHeader = styled.div`
  /* width: 100%; */
  display: flex;
  //flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  /* gap: 10px; */
  /* margin: 5px; */
`;
const Username = styled.span``;
const EditCon = styled.div`
  margin-right: 10px;
`;
const EditRoutine = styled(ButtonLayout)``;

const PostHeader = ({ user, isMine, routineId }) => {
  return (
    <UserHeader>
      <UserInfo>
        <ProfilePhoto
          width="35px"
          height="35px"
          src={user?.profilePhoto}
          {...user}
          onError={(e) =>
            (e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`)
          }
        />
        {/* <Username>
          <FatText fontSize="18px">{user?.username}</FatText>
        </Username> */}
      </UserInfo>
    </UserHeader>
  );
};

export default PostHeader;
