//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GOOGLE_ACCESS_TOKEN_MUTATION } from "../../queries/oauth/googleAccessToken";
import { userLogIn, userTypeVar } from "../../utils/reactiveVar";

const LinkCon = styled.div`
  display: flex;
  align-items: center;
  background-color: #dd4b39;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
`;
const ALink = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  &:hover {
    color: white;
  }
  gap: 10px;
  font-weight: bold;
`;
const Icon = styled.div`
  font-size: 30px;
  color: white;
`;
const IconTxt = styled.div`
  white-space: nowrap;
  text-align: center;
  width: 60%;
`;

const GoogleLogin = () => {
  const history = useHistory();
  const onCompleted = async (data) => {
    const {
      googleAccessToken: { ok, error, token: googleToken, refreshtoken, type },
    } = data;
    if (!ok) {
      return alert("이미 등록된 email 입니다! 다른 방법으로 로그인 하세요.");
    }
    //await userTypeVar(type);
    await userLogIn(googleToken, type, refreshtoken);
    history.push("/");
  };
  const [googleAccessTokenMutation] = useMutation(
    GOOGLE_ACCESS_TOKEN_MUTATION,
    {
      onCompleted,
    }
  );
  useEffect(() => {
    const onLoginSuccess = (googleUser) => {
      const profile = googleUser.getBasicProfile();

      const keys = Object.keys(profile);
      const email_key = keys?.filter((key) => {
        const isEmail = profile[key]
          ? profile[key].match(/[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-z]+/)
          : null;
        if (isEmail) {
          return key;
        }
      });

      googleAccessTokenMutation({
        variables: {
          email: profile[email_key], // 주기적으로 키 값이 변경됩니다.
          name: `user_${new Date().getTime()}`,
        },
      });
    };
    const onLoginFail = (e) => {};
    window.gapi.load("auth2", () => {
      window.gapi.auth2.init();
      let options = new window.gapi.auth2.SigninOptionsBuilder();
      options.setPrompt("select_account");
      options.setScope(
        "email profile openid https://www.googleapis.com/auth/userinfo.email"
      );
      window.gapi.auth2
        .getAuthInstance()
        .attachClickHandler(
          "GgCustomLogin",
          options,
          onLoginSuccess,
          onLoginFail
        );
    });
  }, []);
  return (
    <LinkCon>
      <ALink href="#" id="GgCustomLogin">
        <div>
          <Icon className="bx bxl-google"></Icon>
        </div>
        <IconTxt>구글</IconTxt>
      </ALink>
    </LinkCon>
  );
};

export default GoogleLogin;
