import { gql } from "@apollo/client";

export const BLOCK_USER_MUTATION = gql`
  mutation BlockUser($userId: Int!) {
    blockUser(userId: $userId) {
      ok
      error
      id
    }
  }
`;
