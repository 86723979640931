//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql } from "@apollo/client";

export const UPDATE_RECEIPT = gql`
  mutation UpdateReceipt(
    $id: Int!
    $sellerId: Int!
    $buyerId: Int!
    $contractId: Int!
    $contractType: String!
    $type: String!
  ) {
    updateReceipt(
      id: $id
      sellerId: $sellerId
      buyerId: $buyerId
      contractId: $contractId
      contractType: $contractType
      type: $type
    ) {
      ok
      error
    }
  }
`;
