//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템 서버
//

import styled from "styled-components";
import colors from "../../utils/colors";

const NoImg = styled.div`
  width: 80%;
  height: 200px;
  padding: 30px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #b2bec3;
  gap: 10px;
  border: 2px dashed #b2bec3;
  margin-bottom: 20px;
`;
const NoThumbnail = () => {
  return (
    <NoImg>
      <i className="ri-camera-off-line" />
      <p>이미지가 존재하지 않습니다</p>
    </NoImg>
  );
};
export default NoThumbnail;
