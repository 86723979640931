//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@toast-ui/react-grid";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { ADMIN_EVNET_BANNER_DELETE } from "../../queries/admins/eventBanner/adminEventBannerDelete";
import {
  EventBanner,
  EVENT_BANNER,
} from "../../queries/admins/eventBanner/adminEventBannerSelect";
import { ADMIN_EVNET_BANNER_UPDATE } from "../../queries/admins/eventBanner/adminEventBannerUpdate";
import { CREATE_EVENT_BANNER } from "../../queries/admins/eventBanner/createEventBanner";
import { Input } from "../shared/Auth";
import { SButton } from "../shared/SButton";

const Container = styled.div`
  width: 99%;
  background-color: white;
  padding: 10px;
  margin: 15px auto;
  box-shadow: 0 2px 5px #ccc;
  border-radius: 5px;
  .vBtn {
    border: none;
    background-color: white;
    margin: 5px;
    text-align: center;
    width: 100%;
    color: gray;
  }
  .vBtn:hover {
    background-color: orange;
    color: white;
    font-size: 25px;
    border-radius: 5px;
  }
`;
const FontCon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin: 0 auto;
`;
// const Wrap = styled.div`
//   border: 1px solid #ccc;
//   padding: 15px 0;
//   border-radius: 5px;
// `;
const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

const UserRow = styled.div`
  width: 100%;
  display: ${(props) => (props.display ? props.display : "Grid")};
  grid-template-columns: 1fr 5fr;
  text-align: center;
`;

const Change = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .closeBtn {
    background-color: white;
    color: lightgray;
    float: right;
    font-size: 20px;
  }
  .closeBtn:hover {
    color: orange;
    font-size: 25px;
  }
`;

const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  input {
    border: 1px solid #ccc;
    outline: none;
    width: 100%;
  }
`;
const Img = styled.img`
  width: 100%;
`;
const ColTitle = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  width: 100px;
  padding: 10px;
  font-weight: bold;
  background-color: orange;
  color: white;
  line-height: 40px;
`;

const ColValue = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const RowTitle = styled.span`
  width: 100%;
  display: flex;
  font-size: 20px;
  color: black;
  padding-top: 10px;
`;

const ImgCon = styled.div`
  position: relative;
`;
const Label = styled.label`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const EventBannerGrid = () => {
  const updateReset = useRef();
  const profileImage = useRef();
  const gridRef = useRef();
  const { register, getValues, handleSubmit, setValue } = useForm();
  const [selData, setSelData] = useState();
  const data = EventBanner(100);
  const [modal, setModal] = useState(false);
  const [aHidden, setHidden] = useState(false);

  const toggleMenu = () => {
    //aHidden = !aHidden;
    setHidden(!aHidden);
  };

  const [createEventBannerMutation] = useMutation(CREATE_EVENT_BANNER, {
    refetchQueries: [{ query: EVENT_BANNER, variables: { quantity: 100 } }],
    onCompleted: () => {
      setModal(false);
    },
  });
  const [updateEventBannerMutation] = useMutation(ADMIN_EVNET_BANNER_UPDATE, {
    refetchQueries: [{ query: EVENT_BANNER, variables: { quantity: 100 } }],
    onCompleted: () => {
      setModal(false);
    },
  });
  const [deleteEventBannerMutation] = useMutation(ADMIN_EVNET_BANNER_DELETE, {
    refetchQueries: [{ query: EVENT_BANNER, variables: { quantity: 100 } }],
    onCompleted: () => {
      setModal(false);
    },
  });
  useEffect(() => {
    gridRef?.current?.getInstance().resetData(
      data?.adminEventBannerSelect?.map((v) => ({
        ...v,
        createdAt: v.createdAt
          ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
          : v.createdAt,
        updatedAt: v.updatedAt
          ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
          : v.updatedAt,
      }))
    );
  }, [data]);

  useEffect(() => {
    if (!modal) {
      setSelData("");
      setValue("id", "");
      setValue("createdAt", "");
      setValue("deletedAt", "");
      setValue("updatedAt", "");
      setValue("filepath", "");
    }
  }, [modal]);

  const eventBannerColumns = [
    { name: "id", header: "아이디", align: "center", hidden: true },
    { name: "filepath", header: "파일 경로", align: "center" },
    { name: "createdAt", header: "생성 날짜", align: "center" },
    { name: "updatedAt", header: "업데이트 날짜", align: "center" },
    { name: "deletedAt", header: "지운 날짜", align: "center", hidden: true },
  ];

  const onGridClick = (e) => {
    const rowData = gridRef.current.getInstance().getRow(e?.rowKey);
    window.a = gridRef.current;
    if (!!rowData) {
      setSelData(rowData);
      setValue("id", rowData.id);
      // setValue("filepath", rowData.filepath);
      setModal(true);
    }
  };

  const onEventBannerCreateClick = () => {
    setSelData("");
    setValue("id", "");
    setValue("createdAt", "");
    setValue("deletedAt", "");
    setValue("updatedAt", "");
    setValue("filepath", "");
    setModal(true);
  };

  const onEventBannerDeleteClick = () => {
    const { id } = getValues();
    if (!!id) {
      deleteEventBannerMutation({
        variables: {
          id,
        },
      });
    }
  };

  const onEventBannerSaveClick = (data) => {
    const { id, filepath, createdAt, deletedAt, updatedAt } = data;
    if (!id) {
      createEventBannerMutation({
        variables: {
          filepath: filepath[0] && filepath[0],
        },
      });
    } else {
      updateEventBannerMutation({
        variables: {
          id: Number(id),
          file: filepath[0] && filepath[0],
        },
      });
    }
  };
  const onFileChange = (e) => {
    //clearErrors("fileError");
    //a.png => [a, png]
    const ext = e?.target?.files?.[0]?.name?.split(".").pop().toLowerCase();
    const extType = ["png", "jpg"];
    if (!!ext && extType.includes(ext)) {
      const file = e?.target?.files?.[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        //const profileImg = document.getElementById("profileImg");
        profileImage?.current?.setAttribute("src", event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("파일에 문제가 있습니다. 다시 시도해주세요.");
      // setError("fileError", {
      //   message: "파일에 문제가 있습니다. 다시 시도해주세요.",
      // });
      e.target.value = "";
    }
  };

  return (
    <Container>
      <button className="vBtn" onClick={() => toggleMenu()}>
        {aHidden ? (
          <FontCon>
            EventBanner Close
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </FontCon>
        ) : (
          <FontCon>
            EventBanner Open
            <FontAwesomeIcon size="2x" icon={faAngleRight} />
          </FontCon>
        )}
      </button>
      {aHidden ? (
        // <Wrap>
        <UserContainer>
          <div style={{ textAlign: "right" }}>
            <SButton
              display="inline-block"
              width="fit-content"
              onClick={onEventBannerCreateClick}
              context="+ 새 이벤트 배너 생성"
              fontSize="12px"
              color="black"
              border="1px solid lightgray"
            />
          </div>
          <Row margin="10px 0">
            {data?.adminEventBannerSelect?.length && (
              <Grid
                ref={gridRef}
                data={data?.adminEventBannerSelect?.map((v) => {
                  return {
                    ...v,
                    createdAt: v.createdAt
                      ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
                      : v.createdAt,
                    updatedAt: v.updatedAt
                      ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
                      : v.updatedAt,
                  };
                })}
                width="auto"
                bodyHeight={300}
                onClick={onGridClick}
                columns={eventBannerColumns}
                rowHeaders={["rowNum"]}
                pageOptions={{
                  perPage: 0,
                  useClient: true,
                  type: "scroll",
                }}
              />
            )}
          </Row>
        </UserContainer>
      ) : // </Wrap>
      null}
      {modal === true ? (
        <Modal size="xl" show={modal} onHide={() => setModal(false)}>
          <legend>
            <Change>
              <div style={{ width: "100%", display: "flex" }}>
                <RowTitle>이벤트 배너 생성</RowTitle>
                <button className="closeBtn" onClick={() => setModal(false)}>
                  x
                </button>
              </div>
              <BasicInfo>
                {selData?.id ? null : (
                  <UserRow>
                    <ColTitle>파일 경로</ColTitle>
                    <ColValue>
                      <Input
                        {...register("filepath", {
                          required: {
                            value: true,
                            message: "file required.",
                          },
                        })}
                        type="file"
                        autoFocus
                        onChange={onFileChange}
                      ></Input>
                      {/* {formState?.errors?.filepath?.message} */}
                    </ColValue>
                  </UserRow>
                )}
                {selData?.id ? (
                  <UserRow hidden={true}>
                    <ColTitle>아이디</ColTitle>
                    <ColValue>
                      <Input
                        {...register("id", {
                          required: {
                            value: true,
                            message: "username required.",
                          },
                        })}
                        type="text"
                      ></Input>
                    </ColValue>
                  </UserRow>
                ) : null}
                {selData?.id ? (
                  <ImgCon>
                    <Label>
                      <Input
                        ref={updateReset}
                        {...register("filepath")}
                        type="file"
                        style={{ opacity: 0 }}
                        onChange={onFileChange}
                      />
                    </Label>
                    <Img ref={profileImage} src={selData?.filepath} />
                  </ImgCon>
                ) : null}
              </BasicInfo>
            </Change>
            {selData?.id ? null : (
              <div style={{ textAlign: "right", margin: "0 10px 10px 0" }}>
                <SButton
                  onClick={handleSubmit(onEventBannerSaveClick)}
                  width="fit-content"
                  context="생성"
                  bgColor="orange"
                  color="white"
                  fontWeight="bold"
                  border="none"
                />
              </div>
            )}
            <div style={{ display: "flex", margin: "0 auto" }}>
              {selData?.id ? (
                <div
                  style={{
                    margin: "0 10px 10px 0",
                  }}
                >
                  <SButton
                    onClick={handleSubmit(onEventBannerSaveClick)}
                    width="fit-content"
                    context="수정"
                    bgColor="orange"
                    color="white"
                    fontWeight="bold"
                    border="none"
                  />
                </div>
              ) : null}
              {selData?.id ? (
                <div
                  style={{
                    margin: "0 10px 10px 0",
                  }}
                >
                  <SButton
                    onClick={onEventBannerDeleteClick}
                    width="fit-content"
                    context="삭제"
                    bgColor="#FFA500"
                    color="white"
                    fontWeight="bold"
                    border="none"
                  />
                </div>
              ) : null}
            </div>
          </legend>
        </Modal>
      ) : null}
    </Container>
  );
};
export default EventBannerGrid;
