//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
//input file 의 style 을 바꾸기위해 사용하는 label 입니다.
//input file 을 이 컴포넌트의 자식으로 사용하세요.

import styled from "styled-components";

const SLabel = styled.label`
  width: ${(props) => (props.width ? props.width : "")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "15px")};
  padding: ${(props) => (props.padding ? props.padding : "0.475rem 0.85rem")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  border-radius: ${(props) => (props.radius ? props.radius : "5px")};
  cursor: pointer;
  ${(props) =>
    props.variant === "create"
      ? `
    color:white;
    background-color:#0d6efd;
    border-color:#0d6efd;
  `
      : ""}
  ${(props) =>
    props.variant === "update"
      ? `
      color:white;
    background-color:#6c757d;
    border-color:#6c757d;
  `
      : ""}
`;

export const FileLabel = (props) => {
  return (
    <SLabel {...props}>
      {props.context}
      {props.children}
    </SLabel>
  );
};
