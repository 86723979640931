//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useMutation } from "@apollo/client";
import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import Bodyshape from "../component/scan/Bodyshape";
import { GButton } from "../component/shared/GButton";
import { UPLOAD_FILE_TO_BODYSHAPE_MUTATION } from "../queries/bodyshape/uploadFileToBodyshape";
import { SEE_MYGYM } from "../queries/user/seeMyGym";
import { CREATE_PROGRESS_MUTATION } from "../queries/user/updateProgress";
import CheckUserToken from "../utils/checkUserToken";
import { Modal } from "react-bootstrap";

const ScanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  min-height: 500px;
  border-radius: 10px;
  gap: 20px;
  margin: 0 10px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.5px solid lightgray;
  box-shadow: 0 0 15px -5px gray;
  padding: 20px;
  border-radius: 5px;
`;
const Label = styled.label`
  border: 0.5px solid lightgray;
  padding: 10px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;
const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`;
const Caution = styled.span`
  color: red;
`;
const SFile = styled.input`
  opacity: 0;
  position: absolute;
  left: -9999px;
`;
const ScanOutput = styled.div`
  background-color: #222;
  width: 100%;
  height: 500px;
`;
const OutputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
`;
const Actions = styled.div`
  max-width: 240px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  z-index: 102;
`;
const WeightFat = styled.div`
  margin-top: 20px;
  display: flex;
  text-align: left;
`;
const Weight = styled.div`
  display: flex;
  align-items: center;
`;
const FatPercentage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;
const Name = styled.span`
  min-width: 80px;
  margin-right: 10px;
  text-align: end;
`;
const Value = styled.input`
  text-align: end;
  margin-right: 10px;
  width: 90px;
`;
const PreView = styled.img`
  box-shadow: 0 0 10px -5px gray;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 650px;
  min-height: 400px;
  max-height: 650px;
`;
function Scanning() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userData = CheckUserToken();

  const [objFilePath, setObjFilePath] = useState(null);
  const onScanCompleted = (result) => {
    const { uploadFileToBodyshape } = result;
    if (!!uploadFileToBodyshape) {
      setObjFilePath(uploadFileToBodyshape);
    }
  };
  const [loading, setLoading] = useState(false);
  const [weight, setWeight] = useState();
  const [modal, setModal] = useState(false);
  const [fatPercentage, setFatPercentage] = useState();
  const uploadFile = useRef();
  const [file, setFile] = useState();

  const onChange = () => {
    setObjFilePath(null);
    setFile(document.querySelector("input[type=file]").files[0]);
    let preview = document.querySelector("#preview");
    let file = document.querySelector("input[type=file]").files[0];
    let reader = new FileReader();

    reader?.addEventListener(
      "load",
      () => {
        preview.src = reader.result;
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const onError = (e) => {
    e.target.src = `${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`;
  };
  const [uploadFileToBodyshapeMutation] = useMutation(
    UPLOAD_FILE_TO_BODYSHAPE_MUTATION,
    {
      onCompleted: onScanCompleted,
    }
  );
  const [updateProgressMutation] = useMutation(CREATE_PROGRESS_MUTATION, {
    refetchQueries: [{ query: SEE_MYGYM }],
  });
  const startScan = () => {
    uploadFileToBodyshapeMutation({
      variables: {
        file: uploadFile?.current?.files[0],
      },
    });
    setLoading(true);
  };
  const saveAvatar = () => {
    if (!!userData.me.id && !!objFilePath) {
      updateProgressMutation({
        variables: {
          avatar: objFilePath,
          weight: Number(weight),
          fatPercentage: Number(fatPercentage),
        },
      });
      alert("저장완료. 내 프로필에서 확인 할 수 있습니다.");
      setModal(false);
      //window.location.reload();
    } else {
      alert("렌더링 된 모델이 존재하지 않습니다!");
    }
  };
  const onValChange = (event) => {
    let {
      target: { value, id },
    } = event;
    if (id === "weight") {
      setWeight(value);
    } else if (id === "fatPercentage") {
      setFatPercentage(value);
    }
  };
  return (
    <ScanContainer id="main">
      <Section>
        <TitleContainer>
          <Title>3d 스캔된 BodyShape 를 확인해보세요!</Title>
          <Caution>*전신사진을 권장합니다</Caution>
          <Caution>*장애물이 없는 배경을 권장합니다</Caution>
          <Caution>
            *사진을 찍었을 당시의 체중과 체지방률을 입력해주세요
          </Caution>
        </TitleContainer>
      </Section>
      <Section>
        <PreView id="preview" alt="Crop" src="" onError={onError} />

        <OutputContainer>
          <Actions>
            {!objFilePath ? (
              !loading ? (
                <>
                  <Label>
                    사진 올리기
                    <SFile type="file" ref={uploadFile} onChange={onChange} />
                  </Label>

                  <GButton
                    onClick={startScan}
                    variant="update"
                    context="3D 스캔"
                    boxShadow="none"
                    fontSize="16px"
                    fontWeight="400"
                    radius="0.25rem"
                    disabled={file ? false : true}
                  />
                </>
              ) : (
                <button className="btn btn-secondary" type="button" disabled="">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )
            ) : null}
            {!!objFilePath ? (
              <GButton
                onClick={() => setModal(true)}
                variant="create"
                context="보기"
                padding="8px 16px"
                boxShadow="none"
                fontSize="16px"
                fontWeight="400"
                radius="0.25rem"
              />
            ) : null}
          </Actions>
        </OutputContainer>
      </Section>
      <Modal centered="true" show={modal} onHide={() => setModal(false)}>
        <Modal.Body>
          <ScanOutput>
            {!!objFilePath ? <Bodyshape scanfilepath={objFilePath} /> : null}
          </ScanOutput>
          <WeightFat>
            <Weight>
              <Name>체중 : </Name>
              <Value
                type="number"
                id="weight"
                className="form-control"
                onChange={onValChange}
              />
              (KG)
            </Weight>
            <FatPercentage>
              <Name>체지방률 : </Name>
              <Value
                type="number"
                id="fatPercentage"
                className="form-control"
                onChange={onValChange}
              />
              %
            </FatPercentage>
          </WeightFat>
          <div
            style={{ paddingTop: "16px", width: "100%", textAlign: "center" }}
          >
            <GButton
              onClick={saveAvatar}
              context="내 3D 아바타 저장"
              zIndex="1050"
              variant="create"
              fontSize="16px"
              width="100%"
              fontWeight="400"
              padding="8px 16px"
              radius="0.25rem"
            />
          </div>
        </Modal.Body>
      </Modal>
    </ScanContainer>
  );
}

export default Scanning;
