import { isMobile } from "react-device-detect";
import { dateConvertToCurrentTime } from "../../../utils/date/dateConvertToCurrentTime";
import {
  DateP,
  DownladFile,
  FileName,
  LinkMsg,
  MsgCon,
  MsgContent,
  MsgFileContent,
  MsgTypeContainer,
} from "../../../utils/msgStyles";

const TypeFile = ({ isMine, createdAt, file, msg, msgObj, onFileClick }) => {
  return (
    <MsgTypeContainer isMine={isMine}>
      {isMine ? (
        <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
      ) : null}
      <MsgFileContent isMine={!isMine} style={{ padding: "10px" }}>
        <LinkMsg
          hColor="#0d6efd"
          decoration="unset"
          href={msg}
          onClick={() => {
            // onZipClick(z, id);
            onFileClick(msg, msgObj);
          }}
          style={{ cursor: "pointer" }}
          // onClick={() =>}
        >
          {/* <DownloadCnt>
                  이미지 파일 수 : {zipFileCnt} | 다운로드 수 : {downloadCnt}
                </DownloadCnt> */}
          <DownladFile color={file?.color} className={file?.icon} />
          <FileName color={file?.color}> {msg.split("/static/")[1]} </FileName>
        </LinkMsg>
      </MsgFileContent>
      {!isMine ? (
        <DateP>{dateConvertToCurrentTime(Number(createdAt))}</DateP>
      ) : null}
    </MsgTypeContainer>
  );
};
export default TypeFile;
