//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { useState } from "react";
import { Button as BButton } from "react-bootstrap";
import styled from "styled-components";
import ImageLayout from "../component/shared/ImageLayout";
import ModalLayout from "../component/shared/ModalLayout";
import bbeegoSticker from "../static/bbeegoSticker.png";

const Container = styled.div`
  min-height: 300px;
`;
const TabTitle = styled.div`
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
`;
const TabDescription = styled.div`
  padding: 10px;
  width: inherit;
  color: ${(props) => (props?.color ? props?.color : "")};
  font-size: ${(props) => (props?.fontSize ? props?.fontSize : "")};
`;
const TabContent = styled.div`
  padding: 10px;
`;
const ImgContainer = styled.div``;
const Interact = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
const OrderContainer = styled.div``;
const CertContainer = styled.div``;

const ApplySticker = () => {
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  return (
    <Container>
      <TabTitle>스티커 발급 신청</TabTitle>
      <TabDescription>
        스티커를 주문하고 인증해주세요! 인증 확인 후 1만 포인트를
        지급해드립니다!
      </TabDescription>
      <Interact>
        <BButton
          variant="primary"
          style={{
            backgroundColor: "orange",
            border: "1px solid orange",
            fontWeight: "bold",
          }}
          onClick={() => setModal1(true)}
        >
          주문하기
        </BButton>
        <BButton
          variant="info"
          style={{
            color: "white",
            backgroundColor: "#0dcaf0",
            border: "1px solid #0dcaf0",
            fontWeight: "bold",
          }}
          onClick={() => setModal2(true)}
        >
          인증하기
        </BButton>
      </Interact>
      <TabDescription color="red" fontSize="14px">
        * 보이는 사진은 스티커 표본입니다.
      </TabDescription>
      <TabContent>
        <ImgContainer>
          <ImageLayout
            src={bbeegoSticker}
            border="1px solid gray"
            height="300px"
          />
        </ImgContainer>
      </TabContent>
      <ModalLayout modal={modal1} setModal={setModal1}>
        <ModalTitle>주문하기</ModalTitle>
        <OrderContainer></OrderContainer>
      </ModalLayout>
      <ModalLayout modal={modal2} setModal={setModal2}>
        <ModalTitle>인증하기</ModalTitle>
        <CertContainer></CertContainer>
      </ModalLayout>
    </Container>
  );
};

export default ApplySticker;
