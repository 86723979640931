//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import gql from "graphql-tag";

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $username: String
    $description: String
    $profilePhoto: Upload
    $birthyear: Int
    $birthmonth: Int
    $birthday: Int
    $type: String
    $trainerAuthUnlockAt: String
    $realName: String
    $bank: String
    $accountNumber: String
    $gender: String
  ) {
    updateUser(
      id: $id
      username: $username
      description: $description
      profilePhoto: $profilePhoto
      birthyear: $birthyear
      birthmonth: $birthmonth
      birthday: $birthday
      type: $type
      trainerAuthUnlockAt: $trainerAuthUnlockAt
      realName: $realName
      bank: $bank
      accountNumber: $accountNumber
      gender: $gender
    ) {
      ok
      error
    }
  }
`;
