//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { IconSpan } from "../../routes/MyGym";
import { routes } from "../../routes/routes";
import ProfilePhoto from "../shared/ProfilePhoto";
import { SButton } from "../shared/SButton";
import { Thumbnail } from "../PTroom/PTRoom";
import PostHeader from "../../Accounts/PostHeader";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../utils/reactiveVar";

const ChallengeCon = styled.div`
  background-color: white;
  position: relative;
  width: 225px;
  margin: 20px auto;
  /* max-width: 225px; */
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25), 0 2px 3px rgba(0, 0, 0, 0.24);

  transition: all 0.2s linear;
  &:hover {
    transform: scale(1.08);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.45), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  .ribbon {
    width: 142px;
    height: 142px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: -10px;
  }
  .ribbon__content {
    left: -25px;
    top: 30px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: #f5cd79;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.192);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    border: 2px dotted #fff;
    outline: 5px solid #f5cd79;
  }
  .ribbon::before {
    top: 0;
    left: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #f19066;
    box-sizing: content-box;
  }
  .ribbon::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #f19066;
  }
`;
const Header = styled.div`
  width: 270px;
  padding: 5px;
  /* text-align: center;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  */
  align-items: center;
  /* border-top-right-radius: 10px;
  border-top-left-radius: 10px; */
  /* border: 1px solid lightgrey; */
  /* column-gap: 10px; */
`;
const Contents = styled.div`
  width: 100%;
  max-height: 300px;
  padding: 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  /* border: 1px solid lightgrey; */
  border-top: none;
  /* border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
`;
const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #222;
  /* margin: 2px; */
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2px;
`;
const BasePrice = styled.div`
  color: #ed5f00;
  font-size: 12px;
  font-weight: bold;
  span {
    color: #222;
  }
`;
const Bonus = styled(BasePrice)`
  color: green;
`;
const Reward = styled(BasePrice)`
  color: blue;
`;
const Prices = styled(BasePrice)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* border: 1px solid lightgrey; */
  border-radius: 5px;
  row-gap: 5px;
  /* margin-bottom: 7px; */
`;
const InteractDiv = styled.div`
  position: absolute;
  right: 7px;
  top: 7px;
  /* z-index: 99999999; */
  /* width: 50px; */
`;
const Badges = styled.span`
  min-width: 16px; /* pixel unit */
  border-radius: 50%;
  text-align: center;
  background: #1779ba;
  color: #fefefe;
  font-size: 16px;
  width: 22px;
  height: 22px;
  z-index: 9999999;
  /* box-shadow: rgb(30 22 54 / 40%) 0 0px 0px 2px inset; */
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : ""} !important;
  color: ${(props) => (props.color ? props.color : "white")} !important;
  top: ${(props) => (props.top ? props.top : "7%")};
  left: ${(props) => (props.right ? props.right : "3%")};
`;

const MultiChallengeCard = (props) => {
  const history = useHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const showInteract = (props) => {
    props?.setModal(true);
    props?.setModalData({ ...props });
  };
  return (
    <ChallengeCon className="card">
      <Thumbnail
        onClick={() => {
          isLoggedIn
            ? history.push(routes?.multiChallengeInfo, {
                id: props?.id,
              })
            : history.push("/login");
        }}
        bgImg={props?.thumbnail}
      >
        <div className="ribbon">
          <span className="ribbon__content">challenge</span>
        </div>
        <Header>
          {/* <InteractDiv>
            <IconSpan
              top="0"
              right="0"
              color="white"
              // hColor="#dfe4ea"
              fontSize="1.7em !important"
              onClick={() => showInteract(props)}
              className="Interact"
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconSpan>
          </InteractDiv> */}
        </Header>
      </Thumbnail>
      <Contents>
        <div style={{ position: "absolute", bottom: "12px", right: "15px" }}>
          {/* <PostHeader
            // font-size="25px"
            user={props?.user?.profilePhoto}
            isMine={props?.isMine}
          ></PostHeader> */}
          <ProfilePhoto src={props?.user?.profilePhoto} id={props?.user?.id} />
        </div>
        {/* <div
          style={{
            margin: "0 auto",
            padding: "4px",
          }}
          id="UserName"
        >
          {props?.user?.username}
        </div> */}
        {/* <Description>{props?.description}</Description> */}
        <Prices>
          <Title>{props?.title}</Title>
          <BasePrice>
            기본금 :<span> {props?.baseprice}만원</span>
          </BasePrice>
          <Bonus>
            성공보수 : <span>{props?.bonus}만원</span>
          </Bonus>
          <Reward>
            리워드 : <span>{props?.reward}만원</span>
          </Reward>
        </Prices>
        {/* 
        <SButton
          context="상세보기"
          bgColor="#fd780f"
          border="none"
          color="white"
          onClick={() => {
            history.push(routes?.multiChallengeInfo, {
              id: props?.id,
            });
          }}
        /> */}
      </Contents>
    </ChallengeCon>
  );
};

export default MultiChallengeCard;
