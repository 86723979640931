//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import styled from "styled-components";
import Grid from "@toast-ui/react-grid";
import { useRef } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useForm } from "react-hook-form";
import { SButton } from "../shared/SButton";
import { useEffect } from "react";
import { Input, Select } from "../shared/Auth";
import { pTPriceListVar } from "../../utils/reactiveVar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import {
  ADMIN_SELECT_PTPRICE,
  SelectPTPrice,
} from "../../queries/admins/ptprice/adminSelectPTPrice";
import { ADMIN_CREATE_PTPRICE } from "../../queries/admins/ptprice/adminCreatePTPrice";
import { ADMIN_UPDATE_PTPRICE } from "../../queries/admins/ptprice/adminUpdatePTPrice";
import { ADMIN_DELETE_PTPRICE } from "../../queries/admins/ptprice/adminDeletePTPrice";

const Container = styled.div`
  width: 99%;
  background-color: white;
  padding: 10px;
  margin: 15px auto;
  box-shadow: 0 2px 5px #ccc;
  border-radius: 5px;
  .vBtn {
    border: none;
    background-color: white;
    margin: 5px;
    text-align: center;
    width: 100%;
    color: gray;
  }
  .vBtn:hover {
    background-color: orange;
    color: white;
    font-size: 25px;
    border-radius: 5px;
  }
`;
const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  padding: ${(props) => (props.padding ? props.padding : "")};
`;

const UserRow = styled.div`
  width: 100%;
  display: ${(props) => (props.display ? props.display : "Grid")};
  grid-template-columns: 1fr 5fr;
  text-align: center;
`;

const Change = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .closeBtn {
    background-color: white;
    color: lightgray;
    float: right;
    border: none;
    font-size: 20px;
  }
  .closeBtn:hover {
    color: orange;
    font-size: 25px;
  }
`;

const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  input {
    border: 1px solid #ccc;
    outline: none;
    width: 100%;
  }
`;

const ColTitle = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  width: 100px;
  padding: 10px;
  font-weight: bold;
  background-color: orange;
  color: white;
  line-height: 40px;
`;

const ColValue = styled.div`
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const RowTitle = styled.span`
  width: 100%;
  display: flex;
  font-size: 20px;
  color: black;
  padding-top: 10px;
`;
const FontCon = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PTPriceGrid = () => {
  const pTPriceList = useReactiveVar(pTPriceListVar);
  const { register, getValues, handleSubmit, setValue } = useForm();
  const [selData, setSelData] = useState();
  const [modal, setModal] = useState(false);
  const gridRef = useRef();
  let data = SelectPTPrice(100);

  const [aHidden, setHidden] = useState(false);
  const toggleMenu = () => {
    //aHidden = !aHidden;
    setHidden(!aHidden);
  };

  const [createPTPriceMutation] = useMutation(ADMIN_CREATE_PTPRICE, {
    refetchQueries: [
      { query: ADMIN_SELECT_PTPRICE, variables: { quantity: 100 } },
    ],
    onCompleted: () => {
      setModal(false);
    },
  });

  const [updatePTPriceMutation] = useMutation(ADMIN_UPDATE_PTPRICE, {
    refetchQueries: [
      { query: ADMIN_SELECT_PTPRICE, variables: { quantity: 100 } },
    ],
    onCompleted: () => {
      setModal(false);
    },
  });

  const [deletePTPriceMutation] = useMutation(ADMIN_DELETE_PTPRICE, {
    refetchQueries: [
      { query: ADMIN_SELECT_PTPRICE, variables: { quantity: 100 } },
    ],
    onCompleted: () => {
      setModal(false);
    },
  });
  const onGridClick = (e) => {
    const rowData = gridRef.current.getInstance().getRow(e?.rowKey);
    window.a = gridRef.current;
    if (!!rowData) {
      setSelData(rowData);
      setValue("id", rowData.id);
      setValue("price", rowData.price);
      setValue("pTRoomTitle", rowData.pTRoomTitle);
      setValue("pTRoomId", rowData.pTRoomId);
      setValue("month", rowData.month);
      setValue("chatRoomId", rowData.chatRoomId);
      setModal(true);
    }
  };
  const onPTPriceCreateClick = () => {
    setSelData("");
    setValue("price", "");
    setValue("month", "");
    setValue("pTRoomId", "");
    setValue("pTRoomTitle", "");
    setModal(true);
  };

  const onSaveClick = (data) => {
    const { id, price, pTRoomId, month } = data;
    if (!id) {
      createPTPriceMutation({
        variables: {
          price: Number(price),
          pTRoomId: Number(pTRoomId),
          month: Number(month),
        },
      });
    } else {
      updatePTPriceMutation({
        variables: {
          id: Number(id),
          price: Number(price),
          month: Number(month),
        },
      });
    }
  };
  const onDeleteClick = () => {
    const { id } = getValues();

    if (!!id) {
      deletePTPriceMutation({
        variables: {
          id,
        },
      });
    }
  };
  const pTPriceColumns = [
    { name: "id", header: "아이디", align: "center", hidden: true },
    { name: "price", header: "가격", align: "center" },
    { name: "pTRoomId", header: "PT ID", align: "center", hidden: true },
    { name: "pTRoomTitle", header: "PT ROOM", align: "center" },
    { name: "month", header: "기간", align: "center" },
    { name: "chatRoomId", header: "CHAT ROOM ID ", align: "center" },
    { name: "createdAt", header: "생성 날짜", align: "center" },
    { name: "updatedAt", header: "업데이트 날짜", align: "center" },
    { name: "deletedAt", header: "삭제 날짜", align: "center", hidden: true },
  ];
  const selectPTR = () => {
    return pTPriceList.map((v) => {
      return (
        <option key={v.id} value={v.id}>
          {v.title}
        </option>
      );
    });
  };
  useEffect(() => {
    gridRef?.current?.getInstance().resetData(
      data?.adminSelectPTPrice?.map((v) => {
        return {
          ...v,
          pTRoomTitle: v.pTRoom?.title,
          pTRoomId: v.pTRoom?.id,
          chatRoomId: v.chatRoom?.id,
          createdAt: v.createdAt
            ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
            : v.createdAt,
          updatedAt: v.updatedAt
            ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
            : v.updatedAt,
        };
      })
    );
  });
  return (
    <Container>
      <button className="vBtn" onClick={() => toggleMenu()}>
        {aHidden ? (
          <FontCon>
            PTPrice Close
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </FontCon>
        ) : (
          <FontCon>
            PTPrice Open
            <FontAwesomeIcon size="2x" icon={faAngleRight} />
          </FontCon>
        )}
      </button>
      {aHidden ? (
        <UserContainer>
          <div style={{ textAlign: "right" }}>
            <SButton
              display="inline-block"
              onClick={onPTPriceCreateClick}
              width="fit-content"
              context="+ 새 유저 생성"
              fontSize="12px"
              color="black"
              border="1px solid lightgray"
            />
          </div>
          <Row margin="10px 0">
            {data?.adminSelectPTPrice.length && (
              <Grid
                ref={gridRef}
                data={data?.adminSelectPTPrice?.map((v) => {
                  return {
                    ...v,
                    pTRoomTitle: v.pTRoom?.title,
                    pTRoomId: v.pTRoom?.id,
                    chatRoomId: v.chatRoom?.id,
                    createdAt: v.createdAt
                      ? new Date(Number(v.createdAt)).toJSON().split("T")[0]
                      : v.createdAt,
                    updatedAt: v.updatedAt
                      ? new Date(Number(v.updatedAt)).toJSON().split("T")[0]
                      : v.updatedAt,
                  };
                })}
                width="auto"
                bodyHeight={300}
                onClick={onGridClick}
                columns={pTPriceColumns}
                rowHeaders={["rowNum"]}
                pageOptions={{
                  perPage: 0,
                  useClient: true,
                  type: "scroll",
                }}
              />
            )}
          </Row>
        </UserContainer>
      ) : null}
      {modal === true ? (
        <Modal size="md" show={modal} onHide={() => setModal(false)}>
          <Change>
            <div style={{ width: "100%", display: "flex" }}>
              <RowTitle>PT 가격</RowTitle>
              <button className="closeBtn" onClick={() => setModal(false)}>
                x
              </button>
            </div>

            <BasicInfo>
              <UserRow hidden={true}>
                <ColTitle>아이디</ColTitle>
                <ColValue>
                  <Input {...register("id")} type="text"></Input>
                </ColValue>
              </UserRow>
              <UserRow>
                <ColTitle>가격</ColTitle>
                <ColValue>
                  <Input
                    {...register("price", {
                      required: { value: true, message: "price required." },
                    })}
                    type="text"
                  ></Input>
                </ColValue>
              </UserRow>
              {selData?.id ? null : (
                <UserRow>
                  <ColTitle>PT ROOM</ColTitle>
                  <ColValue>
                    <Select
                      {...register("pTRoomId", {
                        required: {
                          value: false,
                          message: "pTRoomTitle required.",
                        },
                      })}
                      type="text"
                    >
                      {selectPTR()}
                    </Select>
                  </ColValue>
                </UserRow>
              )}
              <UserRow>
                <ColTitle>기간</ColTitle>
                <ColValue>
                  <Input
                    {...register("month", {
                      required: { value: true, message: "month required." },
                    })}
                    type="text"
                  ></Input>
                </ColValue>
              </UserRow>
            </BasicInfo>
          </Change>
          {selData?.id ? null : (
            <div style={{ textAlign: "right", margin: "0 10px 10px 0" }}>
              <SButton
                onClick={handleSubmit(onSaveClick)}
                width="fit-content"
                context="생성"
                bgColor="orange"
                color="white"
                fontWeight="bold"
                border="none"
              />
            </div>
          )}
          <div style={{ display: "flex", margin: "0 auto" }}>
            {selData?.id ? (
              <div
                style={{
                  margin: "0 10px 10px 0",
                }}
              >
                <SButton
                  onClick={handleSubmit(onSaveClick)}
                  width="fit-content"
                  context="수정"
                  bgColor="orange"
                  color="white"
                  fontWeight="bold"
                  border="none"
                />
              </div>
            ) : null}
            {selData?.id ? (
              <div
                style={{
                  margin: "0 10px 10px 0",
                }}
              >
                <SButton
                  onClick={onDeleteClick}
                  width="fit-content"
                  context="삭제"
                  bgColor="orange"
                  color="white"
                  fontWeight="bold"
                  border="none"
                />
              </div>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </Container>
  );
};

export default PTPriceGrid;
