import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import SeeUsers from "../../queries/user/seeUsers";

const Container = styled.div`
  width: 100%;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  gap: 10px;
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 20px;
`;
const GridCon = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    gap: 5px;
  }
`;
function UsersInfo() {
  const users = SeeUsers(100);
  const [cpUsers, setCpUsers] = useState(null);

  const cloneObj = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  useEffect(() => {
    //users deep copy and add bithdate
    if (users) {
      const cp = cloneObj(users?.seeUsers);

      if (cp?.length)
        cp?.map((u, idx) => {
          u["birthDate"] =
            String(u?.birthyear) +
            "-" +
            String(u?.birthmonth)?.padStart(2, "0") +
            "-" +
            String(u?.birthday).padStart(2, "0");
        });

      setCpUsers(cp);
    }
  }, [users]);

  const columns = [
    { field: "id", headerName: "No.", width: 90, disableColumnMenu: true },
    {
      field: "username",
      headerName: "이름",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "realName",
      headerName: "본명",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "gender",
      headerName: "성별",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "이메일",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "bank",
      headerName: "은행",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "accountNumber",
      headerName: "계좌번호",
      minWidth: 160,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "type",
      headerName: "타입",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "birthDate",
      headerName: "생년월일",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "socialLoginType",
      headerName: "로그인 타입",
      minWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "생성 날짜",
      minWidth: 140,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: (cellValue) => {
        return cellValue?.value
          ? new Date(Number(cellValue?.value)).toJSON()?.split("T")[0]
          : null;
      },
    },
    {
      field: "updatedAt",
      headerName: "수정 날짜",
      minWidth: 140,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      valueFormatter: (cellValue) => {
        return cellValue?.value
          ? new Date(Number(cellValue?.value)).toJSON()?.split("T")[0]
          : null;
      },
    },
    {
      field: "blockList",
      headerName: "차단 목록",
      minWidth: 140,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "trainerAuthUnlockAt",
      headerName: "lock",
      minWidth: 100,
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  return (
    <Container>
      <Title>유저 목록</Title>
      <GridCon>
        <DataGrid
          rows={cpUsers ? cpUsers : []}
          columns={columns}
          style={{ minHeight: "500px" }}
        />
      </GridCon>
    </Container>
  );
}

export default UsersInfo;
