//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import colors from "../../utils/colors";
import gif from "../../static/spinner.gif";

const Picture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgb(230, 229, 239);
  /* gap: 2px; */
  font-size: 50px;
  color: #b2bec3;
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
  height: ${(props) => (props.length ? props.length : "")};
  /* border: 2px dashed rgb(99, 110, 114); */
  background-color: white;
  &:hover {
    color: ${colors.bbeegoText};
    border: 2px solid ${colors.bbeegoText};
  }
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #b2bec3;
  height: ${(props) => (props.height ? props.height : "400px")};
  div {
    div {
      z-index: 50;
      margin-top: 30px;
      width: 100px;
      height: 100px;
    }
  }
`;

const LoadingText = styled.div`
  /* margin: 20px auto; */
  color: #b2bec3;
  font-size: 23px;
  font-weight: 600;
  text-align: center;
`;

const IsThumbDropZone = ({ getData, getThumbnail, zFile, loading }) => {
  const onDrop = useCallback((acceptedFiles) => {
    getThumbnail(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="here" style={{ width: "100%" }} {...getRootProps()}>
      {loading ? (
        <>
          <Loading height="200px">
            <img src={gif} alt="로딩중" width="60%" />

            <LoadingText>Loading...</LoadingText>
          </Loading>
        </>
      ) : (
        <Picture length="200px" className="bi bi-file-earmark-text">
          <input
            type="file"
            accept="*"
            style={{
              display: "none",
            }}
            {...getInputProps()}
          />
          <p
            style={{
              padding: "3px",
              fontSize: "18px",
              marginTop: "10px",
              textAlign: "center",
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {!zFile
              ? !isDragActive
                ? "파일 선택 or 드래그"
                : "파일 드랍"
              : zFile.name}
            {/* 
          {isDragActive ? "Drop the files here ..." : "Drag 'n' drop some "} */}
          </p>
        </Picture>
      )}
    </div>
  );
};

export default IsThumbDropZone;
