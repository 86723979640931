//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import UseFormError from "../Accounts/UseFormError";
import { ColName, Row } from "./CreatePost";
import { UPDATE_TRAIN_MUTATION } from "../queries/train/updateTrain";

const CREATE_TRAIN = gql`
  mutation CreateTrain(
    $title: String!
    $description: String
    $filepath: Upload
  ) {
    createTrain(title: $title, description: $description, filepath: $filepath) {
      ok
      error
    }
  }
`;

const Container = styled.div`
  width: 1000px;
  min-height: 500px;
  height: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  .FormName {
    font-weight: bold;
    text-align: center;
  }
  .FormValue {
    display: flex;
    padding: 15px;
    Train {
      max-height: 600px;
    }
  }
  .FormValue > input,
  textarea {
    border: 0.5px solid black;
    border-radius: 3px;
    width: 800px;
  }
  .Train_Desc {
    height: 250px;
  }
  .ConfirmBtn {
    grid-column: 1/3;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
    .Create {
      margin-right: 5px;
    }
    .Cancle {
      margin-left: 5px;
    }
  }
`;
const File = styled.input`
  opacity: 0;
  position: absolute;
  left: -9999px;
`;
const FileImg = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 10px;
`;
const Label = styled.label`
  cursor: pointer;
  img {
    border: 0.1px solid white;
    border-radius: 5px;
  }
  video {
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;

function CreateTrain() {
  const location = useLocation();
  const trainId = location?.state?.id;
  const { register, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
  });
  const ImageFile = useRef();
  const VideoFile = useRef();
  const extImg = ["png", "jpg"];
  const extVid = ["mp4", "avi"];
  const [media, setMedia] = useState(true);
  const Video = document.getElementById("videofile");
  if (!!trainId) {
    setValue("title", location?.state?.title);
    setValue("description", location?.state?.description);
  }
  useEffect(() => {
    if (
      extVid.includes(location?.state?.filepath.split(".").pop().toLowerCase())
    ) {
      setMedia(false);
    } else if (
      extImg.includes(location?.state?.filepath.split(".").pop().toLowerCase())
    ) {
      setMedia(true);
    }
  }, []);
  const onCompleted = (data) => {
    const {
      createTrain: { ok },
    } = data;
    if (ok) {
      window.location.replace("/mygym");
    }
  };
  const updateOnCompleted = (data) => {
    window.location.replace("/mygym");
  };
  const [createTrain] = useMutation(CREATE_TRAIN, { onCompleted });
  const [updateTrain] = useMutation(UPDATE_TRAIN_MUTATION, {
    onCompleted: updateOnCompleted,
  });
  const onSubmitValid = (data) => {
    const { title, description, file } = data;
    if (!!trainId) {
      let val = window.confirm("트레이닝을 수정합니다.");
      if (val === true) {
        updateTrain({
          variables: {
            id: trainId,
            title,
            description,
            filepath: file[0],
          },
        });
      }
    } else {
      let val = window.confirm("트레이닝을 생성합니다.");
      if (val === true) {
        createTrain({
          variables: {
            title,
            description,
            filepath: file[0],
          },
        });
      }
    }
  };
  const onFileChange = (e) => {
    const file = e?.target?.files?.[0];
    const ext = file?.name?.split(".").pop().toLowerCase();
    const reader = new FileReader();
    if (!!ext && extImg.includes(ext)) {
      setMedia(true);
      reader.onload = (event) => {
        Video.setAttribute("style", "display:none");
        ImageFile?.current?.setAttribute("style", "display:flex");
        ImageFile?.current?.setAttribute("src", event?.target?.result);
      };
      reader.readAsDataURL(file);
    } else if (!!ext && extVid.includes(ext)) {
      setMedia(false);
      reader.onload = (event) => {
        ImageFile?.current?.setAttribute("style", "display:none");
        Video?.setAttribute("style", "display:flex;width:200px;height:200px;");
        Video?.childNodes[0].setAttribute("src", event?.target?.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("파일에 문제가 있습니다. 다시 시도해주세요");
      e.target.value = "";
    }
  };
  const cancelBtn = () => {
    let val = window.confirm("작성한 내용을 지우고 이전페이지로 이동합니다.");
    if (val === true) {
      window.location.replace(`mygym`);
    } else {
    }
  };
  window.ImageFile = ImageFile;
  window.VideoFile = VideoFile;
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmitValid)}>
        <Row>
          <ColName className="FormName">제목 : </ColName>
          <div className="FormValue" id="title">
            <input
              {...register("title", {
                required: { value: true, message: "제목은 필수값입니다." },
              })}
              className="Train_Title form-control"
            />
            <UseFormError message={formState?.errors?.title?.message} />
          </div>
        </Row>
        <Row>
          <ColName className="FormName">설명 : </ColName>
          <div className="FormValue" id="desciption">
            <textarea
              {...register("description", {
                max: {
                  value: 500,
                  message: "설명은 500자를 넘길 수 없습니다.",
                },
                validate: {
                  lessThan500: (value) => value.length <= 500,
                },
              })}
              className="Train_Desc form-control"
            />
          </div>
        </Row>
        <Row>
          <ColName className="FormName">첨부파일</ColName>
          {!!trainId ? (
            <div className="FormValue" id="file">
              <Label>
                {media ||
                extImg.includes(
                  location?.state?.filepath.split(".").pop().toLowerCase()
                ) ? (
                  <>
                    <FileImg
                      src={
                        location?.state?.filepath
                          ? location?.state?.filepath
                          : ""
                      }
                      ref={ImageFile}
                    />
                    <ReactPlayer
                      url={
                        location?.state?.filepath
                          ? location?.state?.filepath
                          : ""
                      }
                      width="200px"
                      height="200px"
                      ref={VideoFile}
                      id="videofile"
                      controls={false}
                      style={{ display: "none" }}
                    />
                  </>
                ) : (
                  <>
                    <FileImg
                      src={
                        location?.state?.filepath
                          ? location?.state?.filepath
                          : ""
                      }
                      ref={ImageFile}
                      style={{ display: "none" }}
                    />
                    <ReactPlayer
                      url={
                        location?.state?.filepath
                          ? location?.state?.filepath
                          : ""
                      }
                      width="200px"
                      height="200px"
                      ref={VideoFile}
                      id="videofile"
                      controls={false}
                    />
                  </>
                )}
                <File
                  {...register("file")}
                  type="file"
                  id="FileForm"
                  className="form-control"
                  onChange={onFileChange}
                />
              </Label>
            </div>
          ) : (
            <div className="FormValue" id="file">
              <Label>
                {media ? (
                  <>
                    <FileImg
                      src={`${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`}
                      ref={ImageFile}
                    />
                    <ReactPlayer
                      url={`${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`}
                      width="200px"
                      height="200px"
                      ref={VideoFile}
                      id="videofile"
                      controls={false}
                      style={{ display: "none" }}
                    />
                  </>
                ) : (
                  <>
                    <FileImg
                      src={`${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`}
                      ref={ImageFile}
                      style={{ display: "none" }}
                    />
                    <ReactPlayer
                      url={`${process.env.REACT_APP_SERVER_URL}static/defaultImage.png`}
                      width="200px"
                      height="200px"
                      ref={VideoFile}
                      id="videofile"
                      controls={false}
                    />
                  </>
                )}
                <File
                  {...register("file")}
                  type="file"
                  id="FileForm"
                  className="form-control"
                  onChange={onFileChange}
                />
              </Label>
            </div>
          )}
        </Row>
        <div className="ConfirmBtn span2">
          <input
            type="submit"
            className="btn btn-primary Create"
            value="저장"
          />
          <input
            type="button"
            className="btn btn-danger Cancel"
            onClick={cancelBtn}
            value="취소"
          />
        </div>
      </form>
    </Container>
  );
}

export default CreateTrain;
