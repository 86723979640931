//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  AuthContainer,
  BottomContainer,
  Form,
  HeaderContainer,
  Input,
  Span,
  Submit,
  Wrapper,
} from "../component/shared/Auth";
import { routes } from "./routes";
import { userLogIn } from "../utils/reactiveVar";
import SignupSuccess from "../Accounts/SignupSuccess";
import UseFormError from "../Accounts/UseFormError";
import KakaoLogin from "../component/oauth/KakaoLogin";
import GoogleLogin from "../component/oauth/GoogleLogin";
import styled from "styled-components";
import { CardColumns } from "react-bootstrap";
import { useEffect } from "react";
import { Logoh2 } from "../component/main/MainHeader";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ok
      token
      refreshtoken
      error
      type
    }
  }
`;
const DivisionLine = styled.div`
  height: 1px;
  background-color: #ccc;
  width: 100%;
`;
const LoginButtonsWrap = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;
const MLoginWrap = styled.div`
  min-height: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 700px) {
    min-height: unset;
  }
`;
const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const location = useLocation();
  const { register, handleSubmit, formState, setError, clearErrors } = useForm({
    mode: "onChange",
  });
  const onCompleted = async (data) => {
    const {
      login: { ok, error, token, refreshtoken, type },
    } = data;
    if (!ok) {
      if (error === "User Not Found.") {
        return setError("result", { message: "유저가 존재하지 않습니다." });
      } else if (error === "Incorrect Password") {
        return setError("result", { message: "잘못된 유저 정보 입니다." });
      } else {
        return setError("result", { message: error });
      }
    }
    await userLogIn(token, type, refreshtoken);
    //alert("wait");
    history.push("/");
  };
  const [login] = useMutation(LOGIN, { onCompleted });
  const onLoginValid = (data) => {
    const { email, password } = data;
    login({
      variables: {
        email,
        password,
      },
    });
  };
  const clearLoginError = (e) => {
    if (e.keyCode !== 13) {
      clearErrors("result");
    }
  };
  return (
    <MLoginWrap>
      <AuthContainer>
        <Wrapper>
          <HeaderContainer>
            <Logoh2 to={routes.home}></Logoh2>
            {location?.state?.message ? (
              <SignupSuccess>{location.state.message}</SignupSuccess>
            ) : null}
            <Form
              onSubmit={handleSubmit(onLoginValid)}
              style={{ width: "inherit" }}
            >
              <Input
                {...register("email", {
                  pattern: {
                    value: /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-z]+/,
                    message: "이메일 형식이 아닙니다.",
                  },
                  required: "이메일을 입력해주세요.",
                })}
                onKeyUp={clearLoginError}
                type="text"
                placeholder="email"
                error={Boolean(formState?.errors?.email?.message)}
                className="form-control"
              />
              <UseFormError
                message={formState?.errors?.email?.message}
                color="white"
              />
              <Input
                {...register("password", {
                  required: "비밀번호를 입력해주세요.",
                })}
                onKeyUp={clearLoginError}
                type="password"
                placeholder="password"
                error={Boolean(formState?.errors?.password?.message)}
                className="form-control"
              />
              <UseFormError
                message={formState?.errors?.password?.message}
                color="white"
              />
              <Submit
                type="submit"
                value="Log In"
                disabled={!formState.isValid}
                hasError={!formState.isValid}
              />
              <UseFormError
                message={formState?.errors?.result?.message}
                color="white"
              />
            </Form>
            <DivisionLine></DivisionLine>
            <LoginButtonsWrap>
              <KakaoLogin />
              <GoogleLogin />
            </LoginButtonsWrap>
          </HeaderContainer>
          <BottomContainer style={{ flexDirection: "column", gap: "10px" }}>
            <CardColumns>
              <Span>계정이 없으신가요?</Span>
              <Link to={routes.signup}>Sign up</Link>
            </CardColumns>
            <CardColumns>
              <Span>비밀번호를 잊으셨나요?</Span>
              <Link to={routes.findPassword} style={{ fontSize: "14px" }}>
                비밀번호 찾기
              </Link>
            </CardColumns>
          </BottomContainer>
        </Wrapper>
      </AuthContainer>
    </MLoginWrap>
  );
};

export default Login;
