//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { gql, useQuery } from "@apollo/client";
import { RECEIPT_FRAGMENT } from "../fragments/receiptFragment";

export const SELECT_RECEIPTS_BY_USER_ID = gql`
  query SelectReceiptsByUserId(
    $userId: Int
    $take: Int
    $buyLastId: Int
    $sellLastId: Int
    $contractType: String
    $dateType: String
  ) {
    selectReceiptsByUserId(
      userId: $userId
      take: $take
      buyLastId: $buyLastId
      sellLastId: $sellLastId
      contractType: $contractType
      dateType: $dateType
    ) {
      receiptBuyList {
        ...ReceiptFragment
      }
      receiptSellList {
        ...ReceiptFragment
      }
    }
  }
  ${RECEIPT_FRAGMENT}
`;

export const SelectReceiptsByUserId = (
  userId,
  take,
  buyLastId,
  sellLastId,
  contractType,
  dateType
) => {
  const { data, loading, refetch, fetchMore } = useQuery(
    SELECT_RECEIPTS_BY_USER_ID,
    {
      variables: {
        userId,
        contractType,
        dateType,
        ...(take && { take }),
        ...(buyLastId && { buyLastId }),
        ...(sellLastId && { sellLastId }),
      },
      fetchPolicy: "cache-and-network",
    }
  );
  return { data, loading, refetch, fetchMore };
};
